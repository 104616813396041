import type { RouteObject } from 'react-router-dom';
import { RouteConfig } from '../routes.types';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import { flatttenChildren } from './routes.utils.flatten';
import { DEFUALT_NAV_CONFIG, DEFUALT_ROUTE_ACCESS } from '../routes.constants';

export const getRoutesMetadataTree = ({
  routes,
  routesConfig,
}: {
  routes: RouteObject[];
  routesConfig: RouteConfig[];
}): RouteConfig[] => {
  const flattenedRoutesConfig = flatttenChildren<RouteConfig>(routesConfig);

  const traverseRoutes = (
    _routes: RouteConfig[],
    basePath = '',
    parentRoute: RouteConfig | null = null,
  ): RouteConfig[] => {
    return _routes.map((route: RouteConfig) => {
      // Skip the topmost node if it has id: "base"
      if (route.id === 'base') {
        return {
          ...route,
          children: traverseRoutes(route.children || [], basePath, parentRoute),
        };
      }

      const newRoute = { ...route };

      // Conditionally omit the `element` prop
      if (newRoute.element) {
        delete newRoute.element;
      }

      // Accumulate the path to create the full pathname
      const currentPath = newRoute.path ? `${basePath}/${newRoute.path}`.replace(/\/+/g, '/') : basePath;
      newRoute.pathname = currentPath;
      newRoute.slug = route.path;

      // Find matching metadata from flattenedRoutesConfig
      const matchingConfig = find(flattenedRoutesConfig, { pathname: currentPath });

      // Merge matchingConfig with newRoute and set default values for specific props
      if (matchingConfig) {
        newRoute.title = matchingConfig.title ?? null;
        newRoute.subtitle = matchingConfig.subtitle ?? null;
        newRoute.detail = matchingConfig.detail ?? null;
        newRoute.uuid = matchingConfig.uuid ?? null;
        newRoute.isActive = matchingConfig.isActive ?? true;
        newRoute.access = matchingConfig.access ?? DEFUALT_ROUTE_ACCESS;
        newRoute.nav = matchingConfig.nav ?? DEFUALT_NAV_CONFIG;
      } else {
        newRoute.isActive = true;
        newRoute.access = DEFUALT_ROUTE_ACCESS;
        newRoute.nav = DEFUALT_NAV_CONFIG;
      }

      // Add parent props if parentRoute exists
      if (parentRoute) {
        const { uuid, title, subtitle, detail, path, pathname, slug } = parentRoute;
        newRoute.parent = {
          uuid,
          path,
          pathname,
          slug,
          ...(title && { title }),
          ...(subtitle && { subtitle }),
          ...(detail && { detail }),
        };
      }

      // Conditionally add the category prop
      if (newRoute.pathname.startsWith('/cat') || newRoute.pathname.includes('/cat/')) {
        const { uuid, title, subtitle, detail, slug, path, pathname } = newRoute;
        newRoute.category = {
          uuid,
          path,
          pathname,
          slug,
          ...(title && { title }),
          ...(subtitle && { subtitle }),
          ...(detail && { detail }),
        };
      }

      // Traverse children if they exist
      if (newRoute.children) {
        newRoute.children = traverseRoutes(newRoute.children, currentPath, newRoute);
      }

      return newRoute;
    });
  };

  return traverseRoutes(cloneDeep(routes) as RouteConfig[]);
};
