/**
 * Returns a boolean
 * @param {string} value - to determine if String that is not empty ("")
 */

export const isNonEmptyString = (value: string): boolean => {
  if (typeof value !== 'string') return false;
  if (value === '') return false;
  if (!value) return false;
  return true;
};
