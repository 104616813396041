import { ACTIONS, type TableConfig } from 'types';
import { INPUTS, FORMAT } from 'types/form.types';

import { AUTH_USER, AUTH_USER_ENUM } from 'types/form.types';
import { COL_TYPE, ROW_ACTION } from 'components/DataTable/DataTable.types';
import {
  LinkToDetail,
  LinkToCategory,
  LinkToCategoryParent,
  ColumnThumb,
  ActionLink,
} from 'components/DataTable/components';
import { ListItemCard } from 'components/ListItemCard';
import { formatDateLocal } from 'utils';
import { DATATYPE, ENDPOINT } from './registros.config';
import { BiListPlus as IconListAdd } from 'react-icons/bi';
// import { DATATYPE, ENDPOINT } from './registros.config';

// ----------------------------------------------------------------- //
// TABLE CONFIG:
export const tableConfig: TableConfig = {
  dataType: { key: DATATYPE, role: 'table' },
  header: {
    button: {
      isHidden: false, // make explicit 'false', for now.. as other logic handles is also..
      // label: 't.register-entity.buttons.create',
      href: '/registros/create',
      icon: IconListAdd,
      // color: 'successXDark',
      // colorHover: 'successXXDark',
      color: 'successXDark',
      colorHover: 'successXXDark',
    },
  },
  list: {
    colWidth: {
      xs: 12,
      md: 6,
    },
    listItem: {
      component: ListItemCard,
      // render: (props?: DataListItemProps) => <ListItemCard {...props} />,
      shapeRatio: 2.2,
    },
    search: {
      filterByKeys: ['name'],
    },
  },
  actions: {
    hasActionView: true,
    hasActionEdit: true,
    hasActionNew: true,
  },
  api: {
    endpoint: ENDPOINT,
    query: { user_id: AUTH_USER.UUID },
    // query: { user_id: 'AUTH_USER_UUID' },
  },
  columns: [
    // {
    //   label: 'IMAGE',
    //   name: 'image',
    //   // maxWidth: '150px',
    //   isSortable: false,
    //   render: ({ data }) => (data.image ? <ColumnThumb data={data} /> : null),
    // },
    {
      label: 'NAME',
      name: 'name',
      columnType: COL_TYPE.DATA,
      // width: 2,
      // width: false,
      widths: {
        xs: 4,
        lg: 4,
      },
      isSortable: true,
      render: ({ data }) => <LinkToDetail targetID={data.id} label={data.name} />,
    },
    {
      label: 'TYPE',
      name: 'cat_id',
      columnType: COL_TYPE.DATA,
      // TODO: REMOVE `widths` AND use `width` TYPE `false` | { ..sizeMap }
      // width: 2,
      width: false,
      widths: {
        xs: 2,
        lg: 2,
      },
      align: 'right',
      isSortable: true,
      valueFormat: {
        type: FORMAT.BY_ID, // TODO: NO TS TYPE WARN ???????
        source: 'cat_id',
      },
      render: ({ data }) => {
        return <LinkToCategoryParent catId={data.cat_id} />;
      },
      // render: ({ data }) => (
      // <LinkToDetail
      //   targetID={data.id}
      //   label={data.cat_id}
      //   valueFormat={{
      //     type: FORMAT.BY_ID, // TODO: NO TS TYPE WARN ???????
      //     source: 'cat_id',
      //   }}
      // />
      // ),
      // render: ({ data,  valueFormat: {type: FORMAT.BY_ID, source: 'cat_id'} }) => <>{data.user_id}</>,
    },
    {
      label: 'CATEGORÍA',
      name: 'cat_id',
      columnType: COL_TYPE.DATA,
      // width: 2,
      width: false,
      isSortable: true,
      valueFormat: {
        type: FORMAT.BY_ID, // TODO: NO TS TYPE WARN ???????
        source: 'cat_id',
      },
      render: ({ data }) => <LinkToCategory catId={data.cat_id} />,
    },
    // {
    //   label: 'EMAIL',
    //   name: 'email',
    //   // width: 2,
    // widths: {
    //     xs: 0,
    //     lg: 3,
    //   },
    //   isSortable: true,
    //   render: ({ data }) => <LinkToDetail targetID={data.id} label={data.email} />,
    // },
    // {
    //   label: 'PROVINCE',
    //   name: 'province',
    //   // width: 2,
    // widths: {
    //     xs: 0,
    //     lg: 2,
    //   },
    //   isSortable: true,
    //   render: ({ data }) => <LinkToDetail targetID={data.id} label={data.province} />,
    // },
    // ======================================================================== //
    {
      label: 'ID',
      name: 'id',
      columnType: COL_TYPE.DATA,
      // width: 2,
      widths: {
        xs: 0,
        lg: 1,
      },
      align: 'center',
      isSortable: true,
      render: ({ data }) => data?.id,
    },
    // ======================================================================== //
    /*
    {
      label: 'UPPDATED',
      name: 'created_at',
      // width: 2,
      widths: {
        xs: 0,
        lg: 2,
      },
      isSortable: true,
      render: ({ data }) => formatDateLocal(data?.updated_at),
    },
    */
    // ======================================================================== //
    {
      label: 'VIEW',
      name: 'view',
      columnType: COL_TYPE.ACTION,
      // width: 2,
      widths: {
        xs: 0,
        lg: 1,
      },
      isSortable: false,
      render: ({ data }) => <ActionLink data={data} action={ROW_ACTION.VIEW} />,
    },
    {
      label: 'EDIT',
      name: 'edit',
      columnType: COL_TYPE.ACTION,
      // width: 2,
      widths: {
        xs: 0,
        lg: 1,
      },
      isSortable: false,
      render: ({ data }) => <ActionLink data={data} action={ROW_ACTION.EDIT} />,
    },
  ],
};
