import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import { NavigationListener } from 'routes/NavigationListener';
import { Layout } from 'pages/Layout/Layout';
import LayoutProvider from 'pages/Layout/context/LayoutProvider';
import { AccessLayer } from 'routes/AccessLayer';
import { SiteLockLayer } from 'pages/SiteLockPage';
import initialState from 'pages/Layout/context/ContextState';
import { useAuth } from 'auth/AuthContext';
import { transformRoutes } from 'routes/utils/transormRoutes';
import type { RoutesLoaderData } from 'routes/routes.types';
import { getAppRoutes } from 'routes/getAppRoutes';
import { routerFlagsV7 } from 'routes/routes.constants';
import { getAuthRoutes } from 'routes/auth/getAuthRoutes';
import { useEffect, useState } from 'react';
import { useGlobal } from 'store/useGlobalContext';
import { useStore } from 'store/useStore';
import { Spinner } from 'components/Spinner/Spinner';
import { useAppMetadataLayer } from 'routes/hooks/useAppMetadataLayer';
import { AUTH_ROUTE_PATHNAMES } from 'routes/routes.constants';

const App = () => {
  const { user } = useAuth();
  const store = useStore();
  const { categories } = useGlobal('categories');
  const { routesConfiguration } = useGlobal('routesConfiguration');
  const [routerData, setRouterData] = useState<RoutesLoaderData>({ appRoutes: [], transformedRoutes: [] });
  const [isInitialized, setIsInitialized] = useState(false);
  const isAuthRoute = AUTH_ROUTE_PATHNAMES.includes(location.pathname);

  // NOTE: 1. FETCH CATEGORIES + ROUTES..
  const appMetadata = useAppMetadataLayer({ isEnabled: true });

  // NOTE: 2. SET ROUTER_DATA..
  useEffect(() => {
    if (categories.length > 0 && appMetadata.isSuccess && routesConfiguration.length > 0) {
      setRouterData({
        appRoutes: getAppRoutes({ user, store }),
        transformedRoutes: routesConfiguration
          ? transformRoutes(getAppRoutes({ user, store }), '/', routesConfiguration)
          : [],
      });
      setIsInitialized(true);
    }
  }, [appMetadata.isSuccess, routesConfiguration, user]);

  /*
  // NOTE: 3. DEV ONLY !!
  if (appMetadata.isSuccess) {
    const router = createBrowserRouter([{ path: '/', element: <DevDump routerData={routerData} /> }]);
    return <RouterProvider router={router} />;
    return <Spinner page />;
  }
  */

  if (!isAuthRoute && (appMetadata.isFetching || !isInitialized)) {
    return <Spinner page />;
  }

  const router = createBrowserRouter(
    [
      ...getAuthRoutes(), // NOTE: AUTH ROUTES MUST BE FIRST !!
      {
        id: 'root',
        path: '/',
        loader: () => routerData,
        element: (
          <LayoutProvider contextName={'layout'} value={{ ...initialState }}>
            <SiteLockLayer>
              <AccessLayer>
                <Layout>
                  <Outlet />
                </Layout>
              </AccessLayer>
            </SiteLockLayer>
            <NavigationListener />
          </LayoutProvider>
        ),
        children: routerData.appRoutes,
        // children: getAppRoutes({ user, store }),
      },
    ],
    routerFlagsV7,
  );

  return <RouterProvider router={router} />;
};

export default App;
