import type { BreakpointDefaults } from 'types/viewport.types';

import { mapNewBreakpointRules, toEmNumeric, toPixelString, toRemNumeric } from 'utils/utils.viewport';

// BREAKPOINT DEFAULT pixel values (from Carbon) best defined NUMERICALLY,
// allowing for calculations etc..

const BREAKPOINTS_LEGACY: BreakpointDefaults = {
  xs: 320, // (not really used)
  sm: 600, // min 600px
  md: 960, // min 960px
  lg: 1260, // min 1260px
  xl: 1920, // min 1920px (no max required)
};

export const BREAKPOINTS: BreakpointDefaults = {
  xs: 0, // (not really used)
  sm: 576, // min 600px
  md: 768, // min 960px
  lg: 992, // min 1260px
  xl: 1200, // min 1920px (no max required)
  xxl: 1600, // min 1920px (no max required)
};

/**
 * NOTE: xs:320 as a breakpoint is redundant, as the smallest range
 * that will be queried is actually 0-600px (starting from zero),
 * which is the XS "size", when thinking in terms of RANGES.
 *
 * Each subsequent "size" is then the range that begins with its named
 * breakpoint value and ends with the next breakpoint value.
 * (again, when thinking in ranges).
 * The final breakpoint: XL, begins a range where NO max is required.
 *
 * Mobile-first responsive design uses zero as the default size,
 * much like a boolean default is (should be) 'false';
 * then, in ascending order, each breakpoint begins a new override clause.
 * MIN-WIDTH media queries are therefore recommended for most use cases;
 * and they also allow avoiding the need to +/- 1px to the breakpoint value.
 */

export const BREAKPOINTS_PX = mapNewBreakpointRules(toPixelString);
export const BREAKPOINTS_REM = mapNewBreakpointRules(toRemNumeric);
export const BREAKPOINTS_EM = mapNewBreakpointRules(toEmNumeric);
