import ReactSelect, { SelectInstance } from 'react-select';
import { ForwardedRef, forwardRef } from 'react';
import { useGlobal } from 'store/useGlobalContext';
import { getOptionsCategories } from './select-category.utils';
import { SelectProps, useSelectConfig, SELECT_DEFAULT_PROPS } from '../Select';
import { styles } from './SelectCategory.css';

export const SelectCategory = forwardRef<any, SelectProps>(
  (
    { field, options: initOptions, defaultValue, onChange }: SelectProps,
    ref: ForwardedRef<SelectInstance>,
  ) => {
    const { metaCats } = useGlobal('metaCats');

    const { options, currentSelection, handleChange } = useSelectConfig({
      initOptions: initOptions || getOptionsCategories(metaCats),
      defaultValue,
      onChange,
    });

    const selectProps = {
      ...SELECT_DEFAULT_PROPS,
      ariaLabel: field?.label,
      className: `input-select select-${field?.name}`,
      ...(field?.placeholder && { placeholder: field?.placeholder }),
    };

    return (
      <div css={styles}>
        <ReactSelect
          name={field?.name}
          ref={ref as ForwardedRef<SelectInstance>}
          options={options}
          defaultValue={currentSelection}
          value={currentSelection}
          onChange={handleChange}
          {...selectProps}
        />
      </div>
    );
  },
);
