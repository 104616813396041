import type { RouteConfig } from 'routes/routes.types';
import { flatttenChildren } from 'routes/utils/routes.utils.flatten';

const ROUTES_META_PATH_BLACKLIST: string[] = ['', '*', 'logout'];

const getFilteredUniqueRoutes = (routes: RouteConfig[]) => {
  return routes.reduce((acc, current) => {
    if (!acc.find((route: RouteConfig) => route.path === current.path)) {
      acc.push(current);
    }
    return acc;
  }, [] as RouteConfig[]);
};

export const getFlatRoutesMeta = (routes: RouteConfig[]): RouteConfig[] => {
  const flattenedRoutesMeta: RouteConfig[] = routes ? flatttenChildren(routes) : routes;
  // const uniqueRoutesMeta = getFilteredUniqueRoutes(flattenedRoutesMeta);
  const newRoutesMeta = flattenedRoutesMeta.filter(
    (route: any) => !ROUTES_META_PATH_BLACKLIST.includes(route?.path),
  );

  return newRoutesMeta;
};

export const getFlatRoutesMeta__V1 = (routes: RouteConfig[]): RouteConfig[] => {
  const flattenedRoutesMeta: RouteConfig[] = routes ? flatttenChildren(routes) : routes;
  const uniqueRoutesMeta = getFilteredUniqueRoutes(flattenedRoutesMeta);
  const newRoutesMeta = uniqueRoutesMeta.filter(
    (route: any) => !ROUTES_META_PATH_BLACKLIST.includes(route?.path),
  );

  return newRoutesMeta;
};
