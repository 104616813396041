import { faker, fakerES, fakerEN } from '@faker-js/faker';

export const genMockFullName = ({ locale }: { locale?: 'EN' | 'ES' } = {}) => {
  const name_es = fakerES.person.fullName().replace(/-/g, '.');
  const name_en = fakerEN.person.fullName().replace(/-/g, '.');

  if (locale === 'EN') {
    return name_en;
  }
  if (locale === 'ES') {
    return name_es;
  }

  const name_random = faker.helpers.arrayElement([
    name_es,
    name_es,
    name_es,
    name_es,
    name_es,
    name_en,
    name_en,
  ]);

  return name_random;
};

export const genDocumentNumber = (type?: string) => {
  const stringNumeric = faker.string.numeric({ length: 7 });
  const prefixLetter = faker.string.alpha({ casing: 'upper' });
  switch (type) {
    case 'NIE':
      return `X${stringNumeric}${faker.string.alpha({ casing: 'upper' })}`;
    default:
      return `${prefixLetter}${stringNumeric}${faker.string.alpha({ casing: 'upper' })}`;
  }
};
