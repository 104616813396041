import { useState, useEffect } from 'react';
import { AUTHENTICATOR_CREDENTIALS } from './client.config';
import { client as clientInitRest } from './client.rest';
import { client as clientInitSocketIO } from './client.socketio';
import type { UseAuthClient } from './client.types';

export const useGetAuthentication = ({ type }: { type: 'socketio' | 'rest' }): UseAuthClient => {
  const client = type === 'rest' ? clientInitRest : clientInitSocketIO;

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!client.authentication.authenticated);

  const authenticateClient = async () => {
    try {
      await client.reAuthenticate();
      setIsAuthenticated(true);
    } catch {
      try {
        await client.authenticate({
          strategy: 'local',
          ...AUTHENTICATOR_CREDENTIALS,
        });
        setIsAuthenticated(true);
      } catch (error: unknown) {
        setIsAuthenticated(false);
        error instanceof Error ? console.error(error.message) : console.error(String(error));
      }
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      authenticateClient();
    }
  }, [isAuthenticated]);

  return {
    client,
    authenticateClient,
    isAuthenticated,
  };
};
