import { INPUTS } from 'types/form.types';
import type { FieldConfig } from 'components/Form';
import type { SelectOption } from 'components/FormUI/Select';

export const FIELDS_DOCS_PERSONAL: FieldConfig[] = [
  {
    name: 'document_type_personal',
    label: 'tipo de documento',
    inputType: INPUTS.select,
    required: true,
    options: [
      {
        value: 'DNI',
        label: 'DNI',
      },
      {
        value: 'NIE',
        label: 'NIE',
      },
      {
        value: 'Pasaporte',
        label: 'Pasaporte',
      },
    ] as SelectOption[],
  },
  {
    name: 'document_number_personal',
    label: 'número de documento',
    inputType: INPUTS.text,
    required: true,
  },
];

export const FIELDS_DOCS_BIZ: FieldConfig[] = [
  {
    name: 'document_type_biz',
    label: 'tipo de documento',
    inputType: INPUTS.select,
    required: true,
    options: [
      { value: 'NIF', label: 'NIF' },
      { value: 'CIF', label: 'CIF' },
    ] as SelectOption[],
  },
  {
    name: 'document_number_biz',
    label: 'número de documento',
    inputType: INPUTS.text,
    required: true,
  },
];
