import { toast, type ToastOptions, type TypeOptions } from 'react-toastify';
import { MdCheckCircle as IconSuccess } from 'react-icons/md';
import { defaultToastOptions } from './Toast.config';
import 'react-toastify/dist/ReactToastify.css';

const TOASTS: Record<string, any> = {
  success: {
    type: 'success',
    toastId: 'toast-success',
    icon: IconSuccess,
  },
};

export const notify = (newToast: ToastOptions) => {
  const [[type, { message, ...options }]] = Object.entries(newToast);

  toast(message, {
    ...defaultToastOptions,
    ...TOASTS[type],
    ...options,
  });
};
