import { useMutationState, useIsMutating } from '@tanstack/react-query';
import type { UseMutationResult } from '@tanstack/react-query';
import { useUpdate } from 'api';
import { FormData } from 'formdata-node';
import { USER_ROLE } from 'auth/types/user.types';
import { useAuth } from 'auth/AuthContext';

export const useUserValidation = () => {
  const { user } = useAuth();
  const isValidating = !!useIsMutating({ mutationKey: ['users'] });

  const query = {};
  if (user) {
    Object.assign(query, { uuid: user?.uuid });
  }

  const endpoint = 'users';
  const mutationKey = ['updateEntry', { endpoint }];

  const updateEntry = useUpdate({
    mutationKey,
    endpoint,
    query,
    onSuccess: (data) => log('[USER_UPDATED]', 'lime', data),
  });

  const updateUserValidation = async (userRole: USER_ROLE.REGISTERED | USER_ROLE.VALIDATED) => {
    const formData = new FormData();
    formData.set('user_role', userRole);

    // TODO: HOW TO SET `FormData` WITH VALUE OF `number` TYPE... ?? (research..)
    // formData.set('created_at', Number(user.created_at) as unknown as string);

    const body = JSON.stringify(Object.fromEntries(formData.entries()));
    return await updateEntry.mutateAsync({ body, id: user.id });
  };

  const dataValidation = useMutationState({
    filters: { mutationKey, status: 'success' },
    select: (mutation: any) => ({ data: mutation.state.data?.data }),
  })?.at(-1);

  return { updateUserValidation, dataValidation, isValidating };
};
