import { INPUTS } from 'types/form.types';
import type { FieldConfig } from 'components/Form';
import { SPAIN_PROVINCES } from 'constants/spain';

export const FIELDS_ADDRESS: FieldConfig[] = [
  {
    name: 'address_street_type',
    label: 'tipo de vía',
    inputType: INPUTS.text,
    placeholder: 'Calle',
    required: true,
    colWidth: {
      xs: 12,
      md: 2,
    },
  },
  {
    name: 'address_street_name',
    label: 'nombre de calle',
    inputType: INPUTS.text,
    required: true,
    colWidth: {
      xs: 12,
      md: 8,
    },
  },
  {
    name: 'address_street_number',
    label: 'número',
    inputType: INPUTS.text,
    required: true,
    colWidth: {
      xs: 12,
      md: 2,
    },
  },
  {
    name: 'address_block',
    label: 'Bloque',
    // hint: 'letra o número',
    inputType: INPUTS.text,
    colWidth: {
      md: 1,
    },
  },
  {
    name: 'address_escalera',
    label: 'Escalera',
    // hint: 'letra o número',
    inputType: INPUTS.text,
    colWidth: {
      md: 1,
    },
  },
  // -------------------------- //
  {
    name: 'address_piso',
    label: 'Piso',
    inputType: INPUTS.text,
    colWidth: {
      md: 1,
    },
  },
  {
    name: 'address_door',
    label: 'Puerta',
    inputType: INPUTS.text,
    colWidth: {
      md: 1,
    },
  },
  {
    name: 'address_info',
    label: 'Dirección (info adicional)',
    // hint: 'Polígono, Urbanización, etc..',
    placeholder: ' ',
    inputType: INPUTS.text,
    colWidth: {
      md: 8,
    },
  },
  // ======================================================================== //
  {
    name: 'city',
    label: 'población',
    inputType: INPUTS.text,
    required: true,
    colWidth: {
      xs: 12,
      md: 6,
    },
  },
  {
    name: 'postal_code',
    label: 'código postal',
    inputType: INPUTS.text,
    required: true,
    colWidth: {
      xs: 12,
      md: 6,
    },
  },
  // ======================================================================== //
  {
    name: 'country',
    label: 'país',
    inputType: INPUTS.selectCountry,
    defaultValue: 'ES',
    required: true,
  },
  {
    name: 'province',
    label: 'província',
    hint: 'Provincia de España',
    inputType: INPUTS.select,
    options: SPAIN_PROVINCES,
    required: false,
    isDisabled: true,
    isHidden: true,
    isControlledBy: {
      criteria: { country: 'ES' },
      target: {
        isHidden: false,
        isDisabled: false,
      },
    },
  },
  // ======================================================================== //
];

export const FIELDS_ADDRESS_PERSONAL: FieldConfig[] = [...FIELDS_ADDRESS];

export const FIELDS_ADDRESS_BIZ: FieldConfig[] = [
  ...FIELDS_ADDRESS.map((fieldConfig) => ({ ...fieldConfig, name: `biz_${fieldConfig.name}` })),
];
