import { useContext, useCallback, useRef } from 'react';
import type { DispatchContextType, GlobalStore, GlobalStoreKeys, GlobalStoreDispatch } from './GlobalContext.types';
import { toUpperCaseFirst } from 'utils/utils.string.format';
import { GlobalContext, DispatchContext } from './GlobalContext';
import { initialState, RESET_STATE_PATHS } from './store.constants';

// TODO: 🚨 IMPORTANT - ADD RETURN TYPE BASED ON INCOMING `key` PROP..

export const useGlobal = (key?: GlobalStoreKeys, initState?: Partial<GlobalStore>) => {
  // TODO: use which type ?? - should be `DispatchContextType`, no ??
  // const store = { ...useContext(GlobalContext), dispatch: useContext(DispatchContext) as DispatchContextType };
  const store = { ...useContext(GlobalContext), dispatch: useContext(DispatchContext) as GlobalStoreDispatch };

  if (RESET_STATE_PATHS.includes(location.pathname)) {
    Object.assign(store, { ...initialState });
  }

  if (key) {
    const cache = useRef<any>({});
    const { dispatch, ...state } = store;

    // ALLOW OPTIONAL initState TO MIMIC BEHAVIOUR OF React.useState(initState)
    if (initState && !cache.current[key as GlobalStoreKeys]) {
      dispatch({ [key]: initState });
      cache.current[key] = initState;
    }

    return {
      [key]: state[key],
      [`set${toUpperCaseFirst(key)}`]: useCallback((value: Partial<GlobalStore>) => dispatch({ [key]: value }), [key]),
    };
  }

  return store;
};
