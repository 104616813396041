/* eslint-disable new-cap */
import { css } from '@emotion/react';
import Color from 'color';
import { colors, layout, min, cssInputBox, cssInputText, forms } from 'styles';

const variantBase = Color(colors.primaryXLight);
const colorVariant = {
  bg: variantBase.lighten(0.7).hex(),
  inactive: variantBase.darken(0.2).desaturate(0).hex(),
  active: variantBase.darken(0.4).desaturate(0.3).hex(),
  sep: variantBase.darken(0.4).fade(0.75).rgb().toString(),
};

const placeholderInactive = css`
  font-weight: 600;
  opacity: 1;
  color: ${colors.successDark};
  -webkit-text-fill-color: ${colors.successDark};
`;

const placeholderActive = css`
  font-weight: 600;
  opacity: 1;
  color: ${colors.successXXDark};
  -webkit-text-fill-color: ${colors.successXXDark};
`;

const placeholderFocus = css`
  font-weight: 600;
  opacity: 1;
  color: transparent;
  -webkit-text-fill-color: transparent;
`;

const TAG_HEIGHT = 48;

const stylesCustom = css`
  .react-tags {
  }
  .is-focused {
  }
  .react-tags__selected {
  }
  .react-tags__selected-tag {
  }
  .react-tags__selected-tag-name {
  }
  .react-tags__search {
    display: flex;
    align-self: flex-start;
    height: 100%;
    height: 100%;
    max-height: ${TAG_HEIGHT}px;
    padding: 0;
  }
  .react-tags__search-wrapper {
    height: ${TAG_HEIGHT}px !important;
    padding: 0 0.66em;
    height: calc(${TAG_HEIGHT}px - calc(${layout.borderWidth} * 2)) !important;
    border-radius: ${layout.borderRadius};

    /* ************************************** */

    & {
      border: ${layout.borderWidth} solid ${colors.successLight};
      .react-tags__search-input {
        ::-webkit-input-placeholder {
          ${placeholderInactive}
        }
        ::-moz-placeholder {
          ${placeholderInactive}
        }
        :-ms-input-placeholder {
          ${placeholderInactive}
        }
        ::placeholder {
          ${placeholderInactive}
        }
        ::-webkit-datetime-edit {
          ${placeholderInactive}
        }
      }
      .react-tags__search-input:focus:empty {
        ::-webkit-input-placeholder {
          ${placeholderFocus}
        }
        ::-moz-placeholder {
          ${placeholderFocus}
        }
        :-ms-input-placeholder {
          ${placeholderFocus}
        }
        ::placeholder {
          ${placeholderFocus}
        }
        ::-webkit-datetime-edit {
          ${placeholderFocus}
        }
      }
      &:hover {
        border: ${layout.borderWidth} solid ${colors.successXXDark};
        .react-tags__search-input {
          ::-webkit-input-placeholder {
            ${placeholderActive}
          }
          ::-moz-placeholder {
            ${placeholderActive}
          }
          :-ms-input-placeholder {
            ${placeholderActive}
          }
          ::placeholder {
            ${placeholderActive}
          }
          ::-webkit-datetime-edit {
            ${placeholderActive}
          }
        }
      }
      &:hover:not(:focus) {
        background: white;
      }
    }
  }

  .react-tags__search-input {
    height: 100%;
    color: ${colors.dangerDark}!important;
    cursor: pointer;
    &,
    &:focus {
      outline: none; /* POOR ACCESSIBILITY */
    }
    &:focus {
      border-color: ${colors.primaryLight};
    }
  }
  .react-tags__suggestions {
  }
  .is-active {
  }
  .is-disabled {
  }
  .react-tags__suggestion-prefix {
  }
`;

export const styles = css`
  .react-tags {
    font-size: 1em;
    line-height: 1.2;
    cursor: text;
    ${cssInputBox}
    ${cssInputText}
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    padding: calc(${layout.padding} * 0.2) calc(${layout.padding} * 0.2) !important;

    &:focus {
      border: ${layout.borderWidth} solid ${colors.primaryLight};
    }

    button,
    .react-tags__search {
      margin: calc(${layout.padding} * 0.2) calc(${layout.padding} * 0.2) !important;
    }

    max-width: 100%;
    ${min.sm} {
      max-width: 100%;
    }
    ${min.md} {
      max-width: 100%;
    }
    ${min.lg} {
      /* max-width: 730px; */
      max-width: 100%;
    }
  }

  .react-tags.is-focused {
    border-color: #b1b1b1;
    .react-tags__search-wrapper {
      border: ${layout.borderWidth} solid ${colors.greyXLight};
      .react-tags__search-input {
        cursor: text;
        color: ${colors.textDark} !important;
        background: white !important;
      }
    }
  }

  .react-tags__selected {
    display: contents;
  }

  .react-tags__selected-tag {
    display: inline-block;
    box-sizing: border-box;
    border-color: ${colors.primaryXXLight}!important;
    border-radius: ${layout.borderRadius};

    color: ${colors.primary}!important;
    -webkit-text-fill-color: ${colors.primary}!important;
    &:after {
      color: ${colors.primary}!important;
      -webkit-text-fill-color: ${colors.primary}!important;
      opacity: 0.4;
      content: '\\2297' !important; // DELETE X
      margin: 0 calc(${layout.padding} * 0.25) 0 calc(${layout.padding} * 0.5);
      transform: scale(1.33);
      cursor: pointer;
      /* NEW */
      border-radius: 100%;
      width: 1em;
      height: 1em;
      line-height: 1em;
      background: transparent;
    }
    &:after:hover {
      color: ${colors.primary}!important;
      -webkit-text-fill-color: ${colors.primary}!important;
      opacity: 0.4;
      content: '\\2297' !important; // DELETE X
      margin: 0 calc(${layout.padding} * 0.25) 0 calc(${layout.padding} * 0.5);
      transform: scale(1.33);
      cursor: pointer;
      /* NEW */
      border-radius: 100%;
      width: 1em;
      height: 1em;
      line-height: 1em;
      background: transparent;
      color: ${colors.dangerDark}!important;
      -webkit-text-fill-color: ${colors.dangerDark}!important;
      opacity: 1;
      background: white;
    }

    background-color: white;
    background-color: ${colorVariant.bg};

    /* match the font styles */
    font-size: inherit;
    line-height: inherit;

    .react-tags__selected-tag-name {
      color: ${colors.primary} !important;
      -webkit-text-fill-color: ${colors.primary} !important;
      padding: 0 0.5em;
    }
  }

  .react-tags__selected-tag:after {
    content: '\\2715'; // HEAVY PLUS
    margin-left: calc(${layout.padding} * 0.5);
  }

  .react-tags__search {
    display: inline-block;
    padding: calc(${layout.padding} * 0.5) calc(${layout.padding} * 1) calc(${layout.padding} * 1);
    max-width: 100%;

    .react-tags__suggestions {
      position: relative;
      display: inline-flex;
      align-items: center;
      transform: translateX(calc(${layout.padding} * 0.5));
      border: 0px;
      margin: 0px;
      padding: 0px;
      ul {
        border: 0px;
        li {
          display: flex;
          align-items: center;
          height: ${TAG_HEIGHT}px;
          border-radius: ${layout.borderRadius};
        }
      }
    }
  }

  .react-tags__search-input {
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    font-size: inherit;
    line-height: inherit;
  }

  .react-tags__search-input::-ms-clear {
    display: none;
  }

  .react-tags__suggestions {
    position: absolute;
    z-index: 10;
    top: 100%;
    left: 0;
    width: 100%;
    & > ul > li {
      padding: calc(${layout.padding} * 0.75) calc(${layout.padding} * 1) !important;
    }
    ul[role='listbox'] {
      li {
        padding: calc(${layout.padding} * 0.75) calc(${layout.padding} * 1) !important;
        span {
          font-weight: 400;
          mark {
            font-weight: 700;
          }
        }
      }
      li:last-of-type {
        text-align: center;
        background-color: ${colors.successXDark}!important;
        font-weight: 700;
        padding: calc(${layout.padding} * 1) calc(${layout.padding} * 2) calc(${layout.padding} * 1)
          calc(${layout.padding} * 1) !important;
        color: white !important;
        -webkit-text-fill-color: white !important;
        span {
          font-size: 1em;
          font-weight: 400;
          padding-right: calc(${layout.padding} * 0.5);
          &:before {
            content: '\\FF0B';
            font-size: 1.2em;
            font-weight: 700 !important;
            color: white !important;
            -webkit-text-fill-color: white !important;
            padding-right: calc(${layout.padding} * 0.5);
            transform: scale(1.33) translateY(100%);
            position: relative;
          }
        }
      }
    }
  }

  .react-tags__suggestions ul {
    margin: 4px -1px;
    padding: 0;
    list-style: none;
    background: white;
    border: 1px solid #d1d1d1;
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  }

  .react-tags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 6px 8px;
  }

  .react-tags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 700;
  }

  .react-tags__suggestions li:hover {
    cursor: pointer;
    background: #eee;
  }

  .react-tags__suggestions li.is-active {
    background: #b7cfe0;
  }

  .react-tags__suggestions li.is-disabled {
    opacity: 0.5;
    cursor: auto;
  }

  ${stylesCustom}
`;
