import { Col, Row } from 'react-grid-system';
import { useState } from 'react';
import { on } from 'events';
import type { DataEntry, METADATA } from 'types';
import { useGetMetadata } from 'api/useGetMetaData_THIS';
import { Button } from 'components/Button';
import { Modal } from 'components/Modal/Modal';
import { Spinner } from 'components/Spinner';
import type { ModalTriggerProps } from './ModalTrigger.types';
import { styles } from './ModalTrigger.css';

export const ModalTrigger = ({
  target,
  isDisabled = false,
  label,
  variant = 'clear',
  color = 'primaryDark',
  type = 'link',
  modalSize = 'lg',
  onConfirm,
}: ModalTriggerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<DataEntry>();

  const { isFetching, refetch: fetchModalData } = useGetMetadata({
    meta_key: target.meta_key as METADATA,
    enabled: false,
    onSuccess: (res) => {
      setData(res);
      setIsOpen(true);
    },
  });

  const handleConfirm = () => {
    setIsOpen(false);
    onConfirm?.(target.meta_key);
  };

  return (
    <span css={styles} className={`modal-trigger ${isDisabled ? 'is-disabled' : ''}`}>
      <Button
        label={label}
        color={color}
        variant={type === 'button' ? variant : 'clear'}
        className={`btn-${type}`}
        isDisabled={isDisabled}
        onClick={fetchModalData}
        type="button"
      />
      {isFetching ? (
        <Spinner page />
      ) : data ? (
        <Modal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          title={`${data?.title}`}
          onConfirm={handleConfirm}
          size={modalSize}
        >
          <section>
            <Row>
              <Col xs={12}>
                <p>{data?.text}</p>
              </Col>
            </Row>
          </section>
        </Modal>
      ) : null}
    </span>
  );
};
