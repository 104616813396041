import { AUTHENTICATOR_TOKEN } from '../client/client.config';
import ls from 'localstorage-slim';

// NOTE: ROBUST REPLACEMENT for LOCALSTORAGE
// https://www.npmjs.com/package/localstorage-slim

export const ConnectionToken = {
  get: function () {
    try {
      const token = ls.get(AUTHENTICATOR_TOKEN);
      return token;
    } catch (error: unknown) {
      error instanceof Error ? console.error(error.message) : console.error(String(error));
      return null;
    }
  },
  set: function (token: unknown) {
    ls.set(AUTHENTICATOR_TOKEN, JSON.stringify(token));
  },
  delete: function () {
    const storedToken = this.get();
    if (storedToken) {
      ls.remove(AUTHENTICATOR_TOKEN);
    }
  },
};
