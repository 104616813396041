import { css } from '@emotion/react';
import { layout } from 'styles/layout.css';
import { colors } from 'styles/colors.css';
import { min, max } from 'styles/media-queries-V1';

export const styles = css`
  padding: 0;

  h1 {
    /* margin-bottom: 0; */
  }

  h3 {
    margin-top: 0.5em;
    margin-bottom: 2em;
  }

  hr {
    margin: 1.7em 0 1.3em;
  }

  .tool-bar {
    padding: 0;
    & > div {
      ${min.md} {
        padding: 0.2em 0;
      }
    }

    .col-search {
      order: 1;
    }

    .col-new {
      display: flex;
      justify-content: flex-end;
      order: 2;

      ${max.md} {
        order: -1;
        a {
          width: 100%;
        }
      }
    }
  }

  #search {
    /* margin-top: calc(${layout.padding} * 4); */
    /* margin-bottom: 1em; */
    &.has-query {
      input {
        border-color: ${colors.successXDark};
      }
      label {
        border-color: ${colors.successXDark};
        background: ${colors.successXDark};
      }
    }
    &.has-query.no-results {
      input {
        border-color: ${colors.warning};
      }
      label {
        border-color: ${colors.warning};
        background: ${colors.warning};
      }
    }
  }
`;
