import type { TableConfig } from 'types';
import { FORMAT } from 'types/form.types';
import {
  ColumnThumb,
  LinkToDetail,
  LinkToCategory,
  LinkToCategoryParent,
  ActionLink,
} from 'components/DataTable/components';
import { COL_TYPE, ROW_ACTION } from 'components/DataTable/DataTable.types';
import { ListItemCard } from 'components/ListItemCard';
import { DATATYPE, ENDPOINT } from './posts.config';
// formatFieldValue

// ----------------------------------------------------------------- //
// TABLE CONFIG:
export const tableConfig: TableConfig = {
  dataType: { key: DATATYPE, role: 'table' },
  api: {
    endpoint: ENDPOINT,
  },
  list: {
    colWidth: {
      xs: 12,
      md: 6,
    },
    listItem: {
      // component: EventCard,
      component: ListItemCard,
      shapeRatio: 2.2,
    },
    search: {
      filterByKeys: ['name'],
    },
  },
  actions: {
    hasActionView: true,
    hasActionEdit: true,
    hasActionNew: true,
  },
  columns: [
    // {
    //   label: 'IMAGE',
    //   name: 'image',
    //   // maxWidth: '150px',
    //   isSortable: false,
    //   render: ({ data }) => (data.image ? <ColumnThumb data={data} /> : null),
    // },
    {
      label: 'NAME',
      name: 'name',
      columnType: COL_TYPE.DATA,
      // width: 2,
      width: false,
      isSortable: true,
      render: ({ data }) => <LinkToDetail targetID={data.id} label={data.name} />,
    },
    /*
    {
      label: 'EMAIL',
      name: 'email',
      // width: 2,
      widths: {
        xs: 0,
        lg: 3,
      },
      isSortable: true,
      render: ({ data }) => <LinkToDetail targetID={data.id} label={data.email} />,
    },
    */
    {
      label: 'CATEGORY',
      name: 'cat_id',
      columnType: COL_TYPE.DATA,
      valueFormat: {
        type: FORMAT.BY_ID, // TODO: NO TS TYPE WARN ???????
        source: 'cat_id',
      },
      // width: 2,
      widths: {
        xs: 0,
        lg: 3,
      },
      isSortable: true,
      // render: ({ data }) => <LinkToDetail targetID={data.cat_id} label={data.cat_id} />,
      render: ({ data }) => {
        return <LinkToDetail targetID={data.cat_id} label={data.cat_id} />;
      },
    },
    {
      label: 'PROVINCE',
      name: 'province',
      columnType: COL_TYPE.DATA,
      // width: 2,
      widths: {
        xs: 0,
        lg: 2,
      },
      isSortable: true,
      render: ({ data }) => <LinkToDetail targetID={data.id} label={data.province} />,
    },
    {
      label: 'ID',
      name: 'id',
      columnType: COL_TYPE.DATA,
      // width: 2,
      widths: {
        xs: 0,
        lg: 1,
      },
      isSortable: true,
      render: ({ data }) => data.id,
    },
    // ======================================================================== //
    /*
    {
      label: 'UPPDATED',
      name: 'created_at',
      // width: 2,
      widths: {
        xs: 0,
        lg: 2,
      },
      isSortable: true,
      render: ({ data }) => formatDateLocal(data?.updated_at),
    },
    */
    // ======================================================================== //
    {
      label: 'VIEW',
      name: 'id',
      columnType: COL_TYPE.ACTION,
      // width: 2,
      widths: {
        xs: 0,
        lg: 1,
      },
      isSortable: false,
      render: ({ data }) => <ActionLink data={data} action={ROW_ACTION.VIEW} />,
    },
    {
      label: 'EDIT',
      name: 'id',
      columnType: COL_TYPE.ACTION,
      // width: 2,
      widths: {
        xs: 0,
        lg: 1,
      },
      isSortable: false,
      render: ({ data }) => <ActionLink data={data} action={ROW_ACTION.EDIT} />,
    },
  ],
};
