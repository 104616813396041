import { css } from '@emotion/react';
import { colors, layout, min } from 'styles';

export const styles = css`
  .row-preview {
    width: 100%;
    height: 100%;
    align-items: stretch;
    background: white;
    margin: calc(${layout.padding} * 0.33) 0 !important;
    padding: calc(${layout.padding} * 0.8) 0 !important;
    border-radius: calc(${layout.padding} * 0.33) !important;
    font-size: 1.1em;
    .col-action {
      padding: 0 !important;
      svg.faux-checkbox {
        display: flex;
        background: white;
        color: ${colors.primaryXXLight};
        transform: scale(1.75) translateX(calc(${layout.padding} * 0.5));
      }
      svg.faux-checkbox.is-clickable {
        color: ${colors.success};
        cursor: pointer;
        &:hover {
          transition: color 0.3s ease;
          color: ${colors.successXDark};
        }
      }
    }
    .col-name {
      color: ${colors.greyDark};
      -webkit-text-fill-color: ${colors.greyDark};
      font-weight: 700;
    }
    .col-location {
      color: ${colors.grey};
      -webkit-text-fill-color: ${colors.grey};
      font-weight: 500;
    }
  }
`;
