import { css } from '@emotion/react';

const imgWidth = 120;
const imgHeight = 100;

export const styles = css`
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  /*
  height: ${imgHeight}px;
  width: ${imgWidth}px;
  max-height: 100px;
  */
  margin: auto;
  img {
    /*max-height: ${imgHeight}px;*/
  }

  &.cover {
    background-size: cover;
    background-position: 50%;
    position: absolute;
    width: 80%;
    left: 10%;
    top: 0;
    img {
      opacity: 0;
    }
  }
`;
