import type { Dispatch, SetStateAction } from 'react';
import { useEffect } from 'react';
import { usePagination } from 'components/Pagination';
import { Tag } from 'components/Tag';
import { styles } from './DataFilters.css';

interface IDataFilters {
  filterTarget: string;
  filters: string[];
  setFilters: Dispatch<SetStateAction<string[]>>;
}

const DataFilters = ({ filterTarget, filters = [], setFilters }: IDataFilters) => {
  const { gotoFirstPage } = usePagination();

  const handleRemove = (filterToRemove: string) => {
    const newTagArray = filters.filter((filter) => filter !== filterToRemove);
    setFilters(newTagArray);
  };

  useEffect(() => {
    gotoFirstPage();
  }, [...filters]);

  return (
    <div css={styles}>
      {filters.length > 0 && (
        <label>
          filtering by <span>{filterTarget}:</span>
        </label>
      )}
      {filters.map((filter, index) => (
        <Tag label={filter} key={index} type="primary" handleRemove={handleRemove} />
      ))}
    </div>
  );
};

export { DataFilters };
