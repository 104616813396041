import type { DataEntry, DataConfigRole } from 'types';
import type { FormConfig } from 'types/form.types';
import type { FormSectionConfig } from 'components/FormSection/FormSection.types';
import type { DetailConfig } from 'components/DetailSummary';
import { getParsedJSON, isSet } from 'utils';
import { omitNull } from 'utils/utils.object';
import dataPostsLocal from 'mocks/data/posts_legacy.json';

export const matchLegacyPostData = <T>({ dataFetched }: { dataFetched: any }) => {
  const dataMatched = dataPostsLocal.find(
    (entry: typeof dataFetched) => Number(entry.id) === Number(dataFetched?.id),
  ); // T | IGeneric
  const dataMatchedJSON =
    dataMatched?.json_meta !== '{}' ? omitNull(getParsedJSON(dataMatched?.json_meta || {})) : {};

  const allKeysMatched = [...new Set([...Object.keys(dataMatched || {}), ...Object.keys(dataMatchedJSON)])];
  const dataMatchedMerged = {} as any;

  if (dataMatched) {
    for (const key of allKeysMatched) {
      if (['id', 'cat_id', 'json_meta'].includes(key)) continue;

      if (key === 'user_id' && 'user_id' in dataFetched) continue;

      if (key in dataMatched && isSet(dataMatched[key as keyof typeof dataMatched])) {
        dataMatchedMerged[key] = dataMatched[key as keyof typeof dataMatched];
        continue;
      }

      if (key in dataMatchedJSON && isSet(dataMatchedJSON[key as keyof typeof dataMatchedJSON])) {
        dataMatchedMerged[key] = dataMatchedJSON[key as keyof typeof dataMatchedJSON];
        continue;
      }
    }
  }

  return dataMatchedMerged;
};

// ======================================================================== //

export const getTimestampFromData = ({
  dataFetched,
  dataSource,
  key,
}: {
  dataFetched: DataEntry;
  dataSource: DataEntry | undefined;
  key: 'created_at' | 'updated_at';
}): number => {
  if (dataFetched[key]) {
    return Number(
      typeof dataFetched[key] === 'number'
        ? dataFetched[key]
        : new Date(dataFetched[key] as string).getTime(),
    );
  }
  if (dataSource?.[key]) {
    return Number(
      typeof dataSource[key] === 'number' ? dataSource[key] : new Date(dataSource[key] as string).getTime(),
    );
  }

  return new Date().getTime();
};

export const getBooleanFromData = ({
  dataFetched,
  dataSource,
  key,
}: {
  dataFetched: DataEntry;
  dataSource: DataEntry | undefined;
  key: string;
}): number => {
  if (typeof dataFetched[key] === 'number' || typeof dataFetched[key] === 'boolean') {
    return Number(dataFetched[key]);
  }
  if (typeof dataSource?.[key] === 'number' || typeof dataSource?.[key] === 'boolean') {
    return Number(dataSource[key]);
  }

  return 1;
};

// ======================================================================== //

export const assignFieldPrefixes = <T>({
  config,
  role,
}: {
  config: T | FormConfig | DetailConfig;
  role: DataConfigRole;
}) => {
  if (!config) return;

  const sections = (config as FormConfig | DetailConfig)?.sections;
  // const { sections } = cloneDeep(Object.assign({ sections: [] }, config));

  if (sections?.length) {
    for (const section of sections as FormSectionConfig[]) {
      log('DEV8: **** DEFAULT_CONFIG (PASSDED) >>>>>>>>', 'grey', section);
      if (section.fields?.length) {
        section.fields.forEach((field) => {
          Object.assign(field, { name: `${section.sectionKey}.${field.name}` });
          // field.name = `${section.sectionKey}.${field.name}`;
          log('DEV8: **** DEFAULT_CONFIG (PASSDED) >>>>>>>>', 'cyan', field);
        });
      }
    }
  }
};
