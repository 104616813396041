import { FormState, FieldValues } from 'react-hook-form';
import type { FieldConfig } from 'types/form.types';
import type { FormSectionConfig } from 'components/FormSection/FormSection.types';
import { DetailSection } from 'components/DetailSummary';
import { defaultColumnSizes } from 'components/Form';
import { hasErrorMinLength } from 'components/Form/config/form.validation';
import { getResponsiveColumnSizes } from 'utils';

export const getFieldWidth = ({ colWidth }: FieldConfig | FormSectionConfig | DetailSection) => {
  return {
    ...getResponsiveColumnSizes({
      defaultProps: defaultColumnSizes,
      customProps: { ...colWidth },
    }),
  };
};

export const handleErrors = ({
  field,
  methods: { formState, getFieldState },
}: {
  field: FieldConfig;
  methods: {
    formState: FormState<FieldValues>;
    getFieldState: any;
  };
}) => {
  // ERRORS + VALIDATION =================================================== //

  const { isSubmitted, submitCount, errors } = formState;
  const { error /* invalid, isDirty, isTouched, */ } = getFieldState(field.name);
  const hasError = !!error;
  if (!hasError) {
    return {
      hasWarning: false,
      hasError: false,
      errorProps: {
        fieldName: field.name,
        hasError: false,
        isErrorVisible: false,
      },
    };
  }

  const hasWarning = hasError && field.value?.length <= hasErrorMinLength;
  const hideRequiredError = error?.type === 'required' && submitCount === 0;
  const hidePatternError = error?.type === 'pattern' && submitCount === 0;
  const hideMinLengthError = error?.type === 'minLength' && submitCount === 0;

  if (error?.type === 'required' && hideRequiredError) {
    delete errors?.[field.name];
  }

  const setErrorVisible = () => {
    if (hasError) {
      if (!hideRequiredError) return true;
      if (!hidePatternError) return true;
      if (!hideMinLengthError) return true;
    }
    return false;
  };

  const isErrorVisible = setErrorVisible();
  const errorProps = {
    fieldName: field.name,
    hasError: true,
    errors,
    type: error?.type,
    message: error?.message,
    isErrorVisible,
    isSubmitted,
  };

  return {
    hasWarning,
    hasError,
    errorProps,
  };
};
