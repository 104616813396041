import { t } from 'i18next';

const isTraslationKey = (val: any): boolean => !!(typeof val === 'string' && val.startsWith('t.'));

const walk = (node: any) => {
  for (const key in node) {
    if (isTraslationKey(node[key])) {
      const translated = t(node[key]);
      node[key] = translated;
    } else if (Object.prototype.toString.call(node[key]) === '[object Object]') {
      walk(node[key]);
    } else if (Array.isArray(node[key])) {
      walk(node[key]);
    }
  }
  return node;
};

const i18nTranslateValues = <T>(val: T): any => {
  // 1. CHECK IF `val` IS STRING (dot notation) + SKIP OBJECT WALK...
  if (typeof val === 'string') {
    return isTraslationKey(val) ? t(val as string) : val;
  }
  // 2. PERFORM DEEP OBJECT WALK TO TRANSLATE ANY NESTED STRING VALUES...
  return walk(val);
};

export { i18nTranslateValues as translate };
