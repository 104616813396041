import authenticationClient from '@feathersjs/authentication-client';
import type { AuthenticationClientOptions } from '@feathersjs/authentication-client';
import { feathers } from '@feathersjs/feathers';
import type { TransportConnection, Application } from '@feathersjs/feathers';

export interface ServiceTypes {}

export type ClientConnection = TransportConnection<ServiceTypes>;

export interface Configuration {
  connection: ClientConnection;
}

export type ClientApplication = Application<ServiceTypes, Configuration>;

/**
 * Returns a TYPED client for a feathers app.
 *
 * @param connection The REST or Socket.io Feathers client connection
 * @param authOptions Additional settings for the authentication client
 * @see https://dove.feathersjs.com/api/client.html
 * @returns The Feathers client application
 */

export const createClient = <Configuration = any>({
  connection,
  authOptions = {},
}: {
  connection: ClientConnection;
  authOptions?: Partial<AuthenticationClientOptions>;
}) => {
  const client: ClientApplication = feathers();

  client.configure(connection);
  client.configure(authenticationClient(authOptions));
  client.set('connection', connection);

  return client;
};
