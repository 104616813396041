export enum CONTEXT_FIELDS {
  isDevToolsVisible = 'isDevToolsVisible',
  isScrollLocked = 'isScrollLocked',
  isSidebarOpen = 'isSidebarOpen',
  isNavbarVisible = 'isNavbarVisible',
  winWidth = 'winWidth',
  winHeight = 'winHeight',
  notify = 'notify',
  TEST = 'TEST',
}

export interface ContextValues {
  [CONTEXT_FIELDS.isDevToolsVisible]: boolean;
  [CONTEXT_FIELDS.isScrollLocked]: boolean;
  [CONTEXT_FIELDS.isSidebarOpen]: boolean;
  [CONTEXT_FIELDS.isNavbarVisible]: boolean;
  [CONTEXT_FIELDS.winWidth]: number | undefined;
  [CONTEXT_FIELDS.winHeight]: number | undefined;
  [CONTEXT_FIELDS.notify]: unknown;
  [CONTEXT_FIELDS.TEST]: number[];
}

export enum CONTEXT_ACTIONS {
  setContextState = 'setContextState', // TODO: FOR USE IN OTHER CONTEXTS
  setLayoutState = 'setLayoutState',
  notify = 'notify',
}

// ------------------------------------------------------------------------ //

const initValues: ContextValues = {
  [CONTEXT_FIELDS.isDevToolsVisible]: false,
  [CONTEXT_FIELDS.isScrollLocked]: false,
  [CONTEXT_FIELDS.isSidebarOpen]: false,
  [CONTEXT_FIELDS.isNavbarVisible]: true,
  [CONTEXT_FIELDS.winWidth]: undefined,
  [CONTEXT_FIELDS.winHeight]: undefined,
  [CONTEXT_FIELDS.notify]: null,
  [CONTEXT_FIELDS.TEST]: [],
};

const actions = {
  [CONTEXT_ACTIONS.setContextState]: () => ({}),
  [CONTEXT_ACTIONS.setLayoutState]: () => ({}),
};

// ------------------------------------------------------------------------ //

export default {
  ...initValues,
  ...actions,
};
