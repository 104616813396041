import { css } from '@emotion/react';
import Color from 'color';
import { colorsMap } from 'components/Map';
import { colors, forms, layout, max, min } from 'styles';
import { calendarDayColor } from 'styles/vendor.ui.css';

// react-datepicker__navigation
// react-datepicker__navigation--previous
// react-datepicker__navigation--next

export const colorAccents = {
  light: Color(colors.primaryXLight).lighten(0.6).hex(),
  med: Color(colors.primaryXLight).lighten(0.4).hex(),
  dark: Color(colors.primaryXLight).darken(0.2).hex(),
};

export const styles = css`
  // ====================================================================== //

  padding: 0 !important;
  padding: calc(${layout.padding} * 2) 0 !important;

  .filter-card {
    /* padding: 0; */
    border: none !important;
    box-shadow: none !important;
  }

  .card-filters-group {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    background: white;
  }

  // DUAL CALENDAR...
  .col-date {
    display: flex;
    justify-content: center;
    background: white !important;
    box-shadow: inset 0 82px 0 0 #e6e6e6;

    ${min.xs} {
      /* padding: 0 calc(${layout.padding} * 1) !important; */
    }
    ${max.sm} {
      padding: 0 !important;
      /* background: white; */
      box-shadow: inset 0px 64px 0px 0 #e6e6e6;
    }
    ${min.md} {
      /* background: white; */
      box-shadow: inset 0px 82px 0px 0 #e6e6e6;
    }
    ${min.lg} {
      background: transparent !important;
      box-shadow: none;
      padding: 0 calc(${layout.padding} * 1) !important;
      justify-content: flex-start;
    }
    ${min.xl} {
      /* padding-right: calc(${layout.padding} * 0) !important; */
    }
  }

  // MAP COLUMN...
  .col-map {
    height: auto;
    align-items: stretch;
    justify-content: stretch;
    display: flex;
    height: unset;

    .card-filters {
      background: transparent;
    }

    .card-filters {
      padding: 0;
      width: 100%;
      box-shadow: none;

      .svg-map-spain {
        max-height: 320px;

        transform: scale(1.8) translate(66%, 0%);

        ${min.sm} {
          height: 320px;
        }

        ${min.md} {
          height: 390px;
          transform: scale(1.2) translate(100%, 12%);
        }

        ${min.lg} {
          transform: scale(1.6) translate(30%, -2%);
        }

        ${'@media (min-width: 1050px)'} {
          height: 290px;
        }

        ${'@media (min-width: 1100px)'} {
          height: 300px;
        }

        ${'@media (min-width: 1150px)'} {
          height: 310px;
        }

        ${'@media (min-width: 1200px)'} {
          transform: scale(1.7) translate(55%, -2%);
          height: 275px;
        }

        ${'@media (min-width: 1250px)'} {
          height: 290px;
        }

        ${'@media (min-width: 1300px)'} {
          height: 300px;
        }

        ${'@media (min-width: 1350px)'} {
          height: 310px;
        }

        ${'@media (min-width: 1400px)'} {
          height: 350px;
        }

        ${'@media (min-width: 1450px)'} {
          height: 320px;
        }

        ${'@media (min-width: 1500px)'} {
          height: 340px;
        }

        ${'@media (min-width: 1600px)'} {
          transform: scale(1.8) translate(66%, -2%);
          height: 350px;
        }
      }
    }
  }

  .col-search {
    height: auto;
    align-items: stretch;
    justify-content: stretch;
    display: flex;
    height: unset;

    ${min.xs} {
      order: 2 !important;
    }

    ${min.lg} {
      order: 1;
    }
  }

  .col-filters {
    height: auto;
    align-items: stretch;
    justify-content: stretch;
    display: flex;
    height: unset;

    ${min.xs} {
      order: 0;
      padding: calc(${layout.padding} * 1) calc(${layout.padding} * 2) 0;
    }
    ${min.lg} {
      padding: 0;
      order: 2;
    }
  }

  fieldset.fieldset-filter {
    width: 100%;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    .card.filter-card {
      padding: 0;
    }
  }

  fieldset.calendar-dual {
    // RESPONSIVELY SCALED WRAPPER for: react-date-picker ================= //

    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    max-width: fit-content;
    height: 275px;

    & > div {
      width: 100%;
    }
    transform: scale(1) translate(0%, 0%);

    .react-datepicker {
      background: transparent;
      border: none;
      margin: auto;
      width: 100%;
      height: 280px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-around;
      justify-content: space-evenly;

      &__header {
        background: transparent;
        border-bottom: 1px solid transparent;
      }

      &__month-container {
        /* width: 100%; */
        /* height: 275px; */
        /* transform: translateX(54px) translateY(62px) scale(1.45); */
      }
      &__header {
        /* width: 101%; */
        background: ${colors.greyXLight};
        border-radius: 0 !important;
      }
      &__current-month {
        /* month header title */
        color: ${colors.greyXDark};
        font-size: 1.15em;
        height: 24px;
        line-height: 24px;
      }
      &__month {
        transform: translateX(2px);
        /* transform: translateX(50px) translateY(60px) scale(1.48); */
      }

      &--selected {
        ${calendarDayColor(colorsMap.selected, colors.successXDark, true)}
      }

      /*** HIDE NAV BUTTONS - HANDLED EXTERANLLY fr this Widget ***/
      &__navigation {
        /* display: none !important; */
        transform: translate(0%, 10%) !important;
        /* padding: 13px 0 0 !important;
      height: 40px !important; */
      }
      &__navigation-icon {
        &:before {
          top: 5px;
        }
      }
      &__navigation--previous {
        /* margin-left: 8px; */
      }
      &__navigation--next {
        /* margin-right: 8px; */
      }
    }

    ${min.sm} {
      transform: scale(1.16) translate(0%, 9%);
      width: 100%;
      max-width: 80vw;
      height: 320px;

      .react-datepicker {
        margin: auto;
        width: 100%;
      }
    }

    ${min.md} {
      transform: scale(1.2) translate(0%, 9%);
      width: 100%;
      max-width: 80vw;
      height: 290px;
    }

    ${min.lg} {
      & > div {
        width: unset;
      }
      .react-datepicker {
        justify-content: flex-start;
        background: white !important;
        &__header {
          background: #e6e6e6;
          border-bottom: 1px solid #aeaeae;
        }
      }
      /* BEGIN RESPONNSIVE SCALING... */
      transform: scale(0.98) translate(-2%, -2%);
    }

    ${'@media (min-width: 1050px)'} {
      transform: scale(1.04) translate(1%, 2%);
      height: 290px;
    }

    ${'@media (min-width: 1100px)'} {
      transform: scale(1.08) translate(3%, 3%);
      height: 300px;
    }

    ${'@media (min-width: 1150px)'} {
      transform: scale(1.14) translate(5.5%, 5.5%);
      height: 310px;
    }

    ${'@media (min-width: 1200px)'} {
      transform: scale(0.95) translate(-3%, -3%);
      height: 275px;
    }

    ${'@media (min-width: 1250px)'} {
      transform: scale(1) translate(0%, 0%);
      height: 290px;
    }

    ${'@media (min-width: 1300px)'} {
      transform: scale(1.05) translate(2%, 2%);
      height: 300px;
    }

    ${'@media (min-width: 1350px)'} {
      transform: scale(1.07) translate(3%, 3%);
      height: 310px;
    }

    ${'@media (min-width: 1400px)'} {
      transform: scale(1.12) translate(5%, 5%);
      height: 350px;
    }

    ${'@media (min-width: 1450px)'} {
      transform: scale(1.15) translate(6%, 6%);
      height: 320px;
    }

    ${'@media (min-width: 1500px)'} {
      transform: scale(1.22) translate(9%, 9%);
      height: 340px;
    }

    ${'@media (min-width: 1600px)'} {
      transform: scale(1.25) translate(10%, 10%);
      height: 350px;
    }
  }

  // react-date-picker ==================================================== //

  .react-datepicker___DELME___ {
    display: flex;
    flex-wrap: nowrap;
    height: 280px;
    border: none;

    &__month-container {
      /* width: 100%; */
      /* height: 275px; */
      /* transform: translateX(54px) translateY(62px) scale(1.45); */
    }
    &__header {
      /* width: 101%; */
      background: ${colors.greyXLight};
      border-radius: 0 !important;
    }
    &__current-month {
      /* month header title */
      color: ${colors.greyXDark};
      font-size: 1.15em;
      height: 24px;
      line-height: 24px;
    }
    &__month {
      transform: translateX(2px);
      /* transform: translateX(50px) translateY(60px) scale(1.48); */
    }

    /*** HIDE NAV BUTTONS - HANDLED EXTERANLLY fr this Widget ***/
    &__navigation {
      /* display: none !important; */
      transform: translate(0%, 10%) !important;
      /* padding: 13px 0 0 !important;
      height: 40px !important; */
    }
    &__navigation-icon {
      &:before {
        top: 5px;
      }
    }
    &__navigation--previous {
      /* margin-left: 8px; */
    }
    &__navigation--next {
      /* margin-right: 8px; */
    }
  }

  // DUAL CALENDAR... // NOTE: NOTE CURRENTLY USED - NOT WORKING CORRECTLY

  .react-datepicker__header--custom {
    height: 68px !important;
    /* padding: ${layout.padding} 0 calc(${layout.padding} * 1.2); */
    padding-top: 0;
    .col-daterange {
      display: flex;
      align-items: center;
    }
    /***** CUSTOM CALENDAR  BUTTONS *****/

    .col-daterange-btn-prev,
    .col-daterange-btn-next {
      padding: 0 !important;
      position: absolute !important;
      z-index: 1;
    }
    .col-daterange-btn-prev {
      justify-content: flex-start;
      transform: translate(1.5%, 9%);
    }
    .col-daterange-btn-next {
      justify-content: flex-end;
      transform: translate(-1.5%, 9%);
    }
    .daterange-btn {
      margin: 0.25em;
    }
    /***** CUSTOM CALENDAR HEADER-TITLE *****/
    .col-daterange-title {
      justify-content: center;
      height: 1.9em;
      font-size: 1.25em;
      font-weight: 700;
      color: ${colors.greyXDark};
      padding: calc(${layout.padding} * 0.66) 0;
      padding: 0;
    }

    .col-daterange-title,
    .react-datepicker__day-names {
      transform: translateY(6px);
    }
  }

  // WRAPPERS =============================================================== //

  .map-wrapper {
    height: 482px;
    overflow: hidden;
    padding: 0 100px 120px 100px !important;
  }

  // ======================================================================== //
  .summary {
    .date {
      text-transform: capitalize;
    }
  }

  #province.input-select {
    width: 100%;
    input {
      min-height: 40px;
    }
    .select__menu-list > div {
      /* Select Options */
      padding: 0.33em 0.66em;
      &:hover {
        background: ${colorAccents.light};
      }
      .select__option:hover {
        background: ${colors.successLight};
      }
    }
    .select__control {
      font-weight: 600;
      padding: 0 0.5em;
    }
  }

  input.event-date-input {
    margin-bottom: calc(${layout.padding} * 1.5);
    display: block;
    text-transform: capitalize;
    &[value=''] {
      text-transform: uppercase;
    }
  }

  // ======================================================================== //

  .col-province {
    /* background: rgba(255, 255, 0, 0.5); */
  }

  .col-map {
    /* display: contents; */
    /* border: 1px solid silver; */
  }

  .svg-map-hover-detail {
    font-weight: 600;
    font-size: 2em;
    color: ${colors.primary};
    height: 120px;
  }

  .svg-map-spain {
    opacity: 1;
    z-index: 5;
    .svg-map__location {
      fill: ${colorsMap.bg}!important;
      stroke: ${colorsMap.borders};
      opacity: 1;
      cursor: default;
    }
    [id*='--selected'] {
      fill: ${colorsMap.selected}!important;
    }
  }
`;
