import { css } from '@emotion/react';
import { layout } from 'styles/layout.css';
import { colors } from 'styles/colors.css';

export const styles = css`
  button {
    margin: 0 !important;
    font-size: 1.33em;
    height: 60px;
  }
  .tabbed-nav {
    min-height: 40px;
    display: flex;
    justify-content: stretch;
    padding: 0 !important;
  }

  .tabbed-content {
    min-height: 200px;
    padding: 0 !important;
  }

  .tab {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0.66em 1em;
    font-size: 1.15em !important;
    font-weight: 700;
    color: ${colors.greyLight};
    border: 0;
    border-bottom: calc(${layout.borderWidth}*2);
    border-color: ${colors.greyXLight};
    border-style: solid;
    cursor: pointer;
    transition: all 300ms ease;
    margin: 0 2px;
    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }

    &.selected {
      pointer-events: none !important;
      color: ${colors.greyDark}!important;
      border-color: ${colors.grey}!important;
    }

    &:not(.selected):hover {
      opacity: 0.66;
    }

    /* ================================================ */

    &,
    &.tab-default {
      &:hover {
        color: ${colors.greyDark}!important;
        border-color: ${colors.grey}!important;
      }
    }

    &.tab-info {
      &:hover {
        color: ${colors.infoLight}!important;
        border-color: ${colors.infoXXLight}!important;
      }
    }

    &.tab-success {
      &:hover {
        color: ${colors.successXDark}!important;
        border-color: ${colors.successLight}!important;
      }
    }

    &.tab-warning {
      &:hover {
        color: ${colors.warningDark}!important;
        border-color: ${colors.warningXLight}!important;
      }
    }

    &.tab-error,
    &.tab-danger {
      &:hover {
        color: ${colors.danger}!important;
        border-color: ${colors.dangerXLight}!important;
      }
    }
  }
`;
