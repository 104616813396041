import { useMutation, QueryClient } from '@tanstack/react-query';
import type { UseQueryOptions, UseMutationResult } from '@tanstack/react-query';
import { api } from 'api';
import { sleep } from 'utils';
import { QueryOptions, UseQueryOptions__DEPRECATED, QueryParams } from './query';

interface UseCreateNew extends Partial<UseQueryOptions>, UseQueryOptions__DEPRECATED, QueryOptions {
  mutationKey?: Array<unknown>;
  endpoint: string;
}

const LIST_QUERY_KEY = ['getList'];
const CREATE_QUERY_KEY = 'createNew';

export const useCreateNew = ({
  endpoint,
  mutationKey,
  onSuccess: onSuccessHandler,
  onError,
}: UseCreateNew): UseMutationResult<Readonly<any>, unknown> => {
  const queryClient = new QueryClient();
  mutationKey ??= ['createNew', { endpoint }];

  return useMutation({
    mutationKey,
    mutationFn: async (data: any) => {
      const response = await api.post(endpoint, data);
      await sleep(100);

      log('[POST]', 'orange', { status: response.status, statusText: response.statusText }, response.data);
      log('--------------------------------------------------------------', 'grey');

      return response.data;
    },
    onSuccess: (data: any) => {
      queryClient.invalidateQueries({ queryKey: LIST_QUERY_KEY });
      onSuccessHandler?.(data);
    },
    onError: (error: unknown) => {
      error instanceof Error ? console.error(error.message) : console.error(String(error));
    },
  });
};
