import { Col, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { MdAddCircleOutline as IconNew } from 'react-icons/md';

import { ButtonLink } from 'components/Button';
import { DataFilters as FilterCategory } from 'components/DataFilters';
import { DataSearch as SearchName } from 'components/DataSearch';
import { RegisterToolbar } from 'components/RegisterToolbar';
import { styles } from './PageToolbar.css';

import { getButtonColors } from 'components/Button/getButtonStyles';

import { useRouter } from 'routes/hooks';

// TODO: interface missing some required props
interface PageToolbarProps {
  filters: any;
  setFilters: Dispatch<SetStateAction<string[]>>;
  hasActionNew: boolean;
}

export const PageToolbar = ({
  data,
  results,
  setResults,
  filters,
  setFilters,
  hasActionNew = false,
  dataConfiguration,
}: any) => {
  const { t } = useTranslation();
  const { basePath, isAdminPath, category, slug } = useRouter();
  const [searchQuery, setSearchQuery] = useState();

  const isRegistrationPage = !!(slug === 'registros');
  const isVisibleNewButton = !!(isAdminPath && hasActionNew);

  const placeholderSubject = String(
    isRegistrationPage ? 'mis registros' : category ? category?.parent?.title || category?.title : '',
  ).toLowerCase();

  const searchConfig = dataConfiguration?.list?.search;

  return (
    <section css={styles}>
      {isVisibleNewButton && isRegistrationPage && (
        <Row className="tool-bar" style={{ transform: 'translateY(-20%)' }}>
          <Col xs={12} className="col-new">
            <RegisterToolbar horizontal />
          </Col>
        </Row>
      )}
      <Row className="tool-bar">
        {searchConfig?.filterByKeys && (
          <Col xs={12} md={8} lg={6} className="col-search">
            <SearchName
              filterByKeys={searchConfig?.filterByKeys ?? ['name']}
              data={data}
              searchResults={results}
              setSearchResults={setResults}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              placeholder={t('t.search.placeholder', { subject: placeholderSubject })}
            />
          </Col>
        )}
        <Col {...(searchConfig ? { xs: 12, md: 4, lg: 6 } : { xs: 12 })} className="col-new">
          <FilterCategory filterTarget="type" filters={filters} setFilters={setFilters} />
          {isVisibleNewButton && !isRegistrationPage && (
            <ButtonLink
              to={`${basePath}/new`}
              state={{ from: basePath, action: 'NEW' }}
              icon={<IconNew />}
              iconPos="left"
              label="NEW"
              color="successXDark"
            />
          )}
        </Col>
      </Row>
    </section>
  );
};
