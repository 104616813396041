import { NavLink } from 'react-router-dom';
import { useRouter } from 'routes/hooks';
import { ROW_ACTION, ValueFormat } from '../DataTable.types';

export const LinkToDetail = ({
  targetID,
  label,
  valueFormat,
  action = ROW_ACTION.VIEW,
}: {
  targetID: number | string;
  label: string | number;
  valueFormat?: ValueFormat;
  action?: ROW_ACTION;
}) => {
  const { basePath } = useRouter();

  return <NavLink to={`${basePath}/${action}/${targetID}`}>{label}</NavLink>;
};
