/* eslint-disable no-undef */
import React, { ReactElement, useEffect, useState } from 'react';
import { Wrapper as GoogleMapWrapper, Status } from '@googlemaps/react-wrapper';
import { GoogleMap } from '@react-google-maps/api';
import { Spinner } from 'components/Spinner';
import type { MapCoords } from './MapGoogle.types';
import { MapMarker } from './MapMarker';
import { getCoordsFromAddress } from './utils/getCoordsFromAddress';

// ======================================================================== //
// GOOGLE MAPS OPTIONS
// NOTE: PRICING...
// https://mapsplatform.google.com/pricing/

const { VITE_GOOGLE_MAPS_API_KEY: GOOGLE_MAPS_API_KEY } = import.meta.env;
const MAP_ID = 'dc826fac70425a5d'; // NOTE: FOR CUSTOM STYLING
// const MAP_ID = '4e1bb97aa4fd6da9'; // NOTE: FOR CUSTOM STYLING

const GOOGLE_MAPS_OPTIONS = {
  mapId: MAP_ID,
  zoom: 14,
  // disableDefaultUI: false,
  // MAP UI CONTROLS:
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: true,
  keyboardShortcuts: false,
  gestureHandling: 'none', // DISABLE DRAGGING
};

const containerStyle = {
  width: '100%',
  height: '400px',
};

const render = (status: Status): ReactElement => {
  if (status === Status.LOADING) return <Spinner />;
  if (status === Status.FAILURE) return <Spinner />;
  return <></>;
};

// ========================================================================== //

const MapGoogle = ({ location, marker }: { location: string[]; marker?: string }) => {
  const [coords, setCoords] = useState<MapCoords>();

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const initialize = async ({ location }: { location: string[] }) => {
    const { lat, lng } = await getCoordsFromAddress({ location });
    setCoords({ lat, lng } as MapCoords);
  };

  useEffect(() => {
    initialize({ location });
  }, []);

  if (!coords) return <Spinner />;

  return (
    <GoogleMapWrapper apiKey={GOOGLE_MAPS_API_KEY} render={render}>
      <GoogleMap mapContainerStyle={containerStyle} center={coords} options={GOOGLE_MAPS_OPTIONS}>
        {/* Child components, such as markers, info windows, etc. */}
        <>{marker && <MapMarker key={marker} text={marker} lat={coords.lat} lng={coords.lng} />}</>
      </GoogleMap>
    </GoogleMapWrapper>
  );
};

export default React.memo(MapGoogle);
