// @ts-nocheck
// @ts-ignore
import type { ODataQuery } from 'api/query/odata.types';
import { ODataQueryParams, ODataQueryOperators } from 'api/query/odata.types';
import type {
  QueryStandard,
  QueryLikeValue,
  QueryWithOperator,
  QueryMulti,
  QueryEquality,
  QueryMatch,
  QueryLessThan,
  QueryGreaterThan,
  QueryLike,
  QueryFilters,
  SortDirection,
} from 'api/query/query.filters';
import { Filters, Operators, OperatorsKnex } from 'api/query/query.filters';
import type {
  QueryError,
  QueryParams,
  QueryOptions,
  UseQueryGetProps,
  UseQueryOptions__DEPRECATED,
} from 'api/query/query.types';
import { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { ACTIONS, METADATA, type DataConfigRole, type DataEntry, type FormConfig } from 'types';
import { AUTH_USER_PLACEHOLDER } from 'types/form.types';
import type { CatMeta } from 'types/metadata.types';
import { useGetMetadata } from 'api/useGetMetaData_THIS';
import type { AuthUser } from 'auth/types/user.types';
import { useGlobal } from 'store/useGlobalContext';
import { useAuth } from 'auth/AuthContext';
import { detailConfig } from 'config/datatype/canales';
import { formConfig } from 'pages/AdminCategories/config';
import type { RouteConfig } from 'routes/routes.types';
import type { DataConfigurationType } from 'types/dataConfig.types';
import { useRouter } from 'routes/hooks';
import { set } from 'lodash';

export const useQueryBuilder = ({ config }: { config: DataConfigurationType } = {}): any => {
  const [query, setQuery] = useState<QueryStandard>({});
  const { params, route, location, category, basePath, pathname, action } = useRouter();
  const { user } = useAuth();

  /*
  const role: DataConfigRole = params.action === ACTIONS.NEW || params.action === ACTIONS.EDIT ? 'form' : 'detail';
  const config = (formConfig ?? detailConfig) as FormConfig;
*/
  const { categories } = useGlobal('categories');
  const { metaCats } = useGlobal('metaCats');

  const endpoint = config?.api?.endpoint || 'posts';

  // const query: QueryStandard = {};

  if (config?.api?.query) {
    Object.entries(config.api.query).forEach(([key, value]) => {
      if (AUTH_USER_PLACEHOLDER.includes(value)) {
        const userPropKey = value.replace('AUTH_USER_', '').toLowerCase() as keyof AuthUser;
        config.api.query[key] = user[userPropKey];
      }
    });

    Object.assign(query, config.api.query);
    return { query, endpoint, action };
  }

  // GROUP-CATEGORY (parent) - get all children categories:
  if (category && !category.parent && action !== ACTIONS.NEW) {
    const catIds = metaCats
      .filter((cat: CatMeta) => String(cat?.parent?.slug) === category.slug)
      .map((cat: CatMeta) => cat.uuid);
    const queryIDs: QueryWithOperator = { cat_id: { $in: [...catIds] } };
    Object.assign(query, { ...queryIDs });
  }

  // CATEGORY:
  if (category && !!category?.parent) {
    Object.assign(query, { cat_id: category.uuid });
  }

  if (params?.id) {
    Object.assign(query, { id: params?.id });
  }

  if (pathname.endsWith('/new') || pathname.endsWith('/register')) {
    // paramsCustom.new = true;
    // delete paramsCustom.id;
  }

  return {
    query,
    endpoint,
    action,
  };
};
