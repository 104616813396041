import type { DefaultValues, FieldValues } from 'react-hook-form';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import { matchSorter } from 'match-sorter';
import type { ISchema, DataEntry, IGeneric } from 'types';
import type { FormButton } from 'components/Form/components/FormButtonBar/FormButtonBar.types';
import type { FormConfig, FieldConfig } from 'types/form.types';
import type { FormSectionConfig } from 'components/FormSection/FormSection.types';
import {
  initDefaultValues,
  flattenFieldConfigs,
  mergeFieldConfigsByKey,
  getDataExpandedFromMetaJSON,
  translate,
} from 'utils';
import { SORT_BY_KEYS } from '../config';
import { setFieldGlobalDefaults } from './form.utils';
import { getConfiguredFields } from './form.utils.config';
import { getFieldsPropsFromSchemaMatch, getSchemaFieldDefinitions } from './form.utils.schema';
import { INPUTS } from 'types/form.types';

// TODO: make NEW Interface: DataViewConfig

export const getFormSections = ({
  fields,
  formConfig,
}: {
  fields: FieldConfig[];
  formConfig: FormConfig;
}): { formSections: FormSectionConfig[] } => {
  const formSections = formConfig.sections.map((section) => {
    const sectionFields: string[] = section.fields.map(({ name }) => name);
    const fieldsConfiguration: FieldConfig[] = fields.filter(({ name }: FieldConfig) => sectionFields.includes(name));
    return {
      ...section,
      fields: fieldsConfiguration,
    };
  });

  return { formSections };
};

// ======================================================================== //
// NOTE:

/*
export const getFromSCHEMA = ({
  defaultValues,
  formConfig,
  schema = {},
}: {
  schema?: ISchema;
  defaultValues: IGeneric;
  formConfig: FormConfig;
}): { fields: FieldConfig[]; values: DefaultValues<typeof defaultValues> } => {
const fieldsArrayFromSchema = matchSorter(getFieldsPropsFromSchemaMatch({ schema }), 'A', { keys: [...SORT_BY_KEYS] });
return fieldsArrayFromSchema;
}
*/

// ====================================================================== //
// INIT FORM WITH INITIAL DEFAULT VALUES

export const getFormFieldsConfigured = ({
  defaultValues,
  formConfig,
  schema = {},
}: {
  defaultValues: IGeneric;
  formConfig: FormConfig;
  schema?: ISchema;
}): { fields: FieldConfig[]; values: DefaultValues<typeof defaultValues> } => {
  // 1. FIELDS ARRAY with SECTIONS FLATTENED
  const fieldsFlattened = flattenFieldConfigs(formConfig.sections);
  // 2. SET GLOBAL OVERRIDES etc..
  const fieldsArrayWithGlobalDefaults = setFieldGlobalDefaults(fieldsFlattened);
  // 3. FIELDS ARRAY FROM CONFIG
  const fieldsArrayConfigured = getConfiguredFields(fieldsArrayWithGlobalDefaults);
  // 4. FIELDS ARRAY FROM SCHEMA
  const schemaFields = getSchemaFieldDefinitions({ schema });
  const schemaFieldsConfigured = getFieldsPropsFromSchemaMatch({ schemaFields });
  const fieldsArrayFromSchema = matchSorter(schemaFieldsConfigured, 'A', {
    keys: [...SORT_BY_KEYS],
  });

  // 5. MERGE ABOVE 2 CONFIGS INTO 1 SINGLE CONFIG !!
  // TODO: ORDER OF CONFIG ARRAY TO ENSURE DESIRED MERGE
  const fieldsArrayMerged = mergeFieldConfigsByKey(fieldsArrayFromSchema, fieldsArrayConfigured, { byKey: 'name' });
  for (const field of fieldsArrayMerged) {
    field.value = defaultValues[field.name];
  }

  // ======================================================================== //

  // TODO: MOVE THIS INTO OWN UTL !!!!
  const fieldsFinal = sortBy(cloneDeep(fieldsArrayMerged), SORT_BY_KEYS);
  const values = {} as DefaultValues<typeof defaultValues>;

  for (const field of fieldsFinal) {
    switch (field.inputType) {
      case INPUTS.checkbox:
      case INPUTS.switch:
        Object.assign(values, { [field.name]: field.value === undefined ? false : field.value });
        break;
      case INPUTS.text:
      case INPUTS.email:
      case INPUTS.textarea:
      case INPUTS.text_rich:
      case INPUTS.select:
      case INPUTS.selectCategory:
      case INPUTS.selectCountry:
        Object.assign(values, { [field.name]: field.value === undefined ? '' : field.value });
        break;
      default:
        Object.assign(values, { [field.name]: field.value });
        break;
    }
  }

  return { fields: fieldsFinal, values };
};

// ====================================================================== //

// USE-EFFECT #3 - CONFIGURE INITIAL DATA + SCHEMA (STAYS HERE !!)
export const initFieldsConfig = <T>({
  formConfig,
  data,
  schema = {}, // defaultValues,
}: {
  formConfig: FormConfig;
  schema?: ISchema;
  data?: DataEntry;
  // defaultValues: DefaultValues<T>;
}): { fields: FieldConfig[]; formSections: FormSectionConfig[]; values: DefaultValues<typeof defaultValues> } => {
  const { defaultValues } = initDefaultValues({ config: formConfig, data });
  // log('FORM_DEFAULTS', 'grey', defaultValues);
  const { fields, values } = getFormFieldsConfigured({ defaultValues, formConfig, schema });
  const { formSections } = getFormSections({ fields, formConfig });
  // log('FORM_DEFAULTS', 'yellow', values);

  return { fields, formSections, values };
};
