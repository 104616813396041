import { css } from '@emotion/react';
import { layout } from 'styles/layout.css';
import { colors } from 'styles/colors.css';

export const styles = css`
  &.alert {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${layout.padding} calc(${layout.padding}) calc(${layout.padding});
    text-align: center;
    min-height: 4em;
    border-width: ${layout.borderWidth};
    border-style: solid;
    svg {
      font-size: 5em !important;
      padding: calc(${layout.padding} * 1) calc(${layout.padding}) calc(${layout.padding} * 1) !important;
      opacity: 0.85;
    }
    h4 {
      font-size: 1.66em;
      font-weight: 700 !important;
      text-align: center;
      margin: calc(${layout.padding} / 4);
    }
    p {
      margin: calc(${layout.padding} / 4);
      font-size: 1em;
      font-weight: 600;
      line-height: 1.2;
    }
  }

  &,
  &.alert-default {
    background-color: ${colors.greyXXLight};
    border-color: ${colors.greyXXLight};
    &,
    * {
      color: ${colors.greyDark};
    }
  }

  &.alert-info {
    background-color: ${colors.infoXXLight};
    border-color: ${colors.infoXXLight};
    &,
    * {
      color: ${colors.infoXDark};
    }
  }

  &.alert-success {
    background-color: ${colors.successLight};
    border-color: ${colors.successLight};
    &,
    * {
      color: ${colors.successXXDark};
    }
  }

  &.alert-warning {
    background-color: ${colors.warningXXLight};
    border-color: ${colors.warningXXLight};
    &,
    * {
      color: ${colors.warningXDark};
    }
  }

  &.alert-error,
  &.alert-danger {
    background-color: ${colors.dangerXLight};
    border-color: ${colors.dangerXLight};
    &,
    * {
      color: ${colors.dangerXDark};
    }
  }

  .spinner {
    margin: calc(${layout.padding}*1) 0;
  }
`;
