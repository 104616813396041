import { Col, Row, Visible } from 'react-grid-system';
import { useNavigateState } from 'hooks/useNavigateState';
import { ReactElement, useState } from 'react';
import { format } from 'date-fns';
import type { TableConfig, Action } from 'types';
import { useRouter } from 'routes/hooks';
import { useGlobal } from 'store/useGlobalContext';
import { DATE_FORMAT } from 'i18n';
import { CalendarDay } from 'components/CalendarDay';
import { Socials } from 'components/Socials';
import { getImageURL } from 'utils';
import { ArrayJSX } from 'utils/ArrayJSX';
import { getFakerImageURLv3 } from 'mocks/generators';
import { ES } from 'config';
import { SPAIN_PROVINCES } from 'constants/spain';
import { styles } from './EventCard.css';

// LOCALE
// import es from 'date-fns/locale/es';

// const InfoRow = ({ key, value }: { key: string; value: string | number }) => (
//   <div className="info-row">
//     <td>
//       <label>{key}</label>
//     </td>
//     <td>{value}</td>
//   </div>
// );

const InfoRow = ({ label, value }: { label: string; value: string }) => {
  if (!value) return null;
  return (
    <div className="info-row">
      <label>{label}:</label>
      {value}
    </div>
  );
};

const EXAMPLE_DATA = {
  id: 1,
  uuid: null,
  name: 'Matías, Morales and Aranda',
  event_type: 'Tipo A',
  date: null,
  time_start: null,
  time_end: null,
  location: 'My House',
  city: 'Sant Antoni',
  country: 'Spain',
  province: 'albacete',
  description: null,
  url: 'https://isabel.com',
  image: 'https://source.unsplash.com/category/interiors/800x480',
  image_id: null,
  user_id: '123e4567-e89b-12d3-a456-426614174000',
  json_meta:
    '{"uuid":null,"name":"Matías, Morales and Aranda","event_type":"Tipo A","date":null,"time_start":null,"time_end":null,"location":"My House","city":"Sant Antoni","country":"Spain","province":"albacete","description":null,"url":"https://isabel.com","image":"https://source.unsplash.com/category/interiors/800x480","image_id":null,"user_id":"123e4567-e89b-12d3-a456-426614174000","is_active":1,"created_at":"2021-07-17 17:51:49.927 +00:00","updated_at":"2022-09-12 00:25:41.845 +00:00","activities":"","email_public":[{"uuid":"e62104f8-bb68-486b-adba-23e08d12f406","name":"user@abc.com"},{"uuid":"e62104f8-bb68-486b-adba-23e08d12f406","name":"admin@abc.com"}],"tel_public":[]}',
  is_active: 1,
  created_at: '2021-07-17 17:51:49.927 +00:00',
  updated_at: '2022-09-12 05:30:29.541 +00:00',
};

type DataListItemProps = {
  data: any;
  single?: boolean;
  config: TableConfig;
  index: number;
};

export const EventCard = ({ data, config, single = false, index }: DataListItemProps): ReactElement => {
  const { params, route } = useRouter();
  const { navigate } = useNavigateState();
  // const imageSrc = getImageURL(data);
  const imageSrc = getFakerImageURLv3();

  const isEven: boolean = index % 2 == 0;
  const listItemConfig = config.list?.listItem;

  // const [imageSrc, setImageSrc] = useState(getFakerImageURLv3());
  const [hasImageError, setHasImageError] = useState(false);

  const pathname = location.pathname;
  const isAdminPath = !!pathname.startsWith('/admin');
  const isCatPath = !!location.pathname.startsWith('/cat/');
  const isExternalURL = !!data?.url?.startsWith('http');

  const handleClick = () => {
    if (route?.path) {
      navigate(`/${route?.path}/view/${data.id}`, { state: { action: 'VIEW' } });
      return;
    }
    if (data.basePath) {
      navigate(`/${data.basePath}/view/${data.id}`, { state: { action: 'VIEW' } });
      return;
    }
    if (data.path) {
      navigate(data.path);
      return;
    }
    if (isExternalURL) {
      const newWindow = window?.open(data.url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
      return;
    }
    if (isCatPath) {
      const state = {};
      !isAdminPath && Object.assign(state, { action: 'VIEW' });
      data?.path && navigate(data.path, { state });
      return;
    }
    navigate(data.url);
  };

  // CSS CLASSES
  const cssClasses = new ArrayJSX('card');
  cssClasses.push(`item-${isEven ? 'even' : 'odd'}`);
  single && cssClasses.push('single');

  // const bgImage = data.image ? { backgroundImage: `url(${imageSrc})` } : null;
  const bgImage = { backgroundImage: `url(${imageSrc}), url(${getFakerImageURLv3()})` };

  const date = new Date(data.date) || new Date();
  // log('DATE >>', 'red', typeof date, date);
  // const eventDate = data.date ? new Intl.DateTimeFormat('es-ES', { dateStyle: 'medium' }).format(date) : '';
  const eventDate = data.date ? format(date, DATE_FORMAT.DEFAULT, { locale: ES }) : '';

  // log('DATE_TYPE', 'cyan', data.date, typeof data.date);
  // log('DEV: DATE_TYPE - EventCard', 'grey', data);

  /*
  Specify default date formatting for language (locale)
  console.log(new Intl.DateTimeFormat('en-US').format(date));
  // expected output: "12/20/2020"

  // Specify default date formatting for language with a fallback language (in this case Indonesian)
  console.log(new Intl.DateTimeFormat(['ban', 'id']).format(date));
  // expected output: "20/12/2020"

  // Specify date and time format using "style" options (i.e. full, long, medium, short)
  console.log(new Intl.DateTimeFormat('en-GB', { dateStyle: 'full', timeStyle: 'long' }).format(date));
  // Expected output "Sunday, 20 December 2020 at 14:23:16 GMT+11"
  */

  // VALUE PARSING
  const provinceLabel = Object.values(SPAIN_PROVINCES).find(
    (province: any) => province.value === data.province,
  )?.label as string;

  // console.log('%cPROVINCE', 'color:grey', provinceLabel);

  return (
    <div css={styles} className={cssClasses.inline()} onClick={handleClick}>
      <aside className="card-aside">
        <Row className="row">
          <Col xs={12} sm={12} md={12} lg={12} xl={9} xxl={9} className="col col-test">
            {/* ========================================================= */}
            <Row className="row row-test">
              <Col xs={3} sm={2} md={3} lg={3} xl={3} xxl={3} className="col col-date">
                {/* <div style={{ height: '100%', padding: '3px' }}> */}
                <CalendarDay date={eventDate} time={data?.time_start} />
                {/* </div> */}
              </Col>

              <Visible xs sm>
                <Col xs={9} sm={10} className="col col-title">
                  <h2>{data.name}</h2>
                </Col>
              </Visible>

              <Col xs={12} sm={10} md={9} lg={9} xl={9} xxl={9} className="col col-info">
                <section className="info">
                  <Visible md lg xl xxl>
                    <h2>{data.name}</h2>
                  </Visible>
                  <InfoRow label="Tipo de Evento" value={data.event_type} />
                  <InfoRow label="Ciudad" value={data.city} />
                  <InfoRow label="Province" value={provinceLabel} />
                </section>
              </Col>

              {/* <Col xs={12} sm={9} md={9} lg={9} xl={9} xxl={9} className="col col-info">
                <h2>{data.name}</h2>
                <InfoRow label="Tipo de Evento" value={data.event_type} />
                <InfoRow label="Ciudad" value={data.city} />
                <InfoRow label="Province" value={provinceLabel} />
              </Col> */}

              {/* <Col xs={12} lg={3} className="col">
                <Socials links={data.socials} color={'white'} mono />
              </Col> */}
            </Row>
            {/* ========================================================= */}
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={3}
            xxl={3}
            style={{ ...bgImage }}
            className="col col-image"
          >
            {/* <Socials links={data.socials} color={'white'} mono /> */}
          </Col>
        </Row>
      </aside>
    </div>
  );
};
