import { Row, Col, Hidden } from 'react-grid-system';
import type { ScreenClass } from 'types';
import { useRouter } from 'routes/hooks';
import { ArrayJSX } from 'utils/ArrayJSX';
import { config as appConfig } from 'config';
import { sizes } from 'styles/media-queries-V1';
import { DataHeadSortable as HeadSortable } from './DataHeadSortable';
import type { DataColumnConfig } from '../../DataTable.types';
import { styles } from './DataHead.css';

// NEW: CSS-Only STICKY-HEADER:
// https://csslayout.io/sticky-table-headers/

export const DataHead = ({ config: { columns, actions } }: any) => {
  const { isAdminPath } = useRouter();

  return (
    <div css={styles}>
      <Row nogutter align="stretch" style={{ height: `calc(${appConfig.tableRowHeight}/2)` }} className="row-header">
        {columns?.map((column: DataColumnConfig, index: number) => {
          // DETERMINE WIDTH: SET or RESPONSIVE /////////////////////////
          const widths = {};
          const hidden = {};
          if (column.widths) {
            let widthValue = Object.values(column.widths)[0];
            // SET ALL AVAIL BREAKPOINTS, EVEN IF UNSET IN CONFIG
            // RULE: SKIPED KEYS TAKE THE VALUE OF LAST SET
            // ACTING AS A min-width MEDIA-QUERY
            for (const size of sizes) {
              widthValue = column.widths[size as ScreenClass] ?? widthValue;
              widthValue === 0
                ? Object.assign(hidden, { [size]: true })
                : Object.assign(widths, { [size]: widthValue });
            }
          } else {
            // OPTIONAL 'width' KeyboardEvent, IF NO RESPONSIVE CHANGE REQUIRED
            Object.assign(widths, { xs: column.width || 0 });
            column.width === 0 && Object.assign(hidden, { xs: true });
          }

          const cssClasses = new ArrayJSX();
          cssClasses.push(`col-${column.label.toLowerCase()}`);
          column.isSortable && cssClasses.push('sortable');

          return (
            <Hidden {...hidden} key={index}>
              <Col
                {...widths}
                className={`th col ${cssClasses.inline()}`}
                style={{ maxWidth: column.maxWidth || 'none' }}
              >
                {column.isSortable ? <HeadSortable column={column}>{column.label}</HeadSortable> : column.label}
              </Col>
            </Hidden>
          );
        })}
        {/*
        // TODO: (DEV - REMOVED TEMPORARILY, IN FAVOUR OF dataConfiguration)
        {actions?.hasActionView && (
          <Col xs={1} className="th col col-actions col-view">
            VIEW
          </Col>
        )}
        {isAdminPath && actions?.hasActionEdit && (
          <Col xs={1} className="th col col-actions col-edit">
            EDIT
          </Col>
        )} */}
      </Row>
    </div>
  );
};
