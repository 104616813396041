import { Row, Col } from 'react-grid-system';
import { useFormContext, useFieldArray, useForm } from 'react-hook-form';
import { useEffect, forwardRef } from 'react';
import { InputURL } from './InputURL';
import { DEFAULT_URL_REPEATER_ITEM } from './input-repeater-url.config';
import { IRepeater } from '../InputRepeater';
import { styles } from './InputRepeaterURL.css';

export const InputRepeaterURL = ({ name: FIELD_KEY, field, defaultValue, repeaterConfig }: IRepeater) => {
  const { control } = useFormContext();
  const fieldArray = useFieldArray({ name: FIELD_KEY, keyName: 'uuid', control });

  useEffect(() => {
    if (defaultValue && fieldArray.fields.length === 0) return fieldArray.replace(DEFAULT_URL_REPEATER_ITEM);
    if (!defaultValue && !field.value) return fieldArray.replace(DEFAULT_URL_REPEATER_ITEM);
    if (defaultValue?.length) return fieldArray.replace(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (fieldArray.fields && !fieldArray.fields?.length) {
      fieldArray.replace(DEFAULT_URL_REPEATER_ITEM);
    }
  }, [fieldArray.fields]);

  return (
    <Row css={styles}>
      <Col>
        <div /* ref={ref} */ className="card-array">
          {fieldArray.fields.map((item: Record<'uuid', string>, index) => (
            <InputURL
              key={item.uuid}
              index={index}
              item={item}
              fieldKey={`${FIELD_KEY}[${index}]`}
              fieldArray={fieldArray}
              repeaterConfig={repeaterConfig}
            />
          ))}
        </div>
      </Col>
    </Row>
  );
};
