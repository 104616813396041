import { Container, Row, Col, Visible } from 'react-grid-system';
import { Helmet } from 'react-helmet-async';
import { css } from '@emotion/react';
import { useRouter } from 'routes/hooks';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { Hamburger } from 'components/Hamburger/Hamburger';
import { UserApnaes } from 'components/UserApnaes';
import { ArrayJSX } from 'utils/ArrayJSX';
import { config } from 'config';
import { HeaderTitle } from './HeaderTitle';
import { styles as stylesDefault } from './HeaderApnaes.css';
import { usePageContent } from 'pages/Layout/PageContentContext/PageContent.hooks';

export const stylesApnaes = css`
  ${stylesDefault}

  .header-main {
    white-space: nowrap;
    /* box-shadow: inset 0 0 0 2px yellow; */
  }
  .header-middle {
    text-align: left;
    justify-content: flex-start;
    /* box-shadow: inset 0 0 0 2px lime; */
  }
  .header-right {
    /* box-shadow: inset 0 0 0 2px pink; */
  }
`;

export const HeaderApnaes = () => {
  const { location, pathname, route } = useRouter();
  // const { title } = usePageContent();

  // CSS CLASSES
  const cssClasses = new ArrayJSX('header');
  cssClasses.push('dark');
  pathname === '/' && cssClasses.push('route-home');

  return (
    <header css={stylesApnaes} className={cssClasses.inline()}>
      {/* <Helmet titleTemplate={`%s - ${config.appName}`} defaultTitle={`${config.appName}`}>
        <title>{title || route?.title}</title>
      </Helmet> */}
      <Container fluid sm={true} md={true} lg={true} xl={true} xxl={false}>
        <Row align="stretch">
          {/* ============================================================== */}
          <Visible xs sm md>
            <Col xs={1} sm={1} md={1} lg={0} xl={0} xxl={0} className="col-burger">
              <Hamburger />
            </Col>
          </Visible>
          {/* ============================================================== */}
          <Col xs={9} sm={9} md={9} lg={9} xl={9} xxl={9} className="header-main">
            <h1>
              <HeaderTitle />
              {/* <Breadcrumbs /> */}
            </h1>
          </Col>
          <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} className="header-right">
            <UserApnaes />
          </Col>
          {/* ============================================================== */}
        </Row>
      </Container>
    </header>
  );
};
