import type { TableConfig } from 'types';
import { LinkToDetail } from 'components/DataTable/components/LinkToDetail';
import { ListItemCard } from 'components/ListItemCard';
import { DATATYPE, ENDPOINT } from './entidades.config';
import { COL_TYPE } from 'components/DataTable/DataTable.types';

// ----------------------------------------------------------------- //
// TABLE CONFIG:
export const tableConfig: TableConfig = {
  dataType: {
    key: DATATYPE,
    role: 'table',
  },
  list: {
    colWidth: {
      xs: 12,
      md: 6,
    },
    listItem: {
      component: ListItemCard,
      shapeRatio: 2.2,
    },
    search: {
      filterByKeys: ['name'],
    },
  },
  actions: {
    hasActionView: true,
    hasActionEdit: true,
    hasActionNew: false,
  },
  api: {
    endpoint: ENDPOINT,
  },
  columns: [
    {
      label: 'NAME',
      name: 'name',
      columnType: COL_TYPE.DATA,
      isSortable: true,
      render: ({ data }) => <LinkToDetail targetID={data.id} label={data.name} />,
    },
    {
      label: 'EMAIL (entidades)',
      name: 'email',
      columnType: COL_TYPE.DATA,
      // width: 2,
      widths: {
        xs: 0,
        lg: 2,
      },
      isSortable: true,
      render: ({ data }) => <LinkToDetail targetID={data.id} label={data.email} />,
    },
    {
      label: 'PROVINCE',
      name: 'province',
      columnType: COL_TYPE.DATA,
      isSortable: true,
      render: ({ data }) => <LinkToDetail targetID={data.id} label={data.province} />,
    },
    {
      label: 'ID',
      name: 'id',
      columnType: COL_TYPE.DATA,
      // width: 2,
      widths: {
        xs: 0,
        lg: 1,
      },
      isSortable: true,
      render: ({ data }) => data.id,
    },
  ],
};
