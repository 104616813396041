import type { ReducerActions } from 'types/ReducerActions';
import type { InviteFiltersState } from './InviteFiltersState';

// ======================================================================== //
// EVENTS FILTER REDUCER:

// GENERIC REDUCER ACTIONS
// REF: https://github.com/microsoft/TypeScript/issues/45541

export const eventFiltersReducer = (
  state: InviteFiltersState,
  action: ReducerActions<InviteFiltersState>,
): InviteFiltersState => {
  switch (action.type) {
    case 'set_province':
      return { ...state, province: action.payload };
    default:
      return { ...state, ...action.payload };
  }
};
