import { Col, Row } from 'react-grid-system';
import { IoCheckbox as IconCheckbox } from 'react-icons/io5';
import type { DataEntry } from 'types';

export const DetailListRow = ({ data, onClick: handleClick }: { data: DataEntry; onClick?: (id: number) => void }) => (
  <Row className="row row-preview" align="center">
    <Col xs={1} className="col col-action">
      <IconCheckbox
        className={`faux-checkbox ${handleClick ? 'is-clickable' : ''}`}
        {...(handleClick ? { onClick: () => handleClick(Number(data.id)) } : null)}
      />
    </Col>
    <Col xs={6} className="col col-name">
      {data.name}
    </Col>
    <Col xs={5} className="col col-location">
      {data.province}
    </Col>
  </Row>
);
