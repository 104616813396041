import type { RouteObject } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import initialLayoutState from 'pages/Layout/context/ContextState';
import LayoutProvider from 'pages/Layout/context/LayoutProvider';
import { LoginPage } from 'pages/LoginPage';
import { LoggedOut } from './LoggedOut';
import { LogOut } from './LogOut';

// NOTE: AUTH ROUTES MUST BE FIRST
export const getAuthRoutes = (): RouteObject[] => [
  {
    id: 'auth',
    // path, // TODO: IN FUTURE THIS COULD BE RELATIVE..
    element: (
      <LayoutProvider contextName={'layout'} value={{ ...initialLayoutState }}>
        <Outlet />
      </LayoutProvider>
    ),
    children: [
      { path: 'logout', element: <LogOut /> },
      { path: 'logged-out', element: <LoggedOut /> },
      { path: 'login', element: <LoginPage /> },
      { path: 'register-user-new', element: <LoginPage /> },
    ],
  },
];
