import { ReactElement } from 'react';
import isEqual from 'lodash/isEqual';
import type { RouteConfig } from 'routes';
import { getRouterRoutes } from 'routes/utils/router.utils.routes';
import { NavItem } from './NavItem';
import { stylesVertical } from './Nav.css';

// NEW: CSS-ONLY DROPDOWN MENU:
// https://csslayout.io/nested-dropdowns/

/**
 * REACT-MENU_DOCS
 * https://www.npmjs.com/package/@szhsin/react-menu
 * https://codesandbox.io/s/react-menu-starter-3ez3c
 * https://codesandbox.io/s/react-menu-starter-3ez3c
 *
 * REACT-ROUTER EXAMPLE:
 * https://codesandbox.io/s/react-menu-react-router-example-dw4ku
 */

export const NavVertical = ({ routes = [] }: { routes?: RouteConfig[] }): ReactElement => {
  if (isEqual(routes, [])) {
    const { ROUTES } = getRouterRoutes();
    routes.concat(ROUTES as RouteConfig[]);
  }

  return (
    <nav css={stylesVertical} className="nav-vertical">
      <ul>
        {routes?.map(
          ({ title, path, nav }: RouteConfig) =>
            nav?.isHidden && (
              <li key={path}>
                <NavItem path={path} title={title} /* end={end} */ />
              </li>
            ),
        )}
      </ul>
    </nav>
  );
};
