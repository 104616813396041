import { Row, Col } from 'react-grid-system';
import { useNavigateState } from 'hooks/useNavigateState';
import { ACTIONS } from 'types/actions.types';
import type { RouteConfig } from 'routes';
import { USER_ROLE } from 'auth/types/user.types';
import { useAuth } from 'auth/AuthContext';
import { useGlobal } from 'store/useGlobalContext';
import { useLayout } from 'pages/Layout/context/LayoutContext';
import { Button } from 'components/Button';

export const DevButtons = ({ formData }: any) => {
  const { user } = useAuth();
  const { navigate } = useNavigateState();
  const { metaRoutes } = useGlobal('metaRoutes');
  const { isDevToolsVisible, isNavbarVisible, setLayoutState } = useLayout();
  const fromLocationView = metaRoutes.find((r: RouteConfig) => r.path === 'account') || {};

  return (
    <>
      {isDevToolsVisible && formData && (
        <Row direction="row" className="row-buttons-view">
          <Col xs={6} className="col col-buttons-view">
            <Button
              type="button"
              label={'Editar Datos'}
              variant="outline"
              color="successXDark"
              onClick={() => {
                navigate(`/account/edit/${formData.id}`, {
                  state: {
                    action: ACTIONS.UPDATE,
                    from: { ...fromLocationView, path: '/account' },
                  },
                });
              }}
            />
          </Col>
          <Col xs={6} className="col col-buttons-view">
            <Button
              type="button"
              label={'Completar más tarde..'}
              variant="outline"
              color="grey"
              onClick={() => {
                navigate('/account');
              }}
            />
          </Col>
        </Row>
      )}
      <Row direction="row" className="row-buttons-view">
        <Col xs={6} className="col col-buttons-view" style={{ paddingTop: '3em' }}>
          <Button
            type="button"
            label={
              user?.user_role === USER_ROLE.REGISTERED
                ? 'TEST: MARCAR USUARIO COMO "PENDING"'
                : 'TEST: MARCAR USUARIO COMO "REGISTERED"'
            }
            color={user?.user_role === USER_ROLE.REGISTERED ? 'warningDark' : 'successXDark'}
            variant="outline"
            onClick={async () => {
              setLayoutState({ isNavbarVisible: !isNavbarVisible });
              // await updateUserValidation(
              //   user?.user_role === USER_ROLE.VALIDATED ? USER_ROLE.REGISTERED : USER_ROLE.VALIDATED,
              // );
              // window.location.reload();
            }}
          />
        </Col>
      </Row>
    </>
  );
};
