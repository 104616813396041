export type CountryData = {
  id: string;
  name_en: string;
  name_es: string;
  flag?: string;
  alpha2: string;
  alpha3: string;
};

export const COUNTRIES: CountryData[] = [
  {
    id: 'AD',
    name_en: 'Andorra',
    name_es: 'Andorra',
    flag: '🇦🇩',
    alpha2: 'ad',
    alpha3: 'and',
  },
  {
    id: 'AE',
    name_en: 'United Arab Emirates',
    name_es: 'Emiratos Árabes Unidos',
    flag: '🇦🇪',
    alpha2: 'ae',
    alpha3: 'are',
  },
  {
    id: 'AF',
    name_en: 'Afghanistan',
    name_es: 'Afganistán',
    flag: '🇦🇫',
    alpha2: 'af',
    alpha3: 'afg',
  },
  {
    id: 'AG',
    name_en: 'Antigua and Barbuda',
    name_es: 'Antigua y Barbuda',
    flag: '🇦🇬',
    alpha2: 'ag',
    alpha3: 'atg',
  },
  {
    id: 'AL',
    name_en: 'Albania',
    name_es: 'Albania',
    flag: '🇦🇱',
    alpha2: 'al',
    alpha3: 'alb',
  },
  {
    id: 'AM',
    name_en: 'Armenia',
    name_es: 'Armenia',
    flag: '🇦🇲',
    alpha2: 'am',
    alpha3: 'arm',
  },
  {
    id: 'AO',
    name_en: 'Angola',
    name_es: 'Angola',
    flag: '🇦🇴',
    alpha2: 'ao',
    alpha3: 'ago',
  },
  {
    id: 'AR',
    name_en: 'Argentina',
    name_es: 'Argentina',
    flag: '🇦🇷',
    alpha2: 'ar',
    alpha3: 'arg',
  },
  {
    id: 'AT',
    name_en: 'Austria',
    name_es: 'Austria',
    flag: '🇦🇹',
    alpha2: 'at',
    alpha3: 'aut',
  },
  {
    id: 'AU',
    name_en: 'Australia',
    name_es: 'Australia',
    flag: '🇦🇺',
    alpha2: 'au',
    alpha3: 'aus',
  },
  {
    id: 'AZ',
    name_en: 'Azerbaijan',
    name_es: 'Azerbaiyán',
    flag: '🇦🇿',
    alpha2: 'az',
    alpha3: 'aze',
  },
  {
    id: 'BA',
    name_en: 'Bosnia and Herzegovina',
    name_es: 'Bosnia y Herzegovina',
    flag: '🇧🇦',
    alpha2: 'ba',
    alpha3: 'bih',
  },
  {
    id: 'BB',
    name_en: 'Barbados',
    name_es: 'Barbados',
    flag: '🇧🇧',
    alpha2: 'bb',
    alpha3: 'brb',
  },
  {
    id: 'BD',
    name_en: 'Bangladesh',
    name_es: 'Bangladés',
    flag: '🇧🇩',
    alpha2: 'bd',
    alpha3: 'bgd',
  },
  {
    id: 'BE',
    name_en: 'Belgium',
    name_es: 'Bélgica',
    flag: '🇧🇪',
    alpha2: 'be',
    alpha3: 'bel',
  },
  {
    id: 'BF',
    name_en: 'Burkina Faso',
    name_es: 'Burkina Faso',
    flag: '🇧🇫',
    alpha2: 'bf',
    alpha3: 'bfa',
  },
  {
    id: 'BG',
    name_en: 'Bulgaria',
    name_es: 'Bulgaria',
    flag: '🇧🇬',
    alpha2: 'bg',
    alpha3: 'bgr',
  },
  {
    id: 'BH',
    name_en: 'Bahrain',
    name_es: 'Baréin',
    flag: '🇧🇭',
    alpha2: 'bh',
    alpha3: 'bhr',
  },
  {
    id: 'BI',
    name_en: 'Burundi',
    name_es: 'Burundi',
    flag: '🇧🇮',
    alpha2: 'bi',
    alpha3: 'bdi',
  },
  {
    id: 'BJ',
    name_en: 'Benin',
    name_es: 'Benín',
    flag: '🇧🇯',
    alpha2: 'bj',
    alpha3: 'ben',
  },
  {
    id: 'BN',
    name_en: 'Brunei Darussalam',
    name_es: 'Brunéi',
    flag: '🇧🇳',
    alpha2: 'bn',
    alpha3: 'brn',
  },
  {
    id: 'BO',
    name_en: 'Bolivia (Plurinational State of)',
    name_es: 'Bolivia',
    flag: '🇧🇴',
    alpha2: 'bo',
    alpha3: 'bol',
  },
  {
    id: 'BR',
    name_en: 'Brazil',
    name_es: 'Brasil',
    flag: '🇧🇷',
    alpha2: 'br',
    alpha3: 'bra',
  },
  {
    id: 'BS',
    name_en: 'Bahamas',
    name_es: 'Bahamas',
    flag: '🇧🇸',
    alpha2: 'bs',
    alpha3: 'bhs',
  },
  {
    id: 'BT',
    name_en: 'Bhutan',
    name_es: 'Bután',
    flag: '🇧🇹',
    alpha2: 'bt',
    alpha3: 'btn',
  },
  {
    id: 'BW',
    name_en: 'Botswana',
    name_es: 'Botsuana',
    flag: '🇧🇼',
    alpha2: 'bw',
    alpha3: 'bwa',
  },
  {
    id: 'BY',
    name_en: 'Belarus',
    name_es: 'Bielorrusia',
    flag: '🇧🇾',
    alpha2: 'by',
    alpha3: 'blr',
  },
  {
    id: 'BZ',
    name_en: 'Belize',
    name_es: 'Belice',
    flag: '🇧🇿',
    alpha2: 'bz',
    alpha3: 'blz',
  },
  {
    id: 'CA',
    name_en: 'Canada',
    name_es: 'Canadá',
    flag: '🇨🇦',
    alpha2: 'ca',
    alpha3: 'can',
  },
  {
    id: 'CD',
    name_en: 'Congo, Democratic Republic of the',
    name_es: 'República Democrática del Congo',
    flag: '🇨🇩',
    alpha2: 'cd',
    alpha3: 'cod',
  },
  {
    id: 'CF',
    name_en: 'Central African Republic',
    name_es: 'República Centroafricana',
    flag: '🇨🇫',
    alpha2: 'cf',
    alpha3: 'caf',
  },
  {
    id: 'CG',
    name_en: 'Congo',
    name_es: 'República del Congo',
    flag: '🇨🇬',
    alpha2: 'cg',
    alpha3: 'cog',
  },
  {
    id: 'CH',
    name_en: 'Switzerland',
    name_es: 'Suiza',
    flag: '🇨🇭',
    alpha2: 'ch',
    alpha3: 'che',
  },
  {
    id: 'CI',
    name_en: "Côte d'Ivoire",
    name_es: 'Costa de Marfil',
    flag: '🇨🇮',
    alpha2: 'ci',
    alpha3: 'civ',
  },
  {
    id: 'CL',
    name_en: 'Chile',
    name_es: 'Chile',
    flag: '🇨🇱',
    alpha2: 'cl',
    alpha3: 'chl',
  },
  {
    id: 'CM',
    name_en: 'Cameroon',
    name_es: 'Camerún',
    flag: '🇨🇲',
    alpha2: 'cm',
    alpha3: 'cmr',
  },
  {
    id: 'CN',
    name_en: 'China',
    name_es: 'China',
    flag: '🇨🇳',
    alpha2: 'cn',
    alpha3: 'chn',
  },
  {
    id: 'CO',
    name_en: 'Colombia',
    name_es: 'Colombia',
    flag: '🇨🇴',
    alpha2: 'co',
    alpha3: 'col',
  },
  {
    id: 'CR',
    name_en: 'Costa Rica',
    name_es: 'Costa Rica',
    flag: '🇨🇷',
    alpha2: 'cr',
    alpha3: 'cri',
  },
  {
    id: 'CU',
    name_en: 'Cuba',
    name_es: 'Cuba',
    flag: '🇨🇺',
    alpha2: 'cu',
    alpha3: 'cub',
  },
  {
    id: 'CV',
    name_en: 'Cabo Verde',
    name_es: 'Cabo Verde',
    flag: '🇨🇻',
    alpha2: 'cv',
    alpha3: 'cpv',
  },
  {
    id: 'CY',
    name_en: 'Cyprus',
    name_es: 'Chipre',
    flag: '🇨🇾',
    alpha2: 'cy',
    alpha3: 'cyp',
  },
  {
    id: 'CZ',
    name_en: 'Czechia',
    name_es: 'República Checa',
    flag: '🇨🇿',
    alpha2: 'cz',
    alpha3: 'cze',
  },
  {
    id: 'DE',
    name_en: 'Germany',
    name_es: 'Alemania',
    flag: '🇩🇪',
    alpha2: 'de',
    alpha3: 'deu',
  },
  {
    id: 'DJ',
    name_en: 'Djibouti',
    name_es: 'Yibuti',
    flag: '🇩🇯',
    alpha2: 'dj',
    alpha3: 'dji',
  },
  {
    id: 'DK',
    name_en: 'Denmark',
    name_es: 'Dinamarca',
    flag: '🇩🇰',
    alpha2: 'dk',
    alpha3: 'dnk',
  },
  {
    id: 'DM',
    name_en: 'Dominica',
    name_es: 'Dominica',
    flag: '🇩🇲',
    alpha2: 'dm',
    alpha3: 'dma',
  },
  {
    id: 'DO',
    name_en: 'Dominican Republic',
    name_es: 'República Dominicana',
    flag: '🇩🇴',
    alpha2: 'do',
    alpha3: 'dom',
  },
  {
    id: 'DZ',
    name_en: 'Algeria',
    name_es: 'Argelia',
    flag: '🇩🇿',
    alpha2: 'dz',
    alpha3: 'dza',
  },
  {
    id: 'EC',
    name_en: 'Ecuador',
    name_es: 'Ecuador',
    flag: '🇪🇨',
    alpha2: 'ec',
    alpha3: 'ecu',
  },
  {
    id: 'EE',
    name_en: 'Estonia',
    name_es: 'Estonia',
    flag: '🇪🇪',
    alpha2: 'ee',
    alpha3: 'est',
  },
  {
    id: 'EG',
    name_en: 'Egypt',
    name_es: 'Egipto',
    flag: '🇪🇬',
    alpha2: 'eg',
    alpha3: 'egy',
  },
  {
    id: 'ER',
    name_en: 'Eritrea',
    name_es: 'Eritrea',
    flag: '🇪🇷',
    alpha2: 'er',
    alpha3: 'eri',
  },
  {
    id: 'ES',
    name_en: 'Spain',
    name_es: 'España',
    flag: '🇪🇸',
    alpha2: 'es',
    alpha3: 'esp',
  },
  {
    id: 'ET',
    name_en: 'Ethiopia',
    name_es: 'Etiopía',
    flag: '🇪🇹',
    alpha2: 'et',
    alpha3: 'eth',
  },
  {
    id: 'FI',
    name_en: 'Finland',
    name_es: 'Finlandia',
    flag: '🇫🇮',
    alpha2: 'fi',
    alpha3: 'fin',
  },
  {
    id: 'FJ',
    name_en: 'Fiji',
    name_es: 'Fiyi',
    flag: '🇫🇯',
    alpha2: 'fj',
    alpha3: 'fji',
  },
  {
    id: 'FM',
    name_en: 'Micronesia (Federated States of)',
    name_es: 'Micronesia',
    flag: '🇫🇲',
    alpha2: 'fm',
    alpha3: 'fsm',
  },
  {
    id: 'FR',
    name_en: 'France',
    name_es: 'Francia',
    flag: '🇫🇷',
    alpha2: 'fr',
    alpha3: 'fra',
  },
  {
    id: 'GA',
    name_en: 'Gabon',
    name_es: 'Gabón',
    flag: '🇬🇦',
    alpha2: 'ga',
    alpha3: 'gab',
  },
  {
    id: 'GB',
    name_en: 'United Kingdom of Great Britain and Northern Ireland',
    name_es: 'Reino Unido',
    flag: '🇬🇧',
    alpha2: 'gb',
    alpha3: 'gbr',
  },
  {
    id: 'GD',
    name_en: 'Grenada',
    name_es: 'Granada',
    flag: '🇬🇩',
    alpha2: 'gd',
    alpha3: 'grd',
  },
  {
    id: 'GE',
    name_en: 'Georgia',
    name_es: 'Georgia',
    flag: '🇬🇪',
    alpha2: 'ge',
    alpha3: 'geo',
  },
  {
    id: 'GH',
    name_en: 'Ghana',
    name_es: 'Ghana',
    flag: '🇬🇭',
    alpha2: 'gh',
    alpha3: 'gha',
  },
  {
    id: 'GM',
    name_en: 'Gambia',
    name_es: 'Gambia',
    flag: '🇬🇲',
    alpha2: 'gm',
    alpha3: 'gmb',
  },
  {
    id: 'GN',
    name_en: 'Guinea',
    name_es: 'Guinea',
    flag: '🇬🇳',
    alpha2: 'gn',
    alpha3: 'gin',
  },
  {
    id: 'GQ',
    name_en: 'Equatorial Guinea',
    name_es: 'Guinea Ecuatorial',
    flag: '🇬🇶',
    alpha2: 'gq',
    alpha3: 'gnq',
  },
  {
    id: 'GR',
    name_en: 'Greece',
    name_es: 'Grecia',
    flag: '🇬🇷',
    alpha2: 'gr',
    alpha3: 'grc',
  },
  {
    id: 'GT',
    name_en: 'Guatemala',
    name_es: 'Guatemala',
    flag: '🇬🇹',
    alpha2: 'gt',
    alpha3: 'gtm',
  },
  {
    id: 'GW',
    name_en: 'Guinea-Bissau',
    name_es: 'Guinea-Bisáu',
    flag: '🇬🇼',
    alpha2: 'gw',
    alpha3: 'gnb',
  },
  {
    id: 'GY',
    name_en: 'Guyana',
    name_es: 'Guyana',
    flag: '🇬🇾',
    alpha2: 'gy',
    alpha3: 'guy',
  },
  {
    id: 'HN',
    name_en: 'Honduras',
    name_es: 'Honduras',
    flag: '🇭🇳',
    alpha2: 'hn',
    alpha3: 'hnd',
  },
  {
    id: 'HR',
    name_en: 'Croatia',
    name_es: 'Croacia',
    flag: '🇭🇷',
    alpha2: 'hr',
    alpha3: 'hrv',
  },
  {
    id: 'HT',
    name_en: 'Haiti',
    name_es: 'Haití',
    flag: '🇭🇹',
    alpha2: 'ht',
    alpha3: 'hti',
  },
  {
    id: 'HU',
    name_en: 'Hungary',
    name_es: 'Hungría',
    flag: '🇭🇺',
    alpha2: 'hu',
    alpha3: 'hun',
  },
  {
    id: 'ID',
    name_en: 'Indonesia',
    name_es: 'Indonesia',
    flag: '🇮🇩',
    alpha2: 'id',
    alpha3: 'idn',
  },
  {
    id: 'IE',
    name_en: 'Ireland',
    name_es: 'Irlanda',
    flag: '🇮🇪',
    alpha2: 'ie',
    alpha3: 'irl',
  },
  {
    id: 'IL',
    name_en: 'Israel',
    name_es: 'Israel',
    flag: '🇮🇱',
    alpha2: 'il',
    alpha3: 'isr',
  },
  {
    id: 'IN',
    name_en: 'India',
    name_es: 'India',
    flag: '🇮🇳',
    alpha2: 'in',
    alpha3: 'ind',
  },
  {
    id: 'IQ',
    name_en: 'Iraq',
    name_es: 'Irak',
    flag: '🇮🇶',
    alpha2: 'iq',
    alpha3: 'irq',
  },
  {
    id: 'IR',
    name_en: 'Iran (Islamic Republic of)',
    name_es: 'Irán',
    flag: '🇮🇷',
    alpha2: 'ir',
    alpha3: 'irn',
  },
  {
    id: 'IS',
    name_en: 'Iceland',
    name_es: 'Islandia',
    flag: '🇮🇸',
    alpha2: 'is',
    alpha3: 'isl',
  },
  {
    id: 'IT',
    name_en: 'Italy',
    name_es: 'Italia',
    flag: '🇮🇹',
    alpha2: 'it',
    alpha3: 'ita',
  },
  {
    id: 'JM',
    name_en: 'Jamaica',
    name_es: 'Jamaica',
    flag: '🇯🇲',
    alpha2: 'jm',
    alpha3: 'jam',
  },
  {
    id: 'JO',
    name_en: 'Jordan',
    name_es: 'Jordania',
    flag: '🇯🇴',
    alpha2: 'jo',
    alpha3: 'jor',
  },
  {
    id: 'JP',
    name_en: 'Japan',
    name_es: 'Japón',
    flag: '🇯🇵',
    alpha2: 'jp',
    alpha3: 'jpn',
  },
  {
    id: 'KE',
    name_en: 'Kenya',
    name_es: 'Kenia',
    flag: '🇰🇪',
    alpha2: 'ke',
    alpha3: 'ken',
  },
  {
    id: 'KG',
    name_en: 'Kyrgyzstan',
    name_es: 'Kirguistán',
    flag: '🇰🇬',
    alpha2: 'kg',
    alpha3: 'kgz',
  },
  {
    id: 'KH',
    name_en: 'Cambodia',
    name_es: 'Camboya',
    flag: '🇰🇭',
    alpha2: 'kh',
    alpha3: 'khm',
  },
  {
    id: 'KI',
    name_en: 'Kiribati',
    name_es: 'Kiribati',
    flag: '🇰🇮',
    alpha2: 'ki',
    alpha3: 'kir',
  },
  {
    id: 'KM',
    name_en: 'Comoros',
    name_es: 'Comoras',
    flag: '🇰🇲',
    alpha2: 'km',
    alpha3: 'com',
  },
  {
    id: 'KN',
    name_en: 'Saint Kitts and Nevis',
    name_es: 'San Cristóbal y Nieves',
    flag: '🇰🇳',
    alpha2: 'kn',
    alpha3: 'kna',
  },
  {
    id: 'KP',
    name_en: "Korea (Democratic People's Republic of)",
    name_es: 'Corea del Norte',
    flag: '🇰🇵',
    alpha2: 'kp',
    alpha3: 'prk',
  },
  {
    id: 'KR',
    name_en: 'Korea, Republic of',
    name_es: 'Corea del Sur',
    flag: '🇰🇷',
    alpha2: 'kr',
    alpha3: 'kor',
  },
  {
    id: 'KW',
    name_en: 'Kuwait',
    name_es: 'Kuwait',
    flag: '🇰🇼',
    alpha2: 'kw',
    alpha3: 'kwt',
  },
  {
    id: 'KZ',
    name_en: 'Kazakhstan',
    name_es: 'Kazajistán',
    flag: '🇰🇿',
    alpha2: 'kz',
    alpha3: 'kaz',
  },
  {
    id: 'LA',
    name_en: "Lao People's Democratic Republic",
    name_es: 'Laos',
    flag: '🇱🇦',
    alpha2: 'la',
    alpha3: 'lao',
  },
  {
    id: 'LB',
    name_en: 'Lebanon',
    name_es: 'Líbano',
    flag: '🇱🇧',
    alpha2: 'lb',
    alpha3: 'lbn',
  },
  {
    id: 'LC',
    name_en: 'Saint Lucia',
    name_es: 'Santa Lucía',
    flag: '🇱🇨',
    alpha2: 'lc',
    alpha3: 'lca',
  },
  {
    id: 'LI',
    name_en: 'Liechtenstein',
    name_es: 'Liechtenstein',
    flag: '🇱🇮',
    alpha2: 'li',
    alpha3: 'lie',
  },
  {
    id: 'LK',
    name_en: 'Sri Lanka',
    name_es: 'Sri Lanka',
    flag: '🇱🇰',
    alpha2: 'lk',
    alpha3: 'lka',
  },
  {
    id: 'LR',
    name_en: 'Liberia',
    name_es: 'Liberia',
    flag: '🇱🇷',
    alpha2: 'lr',
    alpha3: 'lbr',
  },
  {
    id: 'LS',
    name_en: 'Lesotho',
    name_es: 'Lesoto',
    flag: '🇱🇸',
    alpha2: 'ls',
    alpha3: 'lso',
  },
  {
    id: 'LT',
    name_en: 'Lithuania',
    name_es: 'Lituania',
    flag: '🇱🇹',
    alpha2: 'lt',
    alpha3: 'ltu',
  },
  {
    id: 'LU',
    name_en: 'Luxembourg',
    name_es: 'Luxemburgo',
    flag: '🇱🇺',
    alpha2: 'lu',
    alpha3: 'lux',
  },
  {
    id: 'LV',
    name_en: 'Latvia',
    name_es: 'Letonia',
    flag: '🇱🇻',
    alpha2: 'lv',
    alpha3: 'lva',
  },
  {
    id: 'LY',
    name_en: 'Libya',
    name_es: 'Libia',
    flag: '🇱🇾',
    alpha2: 'ly',
    alpha3: 'lby',
  },
  {
    id: 'MA',
    name_en: 'Morocco',
    name_es: 'Marruecos',
    flag: '🇲🇦',
    alpha2: 'ma',
    alpha3: 'mar',
  },
  {
    id: 'MC',
    name_en: 'Monaco',
    name_es: 'Mónaco',
    flag: '🇲🇨',
    alpha2: 'mc',
    alpha3: 'mco',
  },
  {
    id: 'MD',
    name_en: 'Moldova, Republic of',
    name_es: 'Moldavia',
    flag: '🇲🇩',
    alpha2: 'md',
    alpha3: 'mda',
  },
  {
    id: 'ME',
    name_en: 'Montenegro',
    name_es: 'Montenegro',
    flag: '🇲🇪',
    alpha2: 'me',
    alpha3: 'mne',
  },
  {
    id: 'MG',
    name_en: 'Madagascar',
    name_es: 'Madagascar',
    flag: '🇲🇬',
    alpha2: 'mg',
    alpha3: 'mdg',
  },
  {
    id: 'MH',
    name_en: 'Marshall Islands',
    name_es: 'Islas Marshall',
    flag: '🇲🇭',
    alpha2: 'mh',
    alpha3: 'mhl',
  },
  {
    id: 'MK',
    name_en: 'North Macedonia',
    name_es: 'Macedonia del Norte',
    flag: '🇲🇰',
    alpha2: 'mk',
    alpha3: 'mkd',
  },
  {
    id: 'ML',
    name_en: 'Mali',
    name_es: 'Malí',
    flag: '🇲🇱',
    alpha2: 'ml',
    alpha3: 'mli',
  },
  {
    id: 'MM',
    name_en: 'Myanmar',
    name_es: 'Birmania',
    flag: '🇲🇲',
    alpha2: 'mm',
    alpha3: 'mmr',
  },
  {
    id: 'MN',
    name_en: 'Mongolia',
    name_es: 'Mongolia',
    flag: '🇲🇳',
    alpha2: 'mn',
    alpha3: 'mng',
  },
  {
    id: 'MR',
    name_en: 'Mauritania',
    name_es: 'Mauritania',
    flag: '🇲🇷',
    alpha2: 'mr',
    alpha3: 'mrt',
  },
  {
    id: 'MT',
    name_en: 'Malta',
    name_es: 'Malta',
    flag: '🇲🇹',
    alpha2: 'mt',
    alpha3: 'mlt',
  },
  {
    id: 'MU',
    name_en: 'Mauritius',
    name_es: 'Mauricio',
    flag: '🇲🇺',
    alpha2: 'mu',
    alpha3: 'mus',
  },
  {
    id: 'MV',
    name_en: 'Maldives',
    name_es: 'Maldivas',
    flag: '🇲🇻',
    alpha2: 'mv',
    alpha3: 'mdv',
  },
  {
    id: 'MW',
    name_en: 'Malawi',
    name_es: 'Malaui',
    flag: '🇲🇼',
    alpha2: 'mw',
    alpha3: 'mwi',
  },
  {
    id: 'MX',
    name_en: 'Mexico',
    name_es: 'México',
    flag: '🇲🇽',
    alpha2: 'mx',
    alpha3: 'mex',
  },
  {
    id: 'MY',
    name_en: 'Malaysia',
    name_es: 'Malasia',
    flag: '🇲🇾',
    alpha2: 'my',
    alpha3: 'mys',
  },
  {
    id: 'MZ',
    name_en: 'Mozambique',
    name_es: 'Mozambique',
    flag: '🇲🇿',
    alpha2: 'mz',
    alpha3: 'moz',
  },
  {
    id: 'NA',
    name_en: 'Namibia',
    name_es: 'Namibia',
    flag: '🇳🇦',
    alpha2: 'na',
    alpha3: 'nam',
  },
  {
    id: 'NE',
    name_en: 'Niger',
    name_es: 'Níger',
    flag: '🇳🇪',
    alpha2: 'ne',
    alpha3: 'ner',
  },
  {
    id: 'NG',
    name_en: 'Nigeria',
    name_es: 'Nigeria',
    flag: '🇳🇬',
    alpha2: 'ng',
    alpha3: 'nga',
  },
  {
    id: 'NI',
    name_en: 'Nicaragua',
    name_es: 'Nicaragua',
    flag: '🇳🇮',
    alpha2: 'ni',
    alpha3: 'nic',
  },
  {
    id: 'NL',
    name_en: 'Netherlands',
    name_es: 'Países Bajos',
    flag: '🇳🇱',
    alpha2: 'nl',
    alpha3: 'nld',
  },
  {
    id: 'NO',
    name_en: 'Norway',
    name_es: 'Noruega',
    flag: '🇳🇴',
    alpha2: 'no',
    alpha3: 'nor',
  },
  {
    id: 'NP',
    name_en: 'Nepal',
    name_es: 'Nepal',
    flag: '🇳🇵',
    alpha2: 'np',
    alpha3: 'npl',
  },
  {
    id: 'NR',
    name_en: 'Nauru',
    name_es: 'Nauru',
    flag: '🇳🇷',
    alpha2: 'nr',
    alpha3: 'nru',
  },
  {
    id: 'NZ',
    name_en: 'New Zealand',
    name_es: 'Nueva Zelanda',
    flag: '🇳🇿',
    alpha2: 'nz',
    alpha3: 'nzl',
  },
  {
    id: 'OM',
    name_en: 'Oman',
    name_es: 'Omán',
    flag: '🇴🇲',
    alpha2: 'om',
    alpha3: 'omn',
  },
  {
    id: 'PA',
    name_en: 'Panama',
    name_es: 'Panamá',
    flag: '🇵🇦',
    alpha2: 'pa',
    alpha3: 'pan',
  },
  {
    id: 'PE',
    name_en: 'Peru',
    name_es: 'Perú',
    flag: '🇵🇪',
    alpha2: 'pe',
    alpha3: 'per',
  },
  {
    id: 'PG',
    name_en: 'Papua New Guinea',
    name_es: 'Papúa Nueva Guinea',
    flag: '🇵🇬',
    alpha2: 'pg',
    alpha3: 'png',
  },
  {
    id: 'PH',
    name_en: 'Philippines',
    name_es: 'Filipinas',
    flag: '🇵🇭',
    alpha2: 'ph',
    alpha3: 'phl',
  },
  {
    id: 'PK',
    name_en: 'Pakistan',
    name_es: 'Pakistán',
    flag: '🇵🇰',
    alpha2: 'pk',
    alpha3: 'pak',
  },
  {
    id: 'PL',
    name_en: 'Poland',
    name_es: 'Polonia',
    flag: '🇵🇱',
    alpha2: 'pl',
    alpha3: 'pol',
  },
  {
    id: 'PT',
    name_en: 'Portugal',
    name_es: 'Portugal',
    flag: '🇵🇹',
    alpha2: 'pt',
    alpha3: 'prt',
  },
  {
    id: 'PW',
    name_en: 'Palau',
    name_es: 'Palaos',
    flag: '🇵🇼',
    alpha2: 'pw',
    alpha3: 'plw',
  },
  {
    id: 'PY',
    name_en: 'Paraguay',
    name_es: 'Paraguay',
    flag: '🇵🇾',
    alpha2: 'py',
    alpha3: 'pry',
  },
  {
    id: 'QA',
    name_en: 'Qatar',
    name_es: 'Catar',
    flag: '🇶🇦',
    alpha2: 'qa',
    alpha3: 'qat',
  },
  {
    id: 'RO',
    name_en: 'Romania',
    name_es: 'Rumania',
    flag: '🇷🇴',
    alpha2: 'ro',
    alpha3: 'rou',
  },
  {
    id: 'RS',
    name_en: 'Serbia',
    name_es: 'Serbia',
    flag: '🇷🇸',
    alpha2: 'rs',
    alpha3: 'srb',
  },
  {
    id: 'RU',
    name_en: 'Russian Federation',
    name_es: 'Rusia',
    flag: '🇷🇺',
    alpha2: 'ru',
    alpha3: 'rus',
  },
  {
    id: 'RW',
    name_en: 'Rwanda',
    name_es: 'Ruanda',
    flag: '🇷🇼',
    alpha2: 'rw',
    alpha3: 'rwa',
  },
  {
    id: 'SA',
    name_en: 'Saudi Arabia',
    name_es: 'Arabia Saudita',
    flag: '🇸🇦',
    alpha2: 'sa',
    alpha3: 'sau',
  },
  {
    id: 'SB',
    name_en: 'Solomon Islands',
    name_es: 'Islas Salomón',
    flag: '🇸🇧',
    alpha2: 'sb',
    alpha3: 'slb',
  },
  {
    id: 'SC',
    name_en: 'Seychelles',
    name_es: 'Seychelles',
    flag: '🇸🇨',
    alpha2: 'sc',
    alpha3: 'syc',
  },
  {
    id: 'SD',
    name_en: 'Sudan',
    name_es: 'Sudán',
    flag: '🇸🇩',
    alpha2: 'sd',
    alpha3: 'sdn',
  },
  {
    id: 'SE',
    name_en: 'Sweden',
    name_es: 'Suecia',
    flag: '🇸🇪',
    alpha2: 'se',
    alpha3: 'swe',
  },
  {
    id: 'SG',
    name_en: 'Singapore',
    name_es: 'Singapur',
    flag: '🇸🇬',
    alpha2: 'sg',
    alpha3: 'sgp',
  },
  {
    id: 'SI',
    name_en: 'Slovenia',
    name_es: 'Eslovenia',
    flag: '🇸🇮',
    alpha2: 'si',
    alpha3: 'svn',
  },
  {
    id: 'SK',
    name_en: 'Slovakia',
    name_es: 'Eslovaquia',
    flag: '🇸🇰',
    alpha2: 'sk',
    alpha3: 'svk',
  },
  {
    id: 'SL',
    name_en: 'Sierra Leone',
    name_es: 'Sierra Leona',
    flag: '🇸🇱',
    alpha2: 'sl',
    alpha3: 'sle',
  },
  {
    id: 'SM',
    name_en: 'San Marino',
    name_es: 'San Marino',
    flag: '🇸🇲',
    alpha2: 'sm',
    alpha3: 'smr',
  },
  {
    id: 'SN',
    name_en: 'Senegal',
    name_es: 'Senegal',
    flag: '🇸🇳',
    alpha2: 'sn',
    alpha3: 'sen',
  },
  {
    id: 'SO',
    name_en: 'Somalia',
    name_es: 'Somalia',
    flag: '🇸🇴',
    alpha2: 'so',
    alpha3: 'som',
  },
  {
    id: 'SR',
    name_en: 'Suriname',
    name_es: 'Surinam',
    flag: '🇸🇷',
    alpha2: 'sr',
    alpha3: 'sur',
  },
  {
    id: 'SS',
    name_en: 'South Sudan',
    name_es: 'Sudán del Sur',
    flag: '🇸🇸',
    alpha2: 'ss',
    alpha3: 'ssd',
  },
  {
    id: 'ST',
    name_en: 'Sao Tome and Principe',
    name_es: 'Santo Tomé y Príncipe',
    flag: '🇸🇹',
    alpha2: 'st',
    alpha3: 'stp',
  },
  {
    id: 'SV',
    name_en: 'El Salvador',
    name_es: 'El Salvador',
    flag: '🇸🇻',
    alpha2: 'sv',
    alpha3: 'slv',
  },
  {
    id: 'SY',
    name_en: 'Syrian Arab Republic',
    name_es: 'Siria',
    flag: '🇸🇾',
    alpha2: 'sy',
    alpha3: 'syr',
  },
  {
    id: 'SZ',
    name_en: 'Eswatini',
    name_es: 'Suazilandia',
    flag: '🇸🇿',
    alpha2: 'sz',
    alpha3: 'swz',
  },
  {
    id: 'TD',
    name_en: 'Chad',
    name_es: 'Chad',
    flag: '🇹🇩',
    alpha2: 'td',
    alpha3: 'tcd',
  },
  {
    id: 'TG',
    name_en: 'Togo',
    name_es: 'Togo',
    flag: '🇹🇬',
    alpha2: 'tg',
    alpha3: 'tgo',
  },
  {
    id: 'TH',
    name_en: 'Thailand',
    name_es: 'Tailandia',
    flag: '🇹🇭',
    alpha2: 'th',
    alpha3: 'tha',
  },
  {
    id: 'TJ',
    name_en: 'Tajikistan',
    name_es: 'Tayikistán',
    flag: '🇹🇯',
    alpha2: 'tj',
    alpha3: 'tjk',
  },
  {
    id: 'TL',
    name_en: 'Timor-Leste',
    name_es: 'Timor Oriental',
    flag: '🇹🇱',
    alpha2: 'tl',
    alpha3: 'tls',
  },
  {
    id: 'TM',
    name_en: 'Turkmenistan',
    name_es: 'Turkmenistán',
    flag: '🇹🇲',
    alpha2: 'tm',
    alpha3: 'tkm',
  },
  {
    id: 'TN',
    name_en: 'Tunisia',
    name_es: 'Túnez',
    flag: '🇹🇳',
    alpha2: 'tn',
    alpha3: 'tun',
  },
  {
    id: 'TO',
    name_en: 'Tonga',
    name_es: 'Tonga',
    flag: '🇹🇴',
    alpha2: 'to',
    alpha3: 'ton',
  },
  {
    id: 'TR',
    name_en: 'Turkey',
    name_es: 'Turquía',
    flag: '🇹🇷',
    alpha2: 'tr',
    alpha3: 'tur',
  },
  {
    id: 'TT',
    name_en: 'Trinidad and Tobago',
    name_es: 'Trinidad y Tobago',
    flag: '🇹🇹',
    alpha2: 'tt',
    alpha3: 'tto',
  },
  {
    id: 'TV',
    name_en: 'Tuvalu',
    name_es: 'Tuvalu',
    flag: '🇹🇻',
    alpha2: 'tv',
    alpha3: 'tuv',
  },
  {
    id: 'TZ',
    name_en: 'Tanzania, United Republic of',
    name_es: 'Tanzania',
    flag: '🇹🇿',
    alpha2: 'tz',
    alpha3: 'tza',
  },
  {
    id: 'UA',
    name_en: 'Ukraine',
    name_es: 'Ucrania',
    flag: '🇺🇦',
    alpha2: 'ua',
    alpha3: 'ukr',
  },
  {
    id: 'UG',
    name_en: 'Uganda',
    name_es: 'Uganda',
    flag: '🇺🇬',
    alpha2: 'ug',
    alpha3: 'uga',
  },
  {
    id: 'US',
    name_en: 'United States of America',
    name_es: 'Estados Unidos',
    flag: '🇺🇸',
    alpha2: 'us',
    alpha3: 'usa',
  },
  {
    id: 'UY',
    name_en: 'Uruguay',
    name_es: 'Uruguay',
    flag: '🇺🇾',
    alpha2: 'uy',
    alpha3: 'ury',
  },
  {
    id: 'UZ',
    name_en: 'Uzbekistan',
    name_es: 'Uzbekistán',
    flag: '🇺🇿',
    alpha2: 'uz',
    alpha3: 'uzb',
  },
  {
    id: 'VC',
    name_en: 'Saint Vincent and the Grenadines',
    name_es: 'San Vicente y las Granadinas',
    flag: '🇻🇨',
    alpha2: 'vc',
    alpha3: 'vct',
  },
  {
    id: 'VE',
    name_en: 'Venezuela (Bolivarian Republic of)',
    name_es: 'Venezuela',
    flag: '🇻🇪',
    alpha2: 've',
    alpha3: 'ven',
  },
  {
    id: 'VN',
    name_en: 'Viet Nam',
    name_es: 'Vietnam',
    flag: '🇻🇳',
    alpha2: 'vn',
    alpha3: 'vnm',
  },
  {
    id: 'VU',
    name_en: 'Vanuatu',
    name_es: 'Vanuatu',
    flag: '🇻🇺',
    alpha2: 'vu',
    alpha3: 'vut',
  },
  {
    id: 'WS',
    name_en: 'Samoa',
    name_es: 'Samoa',
    flag: '🇼🇸',
    alpha2: 'ws',
    alpha3: 'wsm',
  },
  {
    id: 'YE',
    name_en: 'Yemen',
    name_es: 'Yemen',
    flag: '🇾🇪',
    alpha2: 'ye',
    alpha3: 'yem',
  },
  {
    id: 'ZA',
    name_en: 'South Africa',
    name_es: 'Sudáfrica',
    flag: '🇿🇦',
    alpha2: 'za',
    alpha3: 'zaf',
  },
  {
    id: 'ZM',
    name_en: 'Zambia',
    name_es: 'Zambia',
    flag: '🇿🇲',
    alpha2: 'zm',
    alpha3: 'zmb',
  },
  {
    id: 'ZW',
    name_en: 'Zimbabwe',
    name_es: 'Zimbabue',
    flag: '🇿🇼',
    alpha2: 'zw',
    alpha3: 'zwe',
  },
];

export const OPTIONS_COUNTRIES = COUNTRIES.map((country: CountryData) => {
  return {
    value: country.alpha2,
    label: country.name_en,
  };
});
