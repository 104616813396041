import { Col, Row } from 'react-grid-system';
import { useNavigateState } from 'hooks/useNavigateState';
import { useEffect } from 'react';
import { FaBatteryEmpty as IconEmpty } from 'react-icons/fa';
import { IoIosArrowBack as IconBack } from 'react-icons/io';
import { RouteConfig } from 'routes';
import { useRouter } from 'routes/hooks';
import { useGlobal } from 'store/useGlobalContext';
import { Button } from 'components/Button';
import { Card } from 'components/Card';
import { toUpperCaseFirst } from 'utils';
import { styles } from './NoEntries.css';
import { usePageContent, usePageContentActions } from 'pages/Layout/PageContentContext/PageContent.hooks';

// FaBatteryEmpty

export const NoEntries = () => {
  const { navigate } = useNavigateState();
  const { PATH_BASE, location, params, slug, fromLocation, basePath } = useRouter();
  const { title } = usePageContent();
  const { setPageTitle } = usePageContentActions();
  const { metaRoutes } = useGlobal('metaRoutes');
  // const isVisibleBackButton = !!(params.action === 'EDIT' || params.action === 'NEW');
  const isVisibleBackButton = true;

  useEffect(() => {
    if (metaRoutes.length > 0) {
      const routeMeta = metaRoutes?.find((route: RouteConfig) => route.slug === slug);
      const newTitle = routeMeta?.title || title || toUpperCaseFirst(basePath as string);
      setPageTitle(newTitle);
    }
  }, [metaRoutes, location]);

  const onClickCancel = () => {
    navigate(-1);
  };

  const BackButton = () => (
    <Button
      className="btn-back"
      onClick={onClickCancel}
      variant="solid"
      color="warningLight"
      label={'Back'}
      icon={<IconBack />}
      iconPos="left"
      iconScale={1.8}
    />
  );

  // ====================================================================== //
  // OWNDER_ID: admin001-e89b-12d3-a456-426614174000

  return (
    <section css={styles} className="no-entries">
      <Card>
        <Row className="row">
          <Col xs={12} md={6} className="col col-message">
            {/* <IconEmpty color={colors.warning} /> */}
            <h3 className="message">
              <span>{title}</span> - no hay items
            </h3>
          </Col>
          <Col xs={12} md={6} className="col-button">
            {isVisibleBackButton && <BackButton />}
          </Col>
        </Row>
      </Card>
    </section>
  );
};
