import { css } from '@emotion/react';
import { reverseString } from 'utils';
import { max, min } from './media-queries-V1';

// import { getStamp } from 'utils';
import { colors } from './colors.css';
import { layout } from './layout.css';

// NOTE: GREAT 2023 FULL-GUIDE TO CSS FORMS:
// ref: https://blog.logrocket.com/style-forms-css

// LAYOUT VARIABLES
export const forms = {
  inputs: {
    fontSize: '1.15em',
    fontWeight: '600',
    width: '100%',
    height: '60px',
  },
  errors: {
    height: '30px',
  },
};

export const cssInputText = css`
  font-size: ${forms.inputs.fontSize};
  font-weight: ${forms.inputs.fontWeight};
  font-family:
    'Helvetica Neue',
    'Helvetica',
    'Fira Sans',
    'Droid Sans',
    'Roboto',
    'Segoe UI',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-fill-color: ${colors.text};
  color: ${colors.text};
`;

export const cssInputBox = css`
  appearance: none;
  box-sizing: border-box;
  width: 100%;
  min-height: ${forms.inputs.height};
  padding: 0 1em;
  background-color: rgba(255, 255, 255, 0.8);
  border: ${layout.borderWidth} solid ${colors.greyLight};
  border-radius: ${layout.borderRadius};
  &,
  &:focus {
    outline: none; /* POOR ACCESSIBILITY */
  }
  &:focus {
    border-color: ${colors.primaryLight};
  }
`;

// NOTE: CAN USE: `::placeholder`

export const cssPlaceholder = css`
  font-weight: ${forms.inputs.fontWeight};
  color: ${colors.greyLight};
  -webkit-text-fill-color: ${colors.greyLight};
  letter-spacing: 0 !important;
  opacity: 0.66;
`;

export const cssInputSpecialUI = css`
  select,
  div > .select__control {
    height: ${forms.inputs.height};
    align-content: center;
  }

  .input-select {
    width: 100%;
    input {
      min-height: ${forms.inputs.height};
      align-content: center;
    }
    .select__control {
      font-weight: 600;
      padding: 0 0.5em;
    }
    .select__control--is-focused,
    .select__control--menu-is-open {
      border-color: ${colors.primaryLight};
      box-shadow: 0 0 0 1px ${colors.primaryLight};
    }
    .select__dropdown-indicator {
      margin-left: 8px;
    }
    .select__indicator-separator {
      width: ${layout.borderWidth};
      opacity: 0.66;
    }

    .select__control {
      ${cssInputBox}
      font-weight: 600;
      padding: 0 0.5em;
    }
    .select__value-container,
    .select__single-value,
    .select__input-container {
      color: ${colors.grey};
    }

    .select__control--is-focused,
    .select__control--menu-is-open {
      box-shadow: 0 0 0 1px transparent;
    }

    .select__menu {
      transform: translateY(calc(-${layout.padding} * 0.5));
      ${cssInputText}
      -webkit-text-fill-color: ${colors.greyDark};
      color: ${colors.greyDark};
      font-weight: 500;
      text-indent: 0.5em;
      opacity: 1;
      background-color: rgba(255, 255, 255, 1);
    }

    .select__menu-list {
      .select__option.select__option--is-selected {
        background: ${colors.primaryXXLight}!important;
        color: ${colors.white}!important;
        -webkit-text-fill-color: ${colors.white}!important;
      }
    }
  }

  /* TODO: NEEDED ?? */
  /* .react-datepicker {
    border-color: ${colors.greyLight}!important;
    border-width: ${layout.borderWidth}!important;
    .react-datepicker__header {
      .react-datepicker__current-month {
        color: ${colors.greyXDark};
        -webkit-text-fill-color: ${colors.greyXDark};
        text-transform: capitalize;
      }
    }
  } */
`;

export const cssInputReadOnly = css`
  background-color: ${colors.greyXXLight};
  border-color: ${colors.greyLight};
  color: ${colors.greyDark};
  -webkit-text-fill-color: ${colors.greyDark};
  pointer-events: none;
`;

export const cssForms = css`
  /***** CUSTOM RESET *****/
  button,
  input,
  optgroup,
  select,
  textarea,
  input[type='button'],
  input[type='reset'],
  /* input[type='submit'], */
  button[disabled],
  input[disabled],
  button::-moz-focus-inner,
  input::-moz-focus-inner,
  /* input[type='checkbox'], */
  /* input[type='radio'], */
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button,
  input[type='search'],
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-decoration {
    border: none;
    background-image: none;
    /* background-color: transparent;*/
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  /***** CORE STYLES *****/

  input[type='text'],
  input[type='number'],
  input[type='password'],
  input[type='date'],
  input[type='datetime-local'],
  input[type='time'],
  input[type='email'],
  input[type='tel'],
  input[type='url'],
  input[type='image'],
  input[type='file'],
  input[type='search'],
  textarea,
  select,
  div > .select__control {
    ${cssInputText}
    ${cssInputBox}
  }

  textarea {
    /* min-height: 14em; */
  }

  ${cssInputSpecialUI}

  /***** MISC FIXES *****/

  textarea {
    padding-top: ${layout.padding};
  }

  /***** PLACEHOLDERS *****/

  input,
  textarea {
    ::-webkit-input-placeholder {
      ${cssPlaceholder}
    }
    ::-moz-placeholder {
      ${cssPlaceholder}
    }
    :-ms-input-placeholder {
      ${cssPlaceholder}
    }
    ::placeholder {
      ${cssPlaceholder}
    }
  }

  .placeholder,
  input[type='date']:empty {
    ${cssPlaceholder}
  }

  .select__placeholder {
    ${cssPlaceholder}
  }

  /***** FOCUSSED *****/

  input,
  textarea,
  select,
  div > .select__control {
    &:focus {
      ::-webkit-input-placeholder {
        color: transparent;
        -webkit-text-fill-color: transparent;
      }
      &::-moz-placeholder {
        color: transparent;
        -webkit-text-fill-color: transparent;
      }
      :-ms-input-placeholder {
        color: transparent;
        -webkit-text-fill-color: transparent;
      }
      ::placeholder {
        color: transparent;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  /***** DISABLED *****/

  input,
  textarea,
  select,
  div > .select__control {
    &:disabled,
    &:disabled:focus {
      ${cssInputText}
      ${cssInputBox}
    }
  }
  .select__control {
    .select__placeholder {
      /* display: none !important; */
    }
  }

  /***** AUTOCOMPLETE *****/

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    ${cssInputText}
    ${cssInputBox}
    box-shadow: 0 0 0 1000px white inset;
    transition: background-color 5000s ease-in-out 0s;
    &::first-line {
      ${cssInputText}
      font-size: 18px;
      font-weight: 600;
    }
  }

  /***** AUTOFILL *****/

  :autofill,
  :autofill:hover,
  :autofill:focus {
    ${cssInputText}
    ${cssInputBox}
    box-shadow: 0 0 0 1000px white inset;
    transition: background-color 5000s ease-in-out 0s;
    &::first-line {
      ${cssInputText}
      color: red !important;
      font-size: 18px;
      font-weight: 600;
    }
  }

  button {
    border-width: ${layout.borderWidth};
  }

  input[type='time']::-webkit-datetime-edit {
    position: absolute;
    pointer-events: none;
  }

  input[type='time']::-webkit-calendar-picker-indicator {
    width: 100%;
    margin: 0 -${layout.padding};
    padding: ${layout.padding};
    background-position-x: right;
    background-position-y: center;
    background-size: 28px;
    opacity: 0.25;
  }

  .ant-select-dropdown {
    .ant-select-item .ant-select-item-option-content {
      ${cssInputText}/* border: 2px solid red !important;
      color: red !important; */
    }
  }

  .ant-select-selection-placeholder {
    ${cssInputText}
    ${cssPlaceholder}
    margin-left: 0.66em;
  }
`;
