import ReactDatePicker from 'react-datepicker';
import { ForwardedRef, forwardRef, useEffect, useState } from 'react';
import { DATE_FORMAT } from 'i18n/datetime';
import { ES } from 'config';
import { DatePickerProps } from './DatePicker.types'; // TODO: put back, when interface compeleted
import 'react-datepicker/dist/react-datepicker.css';
import { styles } from './DatePicker.css';

export const DatePicker = forwardRef(
  (
    { name, defaultValue, variant = 'inline', minDate = new Date(), onChange, ...props }: DatePickerProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const [dateSelected, setDateSelected] = useState<Date | undefined>();

    useEffect(() => {
      defaultValue && setDateSelected(new Date(defaultValue));
    }, []);

    const handleChange = (date: Date) => {
      setDateSelected(date);
      onChange(date);
    };

    const minDateSetting = (props as any)?.minDate !== false ? (minDate ? new Date(minDate) : minDate) : null;

    return (
      <div css={styles}>
        {variant === 'calendar' ? (
          <ReactDatePicker
            className="input-calendar"
            id={name}
            name={name}
            selected={defaultValue}
            onChange={handleChange}
            placeholderText={DATE_FORMAT.REVERSE}
            minDate={minDateSetting}
            isClearable={true}
            locale={ES}
            dateFormat={DATE_FORMAT.REVERSE}
            autoComplete="off"
            ref={ref as ForwardedRef<HTMLInputElement>}
            inline
            {...props}
          />
        ) : (
          <ReactDatePicker
            className="input-datepicker"
            id={name}
            name={name}
            selected={dateSelected}
            onChange={handleChange}
            placeholderText={DATE_FORMAT.REVERSE}
            minDate={minDateSetting}
            isClearable={true}
            locale={ES}
            dateFormat={DATE_FORMAT.REVERSE}
            autoComplete="off"
            ref={ref as ForwardedRef<HTMLInputElement>}
            {...props}
          />
        )}
      </div>
    );
  },
);
