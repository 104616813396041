import { RadioSVGMap, SVGMap } from 'react-svg-map';
import { useEffect, useState } from 'react';
import { css } from '@emotion/react';

import { colors } from 'styles/colors.css';
import { MapInteractive } from './Map.types';

import 'react-svg-map/lib/index.css';
import { colorsMap, styles } from './Map.css';

// ======================================================================== //

// TODO: AMAZING MAPS ALTERNATIVE !!!
// PIGEON-MAPS:  🌎 🏆 👍🏼
// DEMO: https://pigeon-maps.js.org/
// DOCS: https://pigeon-maps.js.org/docs/installation/
// npmjs: https://www.npmjs.com/package/pigeon-maps
// layer-plug: https://www.npmjs.com/package/pigeon-maps-cluster
// layers: https://www.maptiler.com/maps/
// markers: https://evilmartians.com/chronicles/how-to-build-a-better-react-map-with-pigeon-maps-and-mapbox
// ref: https://evilmartians.com/chronicles/how-to-build-a-better-react-map-with-pigeon-maps-and-mapbox

// ======================================================================== //

export const Map = ({ name, dataSVG, highlighted = [], selected = [], hidden }: MapInteractive) => {
  const [hoverName, setHoverName] = useState();
  // const [selectedProvinces, setSelectedProvinces] = useState<string[]>([]);

  // useEffect(() => {
  //   /* do something */
  //   if (typeof selected === 'string') {
  //     setSelectedProvinces([selected]);
  //   } else {
  //     setSelectedProvinces(selected);
  //   }
  // }, []);

  const handleLocationHover = (event: Event) => {
    // console.log('EV:', event.target.ariaLabel);
    // setHoverName(event.target.ariaLabel);
  };

  const handleChangeMap = (event: Event) => {
    // const handleSearchEvent = (e: KeyboardEvent<HTMLInputElement>) => {
    //   const searchQuery = (e.target as HTMLInputElement).value;
    const searchQuery = (event.target as HTMLInputElement).value;
    console.log('%c EV_PROV:', 'color:lime', searchQuery);
    // setHoverName(event.target.ariaLabel);
  };

  const highlightedCSS: any[] = [];
  highlighted?.map((item: any) => {
    highlightedCSS.push(css`
      #${item.value} {
        fill: ${colorsMap.highlighted}!important;
      }
    `);
  });

  const selectedCSS: any[] = [];
  selected?.map((item: any) => {
    selectedCSS.push(css`
      #${item.value} {
        fill: ${colorsMap.selected}!important;
      }
    `);
  });

  const stylesSelection = css`
    ${highlightedCSS}
    ${selectedCSS}
  `;

  return (
    <section css={styles}>
      <SVGMap onLocationClick={handleChangeMap} className={`svg-map-${name}`} css={stylesSelection} map={dataSVG} />
    </section>
  );
};
