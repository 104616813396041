import { css } from '@emotion/react';
import Color from 'color';
import { layout, colors, min } from 'styles';
import { cssGlassFrostedV3 } from 'styles/special.css';

const colorFooter = Color(colors.primary).desaturate(0.4).alpha(0.6).rgb().string();
const colorFooterHover = Color(colors.primaryDark).desaturate(0.4).alpha(0.6).rgb().string();

const colorLink = Color(colors.primaryXXLight).lighten(0.3).desaturate(0.3).rgb().string();
const colorLinkHover = Color(colors.primaryXXDark).lighten(0.3).desaturate(0.3).rgb().string();

const colorHover = Color(colors.primaryXLight).lighten(0.2).desaturate(0.5).hex();

export const styles = css`
  margin: auto;
  padding: 0;
  /* overflow: hidden; TODO: FIX BOTTOM CORNDERS TO BE ROUNDED */
  border-radius: calc(${layout.borderRadius} * 2);
  transition:
    box-shadow 300ms ease,
    border 200ms ease !important;
  background-color: ${colors.greyLight};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  filter: saturate(1.15) contrast(1.12);
  border: calc(${layout.borderWidth} * 2) solid ${colors.white};
  box-shadow: inset 0 0 0px 1px #ffffff;

  &:not(.disabled):hover {
    cursor: pointer;
    border: calc(${layout.borderWidth} * 2) solid ${colorHover};
    box-shadow: inset 0 0 0px 1px ${colorHover};
    footer.card-footer {
      background-color: ${colorFooterHover};
    }
  }

  ${min.xs} {
    font-size: 1em;
  }
  ${min.sm} {
    font-size: 1.2em;
  }
  ${min.md} {
    font-size: 1.1em;
  }
  ${min.lg} {
    font-size: 1.2em;
  }
  ${min.xl} {
    font-size: 1.1em;
  }
  ${min.xxl} {
    font-size: 1.2em;
  }

  .info {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    padding: calc(${layout.padding}) calc(${layout.padding} / 2) 0 0 !important;
    font-weight: 700;
    text-shadow: 0.05em 0.05em 0.1em rgba(0, 0, 0, 0.8);
    &,
    a {
      color: ${colors.white};
    }
  }

  footer.card-footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    overflow-wrap: anywhere;
    transition: background-color 300ms ease;
    filter: saturate(-0.15) contrast(-0.12);
    background-color: ${colorFooter};
    ${cssGlassFrostedV3};

    ${min.xs} {
      padding: calc(${layout.padding} * 1) calc(${layout.padding} * 2) calc(${layout.padding} * 0.66);
    }
    ${min.sm} {
      padding: calc(${layout.padding} * 1) calc(${layout.padding} * 2) calc(${layout.padding} * 0.66);
    }
    ${min.md} {
      padding: calc(${layout.padding} * 0.8) calc(${layout.padding} * 2);
    }
    ${min.lg} {
      padding: calc(${layout.padding} * 0.8) calc(${layout.padding} * 2);
    }
    ${min.xl} {
      padding: calc(${layout.padding} * 0.66) calc(${layout.padding} * 2);
    }
    ${min.xxl} {
      padding: calc(${layout.padding} * 0.66) calc(${layout.padding} * 2);
    }

    & > .row {
      min-height: 80px;
    }

    .col {
      display: flex;
      align-items: center;
      padding-top: 0.25em;
      padding-bottom: 0.3em;
      color: ${colors.white};
      .list-card-title {
        font-weight: 500;
        .icon-ficha {
          transform: scale(1.4) translate(10%, -10%);
          margin-right: 0.8em;
        }
      }
      .socials {
        margin: 0 -1em -0.2em;
        transform: translate(1%, -5%);
        .btn {
          ${min.xs} {
            font-size: 0.8em;
          }
          ${min.sm} {
            font-size: 1em;
          }
          ${min.md} {
            font-size: 0.9em;
          }
          ${min.lg} {
            font-size: 1em;
          }
          ${min.xl} {
            font-size: 0.9em;
          }
          ${min.xxl} {
            font-size: 1em;
          }
        }
      }
    }

    .col-top {
      padding-top: calc(${layout.padding} * 0.25);
      padding-bottom: calc(${layout.padding} * 0.25);
    }

    h2 {
      font-size: 1.25em;
      line-height: 1.25;
      color: white;
      margin: 0 !important;
    }

    hr {
      margin: 0.7em 0 0.25em 0;
      opacity: 0.85;
    }

    .contact-row {
      width: 100%;
      color: ${colors.white};
      font-size: 1em;
      font-weight: 700;
      text-align: right;
    }

    .info-row {
      width: 100%;
      color: ${colors.white};
      font-size: 1em;
      font-weight: 400;
      padding: 0.1em 0;
    }

    h2.callout-title {
      width: 100%;
      text-align: center;
    }

    .contact-row,
    .info-row {
      a {
        padding-bottom: 0.15em;
        display: inline-block;
        color: ${colorLink};
        &:hover {
          color: ${colorLinkHover};
        }
      }
    }

    .col.col-left .info-row:last-of-type {
      padding-bottom: 0;
    }

    .col.col-right {
      .contact-row,
      .info-row,
      .contact-row,
      .info-row {
        white-space: nowrap;
        text-align: right;
        text-indent: -100%;
      }
      .socials {
        text-align: right;
      }
    }
  }

  aside,
  .aside {
    padding: calc(${layout.padding} * 1.5) calc(${layout.padding} * 2) calc(${layout.padding} * 1)
      calc(${layout.padding} * 1.5);
    transition: background-color 300ms ease;
    filter: saturate(-0.15) contrast(-0.12);
    background-color: ${colorFooter};
    ${cssGlassFrostedV3};

    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-wrap: anywhere;

    h2 {
      font-size: 1.33em;
      line-height: 1.25;
      color: white;
      margin: 0;
      margin-bottom: 0.33em;
    }

    hr {
      margin: 0.8em 0;
      opacity: 0.85;
    }

    .contact-row {
      width: 100%;
      color: ${colors.white};
      font-size: 1em;
      font-weight: 700;
    }

    .info-row {
      width: 100%;
      color: ${colors.white};
      font-size: 0.9em;
      font-weight: 400;
      padding: 0.1em 0;
    }

    .contact-row,
    .info-row {
      a {
        padding-bottom: 0.15em;
        display: inline-block;
        color: ${colorLink};
        &:hover {
          color: ${colorLinkHover};
        }
      }
    }
  }

  &.item-even {
    background-color: white;
  }

  &.item-odd {
    background-color: ${colors.greyLight};
  }

  &.single {
    height: 50vw;
    max-height: 66%;
    ${min.xs} {
      height: 50vw;
    }
    ${min.sm} {
      height: 50vw;
    }
    ${min.md} {
      height: 40vw;
    }
    ${min.lg} {
      height: 25vw;
    }
    ${min.xl} {
      height: 20vw;
      max-height: 300px;
    }
    ${min.xxl} {
      height: 20vw;
      max-height: 300px;
    }
  }

  &.callout footer > .row {
    min-height: 60px;
  }

  .col.col-top.col-right {
    justify-content: start;
  }

  .col-bottom {
    height: 48px;
  }

  .col.col-bottom.col-left {
    justify-content: start;
    font-size: 0.9em;
    svg {
      /* font-size: 0.8em; */
    }
  }
`;
