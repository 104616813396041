/* eslint-disable max-len */
import type { FormConfig, FieldConfig, FormButton, Pattern } from 'components/Form';
/**
 * Various REGEX patterns to identify string TYPE, in order to render dynamically.
 * USAGE: const isURL = URL.test(value);
 */

export const URL = new RegExp(/(https:\/\/)|(http:\/\/)/);
export const URL_V2_ERR = new RegExp(/[(http(s)?)://(www.)?\w-/=#%&.?]{2,}\.[a-z]{2,}([\w-/=#%&.?]*)/);

export const EMAIL = new RegExp(/[a-z0-9_-]+@[a-z-]+\.[a-z]{2,3}/);
export const EMAIL_V1_ERR = new RegExp(
  /^([a-zA-Z0-9_\-.]+)*(\+[a-z0-9-]+)?@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/,
);

// TEL SPAIN:
export const TEL = new RegExp(
  /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})$/,
);

export const PASSWORD = new RegExp(/(?=.*d)(?=.*[a-z])(?=.*[A-Z])(?!.*s)(?=.*[!@#$*])/);
export const POSTAL_CODE = new RegExp(/^[0-9]{5}$/);

/*
OTHER UUID FORMATS:
/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
/^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i/
/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
*/
export const UUID_V4 = new RegExp(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/i);

export const PATTERNS: { [key: string]: RegExp } = {
  URL,
  URL_V2_ERR,
  EMAIL,
  TEL,
  PASSWORD,
  POSTAL_CODE,
  UUID_V4,
};
