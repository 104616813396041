import { ReactElement } from 'react';
import { MenuItem } from '@szhsin/react-menu';
import { RouteConfig } from 'routes';
import { NavItem } from 'components/Nav/NavItem';

// NEW: CSS-Only DROPDOWN MENU:
// https://csslayout.io/nested-dropdowns/

/**
 * REACT-MENU_DOCS
 * https://www.npmjs.com/package/@szhsin/react-menu
 * https://codesandbox.io/s/react-menu-starter-3ez3c
 * https://codesandbox.io/s/react-menu-starter-3ez3c
 *
 * REACT-ROUTER EXAMPLE:
 * https://codesandbox.io/s/react-menu-react-router-example-dw4ku
 */

export const MenuSubItems = ({ routes }: { routes: RouteConfig[] }): ReactElement[] => {
  const routesVisible: RouteConfig[] = routes.filter(
    (route: RouteConfig) => route.isActive && route?.nav?.isHidden !== true,
  );

  return routesVisible?.map((route) => (
    <MenuItem key={route.pathname}>
      <NavItem path={route.pathname} title={!route.title ? route.path : route.title} />
    </MenuItem>
  ));
};
