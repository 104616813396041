import config from './config';
import { type AppConfiguration, type DotEnvVars, Language } from './config.types';

export * from './config';
export { default as config } from './config';
export { ES } from 'i18n/locale';
export { configSocials } from './socials.config';
export { type AppConfiguration, type DotEnvVars, Language };

// NODE ENVIRONMENT
const NODE_ENV = process.env.NODE_ENV || 'development';
export { NODE_ENV };

// EXPORT FULL CONFIG
export default {
  NODE_ENV,
  ...config,
} as AppConfiguration & DotEnvVars;
