import { css } from '@emotion/react';
import { layout } from 'styles/layout.css';
import { colors } from 'styles/colors.css';

export const styles = css`
  width: auto;
  margin: calc(${layout.padding} * 0.5) calc(${layout.padding} * 1);
  padding-left: calc(${layout.padding} * 0.5);

  &:not(.is-disabled) {
    /* cursor: pointer; */
  }

  &.is-disabled {
    cursor: default;
    /* pointer-events: none; */
  }

  .col-input {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: unset !important;
    flex: 0 1 auto !important;

    label {
      padding-left: calc(${layout.padding} * 1.5);
      white-space: nowrap;
    }

    input {
      display: none;
    }

    .toggle-checkbox {
      /* padding: 0 calc(${layout.padding} * 0.5); */
      &.is-disabled {
        cursor: not-allowed;
        opacity: 0.4;
      }

      &.size-xs {
        transform: scale(1);
      }
      &.size-sm {
        transform: scale(1.5);
      }
      &.size-md {
        transform: scale(2);
      }
      &.size-lg {
        transform: scale(2.8);
      }
      &.size-xl {
        transform: scale(4);
      }

      svg:not(.icon-large) {
        border-radius: ${layout.borderRadius};
        box-shadow: inset 0 0 0 2px ${colors.greyXXLight};
        background: ${colors.greyXLight};
        color: ${colors.grey};
      }

      svg.icon-on {
        display: none;
        /* color: ${colors.successDark}; */
      }
      svg.icon-off {
        display: flex;
      }

      &.is-checked {
        svg.icon-on:not(.icon-large) {
          display: flex;
          background: white;
          color: ${colors.successDark};
        }
        svg.icon-off {
          display: none;
        }
      }

      &:not(.is-disabled).is-checked:hover {
        svg.icon-on {
          display: flex;
          background: white;
          color: ${colors.successDark};
        }
        svg.icon-off {
          display: none;
        }
      }

      /* FOR CUSTOM ICONS - PASSED AS PROPS */

      /* &.is-checked {
      svg.icon-on.icon-large {
        display: flex;
        transform: scale(1.3);
        box-shadow: inset 0 0 0 2px transparent;
        background: transparent!important;
        border-radius: ${layout.borderRadius};
        color: ${colors.grey};
      }
    } */
    }
  }

  .col-label {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex: 1 0 auto;
    padding: 0 calc(${layout.padding} * 0.5);
    pointer-events: none !important;

    &.is-inline {
      max-width: fit-content !important;
    }

    button {
      pointer-events: all !important;
      /* border: 1px solid blue !important; */
    }
  }

  .error-msg.error-required {
    /* HIDE ERR TEXT, SHOW ERR ICON ONLY */
    pointer-events: none;
    color: transparent;
    transform: translate(100%, -100%);
    padding-left: 1.5em;
    svg {
      color: ${colors.danger};
    }
  }
`;
