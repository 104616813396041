import type { RouteObject } from 'react-router-dom';
import type { RouteConfig } from 'routes/routes.types';
import cloneDeep from 'lodash/cloneDeep';
import { removeUndefinedProps } from 'utils/utils.object';
import { CatMeta } from 'types/metadata.types';

export const flatttenChildren = <T extends RouteObject | RouteConfig | CatMeta>(routes: T[]): T[] => {
  const flattened: T[] = [];

  const flatten = (routes: T[]) => {
    for (const route of routes) {
      flattened.push({ ...route, children: undefined }); // Add the route without children
      if (route?.children && Array.isArray(route.children)) {
        flatten(route.children as T[]); // Recursively flatten children
      }
    }
  };

  flatten(routes);

  return flattened;
};

export const flatttenChildren__V1 = <T extends RouteObject | RouteConfig | CatMeta>(routes: T[]): T[] => {
  const flatRoutes: T[] = [];

  const flatten = (acc: T[], route: T): T[] => {
    const { children, ...rest } = route;
    acc.push({ ...rest } as T);

    if (Array.isArray(children) && children.some((e: any) => e.path !== ':id')) {
      return children.reduce(flatten, acc);
    }

    return acc;
  };

  return flatRoutes.reduce(flatten, cloneDeep(routes));
};
