import { css } from '@emotion/react';
import Color from 'color';
import { colors, layout, border, forms, max, cssFontDefaults } from 'styles';

// NOTE: CONSIDER USING `::file-selection-button`
// const hoverColor = Color('#7743CE').alpha(0.1).lighten(0.5);

export const colorDropzone = {
  // light: Color(colors.grey).lighten(0.4).hex(),
  // med: Color(colors.grey).lighten(0.6).hex(),
  hover: Color(colors.successLight).lighten(0.35).desaturate(0.5).hex(),
};

export const styles = css`
  transition: background 0s;

  p {
    padding: 0;
    margin: 0;
  }
  /*********************************************** */

  .progress-bar {
    opacity: 0;
  }

  /*********************************************** */

  .dropzone-container {
    height: ${layout.imagePreview.height};
    box-shadow: inset 0 0 0 8px white;
    background-size: cover;
    background-position: 50%;
    overflow: hidden !important;
    margin: 0 0 0 0 !important;
    position: absolute;
    bottom: calc(${forms.inputs.height} + (${layout.padding} * 3));
    left: 0;
    right: 0;

    transform: translateY(calc(${layout.padding} * 2));

    display: flex;
    align-items: center;
    justify-content: center;

    /*********************************************** */

    border-radius: ${layout.borderRadius};
    padding: calc(${layout.padding} * 2) calc(${layout.padding} * 2) calc(${layout.padding} * 1)
      calc(${layout.padding} * 2) !important;
    margin: calc(${layout.padding} * 2) calc(${layout.padding} * 1.15) !important;
    border: 2px dashed ${colors.greyLight} !important;
    &:hover {
      /* border: 2px dashed ${colors.successDark} !important; */
      /* background: ${colorDropzone.hover}; */
    }
    &.drag-over {
      border: 2px dashed ${colors.successDark} !important;
      background: ${colorDropzone.hover}!important;
      button.btn-add-file {
        /* border: 2px dashed ${colors.successDark} !important; */
        pointer-events: none;
        background: transparent;
      }
    }
  }
  /*********************************************** */

  .file-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    margin-top: 20px;
  }

  .file-item-added {
    display: flex;
    align-items: center;
    margin: 10px 0;
    justify-content: flex-start;
    svg {
      opacity: 0.66;
    }

    .filename p {
      font-weight: 600;
      color: ${colors.grey};
      svg {
        /* font-size: 1.25em; */
        opacity: 0.66;
        margin-right: 0.33em;
      }
    }

    .icon-remove svg {
      opacity: 0.66;
      cursor: pointer !important;
      &:hover {
        opacity: 1;
        color: ${colors.danger}!important;
        fill: ${colors.danger}!important;
        stroke: ${colors.danger}!important;
        -webkit-text-fill-color: ${colors.danger}!important;
      }
      /* color: ${colors.danger}!important;
      fill: ${colors.danger}!important;
      stroke: ${colors.danger}!important;
      -webkit-text-fill-color: ${colors.danger}!important; */
      /* font-size: 1.8em; */
      /* margin-right: 0.33em; */
    }
  }

  /*********************************************** */
`;
