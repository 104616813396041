import { css } from '@emotion/react';
import Color from 'color';
import { colors, layout, BREAKPOINTS, min, max } from 'styles';

const colorFooter = Color(colors.primaryXDark).desaturate(0.2).alpha(0.75).rgb().string();
const colorFooterHover = Color(colors.primaryDark).desaturate(0.4).alpha(0.6).rgb().string();

export const styles = css`
  /* h1 {
    font-size: 3em;
    margin-bottom: 0;
    color: ${colors.primary};
    span {
      color: ${colors.greyLight};
      opacity: 1;
    }
  } */
  p.text-main {
    font-size: 1.25em;
    margin-bottom: 1.5em;
  }

  .highlights {
    margin: 0 -${layout.padding};
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: ${layout.padding} 0 calc(${layout.padding} * 3) 0;
    padding h1 {
      text-align: center;
      transform: translateY(-${layout.padding});
    }

    .grid-item-container {
      box-sizing: border-box;
      height: 0;
      position: relative;
      &:nth-of-type(1) .card {
        /*  margin-right: 0; */
      }
      &:nth-of-type(3) .card {
        /* margin-left: 0; */
      }
    }

    .grid-item-gutter {
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: stretch;
      align-items: stretch;
      flex-direction: column;
      padding-left: 15px;
      padding-right: 15px;
    }

    .card {
      box-sizing: border-box;
      margin: ${layout.padding};
      /* width: 100%; */
      max-height: unset;
      left: 0;
      right: 0;
      position: absolute;
      top: 50px;
    }
  }

  .grid-item-container {
    /* padding-top: 20% !important; */
  }

  /*
  h2 {
    color: ${colors.greyXDark};
  }

  & > div > div > div {
    height: 100%;
    padding-bottom: calc(${layout.padding} * 1);
  }

  & section {
    background-color: transparent;
    padding: 0;
    & > div > div {
    }
    & > div > div > div {
      padding: 0 !important;
    }
  }

  */

  /*
  hr {
    margin: 1.7em 0 1.3em;
  }

  pre {
    font-size: 0.7em;
  }


  header {
    padding: 0;
    margin-bottom: ${layout.padding};
    .page-header-left {
      font-size: 1.1em;
      white-space: nowrap;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: ${colors.grey};
      strong {
        color: ${colors.primary};
        margin-left: 0.5em;
        &.error {
          color: ${colors.warning};
        }
      }

      ${max['sm']} {
        flex-direction: column;
        strong {
          display: block;
          margin-left: 0;
        }
      }
    }
    .page-header-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    @media (max-width: ${BREAKPOINTS.sm}px) {
      margin-bottom: 0;
      .page-header-left,
      .page-header-right {
        justify-content: center;
        button {
          margin: ${layout.padding} 0;
        }
      }
    }
    .alert {
      width: 100%;
      min-height: 50px;
      padding: 0 2em 0 0;
      flex-direction: unset;
      margin: 1em 0 0.5em 0;
      border-radius: ${layout.borderRadius};
      svg {
        height: 40px;
        transform: translateY(-2px);
      }
    }
  }
  */
`;

// TODO: BELOW INTENDED FOR - HILIGHTS
export const responsiveness = {
  [min.xs]: {
    flexBasis: '100%',
    paddingTop: '100%',
    fontSize: '1.5em',
  },
  [min.sm]: {
    flexBasis: '100%',
    paddingTop: '60%',
    fontSize: '1.4em',
  },
  [min.md]: {
    flexBasis: '100%',
    paddingTop: '50%',
    fontSize: '1.2em',
  },
  [min.lg]: {
    flexBasis: '100%',
    paddingTop: '40%',
    fontSize: '1.2em',
  },
  [min.xl]: {
    flexBasis: '50%',
    paddingTop: '33%',
    fontSize: '1.2em',
  },
  [min.xxl]: {
    flexBasis: '33.33%',
    paddingTop: '28%',
    fontSize: '0.95em',
  },
};
