/* eslint-disable new-cap */
import { css } from '@emotion/react';
import Color from 'color';
import { layout, colors, min } from 'styles';
import { cssGlassFrostedV3 } from 'styles/special.css';

const colorFooter = Color(colors.primary).desaturate(0.4).alpha(0.6).rgb().string();
const colorFooterHover = Color(colors.primaryDark).desaturate(0.4).alpha(0.6).rgb().string();

const colorLink = Color(colors.primaryXXLight).lighten(0.3).desaturate(0.3).rgb().string();
const colorLinkHover = Color(colors.primaryXXDark).lighten(0.3).desaturate(0.3).rgb().string();

const colorHover = Color(colors.primaryXLight).lighten(0.2).desaturate(0.5).hex();

const rowHeight = '40px';

export const styles = css`
  margin: auto;
  margin-top: 2px !important;
  margin-bottom: 2px !important;
  padding: 0;
  overflow: hidden;
  border-radius: calc(${layout.borderRadius} * 2);
  transition:
    box-shadow 300ms ease,
    border 200ms ease !important;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  filter: saturate(1.15) contrast(1.12);

  box-shadow: inset 0 0 0px 1px #ffffff;

  & > .row {
    margin-left: 0 !important;
  }

  &.header-item {
    box-shadow: none;
    background: ${Color(colors.greyLight).lighten(0.1).hex()}!important;
    border: ${layout.borderWidth} solid transparent !important;
    &:hover {
      border: ${layout.borderWidth} solid transparent !important;
      /* background: ${colors.greyDark}!important; */
    }
    .th {
      font-weight: 700;
      color: ${Color(colors.grey).darken(0.2).hex()};
      justify-content: center;
      &.col-name {
        text-indent: -2em;
      }
    }
  }

  .col {
    display: flex;
    align-items: center;
    /* border: 1px solid yellow; */

    span {
      /* border: 1px solid silver; */
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 calc(${layout.padding} * 1);
    }
  }

  // ======================================================================== //

  .col-image {
    background-color: ${colors.greyLight};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    & + div {
    }
  }

  .col-name {
    font-weight: 700;
  }

  .col-id,
  .col-action {
    justify-content: center;
  }

  .col-info {
    & > .row {
      height: 100%;
      width: 100%;
      .col {
        /* border: 1px solid orange; */
      }
    }
  }

  .icon-action {
    font-size: 2em;
    cursor: pointer;
    color: ${colors.primaryXLight};
    &:hover {
      color: ${colors.primaryDark};
    }
  }

  .icon-action-contact,
  .icon-action-view {
    path {
      stroke: ${colors.primaryXLight};
    }
    &:hover {
      path {
        stroke: ${colors.primaryDark};
      }
    }
  }

  // ======================================================================== //

  &:not(.disabled):hover {
    cursor: pointer;
    border: ${layout.borderWidth} solid ${colorHover};
    /* border: 1px solid ${colorHover}; */
    /* box-shadow: inset 0 0 0px 1px ${colorHover}; */
    footer.card-footer {
      background-color: ${colorFooterHover};
    }
  }

  ${min.xs} {
    font-size: 1em;
  }
  ${min.sm} {
    font-size: 1.2em;
  }
  ${min.md} {
    font-size: 1.1em;
  }
  ${min.lg} {
    font-size: 1.2em;
  }
  ${min.xl} {
    font-size: 1.1em;
  }
  ${min.xxl} {
    font-size: 1.2em;
  }

  &.item-even {
    background-color: white;
  }

  &.item-odd {
    background-color: ${colors.greyXLight};
    background-color: white;
    /* border: ${layout.borderWidth} solid ${colorHover}; */
  }
`;
