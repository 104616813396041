import { INPUTS } from 'types/form.types';
import type { FieldConfig } from 'components/Form';
import { optionsAutofill, optionsSetLoud } from 'components/Form/config';
import { genMockValueV2, genMockValueByType } from 'mocks/generators';
import type { MockFieldsFn, UnknownArrayOrObject } from '../components/_DevFormState/DevFormState.types';

// GREAT "DIRTY FIELDS_MOCKED" REF:
// https://codesandbox.io/s/react-hook-form-submit-only-dirty-fields-ol5d2?file=/src/index.tsx:105-901

// NOTE: USED BY FORM DEV FOOTER "mock values" BUTTON..
export const autofillMockData = ({
  fields,
  formMethods: { getValues, setValue },
  onlyMockEmpty = false,
}: MockFieldsFn) => {
  const fieldsToMock = fields.filter((field: FieldConfig) => {
    const fieldElement = document.querySelector(`[name="${field.name}"]`) as HTMLInputElement;
    const fieldValue = fieldElement ? fieldElement?.value : field?.value;
    return onlyMockEmpty ? !!(field.name && !fieldValue) : !!field.name;
  });

  // ======================================================================== //
  // TODO: TESTING... DEV ONLY.. REMOVE.
  // TODO: add `mockValue` to FILTER
  console.clear();

  const UNABLE_TO_MOCK: any = [];
  const FIELDS_MOCKED: any = [];

  fieldsToMock.map((field: FieldConfig) => {
    const mockedValue = genMockValueV2({ field, getValues });
    if (mockedValue) {
      field.mockValue = mockedValue;
      FIELDS_MOCKED.push({ name: field.name, inputType: field.inputType, mockValue: field.mockValue });
    } else {
      UNABLE_TO_MOCK.push({ name: field.name, inputType: field.inputType, mockValue: field.mockValue });
    }
  });

  log('DEV: FIELDS TO MOCK - UNABLE_TO_MOCK', 'yellow', UNABLE_TO_MOCK);
  log('DEV: FIELDS TO MOCK - FIELDS_MOCKED', 'yellow', FIELDS_MOCKED);
  log('DEV: FIELDS TO MOCK:', 'orange', fieldsToMock);

  // ======================================================================== //

  for (const field of fieldsToMock as FieldConfig[]) {
    if (!field?.name) continue;
    if (field.name === 'json_meta') continue;
    if (field.name === 'FORM_COMPLETION_PERCENTAGE') continue;
    if (field.name === 'uuid') continue;
    if (field.name === 'user_role') continue;
    if (field.name === 'accept') continue;
    if (field?.readOnly) continue;

    const hasMockFunction = typeof field.mockValue === 'string' && field.mockValue.startsWith('MOCK_');

    if (field.mockValue) {
      const fieldElement = document.querySelector(`[name="${field.name}"]`) as HTMLInputElement;
      const MOCKED_VALUE = hasMockFunction ? genMockValueByType(field.mockValue as string) : field.mockValue;
      setValue(field.name, MOCKED_VALUE, optionsAutofill);
      if (fieldElement) {
        fieldElement.value = MOCKED_VALUE;
        fieldElement.focus();
        fieldElement?.blur();
      }
    }

    // TOGGLE
    if (field.name?.startsWith('is_')) {
      const fieldElement = document.querySelector(
        `[aria-label="toggle__${field?.label}"]`,
      ) as HTMLInputElement;
      if (fieldElement) {
        fieldElement?.focus();
        const indexRandom = genMockValueV2({ field, getValues });
        const boolRandom = indexRandom === 1 ? true : false;
        fieldElement.defaultChecked = boolRandom;
        boolRandom && fieldElement?.click();
        fieldElement?.blur();
      }
      continue;
    }

    // SELECT
    else if (field.inputType?.startsWith('select')) {
      const fieldElement = document.querySelector(`[aria-label="${field?.label}"]`) as HTMLSelectElement;
      fieldElement?.focus();
      const mockValue = genMockValueV2({ field, getValues });
      field.value = mockValue;
      setValue(field.name, mockValue, optionsAutofill);
      fieldElement?.blur();
      continue;
    }

    // REPEATER (standard)
    else if (field.inputType === INPUTS.repeater) {
      log(`DEV5: MOCKING REPEATER - ${field.name}`, 'cyan', field.inputType, field);
      const mockValue = genMockValueV2({ field, getValues });
      setValue(field.name, mockValue, optionsAutofill);
      continue;
    }

    // DEFAULT (NON-CHECKBOX, NON-SELECT...)
    // else if (!field.inputType?.startsWith('select')) {
    else {
      const fieldElement = document.querySelector(`[name="${field.name}"]`) as HTMLInputElement;
      if (fieldElement) {
        fieldElement?.focus();
        // const mockValue = genMockValue(field.name); // TODO: ENSURE IS WORKING CORRECTLY
        const mockValue = genMockValueV2({ field, getValues });
        field.value = mockValue;
        setValue(field.name, mockValue, optionsAutofill);
        fieldElement?.blur();
      }
    }
    log('DEV: ----------------------', 'grey');
  }
};
