import { css } from '@emotion/react';
import { layout } from 'styles/layout.css';
import { colors } from 'styles/colors.css';
import { max, min } from 'styles/media-queries-V1';

export const styles = css`
  width: 100%;
  height: 100%;
  padding: 0;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: calc(100vh * 0.05);

  &:not(*-LoggedOut) .card {
    min-height: 720px;
  }

  form {
    section {
      margin: 0 !important;
      border-bottom: none !important;
    }
  }

  button {
    width: 100%;
  }

  ${max.sm} {
    background-color: white;
  }

  .card {
    width: 100%;
    padding-bottom: calc(${layout.padding} * 2);
    max-width: 90vw;
    border: none;
    box-shadow: none;
    min-height: 560px;
    ${min.sm} {
      min-width: 350px;
      max-width: 550px;
      border-radius: ${layout.borderRadius};
      border: ${layout.borderWidth} solid ${colors.greyXLight};
      box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.1);
    }

    .alert {
      min-height: 180px;
      margin-bottom: calc(${layout.padding} * 2);
      .spinner {
        height: 48px;
      }
      svg {
        font-size: 3em;
        padding: 0 0 0.25em 0;
      }
      p {
        min-height: 1em;
      }
    }
    .tabbed-container {
      min-height: 450px;
      .tab:hover {
        color: ${colors.grey};
        border-color: ${colors.greyLight};
      }
      .tab.selected {
        color: ${colors.greyDark};
        border-color: ${colors.grey};
        &:hover {
          color: ${colors.greyXDark};
          border-color: ${colors.greyDark};
        }
      }

      .tabbed-content {
        margin-top: ${layout.padding};
      }
      .form-status-message,
      .form-dev {
        display: none !important;
      }
      hr {
        display: none !important;
      }
    }

    .tabbed-content {
      &.tab-login {
        label span {
          display: none;
        }
        button[type='submit'] {
          max-width: unset;
          width: 100%;
        }
      }
      label {
        color: ${colors.greyDark};
        span {
          color: ${colors.greyLight};
        }
      }
    }

    .row-buttons {
      padding-top: ${layout.padding};
    }
  }

  &.logged-out .card {
    min-height: unset;
  }

  .form-container {
    width: 100%;
    & > div {
      height: 100%;
    }

    button {
      width: 100%;
      &:disabled {
        /* background-color: #eee !important; */
        background-color: ${colors.grey} !important;
        border-color: ${colors.grey} !important;
      }
    }

    input + button {
      width: unset;
    }

    section {
      border: none;
    }

    fieldset:last-of-type {
      /* padding-bottom: calc(${layout.padding} * 3); */
      /* border-bottom: 2px solid #dcdcdc; */
    }
  }

  label {
    color: ${colors.warningDark};
    color: transparent;
  }
`;
