import { css } from '@emotion/react';
import { colors } from 'styles/colors.css';

export const styles = css`
  box-sizing: border-box;
  /* min-height: 2.2em; */
  padding: 0.15em 0 0;
  text-align: left;
  font-size: 0.9em;
  font-weight: 600;
  font-style: italic;
  /* line-height: 1.5; */
  color: ${colors.warning};
  &.error-required {
    color: ${colors.danger};
  }
  svg {
    position: absolute;
    vertical-align: middle;
    transform: scale(1.5) translateX(-105%) translateY(0.12em);
  }
`;
