import type { TableConfig } from 'types';
import { FORMAT } from 'types/form.types';
import { LinkToDetail, LinkToCategory } from 'components/DataTable/components';
import { ListItemCard } from 'components/ListItemCard';
import { DATATYPE, ENDPOINT } from './canales.config';
import { COL_TYPE } from 'components/DataTable/DataTable.types';

// ----------------------------------------------------------------- //
// TABLE CONFIG:
export const tableConfig: TableConfig = {
  dataType: {
    key: DATATYPE,
    role: 'table',
  },
  list: {
    colWidth: {
      xs: 12,
      md: 6,
    },
    listItem: {
      component: ListItemCard,
      render: (props?: any) => <ListItemCard {...props} />,
      shapeRatio: 2.2,
    },
    search: {
      filterByKeys: ['name'],
    },
  },
  actions: {
    hasActionView: true,
    hasActionEdit: false,
    hasActionNew: false,
  },
  api: {
    endpoint: ENDPOINT,
  },
  columns: [
    {
      label: 'NAME-CANALES',
      name: 'name',
      columnType: COL_TYPE.DATA,
      isSortable: true,
      render: ({ data }) => <LinkToDetail targetID={data.id} label={data.name} />,
    },
    /*
    {
      label: 'EMAIL',
      name: 'email',
      // width: 2,
      widths: {
        xs: 0,
        lg: 2,
      },
      isSortable: true,
      render: ({ data }) => <LinkToDetail targetID={data.id} label={data.email} />,
    },
    */
    {
      label: 'CATEGORÍA',
      name: 'cat_id',
      columnType: COL_TYPE.DATA,
      // width: 2,
      width: false,
      isSortable: true,
      valueFormat: {
        type: FORMAT.BY_ID, // TODO: NO TS TYPE WARN ???????
        source: 'cat_id',
      },
      render: ({ data }) => (
        <LinkToCategory
          catId={data.cat_id}
          //
          // basePath="/registros"
        />
      ),
    },
    {
      label: 'PROVINCE',
      name: 'province',
      columnType: COL_TYPE.DATA,
      isSortable: true,
      render: ({ data }) => <LinkToDetail targetID={data.province} label={data.province} />,
    },
    {
      label: 'ID',
      name: 'id',
      columnType: COL_TYPE.DATA,
      // width: 2,
      widths: {
        xs: 0,
        lg: 1,
      },
      isSortable: true,
      render: ({ data }) => data.id,
    },
  ],
};
