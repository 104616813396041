import { FORMAT } from 'types/form.types';
import { CatMeta } from 'types/metadata.types';
import { RouteConfig } from 'routes';
import { SelectOption } from 'components/FormUI/Select';
import { toUpperCaseFirst } from 'utils';
import type { FieldConfig } from '../components/DetailSummary/DetailSummary.types';

export const formatFieldValue = ({ field, route }: { field: FieldConfig; route: RouteConfig }) => {
  const valueFormat = field.valueFormat;

  log('valueFormat', 'cyan', valueFormat);
  if (!valueFormat || !valueFormat.type) return;

  // NOTE: FORMAT VALUE, IF REQUIRED
  if (valueFormat.type === FORMAT.BY_OPTION) {
    if (valueFormat.options) {
      const selectedOption = (valueFormat.options as SelectOption[]).find((option) => option.value === field.value);
      field.value = selectedOption ? selectedOption.label : field.value;
    }
  }

  // NOTE: FORMAT VALUE, IF REQUIRED
  if (valueFormat.type === FORMAT.TAGS) {
    if (Array.isArray(field.value)) {
      // log('BY_TAGS', 'cyan', valueFormat, field);
      // const selectedOption = (valueFormat.options as SelectOption[]).find((option) => option.value === field.value);
      // field.value = selectedOption ? selectedOption.label : field.value;
    }
  }

  if (valueFormat.type === FORMAT.BY_ID) {
    if ((valueFormat.source as string).endsWith('_id')) {
      field.value = { value: toUpperCaseFirst((route?.category as CatMeta)?.title), url: route?.category?.path };
    }
  }
};
