import React from 'react';
import { ReactElement, ComponentType } from 'react';
import type { RouteObject } from 'react-router-dom';
import ListPage from 'pages/ListPage';
import DetailPage from 'pages/DetailPage';
// import type { FormConfig, TableConfig } from 'types/form.types';
import { getDynamicRoutes } from 'routes/utils/getDynamicRoutes';
import { type DataConfiguration, DataActions } from 'routes/utils/getDynamicRoutes.types';
import type { DetailConfig } from 'components/DetailSummary/DetailSummary.types';
import type { GlobalStoreValues } from 'store/GlobalSchema.types';

// CONFIGS:
import * as canales from 'config/datatype/canales';
import * as entidades from 'config/datatype/entidades';
import * as periodicos from 'config/datatype/periodicos';
import { RouteConfig } from 'routes/routes.types';

const configs: { [key: string]: DataConfiguration['dataConfig'] } = {
  canales,
  periodicos,
  entidades,
};

export const getWebCategoryRoutes = ({ store }: { store: GlobalStoreValues }): RouteObject[] => {
  if (!store?.categories) {
    return [];
  }

  const routesWebCategories: RouteObject[] = getDynamicRoutes(store.categories, {
    basePath: 'cat',
    actions: [DataActions.LIST, DataActions.VIEW],
    components: {
      list: ListPage, // Component for LIST action
      view: DetailPage, // Component for VIEW action
    },
  });

  return routesWebCategories;
};

// ======================================================================== //

export const routesWebCategories__V1: RouteObject[] = [
  {
    path: 'canales',
    children: [
      { index: true, element: <ListPage dataConfig={configs.canales} /> },
      { path: 'view/:id', element: <DetailPage dataConfig={configs.canales} /> },
      {
        path: ':cat',
        children: [
          { index: true, element: <ListPage dataConfig={configs.canales} /> },
          { path: 'view/:id', element: <DetailPage dataConfig={configs.canales} /> },
        ],
      },
    ],
  },
  {
    path: 'periodicos',
    children: [
      { index: true, element: <ListPage dataConfig={configs.periodicos} /> },
      { path: 'view/:id', element: <DetailPage dataConfig={configs.periodicos} /> },
      {
        path: ':cat',
        children: [
          { index: true, element: <ListPage dataConfig={configs.periodicos} /> },
          { path: 'view/:id', element: <DetailPage dataConfig={configs.periodicos} /> },
        ],
      },
    ],
  },
  {
    path: 'entidades',
    children: [
      { index: true, element: <ListPage dataConfig={configs.entidades} /> },
      { path: 'view/:id', element: <DetailPage dataConfig={configs.entidades} /> },
      {
        path: ':cat',
        children: [
          { index: true, element: <ListPage dataConfig={configs.entidades} /> },
          { path: 'view/:id', element: <DetailPage dataConfig={configs.entidades} /> },
        ],
      },
    ],
  },
];
