import React from 'react';
import { createStore } from 'zustand';
import { createZustandContext } from 'utils/zustand';
import type { PageContentStore, PageContentValues } from './PageContent.types';
import type { HeaderButton } from 'types/header.types';
import { IoChevronBackSharp as IconBack } from 'react-icons/io5';
import { initialState } from 'auth/auth.config';
import { t } from 'i18next';

export enum PageContentKeys {
  Title = 'title',
  Detail = 'detail',
  Subtitle = 'subtitle',
  HeaderButton = 'headerButton',
  IsHiddenBreadcrumbs = 'isHiddenBreadcrumbs',
}

const initialStateValues: PageContentValues = {
  title: '',
  detail: '',
  subtitle: '',
  headerButton: null,
  isHiddenBreadcrumbs: false,
};

export const PageContent = createZustandContext((initialValues: PageContentValues = initialStateValues) => {
  return createStore<PageContentStore>((set, get) => ({
    ...initialValues,
    actions: {
      resetHeader: () => {
        set((state) => ({ ...state, ...initialState, headerButton: null, isHiddenBreadcrumbs: false }));
      },
      setPageTitle: (val: string) => {
        set({ title: val });
      },
      setPageDetail: (val: string) => {
        set({ detail: val });
      },
      setPageSubtitle: (val: string) => {
        set({ subtitle: val });
      },
      setPageHeaderButton: (val: HeaderButton) => {
        const headerButtonProps = !!val?.isBackButton
          ? {
              isHidden: false,
              label: t('t.buttons.backTo', { to: val?.backRoute?.title }),
              icon: IconBack,
              iconPos: 'left',
              iconScale: 0.4,
              color: 'warning',
              onClick: () => {
                // NOTE: `history.goBack()` is not recommended, as it's not reliable..
                // ref: https://reactrouter.com/web/api/history
                // history.goBack();
                // history.push((val as HeaderBackButton).route.pathname);
                // history.push((val as HeaderBackButton).route.pathname);
              },
            }
          : val;

        set({ headerButton: headerButtonProps as HeaderButton });
      },
      setPageIsHiddenBreadcrumbs: (val: boolean) => {
        set({ isHiddenBreadcrumbs: val });
      },
    },
  }));
});

// ======================================================================== //
// NOTE: REFERENCES..

// @tkdodo - https://tkdodo.eu/blog/working-with-zustand
// https://tkdodo.eu/blog/zustand-and-react-context
// @MattPockock - https://x.com/mattpocockuk/status/1780865485325979685?s=46&t=cmQVCdpY7_bVoOftn8NIcg
// https://www.typescriptlang.org/play/?#code/JYWwDg9gTgLgBAJQKYEMDG8BmUIjgIilQ3wG4AoUSWOAbzgGUZokBBMYOAXzm1wIBeAVwDOMFADsAJmUrho8emiIoYSJi268cefMLGSpAegBuk4ABsLKWeTQQJYuMtRqAWqPHSAwg7UAPeABeOAAecjg4ABUASQlgGGAUCwAaCOiNIjgkQKRpEUZmInZgUMkATwA+NMqACnSAcyQYTKQALjha4HjE5I7YnqSLAEo4IMqMoqQ00fG6dPtHeF8JAODEYhgAOhdVJBW1+sjIiSErOBQCiouCqNa0yOGKBYcnAAUcE2ApJCgxzrAODAIg6tHSkTQAAtLFIiBIAPwdZDobbIjAAOQgPwoxzg3QSQwAaskhO1onECckcdxZhMwbjFk4ANpiFgAXX+aO2onU4jUtVqtPBxyaLSmtUBEGBW3xvQsxIspOGD2OT3IwqIMCEUAknWFkVCBxy2w+EC+Pz+ZkVSCCtFZRC4lX1x1okulUJhcK4ztCRiNgS2pvNvyduLVkS4z0imu1uvpxx5-pgHUFYwmXK2ib8xtqSeVwqTKsiQe+vxVkfIFfIMHKYCQhT2-3jACNUFAQXBTiBW1Bqd1diIkG8pWdVMAHCmux0uz3aXATBBvhQq4z4AAhNtMISYTD-XbuTyGJNHTqyoZMPaguCtlDt6dCbu-Glp+a4mM65wqNStUIXtR1YYBUHGA51qeNcRvO88UGZI-yQLZIJEItjn7FRB2HMBR0SCdOmbcohVxXFgKAvkkFA8DCMiRCOgMNQELbAoAGpr3KZDcS4YZ83YzjqS4GZnlXOB2DAf5UzmeN311E8DQ3W8tx3QNPlLKBnTPZIFVJW0KIghiOgARjY7hvVxUNCIAWXKISwDAYVfVkqB5MwRSzWU0y1QrIA
// gist: https://gist.github.com/bryanltobing/e09cb4bb110c4d10cefde665b572d899
