import { css, keyframes } from '@emotion/react';
import { styles as cssButton } from 'components/Button/Button.css';
import { colors, layout, border, cssBoxShadow, cssGlassFrostedV2 } from 'styles';
import { MODAL_ANIMATION_MS } from './Modal.config';

export const modalConfig = {
  transition: `transform ${MODAL_ANIMATION_MS}ms cubic-bezier(0.75, 0.25, 0.25, 0.75)`,
  overlay: {
    // color: 'rgba(255, 255, 255, 0.33)',
    color: 'rgba(255, 255, 255, 0.5)',
    opacity: 1,
    transition: `all ${MODAL_ANIMATION_MS}ms cubic-bezier(0.28, 0.11, 0.32, 1)`,
  },
};

const aniFadeIn = keyframes`
  0% {
    opacity: 0;
    z-index: 999;
  }
  100% {
    opacity: ${modalConfig.overlay.opacity};
    z-index: 999;
  }
`;

const aniFadeOut = keyframes`
  0% {
    opacity: ${modalConfig.overlay.opacity};
    z-index: 999;
  }
  100% {
    opacity: 0;
    z-index: -1;
  }
`;

const aniModalOpen = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.2);
    z-index: 999;
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
    z-index: 999;
  }
`;

export const aniModalClose = keyframes`
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
    z-index: 999;
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.75);
    z-index: -1;
  }
`;

export const styles = css`
  .modal {
    position: fixed;
    z-index: 999;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    min-width: 20rem;
    max-width: 100vw;
    width: 40rem;
    overflow-y: hidden;
    overflow-x: hidden;
    padding: calc(${layout.padding} * 2);
    background: ${colors.white};
    transition: ${modalConfig.transition}!important;
    animation-iteration-count: 1;
    border-radius: ${layout.borderRadius};
    opacity: 0;
    z-index: -1;

    ${border}
    ${cssBoxShadow}

    header {
      min-height: 3rem;
      h1 {
        margin: calc(${layout.padding} * 0.5) 0 calc(${layout.padding} * 2);
        text-align: center;
      }
    }

    .row-body {
      margin: calc(${layout.padding} * 2) 0 calc(${layout.padding} * 2.5);
      font-size: 1.25em;
      font-weight: 600;
      text-align: center;
      p {
        margin: 0;
        padding: calc(${layout.padding} * 0.25) ${layout.padding};
      }
    }

    .row-buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      flex-wrap: nowrap;
      & > div {
        padding: 0 calc(${layout.padding} * 0.5) !important;
        display: flex;
        button {
          width: 100%;
          /* ${cssButton} */
        }
      }
    }

    &.modal-sm {
      width: 66vw;
      max-width: 800px;
    }

    &.modal-md {
      width: 66vw;
      max-width: 960px;
    }

    &.modal-lg {
      width: 80vw;
      max-width: 1200px;
    }

    &.modal-xl {
      width: 90vw;
      max-width: 1600px;
    }
  }

  .modal-overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    z-index: 999;
    background-color: ${modalConfig.overlay.color};
    transition: ${modalConfig.overlay.transition};
    opacity: 0;
    z-index: -1;
    ${cssGlassFrostedV2}/* ${cssGlassFrostedV2} */
  }

  &.is-open {
    .modal {
      opacity: 1;
      z-index: 999;
      animation-iteration-count: 1;
      animation: ${aniModalOpen} ${MODAL_ANIMATION_MS}ms ease;
    }
    .modal-overlay {
      opacity: 1;
      z-index: 999;
      animation-iteration-count: 1;
      animation: ${aniFadeIn} ${MODAL_ANIMATION_MS * 2}ms ease;
    }
  }
  &.is-closed {
    .modal {
      opacity: 0;
      z-index: -1;
      animation-iteration-count: 1;
      animation: ${aniModalClose} ${MODAL_ANIMATION_MS}ms ease;
    }
    .modal-overlay {
      opacity: 0;
      z-index: -1;
      animation-iteration-count: 1;
      animation: ${aniFadeOut} ${MODAL_ANIMATION_MS * 2}ms ease;
    }
  }
`;
