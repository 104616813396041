import { css } from '@emotion/react';
import { colors, layout, min } from 'styles';

const placeholderInactive = css`
  font-weight: 600;
  opacity: 0.7;
  color: ${colors.grey};
  -webkit-text-fill-color: ${colors.grey};
`;

const placeholderActive = css`
  font-weight: 600;
  opacity: 0.5;
  color: ${colors.grey};
  -webkit-text-fill-color: ${colors.grey};
`;

export const styles = css`
  // ====================================================================== //
  // FILTER CARD...
  margin: 1em 0;
  /* padding: calc(${layout.padding} * 0.5) calc(${layout.padding} * 1.5) calc(${layout.padding} * 1.5); */
  padding: calc(${layout.padding} * 0.75) calc(${layout.padding} * 1);
  /* padding: calc(${layout.padding} * 0.15) calc(${layout.padding} * 1); */
  border-radius: ${layout.borderRadius};
  border: ${layout.borderWidth} solid white;

  &.is-disabled {
    cursor: default;
    pointer-events: none;
    * {
      pointer-events: none;
    }
    .is-disabled,
    input[type='text']:disabled {
      user-select: none !important;
    }
  }

  &:not(.is-active) {
    button.button-clear,
    button.button-toggle {
      /* color: ${colors.greyLight};
        color: transparent; */
      opacity: 0;
    }
  }

  .filter-content-is-checked {
    pointer-events: none;
    user-select: none;
    opacity: 0.5 !important;
    * {
      pointer-events: none;
      user-select: none;
    }
    label {
      background-color: ${colors.grey};
      border-color: ${colors.grey};
    }
  }

  &:not(.filter-content-is-checked).filter-content-disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.5 !important;
    * {
      pointer-events: none;
      user-select: none;
    }
    label {
      background-color: ${colors.grey};
      border-color: ${colors.grey};
    }
    #search .btn.button {
      color: ${colors.grey}!important;
      svg {
        fill: ${colors.grey}!important;
      }
    }
  }

  input[type='text']:disabled {
    cursor: default;
  }

  /* CUSTOM CHECKBOX ICONS (PASSED AS PROPS) */

  &.is-active {
    border: ${layout.borderWidth} solid ${colors.success};
    header {
      h2 {
        color: ${colors.successXDark};
        -webkit-text-fill-color: ${colors.successXDark};
        span {
          color: ${colors.text};
          -webkit-text-fill-color: ${colors.text};
        }
      }
    }
    #search {
      /* margin-top: calc(${layout.padding} * 4); */
      /* margin-bottom: 1em; */
      &.has-query {
        input {
          border-color: ${colors.successXDark};
        }
        label {
          border-color: ${colors.successXDark};
          background: ${colors.successXDark};
        }
      }
      &.has-query.no-results {
        input {
          border-color: ${colors.warning};
        }
        label {
          border-color: ${colors.warning};
          background: ${colors.warning};
        }
      }
    }
  }

  .toggle-checkbox {
    &.is-checked {
      /* ICON DEACTIVATE (checkbox) */
      svg.icon-on.icon-deactivate {
        display: flex;
        transform: scale(1.3);
        box-shadow: inset 0 0 0 2px transparent;
        background: transparent !important;
        border-radius: ${layout.borderRadius};
        color: ${colors.successDark};
      }
      svg.icon-on.icon-deactivate:hover {
        display: flex;
        transform: scale(1.3);
        box-shadow: inset 0 0 0 2px transparent;
        background: transparent !important;
        border-radius: ${layout.borderRadius};
        color: ${colors.warningDark};
      }
      /* ICON CALENDAR */
      svg.icon-on.icon-calendar {
        display: flex;
        box-shadow: inset 0 0 0 2px transparent;
        background: transparent !important;
        border-radius: ${layout.borderRadius};
        color: ${colors.successDark};
      }
      svg.icon-on.icon-calendar:hover {
        display: flex;
        box-shadow: inset 0 0 0 2px transparent;
        background: transparent !important;
        border-radius: ${layout.borderRadius};
        color: ${colors.warningDark}!important;
      }
    }
  }

  h2 {
    font-size: 1.33em;
    color: ${colors.grey};
    user-select: none;
    display: inline-flex;
    margin-left: 0.5em;
    margin-bottom: 0.1em !important;
    span {
      margin-right: 0.4em;
    }
  }

  header {
    padding: 0.5em 0;
    .input-checkbox {
      display: inline-flex;
      margin-left: 0.5em;
    }

    .row {
    }
  }
  section {
    padding: 0 !important;
    .row {
    }
  }

  label {
  }

  // ====================================================================== //
  // PLACEHOLDERS...

  &.is-disabled,
  &:not(.is-active) {
    input {
      ::-webkit-input-placeholder {
        ${placeholderInactive}
      }
      ::-moz-placeholder {
        ${placeholderInactive}
      }
      :-ms-input-placeholder {
        ${placeholderInactive}
      }
      ::placeholder {
        ${placeholderInactive}
      }
      ::-webkit-datetime-edit {
        ${placeholderInactive}
      }
    }
    .select__placeholder {
      font-weight: 600;
      opacity: 0.7;
      color: ${colors.grey};
      -webkit-text-fill-color: ${colors.grey};
    }
  }
  &.is-active {
    input {
      ::-webkit-input-placeholder {
        ${placeholderActive}
      }
      ::-moz-placeholder {
        ${placeholderActive}
      }
      :-ms-input-placeholder {
        ${placeholderActive}
      }
      ::placeholder {
        ${placeholderActive}
      }
      ::-webkit-datetime-edit {
        ${placeholderActive}
      }
    }
    .select__placeholder {
      opacity: 0.7;
      color: ${colors.grey};
      -webkit-text-fill-color: ${colors.grey};
    }
  }
`;
