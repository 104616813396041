import { Fragment } from 'react';
import type { IGeneric } from 'types';
import { Button } from 'components/Button';
import type { SocialConfig, SocialEntry } from 'components/Socials';
import { Tooltip } from 'components/Tooltip';
import { getParsedJSON } from 'utils';
import { ArrayJSX } from 'utils/ArrayJSX';
import { configSocials } from 'config/socials.config';
import { colors, type ColorName, type HexColor } from 'styles';
import { styles } from './Socials.css';

type SocialsProps = {
  links: string | IGeneric | IGeneric[];
  color?: ColorName | HexColor;
  mono?: boolean;
  scale?: number;
};

export const Socials = ({ links = [], color, mono: isMono, scale = 1 }: SocialsProps) => {
  // log('SOCIALS:', 'cyan', links, getParsedJSON(links, []));

  if (!links || links === '{}' || !(Array.isArray(links) && links?.length)) return;

  const socials: IGeneric = Array.isArray(links) ? links : [];

  if (typeof links === 'object' && !Array.isArray(links)) {
    socials.push(links);
  }

  if (typeof links === 'string') {
    if ((links as string).startsWith('{') && (links as string).endsWith('}')) links = `[${links}]`;
    const parsed = JSON.parse(links)?.map((social: any) => Object.entries(social)[0]);
    parsed && socials.concat([...parsed]);
  }

  const handleClick = (url: string) => {
    const newWindow = window?.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
    return;
  };

  // CSS CLASSES
  const cssClasses = new ArrayJSX('socials');
  isMono && cssClasses.push('mono');

  return (
    <nav css={styles} className={cssClasses.inline()} style={{ transform: `scale(${scale})` }}>
      {socials?.map((social: SocialEntry, index: number) => {
        const socialConfig = configSocials.find((config) => config.name === social.platform) as SocialConfig;

        if (!socialConfig) {
          return <Fragment key={index} />;
        }

        const iconColor = isMono ? color || 'white' : socialConfig.color;

        return (
          <Tooltip
            key={`${index}-socials-${social?.name}`}
            content={`${social.platform}: ${social.name}`}
            // color={socialConfig.color || colors.primary}
            color={colors.primary}
            direction="top"
          >
            <Button
              onClick={() => handleClick(social?.url)}
              icon={<socialConfig.icon className={`social-${socialConfig.name}`} />}
              color={iconColor}
              colorHover={iconColor || 'primary'}
              variant="clear"
            />
          </Tooltip>
        );
      })}
    </nav>
  );
};
