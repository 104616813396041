import type { FieldValues, UseFieldArrayProps } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import type { IRepeaterItemBasic, RepeaterItemConfig } from './InputRepeater.types';

export const DEFAULT_REPEATER_ITEM: IRepeaterItemBasic = { uuid: uuid(), name: '' };

export const getInputRepeaterConfig = ({ name, keyName, control }: UseFieldArrayProps<FieldValues, string, 'uuid'>) => {
  const initFieldArrayConfig: RepeaterItemConfig = { name, keyName, control };
  return { initFieldArrayConfig };
};
