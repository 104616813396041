import type { FormButton } from 'components/Form/components/FormButtonBar/FormButtonBar.types';
import type { FormConfig } from 'types/form.types';
import { INPUTS, types, BUTTONS } from 'components/Form';
import { DATATYPE, ENDPOINT, UNLOCK_SITE_PASS } from './sitelock.config';

let i = 0;

const buttons: FormButton[] = [
  {
    type: types.submit,
    label: 't.site-lock.buttons.enter.label',
    action: BUTTONS.SUBMIT,
  },
];

// TODO: FIX TYPES ETC.. TO BE ABLE TO OMIT `api` KEY, WHEN NOT NEEDED
// export const formConfig: Omit<FormConfig, 'api'> = {
export const formConfig: FormConfig = {
  dataType: { key: DATATYPE, role: 'form' },
  api: { endpoint: ENDPOINT },
  isNavPromptActive: false,
  buttons,
  sections: [
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      fields: [
        {
          name: 'email',
          inputType: INPUTS.email,
          required: false,
          readOnly: true,
          defaultValue: 'demo@test.com',
          isHidden: true,
          colWidth: {
            xs: 12,
          },
        },
        {
          name: 'password',
          label: 't.login.password.label',
          inputType: INPUTS.password,
          placeholder: 'password',
          mockValue: UNLOCK_SITE_PASS,
          required: false,
          colWidth: {
            xs: 12,
          },
        },
      ],
    },
  ],
};
