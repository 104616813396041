import cloneDeep from 'lodash/cloneDeep';
import type { ISchema } from 'types';
import { getFieldDefaultUI } from './form.utils.ui';
import { defaultFieldProps, schemaKeysBlacklist } from '../config';

// TODO: [DEPERCDATED]
export const getSchemaFieldDefinitions = ({ schema }: any) => {
  const schemaKeys = Object.keys(schema);

  return schemaKeys.map((fieldName: string) => {
    if (schemaKeysBlacklist.includes(fieldName)) return;
    const fieldScehmaDefinition = schema[fieldName];
    // INFER DATA-TYPE FROM SCHEMA (DB/SQL MODEL)
    const typeDefaults = [
      { dataType: 'boolean', inputType: 'checkbox' },
      { dataType: 'number', inputType: 'number' },
      { dataType: 'string', inputType: 'date' },
      { dataType: 'string', inputType: 'text' },
      { dataType: 'string', inputType: 'textarea' },
    ];

    const { inputType } = typeDefaults.find(({ dataType }) => dataType === fieldScehmaDefinition.dataType) ?? {
      inputType: 'select',
    };
    const required = !fieldScehmaDefinition?.allowNull;

    // log(`${fieldName}:`, 'red', schemaDataEntryProps);

    return {
      fieldName,
      inputType,
      required,
    };
  });
};

export const getFieldsPropsFromSchemaMatch = ({ schemaFields }: { schemaFields: unknown[] }) => {
  const defaultsFieldProps = cloneDeep(defaultFieldProps);
  // POPULATE EACH FIELD OBJECT WITH DEFAULT PROPS, INFERRED DATA-TYPE + FORMATTED LABEL
  return schemaFields.map((schemaField: any) => {
    const { colWidth, ...defaultPropsCleaned } = defaultsFieldProps;

    return {
      ...defaultPropsCleaned, // empty props schema with default / unset values
      ...schemaField,
      ...getFieldDefaultUI({ fieldName: schemaField.fieldName }),
    };
  });
};
