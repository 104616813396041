import { Col, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import type { DataEntry, TableConfig, ISchema, DataConfigRole } from 'types';
import { ACTIONS, CatMeta } from 'types';
import { useRouter } from 'routes/hooks/useRouter/useRouter';
import { usePageHeader } from 'hooks/usePageHeader';
import { useQueryBuilder } from 'hooks/useQueryBuilder';
import { useQueryGet, api } from 'api';
import { useAuth } from 'auth/AuthContext';
import { useGlobal } from 'store/useGlobalContext';
import { DataTable } from 'components/DataTable';
import { useInitFormData } from 'components/Form';
import { NoEntries } from 'components/NoEntries';
import { PageToolbar } from 'components/PageToolbar';
import { Pagination, PaginationProvider } from 'components/Pagination';
import { Spinner } from 'components/Spinner';
import { initDetailData } from 'config/data.utils/initDetailData';
import { styles } from './AdminListPage.css';
import { useNavigateState } from 'hooks/useNavigateState';
import { usePageContent, usePageContentActions } from 'pages/Layout/PageContentContext/PageContent.hooks';
import { BiListPlus as IconListAdd } from 'react-icons/bi';
import { FiPlusSquare as IconListAdd2 } from 'react-icons/fi';
import { TbTextPlus as IconPlus } from 'react-icons/tb';
import { DataConfiguration } from 'routes/utils/getDynamicRoutes.types';
import { on } from 'events';

const getDataCleaned = ({ dataParsed, metaCats }: { dataParsed: DataEntry[]; metaCats: CatMeta[] }) => {
  const catIds = metaCats.map((cat: CatMeta) => cat.uuid);
  // NOTE: filter dataset using various checks (this meant for 'develpment' only, but may be useful in staging/production..)
  const dataValid = dataParsed
    // NOTE: filter for only valid category ids..
    .filter((dataEntry: DataEntry) => dataEntry?.cat_id && catIds.includes(dataEntry.cat_id as string));
  // NOTE: filter for only sub-categories (by checking if parent exists)
  // .filter(
  //   (dataEntry: DataEntry) =>
  //     dataEntry?.cat_id && !!metaCats.find((cat: CatMeta) => cat.uuid === dataEntry?.cat_id)?.parent,
  // );

  return dataValid;
};

const AdminListPage = ({ dataConfig }: DataConfiguration) => {
  const config: TableConfig = dataConfig.tableConfig;
  const { user } = useAuth();
  const { t } = useTranslation();
  const { navigate, from } = useNavigateState();
  const { params, isUserPath, route, category, slug, location, basePath, PATH_BASE, fromLocation } =
    useRouter();
  const { setPageHeaderTitles } = usePageHeader({ config });
  const { metaCats } = useGlobal('metaCats');
  const catIds = metaCats.map((cat: CatMeta) => cat.uuid);

  // ======================================================================== //

  const { title, detail, subtitle, headerButton } = usePageContent();
  const { resetHeader, setPageTitle, setPageDetail, setPageSubtitle, setPageHeaderButton } =
    usePageContentActions();

  // ======================================================================== //
  // NOTE: NEW HOOK !! - RETURNS `nulls` ON HARD PAGE LOAD
  // const { state, from, action } = useNavigateState();
  // log('useNavigateState()', 'cyan', { state, from, action });
  // ======================================================================== //

  // const { sortParams } = useGlobal('sortParams');
  const [filters, setFilters] = useState([]);
  const [results, setResults] = useState<DataEntry[] | null>();
  const [data, setData] = useState<DataEntry[]>([]);

  // ======================================================================== //

  // const { query, endpoint, action } = useQueryBuilder({ config });
  const { query, endpoint } = useQueryBuilder({ config });

  // NOTE: {isPending, isError } (not isFetching) RECOMMENDED BY react-query MAINTAINERS + DOCS..
  // ref: https://github.com/TanStack/query/discussions/6297

  // const { initFormData } = useInitFormData();

  // ======================================================================== //

  const { data: response, isPending, isFetching, isSuccess } = useQueryGet({ endpoint, query });

  useEffect(() => {
    setData([]);
    setResults(null);
  }, [location.pathname]);

  useEffect(() => {}, [config.header?.button]);

  // ======================================================================== //
  // ======================================================================== //

  useEffect(() => {
    if (isSuccess && Array.isArray(response)) {
      const dataParsed = response.map((data: DataEntry) => initDetailData({ data }));
      const dataValid = getDataCleaned({ dataParsed, metaCats }); // TODO: TEMP ONLY - REMOVE !!
      // log('dataValid', 'yellow', dataValid);
      setData(dataValid || []);
      setResults(dataValid || []);
    }
    setPageHeaderTitles(route);
    if (config.header?.button) {
      log('dataValid', 'yellow', config.header?.button);
      setPageHeaderButton({
        ...config.header?.button,
      });
    } else if (route?.parent) {
      setPageHeaderButton({
        isBackButton: true,
        backRoute: route?.parent,
        fromLocation,
      });
    }
    // NOTE: for initial `refetch()` + development saves, EMPTY deps `[]` works best..
    // NOT SURE YET.. TanStack recommends isPending.. (TBC)
  }, [isSuccess, response]);

  // ======================================================================== //
  // ======================================================================== //

  // NOTE: THIS SET OF CONDITIONS === PERFECT. 👍🏻
  // if (!config || isPending || isFetching || !data || results === null) {
  if ((!config || isFetching || results === null) && !isPending) {
    return <Spinner dark />;
  }

  return (
    <section css={styles}>
      <Row>
        <Col>
          <PaginationProvider numItems={data.length}>
            <PageToolbar
              dataConfiguration={config}
              {...{ data, results, setResults, filters, setFilters }}
              hasActionNew={config?.actions?.hasActionNew}
            />
            <Pagination numResults={results?.length} />
            {data.length > 0 ? (
              <DataTable
                data={results?.length ? results : data}
                config={config}
                routePathBase={basePath as string}
              />
            ) : (
              <NoEntries />
            )}
            <Pagination />
          </PaginationProvider>
        </Col>
      </Row>
      {/* <pre>USER: {JSON.stringify(user, null, 2)}</pre>
      <pre>DATA: {JSON.stringify(data, null, 2)}</pre>
      <pre>RESULTS: {JSON.stringify(results, null, 2)}</pre> */}
    </section>
  );
};

export default AdminListPage;
