import { css } from '@emotion/react';

import { colors, forms, layout } from 'styles';
import { max, min } from 'styles/media-queries-V1';

export const styles = css`
  /* padding: calc(${layout.padding} * 0) calc(${layout.padding} * 0) !important;
  margin-top: calc(${layout.padding} * 3);
  margin-bottom: calc(${layout.padding} * 0);
  margin-left: calc(${layout.padding} * -1);
  margin-right: calc(${layout.padding} * -1); */

  /* ${min.md} {
    margin-left: calc(${layout.padding} * -0.5);
    margin-right: calc(${layout.padding} * -0.5);
  }

  ${min.lg} {
    margin-left: calc(${layout.padding} * -0.5);
    margin-right: calc(${layout.padding} * -0.5);
  }

  ${min.xl} {
    margin-left: calc(${layout.padding} * -1.5);
    margin-right: calc(${layout.padding} * -1.5);
  }

  ${min.xxl} {
    margin-left: calc(${layout.padding} * 0.75);
    margin-right: calc(${layout.padding} * 0.75);
  } */

  h3 {
    margin-top: 0.5em !important;
    margin-bottom: 0 !important;
    margin-left: 0.05em;
    & + h2 {
    }
  }

  /* .card {
    margin: 0 calc(-${layout.padding}) calc(${layout.padding} * 10);
    padding: calc(${layout.padding} * 2) calc(${layout.padding} * 3) calc(${layout.padding} * 2.33);
  } */

  .row {
    min-height: 100px;

    & > .col {
      display: flex;

      align-items: center;
    }
    & > .col-message {
      justify-content: flex-start;
      svg {
        width: 60px;
        transform: scale(3.5) translateY(8%);
        opacity: 0.66;
      }
      h3 {
        padding-left: calc(${layout.padding} * 1) !important;
        color: ${colors.warningDark};
        span {
          color: ${colors.warning};
          opacity: 0.85;
        }
      }
    }

    & > .col:nth-of-type(2) {
      justify-content: flex-end;
    }
  }

  hr {
    border-color: ${colors.greyLight};
    margin-top: calc(${layout.padding} * 2);
  }

  .col-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    ${min.md} {
      align-items: center;
      justify-content: flex-end;
    }

    .btn-back {
      padding-left: 1.5em;
      padding-right: 1.5em;
      margin: calc(${layout.padding} * 0.75) calc(${layout.padding} * 0.5);
    }
  }
`;
