import Color from 'color';
import { getColorVariants } from 'utils';
import { ColorPalette, ColorsDefault, ColorsExtended, type ColorName, type HexColor } from './colors.types';

const primary = Color('#0087B4').desaturate(0.4).hex() as HexColor; // NEW Bondi Blue
const isAdminRoute = !!location.pathname.startsWith('/admin');
const primaryAdmin = primary as HexColor; // NEW Bondi Blue

export const colorsDefault: ColorsDefault = {
  // ADMIN COLORS
  default: '#595F5F', // grey (normal)
  primary: isAdminRoute ? primaryAdmin : primary,
  secondary: '#74CABF', // ??
  grey: '#999999',
  text: '#666666',
  white: '#ffffff',
  states: {
    info: '#2196f3',
    success: '#4FD865',
    warning: '#ee9900',
    danger: '#f44336',
  },
};

const xFactors = {
  main: {
    factor: 0.2,
    factorX: 0.4,
    factorXX: 0.8,
  },
  states: {
    factor: 0.2,
    factorX: 0.4,
    factorXX: 0.8,
  },
};

const colorsExtended = Object.assign({}, colorsDefault) as ColorPalette;

// MAIN COLORS: SHADED VERSIONS
for (const [name, value] of Object.entries(colorsExtended) as [ColorName, HexColor][]) {
  if (typeof value === 'object') continue;
  const color = Color(value);
  const variants = getColorVariants({ color, name, ...xFactors.main });
  Object.assign(colorsExtended, variants);
}

// THEME "info" TO PRIMARY COLOR
if (colorsExtended.states) {
  colorsExtended.states.info = Color(colorsExtended.primary).hex() as HexColor;

  // STATE COLORS: SHADED VERSIONS + FLATTEN
  for (const [name, value] of Object.entries(colorsExtended.states) as [ColorName, HexColor][]) {
    if (typeof value === 'object') continue;
    const color = Color(value);
    const variants = getColorVariants({ color, name, ...xFactors.states });
    Object.assign(colorsExtended, variants);
  }
}

// ADD OFF-WHITE (XXLight GREY)
colorsExtended.greyXXLight = '#f2f2f2';

export const colors = colorsExtended as ColorPalette;
// export default colors as ColorPalette; // TODO: CAN BE REMOVED (TBD..)
