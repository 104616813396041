import { IconBaseProps } from './../../types/ui.types';
import { css } from '@emotion/react';
import Color from 'color';
import { colors } from 'styles/colors.css';

const isColor = (strColor: string) => {
  try {
    return Color(strColor) || true;
  } catch (err) {
    err instanceof Error ? console.error(err.message) : console.error(String(err));
    return false;
  }
};

const getColor = (color: any) => {
  if (color === 'defaultLight') color = 'silver';
  if (color === 'defaultDark') color = 'gray';
  if (color && colors[color as keyof typeof colors]) {
    color = Color(colors[color as keyof typeof colors]);
  } else if (color && isColor(color)) {
    color = Color(color).darken(0.075);
  } else {
    color = colors.default ? Color(colors.default).lighten(0.75) : Color(colors.primary);
  }

  return color;
};

export const getButtonColors = ({ color, colorHover }: any) => {
  const btnColor = getColor(color);
  // const btnColorHover = colorHover ? getColor(colorHover) : btnColor;
  const btnColorHover = colorHover ? getColor(colorHover) : Color(getColor(color)).darken(0.075);
  const btnColorContent = btnColor.luminosity() <= 0.7 ? Color('white') : Color(colors.text);

  return { btnColor, btnColorHover, btnColorContent };
};

// GENERATE SMART COLOR-SCHEME - CSS, DEPENDING ON variant PROP
export const getColorsByVariant = ({ color, colorHover, variant }: any) => {
  const hoverFactor = 0.25;
  const { btnColor, btnColorHover, btnColorContent } = getButtonColors({ color, colorHover });

  if (variant === 'outline') {
    return css`
      color: ${btnColor.hex()};
      background-color: ${btnColor.alpha(0).string()};
      border-color: ${btnColor.hex()};

      &:not([disabled]):hover,
      &:not([disabled]).hover {
        color: ${btnColorHover.darken(hoverFactor).hex()};
        background-color: ${btnColorHover.alpha(hoverFactor).string()};
        border-color: ${btnColorHover.darken(hoverFactor).hex()};
      }
    `;
  }

  if (variant === 'clear') {
    return css`
      color: ${btnColor.hex()};
      background-color: ${btnColor.alpha(0).string()};
      border-color: ${btnColor.alpha(0).string()};

      &:not([disabled]):hover,
      &:not([disabled]).hover {
        color: ${btnColorHover.darken(hoverFactor).hex()};
        background-color: ${btnColorHover.alpha(hoverFactor).string()};
        border-color: ${btnColorHover.alpha(0).string()};
      }
    `;
  }

  if (variant === 'icon') {
    return css`
      color: ${btnColor.hex()};
      background-color: ${btnColor.alpha(0).string()};
      border-color: ${btnColor.alpha(0).string()};

      &:not([disabled]):hover,
      &:not([disabled]).hover {
        color: ${btnColorHover.darken(hoverFactor).hex()};
        background-color: transparent !important;
        border-color: ${btnColorHover.alpha(0).string()};
      }
    `;
  }

  // if (variant === 'solid') {
  return css`
    & {
      color: ${btnColorContent.hex()}!important;
      background-color: ${btnColor.hex()}!important;
      border-color: ${btnColor.hex()}!important;

      &:not([disabled]):hover,
      &:not([disabled]).hover {
        color: ${btnColorContent.hex()}!important;
        background-color: ${btnColorHover.darken(hoverFactor).hex()}!important;
        border-color: ${btnColorHover.darken(hoverFactor).hex()}!important;
      }
    }
    span {
      color: ${btnColorContent.hex()}!important;
      &:not([disabled]):hover,
      &:not([disabled]).hover {
        color: ${btnColorContent.hex()}!important;
      }
    }
  `;
  // }
};

export const getStylesByIconScale = ({ iconScale }: { iconScale: number | string }) => css`
  transform: scale(1);
  svg {
    transform: scale(${iconScale});
  }
  svg + span {
    margin-left: calc(0.6em * ${iconScale});
  }
  span + svg {
    margin-left: calc(0.6em * ${iconScale});
  }
`;
