import { css } from '@emotion/react';
import Color from 'color';
import { colors, layout, forms, border, cssInputBox, cssInputText, max, min } from 'styles';

export const colorAccents = {
  xxlight: Color(colors.primaryXLight).lighten(0.7).hex(),
  xlight: Color(colors.primaryXLight).lighten(0.65).hex(),
  light: Color(colors.primaryXLight).lighten(0.6).hex(),
  med: Color(colors.primaryXLight).lighten(0.4).hex(),
  dark: Color(colors.primaryXLight).darken(0.25).hex(),
};

export const styles = css`
  /*********************************** */

  border-style: solid;
  border-left-width: 2px;
  border-right-width: 2px;
  border-top-width: 1px;
  border-bottom-width: 1px;
  &:first-of-type {
    border-top-width: 2px;
  }
  &:last-of-type {
    border-bottom-width: 2px;
  }
  border-color: transparent;
  padding: 0;

  & > div {
    padding: 0 !important;
    margin: 0 !important;
  }

  .card-item {
    .col {
      height: 100%;
      display: flex;
      justify-content: center;
    }

    .col-name {
      display: flex;
      flex-direction: column;
      padding-right: calc(${layout.padding} * 0.5) !important;
    }

    .col-remove {
      svg {
        opacity: 0.85;
      }
    }

    .btn-remove {
      height: ${forms.inputs.height};
      position: absolute;
      right: calc(${layout.padding} * 0.5);
      svg {
        opacity: 0.85;
      }
    }

    .col-add {
      height: ${forms.inputs.height};
      display: none;
      button:disabled {
        border: none !important;
        background-color: transparent;
        color: ${colors.grey};
      }
    }

    &:last-of-type {
      .col-add {
        display: flex;
      }
      fieldset {
        margin-bottom: 0 !important;
      }
    }

    &:first-of-type:last-of-type {
      input[value=''] + button.btn-remove {
        display: none !important;
      }

      .col-add {
        display: flex;
      }
    }
  }

  .card-item > div {
    margin-right: calc(${layout.padding} * 0.5) !important;
    margin-bottom: calc(${layout.padding} * 1) !important;

    ${min.md} {
      margin-bottom: calc(${layout.padding} * 1) !important;
    }
  }

  .card-array {
  }

  /* COLUMNS ****************************************************** */

  fieldset.fieldset-name {
    display: flex;
    &.is-expanded {
      input {
      }
    }
    input:not(button):not([type='button']),
    input:read-only {
      ${cssInputBox}
      ${cssInputText}
      background: transparent;
      color: ${colorAccents.dark};
      -webkit-text-fill-color: ${colorAccents.dark};
      & + button.btn-remove {
        /* display: none !important; */
      }
    }
    input:focus {
      /* border-color: ${colors.greyLight}!important; */
      background: white !important;
      color: ${colors.text}!important;
      -webkit-text-fill-color: ${colors.text}!important;
    }
    input[aria-invalid='true'] {
      /* border-color: ${colors.warningXXLight}!important; */
      background: white !important;
      color: ${colors.text}!important;
      -webkit-text-fill-color: ${colors.text}!important;
    }
  }

  .card-item {
    & > div:last-of-type {
      margin-right: 0 !important;
    }
  }

  margin: 0 !important;
`;
