import type { DataEntry } from 'types';

export interface ContextActions {
  setDataFilter: (value: unknown) => void;
}

// ======================================================================== //
// STATE DEFINITION (SPECIFIC):

export interface DataFilterState extends ContextActions {
  data: DataEntry[];
  dataFiltered: DataEntry[];
  results: DataEntry[];
}

// ======================================================================== //
// INIT CONTEXT: ContextState.ts

export const initialState: DataFilterState = {
  setDataFilter: () => ({}),
  data: [],
  dataFiltered: [],
  results: [],
};
