import type { ActionMeta } from 'react-select';
import { useState, useEffect } from 'react';
import { METADATA } from 'types';
import type { SelectOptionsAsyncConfig } from 'types/form.types';
import { useQueryGet } from 'api/useQueryGet';
import { useGetMetadata } from 'api/useGetMetaData_THIS';
import type { QueryOptions, UseQueryGetProps } from 'api';

import type { SelectOption, SelectActions } from './Select.types';

export type GetSelectedMethod = (options: any, props?: any) => any;

type UseSelectConfig = {
  initOptions: SelectOption[];
  optionsAsync?: SelectOptionsAsyncConfig; //  Pick<Todo, "title" | "completed">
  onChange: (value: unknown) => void;
  defaultValue?: string;
  isDev?: boolean;
};

const getCurrentSelection = ({
  options,
  defaultValue,
}: {
  options: SelectOption[] | [];
  defaultValue?: string;
}) => {
  const selection = options?.find(
    (option: SelectOption) => option?.value === defaultValue || option?.label === defaultValue,
  );

  return selection as SelectOption;
};

// ====================================================================== //

export const useSelectConfig = ({
  initOptions = [],
  optionsAsync,
  onChange,
  defaultValue,
  isDev = false,
}: UseSelectConfig): any => {
  const [options, setOptions] = useState<SelectOption[]>(initOptions || []);

  // log('1. OPTIONS_CATS: (useSelectConfig.initOptions)', 'yellow', { initOptions });
  // log('2. OPTIONS_CATS: (useSelectConfig.options)', 'yellow', { options });

  const [currentSelection, setCurrentSelection] = useState<SelectOption>(
    getCurrentSelection({ options, defaultValue }),
  );

  // options.map((meta: any) => {
  //   if (!meta.label) {
  //     log(`OPTION: ${meta.value} =================>`, 'grey', meta);
  //     meta.label = meta.value;
  //   }
  // });

  useEffect(() => {
    // TODO: DEV_ONLY !!
    if (isDev && options?.length > 100) {
      // log('OPTIONS: ===============>', 'magenta', options);
    }

    options.map((meta: any) => {
      if (typeof meta === 'string') {
        log(`OPTION: ${meta} =================>`, 'grey', typeof meta);
        meta = {
          value: meta,
          label: meta,
        } as SelectOption;
      }
      if (!meta.label) {
        log(`OPTION: ${meta.value} =================>`, 'grey', typeof meta);
        meta.label = meta.value;
      }
    });
  }, []);

  const handleChange = (newSelection: SelectOption, actionMeta?: ActionMeta<any>) => {
    const { action }: { action: SelectActions } = actionMeta || { action: 'select-option' };

    if (action === 'clear') {
      onChange([]);
      setCurrentSelection(null as unknown as SelectOption);
    }

    if (action === 'select-option') {
      // TODO: V1: FIX-ME !!
      // onCh=ange(newSelection);
      // setCurrentSelection(newSelection);

      // TODO: V2: fixing...
      (newSelection as SelectOption)?.value ? onChange((newSelection as SelectOption).value) : onChange([]);
      setCurrentSelection(newSelection);
    }
  };

  // ====================================================================== //
  // FOR FETCH LOADED OPTIONS !!!

  // const

  const { refetch: getMetadata } = useGetMetadata({
    enabled: false,
    meta_key: optionsAsync?.meta_key as METADATA,
  });
  const { refetch: getData } = useQueryGet({
    enabled: false,
    ...(optionsAsync as UseQueryGetProps), // TODO: FIX - a bit hacky !! :()
  });
  const isMetadataFetch = !!optionsAsync?.meta_key;

  const loadOptions = async () => {
    const { data: optionsData } = await (isMetadataFetch ? getMetadata : getData)();

    if (!isMetadataFetch) {
      const optionsParsed = optionsData.data.map((meta: any) => {
        // log('LOAD_ENDPOINT =================>', 'grey', optionsData, meta);
        // const option = { value: meta?.uuid, label: meta?.name };
        const option = { value: meta?.name || '', label: meta?.name || '' };
        if (meta === defaultValue) setCurrentSelection(option);
        return option;
      });

      log('1. optionsParsed =================>', 'grey', optionsParsed);
      setOptions(optionsParsed);
      return optionsParsed;
    }

    const optionsParsed = optionsData.map((meta: string) => {
      const option = { value: meta, label: meta };
      if (meta === defaultValue) setCurrentSelection(option);
      return option;
    });

    log('2. optionsParsed =================>', 'grey', optionsParsed);
    setOptions(optionsParsed);
    return optionsParsed;
  };

  return {
    options,
    currentSelection,
    loadOptions,
    handleChange,
    // getCurrentSelection,
    setOptions,
    setCurrentSelection,
  };
};
