import { toUpperCaseFirst } from 'utils';
import { formatLabelString } from 'utils/utils.string.format';
import type { FieldConfig } from '../DetailSummary.types';
import { globalFieldProps } from '../config';

export const applyDefaultFieldLabels = ({ fields }: { fields: FieldConfig[] }) => {
  // COMPLETE POPULATING FIELD OBJECTS, MERGING DEFAULTS + CONFIG
  fields.map((field: FieldConfig) => {
    const globalDefaultProps = (globalFieldProps as { [key: string]: any })[field.name as string];

    if (field.label) {
      field.label = toUpperCaseFirst(field.label);
    } else if (globalDefaultProps?.label) {
      field.label = toUpperCaseFirst(globalDefaultProps.label);
    } else if (field.label !== false) {
      field.label = toUpperCaseFirst(field.name);
    }
  });
};

// ========================================================================== //

/**
 * Returns a new cleaned and formatted string for labels and titles
 * @param {string} labelOriginal string to format
 */

export const formatDetailLabel = (labelOriginal: string): string => {
  // EXTEND EXISTING LABEL-FORMAT, ADD SPECIAL CASES FOR DETAIL VIEW
  const formatted = formatLabelString(labelOriginal)
    .replace(/:/i, '')
    .replace(/Public/g, '')
    .replace(/cat_id/i, 'Category')
    .replace(/e mail/i, 'e-mail');

  return formatted;
};
