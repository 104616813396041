import { css } from '@emotion/react';
import Color from 'color';

import { colors } from 'styles/colors.css';

export const colorsMap = {
  borders: Color(colors.primaryXLight).lighten(0.4).desaturate(0).hex(),
  bg: Color(colors.primaryXLight).lighten(0.62).desaturate(0).hex(),
  // bg: Color(colors.primary).lighten(1.5).desaturate(0.3).hex(),
  highlighted: Color(colors.primaryXLight).lighten(0.25).desaturate(0.1).hex(),
  selected: Color(colors.successDark).lighten(0.15).desaturate(0.1).hex(),
};

export const styles = css`
  border: 0 !important;

  .svg-map-hover-detail {
    font-weight: 600;
    font-size: 2em;
    color: ${colors.primary};
    height: 120px;
  }

  .svg-map-* {
    opacity: 1;
    .svg-map__location {
      fill: ${colorsMap.bg}!important;
      stroke: ${colorsMap.borders};
      opacity: 1;
      cursor: default;
    }
    [id*='--selected'] {
      fill: ${colorsMap.selected}!important;
      stroke: white !important;
    }
  }

  #santa-cruz-de-tenerife,
  #las-palmas,
  #ceuta,
  #melilla {
    stroke-width: 2px;
    transform: scale(0.8) translate(10%, 5%);
  }
`;
