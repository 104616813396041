import { faker, fakerES, fakerEN } from '@faker-js/faker';
import type { RepeaterItemSocial } from 'components/FormUI/InputRepeater';
import type { SocialConfig } from 'components/Socials';
import { slugify } from 'utils/utils.string.format';
import { v4 as uuid } from 'uuid';
import { configSocials } from 'config/socials.config';
import { genBizName } from './data.biz';
import { PROVINCIAS } from './constants/fakerValues_ES';

export const genRandomUsername = () => slugify(faker.internet.userName());

export const genMockURL = () => {
  const name_es = slugify(fakerES.person.fullName()).replace(/-/g, '.');
  const name_en = slugify(fakerEN.person.fullName()).replace(/-/g, '.');
  const province = slugify(PROVINCIAS[Math.floor(Math.random() * PROVINCIAS.length)]);
  const business = slugify(
    faker.helpers.arrayElement([
      fakerEN.company.name(),
      fakerES.company.name(),
      fakerEN.company.buzzNoun(),
      fakerES.company.buzzNoun(),
      fakerEN.company.buzzPhrase(),
      fakerES.company.buzzPhrase(),
    ]),
  );

  const domainEnding = faker.helpers.arrayElement(['com', 'com', 'com', 'co', 'co', 'co.uk', 'es', 'biz']);
  const domain = faker.helpers.arrayElement([
    `${business}.${domainEnding}`,
    `${business}.${domainEnding}`,
    `${business}.${domainEnding}`,
    `${business}.${domainEnding}`,
    `${business}.${domainEnding}`,
    `${province}.${domainEnding}`,
    `${name_es}.${domainEnding}`,
    `${name_es}.${domainEnding}`,
    `${name_en}.${domainEnding}`,
  ]);
  const mockURL = `https://${faker.helpers.arrayElement(['www.', '', ''])}${domain}`;

  return faker.helpers
    .arrayElement([
      mockURL,
      mockURL,
      mockURL,
      mockURL,
      mockURL,
      mockURL,
      fakerES.internet.url(),
      fakerES.internet.url(),
      fakerES.internet.url(),
      fakerEN.internet.url(),
      fakerEN.internet.url(),
    ])
    .toLowerCase();
};

// ======================================================================== //
// SOCIAL MEDIA

export const getSocial = () =>
  '{' +
  faker.helpers.arrayElement([
    `"facebook":"https://facebook.com/${genRandomUsername()}"`,
    `"instagram":"https://instagram.com/${genRandomUsername()}"`,
    `"twitter":"https://twitter.com/${genRandomUsername()}"`,
    `"youtube":"https://youtube.com/${genRandomUsername()}"`,
    `"telegram":"https://telegram.org/${genRandomUsername()}"`,
    `"signal":"https://signal.org/${genRandomUsername()}"`,
    `"telegram":"https://telegram.com/${genRandomUsername()}"`,
    `"skype":"https://skype.com/${genRandomUsername()}"`,
  ]) +
  '}';

export const getSocialPlatformKey = () =>
  faker.helpers.arrayElement([...configSocials.map(({ name }: SocialConfig) => name)]);

export const getSocialEntry = (): RepeaterItemSocial => {
  const platform = getSocialPlatformKey();
  const social = configSocials.find((social: SocialConfig) => social.name === platform) as SocialConfig;
  const name = genBizName();
  const url = `${social.baseUrl}/${slugify(name)}`;

  return { uuid: uuid(), name, platform, url };
};
