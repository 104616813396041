import { RegisterOptions } from 'react-hook-form';
import type { IGeneric, IndexSignatureString, UnknownArrayOrObject, IParams, DataConfigType } from 'types';
import { METADATA } from 'types';
import type { ColumnSizes } from 'types/viewport.types';
import type { QueryOptions } from 'api/query/query.types';
import type { FormComponentProps } from 'components/Form/Form.types';
import type {
  FormButton,
  FormButtonBarProps,
} from 'components/Form/components/FormButtonBar/FormButtonBar.types';
import type { IPatternRHF } from 'components/Form/config/form.validation';
import type { IOptionTag } from 'components/FormUI/InputTags';
import type { SelectOption } from 'components/FormUI/Select';
// import type { FormSectionConfig } from 'components/FormSection/FormSection.types';
import type { HeaderButton } from 'types/header.types';
import type { FormSectionConfig } from '../components/FormSection/FormSection.types';

export { FormButton, FormButtonBarProps };

export interface IFormData {
  [key: string]: undefined | null | string | number | boolean | JSON;
}

// export type FieldsConfig = FieldConfig[] | FormSectionConfig[];

export interface FormDataSources {
  columns: string[];
  schema?: boolean | { [key: string]: IGeneric }; // TODO: MAYBE LEGACY ONLY (??)
  json_meta?: boolean;
  // json_meta: boolean | string | { [key: string]: IGeneric };
}

export type FormDataStructure = {
  source: FormDataSources & { schema?: any };
  target: FormDataSources;
};

export interface FormConfig {
  dataType: DataConfigType & { role: 'form' };
  api: QueryOptions;
  dataStructure?: FormDataStructure;
  isNewEntry?: boolean;
  sections: FormSectionConfig[];
  buttons: FormButton[];
  onSubmit?: (submittedData: any) => void;
  isNavPromptActive: boolean | false;
  hero?: FormHeroConfig;
  header?: {
    button?: HeaderButton;
  };
}

export interface FormHeroConfig {
  heroTitleKey?: string | string[];
  buttonRight?: HeaderButton;
}

export type FieldConfigArgs = {
  // schema: ISchema;
  schema: any;
  defaultValues: IFormData | any;
  fieldsConfig: FieldConfig[] | FormSectionConfig[];
};

// ======================================================================== //

export interface DevlopmentProps {
  mockValue?: any;
  mockValueAsAdmin?: any;
}

// ======================================================================== //

//  STANDARD HTML INPUT ATTRIBUTES..
export interface InputAttributes {
  autocapitalize?: boolean;
  autocomplete?: boolean;
  autofocus?: boolean;
  disabled?: boolean;
  placeholder?: boolean;
  readonly?: boolean;
  required?: boolean;
  // specific to textarea..
  rows?: number;
}

// export interface FieldConfig extends IndexSignatureString {
export interface FieldConfig extends DevlopmentProps {
  name: string;
  value?: any;
  defaultValue?: any;
  readOnly?: boolean;
  isDisabled?: boolean;
  isHidden?: boolean;
  placeholder?: string;
  autoComplete?: InputAutoComplete; // DOCS: "autoComplete" (camel-case): "on" | "off"

  // VALIDATION
  required?: boolean;
  pattern?: PatternValidator;
  minLength?: number;
  maxLength?: number;
  inputValidation?: RegisterOptions;
  // inputValidation?: IValidationPropsRHF;

  // UI: LABEL, HINT + COLUMN
  label?: string;
  label_link?: FieldLabelLink;
  isLabelInline?: boolean;
  hint?: string;
  colWidth?: ColumnSizes;
  order?: number;

  // FIELD TYPE
  inputType?: InputTypeEnum;
  // inputValidation?: IValidationPropsRHF;

  // SPECIFIC PROP-SETS by FIELD TYPE
  options?: SelectOption[] | UnknownArrayOrObject[];
  optionsAsync?: SelectOptionsAsyncConfig;
  tagsConfig?: IConfigTags;
  repeaterConfig?: IConfigRepeater;
  attributes?: InputAttributes;

  // SPECIAL UTILITY CONFIGS:
  isControlledBy?: FieldControlledBy;
  isV2ControlledBy?: FieldV2ControlledBy;

  // REST PROPS...
  props?: any;
  params?: any;
}

// FORM CONFIG SPECIAL UTILITY SUB-TYPES ==================================== //

export interface FieldControlledBy {
  criteria: IGeneric;
  target: {
    isHidden?: boolean;
    isDisabled?: boolean;
    required?: boolean;
  };
}

// TODO: NEW !! - Part-A
export interface FieldV2ControlledBy {
  source: string;
  target: FieldV2ControlledByTarget[];
  default: FieldV2ControlledByConfig; // TODO: 'default' HERE -OR- INSIDE `target`... ??
}

// TODO: NEW !! - Part-B
export interface FieldV2ControlledByTarget {
  criteria: string;
  config: FieldV2ControlledByConfig;
}

// TODO: NEW !! - Part-C
export interface FieldV2ControlledByConfig {
  label?: string;
  hint?: string;
  isHidden?: boolean;
  isDisabled?: boolean;
}

// ======================================================================== //

export interface FieldLabelLink {
  key: string;
  label: string;
  target: FieldLabelLinkTarget;
}

export type FieldLabelLinkType = 'MODAL' | 'INTERNAL' | 'EXTERNAL';

export interface FieldLabelLinkTarget {
  type: FieldLabelLinkType;
  href?: string;
  meta_key?: string;
}

// ======================================================================== //

export interface IConfigTags {
  label?: string;
  options?: IOptionTag[];
  meta_key?: METADATA;
}

export interface IConfigRepeater {
  inputType: InputTypeEnum;
  pattern?: PatternValidator;
  placeholder?: string;
}

// FORM FIELD / INPUT TYPE ================================================== //

enum InputTypeEnum {
  // STANDARD INPUT:
  text = 'text',
  // number = 'text',
  number = 'number',
  email = 'email',
  url = 'url',
  tel = 'tel',
  time = 'time',
  // MORE STANDARD:
  uuid = 'uuid',
  password = 'password',
  date = 'date',
  calendar = 'calendar',
  textarea = 'textarea',
  text_rich = 'text_rich',
  select = 'select',
  image = 'image',
  checkbox = 'checkbox',
  checkboxes = 'checkboxes',
  switch = 'switch',
  radios = 'radios',
  // SPECIAL:
  JSON_META = 'JSON_META',
  FORM_COMPLETION = 'FORM_COMPLETION',
  selectCategory = 'selectCategory',
  selectCountry = 'selectCountry',
  selectMulti = 'selectMulti',
  repeater = 'repeater',
  repeaterURL = 'repeaterURL',
  tags = 'tags',
  socials = 'socials',
  youtube = 'youtube',
  invited = 'invited',
}

// TODO: ENUM TO TUPLE ??
declare type InputType<Key extends string = InputTypeEnum> = {
  readonly [key in InputTypeEnum]: string;
};

export { InputTypeEnum as INPUTS, InputTypeEnum };

// FORM SUBMIT ACTIONS ====================================================== //

export type ButtonClickHandler = { button: { type: string; label: string } };

export type Pattern = RegExp | { [key: string]: { value: RegExp | string; message: string } };

enum InputAutoComplete {
  on = 'on',
  off = 'off',
}

export type PatternValidator = Pattern | IPatternRHF;

export interface SelectOptionsAsyncConfig extends QueryOptions {
  meta_key?: METADATA;
}

// DEFAULT_VALUES ================================================== //

export enum DEFAULT_VALUE_ENUM {
  NEW_UUID = 'NEW_UUID',
  cat_id = 'cat_id',
}

export const DEFAULT_VALUE = {
  NEW_UUID: 'NEW_UUID',
  cat_id: 'cat_id',
} as const;

// DYNAMIC_VALUE ================================================== //

export enum AUTH_USER_ENUM {
  UUID = 'AUTH_USER_UUID',
  EMAIL = 'AUTH_USER_EMAIL',
  ROLE = 'AUTH_USER_ROLE',
  IS_REGISTERED = 'AUTH_USER_IS_REGISTERED',
  IS_VALIDATED = 'AUTH_USER_IS_VALIDATED',
}

export const AUTH_USER = {
  UUID: 'AUTH_USER_UUID',
  EMAIL: 'AUTH_USER_EMAIL',
  ROLE: 'AUTH_USER_ROLE',
  IS_REGISTERED: 'AUTH_USER_IS_REGISTERED',
  IS_VALIDATED: 'AUTH_USER_IS_VALIDATED',
} as const;

const AUTH_USER_MAP = new Map<string, string>(Object.entries(AUTH_USER));
export const AUTH_USER_PLACEHOLDER = Object.values(AUTH_USER);

// FORMATS ================================================== //

export enum FORMAT {
  BY_OPTION = 'BY_OPTION',
  BY_CAT = 'BY_CAT',
  BY_ID = 'BY_ID',
  LIST_BY_ID = 'LIST_BY_ID',
  CATEGORY = 'CATEGORY',
  TAGS = 'TAGS',
}
