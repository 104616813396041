/* eslint-disable @typescript-eslint/no-shadow */
import { Row, Col } from 'react-grid-system';
import type { FieldValues, UseFormReturn } from 'react-hook-form';
import { useFormConfigContext } from 'components/Form/context';
import { Fragment, useEffect } from 'react';
import { useRouter } from 'routes/hooks';
import { useLayout } from 'pages/Layout/context/LayoutContext';
import { Button } from 'components/Button';
import type { FieldConfig } from 'components/Form';
import { unsetRequired } from 'components/Form/utils/form.utils.validation';
import { autofillMockData } from 'components/Form/utils/form.utils.mock';
import { isSet } from 'utils';
import { DevValue } from './DevValue';
import type { MockFieldsFn, UnknownArrayOrObject } from './DevFormState.types';
import { styles } from './DevFormState.css';

export const DevFormState = ({ formMethods, fields, isLocked, isVisibleInfo, isVisibleMockButton }: any) => {
  const { formConfig } = useFormConfigContext();
  const { isDevToolsVisible } = useLayout();
  if (!isDevToolsVisible) return <Fragment />;

  const { formState, getValues, setValue, trigger } = formMethods;
  const {
    isDirty,
    isValid,
    isValidating,
    isSubmitting,
    isSubmitted,
    isSubmitSuccessful,
    submitCount,
    errors,
    touched,
    dirtyFields,
    touchedFields,
  } = formState;

  const { params } = useRouter();
  const isNewEntry = !!params.new;
  const isEmpty = Object.values(getValues()).filter((value) => value).length === 0;

  const handleClickMockAll = async () => {
    await unsetRequired(fields);
    await autofillMockData({ fields, formMethods });
  };

  const handleClickMockRest = async () => {
    await autofillMockData({ fields, formMethods, onlyMockEmpty: true });
    trigger();
  };

  const handleClickNoRequire = async () => {
    await unsetRequired(fields as FieldConfig[]);
  };

  useEffect(() => {
    isSet(errors) && console.log('%cERRORS:', 'color:red', errors);
  }, [errors]);

  return (
    <Row css={styles} className="form-dev">
      <Col xs={12}>
        {/* <Col xs={12} className="json-dump">
        <JsonView obj={getValues()} cssPrefix="jsonview" />
        <textarea value={JSON.stringify(getValues(), null, 2)} />
      </Col> */}
        {!isVisibleInfo && isVisibleMockButton && (
          <Col xs={12}>
            {params.table !== 'categories' && (
              <Button
                type="button"
                label="mock data !!"
                onClick={handleClickMockAll}
                style={{ width: '90%' }}
                color="#EEE"
              />
            )}
          </Col>
        )}
        {isVisibleInfo && (
          <>
            {isVisibleMockButton && (
              <Row className="form-dev-buttons">
                <Col xs={12}>
                  <Button
                    type="button"
                    label="mock_empty"
                    onClick={handleClickMockRest}
                    style={{ width: '90%', marginBottom: '1em' }}
                    color="#EEE"
                  />
                  {/* <Button
                    type="button"
                    label="MOCK_ALL"
                    onClick={handleClickMockAll}
                    style={{ width: '90%', marginBottom: '1em' }}
                    color="#EEE"
                  /> */}
                  <Button
                    type="button"
                    label="un-require"
                    onClick={handleClickNoRequire}
                    style={{ width: '90%', marginBottom: '1em' }}
                    color="#EEE"
                  />
                  <Button
                    type="button"
                    label="getValues"
                    onClick={() => log('GET_VALUES() >>', 'orange', getValues())}
                    style={{ width: '90%', marginBottom: '1em' }}
                    color="#AAA"
                  />
                </Col>
              </Row>
            )}
            {/* ====================================================================== */}
            <Row className="form-dev-status">
              <Col xs={isVisibleMockButton ? 3 : 4}>
                <pre style={{ paddingLeft: '15px' }}>
                  <h3>state:</h3>
                  <DevValue prop="isDirty" value={isDirty} />
                  <DevValue prop="isEmpty" value={isEmpty} />
                  <DevValue prop="isLocked" value={isLocked} />
                  <DevValue prop="touched" value {...touched} />
                </pre>
              </Col>
              <Col xs={isVisibleMockButton ? 3 : 4}>
                <pre style={{ paddingLeft: '15px' }}>
                  <h3>validation:</h3>
                  <DevValue prop="isValid" value={isValid} />
                  <DevValue prop="hasError" value={isSet(errors)} />
                  <DevValue prop="numErrors" value={Object.entries(errors).length} />
                </pre>
              </Col>
              <Col xs={isVisibleMockButton ? 3 : 4}>
                <pre style={{ paddingLeft: '15px' }}>
                  <h3>dirtyFields:</h3>
                  <pre>{JSON.stringify(dirtyFields, null, 2)}</pre>
                  <pre>{JSON.stringify(touchedFields, null, 2)}</pre>
                </pre>
              </Col>
              <Col xs={isVisibleMockButton ? 3 : 4}>
                <pre style={{ paddingLeft: '15px' }}>
                  <h3>submission:</h3>
                  <DevValue prop="isValidating" value={isValidating} />
                  <DevValue prop="isSubmitting" value={isSubmitting} />
                  <DevValue prop="isSubmitted" value={isSubmitted} />
                  {isSubmitted && !isSubmitting && <DevValue prop="isSubmitSuccessful" value={isSubmitSuccessful} />}
                  <DevValue prop="submitCount" value={submitCount} />
                </pre>
              </Col>
            </Row>
            {/* ====================================================================== */}
            {/* <Row className="form-dev-status">
              {formConfig && (
                <Col xs={formConfig ? 6 : 12}>
                  <pre style={{ paddingLeft: '15px' }}>
                    <h3>FORM_CONFIG:</h3>
                    {JSON.stringify(formConfig, null, 2)}
                  </pre>
                </Col>
              )}
              {fields && (
                <Col xs={formConfig ? 6 : 12}>
                  <pre style={{ paddingLeft: '15px' }}>
                    <h3>FIELDS:</h3>
                    {JSON.stringify(fields, null, 2)}
                  </pre>
                </Col>
              )}
            </Row> */}
            {/* ====================================================================== */}
          </>
        )}
      </Col>
    </Row>
  );
};
