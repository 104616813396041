import { format } from 'date-fns';
import { ES } from 'config';
import { styles } from './CalendarDay.css';

export const CalendarDay = ({ date, time = '00:00' }: { date: Date | string; time?: Date | string }) => {
  date = date ? new Date(date) : new Date();

  return (
    <div css={styles} className={`alert alert-${time}`}>
      <div className="cal-top">{format(date, 'MMM', { locale: ES })}</div>
      <div className="cal-bottom">{format(date, 'dd', { locale: ES })}</div>
    </div>
  );
};
