import { Col, Row } from 'react-grid-system';
import { useLocation } from 'react-router-dom';
import { ReactElement, useEffect, useState } from 'react';
import { DataEntry, ISchema, ACTIONS } from 'types';
import { useNavigateState } from 'hooks/useNavigateState';
import { useRouter } from 'routes/hooks';
import { useQueryGet } from 'api/useQueryGet';
import { DetailConfig, DetailSummary } from 'components/DetailSummary';
import { Spinner } from 'components/Spinner';
import { initDetailData } from 'config/data.utils/initDetailData';
import { useDataConiguration } from 'config/data.utils/useDataConiguration';
import { styles } from './DetailPage.css';
import { usePageContentActions } from 'pages/Layout/PageContentContext/PageContent.hooks';
import { DataConfiguration } from 'routes/utils/getDynamicRoutes.types';

export type DetailPageProps = {
  endpoint?: string;
  detailConfig?: DetailConfig | any;
  data?: DataEntry;
  schema?: ISchema;
  heroTitleKey?: string | string[];
};

const DetailPage = ({ dataConfig }: DataConfiguration): ReactElement => {
  const detailConfig: DetailConfig = dataConfig.detailConfig;
  const { params } = useRouter();
  const location = useLocation();
  const { setPageTitle, setPageSubtitle, setPageHeaderButton } = usePageContentActions();

  // ======================================================================== //

  // const role: DataConfigRole = params.action === ACTIONS.NEW || params.action === ACTIONS.EDIT ? 'form' : 'detail';
  const { endpoint, dataConfiguration } = useDataConiguration<typeof detailConfig>({
    defaultConfig: detailConfig,
    role: 'detail',
  });
  // const { endpoint, dataConfiguration } = useDataConiguration<DetailConfig>({ defaultConfig, role: 'detail' });

  // ======================================================================== //
  // NOTE: NEW HOOK !!
  const { state, from, action } = useNavigateState();
  log('useNavigateState()', 'cyan', { state, from, action });

  // ======================================================================== //

  // NEW !! - EMAIL: https://dev.to/benjaminnwokolo/sending-an-email-with-react-without-a-backend-server-32gc

  // const { initDetailData } = useInitDetailData();

  const query = {};
  if (params?.id) {
    Object.assign(query, { id: params?.id });
  }

  const { data, schema, isPending, isLoading, isFetching, isSuccess, isError, refetch } = useQueryGet({
    endpoint,
    query: { id: params?.id as string },
    filter: { $limit: 1 },
    enabled: false,
  });

  useEffect(() => {
    if (dataConfiguration) {
      // setData([]);
      // setResults(null);
      refetch();
    }
  }, [isPending, dataConfiguration]);

  useEffect(() => {
    if (data) {
      const configHeader = dataConfiguration?.header;
      setPageTitle(configHeader?.title || 'APNAES');
      setPageSubtitle(data.name);
      setPageHeaderButton({
        isHidden: false, // make explicit for now.. as other logic handles is also..
        label: configHeader?.buttonLabel,
        href: '',
      });
    }
  }, [isSuccess]);

  // log('DATA_CONFIG:', 'red', { dataConfiguration, data, isLoading, isFetching, isSuccess });

  if (!dataConfiguration || !data || isPending) {
    return <Spinner page dark />;
  }

  const dataInitialized = initDetailData({ data, isExpandedSchema: true });

  return (
    <section css={styles}>
      <Row>
        <Col xs={12}>
          <DetailSummary detailConfig={dataConfiguration as any} data={dataInitialized} schema={schema} />
        </Col>
        {/* <pre>
          <strong>db column values:</strong>
          <br />
          {JSON.stringify(dataInitialized, null, 2)}
        </pre> */}
      </Row>
    </section>
  );
};

export default DetailPage;
