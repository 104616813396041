import { css } from '@emotion/react';
import { colors } from 'styles/colors.css';

export const styles = css`
  height: 40px;
  text-align: right;

  label {
    margin-right: 1em;
    font-weight: 700;
    color: ${colors.greyLight};
    span {
      color: ${colors.primary};
    }
  }

  .tag {
    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
`;
