import type { DetailConfig } from 'components/DetailSummary';
import defaultConfigs from 'config/datatype/_default';
import type { DataColumnConfig } from 'components/DataTable/DataTable.types';
import type { DetailSection } from 'components/DetailSummary/DetailSummary.types';
import cloneDeep from 'lodash/cloneDeep';
import keyBy from 'lodash/keyBy';
import merge from 'lodash/merge';
import type { FieldConfig, FormConfig } from 'components/Form';
import type { FormSectionConfig } from 'components/FormSection/FormSection.types';
import { defaultColumnSizes } from 'components/Form';
import { getResponsiveColumnSizes } from 'utils';
import type { OptionalArgs, DataConfigurationTypes } from 'types';

/*
// TODO: MOVED + REFACTORED to REMOVE impplicit import statement (Vite)
export const getDataConfiguration = async (endpoint: string): Promise<DataConfigurationTypes> => {
  try {
    return await import(`config/datatypes/${endpoint}`);
  } catch {
    return { ...defaultConfigs };
  }
};
*/

// NOTE: USED INTERNALLY ONLY...
// FN: getFieldsConfig FOR TYPE posts (CATEGORIES)
export const setAsyncFields = ({
  fieldsConfig,
  doFetch,
}: {
  fieldsConfig: FieldConfig[];
  doFetch?: any;
}): FieldConfig[] => {
  fieldsConfig?.map((field: FieldConfig) => {
    if (field.name === 'categories') {
      field.options = doFetch;
    }
  });

  return fieldsConfig;
};

// 1. FIELDS ARRAY with SECTIONS FLATTENED
export const flattenFieldConfigs = (entries: any[]) => {
  return entries?.flatMap((entry: any) => (entry.fields ? [].concat(...entry.fields) : [entry]));
};

export const flattenFieldConfigs__V2 = (source: FormConfig | FormSectionConfig[] | DetailSection[]): FieldConfig[] => {
  const configSource = cloneDeep(source);
  if (!Array.isArray(configSource) && 'fields' in configSource) {
    return configSource.fields as FieldConfig[];
  }

  const sections = (configSource as FormConfig).sections ? (source as FormConfig).sections : (source as any[]);

  return sections?.reduce((acc: FieldConfig[], section: FormSectionConfig | DetailSection) => {
    if (section?.fields) {
      return [...acc, ...section.fields];
    }
    return acc;
  }, [] as FieldConfig[]);
};

// 2-A. FIELDS ARRAY FROM CONFIG
export const getInitFieldConfigs = ({
  fields,
  defaultFieldProps = {},
}: {
  fields: FieldConfig[] | DataColumnConfig[] | unknown;
  defaultFieldProps: any;
}): FieldConfig[] =>
  (fields as FieldConfig[]).map((field: FieldConfig) => {
    // NOTE: defaultFieldProps = initialised props (empty)
    return { ...defaultFieldProps, ...field };
  });

// 2-B. FIELDS ARRAY FROM CONFIG
export const applyFieldColumnWidths = ({ fields }: { fields: FieldConfig[] }) => {
  fields.map((field: FieldConfig) => {
    field.colWidth = field?.colWidth
      ? getResponsiveColumnSizes({ defaultProps: defaultColumnSizes, customProps: field?.colWidth })
      : { ...defaultColumnSizes };
  });
};

// 4. MERGE ABOVE 2 CONFIGS INTO 1 SINGLE CONFIG !!
// TODO: ORDER OF CONFIG ARRAY TO ENSURE DESIRED MERGE
export const mergeFieldConfigsByKey: OptionalArgs = (fieldsArrayA, fieldsArrayB, args?: { byKey: 'name' }) => {
  const byKey = args?.byKey || 'name';
  const copyOfArrayA = cloneDeep(fieldsArrayA);
  const copyOfArrayB = cloneDeep(fieldsArrayB);
  const fieldsArrayMerged = Object.values(merge(keyBy(copyOfArrayA, byKey), keyBy(copyOfArrayB, byKey))) || [];

  return fieldsArrayMerged;
};
