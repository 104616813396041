import {
  UseFormReturn,
  UseFieldArrayReturn,
  FieldValues,
  FieldError,
  FieldErrors,
  useFormContext,
} from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import type { IRepeaterItemBasic } from '../InputRepeater.types';
import { optionsFormReset } from '../form.config.RHF';
import { DEFAULT_REPEATER_ITEM } from '../input-repeater.config';

const MESSAGE_CONFIRM_CANCEL = 'Abandon unsaved changes ??';
const MESSAGE_CONFIRM_REMOVE = 'Remove this item ??';

export const useFormParent = ({
  formMethods,
  fieldArray,
  newItemEmpty = DEFAULT_REPEATER_ITEM,
}: {
  formMethods: UseFormReturn;
  fieldArray: UseFieldArrayReturn<FieldValues, string, 'uuid'>;
  newItemEmpty?: IRepeaterItemBasic;
}) => {
  // GET REQUIRED FORM VALUES, STATE, METHODS via HOOKS...
  const { control, register, handleSubmit, reset, getValues, setValue, formState, watch } = formMethods;
  const { isDirty, isValid, isSubmitted } = formState;
  const { append, prepend, remove, swap, move, insert, replace } = fieldArray;
  const isLocked = !isDirty || !isValid;

  // FORM ACTIONS =========================================================== //

  const resetFields = async (defaultValues = undefined) => {
    reset(defaultValues, { ...optionsFormReset });
  };

  const resetForm = (replaceWith: any = undefined) => {
    // replace(replaceWith);
  };

  const handleAppend = () => {
    const newItem = { ...DEFAULT_REPEATER_ITEM };
    append(DEFAULT_REPEATER_ITEM);
  };

  const handleRemove = (index: number) => {
    // TODO: NEW MODAL ??
    // confirm(MESSAGE_CONFIRM_REMOVE) == true && remove(index);
    remove(index);
  };

  const handleCancel = () => {
    if (isDirty) {
      confirm(MESSAGE_CONFIRM_CANCEL) == true && resetFields();
    }
  };

  const handleSubmitErrors = (errorsOnSubmit: FieldErrors) => {
    log('SUBMIT & ERRORS', 'red', errorsOnSubmit);
  };

  // ======================================================================== //

  return {
    resetFields,
    resetForm,
    handleAppend,
    handleRemove,
    handleCancel,
    handleSubmitErrors,
  };
};
