import { Row, Col } from 'react-grid-system';
import { HTMLAttributes, ReactNode, ReactElement, FunctionComponent } from 'react';
import { ArrayJSX } from 'utils/ArrayJSX';
import { styles } from './Card.css';

interface CardProps extends HTMLAttributes<FunctionComponent> {
  children: ReactNode;
  className?: string;
  attr?: HTMLAttributes<HTMLElement>;
}

const Card = ({ children, className = '', attr = {} }: CardProps) => {
  const cssClasses = new ArrayJSX();
  cssClasses.push('card');
  className && cssClasses.push(className);

  return (
    <div css={styles} className={cssClasses.inline()} {...{ ...attr }}>
      <Row>
        <Col xs={12} md={12}>
          {children}
        </Col>
      </Row>
    </div>
  );
};

export { Card };
