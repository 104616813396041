export const isJSONString = (values: unknown): boolean => {
  if (typeof values === 'string') {
    const isJSONObject = values.startsWith('{') && values.endsWith('}');
    const isJSONArray = values.startsWith('[') && values.endsWith(']');
    if (isJSONObject || isJSONArray) return true;
  }
  return false;
};

export const getParsedJSON__V1 = (data: any) => {
  return isJSONString(data) ? JSON.parse(data) : data;
};

export const getParsedJSON__V2 = (data: any, fallback: Record<string, never> | [] = {}) => {
  return (isJSONString(data) ? JSON.parse(data) : data) || fallback;
};

// Record<string, never>
export const getParsedJSON = (data: any, fallback: Record<string, never> | [] = {}) => {
  const parsed = isJSONString(data) ? JSON.parse(data) : data;
  if (Array.isArray(fallback) && !Array.isArray(parsed)) {
    return data !== '{}' ? [parsed] : [];
  }

  return parsed || fallback;
};

// TODO: RETURN TYPE MAY NOT BE COMPATIBLE WITH ALL...
export const getParsedJSON__DEV = (data: any) => {
  try {
    if (data) {
      const parsed = JSON.parse(data);
      return parsed;
    }
  } catch (error: unknown) {
    error instanceof Error ? console.error(error.message) : console.error(String(error));
    return data;
  }
  return;
};
