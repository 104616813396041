import { faker } from '@faker-js/faker';
import cloneDeep from 'lodash/cloneDeep';
import type { DataEntry, CatMeta } from 'types';
import { getParsedJSON, isSet } from 'utils';
import { getUserID } from 'mocks/generators/data.entry.byId';
import { matchLegacyPostData, getTimestampFromData } from './initData.utils';

const TIMESTAMP_LEGACY_ = 1640556866718; // Sunday, December 26, 2021 10:14:26.718 PM
const TIMESTAMP_TEST___ = 1703564905000; // Sunday, December 26, 2023 10:14:26.718 PM
const TIMESTAMP_MODERN_DATA = 1704067200000; // Monday, January 1, 2024 12:00:00 AM

interface DataEntryExpanded extends DataEntry {
  json_meta: string;
  created_at: number;
  updated_at: number;
}

export const initDetailData = ({
  data,
  isExpandedSchema = false,
}: {
  data: DataEntry;
  isExpandedSchema?: boolean;
}) => {
  // const dataFetched = cloneDeep(data) as DataEntryExpanded;
  const dataFetched = cloneDeep(data);
  const dataJSON = getParsedJSON(dataFetched?.json_meta);
  const KEYS_DB_SCHEMA = Object.keys(dataFetched || []);
  const KEYS_JSON_EXPANDED = Object.keys(dataJSON || []);

  const keysAll: string[] = [];
  keysAll.push(...KEYS_DB_SCHEMA);
  keysAll.push(...KEYS_JSON_EXPANDED);

  const keysExpanded = [...new Set([...keysAll])];
  const dataExpanded = { ...dataFetched, ...dataJSON } as DataEntryExpanded;
  const dataInitialized = isExpandedSchema ? dataExpanded : dataFetched;

  // log('==> DATA - INSIDE', 'magenta', { dataInitialized, dataJSON });
  // log('==> DATA - ALL_KEYS', 'grey', { KEYS_DB_SCHEMA });
  // log('==> DATA - ALL_KEYS', 'grey', { KEYS_JSON_EXPANDED });
  // log('==> DATA - ALL_KEYS', 'grey', keysExpanded);

  // ------------------------------------------------------------------------ //
  // 3. ADJUST CERTAIN FIELDS..

  if ('user_id' in dataExpanded && !dataExpanded.user_id) {
    dataExpanded.user_id = getUserID();
  }

  if (dataExpanded.url_multi) {
    dataExpanded.url_multi = getParsedJSON(dataExpanded.url_multi, []);
  }

  if (dataExpanded.socials) {
    dataExpanded.socials = getParsedJSON(dataExpanded.socials, []);
  }

  if (typeof dataExpanded.description === 'string' && dataExpanded.description.length <= 20) {
    dataExpanded.description = faker.lorem.paragraphs({ min: 1, max: 2 });
  }

  if (typeof dataExpanded.created_at === 'string') {
    dataExpanded.created_at = getTimestampFromData({ dataFetched, dataSource: dataJSON, key: 'created_at' });
  }

  if (typeof dataExpanded.updated_at === 'string') {
    dataExpanded.updated_at = new Date(dataExpanded.updated_at).getTime();
  }

  const { json_meta: _, ...dataEntry } = dataInitialized;
  Object.assign(dataInitialized, { json_meta: JSON.stringify(dataEntry) });

  return dataInitialized;
};
