import { Col, Row } from 'react-grid-system';
import { useEffect, useState } from 'react';
import { useQueries } from '@tanstack/react-query';
import { CatMeta, DataEntry } from 'types';
import { RouteConfig, useRouter } from 'routes';
import { useGlobal } from 'store/useGlobalContext';
import { DataGrid } from 'components/DataGrid';
import { ListItemCard } from 'components/ListItemCard';
import { getFakerImageURLv3 } from 'mocks/generators/data.images';
import { getRandomInt } from 'utils/utils.number';
import { tableConfig as tableConfigHome } from './HomePage.config';
import { styles } from './HomePage.css';
import { RouteObject, useRouteLoaderData } from 'react-router-dom';
import type { RoutesLoaderData } from 'routes/routes.types';
import { useLayout } from 'pages/Layout/context/LayoutContext';
import { flatttenChildren } from 'routes/utils/routes.utils.flatten';
import { findRouteByProp, findTopRoutes } from 'routes/utils/routes.utils.find';
import { cleanRoutesOfElements } from 'routes/utils/routes.utils';

const CAT_IDS = [
  '123e0000-e89b-12d3-a456-000000000001',
  '123e0000-e89b-12d3-a456-000000000002',
  '123e0000-e89b-12d3-a456-000000000003',
];

const r1 = getRandomInt(1, 10);
const r2 = getRandomInt(1, 10);
const r3 = getRandomInt(1, 10);

const HomePage = () => {
  const { params, route, location } = useRouter();
  const { metaRoutes } = useGlobal('metaRoutes');
  const { metaCats } = useGlobal('metaCats');
  const { categories } = useGlobal('categories');
  const [dataCats, setDataCats] = useState<DataEntry[]>([]);

  // ======================================================================== //

  // NOTE: FOR DEVELOPMENT - OUTPUT FULL ROUTES OBJECT
  const { isDevToolsVisible } = useLayout();
  const { transformedRoutes } = useRouteLoaderData('root') as RoutesLoaderData;

  // ======================================================================== //

  // const cats = findRouteByBasePath__V1(metaRoutes, { basePath: 'cat' });
  // const cats = findRouteByProp(categoriesJson, { path: 'cat' });

  // log('ROUTES_TREE:', 'grey', appRoutes);

  // const SUBSET = findRouteByBasePath(appRoutes, { basePath: 'account' });
  // log('ROUTES_TREE_SUBSET:', 'yellow', SUBSET);
  // log('ROUTES_TREE_FLAT:', 'lime', FLAT);

  // const TOP = findTopRoutes(SUBSET);
  // log('ROUTES_TREE_TOP:', 'lime', TOP);

  // ======================================================================== //

  // const queryCanales = getQueryString('canales', { cat_id: r1 });
  // const queryPeriodicos = getQueryString('canales', { id: r2 });
  // const queryEntidades = getQueryString('canales', { id: r3 });
  // const { data: fetchedCanales } = useQueryApi({ endpoint: queryCanales });
  // const { data: fetchedPeriodicos } = useQueryApi({ endpoint: queryPeriodicos });
  // const { data: fetchedEntidades } = useQueryApi({ endpoint: queryEntidades });

  // getQueryString('posts', { cat_id: CAT_IDS[0] });

  // const { data: fetchedCanalesV2 } = useQueryGet({ endpoint: 'posts', cat_id: CAT_IDS[0] });
  // const { data: fetchedPeriodicos } = useQueryApi({ endpoint: queryPeriodicos });
  // const { data: fetchedEntidades } = useQueryApi({ endpoint: queryEntidades });

  // log('WITH_PARAMS', 'orange', fetchedCanalesV2);

  // =================================================================//

  const getParentCategoryItems = () => {
    return categories.map((cat: any) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const path = metaRoutes.find((route: RouteConfig) => !route.children?.length)?.path;
      const callout: DataEntry = categories.find((item: any) => item.cat_id === cat.uuid) || {};

      if (callout.socials) delete callout.socials;
      const single = {
        ...callout,
        name: cat.title,
        image: getFakerImageURLv3(),
        url: cat?.path,
        path: cat.path,
      };
      // TODO: if NO match, return name and fetch IMAGE from somewhere.
      return single;
    });
  };

  useEffect(() => {
    const parentCategories = getParentCategoryItems();
    setDataCats(parentCategories);
  }, []);

  // ======================================================================== //

  // const { getAppRoutes } = useAppRoutes();
  // const appRoutes = getAppRoutes();

  // const transformRoutes = (routes: RouteObject[], basePath: string = '/'): any[] => {
  //   return routes.map((route) => {
  //     const { path, children, ...rest } = route;
  //     const fullPath = `${basePath}${path}`.replace(/\/+/g, '/'); // Ensure no double slashes

  //     const transformedRoute: any = {
  //       ...rest,
  //       path,
  //       pathname: fullPath,
  //     };

  //     if (children) {
  //       transformedRoute.children = transformRoutes(children, `${fullPath}/`);
  //     }

  //     return transformedRoute;
  //   });
  // };

  // const transformedRoutes = transformRoutes(appRoutes);

  // ======================================================================== //

  return (
    <section css={styles}>
      <Row>
        <Col xs={12}>
          {isDevToolsVisible && (
            <pre style={{ fontSize: '0.7em', lineHeight: 1.3 }}>
              {/* <>{JSON.stringify(cleanRoutesOfElements(appRoutes), null, 2)}</> */}
              {/* <>{JSON.stringify(transformedRoutes, null, 2)}</> */}
              {/* <>{JSON.stringify(cats, null, 2)}</> */}
            </pre>
          )}
          <p className="text-main">
            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
            ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
            nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum. */}
          </p>
          {/*
          <div className="highlights">
            <div className="grid-item-container" css={responsiveness}>
              <div className="grid-item-gutter">
                <h1>Canales</h1>
                {fetchedCanales && <ListItemCard data={fetchedCanales} single />}
              </div>
            </div>
            <div className="grid-item-container" css={responsiveness}>
              <div className="grid-item-gutter">
                <h1>Periodicos</h1>
                {fetchedPeriodicos && <ListItemCard data={fetchedPeriodicos} single />}
              </div>
            </div>
            <div className="grid-item-container" css={responsiveness}>
              <div className="grid-item-gutter">
                <h1>Entidades</h1>
                {fetchedEntidades && <ListItemCard data={fetchedEntidades} single />}
              </div>
            </div>
          </div>
          */}
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="col-list">
          {/* {!isFetching && dataFetched && <DataTable data={dataFetched} schema={tableConfig} />} */}
          {/* {dataFetched && <DataTable data={dataFetched} schema={schema} />} */}
          {!!dataCats.length && (
            <DataGrid data={dataCats} itemComponent={ListItemCard} tableConfig={tableConfigHome} />
          )}
          {/* <pre>{dataFetched && JSON.stringify(dataFetched, null, 2)}</pre> */}
        </Col>
      </Row>
    </section>
  );
};

export default HomePage;
