import { faker } from '@faker-js/faker';
import type { CatMeta } from 'types';

export const genMockCategory = (metaCats?: CatMeta[]) => {
  if (metaCats) {
    // NOTE: CHOOSE CATEGORY ID FROM CURRENT DATABASE
    return faker.helpers.arrayElement([...metaCats.map((cat: any) => cat.uuid)]);
  }

  // NOTE: CHOOSE CATEGORY ID FROM (2024-01-28)
  return faker.helpers.arrayElement([
    '123e0000-e89b-12d3-a456-000000000001',
    'sub00001-d926-49a7-aaf7-661bb4fb0bab',
    '5ea502af-7d2d-4249-bd82-65940c31302f',
    '087dc26e-88e5-46bd-8f70-25bc7e735f55',
    'b0b0d753-ca65-4459-a882-b27a33506b8a',
    'ff7491db-ed2a-4c8c-bca9-246cdbfd8846',
    '123e0000-e89b-12d3-a456-000000000003',
    '4ae0915e-3387-418a-aba6-e882fda08396',
    '636c09de-f8a0-4ddd-9e83-e050f9a188d0',
    '3ccd2f7f-7063-4dae-a518-0be03dfbca27',
    'a29b8bc9-f96a-4182-9898-87b5992cf7ca',
    '635771a9-f81a-488e-b565-7629b6ad4053',
    '8be56a86-470f-4a9e-ac16-0a8fde0d6068',
    '3eab489c-203f-41dd-8d98-795658af806c',
    '59a15ae5-3b33-4c8f-8d14-2797b3ca5362',
    'b96c2e4a-0732-4465-8ca4-76e8c210d226',
  ]);
};

// ======================================================================== //

export const getUserID = () => {
  return faker.helpers.arrayElement([
    'admin001-e89b-12d3-a456-426614174000', // admin
    'admin001-e89b-12d3-a456-426614174000', // admin
    'admin001-e89b-12d3-a456-426614174000', // admin
    'demo0001-e89b-12d3-a456-426614174000', // demo
    'demo0001-e89b-12d3-a456-426614174000', // demo
    '123a4567-e89b-12d3-a456-426614174000', // user
    '123a4567-e89b-12d3-a456-426614174000', // user
    '123a4567-e89b-12d3-a456-426614174000', // user
    '123a4567-e89b-12d3-a456-426614174000', // user
    '09782a90-b0af-4f1a-af31-f51263af9a0e', // valencia
    '09782a90-b0af-4f1a-af31-f51263af9a0e', // valencia
    '09782a90-b0af-4f1a-af31-f51263af9a0e', // valencia
    '4e745351-1bf1-4ab6-8017-a3caea6d39fb', // zaragoza
    '4e745351-1bf1-4ab6-8017-a3caea6d39fb', // zaragoza
    '4e745351-1bf1-4ab6-8017-a3caea6d39fb', // zaragoza
    '5de8ebba-24e9-430a-884b-82b2789829ba', // u007
    '5de8ebba-24e9-430a-884b-82b2789829ba', // u007
    '5de8ebba-24e9-430a-884b-82b2789829ba', // u007
    '5de8ebba-24e9-430a-884b-82b2789829ba', // u007
  ]);
};
