import type { FieldConfig } from 'types';
import cloneDeep from 'lodash/cloneDeep';

export const getFieldsetConfig = <T extends FieldConfig>(
  fields: T[],
  options?: {
    prefix?: `${string}_`;
    // omit?: Array<T['name']> } // sring[] 😔
    omit?: T[][number]['name'][]; // TODO: FIX - TYPE-CONSTRAINT NOT WORKING !!
  },
): FieldConfig[] => {
  const draftFields = options?.prefix
    ? cloneDeep(fields).map((fieldConfig) => ({
        ...fieldConfig,
        name: `${options.prefix}${fieldConfig.name}`,
      }))
    : cloneDeep(fields);

  if (options?.omit) {
    const filtered = draftFields.filter((field) => !options.omit.includes(field.name));
    return filtered;
  }

  return draftFields;
};
