import { css } from '@emotion/react';
import { colors, layout, forms, min, max, cssInputText } from 'styles';

const rowHeight = 0.8;

const colorLink = colors.primary;
const colorLinkHover = colors.primaryDark;

export const styles = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding: 0 calc(${layout.padding} * 1.2);
  font-size: 1.15em;
  /* line-height: 1.7; */

  .row {
    /* line-height: 1.66; */
    & > .col {
      display: flex;
      align-items: center;
    }
    & > .col > .col {
      display: flex;
      align-items: flex-start;
    }
  }

  .col-label {
    align-self: flex-start;
    align-items: flex-start !important;
    padding: calc(${layout.padding} * ${rowHeight}) 0 !important;
    label {
      font-size: 1em !important;
      margin: calc(${layout.padding} * 0) 0 !important;
      letter-spacing: 0.015em;
      color: ${colors.grey};
      opacity: 0.8;
      &:after {
        content: ':';
        padding-left: 0.1em;
      }
    }
  }

  .col-value {
    ${cssInputText}
    font-size: 1em !important;
    font-weight: 600;
    color: ${colors.text};
    padding: calc(${layout.padding} * ${rowHeight}) 0;
    letter-spacing: 0.02em;
    width: 100%;
  }

  .col-repeater {
    width: 100%;
    padding: calc(${layout.padding} * 0) calc(${layout.padding} * 0) calc(${layout.padding} * 0.33) !important;
  }

  .col-paragraphs {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  a,
  a:active,
  a:visited {
    -webkit-text-fill-color: ${colorLink};
    color: ${colorLink};
    svg {
      -webkit-text-fill-color: ${colorLink};
      color: ${colorLink};
    }
    &:hover,
    &:hover svg {
      -webkit-text-fill-color: ${colorLinkHover};
      color: ${colorLinkHover};
    }
  }

  /***** PREFIX ICONS *****/

  .value-url,
  .value-email,
  .value-tel {
    white-space: nowrap;
    color: ${colors.primaryDark};
    svg {
      transform: scale(1.4) translateY(-5%);
      margin-right: 0.33em;
    }
  }

  /***** EDGE-CASE FIXES *****/

  &.row-socials {
    margin-top: calc(${layout.padding} * 0.66);
    .col-socials {
      padding-top: calc(${layout.padding} * 1.2) !important;
    }
  }

  .value-tel {
    svg {
      margin-right: 0.2em;
    }
  }

  .value-email svg {
    transform: scale(1.2) translateX(-7%) translateY(-5%);
  }

  .socials {
    transform: scale(1.2) translate(4%, -12%) !important;
  }
`;
