import { UseFormReturn } from 'react-hook-form';
import { types, ButtonVariant } from 'components/Form';
import { ArrayJSX } from 'utils/ArrayJSX';
import { formatLabelString } from 'utils/utils.string.format';
import { colors } from 'styles/colors.css';

// TODO: REPLACE HOOK VERSION ???
export type ButtonStateStyle = { color: string | undefined; variant: ButtonVariant };

// ========================================================================== //

export const getFieldDefaultUI = ({ fieldName }: { fieldName: string }) => {
  const obj = {
    name: fieldName,
    label: formatLabelString(fieldName),
    placeholder: formatLabelString(fieldName),
  };
  // log('LABEL_STRING', 'violet', fieldName, obj);
  return obj;
};

// ======================================================================== //

export const useFormUtilsUI = ({ formMethods }: { formMethods: UseFormReturn }) => {
  // GET REQUIRED FORM VALUES, STATE, METHODS via HOOKS...
  const { getValues, formState } = formMethods;
  const { isDirty, isValid, isSubmitted } = formState;
  const isLocked = !isDirty || !isValid;

  // USE ArrayJSX() + FORM STATE TO GENERATE CSS Classes Array
  const getFormClasses = (initClass = '') => {
    const isEmpty = Object.values(getValues()).filter((value) => value).length === 0;

    const formClasses = new ArrayJSX(initClass);
    isLocked && formClasses.push('form-locked');
    isDirty && !isEmpty && formClasses.push('form-dirty');
    isValid && formClasses.push('form-valid');
    isSubmitted && formClasses.push('form-submitted');

    return formClasses;
  };

  const getButtonStyleByState = ({ buttonType }: { buttonType: types | string }): ButtonStateStyle => {
    if (buttonType === types.submit) {
      return isLocked && !isValid
        ? { color: colors.grey, variant: 'solid' }
        : { color: colors.successDark, variant: 'solid' };
    }

    if (buttonType === types.reset) {
      return isLocked ? { color: colors.grey, variant: 'outline' } : { color: colors.warningLight, variant: 'solid' };
    }

    // DEFAULT: (buttonType === types.button)
    return isLocked ? { color: colors.grey, variant: 'outline' } : { color: colors.warning, variant: 'outline' };
  };

  const getFormSummaryMessage = () => {
    const message = !isDirty
      ? 'Form is without changes and valid.'
      : isValid
        ? 'Form is valid. Submission required to save changes.'
        : !isValid
          ? 'Form is invalid.'
          : 'Ready to submit';

    return message;
  };

  return {
    getFormClasses,
    getButtonStyleByState,
    getFormSummaryMessage,
  };
};
