/*
export enum Action {
  NEW = 'NEW',
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  ACCESS = 'ACCESS',
}

type ACTIONS<Key extends string = string> = {
  readonly [key in Action]: Action;
};
*/

export enum Action {
  NEW = 'new',
  VIEW = 'view',
  EDIT = 'edit',
  UPDATE = 'update',
  DELETE = 'delete',
  ACCESS = 'access',
}

type ACTIONS<Key extends string = string> = {
  readonly [key in Uppercase<Action>]: Action;
};

export const ACTIONS: ACTIONS = {
  NEW: Action.NEW,
  VIEW: Action.VIEW,
  EDIT: Action.EDIT,
  UPDATE: Action.UPDATE,
  DELETE: Action.DELETE,
  ACCESS: Action.ACCESS,
} as const;
