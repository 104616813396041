import { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import type { DataEntry, TableConfig } from 'types';
import { useGlobal } from 'store/useGlobalContext';
import { DataColumnConfig } from 'components/DataTable/DataTable.types';
import { translate, removePrivateData } from 'utils';

type UseDetailConfigProps = {
  tableConfig: TableConfig;
  data: DataEntry[];
  // schema: ISchema;
};

export const useConfigAndData = ({
  tableConfig: configSource,
  data: dataSource = [],
}: UseDetailConfigProps) => {
  const { metaCats } = useGlobal('metaCats');
  const [values, setValues] = useState<any>();

  // INIT FIELDS GET/SET ========================================================= //
  // BEGIN... EXTRACT JSON_META + SANITZE DATA + CLONE CONFIG FOR MUTATION...

  const tableConfig = cloneDeep(configSource);
  const SOURCE_DATA = cloneDeep(dataSource);

  removePrivateData(SOURCE_DATA);

  // ------------------------------------------------------------------------ //

  const getConfigColumnNames = ({ columns }: { columns: DataColumnConfig[] }) => {
    const configColumnNames = columns.map((field: DataColumnConfig) => field.name);
    return configColumnNames;
  };

  // ====================================================================== //
  // FOR FETCH LOADED OPTIONS !!!

  const getDataFieldNames = async ({ data = [] }: { data: DataEntry[] }) => {
    for await (const field of Object.values(data)) {
      for await (const [key, value] of Object.entries(field)) {
        // REPLACE cat_id VALUE WITH CATEGORY name VALUE...
        if (key.endsWith('_id') && key === 'cat_id') {
          const newValue = metaCats.find((meta: DataEntry) => meta.uuid === value);
          if (newValue?.name) {
            field[key] = newValue?.name;
          }
        }
      }
    }

    return data;
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const initializeValues = async ({ tableConfig, data }: { tableConfig: any; data: DataEntry[] }) => {
    const fieldNames = await getDataFieldNames({ data });

    setValues(fieldNames);
  };

  // ------------------------------------------------------------------------ //

  useEffect(() => {
    if (dataSource) {
      translate(tableConfig);
      getConfigColumnNames(tableConfig);
      initializeValues({ tableConfig, data: dataSource });
    }
  }, [dataSource]);

  return { values };
};
