/* eslint-disable new-cap */
import { css } from '@emotion/react';
import Color from 'color';
import { layout } from 'styles/layout.css';
import { colors } from 'styles/colors.css';

export const colorVariant = {
  light: Color(colors.primaryXLight).lighten(0.5).hex(),
  med: Color(colors.primary).darken(0.1).desaturate(0.3).hex(),
  dark: Color(colors.primaryDark).lighten(0.1).desaturate(0.3).hex(),
};

export const styles = css`
  padding: 0 0 calc(${layout.padding} * 6) 0;
  margin-top: 0;

  section {
    padding: 0;

    h2 {
      margin-bottom: 1em;
      font-size: 1.7em;
      color: ${colors.primaryXLight};
      border-bottom: 2px solid ${colorVariant.light};
    }
    h3 {
      margin-bottom: 1.5em;
    }
    h2 + h3 {
      margin-top: -1.2em;
      margin-bottom: 2em;
    }
  }

  .col-value {
    p {
      margin-top: 0;
    }

    .social-item {
      margin-left: calc(${layout.padding} * -1);
      svg {
        margin-right: calc(${layout.padding} * 1);
      }
      button {
        padding: 0;
        font-weight: 700;
        &:hover {
          background: transparent;
        }
      }
    }
  }

  .col-image {
    padding-bottom: calc(${layout.padding} * 1);
  }

  .image-holder {
    min-height: 300px;
    height: 400px;
    background-repeat: no-repeat !important;
    background-position: 50% !important;
    background-size: cover !important;
  }

  .col-tags {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .tag {
      padding: 1.25em 3em;
      margin-bottom: 0.8em !important;
      border: 0;
      background: ${Color(colors.greyLight).lighten(0.18).hex()};
      border: ${layout.borderWidth} solid ${Color(colors.greyLight).lighten(0.1).hex()}!important;
      color: ${Color(colors.greyLight).darken(0.2).hex()}!important;
      -webkit-text-fill-color: ${Color(colors.greyLight).darken(0.25).hex()}!important;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .col-list-by-id {
    padding: 0 !important;
  }

  hr {
    opacity: 0.5;
    border-color: ${colors.greyLight};
    margin-top: calc(${layout.padding} * 2);
    &.hr-buttons {
      padding-bottom: calc(${layout.padding} * 0);
      margin-bottom: calc(${layout.padding} * 4);
    }
  }
`;
