import { ForwardedRef, forwardRef, useState, useEffect, ChangeEvent } from 'react';
import { css } from '@emotion/react';
import type { SizeUI } from 'types';
import { SWITCH_LABELS } from './Switch.config';
import { styles } from './Switch.css';

// SORUCE: https://www.alltimepower.com/blog/react-hook-forms-custom-checkbox/
// HAS BEEN GREATLY SIMPLIFIED !!

interface ISwitch {
  name: string;
  label?: string;
  defaultValue: boolean;
  onChange: (args?: any) => void;
  size?: SizeUI;
  theme?: string;
  on?: string;
  off?: string;
}

export const Switch = forwardRef<any, ISwitch>(
  ({ name, label, defaultValue = false, onChange, size = 'md', theme, on, off }: ISwitch, ref: ForwardedRef<any>) => {
    const getToggleLabels = () => {
      const labels = theme ? SWITCH_LABELS[theme as string] : SWITCH_LABELS['default'];

      return css`
        .toggle-inner {
          &::before {
            content: '${on || labels.on}';
          }
          &::after {
            content: '${off || labels.off}';
          }
        }
      `;
    };

    const [checked, setChecked] = useState(defaultValue);

    useEffect(() => {
      if (onChange) {
        onChange(checked);
      }
    }, [checked]);

    return (
      <div css={styles}>
        <div className="switch-wrapper" onClick={() => setChecked(!checked)}>
          <input
            name={name}
            aria-label={`toggle__${label}`}
            id={`field__${name}`}
            ref={ref as ForwardedRef<any>}
            className={'input-switch'}
            type="checkbox"
            // value={`${checked}`}
            defaultChecked={checked}
            checked={checked}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setChecked(event.target?.checked);
            }}
          />
          <div className={`toggle-switch size-${size} ${checked ? 'is-checked' : ''}`} css={getToggleLabels()}>
            <span className="toggle-inner" />
            <span className="toggle-circle" />
          </div>
        </div>
      </div>
    );
  },
);
