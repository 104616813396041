import type { FilterLocation } from '../InviteFiltersContext';

interface ResetFilters {
  LOCATIONS: FilterLocation;
}

export const RESET: ResetFilters = {
  LOCATIONS: { value: '', label: '', isActive: false },
};

// TODO: NEEDED ??
// POSSIBLY REDUCES CLARITY FOR LOGIC THAT IS COMPLEX
export const FILTERS = {
  PROVINCE: 'province',
  SEARCH_QUERY: 'searchQuery',
} as const;
