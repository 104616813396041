/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable import/order */
import './globals';

// CORE IMPORTS
import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { reportWebVitals } from 'utils/reportWebVitals';
import ErrorBoundary from './ErrorBoundary';

import { AuthProvider } from 'auth/AuthProvider';
import { GlobalProvider } from 'store/GlobalProvider';
import App from './App';

// LOCALE
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import 'i18n/i18n-init';
import 'i18n/locale';
import { ScreenClassProvider } from 'react-grid-system';
import { cssGlobal } from 'styles/global.css';
import { Global as GlobalStyles } from '@emotion/react';

i18n.changeLanguage('es');

// NODE ENVIRONMENT
// console.clear();
log('(META)', 'grey', import.meta.env);

const NODE_ENV = process.env.NODE_ENV || 'development';
log('(INDEX) render', 'grey', process.env.NODE_ENV, { NODE_ENV });

const helmetContext = {};
const queryClient = new QueryClient();

// TODO: FIX 'HMR' - Hot Module Replacement
// NOT WORKING !! - Vite says to read:
//  https://github.com/vitejs/vite-plugin-react/tree/main/packages/plugin-react#consistent-components-exports

createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ErrorBoundary>
      <I18nextProvider i18n={i18n}>
        <HelmetProvider context={helmetContext}>
          <QueryClientProvider client={queryClient}>
            <ScreenClassProvider>
              <AuthProvider>
                <GlobalProvider>
                  <GlobalStyles styles={cssGlobal} />
                  <App />
                </GlobalProvider>
              </AuthProvider>
            </ScreenClassProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </I18nextProvider>
    </ErrorBoundary>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint.
reportWebVitals(console.log);
