import { ReactNode } from 'react';

import { styles } from './Alert.css';

export const Alert = ({ color = 'info', children }: { color: string; children: ReactNode }) => {
  return (
    <div css={styles} className={`alert alert-${color}`}>
      {children}
    </div>
  );
};
