import { METADATA } from 'types';
import { INPUTS, DEFAULT_VALUE } from 'types/form.types';
import { AUTH_USER } from 'types/form.types';
import type { FieldConfig } from 'components/DetailSummary';
import type { FormConfig, FormButton, FormHeroConfig } from 'components/Form';
import { types, BUTTONS } from 'components/Form/components/FormButtonBar/FormButtonBar.types';
import type { SelectOption } from 'components/FormUI/Select';
import {
  FIELDS_ADDRESS,
  FIELDS_PERSONAL_DATA,
  FIELDS_DOCS_PERSONAL,
  FIELDS_CONTACT_PUBLIC,
  FIELDS_CONTACT_PRIVATE,
  FIELDS_SOCIALS,
  FIELDS_CONFIRM_SIGNATOR,
} from 'config/form.sections';
import { DATATYPE, ENDPOINT } from './entidades.config';
import { getFieldsetConfig } from 'config/data.utils/getFieldsetConfig';

let i = 0;

const buttons: FormButton[] = [
  {
    label: 'CANCEL',
    type: types.button,
    action: BUTTONS.CANCEL,
  },
  {
    label: 'SAVE',
    type: types.submit,
    action: BUTTONS.SUBMIT,
  },
];

const hero: FormHeroConfig = {
  heroTitleKey: ['name'],
};

// NOTE: FOLLOWING CONFIG WILL BE MERGED WITH form.config.ts/globalFieldProps
// TO POPULATE COMMON PROPS, OPTIONS etc
export const formConfig: FormConfig = {
  dataType: { key: DATATYPE, role: 'form' },
  api: { endpoint: ENDPOINT },
  dataStructure: {
    source: {
      columns: ['name'],
      json_meta: true,
    },
    target: {
      columns: ['name'],
      json_meta: true,
    },
  },
  isNavPromptActive: true,
  hero,
  buttons,
  // ----------------------------------------------------------------- //
  sections: [
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Datos de la entidad o asociación',
      fields: [
        {
          name: 'name',
          label: 'nombre de entidad',
          inputType: INPUTS.text,
          required: true,
          colWidth: { xs: 12, md: 6 },
        },
        {
          name: 'image',
          placeholder: 'Escoge archivo...',
          hint: 'choose image to upload',
          inputType: INPUTS.image,
          colWidth: { xs: 12, md: 6 },
        },
      ],
    },
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Datos del titular o representante (ENTIDADES)',
      fields: [
        ...getFieldsetConfig(FIELDS_PERSONAL_DATA),
        ...[
          {
            name: 'position',
            label: 'cargo del titular',
            inputType: INPUTS.text,
            required: false,
          },
          ...getFieldsetConfig(FIELDS_DOCS_PERSONAL),
        ].map((fieldConfig) => ({ ...fieldConfig, colWidth: { xs: 12, md: 4 } })),
      ],
    },
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Datos de la sede social de la entidad o asociación',
      fields: [
        {
          name: 'registration_type',
          label: 'Tipo de entidad o asociación',
          inputType: INPUTS.radios,
          options: [
            {
              value: 'no-registrada',
              label: 'no registrada',
            },
            {
              value: 'registrada',
              label: 'registrada',
            },
          ] as SelectOption[],
          defaultValue: 'no-registrada',
          colWidth: { xs: 12 },
        },
        {
          name: 'registration_number',
          label: 'Número de registro',
          inputType: INPUTS.text,
          isDisabled: true,
          isHidden: true,
          isControlledBy: {
            criteria: { registration_type: 'registrada' },
            target: { isHidden: false },
          },
        },
        // ================================================ //
        // TODO: REMOVE DATE-CONSTRAINTS (maxData: TODAY)
        {
          name: 'registration_date',
          label: 'Fecha de constitución',
          inputType: INPUTS.date,
          // TODO: DATE RESTRAINTS (maxData: TODAY - able select prior)
          isHidden: true,
          isControlledBy: {
            criteria: { registration_type: 'registrada' },
            target: { isHidden: false },
          },
          props: {
            minDate: false,
          },
        },
        {
          name: 'sector',
          label: 'Ámbito de actuación',
          inputType: INPUTS.select,
          options: [
            {
              value: 'local',
              label: 'Local',
            },
            {
              value: 'regional',
              label: 'Regional',
            },
            {
              value: 'nacional',
              label: 'Nacional',
            },
            {
              value: 'internacional',
              label: 'Internacional',
            },
          ] as SelectOption[],
          colWidth: {
            xs: 6,
          },
        },
        {
          name: 'cif',
          label: 'CIF',
          inputType: INPUTS.text,
          isHidden: true,
          isControlledBy: {
            criteria: { registration_type: 'registrada' },
            target: { isHidden: false },
          },
        },
      ],
    },
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Dirección fiscal',
      fields: [...getFieldsetConfig(FIELDS_ADDRESS, { prefix: 'biz_' })],
    },
    // ---------------------------------------------------------------------- //
    {
      // NOTE: VERSION A: COPY_FROM
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Dirección postal',
      sectionToggle: {
        name: 'copy_from_user_address',
        label: 'Marcar si la dirección postal NO coincide con la dirección fiscal.',
        inputType: INPUTS.checkbox,
        defaultValue: false,
        params: { endpoint: 'register', uuid: AUTH_USER.UUID },
      },
      fields: [...getFieldsetConfig(FIELDS_ADDRESS)],
    },
    /*
      // NOTE: VERSION B: COPY_FROM
      {
        sectionTitle: 'Dirección postal',
        copyFrom: {
          name: 'copy_from_user_address',
          label: `Marcar si la dirección coincide con la del titular : ${AUTH_USER.EMAIL}`,
          inputType: INPUTS.checkbox,
          defaultValue: false,
          params: { endpoint: 'register', uuid: AUTH_USER.UUID },
        },
        fields: FIELDS_ADDRESS,
      },
    */
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Más detalles y Descripción de la entidad o asociación',
      description: 'Realice una descripción de la Entidad o Asociación que será visible para los usuarios',
      fields: [
        {
          name: 'url',
          label: 'URL de la página web (o similar) de la entidad o asociación:',
          inputType: INPUTS.url,
          required: false,
          colWidth: { xs: 12, md: 6 },
        },
        {
          name: 'entity_type',
          label: 'tipo de entidad',
          required: true,
          inputType: INPUTS.select,
          options: [],
          optionsAsync: {
            meta_key: METADATA.entity_type,
          },
          colWidth: { xs: 12, md: 6 },
        },
        {
          name: 'business_function',
          label: 'Finalidad de la Entidad o Asociación',
          inputType: INPUTS.textarea,
          required: false,
          colWidth: { xs: 12 },
          // props: {}, // TODO: CUSTOM HEIGHT
        },
        // {
        //   name: 'business_function',
        //   label: 'Finalidad de la Entidad o Asociación',
        //   inputType: INPUTS.text,
        //   required: false,
        // },
        // {
        //   name: 'primary_function',
        //   label: 'Finalidad principal',
        //   inputType: INPUTS.text,
        //   required: false,
        // },
        {
          name: 'description',
          label: 'Descripción de la entidad',
          inputType: INPUTS.textarea,
          attributes: { rows: 12 },
          required: false,
        },
        {
          name: 'activities',
          label: 'Tipo de actos o actividades que realiza',
          inputType: INPUTS.text_rich,
          attributes: { rows: 12 },
          required: false,
        },
      ],
    },
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Datos de Contacto Públicos',
      description: 'visibles a todo el mundo en la web pública',
      fields: [...getFieldsetConfig(FIELDS_CONTACT_PUBLIC)],
    },
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Datos de Contacto para otros medios',
      description: 'visibles solo por titulares de otros medios o entidades',
      fields: [...getFieldsetConfig(FIELDS_CONTACT_PRIVATE)],
    },
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Canales secundarios',
      description: 'Indique canales secundarios o redes sociales',
      fields: [...getFieldsetConfig(FIELDS_SOCIALS)],
    },
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'A devolver firmado:',
      fields: [...getFieldsetConfig(FIELDS_CONFIRM_SIGNATOR)],
    },
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      fields: [
        {
          name: 'cat_id',
          inputType: INPUTS.uuid,
          // defaultValue: DEFAULT_VALUE.cat_id,
          isHidden: true,
        },
      ],
    },
  ],
};
