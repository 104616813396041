import { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import type { DataEntry } from 'types';
import { useRouter } from 'routes/hooks';
import { api, headersDefault as headers, getQueryString } from 'api';
import type { IFormField } from 'components/FormField';
import {
  getParsedJSON,
  initDefaultValues,
  isSet,
  translate,
  // flattenFieldConfigs,
  applyFieldColumnWidths,
  getInitFieldConfigs,
  removePrivateData,
  formatFieldValue,
} from 'utils';
import type { DetailConfig, FieldConfig, DetailSection, FetchedSource } from './DetailSummary.types';
import { defaultFieldProps } from './config';
import { applyDefaultFieldLabels, flattenDetailFieldConfigs } from './utils';

type UseDetailConfigProps = {
  detailConfig: DetailConfig;
  data: DataEntry;
  // schema: ISchema;
};

export const useDetailConfig = ({ detailConfig: configSource, data: dataSource = {} }: UseDetailConfigProps) => {
  const { route } = useRouter();
  const [sections, setSections] = useState<DetailSection[] | null>(null);
  const [values, setValues] = useState<any>();

  // INIT FIELDS GET/SET ========================================================= //
  // BEGIN... EXTRACT JSON_META + SANITZE DATA + CLONE CONFIG FOR MUTATION...

  const detailConfig = cloneDeep(configSource);
  const SOURCE_DATA = cloneDeep(dataSource);
  const JSON_META = getParsedJSON(SOURCE_DATA?.json_meta) || {};

  // log('1.a ===== REMOVE_PRIVATE_DATA - SOURCE_DATA ===== useDetailConfig', 'red', SOURCE_DATA);
  // log('1.b ===== REMOVE_PRIVATE_DATA - JSON_META ===== useDetailConfig', 'red', JSON_META);

  removePrivateData(SOURCE_DATA);
  removePrivateData(JSON_META);

  for (const [key, value] of Object.entries(JSON_META)) {
    !isSet(value) && delete JSON_META[key];
  }

  const { defaultValues } = initDefaultValues({ data: SOURCE_DATA, config: detailConfig });

  // ------------------------------------------------------------------------ //

  const fetchSourceData = async ({
    fetchedSource,
    fields,
  }: {
    fetchedSource: FetchedSource;
    fields: FieldConfig[];
  }) => {
    const { endpoint, ...params } = fetchedSource.params;
    const response = await api.get(`${endpoint}`, { params });

    if (response.status === 200 && response.data) {
      const sourceData: DataEntry = response.data;
      for (const field of fields) {
        if ('name' in field) {
          field.value = sourceData[field.name] || getParsedJSON(sourceData?.json_meta)[field.name];
        }
      }
    }
  };

  const initialize = async ({ sectionsConfig }: { sectionsConfig: DetailSection[] }) => {
    for await (const section of sectionsConfig) {
      // NOTE: POPULATE VALUESSECTION DATA SOURCE FROM OTHER DB TABLE ??
      const fields = getInitFieldConfigs({ fields: section.fields, defaultFieldProps }) as IFormField[];

      applyDefaultFieldLabels({ fields });
      applyFieldColumnWidths({ fields });

      if (section.fetchedSource) {
        await fetchSourceData({ fetchedSource: section.fetchedSource, fields });
      } else {
        for await (const field of fields) {
          // NOTE: POPULATE VALUES WITH DEFAULT API DATA
          if (field.name !== 'json_meta') {
            field.value = JSON_META[field.name] || defaultValues[field.name as keyof typeof defaultValues];
          }
          if (field.data) {
            formatFieldValue({ field, route });
          }
        }
      }
      section.fields = fields;
    }
    setSections(sectionsConfig as DetailSection[]);
  };

  // ------------------------------------------------------------------------ //

  useEffect(() => {
    if (sections) {
      // WITH SECTIONS, CREATE DEDICATED VALUES OBJECT (PARSED)
      const sectionValues = {};
      // flattenFieldConfigs(sections).map((config: FieldConfig) => {
      flattenDetailFieldConfigs(sections).map((config: FieldConfig) => {
        Object.assign(sectionValues, { [config.name]: config.value });
      });
      setValues(sectionValues);
    }
  }, [sections]);

  // ------------------------------------------------------------------------ //

  useEffect(() => {
    translate(detailConfig);
    initialize({ sectionsConfig: detailConfig.sections });
  }, []);

  return { sections, values };
};
