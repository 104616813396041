import { css } from '@emotion/react';
import { layout } from 'styles/layout.css';

// UN-TOUCHED
// btn variant-outline size-md btn-padded form-locked form-valid css-186qjyk-Button

// DIRTY + INVALID
// btn variant-outline size-md btn-padded form-locked form-dirty css-186qjyk-Button

// DIRTY + VALID
// btn variant-solid size-md btn-padded form-dirty form-valid css-bs94w1-Button

export const styles = css`
  padding: 0;

  hr {
    opacity: 0.5;
  }

  .col-buttons {
    display: flex;
    justify-content: stretch;
    align-items: center;

    button {
      margin: 0 calc(${layout.padding} / 2) !important;
      &:first-of-type {
        margin-left: 0 !important;
      }
      &:last-of-type {
        margin-right: 0 !important;
      }
      width: inherit;
      max-width: unset;

      &.variant-outline:disabled {
        background-color: transparent;
      }
    }
  }
`;
