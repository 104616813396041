import { Row, Col } from 'react-grid-system';
import { useState, useEffect, forwardRef, ForwardedRef } from 'react';
import { IoMdRadioButtonOff as IconOff, IoMdRadioButtonOn as IconOn } from 'react-icons/io';
import type { SizeUI } from 'types';
import type { SelectOption } from 'components/FormUI/Select';
import { styles } from './RadioGroup.css';

interface IRadioGroup {
  name: string;
  label?: string;
  defaultValue: string;
  options: SelectOption[];
  onChange: (args?: any) => void;
  size?: SizeUI;
}

export const RadioGroup = forwardRef(
  ({ name, defaultValue, options, onChange, size = 'md' }: IRadioGroup, ref: ForwardedRef<HTMLInputElement>) => {
    const [currentChecked, setCurrentChecked] = useState<string | undefined>(defaultValue);

    const getColumnSize = (numOptions: number) => {
      if (!(numOptions % 2)) return 3;
      if (!(numOptions % 3)) return 3;
      return 3;
    };

    const handleSelect = (selection: string) => {
      setCurrentChecked(selection);
      if (onChange) {
        onChange(currentChecked);
      }
    };

    useEffect(() => {
      if (onChange) {
        onChange(currentChecked);
      }
    }, [currentChecked]);

    const numOptions = options.length;
    const colWidth = getColumnSize(numOptions);
    const dummyAction = () => ({});

    return (
      <div className={`radio-group size-${size}`} css={styles}>
        <Row>
          {options.map((option: SelectOption) => {
            const isChecked = !!(currentChecked === option.value);
            return (
              <Col key={option.value} xs={colWidth}>
                <div
                  onClick={() => handleSelect(option.value)}
                  className={`radio-option ${isChecked ? 'is-checked' : ''}`}
                >
                  <input
                    name={name}
                    type="radio"
                    aria-label={option.label}
                    id={option.value}
                    value={option.value}
                    checked={isChecked}
                    onChange={dummyAction}
                    ref={ref as ForwardedRef<HTMLInputElement>}
                    style={{ display: 'none' }}
                  />
                  <div>
                    <IconOn className="icon-on" />
                    <IconOff className="icon-off" />
                  </div>
                  <label htmlFor={option.value}>{option.label}</label>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  },
);
