import { FaMapMarkerAlt as IconPin } from 'react-icons/fa';
import { styles } from './MapMarker.css';

export const MapMarker = ({ text, onClick }: any) => {
  const IconPinShadow = () => <IconPin className="map-pin-shadow" />;
  return (
    <div css={styles} onClick={onClick}>
      <IconPinShadow />
      <IconPin />
      <span className="map-pin-label">{text}</span>
    </div>
  );
};
