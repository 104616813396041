/* eslint-disable import/namespace */

import { useFormContext } from 'react-hook-form';
import ReactSelect, { SelectInstance } from 'react-select';
import { forwardRef, useEffect } from 'react';
import type { ForwardedRef } from 'react';
import ReactSelectAsync from 'react-select/async';
import { ArrayJSX } from 'utils/ArrayJSX';
import type { SelectOption, SelectProps } from './Select.types';
import { SELECT_DEFAULT_PROPS } from './select.config';
import { useSelectConfig } from './useSelectConfig';
import { styles } from './Select.css';

const CustomOption = ({ innerProps, isDisabled, children }: any) =>
  !isDisabled ? <div {...innerProps}>{children}</div> : null;

export const Select = forwardRef<SelectInstance, SelectProps>(
  (
    {
      name,
      field,
      options: initOptions,
      optionsAsync,
      defaultValue,
      // initValue,
      onChange,
      ...reactSelectProps
    }: SelectProps,
    ref: ForwardedRef<SelectInstance>,
  ) => {
    const { options, setOptions, currentSelection, setCurrentSelection, loadOptions, handleChange } = useSelectConfig({
      initOptions,
      optionsAsync,
      defaultValue,
      onChange,
    });

    const { getValues } = useFormContext();
    const fieldName = field?.name || name;
    const currentValue = reactSelectProps.value || getValues(fieldName);
    const isSelectAsync = !!(optionsAsync?.meta_key || optionsAsync?.endpoint);

    useEffect(() => {
      if (initOptions) {
        const isCurrentOptionValid = !!initOptions.find((province) => province.value === currentSelection?.value);
        if (!isCurrentOptionValid) {
          setCurrentSelection(null);
        }

        // TODO: RE-ENABLE BELOW DATA-SETTER
        setOptions(initOptions);
      }
    }, [initOptions]);

    const cssClasses = new ArrayJSX('input-select');
    cssClasses.push(`select-${fieldName}`);
    field?.isDisabled && cssClasses.push('is-disabled');
    field?.readOnly && cssClasses.push('is-readonly');

    const selectProps = {
      ...SELECT_DEFAULT_PROPS,
      id: fieldName,
      ariaLabel: field?.label,
      className: cssClasses.inline(),
      classNamePrefix: 'select',
      isClearable: reactSelectProps.isClearable,
      isDisabled: field?.isDisabled || reactSelectProps?.isDisabled,
      isSearchable: reactSelectProps.isSearchable,
      // TODO: WHICH OF THESE TWO ... ??
      ...(field?.placeholder && { placeholder: field?.placeholder }),
      placeholder: reactSelectProps.placeholder,
    };

    return (
      <div css={styles}>
        {isSelectAsync ? (
          <ReactSelectAsync
            name={fieldName}
            ref={ref as ForwardedRef<SelectInstance>}
            onChange={handleChange}
            // value={currentSelection}
            // value={options.find((option: SelectOption) => option.value === currentValue) || null}
            value={options.find((option: SelectOption) => option.value === currentValue) || currentSelection}
            // ASYNC OPTIONS ========================
            defaultOptions
            cacheOptions
            loadOptions={loadOptions}
            // --------------------------------------
            components={{ Option: CustomOption }}
            {...selectProps}
          />
        ) : (
          <ReactSelect
            name={fieldName}
            ref={ref as ForwardedRef<SelectInstance>}
            onChange={handleChange}
            // ======================================================================== //
            // TODO: 1: CHANGE: OK, MOCK: OK, RE-LOAD WITH VALUE: NO !!
            // value={options.find((option: SelectOption) => option.value === currentValue) || null}
            // TODO: 2A: CHANGE: NO, MOCK: OK, RE-LOAD WITH VALUE: OK
            // value={
            //   field?.value ||
            //   options.find((option: SelectOption) => option.value === field?.value) ||
            //   currentSelection
            // }
            // TODO: 2B: CHANGE: NO, MOCK: OK, RE-LOAD WITH VALUE: OK
            // value={options.find((option: SelectOption) => option.value === field?.value) || currentSelection}
            // TODO: 3: CHANGE: NO, MOCK: OK, RE-LOAD WITH VALUE: OK
            // value={
            //   options.find(
            //     (option: SelectOption) => option.value === currentValue || option.value === field?.value,
            //   ) || currentSelection
            // }
            value={options.find((option: SelectOption) => option.value === currentValue)}
            // ======================================================================== //
            // STATIC OPTIONS =======================
            // defaultValue={field?.value || field?.defaultValue || null}
            // defaultValue={field?.value || field?.defaultValue || null}
            // defaultValue={currentValue || field?.defaultValue || field?.value || null}
            options={options}
            // --------------------------------------
            components={{ Option: CustomOption }}
            {...selectProps}
          />
        )}
      </div>
    );
  },
);
