import type { RouteConfig } from 'routes/routes.types';

interface DataObject {
  uuid?: string;
  [key: string]: any;
}

export const filterByNavGroup = (data: DataObject[], routeGroup: string): DataObject[] => {
  const result: DataObject[] = [];

  const traverse = (items: DataObject[]) => {
    items.forEach((item) => {
      if (item.routeGroup === routeGroup) {
        result.push(item);
      }
      Object.values(item).forEach((value) => {
        if (Array.isArray(value)) {
          traverse(value);
        }
      });
    });
  };

  traverse(data);

  return result;
};

export const prependBasePathToRouteConfigs = <T extends RouteConfig>(
  routes: T[],
  { basePath }: { basePath: string },
): T[] => {
  const traverseRoutes = <T extends RouteConfig>(routes: T[]): T[] => {
    return routes.map((route) => {
      const newRoute = { ...route };
      if (newRoute.pathname) {
        newRoute.pathname = `/${basePath}${newRoute.pathname}`;
      }
      if (newRoute.parent) {
        newRoute.parent = {
          ...newRoute.parent,
          pathname: `/${basePath}${newRoute.parent.pathname.replace(new RegExp(`^/${basePath}`), '')}`,
        };
      }
      if (newRoute.children) {
        newRoute.children = traverseRoutes(newRoute.children);
      }
      return newRoute;
    });
  };
  return traverseRoutes(routes);
};
