import { css } from '@emotion/react';

export const cssFontDefaults__V2 = css`
  html,
  body,
  * {
    font-family:
      /* Safari for macOS and iOS (San Francisco) */
      -apple-system,
      /* Windows */ 'Segoe UI',
      /* Android */ Roboto,
      /* Basic web fallback */ Helvetica,
      'Helvetica Neue',
      Arial,
      /* Linux */ 'Noto Sans',
      'Liberation Sans',
      Oxygen-Sans,
      Ubuntu,
      Cantarell,
      /* Cross-platform generic font family (default user interface font) */ system-ui,
      /* Sans serif fallback */ sans-serif,
      /* Emoji fonts */ 'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

export const cssFontDefaults = css`
  font-family:
      /* Safari for macOS and iOS (San Francisco) */
    -apple-system,
    /* Windows */ 'Segoe UI',
    /* Android */ /* Roboto, */ /* Android */ 'Noto Sans',
    /* Basic web fallback */ 'Helvetica Neue',
    Arial,
    /* Linux */ 'Noto Sans',
    'Liberation Sans',
    /* Cross-platform generic font family (default user interface font) */ system-ui,
    /* Sans serif fallback */ sans-serif,
    /* Emoji fonts */ 'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const cssFontMono = css`
  /* font-family: source-code-pro, 'Source Code Prop', Menlo, Monaco, Consolas, 'Courier New', monospace; */
  font-family: 'Roboto Mono', 'Source Code Prop', Menlo, Monaco, Consolas, 'Courier New', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;
