import { FieldConfig } from 'types/form.types';
import { toUpperCaseFirst } from 'utils';
import { omitNull } from 'utils/utils.object';
import { setInputValidation } from './form.utils.validation'; // TODO: BRING TO THIS FILE !!
import { globalFieldProps } from '../config';

// 5. SET GLOBAL OVERRIDES etc..
export const setFieldGlobalDefaults = (fields: FieldConfig[]) => {
  // COMPLETE POPULATING FIELD OBJECTS, MERGING DEFAULTS + CONFIG
  const fieldsFullyConfigured = fields.map((field: FieldConfig) => {
    const globalDefaultProps = (globalFieldProps as { [key: string]: any })[field.name as string];

    const isNarrowWidth = !!(field?.colWidth && Object.values(field?.colWidth).find((cols: number) => cols < 3));

    const fieldMerged = {
      // ...field,
      ...globalDefaultProps,
      ...field,
    };

    // STYLE LABEL
    if (fieldMerged.label) {
      fieldMerged.label = toUpperCaseFirst(fieldMerged.label);
    }

    if (!fieldMerged.placeholder) {
      fieldMerged.placeholder = isNarrowWidth ? '' : fieldMerged.label;
    }

    const fieldWithValidation = setInputValidation(fieldMerged);
    const fieldWithFinalConfig = {
      ...omitNull(fieldWithValidation),
    };

    return fieldWithFinalConfig;
  });

  return fieldsFullyConfigured;
};
