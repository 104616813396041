import type { RouteObject } from 'react-router-dom';
import type { RouteConfig } from 'routes/routes.types';

import find from 'lodash/find';
import isObject from 'lodash/isObject';

interface DataObject {
  uuid?: string;
  [key: string]: any;
}

export const findObjectByUUID = (data: DataObject[], uuid: string): DataObject | null => {
  const isDataObject = (item: any): item is DataObject => {
    return isObject(item) && 'uuid' in item;
  };

  const result = find(data, function iteratee(item: any) {
    if (isDataObject(item)) {
      if (item.uuid === uuid) {
        return true;
      }
      return find(item, iteratee);
    }
    return false;
  });

  return isDataObject(result) ? result : null;
};

// ======================================================================== //
// ======================================================================== //

export const findRouteByProp = <T extends RouteObject | RouteConfig>(
  routes: T[],
  criteria: { [K in keyof T]?: T[K] },
): T | undefined => {
  const prop = Object.keys(criteria)[0] as keyof T;
  const value = criteria[prop];

  // Recursive function to find the route by the specified property
  const findRoute = (routes: T[]): T | undefined => {
    for (const route of routes) {
      if (route[prop] === value) {
        return route;
      }
      if (route.children) {
        const found = findRoute(route.children as T[]);
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  };

  return findRoute(routes);
};

// ======================================================================== //

export const removeRouteByProp = <T extends RouteObject | RouteConfig>(
  routes: T[],
  criteria: { [K in keyof T]?: T[K] },
): T | undefined => {
  const prop = Object.keys(criteria)[0] as keyof T;
  const value = criteria[prop];

  const index = routes.findIndex((route) => route[prop] === value);
  if (index !== -1) {
    const [removedRoute] = routes.splice(index, 1);
    return removedRoute;
  }
  return undefined;
};

// ======================================================================== //
// ======================================================================== //

// TODO: NEEDED ??
export const findTopRoutes = (route: RouteObject): RouteObject[] => {
  if (!route.children) {
    return []; // If no children, return an empty array
  }
  // Map each child to include 'pathname' correctly prefixed and exclude 'element'
  return route.children
    .filter(({ path }) => path !== '*')
    .map(({ children, element, ...topRoute }) => ({
      ...topRoute,
      pathname: `${route.path}/${topRoute.path}`.replace(/\/undefined$/, ''), // Correcting the pathname
    }))
    .map((route) => ({
      ...route,
      pathname: route.pathname.startsWith('/') ? route.pathname : `/${route.pathname}`, // Ensure pathname starts with '/'
    }));
};
