/* eslint-disable @typescript-eslint/no-shadow */
import { Col, Row } from 'react-grid-system';
import { useState, useEffect } from 'react';
import { DataEntry } from 'types';
import { useQueryGet } from 'api';
import { DetailListRow } from './DetailListRow';
import type { FieldConfig } from '../DetailSummary/DetailSummary.types';
import { styles } from './DetailList.css';

export const DetailList = ({
  endpoint,
  ids,
  field,
}: {
  endpoint: string;
  ids: number[];
  field: FieldConfig;
}) => {
  const [data, setData] = useState<DataEntry[]>([]);
  const [listItems, setListItems] = useState<DataEntry[]>([]);

  useQueryGet({
    endpoint,
    enabled: true,
    onSuccess: ({ data }) => setData(data),
  });

  const updateListItems = (selectedIDs: string) => {
    const ids: number[] = selectedIDs.split(',').map((id) => Number(id));
    const selected = data.filter((item) => ids.includes(Number(item?.id)));
    setListItems(selected);
  };

  useEffect(() => {
    updateListItems(field.value);
  }, [data]);

  return (
    <Row align="center" css={styles}>
      {listItems.map((item: DataEntry) => (
        <Col xs={12} lg={6} className="col col-list" key={String(item.id)}>
          <DetailListRow key={String(item.id)} data={item} />
        </Col>
      ))}
    </Row>
  );
};
