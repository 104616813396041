import { INPUTS } from 'types/form.types';
import type { ColumnSizes } from 'types/viewport.types';
import type { SelectOption } from 'components/FormUI/Select';

export const defaultColumnSizes: ColumnSizes = {
  xs: 12,
  sm: 12,
  md: 6,
  lg: 6,
  xl: 6,
  xxl: 6,
  // xxxl: 6,
};

// ===== CONFIG + ATTR SCHEMAS ============================================== //

// ADDITIONAL DEFAULTS
export const defaultFieldProps = {
  // BASIC
  name: undefined,
  label: undefined,
  value: undefined,
  default: null,
  inputType: undefined,
  // UI
  isHidden: false,
  colWidth: {
    ...defaultColumnSizes,
  },
};

// ===== GLOBAL CORE CONFIGS ============================================== //

export const globalFieldProps = {
  id: {
    isHidden: true,
  },
  uuid: {
    inputType: INPUTS.uuid,
    isHidden: true,
  },
  name: {
    inputType: INPUTS.text,
    isHidden: false,
    required: true,
    order: 0,
  },
  name_last: {
    label: 'apellidos',
    inputType: INPUTS.text,
    required: false,
    order: 1,
  },
  image: {
    required: true,
  },
  email: {
    name: 'email',
    label: 'email',
    hint: 'correo electrónico',
    inputType: INPUTS.email,
    order: 2,
  },
  tel: {
    label: 'teléfono de contacto',
    inputType: INPUTS.text,
  },
  url: {
    inputType: 'url',
    placeholder: 'https://',
  },
  socials: {
    inputType: 'socials',
    label: 'Redes Sociales',
    hint: 'cambiar la red social especificada clicando el icono',
    colWidth: {
      xs: 12,
    },
  },

  image_id: {
    required: false,
    isHidden: true,
  },
  cat_id: {
    label: 'Categoría',
    isHidden: false,
    readOnly: false,
  },
  company_name: {
    inputType: INPUTS.text,
  },

  // ============================================================ //

  address: {
    label: 'dirección',
    inputType: INPUTS.text,
  },
  address_0: {
    label: 'dirección 1',
    hint: 'calle + edificio',
    inputType: INPUTS.text,
  },
  address_1: {
    label: 'dirección 2',
    hint: 'puerta',
    inputType: INPUTS.text,
  },
  postal_code: {
    label: 'código postal',
    inputType: INPUTS.text,
  },

  // city: {
  //   label: 'población',
  //   inputType: INPUTS.text,
  // },
  // country: {
  //   label: 'país',
  //   inputType: INPUTS.selectCountry,
  // },
  /*
  province: {
    label: 'província',
    hint: 'Provincia de España',
    // inputType: INPUTS.select,
    inputType: INPUTS.select,
    options: SPAIN_PROVINCES,
    order: 11,
  },
*/
  document_type: {
    label: 'tipo de documento',
    inputType: INPUTS.select,
    options: [
      {
        value: 'DNI',
        label: 'DNI',
      },
      {
        value: 'NIE',
        label: 'NIE',
      },
      {
        value: 'Pasaporte',
        label: 'Pasaporte',
      },
    ] as SelectOption[],
  },
  document_number: {
    label: 'número de documento',
    inputType: INPUTS.text,
  },

  // ============================================================ //

  json_meta: {
    label: 'JSON_META',
    isHidden: false,
    inputType: INPUTS.JSON_META,
    colWidth: {
      xs: 12,
    },
  },
  is_active: {
    inputType: 'checkbox',
    readOnly: true,
    isHidden: false,
    value: true,
  },
  user_id: {
    isHidden: true,
    readOnly: true,
  },
  created_at: {
    inputType: 'text',
    isHidden: true,
    readOnly: true,
  },
  updated_at: {
    inputType: 'text',
    isHidden: true,
    readOnly: true,
  },
};

// ===== GLOBAL CORE CONFIGS ============================================== //

// IGNORE WHEN SUBMITTING DATA
export const submitBlacklist = ['HEADER_IMAGE_PREVIEW', 'image__PLACEHOLDER', 'image__FILENAME', 'json_meta'];

// IGNORE WHEN GENERATING SCHEMA BASED ON SCHEMA via API
export const schemaKeysBlacklist = ['image', 'logo'];
