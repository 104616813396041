import { useLayoutEffect } from 'react';
import { Global, css } from '@emotion/react';
import { useLayout } from 'pages/Layout/context/LayoutContext';
import { layout } from 'styles/layout.css';
import { min } from 'styles/media-queries-V1';
import { useBodyScrollable } from './hooks/useBodyScrollable';
import { useScrollLock } from './hooks/useScrollLock';

export const ScrollControl = () => {
  const { isScrollLocked } = useLayout();
  const { isBodyScrollable } = useBodyScrollable();
  const { setIsScrollLocked, scrollbarWidth } = useScrollLock();

  useLayoutEffect(() => {
    isScrollLocked ? setIsScrollLocked(true) : setIsScrollLocked(false);
  }, [isScrollLocked]);

  useLayoutEffect(() => {
    const html = document.querySelector('html') as HTMLElement;
    if (isBodyScrollable && !html.classList.contains('has-scrollbar')) {
      html.classList.add('has-scrollbar');
    } else if (!isBodyScrollable && html.classList.contains('has-scrollbar')) {
      html.classList.remove('has-scrollbar');
    }
  }, [isBodyScrollable]);

  const styles = isScrollLocked
    ? css`
        html.has-scrollbar {
          scrollbar-gutter: auto;
          body {
            width: 100vw;
            div#root {
              & > header {
                position: fixed !important;
                /* margin-right: calc(100vw - 100%) !important; */
                /* margin-right: ${scrollbarWidth}px !important; */
              }
              & > header > div {
                /* margin-right: calc(100vw - 100%) !important; */
                /* margin-right: ${scrollbarWidth}px !important; */
              }
              & > header + nav {
                padding-right: calc(100vw - 100%);
              }
              & > main {
                ${min.sm} {
                  margin-top: calc(${layout.header.height} + 8.5px) !important;
                }
                ${min.lg} {
                  margin-top: unset !important;
                }
              }
              & > footer > div {
                /* margin-right: calc(100vw - 100%) !important; */
              }
            }
            aside#sidebar {
            }
          }
        }
      `
    : css``;

  return <Global styles={{ ...styles }} />;
};
