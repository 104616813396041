import cloneDeep from 'lodash/cloneDeep';
import { type PathMatch } from 'react-router-dom';
import { getParamsMatched } from '../../user/getRoutePathParams';
import type { LocationWithState } from 'types';
import type { RouteConfig } from 'routes';
import { config } from 'config';
import type { ParamsCustom } from './useRouterLoader.types';
import { ACTIONS, Action } from 'types';
import { isUserPath } from 'routes';

const isAdminPath = !!location.pathname.startsWith('/admin');
const PATH_BASE = isAdminPath ? config.PATH_BASE_ADMIN : config.PATH_BASE_WEB;

// NEW: BETTER METHOD TO SET ROUTE_META ====================================== //
// CLEANED / MINIMAL RouteConfig (without Access rules, etc..)

export const getRouteConfigCleaned = (
  matchSlug: string | undefined,
  metaRoutes: RouteConfig[],
  removeKeys: (keyof RouteConfig)[] = ['access', 'isDeletable'],
): RouteConfig => {
  const matchedRoutes = metaRoutes.filter((route: RouteConfig) =>
    location.pathname === '/' ? route.path === '/' : route.slug === matchSlug,
  );

  const matchedRouteMeta = cloneDeep(
    isUserPath()
      ? matchedRoutes.find((route: RouteConfig) => route.pathname.startsWith('/account'))
      : matchedRoutes.find((route: RouteConfig) => !route.pathname.startsWith('/account')),
  ) as RouteConfig;

  for (const key of removeKeys) {
    delete matchedRouteMeta?.[key];
  }

  return matchedRouteMeta as RouteConfig;
};

// FROM-LOCATION + DEFAULT ROUTE SETTING ================================== //

export const getFromLocation = ({
  location,
  metaRoutes,
  paramsCustom,
}: {
  location: LocationWithState;
  metaRoutes: RouteConfig[];
  paramsCustom: ParamsCustom;
}): RouteConfig => {
  if (location?.state?.from) {
    if (typeof location.state.from === 'object') {
      const fromLocation = Object.assign({}, location.state.from);
      delete fromLocation.access;
      return fromLocation;
    }
    if (typeof location.state.from === 'string') {
      const match = metaRoutes?.find((route: RouteConfig) => route.slug === (location.state as any).from);
      const fromLocation = Object.assign({}, match);
      delete fromLocation.access;
      return fromLocation;
    }
  }

  const match = metaRoutes?.find(
    (route: RouteConfig) =>
      route?.path === paramsCustom.slug || route?.path?.endsWith(`/${paramsCustom.slug}`),
  );
  if (match) {
    const fromLocation = Object.assign({}, match);
    delete fromLocation.access;
    return fromLocation;
  }
};

// GET ROUTE ACTION ================================== //

export const getRouteAction = ({
  location,
  paramsCustom,
}: {
  location: LocationWithState;
  paramsCustom: ParamsCustom;
}): Action | undefined => {
  paramsCustom?.action && delete paramsCustom.action;
  const pathname = location.pathname as string;

  if (location?.state?.action) {
    paramsCustom?.id && !paramsCustom?.action && delete paramsCustom?.id;
    return location.state.action as Action;
  }

  switch (true) {
    case pathname.endsWith('/login'):
      return ACTIONS.ACCESS;

    case pathname.endsWith('/new'):
      return ACTIONS.NEW;

    case pathname.includes('/edit'):
    case pathname.includes('/registration'):
      return ACTIONS.EDIT;

    case pathname.includes('/view'):
      return ACTIONS.VIEW;

    default:
      return;
  }
};

// GET ROUTE ACTION ================================== //

export const getUserRouteParams = ({ pathname }: { pathname: string }) => {
  type UserPathMatch = PathMatch<'cat'>;

  const matchPathBase = pathname.includes('/cat/') ? 'registros/cat' : 'registros';
  const match = getParamsMatched({ pathname, paramsCustom: {}, matchPathBase, param: 'cat' });

  const params = { slug: undefined };
  Object.assign(params, { ...match, slug: match?.cat || 'registros' });

  return params;
};
