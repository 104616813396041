import { css } from '@emotion/react';
import { colors } from 'styles/colors.css';

export const styles = css`
  /* padding-top: 5.5em; */

  .alert {
    min-height: unset;
    flex-direction: unset;
    justify-content: flex-start;
    padding: 0;

    .switch {
      margin-right: 1em;
    }

    &.alert-default {
      p {
        color: ${colors.grey};
      }
      strong {
        color: ${colors.greyDark};
      }
    }

    &.alert-info {
      p {
        color: ${colors.primary};
      }
      strong {
        color: ${colors.primaryXDark};
      }
    }
  }
`;
