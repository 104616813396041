import { INPUTS } from 'types/form.types';
import type { FieldConfig } from 'components/Form';

export const FIELDS_CONTACT_PUBLIC: FieldConfig[] = [
  {
    name: 'email_public',
    label: 'email público',
    hint: 'puede añadir varios',
    inputType: INPUTS.repeater,
    required: false,
    repeaterConfig: {
      inputType: INPUTS.email,
    },
  },
  {
    name: 'tel_public',
    label: 'teléfono público',
    hint: 'puede añadir varios',
    inputType: INPUTS.repeater,
    required: false,
    repeaterConfig: {
      inputType: INPUTS.tel,
    },
  },
];

export const FIELDS_CONTACT_PRIVATE: FieldConfig[] = [
  {
    name: 'email_private',
    label: 't.forms.fields.email_private',
    hint: 'puede añadir varios',
    inputType: INPUTS.repeater,
    required: false,
    repeaterConfig: {
      inputType: INPUTS.email,
    },
  },
  {
    name: 'tel_private',
    label: 't.forms.fields.tel_private',
    hint: 'puede añadir varios',
    inputType: INPUTS.repeater,
    required: false,
    repeaterConfig: {
      inputType: INPUTS.tel,
    },
  },
];

export const FIELDS_MEDIOS_SECUNDARIOS: FieldConfig[] = [
  {
    name: 'url_multi',
    inputType: INPUTS.repeater,
    repeaterConfig: {
      inputType: INPUTS.url,
    },
    colWidth: { xs: 12 },
  },
];
