import { useNavigate, useLocation } from 'react-router-dom';
import type { NavigateOptions, To, Location } from 'react-router-dom';
import type { LocationWithState, LocationState } from 'types';
import { cleanRoutePath } from 'routes/utils/routes.utils';
import { useGlobal } from 'store/useGlobalContext';
import { getFromLocation } from 'routes/hooks/useRouter/useRouter.utils';
import { getPathBase } from 'routes';

type NavigateWithState = (to: To | number, options?: NavigateOptions) => Promise<void>;
interface FromLocationProps extends Omit<Location, 'pathname' | 'search'>, LocationState {}

interface UseNavigateState {
  navigate: NavigateWithState;
  key: FromLocationProps['key'];
  hash: FromLocationProps['hash'];
  state: FromLocationProps['state'];
  from: FromLocationProps['from'];
  action: FromLocationProps['action'];
  navigateExternal: ({ url }: { url: string }) => void;
}

export const useNavigateState = (): UseNavigateState => {
  const { PATH_BASE } = getPathBase();
  const { metaRoutes } = useGlobal('metaRoutes');
  const navigateReactRouter = useNavigate();
  const location = useLocation();
  const fromLocation = getFromLocation({
    location: location as unknown as LocationWithState,
    metaRoutes,
    paramsCustom: {},
  });

  const navigate: NavigateWithState = async (to: To | number, options: NavigateOptions = {}) => {
    if (typeof to === 'number') return navigateReactRouter(to); // Directly return as no additional options are needed

    const href = cleanRoutePath(PATH_BASE + to); // Assume to is a string here
    const optionsWithState = {
      ...options,
      state: {
        ...options?.state,
        from: fromLocation,
      },
    };

    await navigateReactRouter(href, optionsWithState);
  };

  const navigateExternal = ({ url }: { url: string }) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer') as Window;
    if (newWindow) newWindow.opener = null;
  };

  const locationState = (location as unknown as LocationWithState)?.state;

  return {
    navigate,
    key: location.key,
    hash: location.hash,
    state: location.state,
    from: locationState?.from,
    action: locationState?.action,
    navigateExternal,
  };
};
