import { API_BASE_URL } from 'api/api.config';
import { ASC } from 'constants/sorting';
import { type AppConfiguration, type DotEnvVars, Language } from './config.types';
import { getPathBase } from 'routes';

export const API_BASE_IMAGES = `${API_BASE_URL}/uploads/images`;

const { IS_ENV_PRODUCTION, VITE_IS_SITE_LOCKED, ...APP_ENV_VARS } = import.meta.env;

log('VARS', 'red', APP_ENV_VARS);

// NOTE: check out @dotenvx 👍🏻
// https://github.com/dotenvx/dotenvx

const config: AppConfiguration & Partial<DotEnvVars> = {
  appName: 'APNAES',
  bizName: 'APNAES Org España',
  // NOTE: root / base paths
  PATH_BASE_WEB: '',
  PATH_BASE_USER: '/account',
  PATH_BASE_ADMIN: '/admin',
  PATH_BASE: location.pathname.startsWith('/admin')
    ? '/admin'
    : location.pathname.startsWith('/account')
      ? '/account'
      : '',
  // NOTE: home path slugs (directed to after login, etc..)
  PATH_HOME_WEB: '', // home
  PATH_HOME_USER: 'account', // home
  PATH_HOME_ADMIN: 'categories',
  // NOTE: API
  API_BASE_URL,
  API_BASE_IMAGES,
  // log-out config
  LOGGED_OUT_PATH: '/',
  // tables
  TABLE_MAX_ROWS: 20,
  SORT_PARAMS_DEFAULT: { column: 'id', dir: ASC },
  tableRowHeight: '60px',
  tableRowPadding: 10,
  // locales
  defaultLanguage: Language.en,
  languages: {
    en: Language.en,
    es: Language.es,
    fr: Language.fr,
  },
  IS_SITE_LOCKED: !!(
    (process.env.NODE_ENV === 'production' || IS_ENV_PRODUCTION || VITE_IS_SITE_LOCKED)
    // (VITE_IS_SITE_LOCKED && APP_ENV_VARS.MODE !== 'development')
  ),
  IS_ENV_PRODUCTION: !!(process.env.NODE_ENV === 'production' || IS_ENV_PRODUCTION),
  ...APP_ENV_VARS,
  // ...defaultJSON,
};

export default { ...config } as AppConfiguration & DotEnvVars;
