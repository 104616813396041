/* eslint-disable @typescript-eslint/no-shadow */
import type { FieldValues, UseFormGetValues } from 'react-hook-form';
import { faker, fakerES, fakerEN } from '@faker-js/faker';
import { v4 as uuid } from 'uuid';
import { INPUTS } from 'types/form.types';
import type { FieldConfig } from 'components/Form';
import { getInputTypeDeep } from 'components/Form/utils/form.utils.config';
import type { SelectOption } from 'components/FormUI/Select';
import { slugify } from 'utils';
import { genIntString, getRandomInt } from 'utils/utils.number';
import { genMockTelNumber, genMockEmail } from './data.contact';
import { genMockURL, getSocialEntry } from './data.internet';
import { genRandomWord, genUUID } from './data.misc';
import { genAddressValue } from './data.address';
import { genDocumentNumber } from './data.person';
import { getUserID } from './data.entry.byId';
import { OPTIONS_COUNTRIES_FLAGS as countries } from 'constants/countries';
import { getFakerImageURLv3 } from './data.images';
import { PROVINCIAS } from './constants/fakerValues_ES';

// ======================================================================== //

// NOTE: on faker(s)...
// LATEST VERSION (BAD):  "faker": "^6.6.6" <== DON'T USE !!
// LAST GOOD WORKING: "faker": "^5.5.3"
// NEW COMMUNITY VERSION: "@faker-js/faker": "^7.6.0"
// https://fakerjs.dev/api/commerce.html

// ======================================================================== //

export const genMockValueByType = (mockType: string) => {
  const fieldType = mockType.replace('MOCK_', '');
  switch (fieldType) {
    case 'NAME':
      // return genRandomWord();
      return fakerEN.person.firstName();
    case 'EMAIL':
      return genMockEmail();
    case 'UUID':
      return genUUID();
    default:
      return mockType;
  }
};

// ======================================================================== //

const genSelectValue = ({ inputType, ...field }: FieldConfig) => {
  const options = field?.options;
  if (options) {
    const i = getRandomInt(0, options.length - 1);
    if ((options[i] as SelectOption)?.value) {
      return (options[i] as SelectOption).value;
    }
  }
  return;
};

// ======================================================================== //

export const genRepeaterValues = ({ itemType, currentValues }: any) => {
  const newValues = [] as unknown[];
  const numItems = Number(currentValues?.length || 0) + getRandomInt(0, 2);

  for (let i = 0; i < numItems; i++) {
    const item = { uuid: uuid(), name: '', ...(currentValues?.[i] ?? {}) };
    if (!item.name && itemType === INPUTS.tel) item.name = genMockTelNumber();
    if (!item.name && itemType === INPUTS.email) item.name = genMockEmail();
    newValues.push(item);
  }

  return newValues;
};

// ======================================================================== //

export const genMockValueV2 = ({
  field: fieldConfig,
  getValues,
}: {
  field: FieldConfig;
  getValues?: UseFormGetValues<FieldValues>;
}) => {
  const { name, inputType, required, value, ...field } = fieldConfig;
  const label = `${slugify(field?.label)}`;

  // NOTE: PERSON..
  if (inputType === INPUTS.text) {
    if (name === 'name' || name === 'nombre') return fakerES.person.firstName();
    if (name === 'name_first') return fakerES.person.firstName();
    if (name === 'name_last' || name === 'apellido') return fakerES.person.lastName();
    if (name === 'apellidos') return `${fakerES.person.lastName()} ${fakerES.person.lastName()}`;
    if (name === 'owner_name') return fakerES.person.fullName();
    if (name === 'position') return fakerES.person.jobType();
  }

  // NOTE: contact
  if (inputType === INPUTS.text) {
    if (name === 'tel') return genMockTelNumber();
    if (name === 'email') return genMockEmail();
    if (name === 'url' || name === 'website') return genMockURL();
  } else if (inputType === INPUTS.tel) {
    return genMockTelNumber();
  } else if (inputType === INPUTS.email) {
    return genMockEmail();
  } else if (inputType === INPUTS.url) {
    return genMockURL();
  }

  // NOTE: business (other)
  if (inputType === INPUTS.text) {
    if (name === 'SECTOR') return fakerES.person.jobArea();
    if (name.startsWith('document_number')) return genDocumentNumber();
    if (name === 'document_number_personal') return genDocumentNumber();
    if (name === 'document_number_biz') return genDocumentNumber();
  }

  // NOTE: ADDRESS..
  if (name.startsWith('biz_')) {
    if (name.endsWith('address_street_type')) return genAddressValue('address_street_type');
    if (name.endsWith('address_street_name')) return genAddressValue('address_street_name');
    if (name.endsWith('address_street_number')) return genAddressValue('address_street_number');
    if (name.endsWith('address_block')) return genAddressValue('address_block');
    if (name.endsWith('address_escalera')) return genAddressValue('address_escalera');
    if (name.endsWith('address_piso')) return genAddressValue('address_piso');
    if (name.endsWith('address_door')) return genAddressValue('address_door');
    if (name.endsWith('address_info')) return genAddressValue('address_info');
    if (name.endsWith('city')) return fakerES.location.city();
    if (name.endsWith('state')) return fakerES.location.state(); // Comunidad Autonimo
    if (name.endsWith('province')) return faker.helpers.arrayElement(PROVINCIAS); // Province
    if (name.endsWith('postal_code')) return fakerES.location.zipCode();
    if (name.endsWith('country')) return countries[getRandomInt(0, countries.length - 1)]?.value;
    if (name.startsWith('biz_address_')) return genAddressValue(label);
  }

  // NOTE: ADDRESS..
  if (name.startsWith('address_') && inputType === INPUTS.text) {
    if (name.endsWith('address_street_type')) return genAddressValue('address_street_type');
    if (name.endsWith('address_street_name')) return genAddressValue('address_street_name');
    if (name.endsWith('address_street_number')) return genAddressValue('address_street_number');
    if (name.endsWith('address_block')) return genAddressValue('address_block');
    if (name.endsWith('address_escalera')) return genAddressValue('address_escalera');
    if (name.endsWith('address_piso')) return genAddressValue('address_piso');
    if (name.endsWith('address_door')) return genAddressValue('address_door');
    if (name.endsWith('address_info')) return genAddressValue('address_info');
    if (name.endsWith('city')) return fakerES.location.city();
    if (name.endsWith('state')) return fakerES.location.state(); // Comunidad Autonimo
    if (name.endsWith('province')) return faker.helpers.arrayElement(PROVINCIAS); // Province
    if (name.endsWith('postal_code')) return fakerES.location.zipCode();
    if (name.endsWith('country')) return countries[getRandomInt(0, countries.length - 1)]?.value;
    if (name.startsWith('address_')) return genAddressValue(label);
  }

  // NOTE: ADDRESS..
  if (inputType === INPUTS.text) {
    if (name === 'city') return fakerES.location.city();
    if (name === 'state') return fakerES.location.state(); // Comunidad Autonimo
    if (name === 'province') return faker.helpers.arrayElement(PROVINCIAS); // Province
    if (name === 'postal_code') return fakerES.location.zipCode();
  }

  if (inputType === INPUTS.textarea || inputType === INPUTS.text_rich) {
    // NOTE: https://fakerjs.dev/api/lorem.html#sentences
    return faker.lorem.sentences({ min: 2, max: 3 }, '\n'); // 'Placeat ex natus tenetur repellendus repellendus iste. Optio nostrum veritatis.'
  }

  // NOTE: LOCATION (greater area)..
  if (inputType === INPUTS.selectCountry) {
    const mockValue = countries[getRandomInt(0, countries.length - 1)]?.value;
    return mockValue;
  }

  // NOTE: LOCATION (greater area)..
  if (inputType === INPUTS.select) {
    return genSelectValue(fieldConfig);
  }

  // NOTE: REPEATER ..

  // TODO: NEED TO CREATE SPECFICI GENERATOR FOR THIS FIELD_TYPE !!!!
  if (inputType === INPUTS.repeaterURL && name !== 'socials') {
    const valuesArray = [];
    const numItems = getRandomInt(1, 3);

    for (let i = 0; i < numItems; i++) {
      const social = getSocialEntry();
      valuesArray.push({ ...social });
    }

    return valuesArray;
  }

  // NOTE: REPEATER ..
  if (inputType === INPUTS.repeater) {
    const itemType = getInputTypeDeep(fieldConfig);
    const currentValues = getValues ? getValues(name) : fieldConfig;
    const newValues = genRepeaterValues({ itemType, currentValues });

    return newValues;
  }

  // NOTE: IMAGE / IMAGE URL..
  if (name === 'image' || inputType === INPUTS.image) {
    return getFakerImageURLv3();
  }

  // NOTE: UUID ..
  if (name === 'user_id' || name === 'owner') {
    return getUserID();
  }

  // NOTE: UUID ..
  if (name === 'uuid' || inputType === INPUTS.uuid || (inputType === INPUTS.text && name.endsWith('_id'))) {
    return uuid();
  }

  if (inputType === INPUTS.checkbox || inputType === INPUTS.switch) {
    if (name === 'is_active') return faker.helpers.arrayElement([1, 1, 1, 1, 1, 1, 1, 1, 0, 0]);
    if (name.startsWith('is_')) return getRandomInt(0, 1);
  }

  return '';
};
