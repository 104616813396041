import { css } from '@emotion/react';
import { layout } from 'styles/layout.css';
import { colors } from 'styles/colors.css';
import { min, max } from 'styles/media-queries-V1';

export const styles = css`
  margin-bottom: calc(${layout.padding} * 1);

  h1,
  h2 {
    margin-top: 0 !important;
    line-height: 1.25 !important;
  }

  h1 {
    margin-bottom: calc(${layout.padding} * 0) !important;
    & + h2 {
      margin-top: calc(${layout.padding} * 0) !important;
    }
  }

  ${max.md} {
    h2 {
      text-align: center;
      padding-bottom: ${layout.padding};
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 1em 5px 1.5em;
    position: relative;
    left: 0;
    li {
      font-size: 1.1em;
      margin: 0;
      padding: 0.25em 0;
      color: ${colors.text};
      display: flex;
      strong {
        display: inline-flex;
        width: 100%;
        ${min.xs} {
          max-width: 40vw;
        }
        ${min.md} {
          max-width: 30vw;
        }
        ${min.lg} {
          max-width: 25vw;
        }
        ${min.xl} {
          max-width: 20vw;
        }
        ${min.xxl} {
          max-width: 15vw;
        }
      }
      span {
        display: inline-flex;
      }
      a {
        font-weight: 700;
      }
    }
    hr {
      margin: 1em 0;
    }
  }

  hr {
    border-color: ${colors.greyLight};
    margin-top: calc(${layout.padding} * 2);
  }

  .col-details {
    /* padding-top: calc(${layout.padding} * 1); */
    padding: 0 calc(${layout.padding} * 0.7) !important;

    h2 {
      color: ${colors.greyDark};
      font-size: 2.25em;
      margin-top: 0.33em;
      margin-bottom: 0em;
      margin-left: 0.05em;
      min-height: 53px;
    }

    .category-list {
      font-size: 1.8em;
      a:first-of-type {
        padding-left: 0;
      }
    }
    hr {
      border-color: ${colors.greyLight};
      &:nth-of-type(2) {
        margin: 2em 0;
      }
    }
  }

  .col-image {
    text-align: center;
    background: ${colors.greyXLight};
    height: auto !important;
    height: 350px;
    min-height: 300px !important;
    transition: background 0.2s ease;

    &.has-preview {
      margin-top: calc(${layout.padding} * 0);
      margin-bottom: calc(${layout.padding} * 0);
      transition: none;
      background-size: cover;
      background-position: 50%;
      background-repeat: no-repeat;
      /* background-repeat: no-repeat; */
    }

    /* creates an inner border for the image */
    box-shadow:
      inset 0 0 0 ${layout.padding} ${colors.white},
      inset 0 0 0 calc(${layout.padding}*2) ${colors.white};
    ${min.md} {
      box-shadow:
        inset 0 0 0 ${layout.padding} ${colors.greyXXLight},
        inset 0 0 0 calc(${layout.padding}*2) ${colors.white};
    }

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
      transform: scale(1.25) translateX(0%);
    }
    &:hover {
      background: ${colors.greyXXLight};
    }
    :-moz-drag-over {
      border: 2px solid red !important;
    }

    .fileupload-image,
    .fileupload-image__PLACEHOLDER {
      width: 100%;
      height: 100%;
      max-height: none !important;
      margin: 0 ${layout.padding};
      padding: ${layout.padding} 0;
      & > div {
        height: 100%;
      }
      input {
        border: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        color: transparent;
        text-indent: -1000%;
        &:hover {
          border: 0;
          background: transparent;
        }
      }
      .icon-select {
        color: #ccc;
        font-size: 1em;
        right: 50%;
        transform: translate(-75%, 50%) scale(0.8);
      }
    }
    &.has-preview {
      background-size: cover !important;
      background-position: 50% !important;
      background-repeat: no-repeat !important;
      input {
        border: 0;
        text-indent: -1000%;
        &:hover {
          border: 0 !important;
          background: transparent !important;
        }
      }
      .icon-select {
        opacity: 0;
      }
    }

    svg {
      color: #ccc;
      font-size: 6em;
      opacity: 0.45;
      top: 50%;
    }
  }

  .btn-header-right {
    font-size: 1.1em;
    font-weight: 700;
    padding-left: 1.5em;
    padding-right: 1.5em;
    margin-top: calc(${layout.padding} * 2);
    transform: translateX(-5px);
  }
`;
