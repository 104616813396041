import type { Metric } from 'web-vitals';
import { onCLS, onFID, onFCP, onLCP, onTTFB } from 'web-vitals';

type MetricHandler = (metric: Metric) => void;

export const reportWebVitals = (onPerfEntry?: MetricHandler) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    onCLS(onPerfEntry);
    onFID(onPerfEntry);
    onFCP(onPerfEntry);
    onLCP(onPerfEntry);
    onTTFB(onPerfEntry);
  }
};
