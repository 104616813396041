import { Row, Col } from 'react-grid-system';
import { useState, forwardRef } from 'react';
import type { ForwardedRef, LegacyRef, ChangeEvent } from 'react';
import { configSocials } from 'config/socials.config';
import { colors } from 'styles/colors.css';
import type { SocialsSelect } from './Socials.types';
import { styles } from './Socials.css';

export const Socials = forwardRef<any, any>(
  ({ name, defaultValue, setValue }: SocialsSelect, forwardedRef: ForwardedRef<any>) => {
    const parsedValues = defaultValue ? JSON.parse(defaultValue) : {};
    const [currentValues, setCurrentValues] = useState(parsedValues);

    const handleKeyUp = (event: ChangeEvent<HTMLInputElement>) => {
      const isValid = event.target.validity.valid;
      if (isValid) {
        const newInputKey = event.target.name as string;
        const newInputValue = event.target.value as string;
        const newValues = { ...currentValues, [newInputKey]: newInputValue };
        if (newInputValue == '') delete newValues[newInputKey];

        setCurrentValues(newValues);

        const newValueJSON = JSON.stringify(newValues) as string;
        setValue(name, newValueJSON, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') event.preventDefault();
    };

    return (
      <section css={styles}>
        <Row className="social-row">
          {configSocials.map(({ baseUrl, validation, color, label, name, ...Social }) => (
            <Col key={name} xs={12} className="social-field">
              <label>{<Social.icon color={color} />}</label>
              <input
                name={name}
                type="url"
                defaultValue={parsedValues[name] || undefined}
                placeholder={baseUrl}
                onKeyDown={handleKeyDown as any}
                onKeyUp={handleKeyUp as any}
                pattern={validation as string}
              />
            </Col>
          ))}
        </Row>
        <input
          name={name}
          value={JSON.stringify(currentValues)}
          type="hidden"
          readOnly
          ref={forwardedRef as LegacyRef<HTMLInputElement>}
        />
      </section>
    );
  },
);
