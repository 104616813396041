import cloneDeep from 'lodash/cloneDeep';
import type { ColumnSizes, ScreenClass, ScreenClassMap } from 'types/viewport.types';
import { defaultColumnSizes } from 'components/Form';
import { getFieldWidth } from 'components/FormField/formField.utils';
import { min } from 'styles';

export const getResponsiveGridItemProps = ({ listConfig, listItemConfig, shapeRatio = 2.2 }: any) => {
  const responsiveness = {};

  // NOTE: CARD_SHAPE_FACTOR is used to calculate padding-top of the grid containers and therefore the CardItem SHAPE.
  // Lower values like 0.5, 1 will increase Card height; while larger values like 3,4,5 etc.. decrease Card height.
  const CARD_SHAPE_DEFAULT = shapeRatio;
  const CARD_SHAPE_FACTOR = listItemConfig?.shapeRatio || CARD_SHAPE_DEFAULT;
  const FONT_SIZE_BASE = 1.2;
  const FONT_SIZE_FACTOR = 0.5;

  Object.entries(getFieldWidth(listConfig)).forEach(([sizeKey, sizeCol], i = 1) => {
    // GRADUAL LOGARITHMIC VALUE REDUCTION
    const z = Math.floor(i * CARD_SHAPE_FACTOR);
    const paddingValue = Math.abs(Math.ceil(100 / Math.log(i * Math.E) - z)) || 100;
    const isMinLG = !['xs', 'sm', 'md'].includes(sizeKey);

    const x = Math.floor(i * FONT_SIZE_FACTOR);
    const a = (FONT_SIZE_BASE / Math.log(i * Math.E)) * x;
    const b = Number((a / 10).toFixed(1));
    const fontSizeValue = FONT_SIZE_BASE + b;

    Object.assign(responsiveness, {
      [min[sizeKey]]: {
        paddingTop: sizeCol === 12 && isMinLG ? `${Math.floor(paddingValue / 2) || 100}%` : `${paddingValue || 100}%`,
        flexBasis:
          sizeCol === 12 ? '100%' : sizeCol === 6 ? '50%' : sizeCol === 4 ? '33%' : sizeCol === 3 ? '25%' : '100%',
      },
    });
  });

  return responsiveness;
};

export const getResponsiveColumnSizes = ({
  defaultProps = defaultColumnSizes,
  customProps: props,
}: {
  defaultProps?: ColumnSizes;
  customProps: ColumnSizes;
}) => {
  const customProps = cloneDeep(props) as ScreenClassMap<number>;
  if (!customProps || !Object.entries(customProps).length) return defaultProps;

  // COLUMN SIZES DEFINED in FORM CONFIG
  const [firstSizeKey, _firstSizeValue] = Object.entries(customProps)[0];
  const [lastSizeKey, lastSizeValue] = Object.entries(customProps)[Object.values(customProps).length - 1];
  const firstSizeIndex = Object.keys(defaultProps).indexOf(firstSizeKey);
  const lastSizeIndex = Object.keys(defaultProps).indexOf(lastSizeKey);

  const widths = {} as ColumnSizes;
  Object.keys(defaultProps).map((screensize: ScreenClass, index: number) => {
    if (customProps[screensize]) {
      widths[screensize] = customProps[screensize];
    } else if (index >= lastSizeIndex) {
      widths[screensize] = lastSizeValue;
    } else if (index > firstSizeIndex && index < lastSizeIndex) {
      widths[screensize] = widths[Object.keys(defaultProps)[index - 1] as ScreenClass];
    } else {
      widths[screensize] = defaultProps[screensize];
    }
  });

  return widths;
};

export const calcWidthByNumChars = ({ element, numChars }: { element: Element; numChars: number }) => {
  if (!element) return numChars;
  const fontSizePX = window?.getComputedStyle(element, null).getPropertyValue('font-size');
  const fontSize = Number(fontSizePX.substring(0, fontSizePX.length - 2));

  const X_FACTOR = 0.47;
  const fullTitleWidth = Math.ceil(numChars * (fontSize * X_FACTOR));

  return fullTitleWidth;
};
