import type { SelectOption } from 'components/FormUI/Select';
import type { SocialConfig } from 'components/Socials';
import { configSocials } from 'config/socials.config';

export const OPTIONS_SOCIALS = configSocials.map((social: SocialConfig) => {
  return {
    value: social.name,
    label: social.label,
  } as SelectOption;
});

export const OPTIONS_SOCIALS_LOGOS = configSocials.map((social: SocialConfig) => {
  return {
    key: social.name,
    id: social.name,
    value: `**${social.name}`,
    label: (
      <div className="option-custom" aria-label={social.label}>
        <span id={social.name}>*{social.label}</span>
        <span className="option-flag">{social.icon}</span>
      </div>
    ),
    icon: social.icon,
  };
});
