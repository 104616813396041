import type { RouteObject } from 'react-router-dom';
import { LayoutPage } from 'pages/Layout/LayoutPage';
import NotFound from 'pages/NotFound';
import type { GlobalStoreValues } from 'store/GlobalSchema.types';

// ROUTES:
import { getUserRoutes } from 'routes/user/routesUser';
import { getWebRoutes } from 'routes/web/routesWeb';
import AccountRegistration from 'pages/AccountRegistration';
import { AuthUser } from 'auth';
import cloneDeep from 'lodash/cloneDeep';

export const getAppRoutes = ({
  user,
  store,
}: {
  user: AuthUser;
  store: GlobalStoreValues;
}): RouteObject[] => {
  const appRoutes = [
    {
      id: 'base',
      path: '/',
      element: <LayoutPage />,
      children: [
        ...getWebRoutes({ store }),
        ...getUserRoutes({ store, user }),
        { path: 'register-user-data', element: <AccountRegistration /> },
        { path: '*', element: <NotFound /> },
      ],
    },
  ];

  return cloneDeep(appRoutes);
};
