import { Col, Row } from 'react-grid-system';
import { ChangeEvent, ForwardedRef, MouseEvent, forwardRef, useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { FaToggleOff } from 'react-icons/fa';
import { GiConsoleController } from 'react-icons/gi';
import { IoSquareOutline as IconOff, IoCheckbox as IconOn } from 'react-icons/io5';
import type { SizeUI } from 'types';
import { Checkbox } from 'components/FormUI/Checkbox';
import type { SelectOption } from 'components/FormUI/Select';
import { styles } from './CheckboxGroup.css';

interface IRadioGroup {
  name: string;
  label?: string;
  defaultValue: string[];
  options: SelectOption[];
  onChange: (args?: any) => void;
  size?: SizeUI;
}

export const CheckboxGroup = forwardRef(
  ({ name, defaultValue, options, onChange, size = 'md' }: IRadioGroup, ref: ForwardedRef<any>) => {
    const [allChecked, setAllChecked] = useState<string[]>(defaultValue || []);

    const getColumnSize = (numOptions: number) => {
      if (!(numOptions % 2)) return 3;
      if (!(numOptions % 3)) return 4;
      return 6;
    };

    const toggleOn = (addedValue: string) => {
      const newAllChecked = [...allChecked, addedValue];
      setAllChecked(newAllChecked);
      onChange(newAllChecked);
    };

    const toggleOff = (removedValue: string) => {
      const newAllChecked = allChecked.filter((item) => item !== removedValue);
      setAllChecked(newAllChecked);
      onChange(newAllChecked);
    };

    const numOptions = options.length;
    const colWidth = getColumnSize(numOptions);

    return (
      <div className={`radio-group size-${size}`}>
        <Row>
          {options.map((option: SelectOption) => {
            const [checked, setChecked] = useState<boolean>(defaultValue.includes(option.value));

            useEffect(() => {
              checked ? toggleOn(option.value) : toggleOff(option.value);
            }, [checked]);

            return (
              <Col xs={colWidth}>
                <div key={option.value} onClick={() => setChecked(!checked)} style={{ cursor: 'pointer' }} css={styles}>
                  <input name={name} style={{ display: 'none' }} type="checkbox" value="true" checked={checked} />
                  <div className={`toggle-checkbox size-sm ${checked ? 'is-checked' : ''}`}>
                    <IconOn className="icon-on" />
                    <IconOff className="icon-off" />
                  </div>
                  <label htmlFor={option.label}>{option.label}</label>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  },
);
