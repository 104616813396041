import { toast, type ToastOptions } from 'react-toastify';
// eslint-disable-next-line prettier/prettier
import { Slide as SLIDE, Zoom as ZOOM, Flip as FLIP, Bounce as BOUNCE } from 'react-toastify';

// TOASTER DOCS: https://fkhadra.github.io/react-toastify/replace-default-transition

export const defaultToastOptions: ToastOptions = {
  // toastClassName: 'TOAST_ALERT',
  position: 'bottom-right',
  transition: ZOOM,
  hideProgressBar: true,
  closeOnClick: true,
  closeButton: false,
  pauseOnHover: true,
  draggable: true,
  pauseOnFocusLoss: false,
  rtl: false,
  autoClose: 1500,
  // limit: 3, // NOTE: MOVED to `ToastContainerProps`
  // EVENTS:
  // onOpen: (props: unknown) => console.log('TOAST_OPEN:', props),
  // onClose: (props: unknown) => console.log('TOAST_CLOSE:', props),
};
