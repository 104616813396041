import { Link } from 'react-router-dom';
import { ReactElement } from 'react';
import { useNavigateState } from 'hooks/useNavigateState';
import { Button } from './Button';
import { ButtonLinkProps } from './Button.types';

export const ButtonLink = ({ to, onClick, ...props }: ButtonLinkProps): ReactElement => {
  // NOTE: ENSURE `from` previous pathname ALWAYS included in router state
  // const { pathname /* basePath */ } = useLocation();
  const newState = { from: location.pathname, ...props.state };

  // TODO: `basePath` SAME as `pathname` ?? -- REMOVE / MERGE ??
  // TODO: NATIVE `location.pathname` SUFFICIENT ??

  return (
    <Link to={to} state={newState}>
      <Button {...props} />
    </Link>
  );
};

export const ButtonLinkV2 = ({ to, onClick, ...props }: ButtonLinkProps): ReactElement => {
  const { navigate } = useNavigateState();
  const handleClick = () => {
    props?.state ? navigate(to, { state: { ...props?.state } }) : navigate(to);
  };

  return <Button {...props} onClick={handleClick} />;
};
