import { css } from '@emotion/react';
import { colors, layout, max, min } from 'styles';

export const styles = css`
  margin-top: calc(${layout.padding} * 0.5);
  margin-bottom: calc(${layout.padding} * 0);
  margin-left: calc(${layout.padding} * 0.75);
  margin-right: calc(${layout.padding} * 0.75);

  padding: 0;
  padding-left: 0 !important;
  padding-right: 0 !important;

  padding-top: calc(${layout.padding} * 1.5) !important;
  padding-bottom: calc(${layout.padding} * 0.5) !important;

  ${min.md} {
    padding-top: calc(${layout.padding} * 1.5) !important;
    padding-bottom: calc(${layout.padding} * 1) !important;
  }

  hr {
    border-color: ${colors.greyLight};
    margin-top: calc(${layout.padding} * 2);
  }

  .row-title {
    display: flex;
    flex-direction: row;
  }

  .col-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    ${min.xs} {
      font-size: 0.75em;
    }
    ${min.sm} {
      font-size: 0.85em;
    }
    ${min.md} {
      font-size: 0.85em;
      justify-content: flex-start;
      align-items: flex-start;
    }
    ${min.lg} {
      font-size: 0.95em;
      justify-content: center;
      align-items: flex-start;
    }
    ${min.xl} {
      font-size: 1em;
      justify-content: center;
      align-items: flex-start;
    }

    h1.page-title {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      line-height: 1.05;
      justify-content: flex-start;
      padding-left: calc(${layout.padding} * 0.5) !important;

      ${min.md} {
        line-height: 1.15;
        padding-left: calc(${layout.padding} * 0) !important;
      }

      br + span.page-title-detail {
        &--is-wrapped {
          font-size: 0.85em;
        }
        ${min.xs} {
          font-size: 0.75em;
        }
        ${min.sm} {
          font-size: 0.85em;
        }
        ${min.md} {
          font-size: 0.9em;
        }
        ${min.lg} {
          font-size: 0.85em;
        }
        ${min.xl} {
          font-size: 0.85em;
        }
      }
    }

    h2.page-subtitle {
      width: inherit;
      margin-top: 0.75em !important;
      margin-bottom: 0 !important;
      font-size: 1.6em;
      line-height: 1.05;
      ${min.sm} {
        font-size: 1.7em;
      }
      ${min.md} {
        font-size: 1.6em;
        line-height: 1.15;
      }
      ${min.lg} {
        font-size: 1.5em;
      }
      ${min.xl} {
        font-size: 1.7em;
      }
    }
  }

  .col-button {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: calc(${layout.padding} * 1) !important;

    ${min.lg} {
      align-items: flex-start;
      justify-content: flex-end;
      padding: 0;
    }

    .btn-back {
      font-size: 1.1em;
      font-weight: 700;
      padding-left: 1.5em;
      padding-right: 1.5em;
      margin: calc(${layout.padding} * 0.5);

      ${max.lg} {
        width: 100%;
      }
    }
  }

  .col-breadcrumbs {
    height: calc(${layout.padding} * 2);
    h3 {
      margin: 0;
      a {
        color: ${colors.primaryXLight};
        &:hover {
          color: ${colors.primaryDark};
        }
      }
    }
    .slash {
      opacity: 0.33;
      margin: 0 0.33em;
      &:before {
        content: '/';
        transform: translateY(-0.1em);
        display: inline-block;
      }
    }
  }
`;
