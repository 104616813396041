import { Row, Col } from 'react-grid-system';
import { useAuth } from 'auth/AuthContext';
import { UserCurrentApnaes } from './UserCurrentApnaes';
import { styles } from './UserApnaes.css';
import { UserLoginApnaes } from './UserLoginApnaes';

const UserApnaes = () => {
  const { user } = useAuth();

  return (
    <div css={styles}>
      <Row>
        <Col className="col col-user-wrapper">
          <ul className="nav-menu">
            <li>{!user ? <UserLoginApnaes /> : <UserCurrentApnaes />}</li>
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export { UserApnaes };
