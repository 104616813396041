import { useEffect, useState } from 'react';
import type { DataEntry } from 'types';
import { useRouter } from 'routes/hooks';
import { useRoutesMeta } from 'routes/hooks/useRoutesMeta/useRoutesMeta';
import { useQueryGet } from 'api';
import { DataFilterProvider, initialState as initialData } from 'store/DataFilterContext';
import type { DataColumnConfig } from 'components/DataTable/DataTable.types';
import { Spinner } from 'components/Spinner';
import { isArray } from 'utils';
import { tableConfig as tableConfigInvite } from 'config/datatype/entidades';
import { ASC } from 'constants/sorting';
import { InviteFiltersProvider } from './InviteFiltersContext';
import { LayoutPage } from './InviteLayoutPage';
import { styles } from './InviteModal.css';
import { usePageContentActions } from 'pages/Layout/PageContentContext/PageContent.hooks';

export type InvitePageProps = {
  fieldName: string;
  endpoint?: string;
  tableConfig?: DataColumnConfig | any;
  selectedIDs?: string[];
};

export const InviteModal = ({ fieldName, endpoint, selectedIDs }: InvitePageProps) => {
  const { params } = useRouter();
  const { setPageSubtitle } = usePageContentActions();
  const { getDataWithsPathsApplied } = useRoutesMeta();

  // TODO: ENFORCE LOCAL FONIG - FOR NOW !!!
  const tableConfig = tableConfigInvite;

  // 1. FULL DATA SET
  const [data, setData] = useState<DataEntry[]>([]);
  endpoint ??= tableConfig?.api?.endpoint || (params.table as string) || 'posts';

  // FETCH DATA ============================================================= //

  const { isFetching, refetch: doFetch } = useQueryGet({
    // endpoint,
    endpoint,
    // date: { $gt: queryStartDate },
    // date: filters.dateSelected.value
    //   ? { $gt: `${queryStartDate} 00:00:00`, $lt: `${queryStartDate} 23:59:59` }
    //   : { $gt: queryStartDate },
    filter: { $sort: { created_at: ASC } },
    enabled: false,
    onSuccess: (res) => {
      const dataArray = !isArray(res.data) ? [res.data] : res.data;
      // setData(getDataWithsPathsApplied({ data: dataArray }));
      setData(getDataWithsPathsApplied({ data: dataArray.slice(0, 100) }));
    },
  });

  useEffect(() => {
    doFetch();
    setPageSubtitle('');
  }, [doFetch, params?.cat]);

  return (
    <section css={styles}>
      {isFetching && <Spinner page dark />}
      {!isFetching && (
        <DataFilterProvider initState={{ ...initialData, data }}>
          <InviteFiltersProvider>
            <LayoutPage
              fieldName={fieldName}
              isFetching={isFetching}
              data={data}
              tableConfig={tableConfig}
              selectedIDs={selectedIDs}
            />
          </InviteFiltersProvider>
        </DataFilterProvider>
      )}
    </section>
  );
};
