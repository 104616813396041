import React from 'react';
import { createStore } from 'zustand';
import { createZustandContext } from 'utils/zustand';
import type { NavItemStore, NavItemValues } from './NavItems.types';
import type { RouteConfig } from 'routes/routes.types';

export enum NavItemKeys {
  NavItemsPublic = 'navItemsPublic',
  NavItemsUser = 'navItemsUser',
  NavItemsPosts = 'navItemsPosts',
}

const initialStateValues: NavItemValues = {
  navItemsPublic: [],
  navItemsUser: [],
  navItemsPosts: [],
};

export const NavItems = createZustandContext((initialValues: NavItemValues = initialStateValues) => {
  return createStore<NavItemStore>((set, get) => ({
    ...initialValues,
    actions: {
      setNavItemsPublic: (val: RouteConfig[]) => {
        set({ navItemsPublic: val });
      },
      setNavItemsUser: (val: RouteConfig[]) => {
        set({ navItemsUser: val });
      },
      setNavItemsPosts: (val: RouteConfig[]) => {
        set({ navItemsPosts: val });
      },
    },
  }));
});

// ======================================================================== //
// NOTE: REFERENCES..

// @tkdodo - https://tkdodo.eu/blog/working-with-zustand
// https://tkdodo.eu/blog/zustand-and-react-context
// @MattPockock - https://x.com/mattpocockuk/status/1780865485325979685?s=46&t=cmQVCdpY7_bVoOftn8NIcg
// https://www.typescriptlang.org/play/?#code/JYWwDg9gTgLgBAJQKYEMDG8BmUIjgIilQ3wG4AoUSWOAbzgGUZokBBMYOAXzm1wIBeAVwDOMFADsAJmUrho8emiIoYSJi268cefMLGSpAegBuk4ABsLKWeTQQJYuMtRqAWqPHSAwg7UAPeABeOAAecjg4ABUASQlgGGAUCwAaCOiNIjgkQKRpEUZmInZgUMkATwA+NMqACnSAcyQYTKQALjha4HjE5I7YnqSLAEo4IMqMoqQ00fG6dPtHeF8JAODEYhgAOhdVJBW1+sjIiSErOBQCiouCqNa0yOGKBYcnAAUcE2ApJCgxzrAODAIg6tHSkTQAAtLFIiBIAPwdZDobbIjAAOQgPwoxzg3QSQwAaskhO1onECckcdxZhMwbjFk4ANpiFgAXX+aO2onU4jUtVqtPBxyaLSmtUBEGBW3xvQsxIspOGD2OT3IwqIMCEUAknWFkVCBxy2w+EC+Pz+ZkVSCCtFZRC4lX1x1okulUJhcK4ztCRiNgS2pvNvyduLVkS4z0imu1uvpxx5-pgHUFYwmXK2ib8xtqSeVwqTKsiQe+vxVkfIFfIMHKYCQhT2-3jACNUFAQXBTiBW1Bqd1diIkG8pWdVMAHCmux0uz3aXATBBvhQq4z4AAhNtMISYTD-XbuTyGJNHTqyoZMPaguCtlDt6dCbu-Glp+a4mM65wqNStUIXtR1YYBUHGA51qeNcRvO88UGZI-yQLZIJEItjn7FRB2HMBR0SCdOmbcohVxXFgKAvkkFA8DCMiRCOgMNQELbAoAGpr3KZDcS4YZ83YzjqS4GZnlXOB2DAf5UzmeN311E8DQ3W8tx3QNPlLKBnTPZIFVJW0KIghiOgARjY7hvVxUNCIAWXKISwDAYVfVkqB5MwRSzWU0y1QrIA
// gist: https://gist.github.com/bryanltobing/e09cb4bb110c4d10cefde665b572d899
