import { css } from '@emotion/react';
import { colors } from './colors.css';
import { layout } from './layout.css';

export const cssGlassFrosted = css`
  backdrop-filter: saturate(90%) blur(2px);
  transition: background-color 0.5s cubic-bezier(0.28, 0.11, 0.32, 1);
  transition-property:
    background-color,
    backdrop-filter,
    -webkit-backdrop-filter;
`;

export const cssGlassFrostedV2 = css`
  backdrop-filter: saturate(90%) blur(4px) opacity(100%);
  transition: background-color 0.3s cubic-bezier(0.28, 0.11, 0.32, 1);
  transition-property:
    background-color,
    backdrop-filter,
    -webkit-backdrop-filter;
`;

export const cssGlassFrostedV3 = css`
  backdrop-filter: saturate(90%) blur(8px) opacity(100%);
  transition: background-color 0.3s cubic-bezier(0.28, 0.11, 0.32, 1);
  transition-property:
    background-color,
    backdrop-filter,
    -webkit-backdrop-filter;
`;

export const cssGlassXFrosted = css`
  backdrop-filter: saturate(90%) blur(20px);
  transition: background-color 0.5s cubic-bezier(0.28, 0.11, 0.32, 1);
  transition-property:
    background-color,
    backdrop-filter,
    -webkit-backdrop-filter;
`;

export const cssGlassXXFrosted = css`
  backdrop-filter: saturate(90%) blur(40px);
  transition: background-color 0.5s cubic-bezier(0.28, 0.11, 0.32, 1);
  transition-property:
    background-color,
    backdrop-filter,
    -webkit-backdrop-filter;
`;

export const cssBoxShadow = css`
  box-shadow: calc(1rem / 2) calc(1rem / 2) 10px 0px rgba(0, 0, 0, 0.33);
`;

export const cssGlassFrostedOpaque = css`
  backdrop-filter: saturate(90%) blur(30px) opacity(100%);
  /* transition: background-color 0.3s cubic-bezier(0.28, 0.11, 0.32, 1);
  transition-property: background-color, backdrop-filter, -webkit-backdrop-filter; */
`;
