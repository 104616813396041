import { useMemo, useState } from 'react';
import { useGuestSession } from '../useGuestSession';
import type { FieldConfig } from 'types/form.types';
import { flattenFieldConfigs } from 'utils/utils.config';
import { config } from 'config';
import { SiteLockPage } from '../SiteLockPage';
import { UNLOCK_SITE_PASS } from '../sitelock.config';
import { formConfig } from '../sitelock.form';
import { useNavigateState } from 'hooks/useNavigateState';
import type { UserLoginData } from '../sitelock.types';
import type { SiteLockProviderProps, SiteLockContextValues } from './SiteLockContext.types';
import { SiteLockContext } from './SiteLockContext';

export const SiteLockProvider = ({ isLockerActive = true, children }: SiteLockProviderProps) => {
  const { guest, storeNewGuestSession } = useGuestSession();
  const [isSiteLocked, setIsSiteLocked] = useState<boolean>(isLockerActive);
  const { navigate } = useNavigateState();

  // FIELD VALUE MOCK ======================================================= //

  const handleSubmit = (formData: UserLoginData) => {
    if (formData.password === UNLOCK_SITE_PASS) {
      log('DEMO: Welcome !!', 'lime', formData);
      config.IS_SITE_LOCKED = false;
      storeNewGuestSession();
      setIsSiteLocked(false);
      navigate('home', { state: { isUnlocked: true } });
    }
  };

  // FIELD VALUE MOCK ======================================================= //

  const mockValues = () => {
    const field = flattenFieldConfigs(formConfig.sections as any)?.find(
      (config: FieldConfig) => config?.name === 'password',
    ) as FieldConfig;

    const fieldElement = document.querySelector(`[name="${field.name}"]`) as HTMLInputElement;
    const buttonSubmit = document.querySelector('[type="submit"]') as HTMLButtonElement;

    fieldElement.value = field.mockValue || UNLOCK_SITE_PASS;
    fieldElement.focus();

    buttonSubmit.disabled = false;
    buttonSubmit.focus();
    // buttonSubmit.click();
  };

  // ======================================================================== //

  const contextValue: SiteLockContextValues = useMemo(
    () => ({
      isSiteLocked,
      setIsSiteLocked,
      handleSubmit,
      mockValues,
    }),
    [isSiteLocked],
  );

  return (
    <SiteLockContext.Provider value={{ ...contextValue, handleSubmit }}>
      {isSiteLocked ? <SiteLockPage /> : children}
    </SiteLockContext.Provider>
  );
};
