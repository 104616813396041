import { useState, useEffect, ReactNode } from 'react';
import { TiArrowSortedUp as IconSortUp, TiArrowSortedDown as IconSortDown } from 'react-icons/ti';
import type { IGeneric } from 'types';
import type { SortDirection } from 'api';
import { useGlobal } from 'store/useGlobalContext';
import { Button } from 'components/Button';
import { usePagination } from 'components/Pagination';
import { ASC, DESC } from 'constants/sorting';
import type { DataColumnConfig } from '../../DataTable.types';

type DataHeadSortable = {
  column: DataColumnConfig;
  children: ReactNode;
  sortParams?: IGeneric;
};

export const DataHeadSortable = ({ column, children }: DataHeadSortable) => {
  const { gotoFirstPage } = usePagination();
  const [sort, setSort] = useState<SortDirection | 0>(0);
  const { sortParams, setSortParams } = useGlobal('sortParams');
  const label = children || column;

  const handleClickSort = (colConfig: DataColumnConfig) => {
    // TODO: ORPHANED ARG: colConfig
    console.log('SORT_ARG: colConfig -', colConfig);
    let nextSortState;
    if (!sort) {
      nextSortState = DESC;
    } else {
      nextSortState = sort === DESC ? ASC : DESC;
    }

    gotoFirstPage(); // RESET PAGINATION to Page 1 ON SORT TOGGLE
    setSortParams({ column, dir: nextSortState });
    setSort(nextSortState);
  };

  useEffect(() => {
    if (sortParams && sortParams.column !== column) {
      setSort(0);
    }
  }, [sortParams]);

  return (
    <Button variant="clear" onClick={() => handleClickSort(column)}>
      {label}{' '}
      <div className={'sort-toggle'}>
        {sort === ASC && <IconSortDown />}
        {sort === DESC && <IconSortUp />}
      </div>
    </Button>
  );
};
