import { css } from '@emotion/react';
import { colors, layout, forms, cssFontMono, min, max, cssInputReadOnly } from 'styles';

export const styles = css`
  fieldset {
    margin-bottom: calc(${layout.padding} * 1.25);
    ${min.md} {
      /* height: unset; */
      margin-bottom: calc(${layout.padding} * 1.5);
    }
  }

  /*** CLASSES ARE SET ON fieldset ***/

  input:required {
  }

  .read-only {
    input:read-only {
      ${cssInputReadOnly}
    }
  }

  .is-dirty {
    input[type='date'],
    input[type='date']:focus {
      color: ${colors.text}!important;
    }
  }

  .has-error {
    input {
      border-color: ${colors.danger};
    }
    input:focus {
      border-color: ${colors.warningXXLight};
    }
  }

  /* input[aria-invalid='true'],
  input:invalid {
    border-color: ${colors.warningXXLight}!important;
  } */

  .is-invalid {
    input {
      border-color: ${colors.warningXXLight};
    }
    input:focus {
      border-color: ${colors.warningXXLight};
    }
  }

  .is-disabled {
    .input-select {
      opacity: 0.75;
      box-shadow: inset 0 0 0 ${forms.inputs.height} ${colors.greyLight};
      border-radius: ${layout.borderRadius};
    }
  }

  .is-hidden {
    display: none !important;
  }

  .padding-x {
    margin: calc(${layout.padding} * 1) 0;
  }

  fieldset.input-type--image {
    /* height: 126px; */
  }

  textarea {
    /* font-weight: 400;
    font-size: 0.9em;
    line-height: 1.75; */
    /* min-height: 5em !important; */
  }

  textarea.json-data {
    ${cssFontMono}
    font-weight: 400;
    font-size: 0.9em;
    line-height: 1.75;
    min-height: 240px;
  }

  fieldset.input-type--repeater {
    .col-label-right {
      padding-right: 0 !important;
      ${min.sm} {
        padding-right: calc(${layout.padding} * 4.5) !important;
      }
      ${min.md} {
        padding-right: 0 !important;
      }
      ${min.lg} {
        padding-right: calc(${layout.padding} * 3.5) !important;
      }
      ${min.xl} {
        padding-right: calc(${layout.padding} * 4.5) !important;
      }
      ${min.xxl} {
        padding-right: calc(${layout.padding} * 5) !important;
      }
    }
  }
`;
