import { FormDataEncoder } from 'form-data-encoder';
import { FormData, Blob } from 'formdata-node'; // Types for Typescript
import { IParams } from 'types';
import { API_BASE_URL } from 'api/api.config';
import { UserAuthSession } from 'auth/storage/user.storage';

export const encodeRequestPayload = async ({ params, encoder }: { params: IParams; encoder: FormDataEncoder }) => {
  log('PAYLOAD', 'orange', params);
  const options = {
    headers: { ...encoder.headers },
    body: new Blob(encoder, { type: encoder.contentType }),
    params: {},
    // TODO: keep `params` to send ??? (is pulling URL query params)
    // NOTE: it seems that FeathersJS receives headers, body, params.. (accurate??)
  };

  return { options };
};

export const encodSubmitData = async (formData: FormData, params = {}): Promise<any> => {
  const encoder = new FormDataEncoder(formData);
  const { options } = await encodeRequestPayload({ params, encoder });

  return options;
};

// FILE + IMAGE SUBMIT =================================================== //

export const isFileInput = (field: unknown) => {
  if (typeof field === 'object' && field[Symbol.toStringTag] === 'File') {
    return true;
  }
  return false;
};

// FILE + IMAGE SUBMISSION
export const postFile = async ({ field, value }: { field: string; value: unknown }) => {
  const { user } = UserAuthSession.get();
  const fileData = new FormData();
  fileData.append(field, value);
  fileData.append('user_id', user.uuid); // TODO: POSSIBLE USE NOT ALWAYS OWNER

  // TODO: TRY api.post() TO AVOID HARDCODING !!
  const response = await fetch(`${API_BASE_URL}/file-upload`, {
    method: 'POST',
    body: fileData as BodyInit,
  }).then((res) => res.json());
  log('[POST] file', 'lime', response);

  return response;
};

export const handleSubmittedFile = async ({ field, value }: { field: string; value: unknown }) => {
  // TODO: EXPAND THIS METHOD TO COVER MORE FILE-TYPES
  try {
    const { id, filename } = await postFile({ field, value });
    return { id, filename };
  } catch (err) {
    console.error(err);
    return;
  }
  // REMOVE HEADER_IMAGE_PREVIEW
};
