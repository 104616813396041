import { useQueries, type UseQueryResult } from '@tanstack/react-query';
import type { RouteGroup, RouteConfig } from '../routes.types';
import { DEFAULT_ROUTES_CONFIG_KEYS } from 'routes/routes.constants';
import { fetchGetData } from 'api/useGetMetaData_THIS';

interface UseGetRouteConfigurationResult {
  isFetchingRoutes: boolean;
  isSuccessRoutes: boolean;
  routesConfig: RouteConfig[];
}

export const useGetRouteConfiguration = (
  routeGroupKeys: RouteGroup[] = DEFAULT_ROUTES_CONFIG_KEYS,
): UseGetRouteConfigurationResult => {
  const queries = routeGroupKeys.map((routeGroup: RouteGroup) => {
    const meta_key = `routes_config_${routeGroup}`;

    return {
      queryKey: ['getMetadata', meta_key],
      queryFn: () => fetchGetData({ endpoint: 'metadata', queryParams: { meta_key } }),
    };
  });

  const results = useQueries({ queries });

  const isFetching = results.some((result: UseQueryResult) => result.isFetching);
  const isSuccess = results.every((result: UseQueryResult) => result.isSuccess);

  const data = results
    .filter((result: UseQueryResult) => result.data)
    .map((result: UseQueryResult) => result.data as RouteConfig);

  return {
    isFetchingRoutes: isFetching,
    isSuccessRoutes: isSuccess,
    routesConfig: data,
  };
};
