import { Row, Col } from 'react-grid-system';
import { useFormContext, useFieldArray, useWatch } from 'react-hook-form';
import { useEffect, useRef } from 'react';
import { FieldItem } from './FieldItem';
import { IRepeater } from './InputRepeater.types';
import { DEFAULT_REPEATER_ITEM } from './input-repeater.config';
import { styles } from './InputRepeater.css';

export const InputRepeater = ({ name: FIELD_KEY, field, defaultValue, repeaterConfig }: IRepeater) => {
  const isInitalRender = useRef(true);
  const { control } = useFormContext();
  const fieldArray = useFieldArray({ name: FIELD_KEY, keyName: 'uuid', control });
  const currentValue = useWatch({ name: field.name });

  useEffect(() => {
    if (defaultValue && fieldArray.fields.length === 0) return fieldArray.replace(DEFAULT_REPEATER_ITEM);
    if (!defaultValue && !field.value) return fieldArray.replace(DEFAULT_REPEATER_ITEM);
    if (defaultValue?.length) return fieldArray.replace(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (fieldArray.fields && !fieldArray.fields?.length) {
      fieldArray.replace(DEFAULT_REPEATER_ITEM);
    }
  }, [fieldArray.fields]);

  return (
    <Row css={styles}>
      <Col>
        <div className="card-array">
          {fieldArray.fields.map((item: Record<'uuid', string>, index) => (
            <FieldItem
              key={item.uuid}
              index={index}
              item={item}
              fieldKey={`${FIELD_KEY}[${index}]`}
              fieldArray={fieldArray}
              repeaterConfig={repeaterConfig}
            />
          ))}
        </div>
      </Col>
    </Row>
  );
};
