import type { TableConfig, Action } from 'types';
import type { DataColumnConfig } from 'components/DataTable/DataTable.types';
import { LinkToDetail } from 'components/DataTable/components';
import { ListItemCard as ListItemCardDefault } from 'components/ListItemCard';

// TABLE CONFIG:
export const tableConfig: TableConfig = {
  dataType: {
    key: null,
    role: 'table',
  },
  list: {
    colWidth: {
      xs: 12,
      lg: 4,
    },
    listItem: {
      component: ListItemCardDefault,
      shapeRatio: 0.5,
      isCallout: true,
      onClickAction: false,
    },
  },
  actions: {
    hasActionView: true,
    hasActionEdit: false,
    hasActionNew: false,
  },
  api: {
    endpoint: '',
  },
  columns: [
    {
      label: 'NAME',
      name: 'name',
      // width: 3,
      // width: false, // FALSE or OMITTED TO SET AS FLEXI-COLUMN
      isSortable: true,
      render: ({ data }) => <LinkToDetail targetID={data.id} label={data.name} />,
    },
    {
      label: 'EMAIL',
      name: 'email',
      widths: {
        xs: 0,
        lg: 2,
      },
      isSortable: true,
      render: ({ data }) => <LinkToDetail targetID={data.id} label={data.email} />,
    },
    {
      label: 'PROVINCE',
      name: 'province',
      isSortable: true,
      render: ({ data }) => <LinkToDetail targetID={data.id} label={data.province} />,
    },
    {
      label: 'ID',
      name: 'id',
      width: 1,
      widths: {
        xs: 0,
        lg: 1,
      },
      isSortable: true,
      render: ({ data }) => data.id,
    },
    /*
    DEFAULT BUTTON COULUMNS:
    { VIEW / width: 1, ADD / width: 1 }.
    */
  ] as DataColumnConfig[],
};
