import { useFormContext } from 'react-hook-form';
import ReactTags from 'react-tag-autocomplete';
import { useState, useEffect, forwardRef, useCallback, useRef, ForwardedRef } from 'react';
import { ITagsField } from './InputTags.types';
import type { IOptionTag } from './InputTags.types';
import { styles } from './InputTags.css';

// const DEFAULT_TAG_TYPE_LABEL = 'etiqueta';
const DEFAULT_TAG_TYPE_LABEL = 'item';

const classNames = {
  root: 'react-tags',
  rootFocused: 'is-focused',
  selected: 'react-tags__selected',
  selectedTag: 'react-tags__selected-tag',
  selectedTagName: 'react-tags__selected-tag-name',
  search: 'react-tags__search',
  searchWrapper: 'react-tags__search-wrapper',
  searchInput: 'react-tags__search-input',
  suggestions: 'react-tags__suggestions',
  suggestionActive: 'is-active',
  suggestionDisabled: 'is-disabled',
  suggestionPrefix: 'react-tags__suggestion-prefix',
};

export const InputTags = forwardRef(
  ({ name, defaultValue = [], tagsConfig, onChange, ...props }: ITagsField, ref: ForwardedRef<any>) => {
    const { register } = useFormContext();
    const reactTags = useRef();
    const [suggestions, setSuggestions] = useState<IOptionTag[]>([]);
    const [tags, setTags] = useState(defaultValue);
    const [label, setLabel] = useState<string>(tagsConfig?.label || DEFAULT_TAG_TYPE_LABEL);

    useEffect(() => {
      const getOptions = async () => {
        if (tagsConfig?.fetchOptions) {
          const metadata = await tagsConfig.fetchOptions();
          setSuggestions(metadata.options);
          setLabel(metadata.label);
          return;
        }
        if (tagsConfig?.options) {
          setSuggestions(tagsConfig.options);
          setLabel(tagsConfig?.label || DEFAULT_TAG_TYPE_LABEL);
          return;
        }
      };
      getOptions();
    }, [tagsConfig]);

    const onDelete = useCallback(
      (tagIndex: number) => {
        const newTags = tags.filter((_, i) => i !== tagIndex);
        onChange(newTags);
        setTags(newTags);
      },
      [tags],
    );

    const onAddition = useCallback(
      (newTag: IOptionTag) => {
        const newTags = [...tags, newTag];
        onChange(newTags);
        setTags(newTags);
      },
      [tags],
    );

    const onValidate = useCallback((newTag: any) => {
      // TODO: SET MIN-MAX...
      if (newTag.name.length >= 3) {
        return /^[a-z]{3,20}$/i.test(newTag.name);
        // onAddition(newTag);
      }
      return;
    }, []);

    return (
      <div css={styles}>
        <ReactTags
          // V1
          {...register(name)}
          id={name}
          ref={reactTags}
          // V2
          allowNew={true}
          allowBackspace
          suggestions={suggestions} // ALL AVAILABLE OPTIONS
          tags={tags} // SELECTED OPTIONS
          classNames={classNames}
          //
          onDelete={onDelete}
          onAddition={onAddition}
          onValidate={onValidate}
          //
          // MORE:
          // suggestionsFilter
          // suggestionsTransform
          //
          ariaLabelText={`+ Añadir ${label}`}
          placeholderText={`+ Añadir ${label}`}
          newTagText="Create new tag:"
          removeButtonText={'Clica para borrar etiqueta'}
          // noSuggestionsText
          //
          autoresize
          // delimiters
          minQueryLength={1}
          maxSuggestionsLength={99}
          //
          onInput={(e: any) => log('onInput', 'magenta', e)}
          onBonFocuslur={(e: any) => log('onFocus', 'magenta', e)}
          onBlur={(e: any) => log('onBlur', 'magenta', e)}
          addOnBlur={true}
          //
          // tagComponent
          // suggestionComponent={() => (
          //   <Select
          //     // {...register(name, { ...inputValidation })}
          //     // label={field.label}
          //     options={suggestions}
          //     // value={getValues(name)}
          //     onChange={(abc) => console.log(abc)}
          //     // isDisabled={field.isDisabled}
          //     // style={{ opacity: field.isDisabled ? 0.33 : 1 }}
          //     // {...field?.props}
          //   />
          // )}
          // suggestionComponent={() => (
          //   <ReactSelect
          //     name={name}
          //     aria-label={label}
          //     className={`input-select select-${name}`}
          //     classNamePrefix="select"
          //     placeholder="Seleccionar..."
          //     // ref={forwardedRef as ForwardedRef<any>}
          //     options={suggestions}
          //     // value={getSelectedOption(options, { value })}
          //     onChange={onAddition}
          //     isDisabled={false}
          //     // OTHER REACT-SELECT OPTIONS
          //     // onBlur={onBlur}
          //     isMulti={false}
          //     isSearchable={true}
          //     isClearable={true}
          //   />
          // )}
          // inputAttributes
        />
      </div>
    );
  },
);
