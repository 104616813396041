import { Row, Col } from 'react-grid-system';
import { useFormContext } from 'react-hook-form';
import { useState, useEffect, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FaYoutube } from 'react-icons/fa';
import { MdErrorOutline } from 'react-icons/md';
import { Spinner } from 'components/Spinner';
import { styles } from './YoutubeURL.css';

export const YoutubeURL = ({ name, defaultValue, /* rules, */ clearErrors }: any) => {
  const { register } = useFormContext();
  const [url, setURL] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  // =============================================================== //

  const patterns = {
    // iframe: new RegExp(/\<iframe/),
    iframe: new RegExp(/<iframe/),
    // iframeSrc: new RegExp(/(?<=src=\")(.*?)(?=")/),
    iframeSrc: new RegExp(/(?<=src=")(.*?)(?=")/),
    embed: new RegExp(/https:\/\/www.youtube.com\/embed\//),
    // urlWatch: new RegExp(/(?<=https:\/\/www.youtube\.com\/watch\?v\=)(.*)/),
    urlWatch: new RegExp(/(?<=https:\/\/www.youtube\.com\/watch\?v=)(.*)/),
    urlEmbed: new RegExp(/(?<=https:\/\/youtu\.be\/embed\/)(.*)/),
    url: new RegExp(/(?<=https:\/\/youtu\.be\/)(.*)/),
    partial: new RegExp(/(?<=https:\/\/www.you)(.*)/),
  };
  // const pattern1 = new RegExp(/\<iframe/);
  // const pattern2 = new RegExp(/(?<=src=")(.*?)(?=")/);
  const isDirty = false;

  const handleChange = (event: any) => {
    const input = event.target.value;
    // setValue(name, input, { shouldDirty: true });  // RHF setValue()
    const prefixYoutube = 'https://www.youtube.com/embed';
    let idVideo;
    setIsLoading(true);

    /*
     * YOUTUBE URLS:
     *
     * REQUIRED/VALID FORMAT:
     * https://www.youtube.com/embed/Jj3w1g3poQs
     *
     * FORMATS NOT VALID, BUT WILL BE CONVERTED AUTOMATICALLY ;)
     * <iframe width="560" height="315" src="https://www.youtube.com/embed/Jj3w1g3poQs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
     * https://youtu.be/Jj3w1g3poQs
     * https://youtu.be/embed/Jj3w1g3poQs
     * https://www.youtube.com/watch?v=Jj3w1g3poQs
     *
     */

    const triggerError = () => {
      setURL(null);
      setHasError(true);
      setIsLoading(false);
      console.log('ERROR!!: ', hasError);
    };

    switch (true) {
      // IFRAME + EMBED
      case input.length === 0:
        setURL(null);
        setHasError(false);
        clearErrors(name);
        setIsLoading(false);
        break;

      // IFRAME + EMBED
      case patterns.iframe.test(input):
        setURL(input.match(patterns.iframeSrc)[0]);
        event.target.value = input.match(patterns.iframeSrc)[0];
        setIsLoading(false);
        break;

      // URL FORMAT: https://www.youtube.com/embed/ (** FORMAT REQUIRED! **)
      case patterns.embed.test(input):
        setURL(input);
        setIsLoading(false);
        break;

      // URL FORMAT: https://www.youtube.com/watch?v=
      case patterns.urlWatch.test(input):
        idVideo = input.match(patterns.urlWatch)[0];
        setURL(`${prefixYoutube}/${idVideo}`);
        event.target.value = `${prefixYoutube}/${idVideo}`;
        setIsLoading(false);
        break;

      // URL FORMAT: https://youtu.be/embed/
      case patterns.urlEmbed.test(input):
        idVideo = input.match(patterns.urlEmbed)[0];
        setURL(`${prefixYoutube}/${idVideo}`);
        event.target.value = `${prefixYoutube}/${idVideo}`;
        setIsLoading(false);
        break;

      // URL FORMAT: https://youtu.be/
      case patterns.url.test(input):
        idVideo = input.match(patterns.url)[0];
        setURL(`${prefixYoutube}/${idVideo}`);
        event.target.value = `${prefixYoutube}/${idVideo}`;
        setIsLoading(false);
        break;

      // URL FORMAT: *NOT* ht...
      case input.length >= 2 && !/(ht)/.test(input):
        triggerError();
        break;

      // URL FORMAT: *NOT* https...
      case input.length >= 5 && !/https(.*)/.test(input):
        triggerError();
        break;

      // URL FORMAT: *NOT* https://www.you...
      case input.length >= 15 && !/https:\/\/www\.you/.test(input):
        triggerError();
        break;

      default:
        setURL(null);
        setIsLoading(true);
        setHasError(false);
    }
  };

  // =============================================================== //

  useEffect(() => {
    setURL(defaultValue);
  }, []);

  // =============================================================== //

  return (
    <div css={styles} className={`${hasError ? 'has-error' : ''}`}>
      <input
        // {...register(name, { ...inputValidation })}
        {...register}
        // name={name}
        // ref={register(rules)}
        type="url"
        placeholder="https://www.youtube.com/embed/abc123"
        // pattern="https?://.*"
        // aria-invalid={false}
        autoComplete="false"
        onChange={(event) => {
          handleChange(event);
        }}
      />

      <div className="iframe-wrapper">
        <div className="iframe-overlay">
          {hasError && <MdErrorOutline className="icon" />}
          {!hasError && !isLoading && <FaYoutube className="icon" />}
          {isLoading && <Spinner />}
        </div>
        {url && <iframe src={url} />}
      </div>
    </div>
  );
};

// export default YoutubeURL; // HERE, WORKS !!

// name, register, defaultValue, /*rules,*/ clearErrors

YoutubeURL.propTypes = {
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  clearErrors: PropTypes.func,
};
