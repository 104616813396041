import { css } from '@emotion/react';
import { colors } from 'styles/colors.css';

export const styles = css`
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  user-select: none;
  transform: translate(-50%, -50%);
  font-size: 2em !important;
  font-weight: 900;
  white-space: nowrap;
  text-align: center;
  color: ${colors.greyXXDark};
  letter-spacing: 0.02em;
  cursor: default;
  &:hover {
    z-index: 1;
  }
  .map-pin-label {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    fill: ${colors.dangerXDark};
    transform: translate(-50%, -100%);
    display: none;
  }
  svg {
    font-size: 1.5em;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    fill: ${colors.dangerXDark};
    &.map-pin-shadow {
      fill: rgba(0, 0, 0, 0.6);
      transform: skew(45deg, 0deg) translate(-50%, 25%) scale(1.5, 0.66);
      filter: blur(3px);
    }
  }
`;
