import { css } from '@emotion/react';
import { layout } from 'styles/layout.css';
import { colors } from 'styles/colors.css';

export const styles = css`
  &.radio-group {
    display: flex;
    flex-direction: column;
    padding: 0 calc(${layout.padding} * 0.25) 0;
    &.size-sm svg {
      transform: scale(1);
    }
    &.size-md svg {
      transform: scale(1.9);
    }
    &.size-lg svg {
      transform: scale(2.5);
    }

    .radio-option {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: calc(${layout.padding} * 0.5) 0 calc(${layout.padding} * 0.5) calc(${layout.padding} * 0.5);
      cursor: pointer;

      svg {
        margin: 0 calc(${layout.padding} * 0) 0 0;
      }

      label {
        color: ${colors.greyDark};
        font-size: 1.15em;
        margin: 0 0 0 1.25em;
        padding: 0.1em 0 0.125em;
        cursor: pointer;
      }
    }
  }

  .radio-option {
    svg {
      border-radius: 50%;
      box-shadow: inset 0 0 0 2px ${colors.greyXXLight};
      background: ${colors.greyXLight};
      color: ${colors.grey};
    }

    svg.icon-on {
      display: none;
      color: ${colors.successDark};
    }
    svg.icon-off {
      display: flex;
    }

    &:hover {
      svg.icon-on {
        display: flex;
        background: white;
        color: ${colors.grey};
      }
      svg.icon-off {
        display: none;
      }
    }

    &.is-checked {
      svg.icon-on {
        display: flex;
        background: white;
        color: ${colors.successDark};
      }
      svg.icon-off {
        display: none;
      }
    }

    &.is-checked:hover {
      svg.icon-on {
        display: flex;
        background: white;
        color: ${colors.successDark};
      }
      svg.icon-off {
        display: none;
      }
    }
  }
`;
