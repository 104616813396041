import { Container, Row, Col } from 'react-grid-system';
import type { ReactElement } from 'react';
import { useEffect } from 'react';
import type { RouteConfig } from 'routes';
import { useAuth } from 'auth/AuthContext';
import { useGlobal } from 'store/useGlobalContext';
import { useLayout } from 'pages/Layout/context/LayoutContext';
import { Menu } from 'components/Menu';
import { stylesHorizontal as styles } from './Nav.css';
import { findRouteByProp, removeRouteByProp } from 'routes/utils/routes.utils.find';
import { useNavItems, useNavItemsActions } from 'components/Nav/NavItemsContext/NavItems.hooks';
import cloneDeep from 'lodash/cloneDeep';

// NEW: CSS-ONLY DROPDOWN MENU:
// https://csslayout.io/nested-dropdowns/

/**
 * REACT-MENU_DOCS
 * https://www.npmjs.com/package/@szhsin/react-menu
 * https://codesandbox.io/s/react-menu-starter-3ez3c
 * https://codesandbox.io/s/react-menu-starter-3ez3c
 *
 * REACT-ROUTER EXAMPLE:
 * https://codesandbox.io/s/react-menu-react-router-example-dw4ku
 */

export const NavHorizontal = (): ReactElement => {
  const { isNavbarVisible } = useLayout();
  if (!isNavbarVisible) return <nav css={styles} className="nav-horizontal" />;

  const { user } = useAuth();
  const { metaRoutesTree } = useGlobal('metaRoutesTree');
  const { routesConfiguration } = useGlobal('routesConfiguration');

  const { navItemsPublic, navItemsUser, navItemsPosts } = useNavItems();
  const { setNavItemsPublic, setNavItemsUser, setNavItemsPosts } = useNavItemsActions();

  useEffect(() => {
    if (!metaRoutesTree?.length) return;
    const routesConfigSource = cloneDeep(routesConfiguration);

    const routesPublic = findRouteByProp(routesConfigSource, { pathname: '/' }) as RouteConfig;
    const routesCategories = routesConfigSource.filter((r) =>
      ['canales', 'periodicos', 'entidades'].includes(r.path),
    );

    // ======================================================================== //
    // NOTE: SEPCIAL CASES
    const eventosRoute = removeRouteByProp(routesPublic.children, { path: 'eventos' });

    // ======================================================================== //

    setNavItemsPublic(routesPublic.children);
    setNavItemsPosts([eventosRoute, ...routesCategories]);

    const routesUser = (user ? routesConfigSource[2]?.children : []) as RouteConfig[];
    setNavItemsUser(routesUser);

    // ======================================================================== //
  }, [metaRoutesTree, location.pathname]);

  const isReadyLeft = user ? !!navItemsPublic?.length && !!navItemsUser?.length : !!navItemsPublic?.length;
  const isReadyRight = !!navItemsPosts?.length;

  return (
    <nav css={styles} className="nav-horizontal">
      <Container fluid xs={true} sm={true} md={true} lg={true} xl={true} className="nav-container">
        <Row align="stretch" justify="between">
          <Col xs={12} className="col-navs">
            <nav className="nav-flex">
              {isReadyLeft && <Menu routes={[...navItemsPublic, ...navItemsUser]} />}
            </nav>
            <nav className="nav-flex">{isReadyRight && <Menu routes={navItemsPosts} />}</nav>
          </Col>
        </Row>
      </Container>
    </nav>
  );
};
