export const PROVINCIAS = [
  'Álava',
  'Albacete',
  'Alicante',
  'Almería',
  'Asturias',
  'Ávila',
  'Badajoz',
  'Barcelona',
  'Burgos',
  'Cantabria',
  'Castellón',
  'Ciudad Real',
  'Cuenca',
  'Cáceres',
  'Cádiz',
  'Córdoba',
  'Gerona',
  'Granada',
  'Guadalajara',
  'Guipúzcoa',
  'Huelva',
  'Huesca',
  'Islas Baleares',
  'Jaén',
  'La Coruña',
  'La Rioja',
  'Las Palmas',
  'León',
  'Lugo',
  'lérida',
  'Madrid',
  'Murcia',
  'Málaga',
  'Navarra',
  'Orense',
  'Palencia',
  'Pontevedra',
  'Salamanca',
  'Santa Cruz de Tenerife',
  'Segovia',
  'Sevilla',
  'Soria',
  'Tarragona',
  'Teruel',
  'Toledo',
  'Valencia',
  'Valladolid',
  'Vizcaya',
  'Zamora',
  'Zaragoza',
];

export const COMUNIDAD_AUTONIMO = [
  'Andalucía',
  'Aragón',
  'Principado de Asturias',
  'Baleares',
  'Canarias',
  'Cantabria',
  'Castilla-La Mancha',
  'Castilla y León',
  'Cataluña',
  'Comunidad Valenciana',
  'Extremadura',
  'Galicia',
  'La Rioja',
  'Comunidad de Madrid',
  'Navarra',
  'País Vasco',
  'Región de Murcia',
];

export const COMUNIDAD_AUTONIMO_SHORT = [
  'And',
  'Ara',
  'Ast',
  'Bal',
  'Can',
  'Cbr',
  'Man',
  'Leo',
  'Cat',
  'Com',
  'Ext',
  'Gal',
  'Rio',
  'Mad',
  'Nav',
  'Vas',
  'Mur',
];
