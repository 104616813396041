import { INPUTS } from 'components/Form';
import type { FieldConfig } from 'components/Form';

export const FIELDS_REGISTER_CONFIRM: FieldConfig[] = [
  {
    name: 'FORM_COMPLETION_PERCENTAGE',
    label: 'Estatus formulario de registro:',
    inputType: INPUTS.FORM_COMPLETION, // NOTE: do NOT make INPUTS.uuid
    required: false,
    readOnly: true,
    isHidden: false,
    colWidth: { xs: 12 },
  },
  {
    name: 'IS_FORM_SUBMIT_LOCKED',
    inputType: INPUTS.text,
    value: 123,
    required: false,
    readOnly: true,
    isHidden: true,
    isDisabled: true,
    colWidth: { xs: 12 },
  },
  {
    name: 'accept_terms',
    label: 'He leído y acepto los términos y condiciones de uso - {LINK}',
    label_link: {
      key: 'LINK',
      label: 'leer aquí',
      target: {
        type: 'MODAL',
        meta_key: 'accept_terms',
      },
    },
    inputType: INPUTS.checkbox,
    required: true,
    isDisabled: true,
    colWidth: { xs: 12 },
    isControlledBy: {
      criteria: { IS_FORM_SUBMIT_LOCKED: false },
      target: {
        isDisabled: false,
        required: true,
      },
    },
  },
  {
    name: 'accept_privacy',
    label: 'Política de protección de datos - {LINK}',
    label_link: {
      key: 'LINK',
      label: 'leer aquí',
      target: {
        type: 'MODAL',
        meta_key: 'accept_privacy',
      },
    },
    inputType: INPUTS.checkbox,
    required: true,
    isDisabled: true,
    colWidth: { xs: 12 },
    isControlledBy: {
      criteria: { IS_FORM_SUBMIT_LOCKED: false },
      target: {
        isDisabled: false,
        required: true,
      },
    },
  },
];
// ---------------------------------------------------------------------- //
/*
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'TEST - is_validated',
      isHidden: false,
      fields: [
        {
          name: 'id',
          label: 'ID',
          inputType: INPUTS.number, // NOTE: do NOT make INPUTS.uuid
          required: true,
          readOnly: true,
          isHidden: false,
          colWidth: { xs: 4 },
        },
        {
          name: 'uuid',
          label: 'REGISTERED_UUID',
          inputType: INPUTS.text, // NOTE: do NOT make INPUTS.uuid
          required: true,
          colWidth: { xs: 4 },
        },
        {
          name: 'is_validated',
          label: 'IS_VALIDATED',
          inputType: INPUTS.checkbox,
          defaultValue: user.user_role === USER_ROLE.VALIDATED,
          colWidth: { xs: 4 },
        },
      ],
    },
    */
