import { css } from '@emotion/react';
import { layout } from 'styles/layout.css';
import { colors } from 'styles/colors.css';
import { min, max } from 'styles/media-queries-V1';

const burgerHeight = 25;
const burger = {
  width: burgerHeight * 1.333,
  height: burgerHeight,
  scale: 1,
  color: colors.greyLight,
  colorHover: colors.primary,
};

export const styles = css`
  &.icon-burger {
    width: ${burger.width}px;
    height: ${burger.height}px;
    position: relative;
    margin: 0;
    transition:
      0.6s ease-in-out,
      left 0;
    cursor: pointer;
    position: fixed;
    top: calc(${layout.header.height} / 2);
    transform: translateY(-40%) scale(${burger.scale});
    ${max.md} {
      transform: translateX(0%) translateY(-40%) scale(${burger.scale});
    }
  }

  &.icon-burger span {
    display: block;
    position: absolute;
    height: calc(${burger.height}px * 0.2);
    width: 100%;
    border-radius: calc(${layout.borderRadius} * 0.5);
    opacity: 1;
    left: 0;
    transform: rotate(0deg) scale(${burger.scale});
    background-color: ${burger.color};
    transition: 0.3s ease-in-out;
  }

  &.icon-burger:hover span {
    background-color: ${burger.colorHover};
  }

  &.icon-burger {
    span:nth-of-type(1) {
      top: 0px;
    }
    span:nth-of-type(2) {
      top: calc(${burger.height}px * 0.4);
    }
    span:nth-of-type(3) {
      top: calc(${burger.height}px * 0.8);
    }
  }

  &.icon-burger.open {
    span:nth-of-type(1) {
      top: calc(${burger.height}px * 0.4);
      transform: rotate(135deg) scale(${burger.scale});
    }
    span:nth-of-type(2) {
      opacity: 0;
      left: -${burger.width}px;
    }
    span:nth-of-type(3) {
      top: calc(${burger.height}px * 0.4);
      transform: rotate(-135deg) scale(${burger.scale});
    }
  }
`;
