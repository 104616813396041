import { types, BUTTONS } from 'components/Form';
import type { FormConfig, FormButton } from 'components/Form';
import { applyReadOnly } from 'components/Form/utils/form.utils.config';
import { FIELDS_PERSONAL_DATA, FIELDS_ADDRESS, FIELDS_DOCS_PERSONAL } from 'config/form.sections';
import { DATATYPE, ENDPOINT } from './account.config';
import { getFieldsetConfig } from 'config/data.utils/getFieldsetConfig';

let i = 0;

const buttons: FormButton[] = [
  {
    type: types.button,
    label: 't.buttons.CANCEL',
    action: BUTTONS.VIEW,
  },
  {
    type: types.submit,
    label: 't.buttons.save',
    action: BUTTONS.SUBMIT,
  },
];

export const formConfig: FormConfig = {
  dataType: { key: DATATYPE, role: 'form' },
  api: { endpoint: ENDPOINT, omitFields: ['is_validated'] },
  isNewEntry: false,
  isNavPromptActive: true,
  buttons,
  sections: [
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Datos personales (FORM)',
      fields: [
        ...applyReadOnly(FIELDS_PERSONAL_DATA, ['name_first', 'name_last']),
        ...applyReadOnly(FIELDS_DOCS_PERSONAL, ['document_type_personal', 'document_number_personal']),
        // ...getFieldsetConfig(FIELDS_DOCS_PERSONAL),
      ],
    },
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Dirección',
      description: 'Opcionalmente puede utilizar como dirección de registro la de su medio o entidad.',
      fields: [...getFieldsetConfig(FIELDS_ADDRESS)],
    },
    // ---------------------------------------------------------------------- //
  ],
};
