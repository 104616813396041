import { css } from '@emotion/react';
import {
  colors,
  layout,
  border,
  min,
  max,
  BREAKPOINTS,
  cssGlassFrosted,
  cssGlassFrostedV2,
  cssGlassXXFrosted,
} from 'styles';

export const sidebarConfig = {
  transition: 'transform 300ms cubic-bezier(0.75, 0.25, 0.25, 0.75)',
  width: 300,
  overlay: {
    color: 'rgba(255, 255, 255, 0.33)',
    opacity: 1,
    transition: 'all 0.3s cubic-bezier(0.28, 0.11, 0.32, 1)',
  },
};

export const styles = css`
  .sidebar-overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
    pointer-events: none;
    opacity: 0;
    background-color: ${sidebarConfig.overlay.color};
    transition: ${sidebarConfig.overlay.transition}!important;
    ${cssGlassFrosted}
  }

  pre {
    font-size: 0.6em !important;
  }

  aside {
    position: fixed;
    left: 0;
    top: ${layout.header.height};
    bottom: 0;
    z-index: 20;
    ${border}
    width: ${sidebarConfig.width}px;
    max-width: 100vw;
    overflow-y: hidden;
    overflow-x: hidden;
    padding: 0 ${layout.padding} ${layout.padding} ${layout.padding};
    /*padding-left: ${sidebarConfig.width};*/
    background: ${colors.white};
    transition: ${sidebarConfig.overlay.transition}!important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    nav {
      flex-grow: 1;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 2em calc(${layout.padding}*1.5);
      li {
        padding: 0.33em 0;
        font-size: 1.2em;
        color: ${colors.grey};
        & > div > div:nth-of-type(2) {
          padding-left: 0.2em !important;
        }
        .switch {
          transform: scale(0.66);
          cursor: pointer;
          &.checked-true {
            color: ${colors.primary};
            &:hover {
              color: ${colors.primaryDark};
            }
          }
          &.checked-false {
            color: ${colors.greyDark};
            &:hover {
              color: ${colors.primary};
            }
          }
        }
      }
    }

    header {
      height: ${layout.header.height};
      padding: ${layout.padding} calc(${layout.padding} * 0.5);
      padding-left: 1em;
      & > div {
        height: 100%;
      }
      h2 {
        margin: 0;
      }
    }
    transition: ${sidebarConfig.transition};
    transform: translateX(-100%);
  }

  &.sidebar-open {
    tranform: translateX(500px) !important;
    .sidebar-overlay {
      /* transition: all 1s ease !important;*/
      opacity: ${sidebarConfig.overlay.opacity};
      pointer-events: auto;
    }
    aside {
      transform: translateX(0%);
      box-shadow: 2px 0px 10px 0px rgba(0, 0, 0, 0.15);
    }
  }

  /********** RESPONSIVE **********/

  ${max.sm} {
    aside {
      width: 100vw;
      top: 0;
      z-index: 1000;
      text-align: center;
      header {
        padding: ${layout.padding} calc(${layout.padding} * 1.5);
      }
    }

    .sidebar-overlay {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 10;
      pointer-events: none;
      opacity: 0;
      ${cssGlassXXFrosted}
    }
  }
`;
