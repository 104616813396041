// NOTE: util to determin is Registration Form is complete + ready for Validation

export const getIsRegistrationComplete = (data: any) => {
  const REQUIRED_FOR_REGISTRATION = [
    'email',
    'name_first',
    'name_last',
    'tel',
    'postal_code',
    'city',
    'province',
    'country',
    'document_type_personal',
    'document_number_personal',
    'address_street_type',
    'address_street_name',
    'address_street_number',
  ];

  const hasMissingValues = REQUIRED_FOR_REGISTRATION.some((key) => !data?.[key]);

  return !hasMissingValues;
};
