import { css } from '@emotion/react';
import { colors } from 'styles/colors.css';

export const styles = css`
  input {
    font-family: monospace;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    /* border-bottom: 0 !important; */
  }
  .iframe-wrapper {
    /* border: 2px solid ${colors.grey}; */
    background-color: rgba(0, 0, 0, 0.6);
    position: relative;
    width: 100%;
    padding-top: 50%;

    .iframe-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        color: white;
        display: inline-block;
        width: 15%;
        height: 15%;
        opacity: 0.8;
      }

      .spinner {
        transform: scale(1.2);
        & > div div {
          background-color: #ffffff;
        }
      }
    }
    iframe {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }

  &.has-error {
    input,
    .iframe-wrapper {
      border-color: ${colors.danger};
    }
    input:focus,
    input:focus + .iframe-wrapper {
      border-color: ${colors.warning};
    }
  }
`;
