import type { RouteObject } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import DetailPage from 'pages/DetailPage';
import EventsPage from 'pages/EventsPage';
import HomePage from 'pages/HomePage';
import InfoPage from 'pages/InfoPage';
import * as eventos from 'config/datatype/eventos';

import type { GlobalStoreValues } from 'store/GlobalSchema.types';
import { getWebCategoryRoutes } from './getWebCategoryRoutes';

export const getWebRoutes = ({ store }: { store: GlobalStoreValues }): RouteObject[] => {
  const routesWeb: RouteObject[] = [
    {
      id: 'public',
      path: '/',
      children: [
        {
          index: true,
          element: <HomePage />,
        },
        { path: 'home', element: <Navigate to="/" replace /> },
        { path: 'about', element: <InfoPage /> },
        { path: 'cat', children: [...getWebCategoryRoutes({ store })] },
        {
          path: 'eventos',
          children: [
            { index: true, element: <EventsPage dataConfig={eventos} /> },
            { path: 'view/:id', element: <DetailPage dataConfig={eventos} /> },
          ],
        },
      ],
    },
  ];

  return routesWeb;
};
