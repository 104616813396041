import { css } from '@emotion/react';
import Color from 'color';
/*
NOTE: CSS-in-JS Best Practices
https://emotion.sh/docs/best-practices#use-the-style-prop-for-dynamic-styles

https://kentcdodds.com/blog/colocation
Colocation by Kent C, Dodds (2019)

https://douges.dev/blog/taming-the-beast-that-is-css-in-js
Change how you write your CSS-in-JS for better performance by Michael Dougall (2021)
*/

// SANITIZE.CSS RESETS
import 'sanitize.css/sanitize.css';
import 'sanitize.css/typography.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/assets.css';
// REMOVING reduce-motion.css - BREAKS Toaster !! 😟
// import 'sanitize.css/reduce-motion.css';

import { colors } from './colors.css';
import { cssFontDefaults, cssFontMono } from './fonts.css';
import { cssForms } from './forms.css';
import { layout, cssLayout } from './layout.css';
import { cssToaster, cssMenu, cssDatePicker } from './vendor.ui.css';

export const border = css`
  border-color: ${colors.greyXLight};
  border-style: solid;
  border-width: 0px;
`;

export const cssGlobal = css`
  /* core */
  :root {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  html,
  body,
  * {
    ${cssFontDefaults}
  }

  ${cssLayout}
  ${cssForms}

  /* vendor ui */
  ${cssToaster}
  ${cssMenu}
  ${cssDatePicker}

  h1 {
    color: ${colors.default};
  }
  h2 {
    color: ${colors.primary};
  }
  h3 {
    color: ${colors.textLight};
  }
  h4 {
    color: white;
    margin: 1.33em 0 0.33em;
  }

  h1,
  h2,
  h3,
  h4 {
    span {
      opacity: 0.66;
    }
  }

  p {
    color: ${colors.text};
    font-weight: 500;
    line-height: 1.75;
  }
  a {
    color: ${colors.primary};
    text-decoration: none;
    &:hover {
      color: ${colors.primaryXDark};
    }
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    color: ${colors.textLight};
  }
  pre {
    font-size: 0.8em;
    line-height: 1.5;
    color: ${colors.textLight};
  }
  hr {
    border: none;
    border-bottom: ${layout.borderWidth} dashed ${colors.greyLight};
    margin: calc(${layout.padding} * 1.2) 0;
    opacity: 0.5;
  }

  header.page-header,
  section {
    padding: calc(${layout.padding} * 1) calc(${layout.padding} * 0.75) calc(${layout.padding} * 2);
    padding-top: calc(${layout.padding} * 1) !important;
    h1 {
      font-size: 2.8em !important;
      margin-top: 0.4em !important;
      margin-bottom: 0.4em !important;
      color: ${colors.primary};
      span {
        color: ${colors.greyLight};
        color: ${Color(colors.primary).lighten(0.66).desaturate(0.66).hex()};
        opacity: 1;
      }
    }
    h1 + h2 {
      margin-bottom: 0.4em !important;
      color: ${colors.greyDark};
      span {
        color: ${colors.greyLight};
        opacity: 1;
      }
    }
    h1 + p,
    h1 + h2 + p {
      font-size: 1.25em;
      margin-top: 0 !important;
      margin-bottom: 2.5em;
    }
  }

  article {
    padding: ${layout.padding};
  }

  span.default {
    color: ${colors.default}!important;
  }
  span.primary {
    color: ${colors.primary}!important;
  }
  span.secondary {
    color: ${colors.secondary}!important;
  }
  span.info {
    color: ${colors.info}!important;
  }
  span.success {
    color: ${colors.success}!important;
  }
  span.warning {
    color: ${colors.warning}!important;
  }
  span.danger {
    color: ${colors.danger}!important;
  }

  button {
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
    background: ${colors.primary};
    margin: 0;
    padding: 0.5em;
  }
  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
  }
  form fieldset:disabled {
    pointer-events: none;
  }

  label {
    display: inline-block;
    font-weight: 700;
    color: ${colors.primaryXLight};
    margin: 0.5em 0.5em 0.7em 0.1em;
    span {
      // NOTE: REMOVED: - 2024-04-14
      /* opacity: 0.5; */
      letter-spacing: 0;
    }
  }

  pre,
  code {
    ${cssFontMono}/* font family default */
  }
`;
