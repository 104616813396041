import { INPUTS } from 'types/form.types';
import { USER_ROLE } from 'auth/types/user.types';
import type { FormConfig, FormButton } from 'components/Form';
import { ButtonTypeEnum, BUTTONS } from 'components/Form/components/FormButtonBar';
import { NODE_ENV } from 'config';
import { DATATYPE, ENDPOINT } from './login.config';

let i = 0;

const buttons: FormButton[] = [
  {
    type: ButtonTypeEnum.submit,
    label: 'ENTER',
    action: BUTTONS.SUBMIT,
  },
];

if (NODE_ENV === 'development') {
  buttons.push({
    type: ButtonTypeEnum.button,
    label: 'DEV_FILL',
    action: BUTTONS.MOCK,
  });
  buttons.push({
    type: ButtonTypeEnum.button,
    label: 'DEV_FILL_ADMIN',
    action: BUTTONS.MOCK_ADMIN,
  });
}

export const formConfig: FormConfig = {
  dataType: { key: DATATYPE, role: 'form' },
  api: { endpoint: ENDPOINT },
  isNavPromptActive: false,
  buttons,
  sections: [
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      fields: [
        {
          name: 'email',
          label: 'User email',
          hint: 'valid email address',
          inputType: INPUTS.email,
          placeholder: 'email',
          // pattern: patterns.email,
          required: true,
          mockValue: 'u000@test.com' /*                  NOTE: TEST
          // mockValue: 'valencia@sevilla.com' /*        NOTE: REGISTERED        */,
          mockValueAsAdmin: 'zaragoza@valencia.com' /*   NOTE: ADMIN             */,
          // mockValue: 'avila@teruel.com',/*            NOTE: ABC123 (invalid)  */
          // mockValue: 'MOCK_EMAIL',
          colWidth: {
            xs: 12,
          },
          props: {
            autoComplete: 'username',
          },
        },
        /* eslint-enable prettier/prettier */
        {
          name: 'password',
          label: 'Password',
          hint: 'minimum 6 characters, one symbol',
          inputType: INPUTS.password,
          placeholder: 'password',
          // pattern: patterns.password,
          minLength: 6,
          required: true,
          // mockValue: 'simpleDevPass',
          mockValue: 'Pass123',
          mockValueAsAdmin: 'Pass123',
          colWidth: {
            xs: 12,
          },
          props: {
            autoComplete: 'current-password',
          },
        },
        {
          name: 'uuid',
          inputType: INPUTS.uuid,
          mockValue: 'MOCK_UUID',
          isHidden: true,
        },
        {
          name: 'user_role',
          label: 'USER ROLE',
          inputType: INPUTS.text,
          defaultValue: USER_ROLE.REGISTERED,
          mockValue: USER_ROLE.REGISTERED, // NOTE: REGISTERED
          mockValueAsAdmin: USER_ROLE.ADMIN, // NOTE: ADMIN
          isHidden: true,
        },
      ],
    },
  ],
};
