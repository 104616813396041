import type { RouteObject } from 'react-router-dom';
import { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import type { IGeneric, DataEntry, CatMeta } from 'types';
import type { RouteConfig } from 'routes';
import { getRouterRoutesInitialized } from 'routes/utils/routes.utils';
import { getFlatRoutesMeta } from './useRoutesMeta.utils';
import { getRoutesConfigured } from './getRoutesConfigured-V1';

import { useGlobal } from 'store/useGlobalContext';
import config from 'config';
import { useAuth } from 'auth/AuthContext';

export const useRoutesMeta = (
  { isFetchEnabled, metaCats }: { isFetchEnabled: boolean; metaCats?: CatMeta[] } = {
    isFetchEnabled: false,
    metaCats: [],
  },
): any => {
  const { user } = useAuth();
  const { categories, routesConfiguration } = useGlobal();

  const { routes, setRoutes } = useGlobal('routes');
  const { metaRoutes, setMetaRoutes } = useGlobal('metaRoutes');
  const { setMetaRoutesTree } = useGlobal('metaRoutesTree');

  const [isBusy, setIsBusy] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  // const areRouteMetaSourcesReady = !!(
  //   isFetchEnabled &&
  //   categories.length &&
  //   metaCats.length &&
  //   Object.entries(routesConfiguration).length
  // );

  const areRouteMetaSourcesReady = !!(isFetchEnabled && categories.length && metaCats.length);

  useEffect(() => {
    // log('ROUTES_READY', 'magenta', areRouteMetaSourcesReady);
  }, [
    areRouteMetaSourcesReady,
    isFetchEnabled,
    categories?.length,
    metaCats?.length,
    Object.entries(routesConfiguration)?.length,
  ]);

  useEffect(() => {
    if (areRouteMetaSourcesReady || isUpdating) {
      const routesInitialized: RouteObject[] = getRouterRoutesInitialized({
        categories,
        routesConfiguration,
      });
      // TODO: should compare to `routes`, NOT `metaRoutes`..
      const isOutDated = !isEqual(routesInitialized, routes /*  metaRoutes */);
      if (isOutDated) {
        log('ROUTES_UPDATE', 'red', areRouteMetaSourcesReady);
        setRoutes(routesInitialized);
      }
    }
  }, [areRouteMetaSourcesReady, routesConfiguration, isUpdating]);

  // 2. SET ROUTES META: use previously fetched routesConfiguration metadata,
  // and generate FLAT `routesMeta` = FULL ROUTES CONFIGURATION
  useEffect(() => {
    if (isFetchEnabled && routes?.length) {
      const metaRoutesTree: RouteConfig[] = getRoutesConfigured({ routes, routesConfiguration, metaCats });
      const newMetaRoutes: RouteConfig[] = getFlatRoutesMeta(cloneDeep(metaRoutesTree));

      if (!isEqual(newMetaRoutes, metaRoutes)) {
        setMetaRoutes(newMetaRoutes);
        setMetaRoutesTree(metaRoutesTree);
      }
      setIsUpdating(false);
      setIsBusy(false);
    }
  }, [routes, user]);

  // NOTE: EXTERNAL USE ONLY
  const getDataWithsPathsApplied = ({ data, slug }: { data: DataEntry[]; slug?: string }): DataEntry[] => {
    if (!data.length) return data;

    return data.map((dataItem: IGeneric) => {
      const dataPath =
        slug ??
        metaRoutes.find((routeMeta: RouteConfig) => routeMeta?.category?.uuid === dataItem?.uuid)?.path;

      return {
        ...dataItem,
        path: `${config.PATH_BASE}/${dataPath}/view/${dataItem?.id}`,
      };
    });
  };

  return {
    isFetchingRoutes: isBusy || isUpdating,
    getDataWithsPathsApplied,
  };
};
