import { Col, Row } from 'react-grid-system';
import type { ReactNode } from 'react';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { getFieldWidth } from 'components/FormField/formField.utils';
import MapGoogle from 'components/MapGoogle/MapGoogle';
import { Spinner } from 'components/Spinner';
import { slugify, getImageURL, toUpperCaseFirst } from 'utils';
import { getFakerImageURLv3 } from 'mocks/generators/data.images';
import { detailConfig as defaultDetailConfig } from 'config/datatype/posts';
import { DetailRow } from './DetailRow';
import type { DetailSection, DetailSummaryProps, FieldConfig } from './DetailSummary.types';
import { useDetailConfig } from './useDetailConfig';
import { styles } from './DetailSummary.css';

// GREAT REF: REACT-QUERY + REACT-HOOK-FORM:
// https://tkdodo.eu/blog/react-query-and-forms#invalidate-and-reset-after-mutation

export const DetailSummary = ({ detailConfig = defaultDetailConfig, data = {} }: DetailSummaryProps) => {
  // ⭐️ == NEW: CONFIGURE FIELDS HOOK
  const { sections, values } = useDetailConfig({ detailConfig, data }); // TODO: schema needed as prop ?? don't think so...

  if (!sections || !values) return <Spinner page />;

  const hero = detailConfig?.hero;
  // const imagePreview = getImageURL({ image: JSON_META.image, image_id: JSON_META.image_id });
  // const imagePreview =
  //   getImageURL({ image: data.image as any, image_id: data.image_id as string }) || getFakerImageURLv3();
  const imagePreview = getFakerImageURLv3();

  // log('DETAIL_DATA:', 'grey', data);

  const geoLocationQuery: string[] = [];
  values.country && geoLocationQuery.push(values.country as string);
  values.province && geoLocationQuery.push(values.province as string);
  values.city && geoLocationQuery.push(values.city as string);
  values.location && geoLocationQuery.push(values.location as string);

  return (
    <div css={styles}>
      <Row>
        <Col xs={12} sm={12} md={hero?.hasMap ? 6 : 12} className="col col-image">
          {imagePreview && (
            <div className="image-holder" style={{ background: `url(${imagePreview}?r=${uuid()})` }} />
          )}
        </Col>
        {hero?.hasMap && !!geoLocationQuery.length && (
          <Col xs={12} sm={12} md={6} className="col col-map">
            <MapGoogle location={geoLocationQuery as string[]} marker={values?.event_type as string} />
          </Col>
        )}
      </Row>
      <Row>
        <>
          {sections.map((detailSection: DetailSection): ReactNode => {
            // NOTE: "isEmptySection" = NONE OF THE FIELDS IN THE SECTION HAVE VALUES
            const isEmptySection = !detailSection.fields.find((field: FieldConfig) => field.value);
            if (detailSection.fields.length && !isEmptySection) {
              return (
                <Col
                  key={uuid()}
                  style={detailSection.isHiddenSeparator ? { borderTop: 'none' } : {}}
                  {...getFieldWidth(detailSection)}
                >
                  <section>
                    <h2>{toUpperCaseFirst(detailSection?.sectionTitle) || 'Info'}</h2>
                    {detailSection.fields.map((field: FieldConfig, i: number): ReactNode => {
                      if (!field || !field.name || !field.value) return null;
                      return (
                        <React.Fragment key={`${slugify(detailSection?.sectionTitle)}-${i}` || i}>
                          <DetailRow field={field} />
                        </React.Fragment>
                      );
                    })}
                  </section>
                </Col>
              );
            }
            return <React.Fragment key={uuid()} />;
          })}
        </>
      </Row>
    </div>
  );
};
