import { DEFAULT_VALUE, INPUTS } from 'types/form.types';
import { USER_ROLE } from 'auth/types/user.types';
import type { FormConfig, FormButton } from 'components/Form';
import { ButtonTypeEnum, BUTTONS } from 'components/Form/components/FormButtonBar';
import { patterns } from 'components/Form/config/form.validation';
import { NODE_ENV } from 'config';
import { DATATYPE, ENDPOINT } from './login.config';

let i = 0;

const buttons: FormButton[] = [
  /*
  {
    type: types.submit,
    // label: 'REGISTER',
    // label: t('t.register.buttons.register'),
    label: 't.register-owner.buttons.register',
    action: BUTTONS.SUBMIT,
  },
  {
    type: types.submit,
    label: 't.register-owner.buttons.register-medio',
    action: BUTTONS.SUBMIT,
  },
  {
    type: types.submit,
    label: 't.register-owner.buttons.register-entidad',
    action: BUTTONS.SUBMIT,
  },
  */
  {
    type: ButtonTypeEnum.submit,
    // label: 'REGISTER',
    // label: t('t.register.buttons.register'),
    label: 't.register-owner.buttons.register',
    action: BUTTONS.SUBMIT,
  },
];

if (NODE_ENV === 'development') {
  buttons.push({
    type: ButtonTypeEnum.button,
    label: 'DEV_FILL',
    action: BUTTONS.MOCK,
  });
  buttons.push({
    type: ButtonTypeEnum.button,
    label: 'DEV_FILL_ADMIN',
    action: BUTTONS.MOCK_ADMIN,
  });
}

export const formConfig: FormConfig = {
  dataType: { key: DATATYPE, role: 'form' },
  api: { endpoint: ENDPOINT },
  isNavPromptActive: false,
  isNewEntry: true,
  buttons,
  sections: [
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      fields: [
        {
          name: 'email',
          label: 'User email',
          hint: 'valid email address',
          inputType: INPUTS.email,
          placeholder: 'email',
          pattern: patterns.email,
          required: true,
          // mockValue: 'u010@test.com',
          mockValue: 'u000@test.com',
          // mockValue: 'MOCK_EMAIL',
          colWidth: {
            xs: 12,
          },
          props: {
            autoComplete: 'username',
          },
        },
        {
          name: 'password',
          label: 'Password',
          hint: 'minimum 6 characters, one symbol',
          inputType: INPUTS.password,
          placeholder: 'password',
          minLength: 6,
          required: true,
          // pattern: patterns.password,
          mockValue: 'Pass123',
          colWidth: {
            xs: 12,
          },
          props: {
            autoComplete: 'new-password',
          },
        },
        {
          name: 'accept',
          label: 'Soy titular de un medio, canal, asociación o entidad.',
          // label: 'Accepto',
          // hint: 'Soy titular de un medio, canal, asociación o entidad.',
          inputType: INPUTS.checkbox,
          required: true,
          colWidth: {
            xs: 12,
          },
        },
        {
          name: 'uuid',
          inputType: INPUTS.uuid,
          // mockValue: 'admin001-e89b-12d3-a456-426614174XXX',
          defaultValue: DEFAULT_VALUE.NEW_UUID,
          mockValue: 'MOCK_UUID',
          isHidden: true,
          readOnly: true,
          colWidth: {
            xs: 12,
          },
        },
        {
          name: 'user_role',
          label: 'USER ROLE',
          inputType: INPUTS.text,
          defaultValue: USER_ROLE.PENDING,
          mockValue: USER_ROLE.PENDING, // NOTE: REGISTERED
          // defaultValue: USER_ROLE.REGISTERED,
          // mockValue: USER_ROLE.REGISTERED, // NOTE: REGISTERED
          mockValueAsAdmin: USER_ROLE.ADMIN, // NOTE: ADMIN
          isHidden: true,
          readOnly: true,
          colWidth: {
            xs: 12,
          },
        },
      ],
    },
  ],
};
