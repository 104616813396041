import type { RouteObject } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import type { CatMeta } from 'types';
import type { RouteConfig, AccessRules, ParentRoutesByGroup } from 'routes/routes.types';
import { getRouterRoutes, genRouteObjectsFromConfig } from './router.utils.routes';
import { genCategoryRouterRoutes } from './routes.utils.categories';
import { DEFUALT_ROUTE_ACCESS } from '../routes.constants';

// ======================================================================== //
// NOTE: USED TO GENERATE NAV ITEMS (ie. NavHorizontal.tsx)

export const getRouterRoutesInitialized = ({
  categories,
  routesConfiguration,
}: {
  categories: CatMeta[];
  routesConfiguration: RouteConfig[];
}): RouteObject[] => {
  const routesLeft: RouteObject[] = genRouteObjectsFromConfig({ routesConfiguration }, []);
  const routesRight: RouteObject[] = genCategoryRouterRoutes({ categories, basePath: 'cat' }, []);

  const { ROUTES } = getRouterRoutes();
  ROUTES[0].children?.push(...routesLeft);
  ROUTES[0].children?.push(...routesRight);

  return ROUTES;
};

// ======================================================================== //

export const getRouteDefaultPermissions = () => ({ ...DEFUALT_ROUTE_ACCESS });

// DEPRECATED: ??
export const getRoutePermissions = ({ route, metaRoutes }: { route: RouteConfig; metaRoutes: any }) => {
  const matchedRouteMeta: RouteConfig =
    metaRoutes.find((meta: RouteConfig) => meta.slug === route.slug) ?? {};

  return {
    access: {
      ...getRouteDefaultPermissions(),
      ...matchedRouteMeta?.access,
    } as AccessRules,
  };
};

// 4.
export const getActiveRoutes = (routes: RouteConfig[]): RouteConfig[] => {
  const routesMain = routes[0]?.children?.slice(1).filter((route: RouteConfig) => route.isActive);

  return routesMain || [];
};

// NEW: BETTER METHOD TO SET ROUTE_META ====================================== //
// CLEANED / MINIMAL RouteConfig (without Access rules, etc..)

export const getRouteConfigCleaned = (
  matchSlug: string | undefined,
  metaRoutes: RouteConfig[],
  removeKeys: (keyof RouteConfig)[] = ['access', 'hasChildren', 'isActive'],
): RouteConfig => {
  const matchedRouteMeta = cloneDeep(
    metaRoutes.find((route: RouteConfig) => route.slug === `${matchSlug}`),
  ) as any;
  for (const key of removeKeys) {
    delete matchedRouteMeta?.[key];
  }

  return matchedRouteMeta as RouteConfig;
};

// ======================================================================== //

export const cleanRoutePath = (path: string) => {
  const cleaned = path
    .replace(/undefined/g, '')
    .replace(/\/\/\//g, '/')
    .replace(/\/\//g, '/');

  return cleaned;
};

// ======================================================================== //

export const cleanRoutesOfElements = (routes: RouteObject[]): any[] => {
  const cleanRoute = (route: RouteObject): any => {
    const { element, children, ...rest } = route;
    const cleanedRoute: any = { ...rest };

    if (children) {
      cleanedRoute.children = children.map(cleanRoute);
    }

    return cleanedRoute;
  };

  return routes.map(cleanRoute);
};

// ======================================================================== //

export const getHasDistinctChildren = (route: RouteConfig): boolean => {
  const topUUID = route.uuid;

  const checkChildren = (children: RouteConfig[]): boolean => {
    for (const child of children) {
      if (child.uuid && child.uuid !== topUUID) {
        return true;
      }
      if (child.children && checkChildren(child.children)) {
        return true;
      }
    }
    return false;
  };

  if (route.children) {
    return checkChildren(route.children);
  }

  return false;
};

// ======================================================================== //

export const extractRouteByPath = (
  routes: RouteConfig[],
  { path }: { path: string } = { path: '' },
): [removed: RouteConfig[], remaining: RouteConfig[]] => {
  const removed: RouteConfig[] = [];
  const remaining: RouteConfig[] = [];

  const traverseRoutes = (routes: RouteConfig[]): RouteConfig[] => {
    return routes.reduce((acc: RouteConfig[], route: RouteConfig) => {
      if (route.path === path) {
        removed.push(route);
      } else {
        if (route.children) {
          const [childRemoved, childRemaining] = extractRouteByPath(route.children, { path });
          if (childRemoved.length > 0) {
            removed.push(...childRemoved);
            route.children = childRemaining;
          }
        }
        acc.push(route);
      }
      return acc;
    }, []);
  };

  remaining.push(...traverseRoutes(routes));

  return [removed, remaining];
};
