import { FiEdit3 as IconEdit } from 'react-icons/fi';
import { RiZoomInLine as IconView } from 'react-icons/ri';
import type { CatMeta } from 'types';
import { useRouter } from 'routes/hooks';
import { useGlobal } from 'store/useGlobalContext';
import { ButtonLink } from 'components/Button';
import { DataEntry, ROW_ACTION } from '../DataTable.types';
import { cleanRoutePath } from 'routes/utils/routes.utils';
import { RouteConfig } from 'routes/routes.types';

export const ActionLink = ({ data, action = ROW_ACTION.VIEW }: { data: DataEntry; action?: ROW_ACTION }) => {
  const { PATH_BASE, basePath } = useRouter();
  const { metaCats } = useGlobal('metaCats');
  const { metaRoutes } = useGlobal('metaRoutes');
  const targetCat = metaCats?.find((cat: CatMeta) => cat.uuid === data?.cat_id);
  const targetRoute = metaRoutes?.find((route: RouteConfig) => route?.uuid === data?.cat_id);

  const baseHref = PATH_BASE === `/${basePath}` ? PATH_BASE : `${PATH_BASE}/${basePath}`;
  const __href = cleanRoutePath(`${baseHref}/${targetCat?.path}/${action}/${data.id}`);
  const _href = cleanRoutePath(`${baseHref}/${targetCat?.pathname}/${action}/${data.id}`);
  const href = cleanRoutePath(`${targetRoute.pathname}/${action}/${data.id}`);

  // log('PATH_BASE', 'blue', targetRoute);

  return (
    <div className="col-inner col-action">
      {action === ROW_ACTION.EDIT && (
        <ButtonLink
          to={href}
          state={{ from: basePath, action: ROW_ACTION.EDIT }}
          variant="clear"
          color="grey"
          colorHover="successDark"
          icon={<IconEdit />}
          iconScale={1.8}
        />
      )}
      {action === ROW_ACTION.VIEW && (
        <ButtonLink
          to={href}
          state={{ from: basePath, action: ROW_ACTION.VIEW }}
          variant="clear"
          color="grey"
          colorHover="successDark"
          icon={<IconView />}
          iconScale={1.8}
        />
      )}
    </div>
  );
};
