import { css } from '@emotion/react';
import { colors } from 'styles/colors.css';

export const styles = css`
  /* TODO: NEEDED ?? - CHECK ADMIN FORMS */
  /* button.react-datepicker */

  .react-datepicker-popper {
    box-shadow: 2px 2px 6px 1px rgba(50, 50, 50, 0.3);
  }

  .react-datepicker__input-container {
    .react-datepicker__close-icon {
      &::after {
        transform: translateX(-40%) scale(1.1);
        opacity: 0.5;
        font-size: 1em;
        line-height: 0.9;
        background-color: ${colors.primary};
        /* &:hover {
          background-color: ${colors.dangerDark}!important;
          opacity: 0.66;
        } */
      }
    }
    .react-datepicker__close-icon:hover {
      &::after {
        background-color: ${colors.dangerDark}!important;
        opacity: 0.66;
      }
    }
  }

  input.input-datepicker {
    ::-webkit-input-placeholder {
      text-transform: uppercase;
    }
    ::-moz-placeholder {
      text-transform: uppercase;
    }
    :-ms-input-placeholder {
      text-transform: uppercase;
    }
    ::placeholder {
      text-transform: uppercase;
    }
    ::-webkit-datetime-edit {
      text-transform: uppercase;
    }
  }

  svg.react-datepicker__triangle {
    display: none !important;
  }

  /* .react-datepicker__month-container{

  } */

  /* .input-calendar,
  .input-datepicker {
    background-color: ${colors.warningDark}!important;
  } */
  /*
    &__triangle {
      transform: translate(125px, 0px) !important;
    }
    $__close-icon {
      padding-right: 1.2em;
      opacity: 0.5;
      &:after {
        background-color: ${colors.grey};
        transform: scale(1.3);
      }
      &:hover {
        opacity: 0.8;
      }
    }
    */
`;
