export const ENDPOINT = 'posts';
export const DATATYPE = 'posts';

export const POSTS_EXAMPLE_DYNAMIC_SCHEMA = {
  id: 436,
  name: 'id fugiat',
  cat_id: '123e0000-e89b-12d3-a456-000000000001',
  user_id: '5de8ebba-24e9-430a-884b-82b2789829ba',
  json_meta: {
    user_id: '5de8ebba-24e9-430a-884b-82b2789829ba',
    cat_id: null,
    owner_name: 'labore',
    position: 'aute',
    document_type_personal: 'Pasaporte',
    document_number_personal: 'qui',
    nombre: 'qui',
    document_type_biz: 'NIF',
    document_number_biz: 'ut',
    address_street_type: 'sunt',
    address_0: 'c/ id enim 06',
    address_1: '04-07',
    address_2: 'incididunt',
    address_3: 'non',
    address_4: 'anim',
    address_5: 'ullamco',
    address_6: 'do',
    city: 'Molina de Segura',
    postal_code: '40006',
    province: 'Huelva',
    country: 'Afghanistan',
    name: 'id fugiat',
    url: 'http://soledad.es',
    sector: 'periodico-digital',
    language: 'ug',
    description: 'amet',
    activities: 'est',
    email_public: [],
    tel_public: [],
    email_private: [],
    tel_private: [],
    url_multi: [],
  },
  is_active: 1,
  created_at: null,
  updated_at: null,
};
