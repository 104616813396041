import { FieldErrors } from 'react-hook-form';
import { ReactElement } from 'react';
import { ErrorMessage as ErrorRHF } from '@hookform/error-message';
import { MdError as IconError } from 'react-icons/md';
import { styles } from './ErrorMessage.css';

type ErrorMessageProps = {
  fieldName: string;
  errors?: FieldErrors;
  type?: string;
  isErrorVisible?: boolean;
};

// TODO:  STILL RECOMMENDED / ADVANTAGEOUS USE <ErrorMessage /> ???
// TODO: READ DOCS TO OPTIMISE !!  https://www.npmjs.com/package/@hookform/error-message

export const ErrorMessage = ({ fieldName, errors, type, isErrorVisible = false }: ErrorMessageProps): ReactElement => (
  <div className={`error-msg error-${type}`} css={styles}>
    {isErrorVisible && (
      <ErrorRHF
        errors={errors}
        name={fieldName}
        render={({ message }) => (
          <>
            <IconError />
            {message}
          </>
        )}
      />
    )}
  </div>
);
