import { Row, Col, Hidden } from 'react-grid-system';
import { NavLink } from 'react-router-dom';
import { useNavigateState } from 'hooks/useNavigateState';
import { useState, useEffect } from 'react';
import { Menu, MenuItem, MenuButton, MenuDivider } from '@szhsin/react-menu';
import gravatar from 'gravatar';
import { BiUserCircle as IconUser, BiExit as IconLogout, BiHomeAlt as IconHome } from 'react-icons/bi';
import { IoMdArrowDropdown as IconCaretDown } from 'react-icons/io';
import { MdOutlineAdminPanelSettings as IconUserAdmin } from 'react-icons/md';
import { useRouter } from 'routes/hooks';
import { useAuth } from 'auth/AuthContext';
import { NavItem } from 'components/Nav/NavItem';
import { ArrayJSX } from 'utils/ArrayJSX';
import { styles } from './UserApnaes.css';
import { useTranslation } from 'react-i18next';

export const UserLoginApnaes = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { navigate } = useNavigateState();
  const { pathname, isAdminPath } = useRouter();
  const [avatar, setAvatar] = useState();
  const [userCurrent, setUserCurrent] = useState<any>();

  useEffect(() => {
    const updateUserCurrent = async () => {
      const avatarURL = await gravatar.url(user.email, { s: '200', r: 'pg', d: 'retro' });
      setAvatar(avatarURL);
      setUserCurrent(user);
    };

    updateUserCurrent();
  }, [user, location.pathname, localStorage.getItem('auth')]);

  const isAdminUser = !!(userCurrent?.user_role === 'ADMIN');
  const isRegisteredUser = !!(userCurrent?.user_role === 'REGISTERED');
  const IconUserLoggedIn = isAdminUser ? IconUserAdmin : IconUser;

  // log('IS_ADMIN', 'magenta', { isAdminUser, isRegisteredUser });

  // SHORTEN DISPLAY USERNAME, IF REQUIRED (TOO LONG)
  const displayName =
    userCurrent && userCurrent?.email.length <= 20
      ? userCurrent?.email
      : userCurrent?.email.match(/(.+?(?=@))/);

  return (
    <div css={styles}>
      <Row>
        <Col className="col-user">
          <ul className="nav-menu">
            <li>
              <Menu
                menuButton={
                  <MenuButton>
                    <IconUserLoggedIn className="avatar" color="greyLight" />
                    <Hidden xs sm md>
                      <span className="username">{displayName}</span>
                    </Hidden>
                    <IconCaretDown className="has-submenu-caret" />
                  </MenuButton>
                }
              >
                {isAdminPath ? (
                  <>
                    <MenuItem
                      onClick={() => {
                        // location.assign(`${location.origin}`);
                        // navigate(`${location.origin}`);
                        navigate('/');
                      }}
                    >
                      <span className="menu-row">
                        {t('t.headerUser.log-in')}
                        <IconHome className="icon-logout" />
                      </span>
                    </MenuItem>
                    <MenuDivider />
                  </>
                ) : (
                  <>
                    <MenuItem
                      onClick={() => {
                        // location.assign(`${location.origin}/admin`);
                        navigate('/account');
                      }}
                    >
                      <span className="menu-row">
                        <NavItem
                          path="/login"
                          title={t('t.headerUser.log-in')}
                          state={{ from: pathname, action: 'LOG_OUT' }}
                        />
                        <IconUserAdmin className="icon-logout" />
                      </span>
                    </MenuItem>
                    <MenuDivider />
                  </>
                )}
                <MenuItem>
                  <span className="menu-row">
                    <NavItem
                      path="/logout"
                      title={t('t.headerUser.note')}
                      state={{ from: pathname, action: 'LOG_OUT' }}
                    />
                    <IconLogout className="icon-logout" />
                  </span>
                </MenuItem>
              </Menu>
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
};
