import cloneDeep from 'lodash/cloneDeep';
import keyBy from 'lodash/keyBy';
import merge from 'lodash/merge';
import type { IGeneric } from 'types';

/**
 * Filters an array of objects using custom predicates.
 *
 * @param  {Array|Object}  obj: the array to filter
 */
export const isArray = (obj: unknown) => !!Array.isArray(obj);

// SOURCE: https://gist.github.com/jherax/f11d669ba286f21b7a2dcff69621eb72#file-filterarray-js

// ignores case-sensitive
const getValue = (value: unknown) => (typeof value === 'string' ? value.toUpperCase() : value);

/**
 * Filters an array of objects (one level-depth) with multiple criteria.
 *
 * @param  {Array}  array: the array to filter
 * @param  {Object} filters: an object with the filter criteria
 * @return {Array}
 */
export function filterArray(array: IGeneric[], filters: IGeneric) {
  const filterKeys = Object.keys(filters);
  return array.filter((item) => {
    // validates all filter criteria
    return filterKeys.every((key) => {
      // ignores an empty filter
      if (!filters[key].length) return true;
      return filters[key].find((filter: IGeneric) => getValue(filter) === getValue(item[key]));
    });
  });
}

/*
// TYPESCRIPT TWEAK

export const multiFilter = (arr: Object[], filters: Object) => {
  const filterKeys = Object.keys(filters);
  return arr.filter(eachObj => {
    return filterKeys.every(eachKey => {
      if (!filters[eachKey].length) {
        return true; // passing an empty filter means that filter is ignored.
      }
      return filters[eachKey].includes(eachObj[eachKey]);
    });
  });
};
*/

// SOURCE: https://gist.github.com/jherax/f11d669ba286f21b7a2dcff69621eb72#file-filterarray-js

/**
 * Filters an array of objects using custom predicates.
 *
 * @param  {Array}  array: the array to filter
 * @param  {Object} filters: an object with the filter criteria
 * @return {Array}
 */
export function filterArrayDeep(array: IGeneric[], filters: IGeneric) {
  const filterKeys = Object.keys(filters);
  return array.filter((item) => {
    // validates all filter criteria
    return filterKeys.every((key) => {
      // ignores non-function predicates
      if (typeof filters[key] !== 'function') return true;
      return filters[key](item[key]);
    });
  });
}

/**
 * The method `filterArray()` has the following signature:
 *
 * `function filterArray<TInput = any>(array: TInput[], filters: IFilters) => TInput[]`
 *
 * Where the function receives an array as the first argument, and a plain object
 * describing the fields to filter as the last argument.
 * The function returns an array of the same type as the input array.
 *
 * The signature of the filters arguments is the following:
 *
 * `interface IFilters {
 *   [key: string]: (value: any) => boolean;
 * }`
 *
 * Where the `filters` argument is an object that contains a `key: string`
 * and its value is a function with the value of the property to evaluate.
 * As the function predicate is evaluated using the `Array.prototype.every()` method,
 * then it must return a boolean value, which will determine if the item
 * must be included or not in the filtered array.
 */

/*

// FILTER with OR

function filterArrayWithOR<TInput = any>(array: TInput[], filters: IFilters): TInput[] {
  const filterKeys = Object.keys(filters);
  return array.filter(item => {
      // validates any (OR) of the filter criteria
    return filterKeys.some(key => {
      // ignores non-function predicates
      if (typeof filters[key] !== 'function') return true;
      return filters[key](item[key]);
    });
  });
}

*/

export const mergeArraysByKey = (arrayA: IGeneric[], arrayB: IGeneric[], args = { byKey: 'name' }) => {
  const byKey = args?.byKey || 'name';
  const copyOfArrayA = cloneDeep(arrayA);
  const copyOfArrayB = cloneDeep(arrayB);
  const arrayMerged = Object.values(merge(keyBy(copyOfArrayA, byKey), keyBy(copyOfArrayB, byKey))) || [];

  return arrayMerged;
};
