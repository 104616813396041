import { faker, fakerES, fakerEN } from '@faker-js/faker';
import { v4 as uuid } from 'uuid';

// ======================================================================== //
// BASIC..

export const genRandomWord = ({ locale }: { locale?: 'EN' | 'ES' } = {}) => {
  if (locale === 'EN') {
    return fakerEN.lorem.word({ length: { min: 5, max: 7 }, strategy: 'closest' });
  }
  if (locale === 'ES') {
    return fakerES.lorem.word({ length: { min: 5, max: 7 }, strategy: 'closest' });
  }

  return faker.lorem.word({ length: { min: 5, max: 7 }, strategy: 'closest' });
};

export const randomBoolean = () => !!Math.round(Math.random());

export const genUUID = () => uuid();

// ======================================================================== //
// MISC - SPECIFIC to this project

export const getEventName = async () => {
  let newEventName = faker.music.songName() + ' ' + faker.music.songName();
  while (newEventName.includes("'")) {
    newEventName = newEventName = faker.music.songName() + ' ' + faker.music.songName();
  }

  return newEventName;
};

export const getEntityType = () => faker.helpers.arrayElement(['Asociación', 'Medio']);
