import { css } from '@emotion/react';
import { layout } from 'styles/layout.css';
import { colors } from 'styles/colors.css';

export const styles = css`
  /* RESET */
  appearance: none;
  outline: none;
  background: none;
  background-image: none;
  background-color: transparent;
  border: none;

  position: relative;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  user-select: none;

  /* CENTER TEXT */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.btn {
    border-width: ${layout.borderWidth};
    border-style: solid !important;
    margin: 0 calc(${layout.padding} / 2);
  }

  &.size-xs {
    font-size: 1em;
  }
  &.size-sm {
    font-size: 1.1em;
  }
  &.size-md {
    font-size: 1.2em;
  }
  &.size-lg {
    font-size: 1.3em;
  }
  &.size-xl {
    font-size: 1.4em;
  }

  &.icon-only {
    /* padding: 0.8em;
    width: 60px !important;
    height: 60px !important; */
    /* width: 24px;
  height: 40px; */
    padding: 0.5em 1em;
    border-radius: ${layout.borderRadius};
  }

  /* BORDER */
  & {
    &:disabled {
      border-width: ${layout.borderWidth};
      border-style: solid;
    }
    border-radius: ${layout.borderRadius};
  }

  /* SIZE: FONT + PADDING */
  font-size: 1.3em;
  font-weight: 600;
  letter-spacing: -0.02em;
  white-space: nowrap;

  padding: 0.66em;

  &.btn-padded {
    /* padding: 0.5em 2.75em; */
    padding: 0.5em 2em;
  }

  /* UX */
  transition:
    background-color 300ms ease,
    border-color 300ms ease;

  &.variant-solid {
    /* background-color: ${colors.greyLight}; */
  }

  &.variant-outline {
    background-color: transparent;
  }

  /* DISABLED */
  &:disabled {
    opacity: 0.5;
    background-color: ${colors.grey};
    border-color: ${colors.grey};
    cursor: default;
    pointer-events: none;

    &.variant-outline {
      background-color: transparent;
    }
  }

  span {
    /* padding-top: 0.1em; */
  }

  /* ICON */
  svg {
    vertical-align: middle;
  }
  svg + span {
    margin-left: 0.25em;
  }
  span + svg {
    margin-left: 0.25em;
  }

  &.left,
  &.icon-left {
    svg {
      margin-left: -0.5em;
    }
  }

  &.right,
  &.icon-right {
    svg {
      margin-right: -0.5em;
    }
  }
`;
