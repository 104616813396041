/**
 * Gets random int
 * @param min
 * @param max
 * @returns random int - min & max inclusive
 */
export const getRandomInt = (min: number, max: number): number => {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const genIntString = (numDigits: number): string => {
  const min = 1;
  const max = 10 ^ numDigits;
  const intString = String(Math.floor(Math.random() * (max - min + 1)) + min);

  return numDigits ? intString.padStart(numDigits, '0') : intString;
};

export const isInteger = (num: any) => /^-?[0-9]+$/.test(num + '');

export const isNumeric = (value: unknown): value is number => {
  return (typeof value === 'number' || typeof value === 'string') && !isNaN(Number(value));
};
