import { setConfiguration } from 'react-grid-system';
import type { ScreenClass } from 'types/viewport.types';
import { BREAKPOINTS } from './breakpoints';

export const sizes = Object.keys(BREAKPOINTS) as ScreenClass[];

// TODO: enforce keys as breakpoint sizes
type MediaQueries = {
  [key: string]: string | number;
};

// MEDIAQUERIES (VERSION v2) - for use in css-in-js style files
export const min: MediaQueries = {};
export const max: MediaQueries = {};

// SET MIN + MAX UTILS
for (const [key, value] of Object.entries(BREAKPOINTS)) {
  min[key] = `@media (min-width: ${value}px)`;
  max[key] = `@media (max-width: ${value}px)`;
}

const containerBreakpoints = Object.values(BREAKPOINTS).slice(1);
const containerWidths = containerBreakpoints.map((value, index) => {
  // return index === 0 ? containerBreakpoints[1] * 0.95 : value * 0.95;
  return value * 0.95;
});

setConfiguration({ containerWidths });
