import DualCalendar from 'react-datepicker';
import { Col, Row } from 'react-grid-system';
import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { format, lastDayOfMonth } from 'date-fns';
import addMonths from 'date-fns/addMonths';
import type { DataEntry } from 'types';
import { useGlobal } from 'store/useGlobalContext';
import { useDataFilter } from 'store/DataFilterContext';
import { DATE_FILTER, DATE_FORMAT } from 'i18n/datetime';
import { Card } from 'components/Card';
import { DataSearch } from 'components/DataSearch';
import { DetailFilter } from 'components/DetailFilter';
import type { SelectOption } from 'components/FormUI/Select';
import { Select } from 'components/FormUI/Select';
import { Map } from 'components/Map';
import { SPAIN_PROVINCES_SVG as SpainProvincesSVG } from 'components/Map/@svg-maps/spain-provinces';
import { Spinner } from 'components/Spinner';
import { formatDate, formatDateRange } from 'utils';
import { ES } from 'config';
import 'react-datepicker/dist/react-datepicker.css';
import { SPAIN_PROVINCES } from 'constants/spain';
import { DualCalendarHeader } from './DualCalendarHeader';
import { RESET } from './event-form.contants';
import {
  getCurrentCalendarRange,
  getDataByDateRange,
  getEventsByDay,
  getLocationsHighlighted,
  getLocationsOptions,
  getSelectLocationPlaceholder,
} from './event-form.utils';
import { useEventFilters } from '../EventFiltersContext';
import { styles } from './EventForm.css';
import { usePageContentActions } from 'pages/Layout/PageContentContext/PageContent.hooks';

export const EventForm = () => {
  const { setPageSubtitle } = usePageContentActions();

  // DATA + FILTERS
  const { setDataFilter, ...dataFilter } = useDataFilter();
  const { data, dataFiltered, results } = dataFilter;
  const { setFilters, ...filters } = useEventFilters();

  // FORM VALUES
  const formMethods = useFormContext();
  const { register, setValue } = formMethods;

  // ------------------------------------------------------------------------- //

  // FORM UI
  const [calendarStart, setCalendarStart] = useState<string>(`${format(new Date(), DATE_FILTER.MONTH)}-01`);
  const [selectedLocations, setSelectedLocations] = useState<SelectOption[]>([]);
  const [isBusy, setIsBusy] = useState<boolean>(true);

  // ------------------------------------------------------------------------- //

  // DATA RESULTS
  const [dataFilteredByDate, setDataFilteredByDate] = useState<DataEntry[]>([]);
  const [searchResults, setSearchResults] = useState<DataEntry[]>([]);

  // ------------------------------------------------------------------------- //

  const DATE_TODAY = new Date();

  const OPTION_PROVINCE_NO_SELECTION = {
    value: '',
    label: getSelectLocationPlaceholder({ results }),
  };

  const resetLocation = () => {
    setSelectedLocations([]);
    setValue('province', RESET.LOCATIONS);
    setFilters({ province: RESET.LOCATIONS });
  };

  // NEW: SET ALL FILTERS =================================================== //

  const applyFilters = (dataToFilter: DataEntry[]) => {
    if (!isBusy) setIsBusy(true);

    let resultsFiltered = dataToFilter;

    if (filters.dateSelected.isActive) {
      resultsFiltered = getEventsByDay({
        dateSelected: filters.dateSelected,
        dateRange: filters.dateRange,
        data: resultsFiltered,
      });
    }

    if (filters.province.isActive) {
      const isSelectedProvincesInResultsByDate = !!resultsFiltered.find((result: DataEntry) =>
        selectedLocations.find((province) => province.value === result?.province),
      );
      if (!isSelectedProvincesInResultsByDate) {
        // RESET SELECTED LOCATION - DUE TO CHANGE OF OTHER FILTER TYPE
        resetLocation();
      }
      if (isSelectedProvincesInResultsByDate) {
        // SET SELECTED LOCATION - DUE TO SELECTION OF LOCATION, IR CHANGE OF OTHER FILTER TYPE THAT INCLUDES LOCATION
        // const filteredByLocation = dataFilteredByDate.filter((result: DataEntry) =>
        //   selectedLocations.find((province) => province.value === result.province),
        // );
        // setResultsByLocation(filteredByLocation);
      }
    }

    setDataFilteredByDate(resultsFiltered);
    setDataFilter({ results: resultsFiltered });
    setIsBusy(false);
  };

  // ====================================================================== //
  // TODO: DUAL-CALENDAR MONTH NAVIGATION

  const getCalendarPagination = (pageChangeDir: number) => {
    const newCalendarStartDate = addMonths(new Date(calendarStart), pageChangeDir);
    const newCalendarStart = `${format(newCalendarStartDate, DATE_FILTER.MONTH)}-01`;

    log('📆 CALENDAR NAVIGATION:', 'white', { pageChangeDir, newCalendarStart });
    setCalendarStart(newCalendarStart);

    return newCalendarStartDate;
  };

  const handleChangeMonth = (pageChangeDir: number) => {
    const newCalendarStartDate = getCalendarPagination(pageChangeDir);
    const { startDate, endDate } = {
      startDate: format(newCalendarStartDate, DATE_FILTER.DEFAULT),
      endDate: format(lastDayOfMonth(addMonths(newCalendarStartDate, 1)), DATE_FILTER.DEFAULT),
    };
    setValue('dateRange', { startDate, endDate });
    setValue('dateSelected', RESET.DATE_SELECTED);
    setFilters({ dateRange: { startDate, endDate }, dateSelected: RESET.DATE_SELECTED });
  };

  // https://reactdatepicker.com/ --> SEE "Custom header with two months displayed"

  const localize = (date: string | Date) => formatDate(date, DATE_FORMAT.LONG);

  // ======================================================================== //

  const handleSelectDate = (dateSelected: Date | null) => {
    if (dateSelected) {
      const value = formatDate(dateSelected, DATE_FILTER.DEFAULT);
      if (filters.dateSelected.value !== value) {
        const localized = localize(dateSelected);
        const datetime = {
          startDate: `${value} 00:00:00`,
          endDate: `${value} 23:59:59`,
        };
        // applyFilters(dataFiltered); // TODO: INCLUDE
        setValue('dateSelected', { value, localized, datetime, isActive: !!value });
        setFilters({ dateSelected: { value, localized, datetime, isActive: !!value } });
      } else {
        setValue('dateSelected', RESET.DATE_SELECTED);
        setFilters({ dateSelected: RESET.DATE_SELECTED });
      }
    } else {
      setValue('dateSelected', RESET.DATE_SELECTED);
      setFilters({ dateSelected: RESET.DATE_SELECTED });
    }
  };

  const handleSelectProvice = (selection: string | null) => {
    const { value, label } =
      SPAIN_PROVINCES.find((option: SelectOption) => option.value === selection) ??
      OPTION_PROVINCE_NO_SELECTION;
    /* eslint-disable prettier/prettier */
    const province =
      value && filters.province?.value
        ? { value, label, isActive: filters.province.isActive }
        : value
          ? { value, label, isActive: true }
          : RESET.LOCATIONS;
    /* eslint-enable prettier/prettier */
    setSelectedLocations([{ value, label }]);
    setValue('province', { ...province });
    setFilters({ province });
  };

  // ======================================================================== //
  // NEW: USE-EFFECTS ======================================================= //
  // ======================================================================== //

  // ✅ 1. INITIALIZE
  useEffect(() => {
    // INIT DATA: FULL FETCH + DATE-RANGE RESULTS
    if (data && !filters.dateRange.startDate && !filters.dateRange.endDate) {
      const dateRange = getCurrentCalendarRange(DATE_TODAY);
      setFilters({ ...filters, dateRange });
    }
  }, [data]);

  // ✅ 2. SET SUBTITLE + CALENDAR EVENT DAYS
  useEffect(() => {
    if (filters.dateRange) {
      setIsBusy(true);
      // SET PAGE SUBTITLE WITH DATE RANGE
      const { startDate, endDate } = formatDateRange(filters.dateRange, DATE_FORMAT.MONTH);
      const dateRangeSubtitle = `${startDate} y ${endDate}`;
      setPageSubtitle(dateRangeSubtitle);

      // SET EVENTS: FILTERED BY DATE
      // TODO: FIX PAST EVENTS - AS THEY BUILD UP - ONLY INCLUDE DATE-RANGE
      // const includePastEvents = filters.dateRange.startDate > formatDate(DATE_TODAY, DATE_FORMAT.DEFAULT);

      const includePastEvents = false;
      const dataFilteredByDateRange = getDataByDateRange({
        dateRange: filters.dateRange,
        data,
        includePastEvents,
      });

      // NOTE: SHOULD SELECTED PROVINCE PERSIST ON DATE CHANGE ??
      const isSelectedProvincesInDataByDateRange = !!(
        filters.province.isActive &&
        dataFilteredByDateRange.find((result: DataEntry) =>
          selectedLocations.find((province) => province.value === result?.province),
        )
      );
      if (!isSelectedProvincesInDataByDateRange) {
        resetLocation();
      }

      setDataFilter({ dataFiltered: dataFilteredByDateRange });
      setIsBusy(false);
    }
  }, [filters.dateRange]);

  // ✅ 3. APPLY FILTERS TO DATA (WITHIN CURRENT DATE_RANGE)
  useEffect(() => {
    if (!isBusy && dataFiltered.length > 0) {
      setIsBusy(true);
      applyFilters(dataFiltered);
    }
  }, [dataFiltered, filters.dateSelected]);

  // ======================================================================== //
  //  USER-APPLIED FILTERS ================================================== //
  // ======================================================================== //

  // LOCATION:

  useEffect(() => {
    if (!isBusy) {
      setIsBusy(true);
      applyFilters(dataFiltered);
      if (filters.province.isActive) {
        const isSelectedProvincesInResultsByDate = !!dataFiltered.find((result: DataEntry) =>
          selectedLocations.find((province) => province.value === result?.province),
        );
        if (!isSelectedProvincesInResultsByDate) {
          resetLocation();
        }
        if (isSelectedProvincesInResultsByDate) {
          // SET SELECTED LOCATION - DUE TO SELECTION OF LOCATION, IR CHANGE OF OTHER FILTER TYPE THAT INCLUDES LOCATION
          const filteredByLocation = dataFilteredByDate.filter((result: DataEntry) =>
            selectedLocations.find((province) => province.value === result.province),
          );
          setDataFilter({ results: filteredByLocation });
        }
      }
      setIsBusy(false);
    }
  }, [filters.province]);

  // RESET / CLEAR HERO DATA STORED FOR DETAIL PAGE
  useEffect(() => {
    if (filters.searchQuery.isActive) {
      setDataFilter({ results: searchResults || dataFiltered });
    } else {
      setDataFilter({ results: dataFiltered });
    }
  }, [filters.searchQuery.isActive, dataFiltered, searchResults]);

  // ======================================================================== //

  return (
    <section css={styles}>
      {isBusy && <Spinner page />}
      <Row>
        <Col xs={12}>
          <form id="form-eventos">
            <input role="presentation" autoComplete="off" style={{ display: 'none' }} />

            {/* ================================================================== */}

            {/* <Row style={{ zIndex: 999999, marginBottom: '100px' }}>
              <Col xs={3}>
                <strong>dataset lengths:</strong>
                <pre>data: {Number(data.length)}</pre>
                <pre>dataFiltered: {Number(dataFiltered.length)}</pre>
                <pre>dataFilteredByDate: {Number(dataFilteredByDate.length)}</pre>
                <pre>dataFilteredByDate: {JSON.stringify(filters, null, 2)}</pre>
              </Col>
              <Col xs={3}>
                <strong>calendarStart:</strong>
                <pre>calendarStart: {calendarStart}</pre>

                <strong>filterResults:</strong>
                <pre>selectedLocations: {Number(selectedLocations.length)}</pre>
                <pre>resultsByLocation: {Number(resultsByLocation.length)}</pre>
              </Col>
              <Col xs={3}>
                <strong>searchQuery:</strong>
                <pre>searchResults: {searchResults?.length}</pre>
                <pre>results: {Number(results.length)}</pre>
              </Col>
              <Col xs={3}>
                <strong>filters.searchQuery:</strong>
                <pre>{JSON.stringify(filters.searchQuery.value, null, 2)}</pre>
                <strong>values.searchQuery:</strong>
                <pre>{JSON.stringify(getValues('searchQuery.value'), null, 2)}</pre>
              </Col>
            </Row> */}

            {/* ================================================================== */}

            <Row>
              <Col xs={12} sm={12} md={12} lg={6} xl={5} xxl={5} className="col col-date">
                <Row>
                  <Col xs={12} className="col">
                    <fieldset className="calendar-dual">
                      <DualCalendar
                        startDate={filters.dateRange.startDate}
                        highlightDates={dataFiltered.map((item: DataEntry) => new Date(item?.date as string))}
                        selected={filters.dateSelected.value ? new Date(filters.dateSelected.value) : ''}
                        onSelect={handleSelectDate} //  DAY TOGGLE
                        locale={ES}
                        minDate={DATE_TODAY}
                        monthsShown={2}
                        fixedHeight={false}
                        inline
                        renderCustomHeader={({
                          monthDate,
                          customHeaderCount,
                          decreaseMonth,
                          increaseMonth,
                        }: any) => (
                          <DualCalendarHeader
                            {...{
                              monthDate,
                              customHeaderCount,
                              decreaseMonth,
                              increaseMonth,
                              handleChangeMonth,
                            }}
                          />
                        )}
                      />
                    </fieldset>
                  </Col>
                </Row>
              </Col>

              <Col xs={12} sm={12} md={12} lg={6} xl={7} xxl={7} className="col col-map">
                <Card className={'card-filters'}>
                  <Map
                    name="spain"
                    dataSVG={SpainProvincesSVG}
                    selected={selectedLocations}
                    highlighted={getLocationsHighlighted({
                      data: filters.searchQuery.isActive ? searchResults : dataFilteredByDate,
                    })}
                  />
                </Card>
              </Col>
            </Row>

            {/* [ ✅ ] FILTERS ---------------------------------------- */}
            <hr />

            <Row align="center">
              <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className="col col-search">
                <fieldset className="fieldset-filter">
                  <DetailFilter
                    field={'searchQuery'}
                    initValue={{ value: filters.searchQuery.value, isActive: false }}
                    filtersState={{ filters, setFilters }}
                  >
                    <DataSearch
                      filterByKeys={['name']}
                      data={dataFiltered}
                      searchQuery={filters.searchQuery.value || ''}
                      setSearchQuery={(value: string) => {
                        const hasQuery = !!value.length;
                        // const hasQuery = value.length >= 3;
                        setValue('searchQuery.value', value);
                        setValue('searchQuery.isActive', hasQuery);
                        setFilters({ searchQuery: { value, isActive: hasQuery } });
                      }}
                      setSearchResults={(payload: unknown) => {
                        setSearchResults(payload as DataEntry[]);
                      }}
                      searchResults={searchResults}
                      placeholder="Buscar eventos filtrados..."
                      isDisabled={false}
                    />
                  </DetailFilter>
                </fieldset>
              </Col>

              <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className="col col-filters">
                <fieldset className="fieldset-filter">
                  <DetailFilter
                    field={'province'}
                    initValue={{ value: '', isActive: false }}
                    filtersState={{ filters, setFilters }}
                  >
                    <Select
                      id="province"
                      {...register('province')}
                      aria-label="Provincia"
                      className="input-select select-province"
                      classNamePrefix="select"
                      isClearable={true}
                      isSearchable={true}
                      // isMulti={false}
                      isDisabled={false}
                      options={getLocationsOptions({
                        results: filters.searchQuery.isActive ? searchResults : dataFilteredByDate,
                      })}
                      onChange={handleSelectProvice}
                      value={String(selectedLocations[0]?.value)}
                      // value={filters.province.value}
                      placeholder={getSelectLocationPlaceholder({ results })}
                      field={{
                        name: 'province',
                        placeholder: OPTION_PROVINCE_NO_SELECTION.label,
                      }}
                    />
                  </DetailFilter>
                </fieldset>
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
    </section>
  );
};
