import { faker } from '@faker-js/faker';
import { getRandomInt } from 'utils';
import jsonImagesPicsum from '../data/picsum.photos.json';

// ======================================================================== //
// TODO: IMAGE PLACEHOLDER API WORKING AS OF 2024-01-21:
// NOTE: SEE BELOW FOR EXAMPLES...

// urlLoremFlickr - https://loremflickr.com
// urlPicsumPhotos - https://picsum.photos
// urlPlaceholder - https://via.placeholder.com

// ======================================================================== //
// TODO: UNUSED IN THIS PROJECT...

export const getPlaceImageURL = () =>
  [
    // [0] - https://loremflickr.com/640/480/business -- LOREM or DEPRECATED (for June 2023) placeimg.com ??
    faker.image.url({
      width: faker.helpers.arrayElement([800, 1000, 1024, 1200]),
      height: faker.helpers.arrayElement([480, 500, 600, 640]),
      // faker.helpers.arrayElement(['tech', 'arch', 'people']),
      // true,
    }),
    // [1] - https://source.unsplash.com/category/people/1000x640
    faker.image.url({
      width: faker.helpers.arrayElement([800, 1000, 1024, 1200]),
      height: faker.helpers.arrayElement([480, 500, 600, 640]),
    }),
    // SELECT RANDOM BOOLEAN (0,1)
    // ][Math.round(Math.random())];
  ][1];

// ======================================================================== //

export const getFakerImageURLv2 = () => {
  const imageIDs = Array.from({ length: 5 }, () => getRandomInt(1, 998));
  // log('FAKE_IMAGE', 'yellow', imageIDs);

  const r = getRandomInt(1, 998);
  const imageData = jsonImagesPicsum[r];

  log('FAKE_IMAGE', 'yellow', imageData.download_url);

  return imageData.download_url;
};

// ======================================================================== //
// NOTE: THIS ONE CURRENTLY IN USE..
export const getFakerImageURLv3 = () => {
  const id = getRandomInt(1, 998);
  const widths = ['800', '1000', '1024', '1200'];
  const heights = [
    '480',
    // '500',
    // '600',
    '640',
  ];
  const width = widths[getRandomInt(0, widths.length - 1)];
  const height = heights[getRandomInt(0, heights.length - 1)];

  // return `https://picsum.photos/id/${id}/${width}/${height}`;
  return `https://picsum.photos/${width}/${height}`;
  // return `https://loremflickr.com/${width}/${height}?lock=${id}`;
};

export const getFakerImageURLv4 = () => {
  const widths = ['800', '1000', '1024', '1200'];
  const heights = ['480', '500', '600', '640'];
  const width = widths[getRandomInt(0, widths.length - 1)];
  const height = heights[getRandomInt(0, heights.length - 1)];

  return `https://picsum.photos/${width}/${height}`;
};

export const getFakerImageURL = () => {
  getFakerImageURLv2();
  const widths = ['800', '1000', '1024', '1200'];
  const heights = ['480', '500', '600', '640'];
  const cats = ['tech', 'arch', 'people'];

  const width = widths[getRandomInt(0, widths.length - 1)];
  const height = heights[getRandomInt(0, heights.length - 1)];
  const cat = cats[getRandomInt(0, cats.length - 1)];

  return `http://placeimg.com/${width}/${height}/${cat}`;
};

// ======================================================================== //
// TODO: IMAGE PLACEHOLDER API WORKING AS OF 2024-01-21:

// NOTE: urlLoremFlickr - https://loremflickr.com
// faker.image.urlLoremFlickr() // 'https://loremflickr.com/640/480?lock=1234'
// faker.image.urlLoremFlickr({ width: 128 }) // 'https://loremflickr.com/128/480?lock=1234'
// faker.image.urlLoremFlickr({ category: 'nature' }) // 'https://loremflickr.com/640/480/nature?lock=1234'

// NOTE: urlPicsumPhotos - https://picsum.photos
// faker.image.urlPicsumPhotos() // 'https://picsum.photos/seed/NWbJM2B/640/480'
// faker.image.urlPicsumPhotos({ width: 128 }) // 'https://picsum.photos/seed/NWbJM2B/128/480'

// NOTE: urlPlaceholder - https://via.placeholder.com
// faker.image.urlPlaceholder() // 'https://via.placeholder.com/150x180/FF0000/FFFFFF.webp?text=lorem'
// faker.image.urlPlaceholder({ width: 128 }) // 'https://via.placeholder.com/128x180/FF0000/FFFFFF.webp?text=lorem'
// faker.image.urlPlaceholder({ height: 128 }) // 'https://via.placeholder.com/150x128/FF0000/FFFFFF.webp?text=lorem'
// faker.image.urlPlaceholder({ backgroundColor: '000000' }) // 'https://via.placeholder.com/150x180/000000/FFFFFF.webp?text=lorem'
// faker.image.urlPlaceholder({ textColor: '000000' }) // 'https://via.placeholder.com/150x180/FF0000/000000.webp?text=lorem'
// faker.image.urlPlaceholder({ format: 'png' }) // 'https://via.placeholder.com/150x180/FF0000/FFFFFF.png?text=lorem'
// faker.image.urlPlaceholder({ text: 'lorem ipsum' }) // 'https://via.placeholder.com/150x180/FF0000/FFFFFF.webp?text=lorem+ipsum'
// faker.image.urlPlaceholder({ width: 128, height: 128, backgroundColor: '000000', textColor: 'FF0000', format: 'png', text: 'lorem ipsum' }) // 'https://via.placeholder.com/128x128/00000

// ======================================================================== //
