import { INPUTS, FORMAT } from 'types/form.types';

import { AUTH_USER } from 'types/form.types';
import type { DetailConfig, DetailSection } from 'components/DetailSummary';
import { DATATYPE, ENDPOINT } from './registros.config';

let i = 0;

// PUBLIC WEB VIEW ----------------------------------------------------------------- //

// NOTE: FOLLOWING CONFIG WILL BE MERGED WITH form.config.ts/globalFieldProps
// TO POPULATE COMMON PROPS, OPTIONS etc
export const detailConfig: DetailConfig = {
  dataType: {
    key: DATATYPE,
    role: 'detail',
  },
  // ----------------------------------------------------------------- //
  api: { endpoint: ENDPOINT },
  sections: [
    // ---------------------------------------------------------------------- //

    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Info',
      fields: [
        {
          name: 'name',
          label: 'nombre',
          inputType: INPUTS.text,
        },
        {
          name: 'category',
          label: 'Categoría',
          inputType: INPUTS.text,
          valueFormat: {
            type: FORMAT.BY_ID, // TODO: NO TS TYPE WARN ???????
            source: 'cat_id',
          },
          colWidth: { xs: 12 },
        },
        {
          name: 'province',
          // label: 'Web principal:',
          inputType: INPUTS.text,
        },
        {
          name: 'city',
          // label: 'Web principal:',
          inputType: INPUTS.text,
        },
        {
          name: 'url',
          label: 'Web principal:',
          inputType: INPUTS.url,
        },
      ],
    },

    // ---------------------------------------------------------------------- //

    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Datos de Contacto',
      // description: 'visibles a todo el mundo en la web pública',
      description: '',
      fields: [
        {
          name: 'email_public',
          label: 'Contacto e-mail',
          inputType: INPUTS.repeater,
          repeaterConfig: {
            inputType: INPUTS.email,
          },
        },
        {
          name: 'tel_public',
          label: 'contacto teléfono',
          inputType: INPUTS.repeater,
          repeaterConfig: {
            inputType: INPUTS.tel,
          },
        },
        // {
        //   name: 'socials',
        //   inputType: INPUTS.socials,
        //   colWidth: {
        //     xs: 12,
        //     md: 12,
        //   },
        // },
      ],
    },

    // ---------------------------------------------------------------------- //

    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Description',
      colWidth: {
        xs: 12,
        md: 12,
      },
      fields: [
        {
          name: 'description',
          // label: DESC,
          inputType: INPUTS.text,
          colWidth: {
            xs: 12,
            md: 12,
          },
        },
      ],
    },

    // ---------------------------------------------------------------------- //

    // {
    //   sectionTitle: 'Datos de la entidad o asociación',
    //   fields: [
    //     {
    //       name: 'json_meta',
    //       label: 'JSON_META',
    //       // isHidden: false,
    //       inputType: INPUTS.JSON_META,
    //       colWidth: {
    //         xs: 12,
    //       },
    //     },
    //   ],
    // },

    // ---------------------------------------------------------------------- //

    /*
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Más detalles y Descripción sobre la actividad',
      description: 'Realice una descripción de la actividad que será visible para los usuarios',
      fields: [
        // {
        //   name: 'url',
        //   label: 'URL de la página web (o similar) de la entidad o asociación:',
        //   inputType: INPUTS.url,
        //
        // },
        {
          name: 'socials',
          inputType: INPUTS.socials,
          colWidth: {
            xs: 12,
            md: 12,
          },
        },
        {
          name: 'sector',
          label: 'Ámbito de actuación',
          inputType: INPUTS.select,
          options: [
            {
              value: 'local',
              label: 'Local',
            },
            {
              value: 'regional',
              label: 'Regional',
            },
            {
              value: 'nacional',
              label: 'Nacional',
            },
            {
              value: 'internacional',
              label: 'Internacional',
            },
          ] as SelectOption[],
          colWidth: {
            xs: 6,
          },
        },
        {
          name: 'description',
          label: 'Descripción',
          hint: 'más información',
          inputType: INPUTS.textarea,

        },
        {
          name: 'activities',
          label: 'Tipo de actos o actividades que realiza',
          inputType: INPUTS.textarea,

        },
      ],
    },

    */

    // ---------------------------------------------------------------------- //

    /*
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Dirección / Ubicación',
      copyFrom: {
        name: 'copy_from_user_address',
        label: `Marcar si la dirección coincide con la del titular : ${AUTH_USER.EMAIL}`,
        inputType: INPUTS.checkbox,
        defaultValue: false,
        params: { endpoint:  'register', uuid: AUTH_USER.UUID },
      },
      fields: [
        {
          name: 'address_0',
          label: 'dirección 1',
          hint: 'calle + edificio',
          inputType: INPUTS.text,
        },
        {
          name: 'address_1',
          label: 'dirección 2',
          hint: 'puerta',
          inputType: INPUTS.text,
        },
        {
          name: 'postal_code',
          label: 'código postal',
          inputType: INPUTS.text,
          order: 2,
        },
        {
          name: 'city',
          label: 'población',
          inputType: INPUTS.text,
        },
        {
          name: 'country',
          label: 'país',
          inputType: INPUTS.selectCountry,
        },
        {
          name: 'province',
          label: 'província',
          hint: 'Provincia de España',
          inputType: INPUTS.select,
          options: SPAIN_PROVINCES,

          isDisabled: true,
          isControlledBy: {
            criteria: { country: 'Spain' },
            target: { isDisabled: false },
          },
        },
      ],
    },
    */
  ],
  // ----------------------------------------------------------------- //
};
