import { UseFormProps } from 'react-hook-form';
import { ButtonTypeEnum, ButtonTypeEnum as types } from 'components/Button/Button.types';
import type { ButtonProps, ButtonType, ButtonVariant } from 'components/Button/Button.types';
import type { ColorName, HexColor } from 'styles/colors.types';

export { ButtonTypeEnum, types };

// FORM SUBMIT ACTIONS ==================================================== //
/**
 * == BUTTON DEF PROPS: CORE ===============================
 * type: ButtonType;
 * label: string;
 * action?: string | undefined;
 *
 * == BUTTON DEF PROPS: COMMON =============================
 * variant?: ButtonVariant | string | undefined;
 * color?: string;
 * colorHover?: string;
 * icon?: string | ReactElement;
 * isDisabled?: boolean;
 * id?: string;
 */

export interface FormButton extends Partial<ButtonProps> {
  type: ButtonType;
  label?: string | any;
  action?: string | undefined; // TODO: DEFINE PRESET + COMMON ACTIONS
  disabled?: boolean; // OVERRIDE FOR isDisabled - SET TO false FOR "NEVER DISABLED"
  variant?: ButtonVariant;
  color?: ColorName | HexColor;
  // icon?: ReactElement;
  onClick?: OnClickMethod;
  isHidden?: boolean;
}

export type OnClickMethod = (a?: any, b?: any, c?: any) => void;

export enum BUTTONS {
  CANCEL = 'CANCEL',
  RESET = 'RESET',
  SUBMIT = 'SUBMIT',
  UPDATE = 'UPDATE',
  EDIT = 'EDIT',
  VIEW = 'VIEW',
  ADD = 'ADD',
  MOCK = 'MOCK',
  MOCK_ADMIN = 'MOCK_ADMIN',
  NO_REQUIRE = 'NO_REQUIRE',
  LATER = 'LATER',
}

// FORM SUBMIT ACTIONS ==================================================== //

export const FORM_BUTTONS_DEFAULT: FormButton[] = [
  {
    type: types.button,
    label: BUTTONS.CANCEL,
    action: 'CANCEL',
    // color: 'warningLight',
  },
  {
    type: types.reset,
    label: BUTTONS.RESET,
    action: 'RESET',
    // color: 'warningLight',
  },
  {
    type: types.submit,
    label: BUTTONS.SUBMIT,
    action: 'SUBMIT',
    // color: 'successDark',
  },
];

// FORM SUBMIT ACTIONS ==================================================== //

export interface FormButtonBarProps {
  buttons?: FormButton[];
  formMethods: UseFormProps | any;
  handleCancel?: () => void;
  handleActions?: { [action: string]: OnClickMethod };
  resetForm?: (defaultValues?: any) => void;
  isLocked: boolean;
}
