import { Col, Row } from 'react-grid-system';
import { FormProvider, useForm } from 'react-hook-form';
import type { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from '@tanstack/react-query';
import type { DataEntry, TableConfig } from 'types';
import { useRouter } from 'routes/hooks';
import { useDataFilter } from 'store/DataFilterContext';
import { DataGrid } from 'components/DataGrid';
import { EventCard } from 'components/EventCard';
import { optionsRHF } from 'components/Form/config';
import { NoEntries } from 'components/NoEntries';
import { Pagination, PaginationProvider } from 'components/Pagination';
import { Spinner } from 'components/Spinner';
import { config } from 'config';
import { tableConfig as dataConfiguration } from 'config/datatype/eventos/eventos.table';
import { useEventFilters } from './EventFiltersContext';
import { EventForm } from './EventForm';

const { TABLE_MAX_ROWS } = config;

// NOTE: @tanstack/react-query v4 *BREAKING_CHANGE* `RefetchQueryFilters` interface has CHANGED !!
// NOW INVALID: RefetchQueryFilters<unknown>  ("Type 'RefetchQueryFilters' is not generic.")
// interface RefetchQueryFilters extends QueryFilters {}
// Use QueryFilters --or-- MutationFilters ??

export type EventsLayoutProps = {
  doFetch: (options?: (RefetchOptions & RefetchQueryFilters) | undefined) => Promise<QueryObserverResult<any, unknown>>;
  isFetching: boolean;
  data: DataEntry[];
  tableConfig: TableConfig;
};

export const EventsLayout = ({ doFetch, isFetching = false, data, tableConfig }: EventsLayoutProps) => {
  const { route } = useRouter();
  const { results = [] } = useDataFilter();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { setFilters: _, ...filtersState } = useEventFilters();
  const formMethods = useForm({ ...optionsRHF, defaultValues: { ...filtersState } });

  // LIST ============================================================= //

  const listConfig = tableConfig.list || ({} as any);
  const listItemConfig = listConfig.listItem || ({} as any);

  const CARD_SHAPE_FACTOR = 5 ?? listItemConfig?.shapeRatio ?? 2;

  const isVisiblePagination: boolean =
    results.length > TABLE_MAX_ROWS || (results.length === 0 && data.length > TABLE_MAX_ROWS);

  return (
    <FormProvider {...formMethods}>
      <Row className="row-list">
        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="col col-form">
          <EventForm />
        </Col>
      </Row>
      <Row className="row-list">
        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="col col-list">
          {isFetching && <Spinner page dark />}
          {!isFetching &&
            (results.length > 0 ? (
              <PaginationProvider numItems={results.length} maxPerPage={dataConfiguration.list?.maxRows}>
                <Pagination numResults={data.length || 0} />
                <div className="results-list">
                  <DataGrid
                    data={results}
                    tableConfig={dataConfiguration}
                    itemComponent={EventCard}
                    shapeRatio={CARD_SHAPE_FACTOR}
                    basePath={route?.path}
                  />
                </div>
                <Pagination />
              </PaginationProvider>
            ) : (
              <>
                <hr />
                <NoEntries />
              </>
            ))}
        </Col>
      </Row>
    </FormProvider>
  );
};
