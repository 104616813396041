import { css } from '@emotion/react';
import { colors, layout, forms, cssPlaceholder } from 'styles';

export const styles = css`
  button.button-toggle {
    position: absolute;
    top: 0;
    right: 0;
    width: ${forms.inputs.height};
    height: ${forms.inputs.height};
    transform: translate(-${layout.padding}, 0);
  }

  input[type='password'] {
    letter-spacing: 0.2em;
    font-weight: 900 !important;
    ::-webkit-input-placeholder {
      ${cssPlaceholder}
    }
    ::-moz-placeholder {
      ${cssPlaceholder}
    }
    :-ms-input-placeholder {
      ${cssPlaceholder}
    }
    ::placeholder {
      ${cssPlaceholder}
    }
  }

  button[data-toggle='true'] {
    color: ${colors.primary};
  }

  button[data-toggle='false'] {
    color: ${colors.greyDark};
  }
`;
