import type { ContextState, ActionType, ActionPayload } from './FormConfigContext.types';

export const Actions = {
  SET_FIELDS: 'SET_FIELDS',
};

export const contextReducer = (state: ContextState, action: ActionType): ContextState => {
  switch (action.type) {
    case Actions.SET_FIELDS:
      return { ...state, ...action.payload };

    /* istanbul ignore next */
    default:
      console.warn('reducer action not recognized');
      return state;
  }
};
