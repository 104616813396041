import { createContext, useCallback, useContext, useReducer } from 'react';

import type { Context, ReactElement, ReactNode } from 'react';
import { eventFiltersReducer } from './EventFiltersReducer';
import { initialState } from './EventFiltersState';
import type { EventFiltersState } from './EventFiltersState';

// NOTE: see also - https://dev.to/mpriour/generating-strongly-typed-reducer-actions-for-react-j77
// NOTE: IN-DEPTH REF - https://www.typescript-training.com/course/intermediate-v1/09-mapped-types/
// NOTE: EXCELLENT REF - https://blog.logrocket.com/how-to-use-keyof-operator-typescript/

export const EventFiltersContext: Context<EventFiltersState> = createContext<EventFiltersState>(initialState);

export const EventFiltersProvider = ({
  initState: initialStateOverrides,
  children,
}: {
  initState?: EventFiltersState;
  children: ReactNode;
}): ReactElement => {
  const [state, dispatch] = useReducer(eventFiltersReducer, { ...initialState, ...initialStateOverrides });

  const setFilters = useCallback((payload: { [key in keyof EventFiltersState]: unknown }) => {
    dispatch({ type: 'SET_FIELDS', payload });
  }, []);

  const initialValue: EventFiltersState = { ...state, setFilters };

  return <EventFiltersContext.Provider value={{ ...initialValue }}>{children}</EventFiltersContext.Provider>;
};

export const useEventFilters = (): EventFiltersState => useContext(EventFiltersContext);
