import { NavLink } from 'react-router-dom';
import { useGlobal } from 'store/useGlobalContext';
import { RouteConfig } from 'routes/routes.types';

export const LinkToCategory = ({ catId, basePath }: { catId: number | string; basePath?: string }) => {
  const { metaRoutes } = useGlobal('metaRoutes');
  const categoryRoute = metaRoutes?.find((route: RouteConfig) => route.uuid === catId);

  if (!categoryRoute?.title) {
    return <>{catId}</>;
  }

  return (
    <NavLink to={categoryRoute?.pathname}>
      <strong>{categoryRoute.title}</strong>
    </NavLink>
  );
};
