import type { TableConfig } from 'types';
import { LinkToDetail } from 'components/DataTable/components/LinkToDetail';
import { EventCard } from 'components/EventCard';
import { DATATYPE, ENDPOINT } from './eventos.config';
import { COL_TYPE } from 'components/DataTable/DataTable.types';

// ----------------------------------------------------------------- //
// TABLE CONFIG:
export const tableConfig: TableConfig = {
  dataType: {
    key: DATATYPE,
    role: 'table',
  },
  list: {
    colWidth: {
      xs: 12,
      lg: 6,
    },
    maxRows: 8,
    listItem: {
      component: EventCard,
      // render: (props) => <EventCard {...props} />,
      shapeRatio: 6, // NOTE: MOVED TO INLINE prop
    },
  },
  actions: {
    hasActionView: true,
    hasActionEdit: false,
    hasActionNew: false,
  },
  api: {
    endpoint: ENDPOINT,
  },
  columns: [
    {
      label: 'NAME',
      name: 'name',
      columnType: COL_TYPE.DATA,
      // width: 3,
      // width: false, // FALSE or OMITTED TO SET AS FLEXI-COLUMN
      isSortable: true,
      render: ({ data }) => <LinkToDetail targetID={data.id} label={data.name} />,
    },
    {
      label: 'EMAIL',
      name: 'email',
      columnType: COL_TYPE.DATA,
      widths: {
        xs: 0,
        lg: 2,
      },
      isSortable: true,
      render: ({ data }) => <LinkToDetail targetID={data.id} label={data.email} />,
    },
    {
      label: 'PROVINCE',
      name: 'province',
      columnType: COL_TYPE.DATA,
      isSortable: true,
      render: ({ data }) => <LinkToDetail targetID={data.id} label={data.province} />,
    },
    {
      label: 'ID',
      name: 'id',
      columnType: COL_TYPE.DATA,
      width: 1,
      widths: {
        xs: 0,
        lg: 1,
      },
      isSortable: true,
      render: ({ data }) => data.id,
    },
  ],
};
