import { css } from '@emotion/react';
import { layout } from 'styles/layout.css';
import { colors } from 'styles/colors.css';
// import { min, max } from 'styles/media-queries';

export const styles = css`
  &:not(.horizontal) {
    .col-buttons-create button {
      margin: calc(${layout.padding} * 2) 0;
      width: 100%;
      max-width: 400px;
      justify-content: space-between;
    }
  }

  &.horizontal {
    width: 100%;
    & > div {
      display: flex;
    }
    .col {
      display: flex;
      justify-content: flex-end;
      &:nth-of-type(2) {
        padding-left: calc(${layout.padding} * 0.5) !important;
      }
    }
    .col-buttons-create button {
      margin: calc(${layout.padding} * 2) 0;
      width: 100%;
      max-width: 400px;
      justify-content: space-between;
    }
  }
`;
