import { Col, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import { AiOutlineStepBackward as IconFirst, AiOutlineStepForward as IconLast } from 'react-icons/ai';
import { MdNavigateBefore as IconPrev, MdNavigateNext as IconNext } from 'react-icons/md';
import { Button } from 'components/Button';
import { config } from 'config';
import { usePagination } from '.';
import { styles } from './Pagination.css';

const { TABLE_MAX_ROWS } = config;

const Pagination = ({
  // numItems,
  numResults = 0, // TODO: RENAME ?? - AS COMES FROM FETCHED `data`
  // stringResults = { single: 'result', plural: 'results' },
  stringResults = { single: 'resultados', plural: 'resultados' },
  isVisibleSummary = true,
}: {
  // numItems?: number;
  numResults?: number; // TODO: RENAME ?? - AS COMES FROM FETCHED `data`
  stringResults?: { single: string; plural: string };
  isVisibleSummary?: boolean;
}) => {
  const { t } = useTranslation();
  const { numItems, numPages, maxPerPage, page, prevPage, nextPage, setPage, gotoFirstPage, gotoLastPage } =
    usePagination();

  // ======================================================================== //
  // TODO: MOVED HERE, FROM OUTSIDE... (WIP..)

  const isVisiblePagination: boolean = numResults > TABLE_MAX_ROWS || (numResults === 0 && numItems > TABLE_MAX_ROWS);
  const isSinglePage = (numItems > 0 && numItems <= TABLE_MAX_ROWS) || numResults > 0 || numResults <= TABLE_MAX_ROWS;
  if (!isVisiblePagination) return null;

  // ======================================================================== //

  return (
    <div css={styles}>
      <Row align="center">
        <Col xs={2} className="col-controls-left">
          <Button
            variant="solid"
            color="primary"
            icon={<IconFirst />}
            iconScale={1.1}
            onClick={gotoFirstPage}
            isDisabled={page === 1 || numItems === 0}
          />
          <Button
            variant="solid"
            color="primary"
            icon={<IconPrev />}
            iconScale={1.6}
            onClick={prevPage}
            isDisabled={page === 1 || numItems === 0}
          />
        </Col>
        <Col xs={8} className="col-pagination-info">
          {isVisibleSummary &&
            (() => {
              switch (true) {
                case numItems > maxPerPage:
                  return (
                    <h2>
                      {t('t.pagination.page').toUpperCase()} {page} <span>{` / ${numPages}`} </span>
                      <span>{t('t.pagination.results', { count: numItems })}</span>
                    </h2>
                  );
                case numItems > 0 && numItems <= maxPerPage:
                  return (
                    <h2>
                      {/* <span className="results-single-page">
                        {numItems} {numItems === 1 ? stringResults.single : stringResults.plural}
                      </span> */}
                      <span className="results-single-page">
                        <label>{numItems === 1 ? stringResults.single : stringResults.plural}:</label> {numItems}
                      </span>
                    </h2>
                  );
                default:
                  return (
                    <h2>
                      <span className="no-results">resultados: 0</span>
                    </h2>
                  );
              }
            })()}
        </Col>
        <Col xs={2} className="col-controls-right">
          <Button
            variant="solid"
            color="primary"
            icon={<IconNext />}
            iconScale={1.6}
            onClick={nextPage}
            isDisabled={page === numPages || numItems === 0}
          />
          <Button
            variant="solid"
            color="primary"
            icon={<IconLast />}
            iconScale={1.1}
            onClick={gotoLastPage}
            isDisabled={page === numPages || numItems === 0}
          />
        </Col>
      </Row>
    </div>
  );
};

export { Pagination };
