import { css } from '@emotion/react';
import { layout } from 'styles/layout.css';
import { colors } from 'styles/colors.css';

export const styles = css`
  padding: calc(${layout.padding} * 3) calc(${layout.padding} * 8);
  padding-top: 5.5em;

  .row-buttons-view {
    .col {
      padding-right: calc(${layout.padding} * 1.5) !important;
      button {
        width: 100% !important;
      }
    }
  }
`;
