/* eslint-disable no-undef */
// import Geocode from 'react-geocode';
import {
  setKey,
  setDefaults,
  setLanguage,
  setRegion,
  fromAddress,
  fromLatLng,
  fromPlaceId,
  setLocationType,
  geocode,
  RequestType,
} from 'react-geocode';
import i18n from 'i18next';
import type { MapCoords } from '../MapGoogle.types';

// ========================================================================== //
// RE: Geocode - https://www.npmjs.com/package/react-geocode

const { VITE_GOOGLE_MAPS_API_KEY: GOOGLE_MAPS_API_KEY } = import.meta.env;
setKey(GOOGLE_MAPS_API_KEY);

setLanguage(i18n.language);
setRegion('es');

// set response region (optional) ------------------------------------------- //
// A Geocoding request with region=es (Spain) will return the Spanish city.
// Geocode.setRegion('es');

// set location_type filter (optional) -------------------------------------- //
// google geocoder returns more that one address for given lat/lng.
// In some case we need one address as response for which google itself provides a location_type filter.
// So we can easily parse the result for fetching address components
// ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
// And according to the below google docs in description, ROOFTOP param returns the most accurate result.
setLocationType('ROOFTOP');

// ========================================================================== //

interface FromAddress {
  address: string;
  apiKey?: string;
  language?: string;
  region?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RESPONSE_EXAMPLE = {
  address_components: [
    {
      long_name: '5',
      short_name: '5',
      types: ['street_number'],
    },
    {
      long_name: 'Département de Paris',
      short_name: 'Département de Paris',
      types: ['administrative_area_level_2', 'political'],
    },
  ],
  formatted_address: 'Champ de Mars, 5 Av. Anatole France, 75007 Paris, France',
  geometry: {
    location: { lat: 48.85837009999999, lng: 2.2944813 },
    location_type: 'ROOFTOP',
    viewport: {
      northeast: { lat: 48.85974613029151, lng: 2.295661530291502 },
      southwest: { lat: 48.85704816970851, lng: 2.292963569708498 },
    },
  },
  partial_match: true,
  place_id: 'ChIJLU7jZClu5kcR4PcOOO6p3I0',
  plus_code: { compound_code: 'V75V+8Q Paris, France', global_code: '8FW4V75V+8Q' },
  types: ['establishment', 'point_of_interest', 'tourist_attraction'],
};

// ========================================================================== //

export const getCoordsFromAddress = async ({ location }: { location: string[] }): Promise<any> => {
  const locationQuery = location.join(', ');
  try {
    const response = await fromAddress(locationQuery);
    const { results, status } = response;
    log('[GEOCODE]', 'hotpink', { query: locationQuery }, ...results);
    // SUCCESS -------------------------------------------------------
    if (status === 'OK') {
      const geoData = results[0];
      const coords = geoData.geometry.location;
      return { lat: coords.lat, lng: coords.lng } as MapCoords;
    }
    // ERROR ---------------------------------------------------------
  } catch (err: unknown) {
    err instanceof Error ? console.error(err.message) : console.error(String(err));
  }
};
