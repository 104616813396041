import type { DetailConfig } from 'components/DetailSummary';
import { types, BUTTONS } from 'components/Form';
import type { FormConfig, FormButton } from 'components/Form';
import { FIELDS_PERSONAL_DATA, FIELDS_ADDRESS, FIELDS_DOCS_PERSONAL } from 'config/form.sections';
import { DATATYPE, ENDPOINT } from './account.config';
import { getFieldsetConfig } from 'config/data.utils/getFieldsetConfig';

let i = 0;

const buttons: FormButton[] = [
  {
    type: types.button,
    label: 't.buttons.returnToAccount',
    action: BUTTONS.CANCEL,
  },
  {
    type: types.button,
    label: 't.buttons.editData',
    action: BUTTONS.EDIT,
    color: 'successXDark',
    variant: 'solid',
  },
];

export const detailConfig: DetailConfig = {
  dataType: { key: DATATYPE, role: 'detail' },
  api: { endpoint: ENDPOINT, omitFields: ['is_validated'] },
  buttons,
  sections: [
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Datos personales (detail)',
      fields: [...getFieldsetConfig(FIELDS_PERSONAL_DATA), ...getFieldsetConfig(FIELDS_DOCS_PERSONAL)],
    },
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Dirección',
      description: 'Opcionalmente puede utilizar como dirección de registro la de su medio o entidad.',
      fields: [...getFieldsetConfig(FIELDS_ADDRESS)],
      // ---------------------------------------------------------------------- //
    },
  ],
};
