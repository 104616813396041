import { Col, Row } from 'react-grid-system';
import { useFormContext } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { IoMdAdd as IconAddNew } from 'react-icons/io';
import { DataEntry } from 'types';
import { useQueryGet } from 'api';
import { Button } from 'components/Button';
import { optionsSetLoud } from 'components/Form';
import { InviteModal } from 'components/FormUI/InviteList/InviteModal';
import { Modal } from 'components/Modal/Modal';
import { detailConfig as tableConfigInvite } from 'config/datatype/invite';
import { InvitePreview } from './InviitePreview';
import { styles } from './InviteList.css';

export const InviteList = ({ field, defaultValue, onChange }: any) => {
  const { register, getValues, setValue } = useFormContext();
  const [entidades, setEntidades] = useState<DataEntry[]>([]);
  const [previews, setPreviews] = useState<DataEntry[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  useQueryGet({
    endpoint: 'entidades',
    enabled: true,
    onSuccess: ({ data }) => setEntidades(data),
  });

  const updatePreviews = (selectedIDs: string) => {
    const ids: number[] = selectedIDs.split(',').map((id) => Number(id));
    const selected = entidades.filter((item) => ids.includes(Number(item?.id)));
    setPreviews(selected);
  };

  useEffect(() => {
    field.value && updatePreviews(field?.value);
  }, [entidades]);

  return (
    <div css={styles}>
      <Row align="center">
        <Col xs={12} lg={12} className="col col-btn">
          <input type="hidden" {...register(field?.name)} onClick={() => setIsOpen(true)} />
          <Button
            label="Añadir e invitar"
            color="successDark"
            variant="outline"
            className="btn-add"
            icon={<IconAddNew />}
            onClick={() => setIsOpen(true)}
          />
        </Col>
        {previews.map((entity: DataEntry) => (
          <Col xs={12} lg={6} className="col col-list" key={String(entity.id)}>
            <InvitePreview key={String(entity.id)} data={entity} onClick={() => setIsOpen(true)} />
          </Col>
        ))}
      </Row>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="Invitar colaboradores"
        size="xl"
        onConfirm={() => {
          field.value = getValues(field.name); // NOTE: UPDATE field CONFIG WITH NEW VALUE - FOR UNDOING (onCancel)
          updatePreviews(field.value);
          log('MODAL_CONFIRM', 'lime', field.value);
        }}
        onCancel={() => {
          setValue(field.name, field.value, optionsSetLoud);
          log('MODAL_CANCEL', 'orange', field.value);
        }}
      >
        <InviteModal
          endpoint="entidades"
          tableConfig={tableConfigInvite}
          fieldName={field.name}
          selectedIDs={field.value ? field.value.split(',') : []}
        />
      </Modal>
    </div>
  );
};
