import { Col, Row } from 'react-grid-system';
import { NavLink } from 'react-router-dom';
import { useNavigateState } from 'hooks/useNavigateState';
import { useEffect, useRef, useState } from 'react';
import type { ReactElement } from 'react';
import { useRouter } from 'routes/hooks';
import { useGlobal } from 'store/useGlobalContext';
import { calcWidthByNumChars } from 'utils';
import { usePageHeader } from 'hooks/usePageHeader';
import { BackButton } from './BackButton';
import { PageTitle } from './PageTitle';
import { styles } from './PageHeader.css';
import { ACTIONS, type Action } from 'types/actions.types';
import { Button } from 'components/Button';
import { getButtonColors } from 'components/Button/getButtonStyles';
import { usePageContent, usePageContentActions } from 'pages/Layout/PageContentContext/PageContent.hooks';
import { translate } from 'utils/utils.translation';

import { BiListPlus } from 'react-icons/bi';
// import { FaPlus } from 'react-icons/fa';
import { FaPlus } from 'react-icons/fa6';
import { FiPlusSquare } from 'react-icons/fi';
import { LuListPlus } from 'react-icons/lu';
import { PiListPlusBold } from 'react-icons/pi';
import { TbCalendarPlus } from 'react-icons/tb';
import { TbTextPlus } from 'react-icons/tb';
import type { IconType } from 'react-icons';
import type { HeaderButton, BackAction } from 'types/header.types';

const Slash = () => <span className="slash" />;

export const PageHeader = (): ReactElement => {
  const { navigate } = useNavigateState();
  const { location, params, route, pathname, fromLocation, action } = useRouter();
  const { setPageHeaderTitles } = usePageHeader();
  const refTitle = useRef<any>();

  // ======================================================================== //

  const { setPageTitle, setPageHeaderButton } = usePageContentActions();
  const { title, detail, subtitle, headerButton } = usePageContent();

  const headerButtonProps = headerButton && headerButton?.isHidden !== true ? headerButton : null;

  const IconButton = headerButtonProps?.icon ? (headerButtonProps.icon as IconType) : null;

  const TITLE_COLUMN_SIZES = headerButton ? { xs: 12, lg: 8, xl: 8, xxl: 9 } : { xs: 12 };
  const BUTTON_COLUMN_SIZES = headerButton ? { xs: 12, lg: 4, xl: 4, xxl: 3 } : { xs: 12 };
  const buttonColors = getButtonColors({
    color: headerButton?.color || 'primaryXLight',
  });

  const handleClick = () => {
    switch (true) {
      case !!headerButton?.href:
        navigate(headerButton.href);
        break;
      case !!(typeof headerButton?.onClick === 'function'):
        headerButton.onClick();
        break;
      default:
        navigate(fromLocation.pathname);
        break;
    }
    navigate(headerButton.href);
  };

  // NOTE: NEW..
  useEffect(() => {
    setPageHeaderTitles(route);
  }, [location.pathname]);

  return (
    <header css={styles} className="page-header">
      {/* <pre>TEST_TITLE: {title}</pre> */}
      {/* <pre>TEST_DETAIL: {detail || ''}</pre>*/}
      {/*<pre>TEST_SUBTITLE: {subtitle}</pre> */}
      <Row className="row-title" direction="row">
        <Col {...TITLE_COLUMN_SIZES} className="col col-title">
          {title && <PageTitle />}
        </Col>
        {headerButton ? (
          <Col {...BUTTON_COLUMN_SIZES} className="col col-button">
            <>
              <Button
                className="btn-header-right"
                onClick={handleClick}
                variant={headerButton?.variant || 'solid'}
                color={headerButton?.color || buttonColors.btnColor}
                colorHover={headerButton?.colorHover || buttonColors.btnColorHover}
                // label={pageButtonLabel || pageTitle ? `Volver a ${pageTitle.toUpperCase()}` : ''}
                label={translate(headerButton?.label)}
                icon={IconButton ? <IconButton /> : null}
                iconPos="left"
                iconScale={1.8}
              />
              {/* <pre style={{ fontSize: '0.7em' }}>
                HeaderButton: {JSON.stringify({ fromLocation }, null, 2)}
              </pre> */}
            </>
          </Col>
        ) : null}
      </Row>
      {route?.parent && (
        <Row>
          <Col className="col col-breadcrumbs">
            <h3>
              <>
                <NavLink to={route.parent.pathname}>{route.parent.title}</NavLink>
                <Slash />
                <NavLink to={route.pathname}>{route.title}</NavLink>
              </>
            </h3>
          </Col>
        </Row>
      )}
    </header>
  );
};
