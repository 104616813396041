import { Row } from 'react-grid-system';
import { useFormContext } from 'react-hook-form';
import type { ReactElement } from 'react';
import { memo, useState } from 'react';
import type { FieldConfig } from 'types/form.types';
import type { FormSectionConfig } from 'components/FormSection/FormSection.types';
import { Checkbox } from 'components/FormUI/Checkbox';
import { slugify } from 'utils';
import { handleCopyFrom } from './formSection.utils';
import { ArrayJSX } from 'utils/ArrayJSX';
// import { FormField } from 'components/FormField'V1;
import { FormField } from 'components/FormField';
import { styles } from './FormSection.css';

// GREAT REF: REACT-QUERY + REACT-HOOK-FORM:
// https://tkdodo.eu/blog/react-query-and-forms#invalidate-and-reset-after-mutation

const FormSection = ({ sectionConfig, defaultValues, /* handleCopyFrom, */ isLocked }: any): ReactElement | null => {
  if (sectionConfig.isHidden) return null;
  const formMethods = useFormContext();

  const {
    sectionKey,
    sectionTitle,
    description,
    sectionToggle,
    fields: sectionFields,
    copyFrom,
    isHiddenSeparator = false,
  }: FormSectionConfig = sectionConfig;
  // TOGGLE SECTION VISIBILITY (IF DEFINED):
  const isToggleSection = sectionToggle && 'defaultValue' in sectionToggle;
  const defaultVisibility = isToggleSection ? sectionToggle.defaultValue : true;
  const [isVisibleSection, setIsVisibleSection] = useState(defaultVisibility);

  // COPY VALUES FROM OTHER DB TABLE (IF DEFINED):
  const isCopyFromSection = copyFrom && 'defaultValue' in copyFrom;
  const defaultCopyFrom = isCopyFromSection ? copyFrom.defaultValue : true;
  const [isCopiedFromSection, setIsCopiedFromSection] = useState(defaultCopyFrom);

  // TODO: MAKE SECTION COMPONENT (WRAPPER) FOR TOGGLE STATE, TITLE, FIELDS etc...

  const sectionID = sectionKey || slugify(sectionTitle);

  const cssClasses = new ArrayJSX();
  (description || sectionToggle?.description) && cssClasses.push('toggle-section');

  return (
    <section
      id={sectionID}
      key={sectionID}
      css={styles}
      className={cssClasses.inline()}
      style={isHiddenSeparator ? { borderTop: 'none' } : {}}
    >
      <header>
        {/* <pre>CHECKED: {String(isVisibleSection)}</pre> */}
        {/* <pre>CHECKED: {sectionToggle && JSON.stringify(sectionToggle.name, null, 2)}</pre> */}
        {sectionTitle && <h2>{sectionTitle}</h2>}
        {description && (
          <h3>{sectionToggle?.description && isVisibleSection ? sectionToggle.description : description}</h3>
        )}
        {isToggleSection && (
          <Checkbox
            // {...formMethods.register(sectionToggle.name)}
            name={sectionToggle.name}
            label={sectionToggle.label}
            field={sectionToggle}
            defaultValue={defaultVisibility}
            value={isVisibleSection}
            onChange={(isChecked: boolean) => {
              log('TOGGLE', 'red', isChecked);
              setIsVisibleSection(!isVisibleSection);
            }}
          />
        )}
        {copyFrom && (
          <Checkbox
            name={copyFrom.name}
            label={copyFrom.label}
            defaultValue={defaultCopyFrom}
            onChange={(isChecked: boolean) => {
              setIsCopiedFromSection(isChecked);
              handleCopyFrom({ isChecked, copyFrom, getValues: formMethods.getValues, setValue: formMethods.setValue });
            }}
          />
        )}
      </header>
      <Row style={{ display: isVisibleSection ? 'flex' : 'none' }}>
        {!!sectionFields?.length &&
          sectionFields.map((field: FieldConfig) => {
            const defaultValue = defaultValues ? (defaultValues as any)[field.name] : undefined;
            return (
              <FormField
                key={field.name}
                name={field.name}
                formMethods={formMethods}
                initialValue={defaultValue}
                isFormLocked={isLocked}
                field={field}
              />
            );
          })}
      </Row>
    </section>
  );
};

export default memo(FormSection);
