import { Row, Col } from 'react-grid-system';
import { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { ISchema, DataEntry, type DataConfigurationType } from 'types';
import { useRouter } from 'routes/hooks';
import { useQueryGet } from 'api/useQueryGet';
import { USER_ROLE } from 'auth/types/user.types';
import { useAuth } from 'auth/AuthContext';
import { useLayout } from 'pages/Layout/context/LayoutContext';
import { Form } from 'components/Form';
import { Spinner } from 'components/Spinner';
import { initDetailData } from 'config/data.utils/initDetailData';
import { formConfig as dataConfiguration } from 'config/datatype/account/register.form';
import { DevButtons } from './DevButtons';
import { usePageHeader } from 'hooks/usePageHeader';
import { getIsRegistrationComplete } from './account.utils';
import { useUserRegistration } from './useUserRegistration';
import { useUserValidation } from './useUserValidation';
import { styles } from './AccountRegistration.css';

const AccountRegistration = () => {
  const { user } = useAuth();
  const { params } = useRouter();
  const { isDevToolsVisible, isNavbarVisible, setLayoutState } = useLayout();
  const { setPageHeaderTitles } = usePageHeader({ config: dataConfiguration as DataConfigurationType });

  Object.assign(dataConfiguration, { isNewEntry: false });
  Object.assign(params, { table: 'registration' });

  const { dataRegistrationUpdate, isUpdating } = useUserRegistration();
  const { updateUserValidation, isValidating } = useUserValidation();
  const [formData, setFormData] = useState<DataEntry | null>(null);

  // ======================================================================== //
  // NOTE: 1. - INITIAL FETCH OF USER REGISTRATION DATA, FROM CONNECTED TABLE..

  const query = {};
  if (user) {
    Object.assign(query, { uuid: user?.uuid });
  }

  const {
    refetch: fetchUserRegistration,
    schema,
    isPending,
    isError,
  } = useQueryGet({
    endpoint: 'registration',
    query,
    enabled: false,
    onSuccess: ({ data }) => {
      const dataInitialized = initDetailData({ data, isExpandedSchema: true });
      setFormData(dataInitialized);
      setPageHeaderTitles(dataInitialized);
    },
  });

  useEffect(() => {
    setLayoutState({ isNavbarVisible: false });
    fetchUserRegistration();
  }, []);

  // ======================================================================== //
  // NOTE: 2. - PARSE USER REGISTRATION DATA..

  const isRegistrationFormComplete = getIsRegistrationComplete(formData);
  const IS_BUSY: boolean = !dataConfiguration || !formData || isPending || isUpdating || isValidating;

  // NOTE: 3. FORM COMPLETED + DATA UPDATED, SET `user` AS `VALIDATED`..

  useEffect(() => {
    if (!IS_BUSY && dataRegistrationUpdate && !isEqual(dataRegistrationUpdate, formData)) {
      const dataInitialized = initDetailData({ data: dataRegistrationUpdate, isExpandedSchema: true });
      setFormData(dataInitialized);
    }
  }, [dataRegistrationUpdate]);

  // NOTE: 4. WHEN VALIDATION CONFIRMED, PROCESS IS COMPLETE + NAVIGATE..

  useEffect(() => {
    if (!IS_BUSY && isRegistrationFormComplete) {
      updateUserValidation(USER_ROLE.REGISTERED).then(({ data }) => {
        // navigate('/account', { replace: true });
        window.location.href = '/account';
      });
    }
  }, [isRegistrationFormComplete]);

  // ======================================================================== //

  if (IS_BUSY) {
    return <Spinner page dark />;
  }

  const isNavBlockerActive = !isRegistrationFormComplete && !updateUserValidation;

  return (
    <section css={styles}>
      <Row>
        <Col xs={12}>
          <Form
            formConfig={dataConfiguration}
            data={{ ...formData, email: formData?.email ?? user?.email }}
            schema={schema}
            isNavPromptActive={isNavBlockerActive}
          />
        </Col>
      </Row>
      {isDevToolsVisible && formData && <DevButtons formData={formData} />}
    </section>
  );
};

export default AccountRegistration;
