import { ACTIONS, type DataConfigurationType } from 'types';
import { PageHeader } from 'components/PageHeader';
import { useEffect } from 'react';
import { type RouteConfig } from 'routes';
import { useRouter } from 'routes/hooks';
import { useAuth } from 'auth/AuthContext';
import { useTranslation } from 'react-i18next';
import { sleep } from 'utils/utils.misc';
import { usePageContentActions } from 'pages/Layout/PageContentContext/PageContent.hooks';

export type PageTitleOverrides = {
  config?: DataConfigurationType;
  title?: string;
  detail?: string;
  subtitle?: string;
};

export const usePageHeader = ({ config }: PageTitleOverrides = {}) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { params, isUserPath, route, category, action } = useRouter();

  // TODO: MOVE TO UTIL...
  const { setPageTitle, setPageDetail, setPageSubtitle } = usePageContentActions();

  const isNew = !!(params?.action && params.action === ACTIONS.NEW);

  const setPageHeaderTitles = async ({ name, title, detail, subtitle }: any = {}) => {
    await sleep(50);

    if (name || title || detail || subtitle) {
      log('page title overrides', 'grey', { name, title, detail, subtitle });
    }

    // NOTE: User (registered) path...
    if (isUserPath) {
      if (action === ACTIONS.VIEW) {
        setPageTitle(route?.title);
        setPageDetail(route?.title);
        setPageSubtitle(route?.subtitle);
        return;
      }
      if (action === ACTIONS.EDIT) {
        setPageTitle(t('t.titles.registros'));
        setPageDetail(route?.title);
        setPageSubtitle(route?.subtitle);
        return;
      }
      if (action === ACTIONS.NEW) {
        setPageTitle(t('t.titles.create_new', { subject: category?.title }));
        setPageDetail(route?.title);
        setPageSubtitle(route?.subtitle);
        return;
      }
      setPageTitle(route?.title);
      setPageDetail(route?.detail);
      setPageSubtitle(route?.subtitle);
      return;
    }

    // NOTE: defaults..
    if (action === ACTIONS.VIEW) {
      setPageTitle(route?.parent?.title);
      setPageDetail(route?.title);
      setPageSubtitle(route?.subtitle);
      return;
    }
    if (action === ACTIONS.EDIT) {
      setPageTitle(route?.parent?.title);
      setPageDetail(route?.title);
      setPageSubtitle(route?.subtitle);
      return;
    }
    if (action === ACTIONS.NEW) {
      setPageTitle(t('t.titles.create_new', { subject: category?.title }));
      setPageTitle(route?.parent?.title);
      setPageDetail(route?.title);
      setPageSubtitle(route?.subtitle);
      return;
    }
    setPageTitle(route?.title);
    setPageDetail(route?.detail);
    setPageSubtitle(route?.subtitle);
  };

  return { setPageHeaderTitles };
};
