import type { Context } from 'react';
import { createContext } from 'react';
import type { DispatchContextType, GlobalStoreDispatch, GlobalStore } from './GlobalContext.types';
import { initialState } from './store.constants';

// TODO: use which type ?? - should be `DispatchContextType`, no ??
// const store = { ...useContext(GlobalContext), dispatch: useContext(DispatchContext) as DispatchContextType };

export const GlobalContext: Context<GlobalStore> = createContext<GlobalStore>(initialState as unknown as GlobalStore);
export const DispatchContext: Context<DispatchContextType> = createContext<DispatchContextType>(
  {} as DispatchContextType,
);
