import type { AuthenticationRequest, AuthenticationResult } from '@feathersjs/authentication';
import type { AuthClientOptions, AuthCredentials } from './auth.types';

export type AuthUser = {
  id: number;
  uuid: string;
  email: string;
  user_role: USER_ROLE;
  created_at?: number;
  updated_at?: number;
};

export enum USER_ROLE {
  AUTHENTICATOR = 'AUTHENTICATOR',
  ADMIN = 'ADMIN',
  VALIDATED = 'VALIDATED',
  REGISTERED = 'REGISTERED',
  PENDING = 'PENDING',
  GUEST = 'GUEST',
  PUBLIC = 'PUBLIC',
}

export interface StoredAuthenticatedUser extends Pick<AuthCredentials, 'email'> {
  id: number; // TODO: remove this DB `id`
  uuid: string;
}

export type AuthStorageOptions = Pick<AuthClientOptions, 'storage' | 'storageKey' | 'cookie'>;

export interface StoredAuthentication extends AuthenticationResult {
  authentication: any;
  accessToken: string;
  user?: AuthUser; // TODO: DEFINE ??
  uuid?: string;
}

export interface Params {
  authenticated?: boolean;
  authentication?: AuthenticationRequest;
}

// ======================================================================== //
// NOTE: UserSession types

export interface UserSessionAccess {
  accessToken: string;
  jti: string;
  expires: number;
  expiryHours: number;
}

export interface UserSessionUser {
  id: AuthUser['id'];
  email: AuthUser['email'];
  uuid: AuthUser['uuid'];
  user_role: AuthUser['user_role'];
}

export interface UserSession {
  access: UserSessionAccess;
  user: UserSessionUser;
}
