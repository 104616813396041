import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import type { ReactNode } from 'react';
import { SiteLockPage } from 'pages/SiteLockPage';
import { config } from 'config';
import { SiteLockProvider } from '../context/SiteLockProvider';
import { useAuth } from 'auth/AuthContext';

export const SiteLockLayer = ({ children }: { children: ReactNode }): ReactNode => {
  const { user } = useAuth();
  const location = useLocation();
  const isUnlocked = !!location?.state?.isSiteUnlocked;

  if (config.IS_SITE_LOCKED && !user && !isUnlocked) {
    return (
      <SiteLockProvider>
        <Routes>
          <Route path="*" element={<SiteLockPage />} />
        </Routes>
      </SiteLockProvider>
    );
  }

  return children;
};
