import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { useNavigateState } from 'hooks/useNavigateState';
import { LuConstruction as IconConstruction } from 'react-icons/lu';
import { useSiteLocker } from './context/SiteLockContext';
import { Alert } from 'components/Alert';
import { Card } from 'components/Card';
import { Form } from 'components/Form';
import { translate } from 'utils';
import { formConfig } from './sitelock.form';
import { deleteStoredGuest } from './sitelock.utils';
import { styles } from './SiteLockPage.css';

const SiteLockPage = (): ReactNode => {
  const { isSiteLocked, handleSubmit, mockValues } = useSiteLocker();
  const { t } = useTranslation();

  const { navigate } = useNavigateState();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    deleteStoredGuest();
    if (pathname !== '/') {
      navigate('/', { replace: true, state: { isUnlocked: false } });
    }
  }, []);

  return (
    <section css={styles}>
      <Card>
        <div className="form-container">
          <Alert color="warning">
            <IconConstruction onClick={mockValues} />
            <h4>{t('t.site-lock.title')}</h4>
            <p>{t('t.site-lock.subtitle')}</p>
            <p>{t('t.site-lock.text')}</p>
          </Alert>
          <Form formConfig={formConfig} onSubmit={handleSubmit} isNavPromptActive={false} />
        </div>
      </Card>
    </section>
  );
};

export { SiteLockPage };
