import type { DataEntry, ISchema } from 'types';
import type { AuthUser } from 'auth/types/user.types';

interface DataEntryExpanded extends DataEntry {
  json_meta: string;
  created_at: number;
  updated_at: number;
}

export const initEmptyData = ({
  endpoint = 'posts',
  schema,
  user,
}: {
  endpoint?: string;
  schema: ISchema;
  user: AuthUser;
}) => {
  const KEYS_DB_SCHEMA = Object.keys(schema || {});

  const keysAll: string[] = [];
  keysAll.push(...KEYS_DB_SCHEMA);

  const keysExpanded = [...new Set([...keysAll])];
  const dataInitialized = {} as DataEntryExpanded;

  if ('user_id' in schema) {
    dataInitialized.user_id = user?.uuid;
  }

  if (typeof dataInitialized.created_at === 'string') {
    dataInitialized.created_at = Date.now();
  }

  if (typeof dataInitialized.updated_at === 'string') {
    dataInitialized.updated_at = new Date(dataInitialized.updated_at).getTime();
  }

  const { json_meta: _, ...data } = dataInitialized;
  Object.assign(dataInitialized, { json_meta: JSON.stringify(data) });

  return dataInitialized;
};
