/* eslint-disable @typescript-eslint/no-shadow */
import { useState, useEffect } from 'react';
import type { AuthenticationResult } from '@feathersjs/authentication';
import { USER_ROLE } from 'auth/types/user.types';
import { UserAuthSession } from 'auth/storage/user.storage';
import { ConnectionToken } from 'auth/storage/auth.storage';
import { storeAuthentication } from 'auth/storage/user.storage';
import type { UseAuthClient } from './client.types';
import type { AuthClient, AuthClientAuthenticated, AuthClientOptions } from '../types/auth.types';
import type { StoredAuthentication, AuthStorageOptions, AuthUser, UserSession } from '../types/user.types';

// NOTE: NEW 2023 AUTH REF: https://www.bezkoder.com/react-hooks-jwt-auth/

// DOCS FOR NEW + SIMPLE  AUTH !! 😎
// https://ui.dev/react-router-protected-routes-authentication

// MORE !! includes redirect, permissons + roles !
// https://www.robinwieruch.de/react-router-private-routes/

// TODO:
// MAKE ALL-IN-ONE (Client+Admin) AS PER ANSWER 88, HERE:
// https://stackoverflow.com/questions/62384395/protected-route-with-react-router-v6

// AUTH PROVIDER (FULL):
// https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src%2FApp.tsx

// ======================================================================== //

export const useAuthClient = ({ client, authenticateClient, isAuthenticated }: UseAuthClient): any => {
  const [authenticatedUser, setAuthenticatedUser] = useState<AuthUser | null>(null);

  // ======================================================================== //

  const resetAuthentication = async ({ client }: { client: AuthClient }) => {
    const authentication = await client.get('authentication');
    if (authentication?.user?.user_role !== 'AUTHENTICATOR') {
      log('RESETTING_AUTH..', 'red', authentication?.user);
      await client.logout();
      await client.authentication.removeAccessToken();
      UserAuthSession.delete();
      setAuthenticatedUser(null); // NOTE: OLD..
      await authenticateClient();
    }
  };

  // ======================================================================== //

  const verifyAuthenticatedUser = async ({ client }: { client: AuthClient }) => {
    const newAuthentication = (await client.get('authentication')) as AuthClientAuthenticated;
    if (newAuthentication) {
      await storeAuthentication(newAuthentication as StoredAuthentication);
    }

    if (newAuthentication?.user && newAuthentication.user?.user_role !== USER_ROLE.AUTHENTICATOR) {
      log('AUTHENTICATING_CLIENT..', 'grey', { newAuthentication });
      setAuthenticatedUser(newAuthentication.user);
    } else {
      // setAuthenticatedUser(null); // TODO: REMOVE ????
      UserAuthSession.delete();
    }
  };

  const initAuthentication = async ({ client }: { client: AuthClient }) => {
    try {
      await verifyAuthenticatedUser({ client });
    } catch (error: unknown) {
      await authenticateClient();
      await verifyAuthenticatedUser({ client });
    }
  };

  // ======================================================================== //
  // TODO: TESTING MISSING AUTH...

  const verifyStoredAuthentication = async ({ client }: { client: AuthClient }) => {
    const { user } = await client.get('authentication');
    const { accessToken } = await client.get('authentication');
    log('IO_CLIENT: USER+TOKEN ======>> ', 'magenta', { user, accessToken });

    const storedAuth: UserSession = UserAuthSession.get();
    const AUTH_STORED_TOKEN = storedAuth?.access.accessToken;
    const AUTH_STORED_USER = storedAuth?.user;

    const isAuthStored = !!storedAuth;
    const isAuthTokenStored = !!AUTH_STORED_TOKEN;
    const isAuthUserStored = !!AUTH_STORED_USER;
    const isAuthUserLoggedIn = isAuthUserStored && !!user;

    const isAdminRoute = location.pathname.startsWith('/admin');

    log('1. AUTH_VERIFYING..', 'grey', { isAdminRoute }, { isAuthStored, isAuthUserStored, isAuthTokenStored });
    log('2. AUTH_TESTING..', 'grey', { TOKEN_LENGTH: Number(AUTH_STORED_TOKEN?.length), storedAuth });
    log('3. AUTH_TESTING..', 'grey', { AUTH_STORED_USER });
  };

  // ======================================================================== //

  const restoreAuthentication__V1 = async () => {
    const storedAuth: UserSession = UserAuthSession.get();
    if (storedAuth) {
      const authentication: AuthenticationResult = await client.get('authentication');
      if (UserAuthSession.isExpired) {
        authentication.user = storedAuth.user;
        authentication.token = storedAuth.access.accessToken;
      } else {
        UserAuthSession.delete();
        window.location.href = '/logout';
      }
    }
  };

  const restoreAuthentication = async ({ client }: { client: AuthClient }) => {
    const storedAuth: UserSession = UserAuthSession.get();
    if (storedAuth) {
      const authentication: AuthenticationResult = await client.get('authentication');
      if (UserAuthSession.isExpired) {
        authentication.user = storedAuth.user;
        authentication.token = storedAuth.access.accessToken;
      } else {
        UserAuthSession.delete();
        window.location.href = '/logout';
      }
    } else {
      await initAuthentication({ client });
      verifyAuthentication({ client });
    }
  };

  const verifyAuthentication = ({ client }: { client: AuthClient }) => {
    const TOKEN_AUTH_STORED = ConnectionToken.get();
    const hasAuthTokenStored = !!TOKEN_AUTH_STORED;
    log('AUTH_CHECK:', 'grey', {
      HAS_AUTH: !!client?.authentication?.authenticated,
      authentication: client?.authentication,
      isAuthenticated,
      hasAuthTokenStored,
      TOKEN_AUTH_STORED,
    });
  };

  useEffect(() => {
    const isAuthConnected: boolean = client.authentication.authenticated;
    // NOTE: V1 - DEV (WORKS, BUT MESSY..)
    const storedAuth = UserAuthSession.get();
    const isTokenExpired = UserAuthSession.isExpired;

    if (storedAuth) {
      if (isTokenExpired) {
        // TODO: add 'else' to restoreAuthentication
        // NOTE: expired session - delete local token
        UserAuthSession.delete();
        window.location.href = '/logout';
        return;
      }
      if (storedAuth?.user && !isAuthConnected) {
        // NOTE: retreives session via stored token (in case of hard-refresh..etc)
        initAuthentication({ client });
        return;
      }
    }

    verifyAuthentication({ client });

    // ======================================================================== //
    /*
    // NOTE: V2 - DEV (ERR)
    const storedAuth = UserAuthSession.get();
    if (storedAuth) {
      const isTokenExpired = UserAuthSession.isExpired;
      if (isTokenExpired) {
        log('client', 'blue', 1);
        UserAuthSession.delete();
        window.location.href = '/logout';
      } else if (!isAuthenticated && storedAuth?.user) {
        log('client', 'blue', 2);
        // UserAuthSession.delete();
        initAuthentication({ client });
      }
    } else {
      if (!isAuthenticated) {
        log('client', 'blue', 3);
        initAuthentication({ client });
      } else {
        log('client', 'blue', 4);
        verifyAuthentication({ client });
      }
    }
    */

    /*
    // NOTE: V3 - DEV (ERR)
    const storedAuth = UserAuthSession.get();
    if (storedAuth) {
      log('client', 'blue', 1);
      restoreAuthentication({ client });
    } else {
      log('client', 'blue', 2);
      verifyAuthentication({ client });
    }
    */

    /*
    // NOTE: V4 - DEV (ERR in FireFox..)
    const storedAuth = UserAuthSession.get();
    if (storedAuth) {
      log('client', 'blue', 1, { isAuthenticated, authenticateClient });
      restoreAuthentication({ client });
    } else {
      if (!isAuthenticated) {
        log('client', 'blue', 2, { isAuthenticated, authenticateClient });
        initAuthentication({ client });
      } else {
        log('client', 'blue', 3, { isAuthenticated, authenticateClient });
        verifyAuthentication({ client });
      }
    }
    */
  }, [isAuthenticated]);

  return {
    client,
    isAuthenticated: isAuthenticated || client.authentication.authenticated,
    authenticateClient,
    authenticatedUser,
    resetAuthentication,
    initAuthentication,
    verifyAuthentication,
  };
};
