import { createContext, useCallback, useContext, useReducer } from 'react';
import type { Context, ReactElement, ReactNode } from 'react';
import { dataFilterReducer } from './DataFilterReducer';
import { initialState } from './DataFilterState';
import type { DataFilterState } from './DataFilterState';

// NOTE: see also - https://dev.to/mpriour/generating-strongly-typed-reducer-actions-for-react-j77
// NOTE: IN-DEPTH REF - https://www.typescript-training.com/course/intermediate-v1/09-mapped-types/
// NOTE: EXCELLENT REF - https://blog.logrocket.com/how-to-use-keyof-operator-typescript/

export const DataFilterContext: Context<DataFilterState> = createContext<DataFilterState>(initialState);

export const DataFilterProvider = ({
  initState: initialStateOverrides,
  children,
}: {
  initState?: DataFilterState;
  children: ReactNode;
}): ReactElement => {
  const [state, dispatch] = useReducer(dataFilterReducer, { ...initialState, ...initialStateOverrides });

  const setDataFilter = useCallback((payload: { [key in keyof DataFilterState]: unknown }) => {
    dispatch({ type: 'SET_FIELDS', payload });
  }, []);

  const initialValue: DataFilterState = { ...state, setDataFilter };

  return <DataFilterContext.Provider value={{ ...initialValue }}>{children}</DataFilterContext.Provider>;
};

export const useDataFilter = (): DataFilterState => useContext(DataFilterContext);
