import { useTranslation } from 'react-i18next';
import { type ReactElement } from 'react';
import loadable, { LoadableComponent } from '@loadable/component';
import { Card } from 'components/Card';
import { TabbedContent } from 'components/TabbedContent';
import type { TabDefinition } from 'components/TabbedContent/Tabs.types';
import { styles } from './LoginPage.css';
import { config } from 'config';
import { isAdminPath } from 'routes';
import { useAuth } from 'auth/AuthContext';
import { Spinner } from 'components/Spinner/Spinner';

// NOTE: GREAT GUIDE TO BEST-PRACTICES FOR LOGIN-FORMS:
// ref: https://evilmartians.com/chronicles/html-best-practices-for-login-and-signup-forms

// NOTE: CONSIDER USING NEW "PASSKEYS", INSTEAD OF PASSWORDS:
// https://www.passkeys.io

const LoginPage = (): ReactElement => {
  const { t } = useTranslation();
  const { isAuthorizing } = useAuth();
  const PATH_BASE = isAdminPath() ? config.PATH_BASE_ADMIN : config.PATH_BASE_WEB;

  const LoginForm = loadable(
    (): Promise<any> => import('./forms/LoginForm/LoginForm'),
  ) as LoadableComponent<ReactElement>;

  const RegisterForm = loadable(
    (): Promise<any> => import('./forms/RegisterForm/RegisterForm'),
  ) as LoadableComponent<ReactElement>;

  const tabs: TabDefinition[] = [
    {
      id: 'tab-login',
      path: 'login',
      label: t('t.login.tab'),
      element: LoginForm as unknown as ReactElement,
      accentColor: 'info',
      isVisible: true,
    },
    {
      id: 'tab-register',
      path: 'register-user-new',
      label: t('t.register-owner.tab'),
      element: RegisterForm as unknown as ReactElement,
      accentColor: 'success',
      isVisible: true,
    },
  ];

  const visibleTabs = tabs.filter((tab: TabDefinition) => tab.isVisible);
  const activeTab = visibleTabs.find(
    (tab: TabDefinition) => `${PATH_BASE}/${tab.path}` === location.pathname,
  ) as TabDefinition;

  return (
    <section css={styles}>
      <Card>
        <TabbedContent tabs={visibleTabs} activeTab={activeTab} />
        {isAuthorizing && <Spinner page />}
      </Card>
    </section>
  );
};

export { LoginPage };
