import { ReactElement, useEffect, useState } from 'react';
import { format } from 'date-fns';
import type { DataEntry, TableConfig } from 'types';
import { useRouter } from 'routes/hooks';
import { useRoutesMeta } from 'routes/hooks/useRoutesMeta/useRoutesMeta';
import { useQueryGet } from 'api';
import { DataFilterProvider, initialState as initialData } from 'store/DataFilterContext';
import { DATE_FORMAT, DATETIME_FORMAT } from 'i18n/datetime';
import type { DataColumnConfig } from 'components/DataTable/DataTable.types';
import { Spinner } from 'components/Spinner';
import { isArray } from 'utils';
import { useGenMockEventData } from 'mocks/generators/useGenMockEventData';
import { ES } from 'config';
import { useDataConiguration } from 'config/data.utils/useDataConiguration';
// import { tableConfig as dataConfiguration } from 'config/datatype/eventos/eventos.table';
import { ASC } from 'constants/sorting';
import { EventFiltersProvider } from './EventFiltersContext';
import { EventsLayout } from './EventsLayout';
import { styles } from './EventsPage.css';
import { usePageContentActions } from 'pages/Layout/PageContentContext/PageContent.hooks';
import { DataConfiguration } from 'routes/utils/getDynamicRoutes.types';

export type EventsPageProps = {
  endpoint?: string;
  tableConfig?: DataColumnConfig | any;
};

const EventsPage = ({ dataConfig }: DataConfiguration): ReactElement => {
  const defaultConfig: TableConfig = dataConfig.tableConfig;
  const DATE_TODAY = format(new Date(), DATE_FORMAT.DEFAULT, { locale: ES });
  // const DATE_TODAY = format(new Date(), DATETIME_FORMAT.DEFAULT, { locale: ES });

  const { params } = useRouter();
  const { setPageSubtitle } = usePageContentActions();
  const { getDataWithsPathsApplied } = useRoutesMeta();

  const { endpoint, dataConfiguration } = useDataConiguration<TableConfig>({ defaultConfig, role: 'table' });

  // 1. FULL DATA SET
  const [data, setData] = useState<DataEntry[]>([]);
  // endpoint ??= dataConfiguration?.api?.endpoint || (params.table as string) || 'posts';

  const queryStartDate = DATE_TODAY;

  // FETCH DATA ============================================================= //

  const {
    data: response,
    isFetching,
    isSuccess,
    refetch: doFetch,
  } = useQueryGet({
    endpoint: 'eventos',
    query: { date: { $gt: queryStartDate } },
    // date: filters.dateSelected.value
    //   ? { $gt: `${queryStartDate} 00:00:00`, $lt: `${queryStartDate} 23:59:59` }
    //   : { $gt: queryStartDate },
    filter: { $sort: { date: ASC } },
    enabled: false,
  });

  useEffect(() => {
    doFetch();
    setPageSubtitle('');
  }, [doFetch, params?.cat]);

  useEffect(() => {
    if (isSuccess) {
      const dataValid = getDataWithsPathsApplied({ data: response, slug: 'eventos' });
      setData(dataValid || []);
    }
  }, [isSuccess, location.pathname]);

  // ======================================================================== //
  // ======================================================================== //

  // // TODO: DEV ONLY .. REMOVE !!
  // const { genMockData } = useGenMockEventData<TableConfig>({ endpoint: 'eventos' });

  useEffect(() => {
    if (isSuccess && response.length) {
      // genMockData({ data: response });
    }
  }, [isSuccess]);

  // ======================================================================== //
  // ======================================================================== //

  if (!dataConfiguration || isFetching || !data.length) {
    return <Spinner page dark />;
  }

  return (
    <section css={styles}>
      {isFetching && <Spinner page dark />}
      {!isFetching && (
        <DataFilterProvider initState={{ ...initialData, data }}>
          <EventFiltersProvider>
            <EventsLayout
              doFetch={doFetch}
              isFetching={isFetching}
              data={data}
              tableConfig={dataConfiguration}
            />
          </EventFiltersProvider>
        </DataFilterProvider>
      )}
    </section>
  );
};

export default EventsPage;
