import { Visible } from 'react-grid-system';
import type { ReactElement } from 'react';
import { Fragment } from 'react';
import { css } from '@emotion/react';
import { useKeyPress } from 'hooks/useKeyPress';
import { useLayout } from 'pages/Layout/context/LayoutContext';
import { NODE_ENV } from 'config';
import { layout } from 'styles/layout.css';
import { min } from 'styles/media-queries-V1';
import { isLoggedOutPath } from 'routes';

export const styles = css`
  padding: 0 10px;
  position: fixed;
  left: calc(50vw) !important;
  transform: translate(calc(-50% - (${layout.padding} * 2)), 0px) !important;
  z-index: 999999999999;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  &,
  &.variant-light {
    pre {
      color: white !important;
    }
  }

  &.variant-dark {
    pre {
      color: #444 !important;
    }
  }

  top: 150px;
  ${min.sm} {
    top: 150px;
  }
  ${min.md} {
    top: 150px;
  }
  ${min.lg} {
    top: 150px;
  }
  ${min.xl} {
    top: 150px;
  }

  top: ${layout.padding} !important;
  top: 0 !important;

  pre {
    opacity: 0.66;
  }
`;

export const DevScreenSize = ({ variant = 'light' }: { variant?: 'light' | 'dark' }): ReactElement => {
  if (NODE_ENV === 'production') return <Fragment />;

  const { isDevToolsVisible, setLayoutState } = useLayout();
  useKeyPress();

  const handleClick = () => {
    setLayoutState({ isDevToolsVisible: !isDevToolsVisible });
  };

  if (isLoggedOutPath()) variant = 'dark';

  return (
    <div css={styles} onClick={handleClick} className={`dev-screen-size variant-${variant}`}>
      <pre>
        <span style={{ paddingRight: '1em' }}>{isDevToolsVisible ? '(DEV)' : ''}</span>
        <Visible xs>XS</Visible>
        <Visible sm>SM</Visible>
        <Visible md>MD</Visible>
        <Visible lg>LG</Visible>
        <Visible xl>XL</Visible>
        <Visible xxl>XXL</Visible> - {window.innerWidth}px
      </pre>
    </div>
  );
};
