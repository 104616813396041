import type { Method } from '@babel/types';
import { API_BASE_URL } from 'api';
import { PATTERNS, getFakerImageURL } from 'utils';
import { reverseString } from './utils.string.format';

export const getPaddedID = (id: string | number) => {
  const fixedLength = 3;
  const padChar = '0';
  const idString = `${id}`;
  const numZerosToPad = fixedLength - idString.length;
  const paddingString = padChar.repeat(numZerosToPad);
  return `${paddingString}${idString}`;
};

export const getThumbURL = (id: string | number): string => `${API_BASE_URL}/assets/thumbnails/${getPaddedID(id)}.png`;
export const getImageURL_V1 = (id: string | number): string => `${API_BASE_URL}/assets/images/${getPaddedID(id)}.png`;
export const getImageURL = ({ image, image_id }: { image?: string; image_id?: number | string }) => {
  if (image_id && image) {
    if (PATTERNS.URL.test(image as string)) {
      return image;
    }
    return `${API_BASE_URL}/uploads/images/${image}`;
  }

  // default / backup - random stock image
  return getFakerImageURL();
};

export const sleep = async (ms: number): Promise<Method> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getStamp = (): number => {
  const timestamp = String(new Date().getTime());
  const stamp = timestamp.slice(timestamp.length - 6);
  const stampReversed = reverseString(stamp);

  return Number(stampReversed);
};
