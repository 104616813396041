import { NavLink } from 'react-router-dom';
import { useRouter } from 'routes/hooks';
import { config } from 'config';

export const HeaderTitle = () => {
  const { PATH_BASE } = useRouter();
  return (
    <NavLink to={PATH_BASE || '/'} className="title-app">
      {config.appName}
    </NavLink>
  );
};
