import type { ReducerActions } from 'types/ReducerActions';
import type { EventFiltersState } from './EventFiltersState';

// ======================================================================== //
// EVENTS FILTER REDUCER:

// GENERIC REDUCER ACTIONS
// REF: https://github.com/microsoft/TypeScript/issues/45541

export const eventFiltersReducer = (
  state: EventFiltersState,
  action: ReducerActions<EventFiltersState>,
): EventFiltersState => {
  switch (action.type) {
    case 'set_dateRange':
      return { ...state, dateRange: action.payload };
    case 'set_dateSelected':
      return { ...state, dateSelected: action.payload };
    case 'set_province':
      return { ...state, province: action.payload };
    default:
      return { ...state, ...action.payload };
  }
};
