import { useFormContext, useWatch } from 'react-hook-form';
import { useState, useEffect, useRef } from 'react';
import { ArrayJSX } from 'utils/ArrayJSX';
import { useDebounce } from '@uidotdev/usehooks';
import { getMockSenderEnhancer } from '@rpldy/mock-sender';
import { styles } from './InputImageURL.css';
import { FieldConfig } from 'types/form.types';
import { STATUS } from 'constants/status';
import Uploady from '@rpldy/uploady';
import type { PreviewStatus } from 'types/status.types';
import { FaRegImage as IconImage } from 'react-icons/fa';
import { RiDeleteBack2Fill as IconDelete } from 'react-icons/ri';
import { ImagePreviewUploady } from './ImagePreviewUploady';
import { EMPTY_STRING, DEFAULT_PLACEHOLDER, DEFAULT_PREVIEW_HEIGHT } from './InputImageURL.constants';
import { Col, Row } from 'react-grid-system';

// ======================================================================== //

const mockSenderEnhancer = getMockSenderEnhancer({ delay: 1500 });

// TODO: SEE THESE EXAMPLES..
// NOTE: ref - https://codesandbox.io/p/sandbox/react-uploady-dnd-with-asuploadbutton-jk9bg?file=%2Fsrc%2FApp.js%3A40%2C4-40%2C44
// NOTE: ref - https://codesandbox.io/p/sandbox/react-uploady-paste-to-upload-demo-usx9s?file=%2Fsrc%2Findex.js
// NOTE: ref - https://codesandbox.io/p/sandbox/react-uploady-add-and-remove-before-upload-vk1rs5?file=%2Fsrc%2FApp.js%3A2%2C1-2%2C40

// TODO: HERE !!!
// https://react-uploady.org/docs/api/types/#uploadyContextType
// PreviewOptions

// const EMPTY_STRING = '';
// const DEFAULT_PLACEHOLDER = 'Escoge archivo...';
// const TEST_QUERY =
//   'https://upload.wikimedia.org/wikipedia/commons/1/14/Mickey_mouse_-_mad_doctor_1933_-_domini_p%C3%BAblic.jpg';
// const PLACEHOLDER_IMAGE = 'assets/img-placeholder-1000x600.png';
// const IMG_FALLBACK = 'assets/img-placeholder-1000x600.png';
// const DEFAULT_PREVIEW_HEIGHT = 300;

export const InputImageURL = ({
  name,
  defaultValue,
  field,
  height = DEFAULT_PREVIEW_HEIGHT,
  inline = false,
}: {
  name: string;
  defaultValue: string;
  field: FieldConfig;
  height: number;
  inline: boolean;
}) => {
  const { register, control, setValue } = useFormContext();
  const value = useWatch({ control, name });
  const imgRef = useRef<HTMLImageElement>(null);
  const [status, setStatus] = useState<PreviewStatus>(STATUS.IDLE);
  const [remotePreviewURL, setRemotePreviewURL] = useState<string>();
  const [userQueryURL, setUserQueryURL] = useState<string>();
  const debouncedQueryURL = useDebounce(userQueryURL, 600);

  // ======================================================================== //

  type ImageSelection = {
    name: string;
    size: number;
    type: string; /// NOTE: MIME TYPE
    lastModified: number;
    webkitRelativePath: string;
  };

  const [file, setFile] = useState<File>();
  const [image, setImage] = useState<ImageSelection>();
  const [items, setItems] = useState([]);
  const [localPreviewURL, setLocalPreviewURL] = useState<string | null>(null);

  const fileProperties = file
    ? {
        name: file.name,
        size: file.size,
        type: file.type,
        lastModified: file.lastModified,
        webkitRelativePath: file.webkitRelativePath,
      }
    : null;

  // ======================================================================== //

  useEffect(() => {
    if (file) {
      const fileImage: ImageSelection = {
        name: file.name,
        size: file.size,
        type: file.type,
        lastModified: file.lastModified,
        webkitRelativePath: file.webkitRelativePath,
      };
      setImage(fileImage);
      setValue(name, fileImage.name, { shouldDirty: true });
      setStatus(STATUS.SUCCESS);
    }
  }, [file]);

  // ======================================================================== //

  useEffect(() => {
    const newState = file ? STATUS.SUCCESS : String(value).length > 2 ? STATUS.LOADING : STATUS.IDLE;
    setStatus(newState);
    setRemotePreviewURL(undefined);
    setUserQueryURL(value);
  }, [value]);

  const loadImage = async (url: string) => {
    const img = imgRef.current as HTMLImageElement;
    return new Promise((resolve, reject) => {
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = url;
    });
  };

  /* stylesheet://localhost/registros/cat/canales/economia/edit/style#style-sheet-2650-28656 */

  useEffect(() => {
    const handleChangeQueryURL = async (url: string) => {
      if (debouncedQueryURL) {
        try {
          await loadImage(url);
          setStatus(STATUS.SUCCESS);
          setRemotePreviewURL(url);
          setLocalPreviewURL(undefined);
        } catch (error) {
          if (!file) {
            setStatus(STATUS.ERROR);
            setRemotePreviewURL(undefined);
          }
        }
      }
    };

    handleChangeQueryURL(debouncedQueryURL as string);
  }, [debouncedQueryURL]);

  // HTML INPUT ============================================================= //
  // TODO: FIND LESS DIRTY WAY TO NAME THE ACTUAL <input type="file" />
  // THE image-upload-react DOES NOT ALLOW TO SET A CUSTOM name ATTR - IS ALWAYS "photo" - STRANGE !!
  const _inputSelector = `.fileupload-${name} input[type="file"]`;
  const inputSelector = `.fileupload-${name} input[type="url"]`;
  const inputElement = document.querySelector(inputSelector) as HTMLInputElement;
  if (inputElement) {
    inputElement.name = name;
    inputElement.placeholder = DEFAULT_PLACEHOLDER;
  }

  // USER EVENTS ============================================================ //

  const handleOpenSelect = () => {
    // WHEN TRIGGERED FROM ANOTHER/OUTSIDE ELEMENT
    inputElement.click();
  };

  const handleClearValue = () => {
    setValue(name, EMPTY_STRING, { shouldDirty: true });
    setStatus(STATUS.IDLE);
    setImage(undefined);
    setLocalPreviewURL(undefined);
    setItems(undefined);
    setFile(undefined);
  };

  // const handleImageSelect = (evt: InputEvent | MouseEvent | any) => {
  //   const file = evt.target.files[0];
  //   setValue(name, file, { shouldDirty: true });
  // };

  // ------------------------------------------------------------------------ //

  const cssStatus = new ArrayJSX();
  status === STATUS.LOADING && cssStatus.push('loading');
  status === STATUS.ERROR && cssStatus.push('error');

  return (
    <div css={styles} className={cssStatus.inline()} id="___FILE___">
      <Uploady
        debug
        autoUpload={false}
        enhancer={mockSenderEnhancer}
        destination={{
          url: 'https://localhost:3030/files',
          // headers: { 'x-custom': '123' }
        }}
      >
        <img className="img-loader" ref={imgRef} />
        <Row className="fileupload-input-row">
          {/* ------------------------------------------------------------------ */}
          <Col xs={12} className="col col-input">
            <div className="input-icon input-icon-left" onClick={handleOpenSelect}>
              <IconImage className="icon icon-select" />
            </div>
            {value && (
              <div
                className={`input-icon input-icon-right ${cssStatus.inline()} danger`}
                onClick={handleClearValue}
              >
                <IconDelete className="icon icon-delete" />
              </div>
            )}
            <input
              {...register(name)}
              type="url"
              placeholder="https://cdn.example.com/image.jpg"
              pattern="https?://.*"
              aria-invalid={status === STATUS.ERROR}
              className={`fileupload-${name}`}
              autoComplete="false"
            />
          </Col>
          {/* ------------------------------------------------------------------ */}
          <Col xs={12} className="col col-input">
            <ImagePreviewUploady
              status={status}
              setStatus={status}
              image={image}
              setImage={setImage}
              localPreviewURL={localPreviewURL}
              setLocalPreviewURL={setLocalPreviewURL}
              items={items}
              setItems={setItems}
              file={file}
              setFile={setFile}
              remotePreviewURL={remotePreviewURL}
              hiehgt={height}
            />
          </Col>
          {/* ------------------------------------------------------------------ */}
        </Row>
        <br />
        {/* <pre>localPreviewURL: {localPreviewURL}</pre>
        <pre>remotePreviewURL: {remotePreviewURL}</pre>
        <pre>FILE: {String(!!file)}</pre>
        <pre>STATUS: {JSON.stringify(status, null, 2)}</pre> */}
      </Uploady>
    </div>
  );
};
