/* eslint-disable new-cap */
import { css } from '@emotion/react';
import Color from 'color';
import { colors, forms, layout, cssInputBox, cssInputText } from 'styles';

export const colorAccents = {
  xxlight: Color(colors.primaryXLight).lighten(0.7).hex(),
  xlight: Color(colors.primaryXLight).lighten(0.65).hex(),
  light: Color(colors.primaryXLight).lighten(0.6).hex(),
  med: Color(colors.primaryXLight).lighten(0.4).hex(),
  dark: Color(colors.primaryXLight).darken(0.25).hex(),
};

export const colorVariant = {
  light: Color(colors.grey).lighten(0.4).hex(),
  med: Color(colors.grey).lighten(0.6).hex(),
  bg: Color(colors.primaryXLight).lighten(0.7).desaturate(0.4).hex(),
};

export const styles = css`
  /*********************************** */

  border-style: solid;
  border-left-width: 2px;
  border-right-width: 2px;
  border-top-width: 1px;
  border-bottom-width: 1px;
  &:first-of-type {
    border-top-width: 2px;
  }
  &:last-of-type {
    border-bottom-width: 2px;
  }
  border-color: transparent;
  padding: 0;

  & > div {
    padding: 0 !important;
    margin: 0 !important;
  }

  margin: 0 calc(${layout.padding} * 1) 0 calc(${layout.padding} * 1);
  padding: calc(${layout.padding} * 1);

  .card-item {
    margin-bottom: calc(${layout.padding} * 0.5);

    & > div {
      display: flex;
      align-items: center !important;
    }

    .col {
      height: 100%;
      display: flex;
      justify-content: center;
    }

    .col-name {
      ${cssInputBox}
      border: 0;
      /* background: ${colorVariant.bg}; */
      width: 100% !important;
      display: flex;
      flex-direction: column;
      padding: calc(${layout.padding} * 1);
      input {
        /* border-color: ${colorVariant.light} !important; */
        &:focus {
          border-color: ${colors.primaryLight}!important;
        }
      }
    }

    .btn-remove {
      height: ${forms.inputs.height};
      position: absolute;
      right: calc(${layout.padding} * 0.5);
      svg {
        opacity: 0.85;
      }
    }

    .col-add {
      height: ${forms.inputs.height};
      display: none;
      button:disabled {
        border: none !important;
        background-color: transparent;
        color: ${colors.grey};
      }
    }

    &:last-of-type {
      .col-add {
        display: flex;
      }
      fieldset {
        margin-bottom: 0 !important;
      }
    }

    &:first-of-type:last-of-type {
      input.link-url[value=''] + button.btn-remove {
        display: none !important;
      }

      .col-add {
        display: flex;
      }
    }
  }

  .card-item > div {
    margin-right: calc(${layout.padding} * 0.5) !important;
  }

  .card-array {
  }

  /* COLUMNS ****************************************************** */

  fieldset.fieldset-name {
    display: flex;
    margin-bottom: 0;

    .input-select {
      .option-custom {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 0.5em calc(${layout.padding} * 3) 0.5em calc(${layout.padding} * 3);
      }

      .option-icon {
        position: absolute;
        transform: scale(1.3) translateX(-1.8em) translateY(0);
        /* transform: scale(1.3) translateX(-1.8em) translateY(-40%); */
        margin: 1em 1.25em 1em 0.5em;
      }

      .select__single-value .option-icon {
        transform: scale(1.3) translateX(-2.2em) translateY(0);
      }
    }

    .input-select,
    .link-name input,
    .link-url input {
      &,
      &:focus {
        outline: none; /* POOR ACCESSIBILITY */
      }
      &:focus {
        border-color: ${colors.primaryLight}!important;
      }
    }

    .input-select {
      /* flex: 1, 1, 100px; */
      width: 250px;
    }

    .link-name {
      flex: 3 1;
      margin: 0 calc(${layout.padding} * 0.5);
    }

    .link-url {
      flex: 5 1;
      /* margin: 0 calc(${layout.padding} * 0.5); */
    }

    input:not(button):not([type='button']),
    input:read-only {
      /* background: transparent; */
      ${cssInputBox}
      ${cssInputText}
      /* border-color: ${colorAccents.med}; */
      /* background: ${colorAccents.xxlight}; */
      background: transparent;
      /* color: ${colorAccents.dark}; */
      /* -webkit-text-fill-color: ${colorAccents.dark}; */
      color: ${colors.text}!important;
      -webkit-text-fill-color: ${colors.text};
    }
    input:focus {
      /* border-color: ${colors.greyLight}!important; */
      background: white !important;
      color: ${colors.text}!important;
      -webkit-text-fill-color: ${colors.text}!important;
    }
    input[aria-invalid='true'] {
      /* border-color: ${colors.warningXXLight}!important; */
      background: white !important;
      color: ${colors.text}!important;
      -webkit-text-fill-color: ${colors.text}!important;
    }
  }

  .card-item {
    & > div:last-of-type {
      margin-right: 0 !important;
    }
  }

  margin: 0 !important;
`;
