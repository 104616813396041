import { ReactNode } from 'react';
import { RiCloseCircleFill as IconRemove } from 'react-icons/ri';
import { Button } from 'components/Button';
import { styles } from './Tag.css';

// export const Tag = ({ type = 'info', label, handleRemove, children }: any) => {
export const Tag = ({
  type = 'info',
  label,
  handleRemove,
  children,
}: {
  type?: string;
  label?: string;
  handleRemove?: any;
  children?: ReactNode;
}) => {
  return (
    <div css={styles} className={`tag tag-${type}`} style={handleRemove && { paddingRight: 0 }}>
      {label && <label>{label}</label>}
      {!label && children}
      {handleRemove && (
        <Button
          variant="clear"
          color="greyDark"
          icon={<IconRemove />}
          iconScale={1.3}
          onClick={() => handleRemove(label)}
        />
      )}
    </div>
  );
};
