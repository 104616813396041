import { Col, Row } from 'react-grid-system';
import { useFormContext } from 'react-hook-form';
import type { IconType } from 'react-icons';
import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import { Card } from 'components/Card';
import { Checkbox } from 'components/FormUI/Checkbox';
import { ArrayJSX } from 'utils/ArrayJSX';
import { styles } from './DetailFilter.css';

interface DetailFilterProps {
  field: string;
  label?: string;
  labelOff?: string;
  initValue: any;
  filtersState?: any;
  onToggle?: (value: unknown) => void;
  isActive?: boolean;
  isDisabled?: boolean;
  // iconOnCustom?: FC; // TODO: FC will be REMOVED FROM React
  // iconOnCustom?: ReactElement; // TODO: FC will be REMOVED FROM React
  iconOnCustom?: IconType | ReactElement; // TODO: FC will be REMOVED FROM React
  iconOffCustom?: IconType | ReactElement; // TODO: FC will be REMOVED FROM React
  iconOverride?: ReactElement;
  className?: string;
  children: ReactElement;
}

export const DetailFilter = ({
  field: FIELD_NAME,
  label,
  labelOff = label,
  className,
  initValue = { value: '', isActive: false },
  filtersState: { filters, setFilters },
  onToggle: handleToggleFilter,
  isActive: initIsActive = false,
  isDisabled = false,
  iconOnCustom,
  iconOffCustom,
  iconOverride: IconOverride,
  children,
}: DetailFilterProps) => {
  const { register, getValues, setValue } = useFormContext();
  // const [isActive, setIsActive] = useState(initIsActive);
  const [isActive, setIsActive] = useState(initIsActive);

  // useEffect(() => {
  //   if (onChange) {
  //     onChange(checked);
  //   }
  // }, [checked, value]);

  useEffect(() => {
    // setChecked(typeof value !== 'undefined' ? value : defaultValue);
    handleToggleFilter && handleToggleFilter(isActive);
    if (!isActive) {
      setValue(FIELD_NAME, { ...getValues(FIELD_NAME), isActive: false });
      setFilters({ [FIELD_NAME]: initValue });
    } else {
      setValue(FIELD_NAME, { ...getValues(FIELD_NAME), isActive: isActive });
      setFilters({ [FIELD_NAME]: { ...filters[FIELD_NAME], isActive: isActive } });
    }
  }, [isActive]);

  // TODO: CHECK PERFORMANCE + POSSIBLE CONFLICTS WITH FILTERS
  const handleToggle = (isChecked: boolean) => {
    setIsActive(isChecked);
    // handleToggleFilter && handleToggleFilter(isChecked);
    // if (!isChecked) {
    //   setValue(FIELD_NAME, { ...getValues(FIELD_NAME), isActive: false });
    //   setFilters({ [FIELD_NAME]: initValue });
    // } else {
    //   setValue(FIELD_NAME, { ...getValues(FIELD_NAME), isActive: isChecked });
    //   setFilters({ [FIELD_NAME]: { ...filters[FIELD_NAME], isActive: isChecked } });
    // }
  };

  // CSS CLASSES
  const cssClasses = new ArrayJSX(`filter-card-${FIELD_NAME}`);
  className && cssClasses.push(className);
  getValues(`${FIELD_NAME}.isActive`) && cssClasses.push('is-active');

  const contentsCSS = new ArrayJSX();
  isDisabled && contentsCSS.push('filter-content-disabled');
  !getValues(FIELD_NAME).isActive && contentsCSS.push('filter-content-is-checked');

  return (
    <Card css={styles} className={`filter-card ${cssClasses.inline()}`}>
      {/* <Row className="row" align="center">
        <Col xs={4}>
          <h2>{isActive ? label : labelOff} :</h2>
        </Col>
        <Col xs={8}>{children}</Col>
      </Row> */}
      <Row className="row" align="center">
        <Col xs={12}>{children}</Col>
      </Row>
    </Card>
  );
};
