import { Row, Col } from 'react-grid-system';
import type { UseFormReturn } from 'react-hook-form';
import { useFormState, useFormContext } from 'react-hook-form';
import { useEffect, memo } from 'react';
import type { ReactElement } from 'react';
import { v4 as uuid } from 'uuid';
import type { DataEntry, IGeneric } from 'types';
import { INPUTS, FORMAT, DEFAULT_VALUE } from 'types/form.types';
import { useRouter } from 'routes/hooks';
import type { IConfigRepeater, FieldConfig, FormConfig } from 'components/Form';
import { optionsSetLoud } from 'components/Form';
import { ErrorMessage } from 'components/Form/components/ErrorMessage/ErrorMessage-V1';

// LOADABLE COMPONENTS
import { FormFieldLabel } from 'components/FormFieldLabel';
import { Checkbox } from 'components/FormUI/Checkbox';
import { CheckboxGroup } from 'components/FormUI/CheckboxGroup/CheckboxGroup';
import { DatePicker } from 'components/FormUI/DatePicker';
import { FormCompletion } from 'components/FormUI/FormCompletion';
import { FormValuesJSON } from 'components/FormUI/FormValuesJSON';
import { InputField } from 'components/FormUI/InputField';
import { InputPassword } from 'components/FormUI/InputPassword';
import { InputRepeater } from 'components/FormUI/InputRepeater';
import { InputRepeaterURL } from 'components/FormUI/InputRepeaterURL';
import { InputTags } from 'components/FormUI/InputTags';
import { InviteList } from 'components/FormUI/InviteList';
import { RadioGroup } from 'components/FormUI/RadioGroup/RadioGroup';
import { Select } from 'components/FormUI/Select';
import { SelectMulti } from 'components/FormUI/SelectMulti';
import { SelectCategory } from 'components/FormUI/SelectCategory';
import { SelectCountry } from 'components/FormUI/SelectCountry';
import { Socials } from 'components/FormUI/Socials';
import { Switch } from 'components/FormUI/Switch';
import { TextArea, TextAreaRich } from 'components/FormUI/TextArea';
import { YoutubeURL } from 'components/FormUI/YoutubeURL';
import { ArrayJSX } from 'utils/ArrayJSX';
import { isSet } from 'utils/utils.object';
import { getFieldWidth, handleErrors } from './formField.utils';

// ======================================================================== //

// NOTE: ✅ V2 - GREAT - TYPE IN URL, WITH LIVE PREVIEW
import { InputImageURL } from 'components/FormUI/InputImageURL';
import { styles } from './FormField.css';

// NOTE: GREAT REF: REACT-QUERY + REACT-HOOK-FORM:
// https://tkdodo.eu/blog/react-query-and-forms#invalidate-and-reset-after-mutation

// TODO: AMAZING BEST-PRACTICES REF FOR FORMS:
// ref: https://blog.logrocket.com/style-forms-css/

export interface IFormField extends IGeneric, FieldConfig {
  field: FieldConfig;
  formConfig?: FormConfig;
  formMethods?: UseFormReturn;
  initialValue?: DataEntry | string | number | boolean;
  isFormLocked?: boolean;
}

const FormFieldInput = memo(
  ({
    field,
    formMethods,
    isFormLocked,
  }: {
    field: FieldConfig;
    formMethods: UseFormReturn;
    isFormLocked: boolean;
  }): ReactElement => {
    const { register, setValue, clearErrors, getValues, getFieldState, formState } =
      formMethods as UseFormReturn;
    const { name, inputValidation } = field;
    const { route } = useRouter();

    // ERRORS + VALIDATION =================================================== //

    const { isValid, errors } = useFormState({ name: field.name });
    // const { isDirty, isValid, errors } = useFormState({ name: field.name });
    const { isDirty, isTouched, invalid, error } = getFieldState(field.name);

    // log(`FIELD_STATE: %c${field.name} -`, 'orange', { isDirty, isTouched, invalid, error });

    const { hasWarning, hasError, errorProps } = handleErrors({
      field,
      methods: formMethods as UseFormReturn,
    });

    // useEffect(() => {
    //   if (hasWarning) {
    //     log(`FORM: has warning: %c${field.name}`, 'orange', hasWarning);
    //     // clearErrors(); // TODO: IN or OUT ?????
    //   } else if (hasError) {
    //     log(`FORM: has error: %c${field.name}`, 'red', hasError);
    //   }
    // }, [hasWarning, hasError]);

    // =============================================================== //

    const setIsControlledByStatus = (field: FieldConfig) => {
      if (!field.isControlledBy) return;

      log('__BY_HIDDEN -----------------------------', 'grey');

      const { criteria, target } = field.isControlledBy;
      const [criteriaKey, criteriaValue] = Object.entries(criteria)[0];
      const currentSourceValue = getValues(criteriaKey);

      log('__BY_HIDDEN - 1', 'grey', { criteria, target });
      log('__BY_HIDDEN - 2', 'orange', { criteriaKey, criteriaValue });
      log('__BY_HIDDEN - 3', 'lime', { currentSourceValue });

      for (const [targetKey, targetConditionedValue] of Object.entries(target)) {
        if (typeof targetConditionedValue === 'boolean') {
          // const isCriteriaMet = !!(Number(currentSourceValue) === Number(criteriaValue));
          const isCriteriaMet = !!(currentSourceValue === criteriaValue);

          // eslint-disable-next-line prettier/prettier
          (field as IGeneric)[targetKey] = isCriteriaMet ? targetConditionedValue : !targetConditionedValue;

          if (!isCriteriaMet) {
            field.value = null;
          }

          if (!isCriteriaMet && targetKey === 'isHidden') {
            log('__BY_HIDDEN - isHidden', 'blue', field);
            // setValue(field.name, '', {
            //   shouldValidate: false,
            //   shouldDirty: true,
            //   shouldTouch: false,
            // });
          }

          if (!isCriteriaMet && targetKey === 'isDisabled') {
            log('__BY_HIDDEN - isDisabled', 'blue', field);
            // setValue(field.name, '', {
            //   shouldValidate: false,
            //   shouldDirty: true,
            //   shouldTouch: false,
            // });
          }

          // if (isCriteriaMet && targetKey === 'isDisabled') {
          //   field.isDisabled = false;
          //   log('MET_DISABLED', 'lime', field.name, field);
          // }
        }
      }
    };

    // if (field.isControlledBy) {
    //   setIsControlledByStatus(field);
    // }

    // if (field.name === 'accept_terms') {
    //   log('accept_terms', 'grey', field);
    // }

    const setValueCustom = (value: unknown) => {
      log(`__DEV: setValue - ${field.name}`, 'magenta', value);
      setValue(field.name, value, optionsSetLoud);
    };

    hasError && log('errors', 'red', errors);

    // =============================================================== //

    /*
  // CSS-ONLY POSSIBLE
  field.inputValidation?.required && cssClasses.push('required');
  isDirty && cssClasses.push('is-dirty');
  hasError && formState.submitCount > 0 && getValues(field.name)?.length >= 3 && cssClasses.push('has-error');
  hasWarning && cssClasses.push('has-warning');
  isFormLocked && cssClasses.push('is-form-locked');
  // [INPUTS.checkbox, INPUTS.switch].includes(field.inputType as INPUTS) && cssClasses.push('field');
*/

    const props = field?.props ?? {};

    // if (field.name === 'cat_id' && Object.values(DEFAULT_VALUE).includes(field.defaultValue)) {
    //   if (field?.defaultValue === DEFAULT_VALUE.cat_id) {
    //     setValue(field.name, route.uuid);
    //   }
    // }

    switch (field.inputType) {
      case 'text':
      case 'number':
      case 'email':
      case 'url':
      case 'tel':
      case 'time':
        return (
          <input
            {...register(name, { ...inputValidation })}
            defaultValue={field.value}
            // value={field.value}
            type={field.inputType}
            placeholder={field.placeholder}
            autoComplete={field.autoComplete || 'off'}
            readOnly={field.readOnly && true}
            disabled={isFormLocked && true}
            aria-invalid={!isValid}
            aria-errormessage={errors?.[name]?.message}
            {...props}
          />
        );

      case 'password':
        return <InputPassword field={field} {...props} />;

      case 'uuid':
        // log('DATA_UUID', 'orange', name, field);
        return (
          <input
            {...register(name, { ...inputValidation })}
            // defaultValue={field.defaultValue || field.value}
            // defaultValue={!field.value && field.defaultValue === DEFAULT_VALUE.NEW_UUID ? uuid() : uuid()}
            // value={
            //   !field.value && field.defaultValue === DEFAULT_VALUE.NEW_UUID
            //     ? uuid()
            //     : field.defaultValue || field.value
            // }
            // defaultValue={
            //   !field.value && field.defaultValue === DEFAULT_VALUE.NEW_UUID ? uuid() : `XX${field.value}`
            // }
            // value={!field.value && field.defaultValue === DEFAULT_VALUE.NEW_UUID ? uuid() : `XX${field.value}`}
            value={field.value}
            placeholder={field.placeholder}
            autoComplete={field.autoComplete}
            readOnly={field.readOnly}
            disabled={isFormLocked}
            type={!field.isHidden ? 'text' : 'hidden'}
          />
        );

      case 'textarea':
        // TODO: FIX !! -- getting `ref` error in console
        // return <TextArea {...register(name, { ...inputValidation })} field={field} disabled={isFormLocked} />;
        return (
          <textarea
            {...register(field.name, { ...field.inputValidation })}
            rows={field?.attributes?.rows || 3}
            disabled={isFormLocked || field.isDisabled}
          />
        );

      case 'text_rich':
        return (
          <TextAreaRich {...register(name, { ...inputValidation })} field={field} disabled={isFormLocked} />
        );

      case 'invited':
        return (
          <InviteList
            {...register(name, { ...inputValidation })}
            field={field}
            defaultValue={field.value}
            onChange={setValueCustom}
            isDisabled={field.isDisabled}
            style={{ opacity: field.isDisabled ? 0.33 : 1 }}
            {...field?.props}
          />
        );

      case 'select':
        return (
          <Select
            {...register(name, { ...inputValidation })}
            field={field}
            options={field.options}
            optionsAsync={field?.optionsAsync}
            defaultValue={field.value}
            onChange={setValueCustom}
            isDisabled={field.isDisabled}
            style={{ opacity: field.isDisabled ? 0.33 : 1 }}
            {...field?.props}
          />
        );

      case 'selectMulti':
        return (
          <SelectMulti
            {...register(name, { ...inputValidation })}
            field={field}
            options={field.options}
            optionsAsync={field?.optionsAsync}
            defaultValue={field.value}
            onChange={setValueCustom}
            isDisabled={field.isDisabled}
            style={{ opacity: field.isDisabled ? 0.33 : 1 }}
            {...field?.props}
          />
        );

      case 'selectCategory':
        return (
          <SelectCategory
            {...register(name, { ...inputValidation })}
            field={field}
            options={field.options}
            defaultValue={field.value || route?.category?.uuid}
            onChange={setValueCustom}
            {...field?.props}
          />
        );

      case 'selectCountry':
        return (
          <SelectCountry
            {...register(name, { ...inputValidation })}
            field={field}
            options={field.options}
            defaultValue={field.value}
            // onChange={setValueCustom}
            {...field?.props}
          />
        );

      case 'repeater':
        return (
          <InputRepeater
            // {...register(name, { ...inputValidation })}
            name={field.name}
            label={field.label}
            field={field}
            defaultValue={field.value}
            repeaterConfig={field?.repeaterConfig as IConfigRepeater}
            onChange={setValueCustom}
          />
        );

      case 'repeaterURL':
        return (
          <InputRepeaterURL
            // {...register(name, { ...inputValidation })}
            name={field.name}
            label={field.label}
            field={field}
            defaultValue={field.value}
            repeaterConfig={field?.repeaterConfig as IConfigRepeater}
            onChange={setValueCustom}
          />
        );

      case 'tags':
        return (
          <InputTags
            {...register(name, { ...inputValidation })}
            defaultValue={field.value}
            tagsConfig={field?.tagsConfig}
            onChange={setValueCustom}
            {...field?.props}
          />
        );

      case 'checkboxes':
        return (
          <CheckboxGroup
            {...register(name, { ...inputValidation })}
            label={field.label}
            options={field.options}
            defaultValue={field.value}
            onChange={setValueCustom}
            {...field?.props}
          />
        );

      case 'checkbox':
        return (
          <Checkbox
            {...register(name, { ...inputValidation })}
            // name={field.name}
            field={field}
            label={field.label}
            required={field.required}
            isDisabled={field.isDisabled}
            defaultValue={field.value}
            onChange={setValueCustom}
            validation={{ hasWarning, hasError, errorProps }}
          />
        );

      case 'switch':
        return (
          <Switch
            {...register(name, { ...inputValidation })}
            name={field.name}
            label={field.label}
            defaultValue={field.value || field.defaultValue}
            onChange={setValueCustom}
          />
        );

      case 'radios':
        return (
          <RadioGroup
            {...register(name, { ...inputValidation })}
            label={field.label}
            options={field.options}
            defaultValue={field.value || field.defaultValue}
            onChange={setValueCustom}
            {...field?.props}
          />
        );

      case 'image':
        // ------------------------------------------------------------------------ //
        // NOTE: ✅ V1- MEH - TYPE IN URL, WITH LIVE PREVIEW
        // return (
        //   <FileUploadLegacy
        //     name={name}
        //     field={field}
        //     defaultValue={field.defaultValue}
        //     height={250}
        //     inline
        //     clearErrors={undefined}
        //     type="file"
        //     placeholder={field.placeholder as string}
        //     disabled={isFormLocked && true}
        //   />
        // );
        // ------------------------------------------------------------------------ //
        // NOTE: ✅ V2 - GREAT - TYPE IN URL, WITH LIVE PREVIEW
        return (
          <InputImageURL name={name} field={field} defaultValue={field.defaultValue} height={250} inline />
        );
      // ------------------------------------------------------------------------ //
      // TODO: ⭐✅ V3 ?? -- FileUpload.UPLOADY.tsx -- GREAT !! DnD + FileList... no preview ?? (check options)
      // return <FileUploadPreview name={name} field={field} defaultValue={field.defaultValue} height={250} />;
      // return <FileUploadPreview />;
      // ------------------------------------------------------------------------ //
      // NOTE: V4 meh 👎🏻 - FileUpload.DROP-01.tsx - BASIC, but could be STYLED to be much better
      // return <Dropzone />;
      // ------------------------------------------------------------------------ //
      // NOTE:  V5 👎🏻 ?? -- FileUpload.BYTE-03.tsx -- GOOD but COMPLEX, DnD, CROPPING, but 3rd party storage ????
      // return (
      //   <Bitescale />
      // );
      // ------------------------------------------------------------------------ //

      case 'date':
        return (
          <DatePicker
            {...register(name, { ...inputValidation })}
            defaultValue={field.value}
            onChange={setValueCustom}
            variant="inline"
          />
        );

      case 'calendar':
        return (
          <DatePicker
            {...register(name, { ...inputValidation })}
            defaultValue={field.value}
            onChange={setValueCustom}
            variant="calendar"
          />
        );

      case 'socials':
        return (
          <Socials
            {...register(name, { ...inputValidation })}
            name={field.name}
            defaultValue={field.value}
            setValue={setValue as any}
          />
        );

      case 'youtube':
        return (
          <YoutubeURL
            {...register(name, { ...inputValidation })}
            name={field.name}
            defaultValue={field.value}
            clearErrors={clearErrors}
          />
        );

      case 'FORM_COMPLETION':
        return <FormCompletion field={field} />;

      // return (
      //   <input
      //     {...register(name, { ...inputValidation })}
      //     defaultValue={field.value}
      //     // defaultValue={field.defaultValue || field.value}
      //     // defaultValue={field.defaultValue}
      //     // value={field.value || field.defaultValue}
      //     type={field.inputType}
      //     placeholder={field.placeholder}
      //     autoComplete={field.autoComplete || 'off'}
      //     readOnly={field.readOnly && true}
      //     disabled={isFormLocked && true}
      //     {...props}
      //   />
      // );

      case 'JSON_META':
        return <FormValuesJSON />;

      default:
        return <h4 style={{ color: 'red' }}>ERR: {name}</h4>;
    }
  },
  (prevProps, nextProps) => {
    return prevProps.formMethods.formState.isDirty === nextProps.formMethods.formState.isDirty;
  },
);

export const FormField = ({
  field,
  formConfig,
  //  formMethods,
  isFormLocked,
}: IFormField) => {
  const formMethods = useFormContext();

  // FIELD INPUT: CSS STYLES
  const cssClasses = new ArrayJSX();
  cssClasses.push('form-field');
  cssClasses.push(`input-type--${field.inputType}`);
  (field.inputType === INPUTS.checkbox || field.inputType === INPUTS.switch) && cssClasses.push('padding-x');
  field.readOnly && cssClasses.push('read-only');
  // field.isDisabled ? cssClasses.push('is-disabled') : cssClasses.remove('is-disabled');
  field.isDisabled || isFormLocked ? cssClasses.push('is-disabled') : cssClasses.remove('is-disabled');
  field.isHidden ? cssClasses.push('is-hidden') : cssClasses.remove('is-hidden');
  const isLabelInline =
    field?.isLabelInline === true ||
    !!(field?.inputType === INPUTS.checkbox && field?.isLabelInline !== false);

  return (
    <Col key={field.name} {...getFieldWidth(field)} css={styles}>
      <fieldset className={cssClasses.inline()}>
        {/* === LABEL ====================================================== */}

        {!field.isHidden && !isLabelInline && <FormFieldLabel field={field} />}

        <FormFieldInput field={field} formMethods={formMethods} isFormLocked={!!isFormLocked} />

        {/* === INPUT-TYPE ================================================= */}
      </fieldset>
    </Col>
  );
};

// export default FormField;
