import { css } from '@emotion/react';
import Color from 'color';
import { layout } from 'styles/layout.css';
import { colors } from 'styles/colors.css';

export const colorVariant = {
  light: Color(colors.primaryXLight).lighten(0.72).hex(),
  med: Color(colors.primary).darken(0.1).desaturate(0.3).hex(),
  dark: Color(colors.primaryDark).lighten(0.1).desaturate(0.3).hex(),
};

export const styles = css`
  border-top: ${layout.borderWidth} solid ${colors.greyXLight};
  border-top: ${layout.borderWidth} solid ${Color(colors.greyLight).lighten(0.15).hex()};

  padding-top: calc(1em * 0.33) !important;
  padding-bottom: calc(1em * 1.33);
  padding-left: 0;
  padding-right: 0;
  &:last-of-type {
    border-bottom: ${layout.borderWidth} solid ${colors.greyXLight};
    margin-bottom: 4em;
  }

  header {
    /* SECTION HEADER + CHECKBOX TOGGLES etc.. */
    margin-bottom: 1em;
    & > div > div {
      min-height: 5em !important;
      padding: 0em 0 1em;
      display: flex;
      align-items: center;
      &:first-of-type {
        max-width: 4.25em !important;
        padding-bottom: 1.2em;
      }
      label {
        font-size: 1.2em;
      }
    }

    & > .input-checkbox > .col-input {
      padding-bottom: 0;
      &:hover {
        cursor: pointer;
      }
    }
  }

  h2 {
    display: block;
    width: 100%;
    color: ${colors.greyDark};
    margin: 1em 0 0.25em 0;
    letter-spacing: -0.033em;
  }
  h3 {
    display: block;
    width: 100%;
    color: ${colors.grey};
    font-size: 1.15em;
    font-weight: 600;
    /* margin-bottom: 2em !important; */
    /* margin-bottom: 0 !important; */
  }
  h2 + h3 {
    margin-top: 0;
    /* margin-bottom: 1.25em; */
  }

  &.toggle-section h2 + h3 {
    /* margin-bottom: 1.5 !important; */
  }
`;
