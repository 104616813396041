import React, { ReactElement, useEffect, useState } from 'react';
import { Select, Tag } from 'antd';
import { styles } from './SelectMulti.css';
import type { SelectProps as AntDSelectProps } from 'antd';
import type { SelectProps } from '../Select/Select.types';
import { t } from 'i18next';

const MAX_COUNT = 10;

interface SelectMultiProps extends SelectProps {}
type TagRender = AntDSelectProps['tagRender'];

const tagRender: TagRender = (props) => {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color={value}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginInlineEnd: 4 }}
    >
      {label}
    </Tag>
  );
};

export const SelectMulti = ({
  // name,
  // field,
  options: initOptions,
  // optionsAsync,
  defaultValue,
  // initValue,
  onChange,
  ...props
}: SelectMultiProps & AntDSelectProps): ReactElement => {
  const [selectedItems, setSelectedItems] = useState<string[]>(defaultValue ?? []);

  useEffect(() => {
    onChange?.(selectedItems);
  }, [selectedItems]);

  return (
    <div css={styles}>
      <Select
        // mode="tags"
        mode="multiple"
        tagRender={tagRender}
        maxCount={MAX_COUNT}
        defaultValue={defaultValue}
        style={{ width: '100%' }}
        placeholder={t('t.forms.dropDown.placeholderr')}
        popupClassName={`input-multi-select`}
        // allowClear
        onChange={setSelectedItems}
        options={initOptions}
        // optionRender={(option) => (
        //   <Space>
        //     <span role="img" aria-label={option.data.label}>
        //       {option.data.emoji}
        //     </span>
        //     {option.data.desc}
        //   </Space>
        // )}
        {...props}
      />
    </div>
  );
};
