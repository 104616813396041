/* eslint-disable new-cap */
import { css } from '@emotion/react';
import Color from 'color';
import { colors, cssInputBox, cssInputText, layout, min, cssInputReadOnly } from 'styles';

export const colorVariant = {
  light: Color(colors.primaryXLight).lighten(0.72).hex(),
  med: Color(colors.primary).darken(0.1).desaturate(0.3).hex(),
  dark: Color(colors.primaryDark).lighten(0.1).desaturate(0.3).hex(),
};

export const styles = css`
  padding: 0;
  hr {
    opacity: 0.5;
    &.hr-buttons {
      padding-bottom: calc(${layout.padding} * 0);
      margin-bottom: calc(${layout.padding} * 4);
    }
  }

  &:not(.form-submitted) {
    input,
    textarea,
    select {
      &:focus + .error-msg {
        opacity: 0;
      }
    }
  }

  .row-buttons {
  }

  .form-status-message {
    width: 100%;
    min-height: 3em;
    padding: 0.5em 1.5em 1em 1.5em;
    display: flex;
    justify-content: flex-end;
    align-items: end;
    ${min.md} {
      justify-content: flex-end;
    }
    font-size: 1.2em;
    font-weight: 600;
    color: ${colors.greyDark};
    &.form-dirty {
      color: ${colors.warning};
    }
    &.form-valid {
      color: ${colors.greyDark};
    }
    justify-content: flex-end;
  }

  /* ************************************************************ */

  .select__menu {
    /* border: 2px solid red !important; */
  }

  /* ************************************************************ */
  /***** FORM VIEW READONLY MODE *****/

  form.mode-view {
    fieldset,
    fieldset * {
      pointer-events: none;
    }

    input,
    optgroup,
    select,
    textarea,
    input:not(input[type='button']),
    input:not(input[type='reset']),
    input:not(input[type='submit']),
    .select__control {
      ${cssInputBox}
      ${cssInputText}
      background: white!important;
      border-color: white !important;
      color: ${colorVariant.med};
      -webkit-text-fill-color: ${colorVariant.med};

      ::-webkit-input-placeholder {
        opacity: 0;
      }
      ::-moz-placeholder {
        opacity: 0;
      }
      :-ms-input-placeholder {
        opacity: 0;
      }
      ::placeholder {
        opacity: 0;
      }
      ::-webkit-datetime-edit {
        opacity: 0.4;
      }
    }

    fieldset > label {
      transform: translateY(-6px);
      color: ${Color(colors.greyLight).darken(0.15).hex()};
      &:before,
      &:after {
        display: none !important;
      }
      span {
        display: none !important;
      }
    }

    /* SPECIFIC HIDES FOR ACTION: "VIEW" ************************** */

    .fileupload-image .icon {
      /* ICON: delete */
      &:nth-of-type(2) {
        opacity: 0;
      }
    }

    .form-field.input-type--checkbox,
    .form-field.input-type--switch {
      padding: calc(${layout.padding} * 0.75) 0;
    }

    .input-type--repeater {
      .col-add button {
        opacity: 0;
      }
    }

    /* ************************************************************ */

    .select__control {
      background-color: ${colors.greyXXLight};
      padding: calc(${layout.padding} * 0.5) !important;
      .select__input,
      .select__indicators {
        display: none;
      }
    }

    .error-msg {
      /* display: none; */
    }

    .row-buttons,
    .form-dev {
      /*
      TODO: WHY WAS THIS SET ??
      display: none !important;
      */
    }
    /* ************************************************************ */
  }
`;
