import { css } from '@emotion/react';
import { layout, min, colors } from 'styles';

export const styles = css`
  margin: 0 calc(${layout.padding} * -1.25);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .grid-item-container {
    box-sizing: border-box;
    height: 0;
    position: relative;
  }

  .grid-item-gutter {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
  }

  .card {
    box-sizing: border-box;
    margin: ${layout.padding};
    width: 100%;
  }

  .grid-item-header {
    /* max-height: 2em; */
    padding-top: 2%;
  }

  .grid-item-header > div {
    /* max-height: 2em; */
    padding-top: 1%;
  }

  .header-item {
    background-color: ${colors.primaryXXLight} !important;
    /* max-height: 2em; */
    .th {
      color: white;
      font-size: 0.9em;
    }
  }
`;

export const responsiveness = {
  // [min.xs]: {
  //   flexBasis: '100%',
  //   paddingTop: '100%',
  //   fontSize: '1.5em',
  // },
  // [min.sm]: {
  //   flexBasis: '100%',
  //   paddingTop: '60%',
  //   fontSize: '1.4em',
  // },
  // [min.md]: {
  //   flexBasis: '100%',
  //   paddingTop: '50%',
  //   fontSize: '1.2em',
  // },
  // [min.lg]: {
  //   flexBasis: '100%',
  //   paddingTop: '40%',
  //   fontSize: '1.2em',
  // },
  // [min.xl]: {
  //   flexBasis: '100%',
  //   paddingTop: '33%',
  //   fontSize: '1.2em',
  // },
  // [min.xxl]: {
  //   flexBasis: '100%',
  //   paddingTop: '28%',
  //   fontSize: '1.2em',
  // },
};

export const responsiveness__ORIG = {
  [min.xs]: {
    flexBasis: '100%',
    paddingTop: '100%',
    fontSize: '1.5em',
  },
  [min.sm]: {
    flexBasis: '100%',
    paddingTop: '60%',
    fontSize: '1.4em',
  },
  [min.md]: {
    flexBasis: '100%',
    paddingTop: '50%',
    fontSize: '1.2em',
  },
  [min.lg]: {
    flexBasis: '100%',
    paddingTop: '40%',
    fontSize: '1.2em',
  },
  [min.xl]: {
    flexBasis: '50%',
    paddingTop: '33%',
    fontSize: '1.2em',
  },
  [min.xxl]: {
    flexBasis: '50%',
    paddingTop: '28%',
    fontSize: '1.2em',
  },
};

/*
export const responsiveness_V2 = {
  [min.xs]: {
    flexBasis: '100%',
    paddingTop: '100%',
    fontSize: '1.5em',
  },
  [min.sm]: {
    flexBasis: '100%',
    paddingTop: '60%',
    fontSize: '1.4em',
  },
  [min.md]: {
    flexBasis: '100%',
    paddingTop: '50%',
    fontSize: '1.2em',
  },
  [min.lg]: {
    flexBasis: '100%',
    paddingTop: '40%',
    fontSize: '1.2em',
  },
  [min.xl]: {
    flexBasis: '50%',
    paddingTop: '33%',
    fontSize: '1.2em',
  },
  [min.xxl]: {
    flexBasis: '50%',
    paddingTop: '33%',
    fontSize: '1.2em',
  },
};

export const responsiveness_V1 = {
  [min.xs]: {
    flexBasis: '100%',
    paddingTop: '100%',
    fontSize: '1.5em',
  },
  [min.sm]: {
    flexBasis: '100%',
    paddingTop: '60%',
    fontSize: '1.4em',
  },
  [min.md]: {
    flexBasis: '100%',
    paddingTop: '60%',
    fontSize: '1.2em',
  },
  [min.lg]: {
    flexBasis: '50%',
    paddingTop: '30%',
    fontSize: '1.2em',
  },
  [min.xl]: {
    flexBasis: '33%',
    paddingTop: '20%',
    fontSize: '1.2em',
  },
  [min.xxl]: {
    flexBasis: '33%',
    paddingTop: '20%',
    fontSize: '1.2em',
  },
};
*/
