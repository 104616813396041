import type { ReactElement, ReactNode } from 'react';
import { useState } from 'react';
import type { ColorName, HexColor } from 'styles';
import { styles } from './Tooltip.css';

interface ITooltip {
  content: string;
  direction: string;
  delay?: number;
  children: ReactNode;
  // color: string;
  color: ColorName | HexColor;
}

export const Tooltip = ({ content, direction, delay, children, color }: ITooltip): ReactElement => {
  const [active, setActive] = useState(false);
  let timeout: any;

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div css={styles} className="tooltip-wrapper" onMouseEnter={showTip} onMouseLeave={hideTip}>
      {children}
      {active && (
        <div
          style={{ backgroundColor: color }}
          className={`tooltip ${direction || 'top'} ${color && `tooltip-${color}`}`}
        >
          {content}
        </div>
      )}
    </div>
  );
};
