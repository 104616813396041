import type { FormConfig } from 'components/Form';
import type { FormSectionConfig } from 'components/FormSection/FormSection.types';
import type { ContextValues, ContextState } from './FormConfigContext.types';
import { CONTEXT_ACTIONS } from './FormConfigContext.types';
import type { ISchema } from 'types';

export const CONTEXT_NAME = 'FormConfig';

export const initialValues: ContextValues = {
  formConfig: {} as FormConfig,
  formSections: {} as FormSectionConfig[],
  fields: [],
  defaultValues: [],
  schema: {} as ISchema,
  isLocked: false,
  notify: null,
};

const actions = {
  [CONTEXT_ACTIONS.setContextState]: () => ({}),
  [CONTEXT_ACTIONS.setFormConfigState]: () => ({}),
};

export const initialState: ContextState = {
  ...initialValues,
  ...actions,
};
