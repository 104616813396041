import { css } from '@emotion/react';
import { colors } from 'styles/colors.css';

export const styles = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  opacity: 0.66;

  &.inline {
    position: relative;
  }

  &.page {
    z-index: 999;
    position: fixed;
    background: ${colors.white};
    opacity: 0.33;

    svg rect {
      fill: ${colors.primaryXLight}!important;
      stroke: none;
    }
  }

  /*** LOADER ELEMENTS ***/

  & svg {
    fill: ${colors.greyLight};
    max-height: 48px;
  }

  &.color-light svg rect {
    fill: white;
  }
  &.color-dark svg rect {
    fill: ${colors.greyDark};
  }

  &.color-default svg rect {
    fill: ${colors.defaultDark};
  }

  &.color-info svg rect {
    fill: ${colors.infoDark};
  }

  &.color-success svg rect {
    fill: ${colors.successDark};
  }

  &.color-warning svg rect {
    fill: ${colors.warningDark};
  }

  &.color-error svg rect,
  &.color-danger svg rect {
    fill: ${colors.dangerDark};
  }
`;
