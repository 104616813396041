/* eslint-disable @typescript-eslint/no-shadow */
import type { AuthClient } from '../types/auth.types';

// NOTE: DEV ONLY..
const testService = async ({ client }: { client: AuthClient }) => {
  const SERVICE_NAME = 'posts'; // TODO: test if other services work correctly
  // const SERVICE_NAME = 'users';
  try {
    const SERVICE = client.service(SERVICE_NAME);
    const RESPONSE = await SERVICE.find({ query: { id: 1 } });
    log(`IO_CLIENT: SERVICE ======>> ${SERVICE_NAME}`, 'lime', ...RESPONSE.data);
    const { user } = await client.get('authentication');
    const { accessToken } = await client.get('authentication');
    log('IO_CLIENT: USER+TOKEN AUTH ======>> ', 'yellow', { user, accessToken });
  } catch (error: unknown) {
    error instanceof Error ? console.error(error.message) : console.error(String(error));
  }
};

// ======================================================================== //

export const parseResponseError = (error: unknown) => {
  if (error instanceof Error) {
    const { className, code, message, name } = JSON.parse(JSON.stringify(error));
    log('LOGIN_ERROR (Error) >>', 'red', { className, code, message, name });

    return { className, code, message, name };
  } else {
    const message = String(error);
    log('LOGIN_ERROR (STRING) >>', 'red', message);

    return { className: 'error-generic', code: '4xx', message, name: 'ERROR_GENERIC' };
  }
};
