import { Col, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react';
import { TbFileSearch as IconFicha } from 'react-icons/tb';
import type { CatMeta } from 'types';
import { ACTIONS } from 'types';
import { useGlobal } from 'store/useGlobalContext';
import { Socials } from 'components/Socials';

export const CardFooter = ({ data, config, handleClickCard, navigateInternal, index }) => {
  const { t } = useTranslation();
  const { metaCats } = useGlobal('metaCats');

  const category = metaCats.find((cat: CatMeta) => cat.uuid === data?.cat_id) as CatMeta;
  const hasCategoryLabel = !!category?.parent;
  const listItemConfig = config.list?.listItem;
  const isCallout = listItemConfig?.isCallout;

  const dataSocials = Array.isArray(data?.url_multi)
    ? data.url_multi
    : Array.isArray(data?.socials)
      ? data.socials
      : [];

  const handleClickTitle = (event?: any) => {
    log('CLICK:', 'grey', event.target.id);
    event.preventDefault();
    if (event.target.id === 'list-card-title') {
      return navigateInternal({ action: ACTIONS.VIEW });
    }
    return;
  };

  return isCallout ? (
    <footer className="card-footer">
      <Row className="row">
        <Col xs={12} className="col" style={{ pointerEvents: 'visible' }}>
          <h2 id="list-card-title" className="callout-title">
            {data.name}
          </h2>
        </Col>
      </Row>
    </footer>
  ) : (
    <footer className="card-footer" onClick={handleClickCard}>
      <Row className="row">
        <Col xs={12} className="col col-top col-left">
          <h2>{data.name}</h2>
        </Col>
        <Col xs={12} md={7} className="col col-top col-right">
          {/* {hasSocialsValue && t('t.web-list.list-item.socials')} */}
          {hasCategoryLabel ? (
            <span style={{ fontSize: '1em', fontWeight: 600, opacity: 0.66, whiteSpace: 'nowrap' }}>
              {category.title}
            </span>
          ) : (
            <Fragment />
          )}
        </Col>
        <Col xs={12} md={7} className="col col-bottom col-left">
          <div id="list-card-title" className="list-card-title" onClick={handleClickTitle}>
            <span id="list-card-title" style={{ fontSize: '1em', fontWeight: 600, opacity: 0.66 }}>
              <IconFicha id="list-card-title" className="icon-ficha" scale={0.5} />
              {t('t.web-list.list-item.view-detail')}
            </span>
          </div>
        </Col>
        <Col xs={12} md={5} className="col col-bottom col-right">
          {dataSocials?.length > 0 ? <Socials links={dataSocials} color={'white'} mono /> : <Fragment />}
        </Col>
      </Row>
    </footer>
  );
};
