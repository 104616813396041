import socketio from '@feathersjs/socketio-client';
import io from 'socket.io-client';
import { createClient } from 'auth/client/createClient';
import config from 'config';
import { DEFAULT_AUTH_OPTIONS_CORE } from './client.config';
import type { AuthClient } from '../types/auth.types';

const { API_BASE_URL, NODE_ENV } = config;

log(`[API ${String(NODE_ENV)}]`, 'grey', API_BASE_URL);

// NOTE: socket.io - How to Use with React..
// https://socket.io/how-to/use-with-react

// "undefined" means the URL will be computed from the `window.location` object
// const URL = process.env.NODE_ENV === 'production' ? undefined : 'http://localhost:4000';

const DEFAULT_AUTH_OPTIONS_SOCKETIO = {
  ...DEFAULT_AUTH_OPTIONS_CORE,
  storage: window.localStorage, // TODO: ** SET 'storage' OPTION TO ENABLE 'localStorage' ???
};

// 💡 By default, the Socket.IO client opens a connection to the server right away. You can prevent this behavior with the autoConnect option:

const socket = io(API_BASE_URL, {
  transports: ['websocket'],
  forceNew: false,
  autoConnect: true, // default
});

export const client: AuthClient = createClient({
  connection: socketio(socket),
  authOptions: { ...DEFAULT_AUTH_OPTIONS_SOCKETIO },
});
