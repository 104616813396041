import { Row, Col } from 'react-grid-system';
import { useEffect, useState } from 'react';
import { MdAddCircleOutline as IconNew } from 'react-icons/md';
import { ISchema, DataEntry } from 'types';
import { useRouter } from 'routes/hooks';
import { useQueryGet } from 'api';
import { useAuth } from 'auth/AuthContext';
import { useGlobal } from 'store/useGlobalContext';
import { ButtonLink } from 'components/Button';
import { Form } from 'components/Form';
import type { FormConfig } from 'types/form.types';
import { usePageHeader } from 'hooks/usePageHeader';
import { PageHero } from 'components/PageHero';
import { RegisterToolbar } from 'components/RegisterToolbar';
import { Spinner } from 'components/Spinner';
import { initDetailData } from 'config/data.utils/initDetailData';
import { formConfig as defailFormConfig } from 'config/datatype/entidades/entidades.form';
import { type Schema } from 'type-fest';
import { styles } from './Dashboard.css';

const Dashboard = () => {
  const { user } = useAuth();
  const { params, route, fromLocation, slug, basePath, PATH_BASE } = useRouter();
  const { pageHero, setPageHero } = useGlobal('pageHero');
  const [data, setData] = useState(null);
  const { setPageHeaderTitles } = usePageHeader();

  const {
    data: response,
    isFetching,
    isSuccess,
  } = useQueryGet({
    endpoint: 'registration',
    query: { json_meta: { $like: `%${user?.uuid}%` } },
    filter: { $limit: 1 },
  });

  log('__DASH:', 'orange', { route, fromLocation });

  useEffect(() => {
    if (response && isSuccess) {
      log('PAGE_ROUTE', 'red', response);
      try {
        const dataParsed = initDetailData({ data: response });
        setData(dataParsed as any);
        setPageHero({ ...pageHero, title: false, values: dataParsed });
      } catch (err) {
        console.error(err);
      } finally {
        setPageHeaderTitles();
      }
    }
  }, [response]);

  if (isFetching) return <Spinner page />;

  const isReady = !isFetching && response;

  //   Registre su medio o entidad principal:
  // - Registrar: Medio o canal (F1)
  // - Periódico digital (F1)
  // - Entidad o asociación. (F2)

  return (
    <section css={styles}>
      <PageHero />
      <Row>
        <Col xs={12} md={8} className="col col-buttons-create">
          <RegisterToolbar />
        </Col>
        <Col xs={12} md={4}>
          {isReady && <pre style={{ overflowX: 'hidden' }}>{JSON.stringify(data, null, 2)}</pre>}
        </Col>
      </Row>
    </section>
  );
};

export default Dashboard;
