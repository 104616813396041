/* eslint-disable new-cap */
import { css } from '@emotion/react';
import Color from 'color';
import { colors, forms, layout, cssInputReadOnly } from 'styles';

export const colorAccents = {
  light: Color(colors.primaryXLight).lighten(0.6).hex(),
  med: Color(colors.primaryXLight).lighten(0.4).hex(),
  dark: Color(colors.primaryXLight).darken(0.2).hex(),
};

export const styles = css`
  z-index: 10 !important;

  .input-select {
    width: 100%;
    .select__control {
      font-weight: 600;
      padding: calc(${layout.padding} * 0.5) !important;
    }
    .select__value-container,
    .select__single-value,
    .select__input-container {
      /* max-height: calc(${forms.inputs.height} - 4px); */
      /* line-height: calc(${forms.inputs.height} - 4px); */
      /* transform: translateY(-1px); */
    }

    .select__control--is-focused,
    .select__control--menu-is-open {
      border-color: ${colors.primaryLight};
    }

    .select__clear-indicator {
      transform: translate(calc(-${layout.padding} * 0.5), 0);
      &:hover {
        color: ${colors.dangerDark};
        cursor: pointer;
      }
    }

    .select__indicators {
    }

    .select__menu-list > div {
      padding: 0.33em 0.66em;

      &:hover {
        background: ${colorAccents.light};
      }
      .select__option:hover {
        background: ${colors.successLight};
      }
    }

    &.is-readonly {
      ${cssInputReadOnly};
      .select__control {
        background-color: ${colors.greyXXLight};
        padding: calc(${layout.padding} * 0.5) !important;
        .select__input,
        .select__indicators {
          display: none;
        }
      }
    }
  }
`;
