import { add, format } from 'date-fns';
import type { DataEntry, DateRange } from 'types';
import { DATETIME_FORMAT } from 'i18n/datetime';
import type { SelectOption } from 'components/FormUI/Select';
import { getMonthEnd, getMonthStart } from 'utils';
import { SPAIN_PROVINCES } from 'constants/spain';
import type { FilterDateSelected, FilterLocation } from '../EventFiltersContext';

// -------------------------------------------------------------------------- //
// NEW: EVENT DATES + CALENDAR ============================================== //
// -------------------------------------------------------------------------- //

export const getCurrentCalendarRange = (date: Date | string, numMonthsSpan = 2): DateRange<string> => {
  date = new Date(date);
  const startDate = getMonthStart(date);
  const endDate = getMonthEnd(add(date, { months: numMonthsSpan - 1 }));

  return { startDate, endDate };
};

// -------------------------------------------------------------------------- //
/* eslint-disable prettier/prettier */

export const getDataByDateRange = ({
  dateRange,
  data,
  includePastEvents = false,
}: {
  dateRange: DateRange<string>;
  data: DataEntry[];
  includePastEvents?: boolean;
}) => {
  if (!dateRange || !dateRange.startDate || !dateRange.endDate) return data;
  const { startDate, endDate } = dateRange;
  const eventsMatched = data.filter(({ date }: DataEntry & { date: string }) => {
    const eventDate = format(new Date(date), DATETIME_FORMAT.DEFAULT);
    const isEventDateMatched = includePastEvents ? eventDate < endDate : eventDate > startDate && eventDate < endDate;

    return isEventDateMatched;
  });

  return eventsMatched;
};

/* eslint-enable prettier/prettier */
// -------------------------------------------------------------------------- //
/* eslint-disable prettier/prettier */

export const getSelectLocationPlaceholder = ({ results }: { results: DataEntry[] }) => {
  const subject = results.length === 1 ? 'provincia' : 'provincias';

  return `Seleccionar... ${results.length} ${subject}`;
};

export const getLocationsOptions = ({ results, dataFiltered }: any): SelectOption[] => {
  const provincesInResults =
    results.map(({ province }: DataEntry) =>
      SPAIN_PROVINCES.find((provinceOption: SelectOption) => provinceOption.value === province),
    ) || SPAIN_PROVINCES; // TODO: OR DEFAULT TO dataFiltered ???

  return provincesInResults;
};

// eslint-disable-next-line @typescript-eslint/no-shadow
export const getLocationsHighlighted = ({ data }: { data: DataEntry[] }) =>
  data.map(
    (item: DataEntry) =>
      ({
        value: item.province,
        label: item.province,
      }) as SelectOption,
  );

/* eslint-enable prettier/prettier */
// NEW: SET ALL FILTERS ===================================================== //
/* eslint-disable prettier/prettier */

export const getEventsByDay = ({
  dateSelected,
  dateRange,
  data,
}: {
  dateSelected: FilterDateSelected;
  dateRange: DateRange<string>;
  data: DataEntry[];
}) => {
  const dataFilteredByDay =
    dateSelected.isActive && data.length > 1
      ? getDataByDateRange({
          dateRange: {
            startDate: `${dateSelected.value} 00:00:00`,
            endDate: `${dateSelected.value} 23:59:59`,
          },
          data,
        })
      : getDataByDateRange({ dateRange, data });

  return dataFilteredByDay;
};

/* eslint-enable prettier/prettier */
// -------------------------------------------------------------------------- //
// NEW: FILTER SETTERS ====================================================== //
// -------------------------------------------------------------------------- //

export const getEventsByProvince = ({ province, data }: { province?: FilterLocation; data: DataEntry[] }) => {
  if (!province || !province.value) return data;

  const eventsMatched = data.filter((event: DataEntry) => event.province === province.value);
  log('DATA_FILTERED_BY_PROVINCE:', 'orange', province.value, eventsMatched);

  return eventsMatched;
};

// -------------------------------------------------------------------------- //
