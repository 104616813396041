import {
  FaFacebook as IconFacebook,
  FaInstagramSquare as IconInstagram,
  FaTwitter as IconTwitter,
  FaYoutube as IconYoutube,
  FaSkype as IconSkype,
  FaTelegramPlane as IconTelegram,
} from 'react-icons/fa';
import { SiSignal as IconSignal } from 'react-icons/si';
import type { SocialConfig } from 'components/Socials';

// NOTE: THIS ONE USED FOR LATEST MULTI-URL REPEATER
// TODO: REMOVE OTHERS THAT `Socials` DOES NNOT REQUIRE !!

export const configSocials: SocialConfig[] = [
  {
    name: 'facebook',
    label: 'Facebook',
    icon: IconFacebook,
    color: '#0C86EF',
    baseUrl: 'https://facebook.com',
    validation: '((http(s)?)://)?(www.)?facebook.com/[a-zA-Z0-9]{2,}',
  },
  {
    name: 'instagram',
    label: 'Instagram',
    icon: IconInstagram,
    color: '#DA2E79',
    baseUrl: 'https://instagram.com',
    validation: '((http(s)?)://)?(www.)?instagram.com/[a-zA-Z0-9]{2,}',
  },
  {
    name: 'twitter',
    label: 'Twitter',
    icon: IconTwitter,
    color: '#1CA0F1',
    baseUrl: 'https://twitter.com',
    validation: '((http(s)?)://)?(www.)?twitter.com/[a-zA-Z0-9]{2,}',
  },
  {
    name: 'youtube',
    label: 'Youtube',
    icon: IconYoutube,
    color: '#FE0000',
    baseUrl: 'https://youtube.com',
    validation: '((http(s)?)://)?(www.)?youtube.com/[a-zA-Z0-9]{2,}',
  },
  {
    name: 'telegram',
    label: 'Telegram',
    icon: IconTelegram,
    color: '#34ACE0',
    baseUrl: 'https://telegram.org',
    validation: '((http(s)?)://)?(www.)?telegram.org/[a-zA-Z0-9]{2,}',
  },
  {
    name: 'signal',
    label: 'Signal',
    icon: IconSignal,
    color: '#3872E8',
    baseUrl: 'https://signal.org',
    validation: '((http(s)?)://)?(www.)?signal.org/[a-zA-Z0-9]{2,}',
  },
  {
    name: 'skype',
    label: 'Skype',
    icon: IconSkype,
    color: '#00A5E8',
    baseUrl: 'https://skype.com',
    validation: '((http(s)?)://)?(www.)?skype.com/[a-zA-Z0-9]{2,}',
  },
];
