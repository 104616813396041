import { Row, Col } from 'react-grid-system';
import { MdAddCircleOutline as IconNew } from 'react-icons/md';
import { ISchema, DataEntry } from 'types';
import { useRouter } from 'routes/hooks';
import { ButtonLink } from 'components/Button';
import { Form } from 'components/Form';
import type { FormConfig } from 'types/form.types';
import { ArrayJSX } from 'utils/ArrayJSX';
import { styles } from './RegisterToolbar.css';

export type AdminUserAccountPageProps = {
  endpoint?: string;
  formConfig?: FormConfig;
  data?: DataEntry;
  schema?: ISchema;
  isLocked?: boolean;
  isNavPromptActive?: boolean;
  heroTitleKey?: string | string[];
};

export const RegisterToolbar = ({ horizontal: isHorizontal = false }: { horizontal?: boolean }) => {
  const { params, route, slug, basePath, PATH_BASE } = useRouter();

  const cssClasses = new ArrayJSX();
  isHorizontal && cssClasses.push('horizontal');

  // log('=== ROUTE ===', 'red', { location });
  // log('=== ROUTE ===', 'magenta', { params, slug, basePath });
  // log('=== ROUTE ===', 'grey', route);

  //   Registre su medio o entidad principal:
  // - Registrar: Medio o canal (F1)
  // - Periódico digital (F1)
  // - Entidad o asociación. (F2)

  /*
  if (slug === 'registros') {
    return (
      <div css={styles} className={`${cssClasses.inline()}`}>
        <Row>
          <Col xs={12} className="col">
            <ButtonLink
              to={`${PATH_BASE}/accyes, yyesyeyeount`}
              state={{ from: basePath }}
              size={'xs'}
              // icon={<IconNew />}
              // iconPos="right"
              label="Nuevo"
              color="successXDark"
            />
          </Col>
        </Row>
      </div>
    );
  }
  */

  return (
    <div css={styles} className={`${cssClasses.inline()}`}>
      <Row>
        <Col xs={12} className="col">
          <h2>Registre su medio o canal principal, periódico digital o entidad:</h2>
        </Col>
        <Col xs={12} className="col">
          <ButtonLink
            to={`${PATH_BASE}/registros/cat/canales/new`}
            state={{ from: basePath, action: 'NEW' }}
            // state={{ from: basePath, action: 'NEW' }}
            // icon={<IconNew />}
            iconPos="right"
            label="Medio o canal principal"
            color="successXDark"
          />
          <ButtonLink
            to={`${PATH_BASE}/registros/cat/periodicos/new`}
            state={{ from: basePath, action: 'NEW' }}
            // state={{ from: basePath, action: 'NEW' }}
            // icon={<IconNew />}
            iconPos="right"
            label="Periódico digital"
            color="successXDark"
          />
          <ButtonLink
            to={`${PATH_BASE}/registros/cat/entidades/new`}
            state={{ from: basePath, action: 'NEW' }}
            // state={{ from: basePath, action: 'NEW' }}
            // icon={<IconNew />}
            iconPos="right"
            label="Entidad o asociación"
            color="successXDark"
          />
        </Col>
      </Row>
    </div>
  );
};
