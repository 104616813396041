/* eslint-disable max-len */
import { css, keyframes } from '@emotion/react';
import Color from 'color';

import { BREAKPOINTS, colors, layout, max, min } from 'styles';
import { cssGlassFrostedOpaque, cssGlassFrostedV2, cssGlassFrostedV3, cssGlassXFrosted } from 'styles/special.css';

const colorInfoBG = Color(colors.white).desaturate(0.4).alpha(0.6).rgb().string();
const colorInfoBGHover = Color(colors.primaryDark).desaturate(0.4).alpha(0.6).rgb().string();
const colorFooter = Color(colors.primary).desaturate(0.4).alpha(0.6).rgb().string();
const colorFooterHover = Color(colors.primaryDark).desaturate(0.4).alpha(0.6).rgb().string();
const colorLink = Color(colors.primaryLight).lighten(0.3).desaturate(0.3).rgb().string();
const colorLinkHover = Color(colors.primaryXXDark).lighten(0.3).desaturate(0.3).rgb().string();
const colorHover = Color(colors.primaryXLight).lighten(0.2).desaturate(0.5).hex();

export const styles = css`
  margin: auto;
  padding: 0;
  overflow: hidden;
  border-radius: calc(${layout.borderRadius} * 2);
  transition:
    box-shadow 300ms ease,
    border 200ms ease !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  filter: saturate(1.15) contrast(1.12);
  border: calc(${layout.borderWidth} * 2) solid ${colors.white};
  box-shadow: inset 0 0 0px 1px #ffffff;
  /*box8-shadow: inset 0 0 0px 1px ${colors.primaryXXLight};*/
  font-size: 1em;

  /* min-height: 200px; */
  /* height: 200px; */

  &:not(.disabled):hover {
    cursor: pointer;
    /* TODO: KEEP - MAYBE LATER..
    border: calc(${layout.borderWidth} * 2) solid ${colorHover};
    box-shadow: inset 0 0 0px 1px ${colorHover};
    */
    footer {
      background-color: ${colorFooterHover};
    }
  }

  ${min.xs} {
    font-size: 1em;
  }
  ${min.sm} {
    font-size: 1.2em;
  }
  ${min.md} {
    font-size: 1.1em;
  }
  ${min.lg} {
    font-size: 1em;
  }
  ${min.xl} {
    font-size: 1.1em;
  }
  ${min.xxl} {
    font-size: 1.2em;
  }

  .row {
    /* margin: 0 !important; */
    height: 100%;
    align-items: stretch;
  }

  aside.card-aside {
    width: 100%;
    height: 100%;

    /* CONTENTS WRAPPER (RIGHT) */
    .col-image {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 100%; /* RIGHT */

      order: 2;

      height: 50%;
      padding-top: calc(${layout.padding} * 0.33);

      ${min.sm} {
        height: 70%;
        padding-top: calc(${layout.padding} * 0.33);
      }
      ${min.md} {
        height: 60%;
        padding-top: calc(${layout.padding} * 0.33);
      }
      ${min.lg} {
        height: 50%;
        padding-top: calc(${layout.padding} * 0.33);
      }
      ${min.xl} {
        height: 100%;
        padding-top: 0;
      }

      .bg-image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        opacity: 1;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 100%;
      }
    }

    /* CONTENTS WRAPPER (LEFT) */
    .row-test {
      align-items: center !important;
      padding: 5px;
    }

    /* CONTENTS WRAPPER (LEFT) */
    .col-test {
      background: white;
      font-size: 1em;
      order: 3;
      height: 50%;
      padding-top: calc(${layout.padding} * 0.33);

      ${min.sm} {
        height: 30%;
      }
      ${min.md} {
        height: 40%;
      }
      ${min.lg} {
        height: 50%;
      }
      ${min.xl} {
        height: 100%;
        padding-top: 0;
        order: 1;
      }

      /* CONTENTS WRAPPER (RIGHT) */
      .col-title {
        display: flex;
        align-items: center;
        background: white;
        padding-left: 0 !important;

        ${min.xs} {
          height: 70px;
          font-size: 1.1em;
        }
        ${min.sm} {
          height: 90px;
          padding-left: calc(${layout.padding} * 1) !important;
        }
        ${min.md} {
          height: 150px;
          padding-left: calc(${layout.padding} * 0.5) !important;
        }
        ${min.lg} {
        }
        ${min.xl} {
        }
        ${min.xxl} {
        }
      }

      /* C

      /* CONTENTS WRAPPER (RIGHT) */
      .col-title,
      .col-info {
        h2 {
          ${min.xs} {
            font-size: 1.1em;
          }
          ${min.sm} {
            font-size: 1.2em;
          }
          ${min.md} {
            font-size: 1.2em;
          }
          ${min.lg} {
            font-size: 1.1em;
          }
          ${min.xl} {
          }
          ${min.xxl} {
          }
        }
      }

      .col-date {
        height: 100%;
        & > div {
        }
        ${min.xs} {
        }
        ${min.sm} {
          padding-right: 0 !important;
        }
        ${min.md} {
          font-size: 1.1em;
        }
        ${min.lg} {
          padding-right: 0 !important;
        }
        ${min.xl} {
          padding-right: 0 !important;
        }
        ${min.xxl} {
        }

        .cal-top {
          ${min.xs} {
            font-size: 1em;
          }
          ${min.sm} {
            font-size: 1em;
          }
          ${min.md} {
            font-size: 1.1em;
          }
          ${min.lg} {
            font-size: 1.2em;
          }
          ${min.xl} {
            font-size: 1.5em;
          }
          ${min.xxl} {
            font-size: 1.4em;
          }
        }
        .cal-bottom {
          ${min.xs} {
            /* background: white; */
            font-size: 2em;
          }
          ${min.sm} {
            /* background: silver; */
            font-size: 1.8em;
          }
          ${min.md} {
            /* background: pink; */
            font-size: 2.8em;
          }
          ${min.lg} {
            /* background: yellow; */
            font-size: 2.5em;
          }
          ${min.xl} {
            /* background: silver; */
            font-size: 3em;
          }
          ${min.xxl} {
            /* background: white; */
            font-size: 3.2em;
          }
        }
      }

      .col-info {
        ${min.lg} {
          height: 90%;
          padding-right: 0 !important;
        }
        section.info {
          display: contents;
        }
        .contact-row {
          width: 100%;
          color: ${colors.white};
          font-size: 1em;
          font-weight: 700;
        }

        .info-row {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: row;
          font-weight: 500;
          padding: 0 0;

          width: 100%;
          color: ${colors.text};
          font-size: 0.85em;
          font-weight: 400;
          padding: 0.1em 0;

          label {
            color: ${colors.primaryXXLight};
            min-width: 160px;
            margin: 0;
          }

          ${min.xs} {
            font-size: 1em;
          }
          ${min.sm} {
            font-size: 1.2em;
          }
          ${min.md} {
            font-size: 0.9em;
          }
          ${min.lg} {
            font-size: 0.9em;
          }
          ${min.xl} {
            font-size: 0.9em;
          }
          ${min.xxl} {
            font-size: 0.9em;
          }
        }
      }

      .contact-row,
      .info-row {
        a {
          padding-bottom: 0.15em;
          display: inline-block;
          color: ${colorLink};
          &:hover {
            color: ${colorLinkHover};
          }
        }
      }

      h2 {
        font-size: 1em;
        line-height: 1.33;
        color: ${colors.primary};
        margin: 0;
        margin-bottom: 0.5em !important;
        /* white-space: nowrap; */
      }

      hr {
        margin: 0.66em 0;
        opacity: 0.33;
      }
    }
  }

  &.item-even {
    background: ${colors.white};
  }

  &.item-odd {
    background: ${colors.white};
  }

  &.single {
    height: 50vw;
    max-height: 66%;
    ${min.xs} {
      height: 50vw;
    }
    ${min.sm} {
      height: 50vw;
    }
    ${min.md} {
      height: 40vw;
    }
    ${min.lg} {
      height: 25vw;
    }
    ${min.xl} {
      height: 20vw;
      max-height: 300px;
    }
    ${min.xxl} {
      height: 20vw;
      max-height: 300px;
    }
  }
`;
