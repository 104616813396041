import { config } from 'config';
import { cleanRoutePath } from './utils/routes.utils';
export type { RouteConfig, ParentRoutesByGroup, RouteGroup } from './routes.types';
export { useRouter } from './hooks/useRouter/useRouter';
export { useGetRouteConfiguration } from './hooks/useGetRouteConfiguration';
export { AccessLayer } from './AccessLayer';

export const isAdminPath = () => !!location.pathname.startsWith('/admin');
// export const isUserPath = () => ['/account', '/registros'].includes(location.pathname);
export const isUserPath = () => !!location.pathname.startsWith('/account');
export const isLoggedOutPath = () =>
  ['/logout', '/logged-out', '/login', '/register-user-new'].includes(location.pathname);

export const getPathBase = () => {
  const PATH_BASE = cleanRoutePath(
    isUserPath()
      ? config.PATH_BASE_USER
      : isAdminPath()
        ? config.PATH_BASE_ADMIN
        : config.PATH_BASE_WEB || '',
  );

  return { PATH_BASE };
};
