import { Row, Col } from 'react-grid-system';
import { useEffect, useState, type ReactElement } from 'react';
import { DataEntry } from 'types';
import type { TableConfig } from 'types';
import { CatMeta } from 'types/metadata.types';
import { useRouter } from 'routes/hooks';
import { useRoutesMeta } from 'routes/hooks/useRoutesMeta/useRoutesMeta';
import { useQueryGet } from 'api';
import { useGlobal } from 'store/useGlobalContext';
import { DataGrid } from 'components/DataGrid';
import { ListItemCard as ListItemCardDefault } from 'components/ListItemCard';
import { NoEntries } from 'components/NoEntries';
import { PageToolbar } from 'components/PageToolbar';
import { Pagination, PaginationProvider } from 'components/Pagination';
import { Spinner } from 'components/Spinner';
import { config as appConfig } from 'config';
import { initDetailData } from 'config/data.utils/initDetailData';
import { styles } from './ListPage.css';
import { useQueryBuilder } from 'hooks/useQueryBuilder';
import { usePageHeader } from 'hooks/usePageHeader';
import { tableConfig } from 'config/datatype/invite/eventos.table';
import { DataConfiguration } from 'routes/utils/getDynamicRoutes.types';

const ListPage = ({ dataConfig }: DataConfiguration): ReactElement => {
  const tableConfig: TableConfig = dataConfig.tableConfig;
  const { params, route, location, category, basePath, slug, PATH_BASE } = useRouter();
  const { setPageHeaderTitles } = usePageHeader({ config: tableConfig });
  const { metaCats } = useGlobal('metaCats');
  const { getDataWithsPathsApplied } = useRoutesMeta();
  // const { endpoint, dataConfiguration } = useDataConiguration<TableConfig>({ defaultConfig, role: 'table' });

  // const { sortParams } = useGlobal('sortParams');
  const [filters, setFilters] = useState([]);
  const [results, setResults] = useState<DataEntry[] | null>();
  const [data, setData] = useState<DataEntry[]>();

  // ======================================================================== //
  // NOTE: {isPending, isError } (not isFetching) RECOMMENDED BY react-query MAINTAINERS + DOCS..
  // ref: https://github.com/TanStack/query/discussions/6297

  const { query, endpoint } = useQueryBuilder({ config: tableConfig as TableConfig });

  const { data: response, isPending, isFetching, isSuccess } = useQueryGet({ endpoint, query });

  useEffect(() => {
    setData([]);
    setResults(null);
    setPageHeaderTitles(route);
  }, [location.pathname]);

  useEffect(() => {
    if (isSuccess) {
      const dataParsed = response.map((data: DataEntry) => initDetailData({ data, isExpandedSchema: true }));
      // NOTE: filter dataset using various checks (this meant for 'develpment' only, but may be useful in staging/production..)
      const dataValid = dataParsed.filter(
        (data: DataEntry) =>
          data?.cat_id && !!metaCats.find((cat: CatMeta) => cat.uuid === data?.cat_id)?.parent,
      );
      setData(dataValid || []);
      setResults(dataValid || []);
      setPageHeaderTitles(route);
    }
    // NOTE: for initial `refetch()` + development saves, EMPTY deps `[]` works best..
    // NOT SURE YET.. TanStack recommends isPending.. (TBC)
  }, [isSuccess, response]);

  if (!tableConfig || isPending || isFetching || !data || results === null) {
    return <Spinner dark />;
  }

  const listItemConfig = tableConfig.list?.listItem;
  const CARD_SHAPE_FACTOR = listItemConfig?.shapeRatio ?? 2;
  const ListItemCard = listItemConfig?.component ?? ListItemCardDefault;

  return (
    <section css={styles}>
      <Row className="row-list">
        <Col className="col col-list">
          {/* <pre>{JSON.stringify({ params, route, category, slug, basePath, PATH_BASE }, null, 2)}</pre> */}
          <PaginationProvider numItems={data.length}>
            <PageToolbar
              dataConfiguration={tableConfig}
              {...{ data, results, setResults, filters, setFilters }}
            />
            <Pagination numResults={data.length} />
            {results.length > 0 ? (
              <DataGrid
                data={results.length > 0 ? results : data}
                tableConfig={tableConfig}
                itemComponent={ListItemCard}
                shapeRatio={CARD_SHAPE_FACTOR}
                basePath={route?.path}
              />
            ) : (
              <NoEntries />
            )}
            <Pagination />
          </PaginationProvider>
        </Col>
      </Row>
    </section>
  );
};

export default ListPage;
