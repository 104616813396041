import { CatMeta } from 'types/metadata.types';
import { SelectOption } from 'components/FormUI/Select';

export const getOptionsCategories = (cats: CatMeta[]): SelectOption[] => {
  const options: SelectOption[] = [];

  for (const cat of cats) {
    const isDuplicate = !!options.find((option) => option.value === cat.uuid);

    if (!isDuplicate) {
      options.push({
        value: cat.uuid || '',
        label: cat.title || '',
      } as SelectOption);
    }
  }

  return options;
};
