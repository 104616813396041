import { Col, Row } from 'react-grid-system';
import { INPUTS, FORMAT } from 'types/form.types';
import { isArray } from 'utils';
import { ArrayJSX } from 'utils/ArrayJSX';
import type { FieldConfig } from './DetailSummary.types';
import { DetailValue } from './DetailValue';
import { formatDetailLabel } from './utils/detail.utils.labels';
import { styles } from './DetailRow.css';

type DetailRowProps = { field: FieldConfig };

export const DetailRow = ({ field }: DetailRowProps) => {
  const isRepeater = !!isArray(field?.value);
  const isTextArea = !!(field?.inputType === INPUTS.textarea);
  const isTagsField = !!(field?.valueFormat?.type === FORMAT.TAGS);
  const isListByID = !!(field?.valueFormat?.type === FORMAT.LIST_BY_ID);

  const colValueCSS = new ArrayJSX('col');
  colValueCSS.push(`value-${field.name}`);
  isRepeater ? colValueCSS.push('col-repeater') : colValueCSS.push('col-value');
  isTextArea && colValueCSS.push('col-paragraphs');
  isTagsField && colValueCSS.push('col-tags');
  isListByID && colValueCSS.push('col-list-by-id');

  const hasLabel = !!(field.label && (field?.inputType !== INPUTS.textarea || field?.name !== 'description'));
  const label = field.label ? formatDetailLabel(field.label as string) : false;

  return (
    <Row className={`row row-detail row-${field.name}`} css={styles}>
      {hasLabel && (
        <Col xs={4} className={`col col-label col-${field.name}`}>
          <label>{label}</label>
        </Col>
      )}
      <Col xs={hasLabel ? 8 : 12} className={colValueCSS.inline()}>
        <DetailValue field={field} options={{ isRepeater, isTextArea, isTagsField, isListByID }} />
      </Col>
    </Row>
  );
};
