import type { FormConfig, FieldConfig } from 'components/Form';
import type { FormSectionConfig } from 'components/FormSection/FormSection.types';
import type { FieldValues } from 'react-hook-form';
import type { ISchema, MapIndex } from 'types';

export type ValueKeys = 'formConfig' | 'formSections' | 'fields' | 'defaultValues' | 'schema' | 'isLocked' | 'notify';

export enum CONTEXT_ACTIONS {
  setContextState = 'setContextState', // TODO: FOR USE IN OTHER CONTEXTS
  setFormConfigState = 'setFormConfigState',
}

export interface ContextValues extends MapIndex<ValueKeys> {
  formConfig: FormConfig;
  formSections: FormSectionConfig[];
  fields: FieldConfig[];
  defaultValues: FieldValues;
  schema: ISchema;
  isLocked: boolean;
}

export interface ContextActions extends MapIndex<CONTEXT_ACTIONS> {
  setContextState: (value: unknown) => void;
  setFormConfigState: (value: unknown) => void; // TODO: FIND WAY TO USE STRING TEMPATE AS `key` !!!!
}

export interface ContextState extends ContextValues, ContextActions {}

// ======================================================================== //

// REDUCER TYPES..
export type ActionPayload = { value: { [key: string]: unknown } };
export type ActionType = { type: string; payload: ActionPayload };
