import cloneDeep from 'lodash/cloneDeep';
import { RouteAccess, RouteGroup } from 'routes/routes.types';
import type { CatMeta } from 'types/metadata.types';
import { flatttenChildren } from 'routes/utils/routes.utils.flatten';

const getFilteredUniqueCategories = ({ categories }: { categories: CatMeta[] }) => {
  return categories.reduce((acc, current) => {
    if (!acc.find((cat: CatMeta) => cat.path === current.path)) {
      acc.push(current);
    }
    return acc;
  }, [] as CatMeta[]);
};

export const getCategoriesFlat = ({ categories }: { categories: CatMeta[] }) => {
  const catsFlat: CatMeta[] = categories ? flatttenChildren<CatMeta>(categories) : categories;
  const uniqueCatsMeta = getFilteredUniqueCategories({ categories: catsFlat });

  return uniqueCatsMeta;
};

const appendRouteMetaProps = (
  category: CatMeta,
  routeGroup: RouteGroup,
  access: RouteAccess,
  parent?: CatMeta,
): CatMeta => {
  const { children, ...rest } = category;

  const parentMeta = parent
    ? {
        parent: {
          uuid: parent.uuid,
          path: parent.path,
          pathname: parent.pathname,
          title: parent.title,
          slug: parent.slug,
        },
      }
    : {};

  const childrenMeta = children
    ? {
        children: children.map((child) => appendRouteMetaProps(child, routeGroup, access, category)),
      }
    : {};

  const updatedCategory: CatMeta = {
    ...rest,
    routeGroup,
    access,
    ...parentMeta,
    ...childrenMeta,
  };

  return updatedCategory;
};

export const processCategoryMetadata = ({
  categories,
  routeGroup = 'posts',
  access,
}: {
  categories: CatMeta[];
  routeGroup?: RouteGroup;
  access: RouteAccess;
}): CatMeta[] => {
  return categories.map((category: CatMeta) => appendRouteMetaProps(category, routeGroup, access));
};
