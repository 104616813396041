import { Navigate } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';
import AdminCategories from 'pages/AdminCategories';
import AdminListPage from 'pages/AdminListPage/AdminListPage';
import AdminTitlesPage from 'pages/AdminTitlesPage';
import HomePage from 'pages/HomePage';
import { LayoutPage } from 'pages/Layout/LayoutPage';
import ListPage from 'pages/ListPage';
import NotFound from 'pages/NotFound';
import { LogOut } from './auth/LogOut';
import * as eventos from 'config/datatype/eventos';

export const ROUTES_BASE: RouteObject[] = [
  {
    path: '/',
    element: <LayoutPage />,
    children: [],
  },
  { path: 'logout', element: <LogOut /> },
  { path: '*', element: <NotFound /> },
];

// TODO: REMOVE ?? - ALL ROUTE NOW LOADED DYNAMICALLY..
export const ROUTES_WEB: RouteObject[] = [
  { element: <Navigate to={'/home'} /> },
  {
    path: '',
    element: <HomePage />,
  },
  {
    path: 'eventos',
    element: <ListPage dataConfig={eventos.tableConfig as any} />,
  },
];

// TODO: REMOVE ?? - ALL ROUTE NOW LOADED DYNAMICALLY..
// ROUTES - BASE OBJECT (DYNAMIC ROUTES ADDED)
export const ROUTES_ADMIN: RouteObject[] = [
  /*
  { element: <Navigate to={'/admin/categories'} /> },
  {
    path: 'titles',
    element: <AdminTitlesPage />,
  },
  {
    path: 'categories',
    element: <AdminCategories />,
  },
  {
    path: 'users',
    element: <AdminListPage />,
  },
  /*
  // TODO: MOVED TO `cat/entidades`
  {
    path: 'entidades',
    element: <AdminListPage />,
  },
  */
  /*
  {
    path: 'account',
    element: <AdminListPage />,
  },
  {
    path: 'registros',
    element: <AdminListPage />,
  },
  {
    path: 'eventos',
    element: <AdminListPage />,
  },
  */
  // ..PAGES,
];
