import { ReactNode } from 'react';
import { FORMAT } from 'types/form.types';
import type { ColumnSizes } from 'types/viewport.types';

export type DataEntry = Record<string, any>;
export type ImageProps = { image: string; image_id: string };

export enum ROW_ACTION {
  VIEW = 'view',
  EDIT = 'edit',
  SELECT = 'select',
  CONTACT = 'contact',
}

export enum COL_TYPE {
  DATA = 'data',
  ACTION = 'action',
}

export type ValueFormat = {
  type: FORMAT;
  source?: string;
  // options?: SelectOption[] | UnknownArrayOrObject[];
};

export interface DataColumnConfig {
  name: string;
  label: string;
  columnType: COL_TYPE;
  width?: number | boolean;
  maxWidth?: string | number;
  widths?: ColumnSizes;
  align?: 'left' | 'center' | 'right';
  isSortable: boolean;
  render?: ({ data }: { data: DataEntry }) => ReactNode;
  //  render?: ({ data, valueFormat }: { data: DataEntry; valueFormat?: ValueFormat }) => ReactNode;
  action?: ROW_ACTION;
  valueFormat?: {
    type: FORMAT;
    source?: string;
  };
}
