import { useRouter } from 'routes/hooks/useRouter/useRouter';
import { useNavigateState } from 'hooks/useNavigateState';
import { TabNav } from 'components/TabbedContent';
import { ArrayJSX } from 'utils/ArrayJSX';

// TODO: NEW: CSS-Only TABS:
// https://codepen.io/MPDoctor/pen/mpJdYe

export const Tab = ({ tab, setCurrentTabID, selected }: TabNav) => {
  const { navigate } = useNavigateState();
  const { PATH_BASE } = useRouter();

  const handleChangeTab = () => {
    if (tab.path) {
      navigate(`${PATH_BASE}/${tab.path}`, { replace: false });
    }
    setCurrentTabID(tab.id);
  };

  const cssClasses = new ArrayJSX('tab');
  cssClasses.push(tab.id);
  selected && cssClasses.push('selected');
  tab.accentColor && cssClasses.push(`tab-${tab.accentColor}`);

  return (
    <div className={cssClasses.inline()} onClick={handleChangeTab}>
      {tab.label}
    </div>
  );
};
