import { Row, Col } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import { useNavigateState } from 'hooks/useNavigateState';
import { ReactElement, useEffect } from 'react';
import { Button } from 'components/Button';
import { styles } from './NotFound.css';
import { usePageContentActions } from 'pages/Layout/PageContentContext/PageContent.hooks';
import { useLayout } from 'pages/Layout/context/LayoutContext';
import { useRouteLoaderData } from 'react-router-dom';
import { RoutesLoaderData } from 'routes/routes.types';

const NotFound = (): ReactElement => {
  const { t } = useTranslation();
  const { navigate } = useNavigateState();
  const { setPageTitle, setPageDetail, setPageSubtitle } = usePageContentActions();

  // NOTE: FOR DEVELOPMENT - OUTPUT FULL ROUTES OBJECT
  const { isDevToolsVisible } = useLayout();
  const { appRoutes, transformedRoutes } = useRouteLoaderData('root') as RoutesLoaderData;

  useEffect(() => {
    // TODO: MAKE "reset" UTIL INSIDE OF STORE
    setPageTitle('');
    setPageDetail('');
    setPageSubtitle('');
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <section css={styles}>
      <Row>
        <Col>
          <h3>{t('t.error-pages.404.title')}</h3>
          <p>¯\_(ツ)_/¯</p>
          <Button
            variant="outline"
            color="warningXLight"
            label={t('t.error-pages.buttons.back')}
            onClick={handleGoBack}
          />
          <Button
            variant="outline"
            color="primaryXXLight"
            label={t('t.error-pages.buttons.goto-home')}
            onClick={handleGoHome}
          />
        </Col>
      </Row>
      {isDevToolsVisible && (
        <Row style={{ width: '66%', textAlign: 'left' }}>
          <Col>
            <pre style={{ fontSize: '0.7em', lineHeight: 1.3 }}>
              {JSON.stringify(transformedRoutes, null, 2)}
            </pre>
          </Col>
        </Row>
      )}
    </section>
  );
};

export default NotFound;
