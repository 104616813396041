import { Row, Col } from 'react-grid-system';

export const DevValue = ({ prop, value }: any) => {
  const style = typeof value === 'boolean' ? { color: value === true ? 'green' : 'red' } : {};
  return (
    <Row className="dev-row">
      <Col xs={6}>{prop}:</Col>
      <Col xs={6} style={{ ...style, textAlign: 'right' }}>
        {String(value)}
      </Col>
    </Row>
  );
};
