import type { RouteObject } from 'react-router-dom';
import AccountDetailsPage from 'pages/AccountDetailsPage';
import AccountPage from 'pages/AccountPage';
import AdminListPage from 'pages/AdminListPage';
import Dashboard from 'pages/Dashboard';
// import { routesUserCategories } from 'routes/user/routesUserCategories';
import { getUserCategoryRoutes } from './routesUserCategories';
import { type DataConfiguration, DataActions } from 'routes/utils/getDynamicRoutes.types';
import NotFound from 'pages/NotFound';
import { GlobalStoreValues } from 'store/GlobalSchema.types';
import { AuthUser } from 'auth/types/user.types';

// CONFIGS:
import * as registros from 'config/datatype/registros';

const configs: { [key: string]: DataConfiguration['dataConfig'] } = {
  registros: { ...registros },
};

export const getUserRoutes = ({
  store,
  user,
}: {
  store: GlobalStoreValues;
  user: AuthUser;
}): RouteObject[] => {
  log('__USER', 'orange', user);
  if (!user) {
    return [];
  }

  const routesUser: RouteObject[] = [
    {
      id: 'user',
      path: 'account',
      children: [
        { index: true, element: <AccountPage /> },
        {
          path: 'view',
          children: [
            { index: true, element: <AccountDetailsPage /> },
            { path: ':id', element: <AccountDetailsPage /> },
          ],
        },
        {
          path: 'edit',
          children: [
            { index: true, element: <AccountDetailsPage /> },
            { path: ':id', element: <AccountDetailsPage /> },
          ],
        },
        {
          path: 'registros',
          children: [
            { index: true, element: <AdminListPage dataConfig={configs.registros} /> },
            {
              path: 'create',
              children: [{ index: true, element: <Dashboard /> }],
            },
            { path: 'cat', children: [...getUserCategoryRoutes({ user, store })] },
            { path: '*', element: <NotFound /> },
          ],
        },
      ],
    },
  ];

  return routesUser;
};
