import cloneDeep from 'lodash/cloneDeep';
import type { IParams } from 'types';
import type { FormData as FormDataNode } from 'formdata-node'; // Types for Typescript

// PARAMS OBJECT: coming from router, often contains
// params unrelated to what params we wish to send to API.. let's clean those out !!
// params = { cat: 'canales', id: '6', table: 'posts', action: 'EDIT', from: '/admin/cat/canales' }

// MUTATES ORIGINAL params OBJECT (be careful where/when this is used - do not delete dependecies)
export const cleanParams = ({ params }: { params: IParams }) => {
  if ('from' in params && !params.from) delete params.from;
  if ('action' in params && !params.action) delete params.action;
  if ('cat' in params && !params.cat) delete params.cat;
  if ('parent' in params && !params.parent) delete params.parent;
  if ('sub' in params && !params.sub) delete params.sub;
  if ('slug' in params && !params.slug) delete params.slug;
  if ('table' in params && !params?.table) delete params.table;

  return { params };
};

// SAME AS ABOVE - DOES NOT MUTATE
export const getCleanParams = ({ params }: { params: IParams }) => {
  const paramsCleaned = cloneDeep(params);
  if ('from' in paramsCleaned && !paramsCleaned.from) delete paramsCleaned.from;
  if ('action' in paramsCleaned && !paramsCleaned.action) delete paramsCleaned.action;
  if ('cat' in paramsCleaned && !paramsCleaned.cat) delete paramsCleaned.cat;
  if ('parent' in paramsCleaned && !paramsCleaned.parent) delete paramsCleaned.parent;
  if ('sub' in paramsCleaned && !paramsCleaned.sub) delete paramsCleaned.sub;
  if ('slug' in paramsCleaned && !paramsCleaned.slug) delete paramsCleaned.slug;
  if ('table' in paramsCleaned && !paramsCleaned?.table) delete paramsCleaned.table;

  return paramsCleaned;
};

export const getEndpoint = ({ params }: { params: IParams }) => {
  // eslint-disable-next-line prettier/prettier
  const endpoint = params?.endpoint ? params?.endpoint : params?.cat ? 'posts' : params?.table;

  return { endpoint };
};

export const getFormDataToJsonString = ({ formData }: { formData: FormData | string }): string => {
  if (typeof formData === 'object') {
    /*
    let formData = new FormData()
    let time = new Date()
    console.log("statement: formData is a FormData instance", formData instanceof FormData) // statement is true
    console.log("statement: time is a FormData instance", time instanceof FormData) // statment is false
    */
    // if (formData instanceof FormData) {
    const plainFormData = Object.fromEntries((formData as FormDataNode).entries());
    const formDataJsonString = JSON.stringify(plainFormData);

    return formDataJsonString;
  }

  return formData;
};

export const getIdempotencyKeyHeader = (idempotencyKey: string): Headers => {
  const headers = new Headers();
  headers.set('X-Idempotency-Key', idempotencyKey);

  return headers;
};
