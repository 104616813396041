import rest from '@feathersjs/rest-client';
import axios from 'axios';
import { apiConfig } from 'api';
import { createClient } from 'auth/client/createClient';
import config from 'config';
import { DEFAULT_AUTH_OPTIONS_CORE } from './client.config';
import type { AuthClient, AuthClientOptions } from '../types/auth.types';

const { API_BASE_URL } = config;

const DEFAULT_AUTH_OPTIONS_REST: AuthClientOptions = {
  ...DEFAULT_AUTH_OPTIONS_CORE,
  header: 'Authorization', // the default authorization header for REST
  scheme: 'Bearer',
  storage: window.localStorage,
};

const restClient = rest(API_BASE_URL).axios(axios); // V1

export const client: AuthClient = createClient({
  connection: restClient,
  authOptions: { ...DEFAULT_AUTH_OPTIONS_REST },
});

// ======================================================================== //

// NOTE: axios-specific config could be used in future, but not currently necessary
const axiosConfig = axios.create({
  ...apiConfig,
  headers: {
    ...(apiConfig as any).headers,
    Authorization: 'Bearer <Token for other-feathers-api.com>',
  },
});

// NOTE: alternate `restClient` initialisations:
// const restClient = rest(API_BASE_URL).axios(axiosConfig); // V2
// const restClient = client.configure(restClient.fetch(window.fetch.bind(window))); // V3
// const restClient = rest(); // V4 - UNSET is SAME host
