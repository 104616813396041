import { INPUTS, FORMAT, DEFAULT_VALUE } from 'types/form.types';
import { AUTH_USER } from 'types/form.types';
import type { FormHeroConfig, FieldConfig, FormButton, FormConfig } from 'types/form.types';
import { BUTTONS, types } from 'components/Form/components/FormButtonBar/FormButtonBar.types';
import type { SelectOption } from 'components/FormUI/Select';
import {
  FIELDS_ADDRESS,
  FIELDS_DOCS_BIZ,
  FIELDS_PERSONAL_DATA,
  FIELDS_DOCS_PERSONAL,
  FIELDS_SOCIALS,
  FIELDS_CONTACT_PUBLIC,
  FIELDS_CONTACT_PRIVATE,
  FIELDS_CONFIRM_SIGNATOR,
} from 'config/form.sections';
import { TAGS_COUNTRIES } from 'constants/countries';
import { OPTIONS_LANGUAGES, TAGS_LANGUAGES } from 'constants/languages';
import { getFieldsetConfig } from 'config/data.utils/getFieldsetConfig';
import { DATATYPE, ENDPOINT } from './canales.config';
import { OPTIONS_COUNTRIES_FLAGS } from 'constants/countries';

let i = 0;

const SUBJECT = 'canale';
const SUBJECT_PLURAL = 'canales';

const buttons: FormButton[] = [
  {
    label: 'CANCEL',
    type: types.button,
    action: BUTTONS.CANCEL,
  },
  {
    label: 'SAVE',
    type: types.submit,
    action: BUTTONS.SUBMIT,
  },
];

const hero: FormHeroConfig = {
  heroTitleKey: ['name'],
};

// NOTE: FOLLOWING CONFIG WILL BE MERGED WITH form.config.ts/globalFieldProps
// TO POPULATE COMMON PROPS, OPTIONS etc
export const formConfig: FormConfig = {
  dataType: { key: DATATYPE, role: 'form' },
  api: { endpoint: ENDPOINT },
  dataStructure: {
    source: {
      columns: ['name'],
      json_meta: true,
    },
    target: {
      columns: ['name'],
      json_meta: true,
    },
  },
  isNavPromptActive: true,
  hero,
  buttons,
  sections: [
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Datos del medio principal (CANALES)',
      fields: [
        {
          name: 'name',
          label: 'nombre de medio',
          inputType: INPUTS.text,
          required: true,
        },
        {
          name: 'image',
          placeholder: 'Escoge archivo...',
          hint: 'Imagen pricipal',
          inputType: INPUTS.image,
        },
      ],
    },
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Datos del titular o representante',
      fields: [
        ...getFieldsetConfig(FIELDS_PERSONAL_DATA),
        ...[
          {
            name: 'position',
            label: 'cargo del titular',
            inputType: INPUTS.text,
            required: false,
            readonly: true,
          },
          ...getFieldsetConfig(FIELDS_DOCS_PERSONAL),
        ].map((fieldConfig) => ({ ...fieldConfig, colWidth: { xs: 12, md: 4 } })),
      ],
    },
    // ======================================================================== //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Datos fiscales del medio',
      fields: [...getFieldsetConfig(FIELDS_DOCS_BIZ)],
    },
    // ======================================================================== //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Dirección fiscal',
      fields: [...getFieldsetConfig(FIELDS_ADDRESS, { prefix: 'biz_' })],
    },
    // ======================================================================== //
    {
      // NOTE: VERSION A: COPY_FROM
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Dirección postal',
      description: 'Usando los mismos datos de la dirección fiscal, por defecto',
      sectionToggle: {
        description: 'Usando datos distintos para dirección postal',
        name: 'copy_from_user_address',
        label: 'Marcar si la dirección postal NO coincide con la dirección fiscal.',
        inputType: INPUTS.checkbox,
        defaultValue: false,
        params: { endpoint: 'register', uuid: AUTH_USER.UUID },
      },
      fields: [...getFieldsetConfig(FIELDS_ADDRESS)],
    },
    // ======================================================================== //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Más detalles',
      fields: [
        {
          name: 'url',
          placeholder: 'Escoge archivo...',
          inputType: INPUTS.url,
          required: true,
        },
        // TODO:  -- MAKE SELECT DROPDOWN -
        // TODO: USE CURRENT "WEB"
        // TODO: IMPORTANT - FETCH SUB-CATS FROM DB !!!!!
        // {
        //   name: 'cats',
        //   label: 'Categorías',
        //   inputType: INPUTS.select,
        // },
        {
          name: 'sector',
          label: 'Tipo de medio o canal',
          inputType: INPUTS.select,
          options: [
            {
              value: 'canal-de-informacion',
              label: 'Canal de información',
            },
            {
              value: 'periodico-digital',
              label: 'Periódico digital',
            },
            {
              value: 'redes-sociales',
              label: 'Redes Sociales',
            },
          ] as SelectOption[],
          required: true,
          colWidth: {
            xs: 12,
            lg: 6,
          },
        },
        {
          name: 'language',
          label: 'Idioma',
          inputType: INPUTS.select,
          options: OPTIONS_LANGUAGES,
          required: true,
          colWidth: {
            xs: 12,
            lg: 6,
          },
        },
        {
          // TODO: make drodpown
          name: 'geo_range',
          label: 'Alcance: (Indique países con seguimiento)',
          // inputType: INPUTS.selectCountry,
          inputType: INPUTS.selectMulti,
          options: OPTIONS_COUNTRIES_FLAGS,
          required: true,
          colWidth: {
            xs: 12,
            // md: 6,
          },
        },
        {
          name: 'description',
          label: 'Descripción',
          // hint: 'más información',
          inputType: INPUTS.textarea,
          required: true,
          colWidth: {
            xs: 12,
          },
        },
        // {
        //   name: 'activities',
        //   label: 'Información adicional',
        //   inputType: INPUTS.textarea,
        //   required: false,
        // },
      ],
    },
    // ======================================================================== //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Datos de contacto públicos',
      description: 'visibles a todo el mundo en la web pública',
      fields: [...getFieldsetConfig(FIELDS_CONTACT_PUBLIC)],
    },
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Datos de contacto para usuarios registrados',
      description: 'visibles solo por titulares de otros medios o entidades',
      fields: [...getFieldsetConfig(FIELDS_CONTACT_PRIVATE)],
    },
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Canales secundarios',
      description: 'Indique canales secundarios o redes sociales',
      fields: [...getFieldsetConfig(FIELDS_SOCIALS)],
    },
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'A devolver firmado:',
      fields: [...getFieldsetConfig(FIELDS_CONFIRM_SIGNATOR)],
    },
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      fields: [
        {
          name: 'cat_id',
          inputType: INPUTS.uuid,
          // defaultValue: DEFAULT_VALUE.cat_id,
          isHidden: true,
        },
      ],
    },
  ],
};
