import { useContext } from 'react';
import { useStore, type StoreApi } from 'zustand';
import type { PageContentStore } from './PageContent.types';
import { PageContent } from './PageContentContext';

export const usePageContentSubscriptions = () => {
  const store = useContext(PageContent.Context);
  if (!store) {
    throw new Error('usePageContentActions must be used within a PageContentProvider');
  }
  store.subscribe((state) => {
    // log('__PAGE_CHANGE - EVENT', 'cyan', state);
  });
  return;
};

export const usePageContentActions = (): PageContentStore['actions'] => {
  const store = useContext(PageContent.Context);
  if (!store) {
    throw new Error('usePageContentActions must be used within a PageContentProvider');
  }
  return useStore<StoreApi<PageContentStore>, PageContentStore['actions']>(store, (state) => state.actions);
};

export const usePageContent = (): Omit<PageContentStore, 'actions'> => {
  const store = PageContent.useContext();
  if (!store) {
    throw new Error('usePageContent must be used within a PageContentProvider');
  }
  return useStore<StoreApi<PageContentStore>, Omit<PageContentStore, 'actions'>>(
    store,
    ({ actions, ...state }) => ({
      ...state,
    }),
  );
};
