import { Col, Row } from 'react-grid-system';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import type { ReactNode } from 'react';
import type { DataEntry, TableConfig } from 'types';
import { useRouter } from 'routes/hooks';
import { useDataFilter } from 'store/DataFilterContext';
import { DataGrid } from 'components/DataGrid';
import { optionsSetLoud } from 'components/Form';
import { optionsRHF } from 'components/Form/config';
import { ListItemRow as ListItemDefault } from 'components/ListItemRow';
import { NoEntries } from 'components/NoEntries';
import { Pagination, PaginationProvider } from 'components/Pagination';
import { Spinner } from 'components/Spinner';
import { useInviteFilters } from './InviteFiltersContext';
import { InviteForm } from './InviteForm';
import { useConfigAndData } from './useConfigAndData';

export type InviteLayoutProps = {
  fieldName: string;
  isFetching: boolean;
  data: DataEntry[];
  tableConfig: TableConfig;
  selectedIDs?: string[];
};

export const LayoutPage = ({ fieldName, isFetching = false, data, tableConfig, selectedIDs }: InviteLayoutProps) => {
  const { route } = useRouter();
  const { results = [] } = useDataFilter();

  // ⚠️ TO UPDATE *PARRENT* FORM VALUES, *NOT* CHILD FORM (below)
  const { setValue } = useFormContext();
  const changeParentFormValues = (ids: string[]) => {
    setValue(fieldName, ids.join(','), optionsSetLoud);
  };

  // LIST ============================================================= //

  const { values } = useConfigAndData({ tableConfig, data: results }); // TODO: schema needed as prop ?? don't think so...
  const { setFilters: _, ...filtersState } = useInviteFilters();
  const formMethods = useForm({ ...optionsRHF, defaultValues: { ...filtersState } });

  // LIST ============================================================= //

  const listConfig = tableConfig.list || ({} as any);
  const listItemConfig = listConfig.listItem || ({} as any);

  const CARD_SHAPE_FACTOR = listItemConfig?.shapeRatio ?? 5 ?? 2;
  const ListItemCard: ReactNode = listItemConfig?.component ?? ListItemDefault;

  // LIST ============================================================= //

  if (!values) return null;

  return (
    <FormProvider {...formMethods}>
      <PaginationProvider numItems={results.length} maxPerPage={tableConfig.list?.maxRows} selectedIDs={selectedIDs}>
        <Pagination numResults={data.length || 0} />
        <Row className="row-form">
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="col col-form">
            <InviteForm setSelectedIDs={changeParentFormValues} />
          </Col>
        </Row>
        {isFetching && <Spinner page dark />}
        {!isFetching &&
          (results.length > 0 ? (
            <Row className="row-list">
              <Col xs={12} sm={12} md={12} lg={12} xl={12} className="col col-list">
                <div className="results-list">
                  <DataGrid
                    data={results}
                    tableConfig={tableConfig}
                    itemComponent={ListItemCard}
                    shapeRatio={CARD_SHAPE_FACTOR}
                    basePath={route?.path}
                    hasHeader
                  />
                </div>
              </Col>
            </Row>
          ) : (
            <>
              <hr />
              <NoEntries />
            </>
          ))}
        <Pagination />
      </PaginationProvider>
    </FormProvider>
  );
};
