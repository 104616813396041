import type { IOptionTag } from 'components/FormUI/InputTags';
import type { SelectOption } from 'components/FormUI/Select';

export const SPAIN_PROVINCES: SelectOption[] = [
  {
    value: 'alava',
    label: 'Álava',
  },
  {
    value: 'alicante',
    label: 'Alicante',
  },
  {
    value: 'albacete',
    label: 'Albacete',
  },
  {
    value: 'almeria',
    label: 'Almería',
  },
  {
    value: 'asturias',
    label: 'Asturias',
  },
  {
    value: 'avila',
    label: 'Ávila',
  },
  {
    value: 'balears',
    label: 'Islas Balears',
  },
  {
    value: 'barcelona',
    label: 'Barcelona',
  },
  {
    value: 'badajoz',
    label: 'Badajoz',
  },
  {
    value: 'burgos',
    label: 'Burgos',
  },
  {
    value: 'a-coruna',
    label: 'La Coruña',
  },
  {
    value: 'caceres',
    label: 'Cáceres',
  },
  {
    value: 'cadiz',
    label: 'Cádiz',
  },
  {
    value: 'cantabria',
    label: 'Cantabria',
  },
  {
    value: 'ceuta',
    label: 'Ceuta',
  },
  {
    value: 'cordoba',
    label: 'Córdoba',
  },
  {
    value: 'ciudad-real',
    label: 'Ciudad Real',
  },
  {
    value: 'castellon',
    label: 'Castellón',
  },
  {
    value: 'cuenca',
    label: 'Cuenca',
  },
  {
    value: 'las-palmas',
    label: 'Las Palmas',
  },
  {
    value: 'girona',
    label: 'Girona',
  },
  {
    value: 'granada',
    label: 'Granada',
  },
  {
    value: 'guadalajara',
    label: 'Guadalajara',
  },
  {
    value: 'guipuzcoa',
    label: 'Guipúzcoa',
  },
  {
    value: 'huelva',
    label: 'Huelva',
  },
  {
    value: 'huesca',
    label: 'Huesca',
  },
  {
    value: 'jaen',
    label: 'Jaén',
  },
  {
    value: 'la-rioja',
    label: 'La Rioja',
  },
  {
    value: 'leon',
    label: 'León',
  },
  {
    value: 'lleida',
    // label: 'Lleida',
    label: 'Lérida',
  },
  {
    value: 'lugo',
    label: 'Lugo',
  },
  {
    value: 'madrid',
    label: 'Madrid',
  },
  {
    value: 'malaga',
    label: 'Málaga',
  },
  {
    value: 'melilla',
    label: 'Melilla',
  },
  {
    value: 'murcia',
    label: 'Murcia',
  },
  {
    value: 'navarra',
    label: 'Navarra',
  },
  {
    value: 'ourense',
    // label: 'Ourense',
    label: 'Orense',
  },
  {
    value: 'palencia',
    label: 'Palencia',
  },
  {
    value: 'pontevedra',
    label: 'Pontevedra',
  },
  {
    value: 'salamanca',
    label: 'Salamanca',
  },
  {
    value: 'santa-cruz-de-tenerife',
    label: 'Santa Cruz de Tenerife',
  },
  {
    value: 'segovia',
    label: 'Segovia',
  },
  {
    value: 'sevilla',
    label: 'Sevilla',
  },
  {
    value: 'soria',
    label: 'Soria',
  },
  {
    value: 'tarragona',
    label: 'Tarragona',
  },
  {
    value: 'teruel',
    label: 'Teruel',
  },
  {
    value: 'toledo',
    label: 'Toledo',
  },
  {
    value: 'valencia',
    label: 'Valencia',
  },
  {
    value: 'valladolid',
    label: 'Valladolid',
  },
  {
    value: 'vizcaya',
    label: 'Vizcaya',
  },
  {
    value: 'zaragoza',
    label: 'Zaragoza',
  },
  {
    value: 'zamora',
    label: 'Zamora',
  },
];
