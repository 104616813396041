import { css } from '@emotion/react';
import Color from 'color';
import { colors, layout, BREAKPOINTS, min, max } from 'styles';

const colorNav = Color(colors.primaryXLight);
const colorsNav = {
  bg: colorNav.lighten(0.4).hex(),
  inactive: colorNav.lighten(0.1).desaturate(0.95).hex(),
  sep: colorNav.darken(0.2).fade(0.75).rgb().toString(),
};

export const styles = css`
  .col-navs {
    display: flex;
    justify-content: space-between;
  }
  .nav-flex {
    display: inline-flex;
  }

  &.nav-horizontal {
    left: 0;
    right: 0;
    min-height: 41px;
    ul {
      list-style: none;
      margin: 0;
      display: flex;
    }
    li {
      font-size: 1.1em;
      &::before {
        content: none;
      }
      a.inactive {
        &:hover {
          color: ${colors.primaryXLight};
        }
      }
      a,
      a.inactive {
        display: block;
        color: ${colors.defaultLight};
        color: ${colorsNav.inactive};
        font-weight: 700;
      }
      a.active {
        color: ${colors.primary};
      }
    }
  }
`;

export const stylesHorizontal = css`
  ${styles}
  position: sticky;
  width: 100%;
  background-color: ${colors.white};
  background-color: ${colorsNav.bg};
  top: calc(${layout.header.height} + ${layout.padding} / 2);
  padding: calc(${layout.padding}*0.66) 0;
  z-index: 20;

  ul {
    flex-direction: row;
    align-items: flex-start;
  }

  li {
    a {
      padding: 0 1.25em;
      border-left: 1px solid ${colorsNav.sep};
      border-right: 1px solid ${colorsNav.sep};
    }
    &:first-of-type a {
      border-left: 0;
      padding-left: 0.5em;
    }
    &:last-of-type a {
      border-right: 0;
      padding-right: 0.5em;
    }
  }

  /********** RESPONSIVE **********/

  ${max.lg} {
    display: none;
  }

  .nav-container {
    ${min.lg} {
      padding: 0 calc(${layout.padding} * 2.5) !important;
    }
    ${min.xl} {
      padding: 0 calc(${layout.padding} * 3.5) !important;
    }
    ${min.xxl} {
      padding: 0 calc(${layout.padding} * 1.5) !important;
    }
  }
`;

/** ******** CATEGORIES **********/

export const stylesCats = css`
  ${stylesHorizontal}
  top: calc(${layout.header.height});
  transform: translateY(100%);
  background-color: ${colorNav.lighten(0.6).hex()};

  li {
    a.inactive {
      &:hover {
        color: ${colors.primaryDark};
      }
    }
    a,
    a.inactive {
      color: ${colors.defaultXDark};
      color: ${colorsNav.inactive};
    }
    a.active {
      color: ${colors.primaryXXDark};
    }
  }
`;

/** ******** VERTICAL **********/

export const stylesVertical = css`
  ${styles}
  padding: calc(${layout.padding}*0.66) 0;
  z-index: 20;

  ul {
    flex-direction: column;
    align-items: flex-start;
  }

  li {
    padding: 0.5em 0 !important;
    a,
    a.inactive {
      padding: 0 0.66em;
      border-left: 4px solid ${colors.greyXLight};
    }
    a.active {
      border-left: 4px solid ${colors.greyXLight};
    }
  }

  /********** RESPONSIVE **********/

  & > div {
    ${max.xxl} {
      padding: 0 calc(${layout.padding} * 2.5) !important;
    }
    ${min.xxl} {
      padding: 0 calc(${layout.padding} * 1.5) !important;
    }
  }
`;
