import { useMutationState, useIsMutating } from '@tanstack/react-query';

export const useUserRegistration = () => {
  const isUpdating = !!useIsMutating({ mutationKey: ['registration'] });

  const dataRegistrationUpdate = useMutationState({
    filters: { mutationKey: ['updateEntry', { endpoint: 'registration' }], status: 'success' },
    select: (mutation: any) => mutation.state.data.data,
  })?.at(-1);

  return {
    dataRegistrationUpdate,
    isUpdating,
  };
};
