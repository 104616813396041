import { Row, Col } from 'react-grid-system';
import { useFormContext } from 'react-hook-form';
import { ReactElement, useState, useEffect } from 'react';
import { styles } from './FormValuesJSON.css';

export const FormValuesJSON = (): ReactElement => {
  // ======================================================================== //
  // TODO: TEMP DEV - REMOVE !!
  // return <></>;
  // ======================================================================== //

  const { getValues } = useFormContext();
  const values = getValues();

  /*
  const getSummaryData = (data: any) => {
    const { id, name, province, cat_id, user_id, ...summary } = data;
    return { id, name, province, cat_id, user_id };
  };

  const getParseDataJSON = (data: any) => {
    try {
      const parsed = JSON.parse(data);
      return parsed;
    } catch {
      return;
    }
  };

  const dataJSON = getParseDataJSON(values.json_meta || values);
  */

  return (
    <Row css={styles}>
      <Col>
        <pre>
          <strong>db column values:</strong>
          <br />
          <br />
          {/* {JSON.stringify(getSummaryData(values), null, 2)} */}
          {JSON.stringify(values, null, 2)}
        </pre>
        {/* <hr />
        <pre>
          <strong>parsed json_meta values:</strong>
          <br />
          <br />
          {JSON.stringify(dataJSON, null, 2)}
        </pre> */}
      </Col>
    </Row>
  );
};
