import { api, headersDefault as headers, getQueryString } from 'api';
import type { QueryOptions, UseQueryGetProps } from 'api/query';
import { sleep } from 'utils';

// TODO: Consolodate ??  `args` parsing + `queryResponse` object construction can def both be moved to a util

export const getData = async ({ endpoint, query, filter }: QueryOptions): Promise<Readonly<any>> => {
  const DATA_EMPTY = endpoint === 'metadata' || (query && 'id' in query) ? {} : [];
  const response = await api.get(`${endpoint}`, { params: query });
  await sleep(100);

  log('[GET]', 'lime', { query, filter }, response.status, response.statusText, response.data);
  log('--------------------------------------------------------------', 'grey');

  // MOVE schema FROM OUT OF responseData
  const schema = response.data?.schema || null;
  delete response.data?.schema;
  const queryResponse = { data: DATA_EMPTY, schema };

  if (endpoint === 'metadata') return { ...response.data, data: response.data.data[0], total: response.data?.total };
  return { ...queryResponse, ...response.data, total: response.data?.total };
};
