import { css } from '@emotion/react';
import { colors, layout, max, BREAKPOINTS } from 'styles';

export const styles = css`
  /*** reset ***/
  position: unset;
  width: unset;

  .col-footer-left {
    justify-content: flex-start;
    align-items: center;
  }
  .col-footer-right {
    justify-content: flex-end;
    align-items: center;
  }

  font-size: 0.85em;
  color: ${colors.grey};

  a {
    font-weight: 700;
    margin-left: 0.33em;
  }

  /********** RESPONSIVE **********/

  ${max.md} {
    & > div {
      & > div {
        height: 100%;
      }
    }
    .col-footer-left,
    .col-footer-right {
      justify-content: center;
    }
  }
`;
