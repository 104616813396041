import { useWatch, useFormContext } from 'react-hook-form';
import ReactSelect from 'react-select';
import type { SelectInstance } from 'react-select';
import { forwardRef, useEffect } from 'react';
import type { ForwardedRef } from 'react';
import type { FieldConfig } from 'components/Form';
import type { SelectOption, SelectProps } from 'components/FormUI/Select/Select.types';
import { OPTIONS_COUNTRIES_FLAGS } from 'constants/countries';
import { useSelectConfig, SELECT_DEFAULT_PROPS } from '../Select';
import { styles } from './SelectCountry.css';

interface SelectWithRequiredField extends SelectProps {
  field: FieldConfig;
}

export const SelectCountry = forwardRef<any, SelectWithRequiredField>(
  (
    { field, options: initOptions, defaultValue, onChange }: SelectWithRequiredField,
    ref: ForwardedRef<SelectInstance>,
  ) => {
    // TODO: REVIEW THIS HOOK..

    const { getValues } = useFormContext();

    const { options, currentSelection, setCurrentSelection, handleChange } = useSelectConfig({
      initOptions: initOptions || OPTIONS_COUNTRIES_FLAGS,
      defaultValue,
      onChange,
      isDev: true,
    });

    const currentValue = useWatch({ name: field.name });

    useEffect(() => {
      const changedOption = options.find((option: any) => option.value === currentValue);
      setCurrentSelection(changedOption);
    }, [currentValue]);

    const selectProps = {
      ...SELECT_DEFAULT_PROPS,
      ariaLabel: field?.label,
      className: `input-select select-${field?.name}`,
      isMulti: false,
      ...(field?.placeholder && { placeholder: field?.placeholder }),
    };

    return (
      <div css={styles}>
        {ref ? (
          <ReactSelect
            name={field.name}
            ref={ref as ForwardedRef<SelectInstance>}
            options={options}
            // defaultValue={currentSelection} // NOTE: removed to MATCH other Select components, and allow mocking
            defaultValue={options.find((option: SelectOption) => option.value === field?.defaultValue)} // NOTE: removed to MATCH other Select components, and allow mocking
            value={currentSelection} // NOTE: removed to MATCH other Select components, and allow mocking
            // value={options.find((option: SelectOption) => option.value === currentValue)}
            onChange={handleChange}
            {...selectProps}
          />
        ) : null}
      </div>
    );
  },
);
