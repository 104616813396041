import type { ReducerActions } from 'types/ReducerActions';
import type { DataFilterState } from './DataFilterState';

// ======================================================================== //
// EVENTS FILTER REDUCER:

export const dataFilterReducer = (state: DataFilterState, action: ReducerActions<DataFilterState>): DataFilterState => {
  switch (action.type) {
    case 'set_dataFiltered':
      return { ...state, dataFiltered: action.payload };
    case 'set_results':
      return { ...state, results: action.payload };
    case 'SET_FIELDS':
      return { ...state, ...action.payload };

    default:
      Object.assign(state, action.payload);
      console.error(`Action of ${action} is not supported`);
      return state;
  }
};
