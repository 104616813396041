import { INPUTS } from 'types/form.types';
import type { FieldConfig } from 'components/Form';

export const FIELDS_CONFIRM_SIGNATOR: FieldConfig[] = [
  {
    name: 'accept_0',
    label: 'Declaración responsable (VER)',
    inputType: INPUTS.checkbox,
    required: false,
    colWidth: { xs: 12 },
  },
  {
    name: 'accept_1',
    label: 'Acuerdo de colaboración (VER)',
    inputType: INPUTS.checkbox,
    required: false,
    colWidth: { xs: 12 },
  },
];
