import type { IGeneric } from 'types';
/**
 * POJO = "Plain Old Javascript Object"
 * JS Objects have inherent properties
 * typeof ARRAY will return 'object',
 * but below function can be used to determine TRUE Object!
 *
 * @param {object} arg object to test if is POJO
 * EXAMPLES:
 * isPOJO({}); // true
 * isPOJO(Object.create(null)); // true
 * isPOJO(null); // false
 * isPOJO(new Number(42)); // false
 */
export const isPOJO = (obj: unknown): obj is object => {
  if (typeof obj !== 'object' || obj === null) return false;

  let proto = obj;
  while (Object.getPrototypeOf(proto) !== null) {
    proto = Object.getPrototypeOf(proto);
  }

  return Object.getPrototypeOf(obj) === proto;
};

// ==================================================================== //

/**
 * Returns a new object with the values at each key mapped using mapFn(value)
 *
 * @param {object} obj object to re-map
 * @param {mapFn} mapFn function to perform remapping
 * EXAMPLE USE:
 * const newObject = objectMap(myObject, (value) => {
 *   return value * 2
 * })
 */
export function objectMap(obj: IGeneric, mapFn: any) {
  return Object.keys(obj).reduce((result: IGeneric, key: any) => {
    result[key] = mapFn(obj[key]);
    return result;
  }, {});
}

// ==================================================================== //

export const isSet = (toTest: unknown) => {
  if (typeof toTest === 'boolean' && toTest === false) return true;
  if (typeof toTest === 'string' && toTest > '') return true;
  if (typeof toTest === 'number') return true;
  if (Array.isArray(toTest) && toTest.length > 0) return true;
  if (typeof toTest === 'object' && toTest !== null && Object.entries(toTest).length) return true;
  return false;
};

// ==================================================================== //

export const sortArrayByKeyV1_SINGLE = (arr: any[], sortKey = 'name') => {
  const arrSorted = [...arr].sort((a, b) => {
    if (a[sortKey] === sortKey) return -1;
    if (b[sortKey] === sortKey) return 1;
    return 0;
  });

  return arrSorted;
};

// TODO: MAKE SMARTER VERSION WHERE .name IS NOT ALWAYS KEY TESTED
export const sortArrayByKeys = (arr: any[], ...sortKeys: string[]) => {
  sortKeys.reverse(); // first criterium take precedence
  for (const byKey of sortKeys) {
    arr.sort((a, b) => {
      if (a.name === byKey) return -1;
      if (b.name === byKey) return 1;
      return 0;
    });
  }

  return arr;
};

// ==================================================================== //

export function mergeArraysOfObjects(arrays: IGeneric, prop: string) {
  const merged: any = {};
  arrays.forEach((arr: IGeneric) => {
    arr.forEach((item: IGeneric) => {
      merged[item[prop]] = Object.assign({}, merged[item[prop]], item);
    });
  });
  return Object.values(merged);
}

// delete props = null (only)
export function omitNull(obj: any) {
  if (!obj) return obj;
  Object.keys(obj)
    .filter((key) => obj[key] === null)
    .forEach((key) => delete obj[key]);
  return obj;
}

// delete props = undefined (only)
export function omitUndefined(obj: IGeneric) {
  Object.keys(obj)
    .filter((key) => obj[key] === undefined)
    .forEach((key) => delete obj[key]);
  return obj;
}

// delete props = null AND undefined
export function omitUnset(obj: IGeneric) {
  Object.keys(obj)
    .filter((key) => obj[key] === null || obj[key] === undefined)
    .forEach((key) => delete obj[key]);
  return obj;
}

export function deepClone(value: any) {
  const deepArray = (collection: any) => {
    return collection.map((val: unknown) => deepClone(val));
  };

  const deepObject = (source: IGeneric) => {
    const result: any = {};
    Object.keys(source).forEach((key) => {
      const val = source[key];
      result[key] = deepClone(val);
    }, {});
    return result;
  };

  if (typeof value !== 'object') return value;
  if (value === null) return value;
  if (Array.isArray(value)) return deepArray(value);

  return deepObject(value);
}

export const removeUndefinedProps = (obj) => {
  const filteredEntries = Object.entries(obj).filter(([key, value]) => value !== undefined);
  return Object.fromEntries(filteredEntries);
};
