import addHours from 'date-fns/addHours';
import { v4 as uuid } from 'uuid';

const SESSION_LENGTH_HRS = 1;

export const useGuestSession = () => {
  const getNow = () => Math.floor(Date.now() / 1000);
  const getGuestSession = () => JSON.parse(localStorage.getItem('guest') as string);

  const isExpired = () => {
    const storedSession = getGuestSession();
    return !!(storedSession && getNow() < storedSession.expires);
  };

  const getRelativeExpiry = () => {
    const now = getNow();
    const expires = addHours(now, SESSION_LENGTH_HRS).getTime();

    return expires;
  };

  const storeNewGuestSession = () => {
    const guest = {
      uuid: uuid(),
      expires: getRelativeExpiry(),
    };
    localStorage.setItem('guest', JSON.stringify(guest));
  };

  const updateStoredAuth = () => {
    const storedSession = getGuestSession();
    const sessionUUID = storedSession && isExpired() ? uuid() : storedSession?.uuid;
    const expires = getRelativeExpiry();
    const guest = { ...storedSession, uuid: sessionUUID, expires };

    localStorage.setItem('guest', JSON.stringify(guest));
  };

  if (isExpired()) {
    localStorage.removeItem('guest');
  } else {
    updateStoredAuth();
  }

  return { guest: updateStoredAuth(), storeNewGuestSession };
};
