import { useState, useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import type { UseQueryResult, UseQueryOptions } from '@tanstack/react-query';
import { v4 as uuid } from 'uuid';
import { METADATA } from 'types';
import { api, getQueryString } from 'api';
import { omitUnset } from 'utils';
import { getIdempotencyKeyHeader } from './api.utils';
import { QueryOptions, UseQueryOptions__DEPRECATED, QueryParams } from './query';
import { getData, getSchema } from './utils';

interface UseQueryGetProps extends Partial<UseQueryOptions>, UseQueryOptions__DEPRECATED, QueryOptions {
  meta_key: METADATA;
}

export const fetchGetData = async ({
  endpoint,
  query,
  queryParams,
  idempotencyKey,
}: any): Promise<Readonly<any>> => {
  const headerIdempotencyKey = getIdempotencyKeyHeader(idempotencyKey);
  const params = { ...queryParams, ...query };
  const response = await api.get(endpoint, { params });

  const responseData = response.data;
  delete responseData?.schema;

  const dataParsed = JSON.parse(responseData.data[0]?.value);
  log(`[GET] ${params.meta_key}`, 'violet', dataParsed);

  return dataParsed;
};

const useGetMetadata = (args: UseQueryGetProps): UseQueryResult<any, unknown> & any => {
  const { meta_key, enabled = true, onSuccess, onError, schemaOnly = false, query, ...params } = args;

  // TODO: NEEDED ??
  omitUnset(params);
  const endpoint = 'metadata';

  const { data, isPending, isSuccess, isError, ...queryResult } = useQuery({
    queryKey: ['getMetadata', meta_key],
    queryFn: async () => {
      return fetchGetData({
        endpoint,
        queryParams: {
          ...params,
          meta_key,
        },
      });
    },
    enabled,
    refetchOnWindowFocus: false,
    gcTime: Infinity,
  });

  useEffect(() => {
    if (!isPending && isSuccess) {
      onSuccess?.(data);
    }
  }, [!isPending, isSuccess, data]);

  return { data, isPending, isSuccess, isError, ...queryResult };
};

export { useGetMetadata };
