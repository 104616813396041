import type { IOptionTag } from 'components/FormUI/InputTags';
import type { SelectOption } from 'components/FormUI/Select';
import { COUNTRIES, CountryData } from './countries-V2';

export { COUNTRIES };
export type { CountryData };

export const TAGS_COUNTRIES = COUNTRIES.map((country: CountryData) => {
  return {
    id: country.id,
    name: country.name_en,
  } as IOptionTag;
});

export const OPTIONS_COUNTRIES = COUNTRIES.map((country: CountryData) => {
  return {
    key: country.id,
    id: country.id,
    value: country.name_en,
    label: country.name_en,
    flag: country.flag,
  };
});

export const OPTIONS_COUNTRIES_FLAGS__V1 = COUNTRIES.map((country: CountryData) => {
  return {
    key: country.id,
    id: country.id,
    value: country.name_en,
    label: (
      <div className="option-custom" aria-label={country.name_en}>
        <span id={country.id}>{country.name_en}</span>
        <span className="option-flag">{country.flag}</span>
      </div>
    ),
    flag: country.flag,
  };
});

export const OPTIONS_COUNTRIES_FLAGS = COUNTRIES.map((country: CountryData) => {
  return {
    key: country.id,
    id: country.id,
    value: country.id,
    label: `${country.flag} ${country.name_en}`,
    flag: country.flag,
  };
});
