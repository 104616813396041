import { css } from '@emotion/react';
import { colors, layout, min, max, forms } from 'styles';

export const styles = css`
  ${min.lg} {
    transform: translateX(calc(${layout.padding} / 2));
    columns: 2 auto;
    column-gap: 40px;
    column-span: all;
    column-fill: auto;
    padding: 0;
    /* margin: -15px -6px; */
  }
  a {
    display: block;
    padding: 0;
    color: ${colors.primary};
    font-weight: 700;
  }
  button {
    padding: 0.5em;
  }

  .social-row {
    border-top: ${layout.padding} solid transparent;
    border-bottom: ${layout.padding} solid transparent;
  }

  .social-field {
    display: block;
    flex-direction: column;
    margin: ${layout.padding};
    /* margin: 0 15px 15px 15px; */
    padding: 0 ${layout.padding} 0 calc(${layout.padding} / 2) !important;
    label {
      position: absolute;
      width: ${forms.inputs.height};
      height: ${forms.inputs.height};
      bottom: -6px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        transform: scale(1.66);
      }
    }
    input {
      width: 100%;
      padding-left: 4em;
    }

    input:invalid {
      border: ${layout.borderWidth} solid ${colors.warning} !important;
    }
  }
`;
