import { Row, Col } from 'react-grid-system';
import { styles } from './InfoPage.css';
import { useLocation } from 'react-router-dom';

const InfoPage = () => {
  const locationRouter = useLocation();
  // ======================================================== //

  return (
    <section css={styles}>
      {/* <Row>
        <Col xs={6}>
          <pre>{JSON.stringify(location, null, 2)}</pre>
        </Col>
        <Col xs={6}>
          <pre>{JSON.stringify(locationRouter, null, 2)}</pre>
        </Col>
      </Row> */}
      <Row>
        {/*
        // TODO: ADD ABILITY for SUBTITLES in ADMIN for <span> CONTENT
        <Col xs={12}>
          <h1>
            APNAES <span>: Sobre nosotros</span>
          </h1>
        </Col> */}
        <Col xs={6}>
          <p className="text-main">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
            nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
            esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt
            in culpa qui officia deserunt mollit anim id est laborum.
          </p>
        </Col>
        <Col xs={6}>
          <p className="text-main">
            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
            mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="col-list">
          <p className="text-main">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
            nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
            esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt
            in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
            minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
            mollit anim id est laborum.
          </p>
        </Col>
      </Row>
    </section>
  );
};

export default InfoPage;
