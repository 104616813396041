import type { Dispatch, ReactNode } from 'react';
import { useReducer } from 'react';
import type { DispatchContextType, GlobalStore } from './GlobalContext.types';
import type { GlobalStoreValues } from './GlobalSchema.types';
import { GlobalContext, DispatchContext } from './GlobalContext';
import { initialState } from './store.constants';

export const GlobalProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(
    (prevState: GlobalStoreValues, newValue: Partial<GlobalStoreValues>): GlobalStoreValues => ({
      ...prevState,
      ...newValue,
    }),
    initialState,
  );

  const initialValue: GlobalStore = {
    ...state,
    dispatch,
  } as GlobalStore & { dispatch?: Dispatch<Partial<GlobalStore>> };

  return (
    <GlobalContext.Provider value={initialValue}>
      <DispatchContext.Provider value={dispatch as DispatchContextType}>{children}</DispatchContext.Provider>
    </GlobalContext.Provider>
  );
};
