import { Container, Row, Col } from 'react-grid-system';
import { useState, ReactNode } from 'react';
import type { ReactElement } from 'react';
import { TabDefinition } from 'components/TabbedContent';
import { Tab } from './Tab';
import { styles } from './TabbedContent.css';

export const TabbedContent = ({
  tabs,
  activeTab,
}: {
  tabs: TabDefinition[];
  activeTab: TabDefinition;
}): ReactElement => {
  const [currentTabID, setCurrentTabID] = useState<string>(activeTab?.id || `${location.pathname}`);
  const minHeight = '450px';

  return (
    <div css={styles} className="tabbed-container" style={{ minHeight }}>
      <Container>
        <Row>
          <Col xs={12} md={12} className="tabbed-nav">
            {tabs.map((tab) => (
              <Tab
                tab={tab}
                setCurrentTabID={setCurrentTabID}
                key={tab?.id}
                selected={tab?.id === currentTabID}
              />
            ))}
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} className={`tabbed-content ${currentTabID}`}>
            {tabs.flatMap((tab: any): ReactNode => {
              const TabContent = tab.element;
              return tab.id === currentTabID ? (
                <TabContent key={tab?.id} setCurrentTabID={setCurrentTabID} {...{ tab }} />
              ) : null;
            })}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
