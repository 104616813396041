import { css } from '@emotion/react';
import { colors, layout, forms } from 'styles';

const disabledBorderColor = colors.greyXLight;

export const styles = css`
  display: flex;

  input[type='submit'] {
    width: 60px;
  }

  label {
    font-weight: 700;
    color: white;
    background-color: ${colors.primaryLight};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1em;
    border: ${layout.borderWidth} solid ${colors.primary};
    border-top-left-radius: ${layout.borderRadius};
    border-bottom-left-radius: ${layout.borderRadius};
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin: 0;
    span {
      color: ${colors.primary};
    }
  }

  input {
    outline: 0;
    padding: 0 1em;
    font-weight: 600;
    border: ${layout.borderWidth} solid ${colors.greyLight};
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: ${layout.borderRadius};
    border-bottom-right-radius: ${layout.borderRadius};
    border-left: 0 !important;
    background-color: white;
    color: ${colors.text};
    &.no-results {
      color: ${colors.warning};
    }
    &:focus,
    &:active {
      border-color: ${colors.primaryLight};
    }
  }

  input[name='searchQuery'][value=''] + button {
    display: none !important;
  }

  label + input {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.is-disabled {
    pointer-events: none;
    background: ${colors.greyXLight};
    opacity: 0.75;
    label {
      background-color: ${colors.greyLight};
      border-color: ${colors.greyLight};
    }
  }

  button.button-clear {
    position: absolute;
    right: 0;
    width: ${forms.inputs.height};
    height: ${forms.inputs.height};
    transform: translate(-${layout.padding}, 0);

    &:hover {
      color: ${colors.warning};
    }
    &[data-is-active='true'] {
      color: ${colors.warningLight};
      &:hover {
        color: ${colors.danger};
      }
    }
    &[data-is-active='false'] {
      pointer-events: none;
      color: ${colors.greyXLight};
    }
  }
`;
