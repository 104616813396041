import type { UseFormReturn, SubmitHandler, FieldValues, FieldErrors } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';

import { useNavigateState } from 'hooks/useNavigateState';
import { useQueryClient } from '@tanstack/react-query';
import { FormDataEncoder } from 'form-data-encoder';
import { FormData, Blob } from 'formdata-node'; // Types for Typescript
import isEqual from 'lodash/isEqual';
import { IParams, ISchema, FieldConfig, type FormConfig } from 'types';
import { ACTIONS } from 'types/actions.types';
import { useLayout } from 'pages/Layout/context/LayoutContext';
import { useRouter } from 'routes/hooks';
import { useUpdate, useCreateNew, getEndpoint } from 'api';
import { useGlobal } from 'store/useGlobalContext';
import { initDetailData } from 'config/data.utils/initDetailData';
import { REGEX_FIRST_SLASH } from 'constants/regex';
import { useGetContext as useFormConfigContext } from './context/FormConfigContext';
import { optionsSetQuiet, optionsSetLoud, submitBlacklist, optionsFormReset } from './config';
import { handleSubmittedFile } from './utils/form.utils.submit';
// UTC TIME: FORM => API+DB ================================================= //

// REF: https://github.com/marnusw/date-fns-tz
// GET BROWSER TZ: Intl.DateTimeFormat().resolvedOptions().timeZone // "Europe/Madrid"
// GET TZ OFFSET: new Date().getTimezoneOffset() // RETURNS DIFF IN MINUTES
// GET BROWSER LANGUAGE: navigator.language + navigator.languages // "en-US" + ["en-US","es-MX"]

// const date = getDatePickerValue(); // e.g. 2014-06-25 10:00:00 (picked in any time zone)
// const timeZone = getTimeZoneValue(); // e.g. America/Los_Angeles
// const utcDate = zonedTimeToUtc(date, timeZone); // In June 10am in Los Angeles is 5pm UTC
// postToServer(utcDate.toISOString(), timeZone); // post 2014-06-25T17:00:00.000Z, America/Los_Angeles

// ======================================================================== //

const isFileInput = (field: unknown) => {
  if (typeof field === 'object' && field[Symbol.toStringTag] === 'File') {
    return true;
  }
  return false;
};

// ====================================================================== //

type UseFormSubmit = {
  // onSubmit?: SubmitHandler<FieldValues>; // TODO: USE THIS INSTEAD OF `any` (??)
  onSubmit: any;
  endpointOverride?: string;
  formMethods: UseFormReturn;
  resetFields: (data?: unknown) => Promise<void>;
};

export const useFormSubmit = ({
  onSubmit, // optional override
  endpointOverride = '',
  formMethods,
  resetFields,
  formConfig,
  fields,
  schema,
}: UseFormSubmit & { formConfig: FormConfig; fields: FieldConfig[]; schema: any }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  // const { navigate } = useNavigateState();
  const { notify } = useLayout();
  // const { formConfig, fields, schema } = useFormConfigContext();
  const { params, action, basePath, fromLocation, PATH_BASE } = useRouter();

  const endpoint =
    formConfig.api.endpoint ??
    ((endpointOverride ? { endpoint: endpointOverride } : getEndpoint({ params })).endpoint as string);
  // const isNewEntry = !!(formConfig?.isNewEntry!== false && params?.new);
  const isNewEntry = !!(formConfig?.isNewEntry !== false && params?.new);
  const { setIsNavigationBlocked } = useGlobal('isNavigationBlocked');

  // ====================================================================== //
  // REDUCED VERSION: (HANDLERS BELOW)

  const update = useUpdate({
    endpoint,
    onSuccess: (res) => {
      // queryClient.invalidateQueries({ queryKey: ['getDetail'] });
      setIsNavigationBlocked(false);
    },
  });

  const saveNew = useCreateNew({
    endpoint,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getDetail', { endpoint }] });
      setIsNavigationBlocked(false);
    },
  });

  // ====================================================================== //
  // MAIN SUBMIT - USED BY DEFAULT IF NO OVERRIDE PASSED AS PROP TO FORM

  const onFormSubmit = async (submitData: FieldValues, args = {}): Promise<any> => {
    // console.clear();
    // 1. INIT..
    const formData = new FormData();
    const formDataJSON: { [key: string]: any } = {};
    const submitFields = Object.entries(submitData).filter(([field]) => !submitBlacklist.includes(field));

    log('SUBMIT_DATA_FORMDATA', 'lime', submitData);
    log('ARGS', 'magenta', args);
    log('=========== submitFields =============', 'blue', submitFields);

    // 2. ITERATE OVER FIELDS, ADD TO FORM_DATA OBJECT
    for await (const [field, value] of submitFields) {
      if (field === 'json_meta') continue;
      if (submitBlacklist.includes(field)) continue;

      const isSchemaField = !!Object.keys(schema).includes(field);

      const fieldConfig = fields.find((config: FieldConfig) => config.name === field) as FieldConfig;
      const originalValue = fieldConfig?.value || fieldConfig?.defaultValue;
      const inputType = (fields as FieldConfig[])?.find((config) => config.name === field)?.inputType;

      if (!value && !originalValue) continue;
      // if (isEqual(value, originalValue)) continue; // TODO: PUT BACK ??

      if (typeof value === 'object') {
        if (isFileInput(value)) {
          // FILE SUBMITTED, DO UPLOAD !!
          const responseSubmittedFile = await handleSubmittedFile({ field, value });
          const id = responseSubmittedFile?.id;
          const filename = responseSubmittedFile?.filename;
          if (id && filename) {
            formData.set(field, filename);
            formData.set(`${field}_id`, id);
          }
          continue;
        }
        // OBJECT FIELD: json | object | array VALUE
        if ((inputType as string).startsWith('repeater') && !!value?.length) {
          const valuesTrimmed = value.filter((json: { name: string }) => json.name !== '');
          const valuesCleaned = valuesTrimmed.filter((repeaterItem: any) => {
            // TODO: REMOVED TO KEEP UUID
            // const { uuid, ...repeaterData } = repeaterItem;
            // return { ...repeaterData };

            return repeaterItem;
          });
          // TODO: REMOVED TO AVOID EXTRA SLASHES
          // formDataJSON[field] = JSON.stringify(valuesCleaned);
          formDataJSON[field] = valuesCleaned;
        } else {
          formDataJSON[field] = value;
        }
      } else {
        // STANDARD FIELD: string | numeric VALUE
        formDataJSON[field] = value;
      }

      if (isSchemaField) {
        formData.append(field, formDataJSON[field]);
      }
    }

    // 3. SET NEW MAIN `JSON_META` FIELD..
    formData.set('json_meta', JSON.stringify(formDataJSON));
    const plainFormData = Object.fromEntries(formData.entries());
    const body = JSON.stringify(plainFormData);

    const response = isNewEntry
      ? await saveNew.mutateAsync({ body })
      : await update.mutateAsync({ id: params.id ?? formDataJSON.id, body });

    if (response) {
      setIsNavigationBlocked(false);
      return response;
    }
  };

  // ====================================================================== //
  // SUBMIT HANDLERS..

  // NOTE: for quickly creating a new entry when one needs to first exist to pre-populate form
  const handleHasSubmittedNew = (isSubmitSuccessful = false) => {
    if (!isSubmitSuccessful) return;
    const { getValues, unregister } = formMethods;
    const { ID_NEW, ...submitValues } = { ID_NEW: undefined, ...getValues() };
    if ('ID_NEW' in submitValues) {
      delete submitValues.ID_NEW;
      unregister('ID_NEW', { keepValue: false, keepDefaultValue: false });
    }
  };

  const handleSubmitForm = async (submittedData: FieldValues) => {
    setIsNavigationBlocked(false);
    // NOTE: `submitForm` action - sourced from `formConfig` or default (here)
    const submitFormAction = onSubmit ?? formConfig?.onSubmit ?? onFormSubmit;
    const args = endpoint ? { endpoint } : {};
    const response = await submitFormAction(submittedData, args);

    if (response) {
      response.ID_NEW && formMethods.setValue('ID_NEW', response.ID_NEW, optionsSetQuiet);
      const { status } = response;
      if (status === 200) notify({ success: { message: 'Item saved!' } });
      if (status === 201) notify({ success: { message: 'New item added!' } });
      const dataExpaned = initDetailData({ data: response.data, isExpandedSchema: true });
      await resetFields({ data: dataExpaned });
      // TODO: REPLACE BELOW WITH `onSuccess` HANDLER !!
      // const path = `${fromLocation?.path || basePath}`
      //   .replace('undefined', '')
      //   .replace('//', '/')
      //   .replace(REGEX_FIRST_SLASH, '');
      // await navigate(`${PATH_BASE}/${path}`);
      return response;
    } else {
      if ((formConfig as FormConfig).isNavPromptActive !== false) {
        setIsNavigationBlocked(true);
        // isDirty && !isNavigationBlocked && setIsNavigationBlocked(isDirty);
      }
    }
  };

  // SUBMIT HANDLING ======================================================== //

  const handleSubmitErrors = (errorsOnSubmit: FieldErrors) => {
    log('SUBMIT & ERRORS (submit handler)', 'red', errorsOnSubmit);
  };

  const handleSubmit =
    action !== ACTIONS.VIEW ? formMethods.handleSubmit(handleSubmitForm, handleSubmitErrors) : (null as any);

  return { handleSubmit, handleSubmitForm, handleHasSubmittedNew, handleSubmitErrors };
};
