import { useMemo, useState } from 'react';
import type { ReactNode } from 'react';
import { useAuthClient } from 'auth/client/useAuthClient';
import { useGetAuthentication } from 'auth/client/useGetAuthentication';
import { parseResponseError } from 'auth/utils/auth.utils';
import type { UserLoginData } from 'pages/SiteLockPage';
import { Spinner } from 'components/Spinner';
import { AuthContext } from './AuthContext';
import type { AuthCredentials } from './types/auth.types';
import type { AuthContextValues } from './AuthContext.types';
import type { AuthUser } from './types/user.types';
import { USER_ROLE } from './types/user.types';

// REGISTER NEW USER:
// NOTE: CONSIDER USING Client EVENTS !!
// ie: userService.on('created', onCreatedUser) -- do something..
// ref: https://feathersjs.com/api/events.html

// NOTE: ** Anonymous authentication - custom strategy **
// https://feathersjs.com/cookbook/authentication/anonymous.html

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { client, authenticateClient, isAuthenticated } = useGetAuthentication({ type: 'socketio' });

  if (!client) {
    return <Spinner page color="warning" />;
  }

  const { resetAuthentication, initAuthentication, authenticatedUser } = useAuthClient({
    client,
    authenticateClient,
    isAuthenticated,
  });

  // ======================================================================== //

  const [isAuthorizing, setIsAuthorizing] = useState<boolean>(false);
  const [resError, setResError] = useState<unknown>();

  // MOVE SITE LOCKER HERE ????
  const handleSubmit = (formData: UserLoginData) => {
    log('SUBMIT', 'red', formData);
    const UNLOCK_SITE_PASS = 'Pass123';
    if (formData.password === UNLOCK_SITE_PASS) {
      // storeNewGuestSession();
      // setIsAuthenticated(false);
    }
  };

  const registerNewUser = async (
    { ...formData }: AuthCredentials & AuthUser,
    onSuccess: any,
    onError: any,
  ) => {
    log('registering new user ...', 'lime', formData);
    setIsAuthorizing(true);
    try {
      // create new user entry
      const userService = client.service('users');
      const response = await userService.create({ ...formData });

      // authorize new user
      const userCredentials: AuthCredentials = { email: formData.email, password: formData.password };
      await client.authenticate({ strategy: 'local', ...userCredentials });
      await initAuthentication({ client });

      setResError(null);
      onSuccess?.(response);
    } catch (error: unknown) {
      error instanceof Error ? console.error(error.message) : console.error(String(error));
      const { className, code, message, name } = parseResponseError(error);
      const statusCode =
        code === 500 && message.includes('SQLITE_CONSTRAINT: UNIQUE constraint failed: users.email')
          ? 422
          : 500;
      setResError({ className, code: statusCode, message, name });
      onError && onError();
    } finally {
      setIsAuthorizing(false);
    }
  };

  const login = async ({ email, password }: AuthCredentials, onSuccess: any, onError: any) => {
    // TODO: FIX ME !!
    setIsAuthorizing(true);
    try {
      const userCredentials: AuthCredentials = { email, password };
      const res = await client.authenticate({ strategy: 'local', ...userCredentials });
      await initAuthentication({ client });
      // TODO: FIX ME !!
      // setResError(null);
      onSuccess?.(res);
    } catch (error: unknown) {
      error instanceof Error ? console.error(error.message) : console.error(String(error));
      const { className, code, message, name } = parseResponseError(error);
      setResError({ className, code, message, name });
      onError?.();
    } finally {
      setIsAuthorizing(false);
    }
  };

  const logout = async (onLogoutCallback?: any, onCallback?: any) => {
    log('LOGGING OUT...', 'grey');
    await resetAuthentication({ client });
    if (onLogoutCallback) onLogoutCallback();
  };

  const isAdminUser = !!(authenticatedUser?.user_role === 'ADMIN');

  const contextValue: AuthContextValues = useMemo(
    () => ({
      user: authenticatedUser,
      isAuthenticated, // TODO: REMOVE (INTERNAL ONLY, IF NOT DEV)
      isAdminUser,
      handleSubmit,
      registerNewUser,
      login,
      logout,
      isAuthorizing,
      error: resError,
      setError: (err: unknown) => ({}),
      setIsAuthorizing: (err: unknown) => ({}),
    }),
    [isAuthenticated, authenticatedUser, location.pathname, resError],
  );

  if (!isAuthenticated) {
    return <Spinner page color="warning" />;
  }

  return <AuthContext.Provider value={{ ...contextValue, handleSubmit }}>{children}</AuthContext.Provider>;
};
