import { css } from '@emotion/react';
import { colors, layout, cssInputBox, forms } from 'styles';

export const styles = css`
  position: absolute;
  left: ${layout.padding};
  right: ${layout.padding};

  input {
    font-family: monospace;
    font-weight: 600;
    letter-spacing: -0.005em;
    padding-left: calc(${layout.padding} * 4) !important;
    font-size: 1em;
  }

  &.error {
    input,
    .input-icon {
      border-color: ${colors.warningLight};
    }
  }

  &.loading {
    input,
    .input-icon {
      border-color: ${colors.primaryLight};
    }
  }

  &.error {
    input,
    .img-preview {
      border-color: ${colors.warning};
    }
  }

  .img-loader {
    display: none;
  }

  .icon {
    color: ${colors.greyLight};
  }

  .input-icon {
    /****** INPUT ICON RIGHT *****/

    ${cssInputBox}
    border: 0;
    position: absolute;
    top: 2px;
    bottom: 2px;
    min-height: unset;
    width: calc(${forms.inputs.height} - (${layout.borderWidth} * 2));
    height: calc(${forms.inputs.height} - (${layout.borderWidth} * 2));
    border-radius: calc(${layout.borderRadius} * 0.5);
    z-index: 10;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
      pointer-events: none;
      width: calc(${forms.inputs.height} * 0.45) !important;
      height: calc(${forms.inputs.height} * 0.45) !important;
    }

    background-color: white;

    /****** INPUT COLORS *****/
    &.info {
      background-color: ${colors.infoXXLight};
      svg {
        color: ${colors.warning};
      }

      background-color: ${colors.dangerXLight};
      svg {
        color: ${colors.danger};
      }
    }

    &.error,
    &.danger {
      background-color: ${colors.warningXXLight};
      svg {
        color: ${colors.warning};
      }
      &:hover {
        background-color: ${colors.dangerXLight};
        svg {
          color: ${colors.danger};
        }
      }
    }
  }

  &,
  & * {
    transition: all 0.25s ease;
  }

  .input-icon-left {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    left: calc(${layout.padding} + ${layout.borderWidth});
  }

  .input-icon-right {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    right: calc(${layout.padding} + ${layout.borderWidth});
  }

  /**************************************************************/
  /**************************************************************/

  .error {
    .overlay {
      /* background-color: ${colors.warning}; */
      background-color: rgb(240, 153, 13, 0.25);
    }
  }

  .loading {
    .overlay {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  .img-preview-wrapper {
    position: relative;
    /* width: 100%; */
    padding-top: 36%;
    box-shadow: inset 0 0 0 8px white !important;
  }
  .spinner {
    z-index: 20;
    opacity: 0.66 !important;
  }

  .img-preview {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-position: 50%;
    background-size: cover;
    padding: calc(${layout.padding} * 2) !important;
  }

  &:not(.inline) {
    .img-preview-wrapper {
      position: absolute;
      width: auto;
      right: calc(${layout.padding} * 1);
      left: calc(${layout.padding} * 1);
    }
  }

  .overlay-wrapper {
    position: absolute;
    top: ${layout.padding};
    bottom: ${layout.padding};
    left: ${layout.padding};
    right: ${layout.padding};
    z-index: 10;

    background-size: cover;
    background-position: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &:hover {
      cursor: pointer !important;
    }

    .overlay {
      width: 100%;
      /* height: 100%; */
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        cursor: pointer !important;
      }
    }

    .btn-upload-outter {
      background-color: transparent;
      &:hover {
        cursor: pointer !important;
      }
    }

    .btn-add-file {
      &:hover {
        cursor: pointer !important;
      }
    }

    &.success {
      .btn-upload-outter {
        opacity: 0;
      }
      &:hover {
        .btn-upload-outter {
          opacity: 1;
        }
      }
    }

    &.loading {
      .btn-upload-outter {
        opacity: 0;
      }
    }

    &.error {
      .btn-upload-outter {
        opacity: 1;
      }
      svg.icon-error {
        transform: translateY(72px);
      }
    }
  }

  .icon.icon-error {
    color: ${colors.warningDark};
    display: inline-block;
    margin-top: 2%;
    width: 25%;
    height: 25%;
    opacity: 0.5;
    z-index: 10;
  }

  &.error {
    input,
    .img-preview {
      border-color: ${colors.warning};
    }
  }

  .img-loader {
    display: none;
  }
`;
