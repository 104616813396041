import { useMutation } from '@tanstack/react-query';
import type { UseQueryOptions, UseMutationResult } from '@tanstack/react-query';
import { api, headersDefault as headers, getQueryString } from 'api';
import { sleep } from 'utils';
import type { QueryOptions, UseQueryOptions__DEPRECATED, QueryParams } from './query';

interface UseUpdateProps extends Partial<UseQueryOptions>, UseQueryOptions__DEPRECATED, QueryOptions {
  mutationKey?: Array<unknown>;
  endpoint: string;
}
export type UseUpdateReturn = UseMutationResult<Readonly<any>, unknown>;

export const useUpdate = ({ mutationKey, endpoint, onSuccess, onError }: UseUpdateProps): UseUpdateReturn => {
  mutationKey ??= ['updateEntry', { endpoint }];

  return useMutation({
    mutationKey,
    mutationFn: async ({ id, body }: { id: string; body: string }) => {
      const response = await api.patch(`${endpoint}`, { id, body });
      await sleep(100);

      log('[PATCH]', 'yellow', { status: response.status, statusText: response.statusText }, response.data);
      log('--------------------------------------------------------------', 'grey');

      return response;
    },
    onSuccess,
    onError: (error: unknown) => {
      error instanceof Error ? console.error(error.message) : console.error(String(error));
    },
  });
};
