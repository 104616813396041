import { useContext } from 'react';
import type { DispatchContextType, GlobalStoreDispatch, GlobalStore } from './GlobalContext.types';
import { GlobalContext, DispatchContext } from './GlobalContext';
import { initialState, RESET_STATE_PATHS } from './store.constants';

// TODO: use which type ?? - should be `DispatchContextType`, no ??

// export const useStore = (): GlobalStore & { dispatch: DispatchContextType } => {
export const useStore = (): GlobalStore & { dispatch: GlobalStoreDispatch } => {
  const store = {
    ...useContext(GlobalContext),
    dispatch: useContext(DispatchContext) as GlobalStoreDispatch,
  };

  if (RESET_STATE_PATHS.includes(location.pathname)) {
    Object.assign(store, { ...initialState });
  }

  return store;
};
