import type { TableConfig } from 'types';
import { FORMAT } from 'types/form.types';
import { LinkToDetail, LinkToCategory } from 'components/DataTable/components';
import { ListItemCard } from 'components/ListItemCard';
import { COL_TYPE, ROW_ACTION } from 'components/DataTable/DataTable.types';
import { DATATYPE, ENDPOINT } from './periodicos.config';

// ----------------------------------------------------------------- //
// TABLE CONFIG:
export const tableConfig: TableConfig = {
  dataType: {
    key: DATATYPE,
    role: 'table',
  },
  api: {
    endpoint: ENDPOINT,
  },
  list: {
    colWidth: {
      xs: 12,
      md: 6,
    },
    listItem: {
      component: ListItemCard,
      shapeRatio: 2.2,
    },
    search: {
      filterByKeys: ['name'],
    },
  },
  actions: {
    hasActionView: true,
    hasActionEdit: false,
    hasActionNew: false,
  },
  columns: [
    {
      label: 'NAME-PERIODICOS',
      name: 'name',
      columnType: COL_TYPE.DATA,
      isSortable: true,
      render: ({ data }) => <LinkToDetail targetID={data.id} label={data.name} />,
    },
    {
      label: 'CATEGORÍA',
      name: 'cat_id',
      columnType: COL_TYPE.DATA,
      // width: 2,
      width: false,
      isSortable: true,
      valueFormat: {
        type: FORMAT.BY_ID, // TODO: NO TS TYPE WARN ???????
        source: 'cat_id',
      },
      render: ({ data }) => <LinkToCategory catId={data.cat_id} />,
    },
    {
      label: 'PROVINCE',
      name: 'province',
      columnType: COL_TYPE.DATA,
      isSortable: true,
      render: ({ data }) => <LinkToDetail targetID={data.id} label={data.province} />,
    },
    {
      label: 'ID',
      name: 'id',
      columnType: COL_TYPE.DATA,
      // width: 2,
      widths: {
        xs: 0,
        lg: 1,
      },
      isSortable: true,
      render: ({ data }) => data.id,
    },
  ],
};
