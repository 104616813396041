import type { ReactNode } from 'react';
import type { TableConfig } from 'types';
import { METADATA } from 'types';
import type { FormConfig } from 'types/form.types';
import { INPUTS } from 'types/form.types';
import type { ColumnSizes } from 'types/viewport.types';
import { UserAuthSession } from 'auth/storage/user.storage';
import { LinkToDetail } from 'components/DataTable/components/LinkToDetail';
import type { DetailConfig, DetailSection } from 'components/DetailSummary';
// import { EventCard } from 'components/EventCard';
import type { FormHeroConfig, FieldConfig, FormButton } from 'components/Form';
import { BUTTONS, types } from 'components/Form/components/FormButtonBar/FormButtonBar.types';
import type { SelectOption } from 'components/FormUI/Select';
import { mapEntries } from 'components/FormUI/Select/select.utils';
import { getOptionsFromAPI } from 'utils/getOptionsFromAPI';
import { isSet, omitNull } from 'utils/utils.object';
import { ASC, DESC } from 'constants/sorting';
import { SPAIN_PROVINCES } from 'constants/spain';
import { DATATYPE, ENDPOINT } from './eventos.config';

let i = 0;

const buttons: FormButton[] = [
  {
    type: types.button,
    label: 'CANCEL',
    action: BUTTONS.CANCEL,
  },
  {
    type: types.submit,
    label: 'SAVE',
    action: BUTTONS.SUBMIT,
  },
];

const hero: FormHeroConfig = {
  heroTitleKey: ['name'],
};

const getIsValid = (val: any) => {
  log('GET_IS_VALID', 'magenta', val);
  // return isSet(val) ? val : false;
  return val;
};

// NOTE: FOLLOWING CONFIG WILL BE MERGED WITH form.config.ts/globalFieldProps
// TO POPULATE COMMON PROPS, OPTIONS etc
export const formConfig: FormConfig = {
  dataType: { key: DATATYPE, role: 'form' },
  api: { endpoint: ENDPOINT },
  isNavPromptActive: true,
  hero,
  buttons,
  sections: [
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Datos del Evento',
      fields: [
        {
          name: 'name',
          label: 'nombre del evento',
          inputType: INPUTS.text,
          required: true,
          order: 1,
        },
        {
          name: 'image',
          label: 'imagen publicitaria / flyer',
          placeholder: 'Escoge archivo...',
          hint: 'choose image to upload',
          inputType: INPUTS.image,
          order: 2,
        },
        {
          name: 'event_type',
          label: 'tipo de evento',
          inputType: INPUTS.select,
          options: [],
          optionsAsync: {
            meta_key: METADATA.event_type,
          },
          order: 3,
        },
        {
          name: 'user_id',
          label: 'Entidad Organizadora',
          hint: 'Quién es que organiza el evento',
          inputType: INPUTS.text,
        },
      ],
    },
    // ---------------------------------------------------------------------- //
    // {
    //   sectionTitle: 'Colaboradores V1',
    //   // description: 'visibles a todo el mundo en la web pública',
    //   fields: [
    //     {
    //       name: 'colaborators',
    //       label: 'Nombre del Colaborador',
    //       hint: 'puede añadir varios',
    //       inputType: INPUTS.tags,
    //       options: SPAIN_PROVINCES,
    //       required: false,
    //       // repeaterConfig: {
    //       //   inputType: INPUTS.text,
    //       // },
    //       // tagsConfig: {
    //       //   label: 'XXX',
    //       // },
    //       colWidth: {
    //         xs: 12,
    //         lg: 6,
    //       },
    //     },
    //   ],
    // },
    // ---------------------------------------------------------------------- //
    /*
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'FORM- TESTING - Colaboradores V2',
      // description: 'visibles a todo el mundo en la web pública',
      fields: [
        {
          name: 'colaborators',
          label: 'Nombre del Colaborador',
          hint: 'puede añadir varios',
          inputType: INPUTS.tags,
          options: SPAIN_PROVINCES,
          required: false,
          // repeaterConfig: {
          //   inputType: INPUTS.text,
          // },
          // tagsConfig: {
          //   label: 'XXX',
          // },
          colWidth: {
            xs: 12,
            lg: 6,
          },
        },
        {
          name: 'colaborator_TEST',
          label: 'colaborators TEST',
          inputType: INPUTS.select,
          options: [],
          optionsAsync: {
            // meta_key: METADATA.event_type,
            endpoint: 'entidades',
          },
          order: 3,
        },
      ],
    },
    */
    // ---------------------------------------------------------------------- //
    /*
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'FORM TESTING - MISC',
      // description: 'visibles a todo el mundo en la web pública',
      fields: [
        {
          name: 'cat_id',
          label: 'Tipo de Contacto',
          inputType: INPUTS.selectCategory,
          // inputType: INPUTS.select,
          // options: [],
          colWidth: {
            xs: 12,
            lg: 6,
          },
        },

        {
          name: 'testing_2',
          label: 'TESTING v2',
          inputType: INPUTS.select,
          options: [],
          //
          optionsAsync: {
            endpoint: 'posts',
            // sort: -1
            // filter: { $sort: { date: DESC } },
            // meta_key: METADATA.event_type,
          },
          isControlledBy: {
            criteria: { cat_id: 'b36fdec5-4389-4fa1-b985-caa59e599d04' },
            target: { isHidden: false },
          },
          colWidth: {
            xs: 12,
            lg: 6,
          },
        },
      ],
    },
    */
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Fecha del Evento',
      fields: [
        {
          name: 'date',
          label: 'Fecha del evento',
          inputType: INPUTS.date,
          colWidth: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 4,
            xl: 6,
          },
        },
        {
          name: 'time_start',
          label: 'Hora de inicio',
          inputType: INPUTS.time,
          colWidth: {
            xs: 12,
            sm: 12,
            md: 6,
            lg: 4,
            xl: 3,
          },
        },
        {
          name: 'time_end',
          label: 'Hora de terminación',
          inputType: INPUTS.time,
          colWidth: {
            xs: 12,
            sm: 12,
            md: 6,
            lg: 4,
            xl: 3,
          },
        },
      ],
    },
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Ubicación del Evento',
      fields: [
        {
          name: 'location',
          label: 'Ubicación del evento',
          inputType: INPUTS.text,
          order: 0,
          colWidth: {
            xs: 12,
            md: 6,
          },
        },
        {
          name: 'city',
          label: 'Localidad',
          inputType: INPUTS.text,
          order: 1,
          colWidth: {
            xs: 12,
            md: 6,
          },
        },

        {
          name: 'country',
          label: 'País',
          inputType: INPUTS.selectCountry,
          order: 6,
        },
        {
          name: 'province',
          label: 'Província',
          hint: 'Provincia de España',
          inputType: INPUTS.select,
          options: SPAIN_PROVINCES,
          required: false,
          isHidden: false,
          colWidth: {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            xl: 12,
            xxl: 12,
          },
          isControlledBy: {
            criteria: { country: 'Spain' },
            target: { isHidden: false },
          },
          order: 11,
        },
      ],
    },
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Descripción del Evento',
      // description: 'Realice una descripción de la Entidad o Asociación que será visible para los usuarios',
      isHiddenSeparator: false,
      fields: [
        {
          name: 'url',
          label: 'URL de video del evento',
          inputType: INPUTS.url,
          required: false,
          order: 0,
          colWidth: { xs: 12 },
        },
        {
          name: 'description',
          label: 'Descripción del evento',
          inputType: INPUTS.textarea,
          required: false,
          colWidth: {
            xs: 12,
            lg: 6,
          },
        },
        {
          name: 'activities',
          label: 'Más detalles',
          inputType: INPUTS.textarea,
          required: false,
          colWidth: {
            xs: 12,
            lg: 6,
          },
        },
      ],
    },
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Datos de Contacto',
      // description: 'visibles a todo el mundo en la web pública',
      fields: [
        {
          name: 'email_public',
          label: 'email público',
          hint: 'puede añadir varios',
          inputType: INPUTS.repeater,
          required: false,
          repeaterConfig: {
            inputType: INPUTS.email,
          },
          colWidth: {
            xs: 12,
            lg: 6,
          },
        },
        {
          name: 'tel_public',
          label: 'teléfono público',
          hint: 'puede añadir varios',
          inputType: INPUTS.repeater,
          required: false,
          repeaterConfig: {
            inputType: INPUTS.tel,
          },
          colWidth: {
            xs: 12,
            lg: 6,
          },
        },
      ],
    },

    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Colaboradores',
      // description: 'visibles a todo el mundo en la web pública',
      fields: [
        {
          name: 'colaborators',
          label: 'Añadir e invitar colaboradores',
          inputType: INPUTS.invited,
          required: false,
          colWidth: { xs: 12 },
        },
      ],
    },
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Patrocinadores',
      // description: 'visibles a todo el mundo en la web pública',
      fields: [
        {
          name: 'sponsors',
          label: 'Nombre del Patrocinador',
          hint: 'puede añadir varios',
          inputType: INPUTS.tags,
          // options: SPAIN_PROVINCES,
          // required: false,
          // maxLength: 3,
          // repeaterConfig: {
          //   inputType: INPUTS.text,
          //   label: 'Sponsors',
          // },
          tagsConfig: {
            // inputType: INPUTS.text,
            label: 'Patrocinador',
          },
          colWidth: {
            xs: 12,
            lg: 12,
          },
        },
      ],
    },
  ],
  // ----------------------------------------------------------------- //
};
