import type {
  UseFormProps,
  KeepStateOptions,
  FormState,
  FieldValues,
  DeepPartial,
  Resolver,
  Mode,
  FieldErrors,
} from 'react-hook-form';

// ALL LATEST v7 DEFAULTS - OFFICIAL DOCS (09-2021)
// https://react-hook-form.com/ts#Mode
// TYPES: import { UseFormProps, RegisterOptions, FormStateProxy, FieldErrors } from 'react-hook-form';

// export declare type UnpackNestedValue<T> = T extends NestedValue<infer U>
//   ? U
//   : T extends Date | FileList | File
//   ? T
//   : T extends object
//   ? {
//       [K in keyof T]: UnpackNestedValue<T[K]>;
//     }
//   : T;

// ========================================================================== //

export type Mode_REF = {
  onBlur: 'onBlur';
  onChange: 'onChange';
  onSubmit: 'onSubmit';
  onTouched: 'onTouched';
  all: 'all';
};
export type UseFormProps_REF<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object,
> = Partial<{
  mode: Mode;
  reValidateMode: Mode;
  defaultValues: DeepPartial<TFieldValues>;
  resolver: Resolver<TFieldValues, TContext>;
  context: TContext;
  shouldFocusError: boolean;
  shouldUnregister: boolean;
  criteriaMode: 'firstError' | 'all';
}>;

// export type UseFormProps_REF_NEW<TFieldValues extends FieldValues = FieldValues, TContext = any> = Partial<{
//   mode: Mode;
//   reValidateMode: Exclude<Mode, 'onTouched' | 'all'>;
//   defaultValues: DefaultValues<TFieldValues> | AsyncDefaultValues<TFieldValues>;
//   values: TFieldValues;
//   resetOptions: Parameters<UseFormReset<TFieldValues>>[1];
//   resolver: Resolver<TFieldValues, TContext>;
//   context: TContext;
//   shouldFocusError: boolean;
//   shouldUnregister: boolean;
//   shouldUseNativeValidation: boolean;
//   progressive: boolean;
//   criteriaMode: CriteriaMode;
//   delayError: number;
// }>;

// https://react-hook-form.com/api/useform
export const defaultOptionsRHF: UseFormProps = {
  mode: 'onSubmit',
  reValidateMode: 'onChange',
  defaultValues: {},
  resolver: undefined,
  context: undefined,
  shouldFocusError: true,
  shouldUnregister: false,
  criteriaMode: 'firstError',
  // EXTRAS
  shouldUseNativeValidation: false,
  delayError: undefined,
};

// CUSTOM OVERRIDES
// TODO: NOTE - PERHAPS BEST UX:
// 1. initial form state - mode:'onBlur'
// 2. after submitting with errors: mode:'onChange' (for easy FIXING)
const customOptionsRHF: Partial<UseFormProps> = {
  mode: 'onChange', // DEFAULT: 'onChange'
  reValidateMode: 'onChange', // DEFAULT: 'onChange'
  criteriaMode: 'all',
  // resolver: joiResolver(schemaJoi), // TODO: USE YUP !!
};

// CUSTOM OVERRIDES
export const optionsRHF: UseFormProps = {
  ...defaultOptionsRHF,
  ...customOptionsRHF,
};

// ========================================================================== //

// RHF FORM-STATE: destructured from formState + useFormState()
// https://react-hook-form.com/api/useform/formstate
// https://react-hook-form.com/api/useformstate

export const initFormStateRHF: FormState<FieldValues> = {
  isDirty: false,
  isLoading: false,
  disabled: false,
  dirtyFields: {},
  touchedFields: {},
  errors: {},
  validatingFields: {},
  isValid: false,
  isValidating: false,
  isSubmitted: false,
  isSubmitSuccessful: false,
  isSubmitting: false,
  submitCount: 0,
};

// ========================================================================== //

export const optionsFormReset: KeepStateOptions = {
  keepErrors: false,
  keepDirty: false,
  keepTouched: false,
  keepIsValid: false,
  keepDirtyValues: false,
  keepValues: false,
  keepDefaultValues: false,
  keepIsValidating: false,
  keepIsSubmitted: true,
  keepSubmitCount: true,
  keepIsSubmitSuccessful: true,
};

// ========================================================================== //
// OPTIONS FOR `setValue`..

export const optionsSetQuiet = {
  shouldValidate: false,
  shouldDirty: false,
  shouldTouch: false,
};

export const optionsSetLoud = {
  shouldValidate: true,
  shouldDirty: true,
  shouldTouch: true,
};

export const optionsAutofill = {
  shouldValidate: false,
  shouldDirty: true,
  shouldTouch: true,
};
