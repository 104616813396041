/* eslint-disable import/order */
import { Col, Row } from 'react-grid-system';
import type { IconType } from 'react-icons';
import { forwardRef } from 'react';
import type { ReactElement, HTMLAttributes, ForwardedRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import type { SizeUI } from 'types';
import { ArrayJSX } from 'utils/ArrayJSX';
import { styles } from './Checkbox.css';
import { getCheckboxStateIcon } from './Checkbox.utils';
import type { FieldErrors, FieldValues, UseFormReturn } from 'react-hook-form';
import { ErrorMessage } from 'components/Form/components/ErrorMessage/ErrorMessage-V1';
import type { FieldConfig } from 'components/Form';
import { FormFieldLabel } from 'components/FormFieldLabel';
import { ModalTrigger } from 'components/ModalTrigger';

type FieldErrorProps =
  | {
      fieldName: string;
      errors: FieldErrors<FieldValues>;
      type: any;
      message: any;
      isErrorVisible: boolean;
      isSubmitted: boolean;
    }
  | {
      fieldName: string;
      isErrorVisible: boolean;
      message?: string;
    };
interface FieldValidation {
  hasWarning?: boolean;
  hasError?: boolean;
  errorProps?: FieldErrorProps;
}

// ======================================================================== //
interface CheckboxProps {
  name: string;
  field?: FieldConfig;
  label?: string;
  isLabelHidden?: boolean;
  defaultValue: boolean;
  value?: boolean;
  onChange: (args?: any) => void;
  size?: SizeUI;
  id?: string;
  isDisabled?: boolean;
  required?: boolean;
  iconOnCustom?: IconType | (ReactElement & HTMLAttributes<HTMLElement>);
  iconOffCustom?: IconType | (ReactElement & HTMLAttributes<HTMLElement>);
  validation?: FieldValidation;
}

export const Checkbox = forwardRef(
  (
    {
      name,
      field,
      label,
      isLabelHidden = false,
      defaultValue = false,
      value,
      onChange,
      size = 'md',
      id,
      isDisabled = false,
      // required = false,
      iconOnCustom,
      iconOffCustom,
      validation: { hasError = false, errorProps } = {},
    }: CheckboxProps,
    ref: ForwardedRef<any>,
  ) => {
    const isChecked = value !== undefined ? value : useWatch({ name, defaultValue });

    // CSS CLASSES
    const cssClasses = new ArrayJSX(`size-${size}`);
    isChecked ? cssClasses.push('is-checked') : cssClasses.remove('is-checked');
    isDisabled ? cssClasses.push('is-disabled') : cssClasses.remove('is-disabled');

    const IconOn = getCheckboxStateIcon({ iconCustom: iconOnCustom, state: 'on' });
    const IconOff = getCheckboxStateIcon({ iconCustom: iconOffCustom, state: 'off' });

    const Label = (): ReactElement =>
      field ? (
        <FormFieldLabel field={field} isLabelInline />
      ) : (
        <label htmlFor={id || `field__${name}`}>
          {label}
          {hasError && errorProps?.isErrorVisible && <ErrorMessage {...errorProps} />}
        </label>
      );

    return (
      <Row css={styles} className={`input-checkbox ${isDisabled ? 'is-disabled' : ''}`}>
        {/* <pre>isCurrentValueTrue: {String(isCurrentValueTrue)}</pre> */}
        {/* <pre>isCurrentValueTrue: {JSON.stringify(isChecked, null, 2)}</pre> */}
        <Col className="col col-input" onClick={() => !isDisabled && onChange(!isChecked)}>
          <input
            name={name}
            ref={ref}
            id={id || `field__${name}`}
            aria-label={`toggle__${label}`}
            type="checkbox"
            value="true"
            // checked={isChecked} // TODO: can be removed, i believe..
            onChange={() => !isDisabled && onChange(!isChecked)}
            disabled={isDisabled}
          />
          <div className={`toggle-checkbox ${cssClasses.inline()}`}>
            {IconOn}
            {IconOff}
          </div>
        </Col>
        <Col
          className={`col col-label
            ${field?.isLabelInline !== false ? 'is-inline' : ''}
            ${isDisabled ? 'is-disabled' : ''}
          `}
        >
          {!isLabelHidden && field?.isLabelInline !== false && <Label />}
        </Col>
      </Row>
    );
  },
);
