import { Form } from 'components/Form';
import { v4 as uuid } from 'uuid';
import type { DataConfigurationType, IGeneric, ISchema } from 'types';
import type { FieldConfig } from 'types/form.types';
import type { FormSectionConfig } from 'components/FormSection/FormSection.types';
import { DEFAULT_VALUE } from 'types/form.types';
import { isSet, mergeFieldConfigsByKey } from 'utils';
import { flattenFieldConfigs } from './utils.config';
import type { DefaultValues } from 'react-hook-form';
import type { DetailSection } from 'components/DetailSummary';
import { getSchemaFieldDefinitions } from 'components/Form/utils/form.utils.schema';

// SET VALUE *FALLBACK* - GET VALUE FROM JSON_META FOR FIELDS NO IN SCHEMA / DB
export const getDataExpandedFromMetaJSON = ({
  defaultValues,
}: {
  defaultValues: IGeneric;
}): { fallbackValues: any } => {
  const JSON_META = defaultValues.json_meta ? JSON.parse(defaultValues.json_meta) : null;

  if (JSON_META) {
    // ADD key/value MISSING IN defaultValues, BUT PRESENT IN json_meta

    Object.keys(JSON_META).map((fieldName: string) => {
      if (!(fieldName in defaultValues)) {
        switch (true) {
          case fieldName.startsWith('is_'):
          case fieldName.startsWith('accept_'):
            defaultValues[fieldName] = Boolean(JSON_META[fieldName]);
            break;
          default:
            defaultValues[fieldName] = JSON_META[fieldName];
            break;
        }
      }
    });

    // CHECK defaultValues FOR UNSET VALUES, THAT ARE SET IN json_meta

    Object.keys(defaultValues).map((fieldName: string) => {
      if (!isSet(defaultValues[fieldName])) {
        const jsonValue = JSON_META[fieldName];
        if (jsonValue) {
          defaultValues[fieldName] = jsonValue;
        } else {
          switch (true) {
            case fieldName === 'is_active':
              defaultValues[fieldName] = true;
              break;
            case fieldName.startsWith('is_'):
            case fieldName.startsWith('accept_'):
              defaultValues[fieldName] = false;
              break;
            default:
              break;
          }
        }
      }
    });
  }

  return { fallbackValues: defaultValues };
};

export const initDefaultValues = <T>({
  config,
  data,
  schema = {},
}: {
  config: DataConfigurationType & { sections: FormSectionConfig[] | DetailSection[] };
  data: DefaultValues<any>;
  schema?: ISchema;
}): { defaultValues: DefaultValues<T> } => {
  const fields = flattenFieldConfigs(config.sections);
  const modelValues = { ...data };

  // initit default in case of empty data
  if (data === undefined && Object.keys(modelValues).length === 0) {
    Object.values(fields).forEach((fieldConfig: FieldConfig) => {
      modelValues[fieldConfig.name] = '';
    });
  }

  Object.values(fields).forEach((fieldConfig: FieldConfig) => {
    if ('defaultValue' in fieldConfig) {
      switch (fieldConfig.defaultValue) {
        case DEFAULT_VALUE.NEW_UUID:
          modelValues[fieldConfig.name] = modelValues[fieldConfig.name] || uuid();
          break;
        default:
          modelValues[fieldConfig.name] = fieldConfig.defaultValue;
          break;
      }
    }
  });

  const { fallbackValues: defaultValues } = getDataExpandedFromMetaJSON({ defaultValues: modelValues });

  // TODO: MEGRE WITH SCHEMA FILDS ????
  // const schemaFields = getSchemaFieldDefinitions({ schema });
  // const fieldsArrayMerged = mergeFieldConfigsByKey(fieldsArrayFromSchema, fieldsArrayConfigured, { byKey: 'name' });

  return { defaultValues };
};

// ======================================================================== //

// FILTER-OUT ANY PRIVATE DATA
export const removePrivateData = (values: any | undefined) => {
  for (const [key, _value] of Object.entries(values)) {
    // REMOVE ANY FIELDS MARKED 'USER'
    if (key.toLowerCase().includes('user')) {
      delete values[key];
    }
  }

  return values;
};
