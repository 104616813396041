import { css } from '@emotion/react';
import Color from 'color';
import { colors, layout, min, max } from 'styles';

const variantBase = Color(colors.primaryXLight);
const colorVariant = {
  bg: variantBase.lighten(0.8).hex(),
  inactive: variantBase.darken(0.2).desaturate(0).hex(),
  active: variantBase.darken(0.4).desaturate(0.3).hex(),
  sep: variantBase.darken(0.4).fade(0.75).rgb().toString(),
};

export const styles = css`
  ul.nav-menu {
    list-style: none;
    margin: 0;
    display: flex;
    align-items: center;

    .szh-menu {
      display: block;
    }

    .szh-menu__divider {
      height: 2px !important;
      background-color: ${colorVariant.sep};
      opacity: 0.5;
    }

    /********** BUTTONS (MENU) **********/

    li {
      font-size: 1.1em;
      line-height: 1.33;
      height: 100% !important;
      &::before {
        content: none;
      }
      a,
      button {
        background: transparent;
        border: 0;
        padding: 0 1.25em;
        border-left: 1px solid ${colorVariant.sep};
        border-right: 1px solid ${colorVariant.sep};
        ${min.lg} {
          font-size: 0.9em;
          padding: 0 0.9em;
        }
        ${min.xl} {
          font-size: 0.9em;
          padding: 0 1.15em;
        }
        ${min.xxl} {
          font-size: 0.9em;
          padding: 0 1.25em;
        }
        &:hover {
          cursor: pointer;
          color: ${colors.primaryDark};
          color: ${colorVariant.active};
        }
        span {
          opacity: 0.5;
          padding-left: 0.33em;
          padding-right: 0.33em;
        }
      }
      &:first-of-type a,
      &:first-of-type button,
      &:first-of-type button a {
        border-left: 0;
        padding-left: 0;
      }
      &:last-of-type a,
      &:last-of-type button,
      &:last-of-type button a {
        border-right: 0;
        padding-right: 0 !important;
      }
      a.inactive,
      button.inactive {
        &:hover {
          color: ${colors.primaryXLight};
        }
      }
      a,
      a.inactive,
      button,
      button.inactive {
        display: block;
        color: ${colors.defaultLight};
        color: ${colorVariant.inactive};
        font-weight: 700;
      }
      a.active,
      button.active {
        color: ${colors.primary};
        color: ${colorVariant.active};
      }

      button > a {
        border: 0;
        padding-left: 0;
        padding-right: calc(${layout.padding} * 0.5);
      }

      .szh-menu--state-open li a {
        border: 0;
        padding-left: 0;
      }

      .has-submenu-caret {
        transform: translate(3px, 1px) scale(1.5);
        opacity: 0.8;
      }
    }

    /********** RESPONSIVE **********/

    /* TODO: CHECK NESTING */

    & > div > div > div {
      ${min.lg} {
        padding: 0 calc(${layout.padding});
      }
      ${min.xl} {
        padding: 0 calc(${layout.padding} * 2);
      }
      ${min.xxl} {
        padding: 0 0 !important;
      }
    }
  }
`;
