import { api, headersDefault as headers, getQueryString } from 'api';
import type { QueryOptions, UseQueryGetProps } from '../query/query.types';

export const getSchema = async ({ endpoint }: Partial<QueryOptions>): Promise<Readonly<any>> => {
  // TODO: create method for this..
  const response = await api.get(`${endpoint}/_schema`);
  const responseData = response.data;
  const schema = responseData?.schema || null;

  log('[GET] SCHEMA', 'cyan', response.status, response.statusText, { schema });
  log('--------------------------------------------------------------', 'grey');

  delete responseData?.schema;
  const queryResponse = { data: undefined, schema };

  return queryResponse;
};
