import { useLayout } from 'pages/Layout/context/LayoutContext';
import { ArrayJSX } from 'utils/ArrayJSX';
import { styles } from './Hamburger.css';

export const Hamburger = () => {
  const { isSidebarOpen, isScrollLocked, setLayoutState } = useLayout();

  const handleClick = () => {
    setLayoutState({ isSidebarOpen: !isSidebarOpen });
    setLayoutState({ isScrollLocked: !isScrollLocked });
  };

  const cssClasses = new ArrayJSX();
  cssClasses.push('icon-burger');
  isSidebarOpen && cssClasses.push('open');

  return (
    <div css={styles} className={cssClasses.inline()} onClick={handleClick}>
      <span />
      <span />
      <span />
    </div>
  );
};
