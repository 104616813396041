import { NavLink } from 'react-router-dom';
import { useRouter } from 'routes/hooks';
import { Thumbnail } from 'components/Thumbnail'; // ThumbLazy NOT WORKING WITH PAGINATION
import { getImageURL } from 'utils';
import { DataEntry } from '../DataTable.types';

export const ColumnThumb = ({ rowData }: DataEntry) => {
  const { basePath } = useRouter();
  const imageSrc = getImageURL(rowData);

  return (
    <NavLink to={`${basePath}/view/${rowData.id}`}>
      <Thumbnail src={imageSrc} alt={rowData.name} cover />
    </NavLink>
  );
};
