import { INPUTS } from 'types/form.types';
import type { DetailConfig } from 'components/DetailSummary';
import type { SelectOption } from 'components/FormUI/Select';
import { DATATYPE, ENDPOINT } from './entidades.config';

let i = 0;

// PUBLIC WEB VIEW ----------------------------------------------------------------- //

// NOTE: FOLLOWING CONFIG WILL BE MERGED WITH form.config.ts/globalFieldProps
// TO POPULATE COMMON PROPS, OPTIONS etc
export const detailConfig: DetailConfig = {
  dataType: { key: DATATYPE, role: 'detail' },
  api: { endpoint: ENDPOINT },
  header: {
    title: 't.titles.entity',
    buttonLabel: 'Volver a Entidades',
  },
  hero: {
    hasImage: true,
    hasMap: false,
    // hasMap: {
    //   querySources: ['country', 'province', 'city'],
    // },
  },
  // ----------------------------------------------------------------- //
  sections: [
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Datos de la entidad o asociación',
      fields: [
        {
          name: 'json_meta',
          label: 'JSON_META',
          isHidden: true,
          inputType: INPUTS.JSON_META,
          colWidth: { xs: 12 },
        },
        {
          name: 'name',
          label: 'nombre de entidad',
          inputType: INPUTS.text,
        },
        // {
        //   name: 'image',
        //   placeholder: 'Escoge archivo...',
        //   hint: 'choose image to upload',
        //   inputType: INPUTS.image,
        // },
        {
          name: 'cat_id',
          label: 'Categoría ID',
          inputType: INPUTS.selectCategory,
          // inputType: INPUTS.select,
          // options: [],
          colWidth: { xs: 12 },
        },
      ],
    },

    // ---------------------------------------------------------------------- //

    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Más detalles y Descripción sobre la actividad',
      description: 'Realice una descripción de la actividad que será visible para los usuarios',
      fields: [
        {
          name: 'url',
          label: 'URL de la página web (o similar) de la entidad o asociación:',
          inputType: INPUTS.url,
        },
        {
          name: 'socials',
          inputType: INPUTS.socials,
          colWidth: {
            xs: 12,
            md: 12,
          },
        },
        {
          name: 'sector',
          label: 'Ámbito de actuación',
          inputType: INPUTS.select,
          options: [
            {
              value: 'local',
              label: 'Local',
            },
            {
              value: 'regional',
              label: 'Regional',
            },
            {
              value: 'nacional',
              label: 'Nacional',
            },
            {
              value: 'internacional',
              label: 'Internacional',
            },
          ] as SelectOption[],
          colWidth: {
            xs: 6,
          },
        },
        {
          name: 'description',
          label: 'Descripción',
          hint: 'más información',
          inputType: INPUTS.textarea,
        },
        {
          name: 'activities',
          label: 'Tipo de actos o actividades que realiza',
          inputType: INPUTS.textarea,
        },
      ],
    },

    // ---------------------------------------------------------------------- //

    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Datos de Contacto',
      // description: 'visibles a todo el mundo en la web pública',
      description: '',
      fields: [
        {
          name: 'email_public',
          label: 'Contacto e-mail',
          inputType: INPUTS.repeater,
          repeaterConfig: {
            inputType: INPUTS.email,
          },
        },
        {
          name: 'tel_public',
          label: 'contacto teléfono',
          inputType: INPUTS.repeater,
          repeaterConfig: {
            inputType: INPUTS.tel,
          },
        },
      ],
    },

    // ---------------------------------------------------------------------- //

    /*
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Dirección / Ubicación',
      copyFrom: {
        name: 'copy_from_user_address',
        label: `Marcar si la dirección coincide con la del titular : ${AUTH_USER.EMAIL}`,
        inputType: INPUTS.checkbox,
        defaultValue: false,
        params: { endpoint:  'register', uuid: AUTH_USER.UUID },
      },
      fields: [
        {
          name: 'address_0',
          label: 'dirección 1',
          hint: 'calle + edificio',
          inputType: INPUTS.text,
        },
        {
          name: 'address_1',
          label: 'dirección 2',
          hint: 'puerta',
          inputType: INPUTS.text,
        },
        {
          name: 'postal_code',
          label: 'código postal',
          inputType: INPUTS.text,
          order: 2,
        },
        {
          name: 'city',
          label: 'población',
          inputType: INPUTS.text,
        },
        {
          name: 'country',
          label: 'país',
          inputType: INPUTS.selectCountry,
        },
        {
          name: 'province',
          label: 'província',
          hint: 'Provincia de España',
          inputType: INPUTS.select,
          options: SPAIN_PROVINCES,

          isDisabled: true,
          isControlledBy: {
            criteria: { country: 'Spain' },
            target: { isDisabled: false },
          },
        },
      ],
    },
    */
  ],
  // ----------------------------------------------------------------- //
};
