import type { RouteObject } from 'react-router-dom';
import type { GlobalStoreValues } from 'store/GlobalSchema.types';
import { getDynamicRoutes } from 'routes/utils/getDynamicRoutes';
import { DataActions } from 'routes/utils/getDynamicRoutes.types';

import AdminDetailPage from 'pages/AdminDetailPage';
import AdminListPage from 'pages/AdminListPage';
import DetailPage from 'pages/DetailPage';
import { AuthUser } from 'auth/types/user.types';

export const getUserCategoryRoutes = ({
  store,
  user,
}: {
  store: GlobalStoreValues;
  user: AuthUser;
}): RouteObject[] => {
  if (!user || !store?.categories) {
    return [];
  }

  const routesWebCategories: RouteObject[] = getDynamicRoutes(store.categories, {
    basePath: 'cat',
    actions: [DataActions.LIST, DataActions.NEW, DataActions.EDIT, DataActions.VIEW],
    components: {
      list: AdminListPage,
      new: AdminDetailPage,
      edit: AdminDetailPage,
      view: DetailPage,
    },
  });

  return routesWebCategories;
};
