import { useForm } from 'react-hook-form';
import { useNavigateState } from 'hooks/useNavigateState';
import type { KeyboardEvent, ReactElement } from 'react';
import { useEffect, useState, useReducer, useRef, useMemo } from 'react';
import { LocationWithState } from 'types/location.types';
import { ACTIONS } from 'types/actions.types';
import type { FormConfig, FieldConfig, FormButtonBarProps } from 'types/form.types';
import { useLayout } from 'pages/Layout/context/LayoutContext';
import { useGlobal } from 'store/useGlobalContext';
import { Spinner } from 'components/Spinner';
import { initDetailData } from 'config/data.utils/initDetailData';
import type { FormComponentProps } from './Form.types';
import { FormConfigProvider, useFormConfigContext } from './context';
import { initialValues, initialState } from './context/context.config';
import {
  initFieldsConfig,
  getFormFieldsConfigured,
  // getFormDefaultValues,
  getFormSections,
} from './utils/form.utils.initialize';
import { initDefaultValues, flattenFieldConfigs } from 'utils';
import { translate } from 'utils/utils.translation';
// import { useFormHero } from './useFormHero';
import { optionsRHF, SORT_BY_KEYS } from './config';
import { v4 as uuid } from 'uuid';
import { styles } from './Form.css';
import { FormDynamic } from './FormDynamic';

// NOTE: GREAT REF: REACT-QUERY + REACT-HOOK-FORM:
// https://tkdodo.eu/blog/react-query-and-forms#invalidate-and-reset-after-mutation

// TODO: AMAZING BEST-PRACTICES REF FOR FORMS:
// ref: https://blog.logrocket.com/style-forms-css/

export const Form = ({
  formConfig,
  data,
  schema = {},
  isLocked = false,
  onSubmit,
  isNavPromptActive = true, // Navigation Blocker for unsaved changes
  hasDevTools = false, // Navigation Blocker for unsaved changes
}: FormComponentProps): ReactElement => {
  const [isInitialized, setIsInitialized] = useState(false);
  const { isNavigationBlocked, setIsNavigationBlocked } = useGlobal('isNavigationBlocked');

  log('___FORM:', 'magenta', { isNavigationBlocked, isNavPromptActive }, formConfig);

  // MISC VARS + FORM STYLES
  // if (formConfig?.api?.endpoint) Object.assign(params, { table: formConfig?.api?.endpoint });

  // TODO:: FINISH MERGING SHCMA
  // const { defaultValues: DEFAULTS } = initDefaultValues({ config: formConfig, data, schema });
  // const { fields, formSections, values: defaultValues } = initFieldsConfig({ formConfig, data, schema });

  const {
    fields,
    formSections,
    values: defaultValues,
  } = useMemo(() => {
    return initFieldsConfig({
      formConfig: translate(formConfig),
      data,
      schema,
    });
  }, [formConfig, data]);

  const formMethods = useForm({
    ...optionsRHF,
    defaultValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    formMethods.reset(formMethods.getValues());
    setIsInitialized(true);
  }, []);

  const { isDirty } = formMethods.formState;

  useEffect(() => {
    isDirty
      ? log('isDirty:', 'yellow', { isDirty: true, isNavigationBlocked })
      : log('isDirty:', 'lime', { isDirty: false, isNavigationBlocked });

    if (formConfig?.isNavPromptActive !== false) {
      setIsNavigationBlocked(isDirty);
    }
  }, [isDirty]);

  return (
    <div css={styles}>
      <FormConfigProvider
        value={{
          ...initialValues,
          formConfig,
          formSections,
          fields,
          defaultValues,
          schema,
          isLocked,
        }}
      >
        {/* <PageHero /> */}
        <FormDynamic
          // schema={undefined}
          // isLocked={false}
          // onSubmit={formMethods.handleSubmit(onSubmit)}
          onSubmit={onSubmit}
          // fields={fields}
          formMethods={formMethods}
          // {...{ formConfig, formSections, fields, defaultValues }}
        />
      </FormConfigProvider>
    </div>
  );
};
