import { css } from '@emotion/react';
import { layout } from 'styles/layout.css';
import { colors } from 'styles/colors.css';
// import { min, max } from 'styles/media-queries';

export const styles = css`
  padding-top: 5.5em;

  /*** TODO: TEMP-FIX FOR NEW IMAGE PREVIEW ***/
  form {
    margin-top: calc(${layout.padding} * 4);
  }

  .alert {
    min-height: unset;
    flex-direction: unset;
    justify-content: flex-start;
    padding: 0;

    .switch {
      margin-right: 1em;
    }

    &.alert-default {
      p {
        color: ${colors.grey};
      }
      strong {
        color: ${colors.greyDark};
      }
    }

    &.alert-info {
      p {
        color: ${colors.primary};
      }
      strong {
        color: ${colors.primaryXDark};
      }
    }
  }
`;
