import { Col, Row } from 'react-grid-system';
import { TableConfig, Action, ACTIONS } from 'types';
import { useNavigateState } from 'hooks/useNavigateState';
import { useRouter } from 'routes/hooks';
import { ArrayJSX } from 'utils/ArrayJSX';
import { getFakerImageURLv3 } from 'mocks/generators/data.images';
import { CardFooter } from './ListItemCardFooter';
import { getItemInternalURL } from './ListItemCard.utils';
import { styles } from './ListItemCard.css';

type DataListItemProps = {
  data: any;
  single?: boolean;
  config: TableConfig;
  index: number;
};

export const ListItemCard = ({ data, config, single = false, index }: DataListItemProps) => {
  const { route, params, PATH_BASE } = useRouter();
  const { navigate, navigateExternal } = useNavigateState();
  const imageSrc = getFakerImageURLv3();

  const listItemConfig = config.list?.listItem;
  const isEven: boolean = index % 2 == 0;
  const isCallout = listItemConfig?.isCallout;

  const internalPathURL = getItemInternalURL({ data, route, action: Action.VIEW, params, PATH_BASE });

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const navigateInternal = ({ action }: { action?: Action } = {}) => {
    if (action) {
      navigate(internalPathURL, { state: { action } });
    } else {
      const isActionDefault = !isCallout && listItemConfig?.onClickAction !== false;
      navigate(internalPathURL, { state: isActionDefault ? { action: ACTIONS.VIEW } : null });
    }
  };

  const handleClickCard = (event?: any) => {
    event.preventDefault();
    if (isCallout) {
      return navigateInternal();
    }
    if (event.target.id === 'list-card') {
      return data.url ? navigateExternal({ url: data.url }) : navigateInternal();
    }
  };

  // CSS CLASSES
  const bgImage = imageSrc ? { backgroundImage: `url(${imageSrc})` } : null;
  const cssClasses = new ArrayJSX('card');
  cssClasses.push(`item-${isEven ? 'even' : 'odd'}`);
  single && cssClasses.push('single');
  isCallout && cssClasses.push('callout');

  // NOTE: LIST ITEM CARD - elements of Card navigate to distict paths: internal + external
  return (
    <div css={styles} onClick={handleClickCard} className={cssClasses.inline()} style={{ ...bgImage }}>
      <Row align="center" style={{ width: '100%', height: '100%', alignItems: 'stretch' }} className="row">
        <Col id="list-card" xs={12}>
          <CardFooter
            data={data}
            config={config}
            handleClickCard={handleClickCard}
            navigateInternal={navigateInternal}
            index={index}
          />
        </Col>
      </Row>
    </div>
  );
};
