import { css } from '@emotion/react';
import { layout } from 'styles/layout.css';
import { colors } from 'styles/colors.css';

export const styles = css`
  margin: ${layout.padding} 0 calc(${layout.padding} * 2);

  &,
  & > div {
    height: 52px;
  }

  .col-controls-left,
  .col-controls-right {
    display: flex;
    button {
      padding: 0.33em;
      margin: 0;
      transform: scale(0.8);
    }
  }

  .col-controls-left {
    justify-content: flex-start;
    padding-left: calc(${layout.padding} * 0.75) !important;
  }

  .col-controls-right {
    justify-content: flex-end;
    padding-right: calc(${layout.padding} * 0.75) !important;
  }

  h2 {
    width: 100%;
    text-align: center;
    margin: 0.15em 0;
    margin-bottom: 0 !important;
    font-size: 1.2em;
    font-weight: 800 !important;
    line-height: 1.25;
    span {
      &.results-single-page {
        /* color: ${colors.primaryDark}; */
        font-size: 1.1em;
        font-weight: 700 !important;
        label {
          opacity: 0.9;
          color: ${colors.grey};
        }
        color: ${colors.primaryXLight};
      }
      &.no-results {
        color: ${colors.warning};
        font-size: 1.33em;
        font-weight: 700 !important;
      }
    }
    span:nth-of-type(2) {
      display: block;
      font-size: 0.9em;
      font-weight: 700;
      margin-top: 0.15em;
    }
  }
`;
