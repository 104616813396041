import type { DataEntry, FieldConfig } from 'types';
import { api } from 'api';
import { optionsFormReset, optionsSetLoud, optionsSetQuiet } from 'components/Form/config';
import type { SectionFieldsSetBy } from './FormSection.types';
import { initDetailData } from 'config/data.utils/initDetailData';
import type { FieldValues, UseFormGetValues, UseFormSetValue } from 'react-hook-form';

// const response = await api.get('registration', { params: { uuid: '2e36bc9d-a314-4bda-98be-38305b041f5f' } });

export const handleCopyFrom = async ({
  isChecked,
  copyFrom: { source, target },
  getValues,
  setValue,
}: {
  isChecked: boolean;
  copyFrom: SectionFieldsSetBy;
  getValues: UseFormGetValues<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
}) => {
  if (isChecked) {
    const { endpoint, ...params } = source;
    try {
      const response = await api.get('registration', { ...params });
      const sourceData = initDetailData({ data: response.data.data[0], isExpandedSchema: true });
      target.fields.forEach((field) => {
        const value = target.prefix ? sourceData?.[field.name.replace(target.prefix, '')] : sourceData?.[field.name];
        setValue(field.name, value, optionsSetLoud);
      });
    } catch (error) {
      error instanceof Error ? console.error(error.message) : console.error(String(error));
    }
  } else {
    target.fields.forEach((field) => {
      const value = field.defaultValue;
      setValue(field.name, value, optionsSetLoud);
    });
  }
};
