import { RouteConfig } from 'routes';
import { Action } from 'types/actions.types';

type GetURLProps = {
  data: any;
  route: RouteConfig;
  action?: Action;
  params?: any;
  PATH_BASE: string;
};

export const getItemInternalURL = ({ data, route, action, params, PATH_BASE }: GetURLProps): string => {
  if (action === 'new') return `${route.pathname}/new`;
  if (action) return `${route.pathname}/${action}/${data.id}`;
  if (route?.path) return `${route.pathname}/${action}/${data.id}`;
  if (route?.path) return `${PATH_BASE}/${route.path}/view/${data.id}`;
  if (data?.basePath) return `${PATH_BASE}/${data.basePath}/view/${data.id}`;

  return `${PATH_BASE}/${data.path}`;
};
