import { useLocation } from 'react-router-dom';
import { useNavigateState } from 'hooks/useNavigateState';
import { useEffect } from 'react';
import type { LocationWithState } from 'types';
import { useAuth } from 'auth/AuthContext';
import { Spinner } from 'components/Spinner';

const REDIRECT_DEFAULT = '/logged-out';

const LogOut = ({ redirectPath = REDIRECT_DEFAULT }: { redirectPath?: string }) => {
  const { navigate } = useNavigateState();
  const { user, logout } = useAuth();
  const location = useLocation();
  const locationState = (location as unknown as LocationWithState)?.state;

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      (async () => {
        user
          ? await logout(() => navigate(redirectPath, { state: { ...locationState }, replace: true }))
          : document.location.replace(redirectPath);
      })();
    }

    return () => {
      isSubscribed = false;
    };
  }, [location, user]);

  return <Spinner page dark />;
};

export { LogOut };
