import { UseFormProps } from 'react-hook-form';
import { defaultOptionsRHF } from 'components/Form/config';

// ALL LATEST v7 DEFAULTS - OFFICIAL DOCS (09-2021)
// https://react-hook-form.com/ts#Mode
// TYPES: import { UseFormProps, RegisterOptions, FormStateProxy, FieldErrors } from 'react-hook-form';

export type Mode_ENUM = {
  onBlur: 'onBlur';
  onChange: 'onChange';
  onSubmit: 'onSubmit';
  onTouched: 'onTouched';
  all: 'all';
};

// ========================================================================== //

// CUSTOM OVERRIDES
const customOptionsRHF: Partial<UseFormProps> = {
  mode: 'onChange', // DEFAULT: 'onChange'
  reValidateMode: 'onChange', // DEFAULT: 'onChange'
  // defaultValues: {},
  // resolver: undefined, // joiResolver(schemaJoi), // TODO: USE YUP !!
  // context: undefined,
  // shouldFocusError: true,
  // shouldUnregister: false,
  criteriaMode: 'firstError', // 'firstError' | 'all'
  shouldFocusError: false,
};

// CUSTOM OVERRIDES
export const optionsRHF = {
  ...defaultOptionsRHF,
  ...customOptionsRHF,
};

// ========================================================================== //

// TODO: NOTE - PERHAPS BEST UX:
// 1. initial form state - mode:'onBlur'
// 2. after submitting with errors: mode:'onChange' (for easy FIXING)

// ========================================================================== //

export const optionsFormReset = {
  keepErrors: false,
  keepDirty: false,
  keepIsSubmitted: false,
  keepTouched: true,
  keepIsValid: true,
  keepSubmitCount: true,
};
