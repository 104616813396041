/* eslint-disable import/order */
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { type ReactElement, type Dispatch, type SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { translate } from 'utils';
import { FaRegCheckCircle as IconSuccess, FaUserPlus as IconUser } from 'react-icons/fa';
import { MdErrorOutline as IconError } from 'react-icons/md';
import type { FormData as FormDataType } from 'formdata-node';
import { useAuth } from 'auth/AuthContext';
import { Alert } from 'components/Alert';
import { Button } from 'components/Button';
import { Form } from 'components/Form';
import { css } from '@emotion/react';
import { Spinner } from 'components/Spinner';
import { formConfig } from 'config/datatype/login/register.form';
import { styles } from '../forms.css';
import { useCreateNew } from 'api';
import type { AuthUser } from 'auth';

// NOTE: GREAT GUIDE TO BEST-PRACTICES FOR LOGIN-FORMS:
// ref: https://evilmartians.com/chronicles/html-best-practices-for-login-and-signup-forms

// NOTE: CONSIDER USING NEW "PASSKEYS", INSTEAD OF PASSWORDS:
// https://www.passkeys.io

export const stylesCustom = css`
  /* button[aria-label='DEV_FILL'] {
    display: none !important;
  } */
  .col-buttons {
    button {
      max-width: none !important;
      width: 610px !important;
      opacity: 1 !important;
      flex-wrap: wrap !important;
    }
  }
`;

const RegisterForm = ({
  setCurrentTabID,
}: {
  setCurrentTabID: Dispatch<SetStateAction<string>>;
}): ReactElement => {
  const { t } = useTranslation();
  const { registerNewUser, error } = useAuth();
  const isBusy = false; // TODO: CONNECT BOOLEAN TO MUTATION..

  const createNew = useCreateNew({
    mutationKey: ['createNew', { endpoint: 'registration' }],
    endpoint: 'registration',
  });

  const createNewRegistration = async (newUser: AuthUser) => {
    const formData = new FormData();
    formData.set('uuid', newUser.uuid);
    formData.set('email', newUser.email);
    const body = JSON.stringify(Object.fromEntries((formData as FormDataType).entries()));
    return await createNew.mutateAsync({ body });
  };

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    registerNewUser({ ...(data as any) }, async (newUser: FieldValues) => {
      try {
        const created = await createNewRegistration(newUser as AuthUser);
        // const href = `${PATH_BASE}/register-user-data/${created.id}`;
        const href = '/register-user-data';
        document.location.replace(href);
      } catch (error: unknown) {
        error instanceof Error ? console.error(error.message) : console.error(String(error));
      }
    });
  };

  return (
    <div css={{ ...styles, ...stylesCustom }}>
      {!isBusy && (!error || error?.code < 422) && (
        <Alert color="success">
          {/* <IconUser style={{ transform: 'translateX(+8%)' }} onClick={() => navigate('/registration')} /> */}
          <IconUser style={{ transform: 'translateX(+8%)' }} />

          <h4>{t('t.register-owner.title')}</h4>
          <ul className="subtitle-points">
            <li>{t('t.register-owner.subtitle-points.0')}</li>
            <li>{t('t.register-owner.subtitle-points.1')}</li>
            <li>{t('t.register-owner.subtitle-points.2')}</li>
            <li>{t('t.register-owner.subtitle-points.3')}</li>
          </ul>
        </Alert>
      )}
      {isBusy && (
        <Alert color="info">
          <Spinner inline color="info" />
          <h4>registering...</h4>
          <p></p>
        </Alert>
      )}
      {!isBusy && error?.code >= 404 && (
        <Alert color="danger">
          <IconError />
          <h4>Registration Error</h4>
          <p>
            {error?.code === 422 && 'User already exists'}
            {error?.code === 500 && 'connection error'}
          </p>
        </Alert>
      )}
      {error?.code === 201 && (
        <>
          <Alert color="success">
            <IconSuccess />
            <h4>User registered.</h4>
            <p>{JSON.parse(localStorage?.getItem('NEW_REGISTRATION') as string)?.user?.email}</p>
          </Alert>
          <hr />
          <Button label="GOTO LOGIN" color="defaultLight" onClick={() => setCurrentTabID('tab-login')} />
        </>
      )}
      <Form
        formConfig={translate(formConfig)}
        isLocked={isBusy}
        onSubmit={onSubmit}
        isNavPromptActive={false}
      />
    </div>
  );
};

export default RegisterForm;
