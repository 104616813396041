import { css } from '@emotion/react';
import { colors, layout, min } from 'styles';

export const styles = css`
  form#form-eventos {
    fieldset > label + div {
      margin: calc(${layout.padding} * 1) 0;
    }
  }

  .modal-xl {
    width: 90vw !important;
    max-width: 1600px !important;
  }

  .modal {
    section {
      /* border: 2px solid red !important; */
      border: none !important;
      font-size: 0.9em;
      text-align: left;
    }

    .row-body {
      text-align: left;
    }
  }

  button.btn-add {
    margin: 1em 0;
  }

  ${min.xs} {
    .col-btn {
      order: 2;
    }
    .col-list {
      order: 1;
    }
  }
  ${min.lg} {
    .col-btn {
      order: 1;
    }
    .col-list {
      order: 2;
      /* padding-left: calc(${layout.padding} * 7) !important; */
    }
  }

  .row-preview {
    width: 100%;
    height: 100%;
    align-items: stretch;
    background: white;
    margin: calc(${layout.padding} * 0.33) 0 !important;
    padding: calc(${layout.padding} * 0.8) 0 !important;
    border-radius: calc(${layout.padding} * 0.33) !important;
    font-size: 1.1em;
    .col-action {
      padding: 0 !important;
      svg.faux-checkbox {
        display: flex;
        background: white;
        color: ${colors.success};
        transform: scale(2.25) translateX(calc(${layout.padding} * 0.5));
        cursor: pointer;
        &:hover {
          transition: color 0.3s ease;
          color: ${colors.successXDark};
        }
      }
    }
    .col-name {
      color: ${colors.greyXDark};
      font-weight: 700;
    }
    .col-location {
      color: ${colors.grey};
      font-weight: 500;
    }
  }
`;
