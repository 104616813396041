import { Bars } from 'react-loading-icons';
import type { ReactElement } from 'react';
import { ArrayJSX } from 'utils/ArrayJSX';
import { styles } from './Spinner.css';

interface SpinnerProps {
  inline?: boolean;
  page?: boolean;
  layout?: boolean;
  color?: 'light' | 'dark' | 'default' | 'info' | 'success' | 'warning' | 'error' | 'danger' | any;
  dark?: boolean;
  light?: boolean;
  opacity?: number;
}

export const Spinner = ({ inline, page, layout, color, dark, light, opacity }: SpinnerProps): ReactElement => {
  const cssClasses = new ArrayJSX();
  cssClasses.push('spinner');

  inline && cssClasses.push('inline');
  layout && cssClasses.push('layout');
  page && cssClasses.push('page');

  if (color && !light && !dark) {
    cssClasses.push(`color-${color}`);
  } else {
    light && !dark && cssClasses.push('color-light');
    dark && !light && cssClasses.push('color-dark');
  }

  if (!opacity) {
    opacity = page ? 0.33 : 0.25;
  }

  return (
    <div
      css={styles}
      className={cssClasses.inline()}
      style={{ opacity }}
      role="alert"
      aria-live="polite"
      aria-label="Loading"
    >
      <Bars height="100%" stroke="none" />
    </div>
  );
};
