import type { RouteConfig } from 'routes';
import { useAuth } from 'auth/AuthContext';
import { useGlobal } from 'store/useGlobalContext';
import { useEffect } from 'react';
import { useGetRouteConfiguration } from 'routes/hooks/useGetRouteConfiguration';
import { useInitCategories } from 'routes/hooks/useInitCategories';
import { getAppRoutes } from 'routes/getAppRoutes';
import { prependBasePathToRouteConfigs } from 'routes/utils/routes.utils.config';
import { flatttenChildren } from 'routes/utils/routes.utils.flatten';
import { findRouteByProp } from 'routes/utils/routes.utils.find';
import { getRoutesMetadataTree } from 'routes/utils/getRoutesMetadata';
import { useStore } from 'store/useStore';
import { cloneDeep } from 'lodash';
import { DEFAULT_ROUTES_CONFIG_KEYS } from 'routes/routes.constants';

export const useAppMetadataLayer = ({ isEnabled = false }: { isEnabled?: boolean }) => {
  const store = useStore();
  const { user } = useAuth();
  const { routesConfiguration, setRoutesConfiguration } = useGlobal('routesConfiguration');
  const { categories } = useGlobal('categories');
  const { setMetaRoutes } = useGlobal('metaRoutes');
  const { setMetaRoutesTree } = useGlobal('metaRoutesTree');

  /*
    // TODO:: REEMPLEMENT >>
    const isAdminPath = location.pathname.startsWith('/admin');
    const ROUTES_CONFIG_KEYS: RouteGroup[] = ['public'];

    if (isAdminPath && user?.user_role === USER_ROLE.ADMIN) {
      ROUTES_CONFIG_KEYS.push('admin');
    }
    if ([USER_ROLE.REGISTERED, USER_ROLE.VALIDATED].includes(user?.user_role)) {
      ROUTES_CONFIG_KEYS.push('user');
    }
  */

  if (!isEnabled) {
    return;
  }

  const { isFetchingCategories, isSuccessCategories } = useInitCategories();
  const { isFetchingRoutes, isSuccessRoutes, routesConfig } =
    useGetRouteConfiguration(DEFAULT_ROUTES_CONFIG_KEYS);

  useEffect(() => {
    if (isSuccessRoutes && categories.length > 0) {
      const ROUTES_MERGED = [...routesConfig].flat() as RouteConfig[];

      const categoriesPublic = prependBasePathToRouteConfigs(categories, { basePath: 'cat' });
      ROUTES_MERGED.push(...(categoriesPublic as RouteConfig[]));

      const categoriesPrivate = prependBasePathToRouteConfigs(categories, {
        basePath: 'account/registros/cat',
      });

      // NOTE: PRIVATE USER ROUTES
      const privateCatsRoute = findRouteByProp(ROUTES_MERGED, { pathname: '/account/registros/cat' });

      if (privateCatsRoute) {
        privateCatsRoute.children.push(...(categoriesPrivate as RouteConfig[]));
      }

      // NOTE: 1. SET ROUTES_CONFIG
      setRoutesConfiguration(ROUTES_MERGED);

      // NOTE: 2. SET ROUTES_CONFIG - TO LEGACY STORES
      const ROUTES_MERGED_FLAT = flatttenChildren(ROUTES_MERGED);
      setMetaRoutes(ROUTES_MERGED_FLAT);

      // NOTE: 3. SET ROUTES_CONFIG - TO LEGACY STORES
      const routes = getAppRoutes({ user, store });
      const metaRoutesTree = getRoutesMetadataTree({ routes: cloneDeep(routes), routesConfig });
      setMetaRoutesTree(metaRoutesTree);
    }
  }, [isSuccessRoutes, categories]);

  return {
    isFetching: isFetchingCategories || isFetchingRoutes,
    isSuccess: isSuccessCategories && isSuccessRoutes,
    // data: routesConfig,
    data: routesConfiguration,
  };
};
