import { css } from '@emotion/react';
import { PageHeader } from 'components/PageHeader';
import { reverseString } from 'utils';
import { min } from './media-queries-V1';
import { colors } from './colors.css';
import { cssGlassXFrosted } from './special.css';

const fontSize = 16;
const pageColor = colors.greyXXLight;
const bgColor = 'white';

export const border = css`
  border-color: ${colors.greyXLight};
  border-style: solid;
  border-width: 2px;
`;

// LAYOUT VARIABLES
export const layout = {
  fontSize, // px
  padding: '15px',
  borderWidth: '2px',
  borderRadius: '5px',
  header: {
    height: '80px',
  },
  pageHeader: {
    height: '180px',
  },
  footer: {
    height: '50px',
  },
  sidebar: {
    width: '300px',
  },
  navbar: {
    height: '41px',
  },
  imagePreview: {
    height: '300px',
  },
};

// LAYOUT STYLES
export const cssLayout = css`
  html {
    display: flex;
    align-items: center;
    justify-content: center;
    /** SCROLLBAR HANDLING **/
    scrollbar-gutter: auto;
    overflow-x: hidden;
    overflow-y: auto;
    /** NEXT LINE ENSURES *NO* JUMP WHEN SCROLLBAR TOGGLES **/
    margin-right: calc(-1 * (100vw - 100%));
    body {
      width: 100vw;
    }
  }

  body {
    margin: 0;
    width: 100vw;
    min-height: 100vh;
    /* overflow-x: hidden; */
    &.sidebar-open {
      overflow-y: hidden;
      padding-right: 20px; /* TODO: GET SCROLLBAR WIDTH FOR EACH BROWSER */
    }
  }

  #root {
    display: flex;
    min-height: 100vh;
    background-color: ${pageColor};

    /* NEW */
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
  }

  main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    /* padding-top: ${layout.header.height}; */
    padding-top: 0;
    min-height: calc(100vh - ${layout.header.height + layout.footer.height + layout.navbar.height});
  }

  #root > header,
  #root > footer {
    padding: calc(${layout.padding} * 1.25) calc(${layout.padding} * 1);
    z-index: 0;
    div > div > div {
      display: flex;
      align-items: center;
    }
  }

  #root > header,
  #root > footer,
  #root > aside {
    background-color: ${bgColor};
    ${border}
  }

  #root > header {
    width: 100%;
    /* position: fixed; */
    position: sticky;
    left: 0;
    top: 0;
    border: 0;
    border-bottom-width: ${layout.borderWidth};
    background-color: rgba(255, 255, 255, 0.85);
    z-index: ${`${new Date().getTime()}`.split('').reverse().join('')};
    ${cssGlassXFrosted}
    & > div {
      min-height: calc(${layout.header.height} - 2 * (${layout.padding}));
    }
  }

  #root > footer {
    min-height: ${layout.footer.height};
    border-top-width: ${layout.borderWidth};
  }

  #root > aside {
    /* SIDEBAR */
    padding: ${layout.padding};
    background: ${bgColor};
    z-index: 0;
  }

  #content {
    flex-grow: 1;
    width: 100%;
    padding-top: calc(${layout.padding} * 1);
  }

  #content > section {
    position: relative;
    padding-left: calc(${layout.padding} * 1);
    padding-right: calc(${layout.padding} * 1);
  }

  /***** SECTION, NO PAGE HEADER *****/
  #content > header {
    /* padding-top: calc(${layout.padding} * 2) !important; */
  }

  /***** SECTION, NO PAGE HEADER *****/
  #content > section {
    padding-top: calc(${layout.header.height} + (${layout.padding} * 3));
    ${min.lg} {
      padding-top: calc(${layout.header.height} + (${layout.padding} * 5));
    }
  }

  /***** SECTION BELOW PAGE HEADER *****/
  #content > header + section {
    padding-top: calc(${layout.padding} * 1) !important;
  }

  #content form#form-main {
    padding-bottom: calc(${layout.padding} * 2);
  }

  /********** RESPONSIVE **********/

  .col-left,
  .col-right {
    display: flex;
    justify-content: center;
  }

  #content {
    padding-left: calc(${layout.padding} * 1) !important;
    padding-right: calc(${layout.padding} * 1.8) !important;
  }

  .header > div,
  .footer > nav {
    padding-left: calc(${layout.padding} * 1) !important;
    padding-right: calc(${layout.padding} * 1) !important;
  }

  ${min.md} {
    .col-left {
      justify-content: start;
    }
    .col-right {
      justify-content: end;
    }

    #content {
      padding-left: calc(${layout.padding} * 1) !important;
      padding-right: calc(${layout.padding} * 1.8) !important;
    }

    .header > div,
    .footer > nav {
      padding-left: calc(${layout.padding} * 1) !important;
      padding-right: calc(${layout.padding} * 1) !important;
    }
  }

  ${min.lg} {
    #content,
    .header > div,
    .footer > nav {
      padding-left: calc(${layout.padding} * 1.5) !important;
      padding-right: calc(${layout.padding} * 1.5) !important;
    }
  }

  ${min.xl} {
    #content,
    .header > div,
    .footer > nav {
      padding-left: calc(${layout.padding} * 2.5) !important;
      padding-right: calc(${layout.padding} * 2.5) !important;
    }
  }

  ${min.xxl} {
    #content,
    .header > div,
    .footer > nav {
      padding-left: calc(${layout.padding} * 0.5) !important;
      padding-right: calc(${layout.padding} * 0.5) !important;
    }
  }
`;
