import { css } from '@emotion/react';
import { config } from 'config';
import { layout } from 'styles/layout.css';
import { colors } from 'styles/colors.css';
import { min, max } from 'styles/media-queries-V1';

export const stylesHeaderSort = css``;

export const stylesSort = css``;

export const styles = css`
  height: calc(${config.tableRowHeight} / 2);

  .row-header {
  }

  .th {
    height: calc(${config.tableRowHeight} / 2);
    background-color: ${colors.primary};
    color: #ffffff !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 0.7em;

    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 0;
    border-style: solid;
    border-color: ${colors.primaryDark};

    border-collapse: collapse;
    cursor: default;
    transition: background-color 300ms ease;
    /* DISABLE TEXT SELECTION */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  }

  .th.col-name {
    padding-left: 0 !important;
  }

  .hide-max-lg {
    ${max.lg} {
      display: none;
    }
  }

  .th.sortable {
    cursor: pointer;
    &:hover {
      background-color: ${colors.primaryDark};
    }
    width: 100%;

    button,
    button:hover {
      height: calc(${config.tableRowHeight} / 2);
      width: 100%;
      padding: 0;
      color: #ffffff !important;
      background-color: transparent;

      .sort-toggle {
        height: calc(${config.tableRowHeight} / 2);
        width: 20px;
        margin-right: -20px;
        padding-left: 3px;
        cursor: default;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1em;
        transition: opacity 300ms ease;

        svg {
          opacity: 0.66;
          position: absolute;
          transform: scale(1.5) translateY(0.05em);
        }
      }
    }
  }
`;
