import { css } from '@emotion/react';
import Color from 'color';
import { layout, colors, min, cssGlassFrosted } from 'styles';

const isAdminRoute = !!location.pathname.startsWith('/admin');

// eslint-disable-next-line prettier/prettier
const colorHeaderBase = isAdminRoute
  ? Color(colors.primary).desaturate(0.4).lighten(0.25)
  : Color(colors.primaryLight);

const colorsHeader = {
  bg: colorHeaderBase.desaturate(0.33).fade(0.15).rgb().toString(),
  color: colorHeaderBase.desaturate(0.2).lighten(1).hex(),
  colorHover: '#ffffff',
};

const colorNav = Color(colors.primaryXLight);
const colorsNav = {
  bg: colorNav.lighten(0.75).hex(),
  inactive: colorNav.lighten(0.25).desaturate(0.6).hex(),
  sep: colorNav.lighten(0.6).desaturate(0.4).hex(),
};

export const styles = css`
  /* opacity: 0.2; */
  position: sticky !important;
  /* position: fixed !important; */
  top: 0;
  left: 0;
  right: 0;
  z-index: ${`${new Date().getTime()}`.split('').reverse().join('')};
  min-height: ${layout.header.height};
  padding: ${layout.padding} 0;
  border-color: ${colorsNav.sep};
  border: 0;
  /* ${cssGlassFrosted} */

  /***** CONTAINER *****/
  & > div {
    height: 100%;

    /***** ROW *****/
    & > div {
      height: 100%;
      padding: 0 ${layout.padding};
    }

    /***** COLUMNS *****/
    & > div > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .header-main {
    text-align: center;
    justify-content: center;
    /* background-color: rgba(150, 0, 0, 0.1); */
    h1 {
      display: block;
      width: 100%;
      margin: 0;
      font-size: 1.2em;
      line-height: 1.5;
    }
    .title-app {
      color: ${colors.greyDark};
    }
  }

  .header-middle {
    text-align: center;
    justify-content: center;
    /* background-color: rgba(0, 150, 0, 0.15); */
  }

  .header-right {
    justify-content: flex-end;
    padding: 0 0 !important;
    /* background-color: rgba(0, 0, 150, 0.15); */
  }

  /********** DARK **********/

  &.dark {
    background: ${colorsHeader.bg}!important;
    h1 {
      color: ${colors.white};
    }
    .title-app {
      color: ${colors.white};
    }
    .title-page {
      color: ${colorsHeader.color};
      opacity: 0.9;
    }
    .col-logout,
    .col-avatar,
    .col-username span {
      &,
      button {
        color: ${colorsHeader.colorHover};
        svg {
          fill: ${colorsHeader.colorHover};
          color: ${colorsHeader.colorHover};
        }
      }
      button:hover {
        background: rgba(255, 255, 255, 0.2);
      }
    }

    .col-burger {
      .icon-burger span {
        background-color: ${colorsHeader.color};
      }

      .icon-burger:hover span {
        background-color: ${colorsHeader.colorHover};
      }
    }
  }

  /********** RESPONSIVE **********/

  padding: ${layout.padding} calc(${layout.padding} * 0.66) ${layout.padding} calc(${layout.padding} * 0.2);

  ${min.sm} {
    padding: ${layout.padding} calc(${layout.padding} * 0) ${layout.padding} 0;
  }

  ${min.md} {
    padding: ${layout.padding} calc(${layout.padding} * 1) ${layout.padding} 0;
    .header-main {
      text-align: left;
      justify-content: flex-start;
      h1 {
        display: initial;
        font-size: 1.44em;
      }
    }
    .header-right {
      /* padding: 0 calc(${layout.padding} * 2) !important; */
      padding: 0 0 !important;
    }
  }

  ${min.lg} {
    padding: ${layout.padding} calc(${layout.padding} * 1) ${layout.padding} 0;
    .header-main {
      text-align: left;
      justify-content: flex-start;
      h1 {
        font-size: 1.66em;
      }
    }
  }
  ${min.xl} {
    padding: ${layout.padding} calc(${layout.padding} * 1) ${layout.padding} 0;
  }

  ${min.xxl} {
    padding: ${layout.padding} calc(${layout.padding} * 0) ${layout.padding} 0;
  }

  padding-left: 0 !important;
  padding-right: 0 !important;
`;
