import { Row, Col } from 'react-grid-system';
import { ReactElement, useEffect, useState } from 'react';
import { ACTIONS } from 'types';
import type { DataEntry, DataConfigurationType } from 'types';
import { useRouter } from 'routes/hooks';
import { useQueryGet } from 'api/useQueryGet';
import type { DetailConfig } from 'components/DetailSummary';
import { Form } from 'components/Form';
import type { FormConfig } from 'types/form.types';
import { Spinner } from 'components/Spinner';
import { styles } from './AdminDetailPage.css';
import { initDetailData } from 'config/data.utils/initDetailData';
import { initEmptyData } from 'config/data.utils/initEmptyData';
import { useQueryBuilder } from 'hooks/useQueryBuilder';
import { usePageHeader } from 'hooks/usePageHeader';
import { useAuth } from 'auth/AuthContext';
import { DataValuesJSON } from '../../components/DataValuesJSON';
import { DataConfiguration } from 'routes/utils/getDynamicRoutes.types';
// import { DevDataJSON } from './DataValuesJSON/DevDataJSON';

const AdminDetailPage = ({ dataConfig }: DataConfiguration): ReactElement => {
  const config: FormConfig = dataConfig.formConfig;
  const { user } = useAuth();
  const { params, route, location, action, slug, basePath } = useRouter();
  const { setPageHeaderTitles } = usePageHeader({ config });

  const isNew = !!(params?.action && params.action === ACTIONS.NEW);
  const { query, endpoint } = useQueryBuilder({ config });

  const [data, setData] = useState<DataEntry | null>(null);

  // NOTE: {isPending, isError } (not isFetching) RECOMMENDED BY react-query MAINTAINERS + DOCS..
  // ref: https://github.com/TanStack/query/discussions/6297

  const {
    schema,
    // data: response,
    isSuccess,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useQueryGet({
    endpoint,
    query,
    enabled: false,
    schemaOnly: action === ACTIONS.NEW,
    onSuccess: ({ data }) => {
      if (data) {
        log('__DEV: config', 'grey', data);
        log('__DEV: config', 'lime', { action, route, params, slug, basePath });
        const dataInitialized = initDetailData({ data, isExpandedSchema: true });
        log('__DEV: config', 'magenta', { dataInitialized });
        setData(dataInitialized);
        setPageHeaderTitles(dataInitialized);
      } else {
        const dataParsed = initEmptyData({ schema, user });
        setData(dataParsed);
      }
    },
  });

  useEffect(() => {
    refetch();
    setPageHeaderTitles();
  }, [location.pathname, action]);

  log('__DEV: config', 'red', { config, isLoading, isFetching, data });

  if (!config || isLoading || isFetching || !data) {
    return <Spinner dark />;
  }

  return (
    <section css={styles}>
      {/* <DataValuesJSON data={data} schema={schema} /> */}
      {/* <DevDataJSON /> */}
      <Row>
        <Col xs={12}>
          {data && !isNew ? (
            <Form
              formConfig={config as FormConfig}
              data={data}
              schema={schema}
              // isNavPromptActive={false}
              isNavPromptActive={action === ACTIONS.EDIT}
            />
          ) : (
            <Spinner page dark />
          )}
        </Col>
      </Row>
    </section>
  );
};

export default AdminDetailPage;
