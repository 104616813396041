import type { Context } from 'react';
import React, { createContext, useContext, useReducer, useCallback } from 'react';
import type { ContextState, ValueKeys } from './FormConfigContext.types';
import { contextReducer, Actions } from './context.reducer';
import { CONTEXT_NAME, initialState } from './context.config';

export type ContextProviderProps = {
  value: Partial<ContextState>;
  children: React.ReactNode;
};

const ContextInstance: Context<ContextState> = createContext<ContextState>(initialState);

export const useGetContext = (): ContextState => useContext(ContextInstance);

export const ContextProvider = ({ value: initialStateOverrides, children }: ContextProviderProps) => {
  const [state, dispatch] = useReducer(contextReducer, { ...initialState, ...initialStateOverrides });

  const setContextState = useCallback((value: { [key in ValueKeys]: unknown }) => {
    dispatch({ type: Actions.SET_FIELDS, payload: { value } });
  }, []);

  return (
    <ContextInstance.Provider value={{ ...state, [`set${CONTEXT_NAME}State`]: setContextState }}>
      {children}
    </ContextInstance.Provider>
  );
};

export default ContextProvider;
