import { useTranslation } from 'react-i18next';
import { ReactElement } from 'react';
import { Menu as MenuReact, MenuItem, MenuButton, MenuDivider } from '@szhsin/react-menu';
import { v4 as uuid } from 'uuid';
import { IoMdArrowDropdown as IconCaretDown } from 'react-icons/io';
import type { RouteConfig } from 'routes';
import { NavItem } from 'components/Nav/NavItem';
import { MenuSubItems } from './MenuSubItems';
import { styles } from './Menu.css';

// NEW: CSS-Only DROPDOWN MENU:
// https://csslayout.io/nested-dropdowns/

/**
 * REACT-MENU_DOCS
 * https://www.npmjs.com/package/@szhsin/react-menu
 * https://codesandbox.io/s/react-menu-starter-3ez3c
 * https://codesandbox.io/s/react-menu-starter-3ez3c
 *
 * REACT-ROUTER EXAMPLE:
 * https://codesandbox.io/s/react-menu-react-router-example-dw4ku
 */

// TODO: REMOVE any[]
export const Menu = ({ routes = [] }: { routes: RouteConfig[] }): ReactElement => {
  const { t } = useTranslation();

  const routesVisible: RouteConfig[] = routes.filter(
    (route: RouteConfig) => route.isActive && route?.nav?.isHidden !== true,
  );

  return (
    <div css={styles}>
      <div>
        <ul className="nav-menu">
          {routesVisible?.map((route: RouteConfig) => {
            const title = !route.title ? route.path : route.title;
            const hasChildren = route?.nav?.isHiddenChildren !== true && !!route?.children?.length;

            if (!hasChildren) {
              return (
                <li key={uuid()}>
                  <NavItem path={route.pathname} title={title} />
                </li>
              );
            }

            return (
              <li key={uuid()}>
                <MenuReact
                  menuButton={
                    <MenuButton>
                      <>
                        {title} <IconCaretDown className="has-submenu-caret" />
                      </>
                    </MenuButton>
                  }
                >
                  <MenuItem key={uuid()}>
                    <NavItem
                      path={route.pathname}
                      title={
                        <>
                          {title} <span>({t('t.menu.labels.all')})</span>
                        </>
                      }
                    />
                  </MenuItem>
                  <MenuDivider />
                  <MenuSubItems routes={route.children} />
                </MenuReact>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
