import { css } from '@emotion/react';
import { colors, layout, max, min } from 'styles';

export const styles = css`
  height: calc(${layout.header.height} - ${layout.padding} * 2);
  transform: translate(calc(${layout.padding} * 0.5), 0);

  ${min.md} {
    transform: translate(calc(${layout.padding} * 0.5), 0);
  }
  ${min.lg} {
    transform: translate(calc(${layout.padding} * 0.5), 0);
  }
  ${min.xl} {
    transform: translate(calc(${layout.padding} * 0), 0);
  }

  .tooltip-wrapper {
    display: inline-flex;
    align-items: center;
    a {
      font-weight: 700;
      display: inline-flex;
      align-items: center;
      color: white;
    }
  }

  .col-user {
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    white-space: nowrap;
    color: ${colors.white};
    transform: translate(-5%, 0);

    svg.avatar {
      fill: ${colors.white};
      margin-right: ${layout.padding};
      padding: 0;
      transform: translate(-80%, 0) scale(1.8);
      ${min.lg} {
        transform: translate(-15%, 0) scale(1.66);
      }
    }

    svg.has-submenu-caret {
      transform: translate(-100%, 0) scale(1.25);
      ${min.lg} {
        transform: translate(-15%, 0) scale(1.33);
      }
    }

    img.avatar {
      /* For non-IE + Edge: BONUS aesthetic for toned-down avatar colors 😁 */
      filter: grayscale(0.25) opacity(0.75);
      /* height: calc(${layout.header.height} - ${layout.padding} * 2); */
      /* max-height: 38px; */
      /* margin: 4px; */
      /* border-radius: 3px; */
    }

    .username {
      display: inline-flex;
      justify-content: center;
      white-space: nowrap;
      align-items: center;
      padding: 0 ${layout.padding} 0 calc(${layout.padding} * 0.25);
      font-size: 0.9em;
      font-weight: 700;
      line-height: 1;
      cursor: pointer;
    }
    .username + .has-submenu-caret {
    }
  }

  /* --------------------------------------------------------------------- */

  .col-logout {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  ul.nav-menu {
    list-style: none;
    padding: 0;
    /* transform: translateX(calc(${layout.padding} * 1.5)); */
    & > li > button {
      border: none !important;
      background: none !important;
      text-align: center;
    }
  }

  span.start-session,
  span.register {
    font-weight: 700;
    color: white;
    display: inline-flex;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
    svg.btn-login {
      fill: ${colors.white};
      margin-right: ${layout.padding};
      padding: 0;
      transform: translate(calc(${layout.padding} * 1.25), 25%) scale(1.8);
      margin-right: calc(${layout.padding} * 0.75);
      color: ${colors.white};
      /* opacity: 0.8;
      &:hover {
        opacity: 1;
      } */
    }
  }

  span.start-session {
    transform: translate(calc(${layout.padding} * 0), 0);
  }
  span.register {
    transform: translate(calc(${layout.padding} * -2), 0);
  }

  ${min.sm} {
    span.start-session {
      transform: translate(calc(${layout.padding} * -0.5), 0);
    }
    span.register {
      transform: translate(calc(${layout.padding} * -2.5), 0);
    }
  }
  ${min.md} {
    span.start-session {
      transform: translate(calc(${layout.padding} * -1.5), 0);
    }
    span.register {
      transform: translate(calc(${layout.padding} * -3.5), 0);
    }
  }
  ${min.lg} {
    span.start-session {
      transform: translate(calc(${layout.padding} * -1), 0);
    }
    span.register {
      transform: translate(calc(${layout.padding} * -3), 0);
    }
  }
  ${min.xl} {
    span.start-session {
      transform: translate(calc(${layout.padding} * -1.5), 0);
    }
    span.register {
      transform: translate(calc(${layout.padding} * -3.5), 0);
    }
  }
`;
