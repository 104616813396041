import type { RouteObject, NonIndexRouteObject } from 'react-router-dom';
import type { CatMeta } from 'types';
import type { RouteConfig } from 'routes/routes.types';
import AdminDetailPage from 'pages/AdminDetailPage';

// const ROUTES_META_PATH_BLACKLIST: string[] = ['/', '*', 'logout'];
const ROUTES_META_PATH_BLACKLIST: string[] = ['', '*', 'logout'];

// ========================================================================== //
// CATEGORY ROUTE GENERATOR

export const genCategoryRouterRoutes = (
  { categories, basePath }: { categories: CatMeta[]; basePath: string },
  arr: unknown = [],
): RouteObject[] => {
  if (!categories) return [];

  const categoryRoutes = categories.map((cat) => {
    const hasChildren = !!cat.children?.length;
    const childRoute = {
      ...cat,
      path: `${basePath}/${cat.slug}`,
    };

    return {
      title: cat.title,
      path: childRoute.path,
      element: AdminDetailPage as NonIndexRouteObject,
      children: hasChildren
        ? genCategoryRouterRoutes(
            { categories: childRoute.children as CatMeta[], basePath: childRoute.path },
            arr,
          )
        : [{ path: `${childRoute.path}/:id`, element: AdminDetailPage as NonIndexRouteObject }],
    } as unknown as RouteObject;
  });

  return categoryRoutes;
};

// ======================================================================== //

export const getRoutesWithCategories = ({ categories }: { categories: CatMeta[] }): RouteObject[] => {
  const routes: RouteObject[] = genCategoryRouterRoutes({ categories, basePath: 'cat' }, []);

  return [...routes];
};

export const getCategoryRoutes = (routes: RouteConfig[]) => {
  const routesToIterate: RouteConfig[] = (
    routes[0].path === '/' ? routes[0]?.children?.slice(1) : routes
  ) as RouteConfig[];

  const categoryRoutes: RouteConfig[] = routesToIterate.filter((route: RouteConfig) => {
    // TODO: SHOULD BE ABLE TO REMOVE - route?.path.startsWith('cat/')
    return route?.path === 'cat' || route?.path.startsWith('cat/');
  });

  return categoryRoutes;
};

// ======================================================================== //

export const getCategoryOfRoute = ({
  route,
  metaCats,
}: {
  route: RouteConfig | RouteConfig;
  metaCats: any;
}): CatMeta => {
  const pathClean = route.path.replace('/:id', '');
  const isParamPath = !!route.path.endsWith('/:id');

  const matchedCat: CatMeta = isParamPath
    ? metaCats.find((cat: CatMeta) => pathClean.endsWith(`/${cat.slug}`))
    : metaCats.find((cat: CatMeta) => cat.slug === route.slug);

  const category: CatMeta = {
    path: isParamPath ? pathClean : route.path,
    slug: matchedCat?.slug,
    title: matchedCat?.title,
    uuid: matchedCat?.uuid,
  };

  return category;
};
