import { useRef, useEffect } from 'react';
import { getScrollbarWidth } from './scroll.utils';

export const useScrollLock = ({ onBlock, onAllow }: { onBlock?: () => void; onAllow?: () => void } = {}) => {
  const safeDocument = typeof document !== 'undefined' ? document : {};
  const { body } = safeDocument as Document;
  const html = (safeDocument as Document).documentElement;
  const scrollLocked = useRef<boolean>(false);

  // const scrollbarWidth = window.innerWidth - (html?.clientWidth || 0);
  // const bodyPaddingRight = parseInt(window.getComputedStyle(body).getPropertyValue('padding-right')) || 0;

  /**
   * 1. Fixes a bug in iOS and desktop Safari whereby setting
   *    `overflow: hidden` on the html/body does not prevent scrolling.
   * 2. Fixes a bug in desktop Safari where `overflowY` does not prevent
   *    scroll if an `overflow-x` style is also applied to the body.
   */

  const setIsScrollLocked = (isLocked: boolean) => {
    if (isLocked) {
      if (!body || !body.style || scrollLocked.current) return;

      if (html) {
        html.style.position = 'relative';
        html.style.overflow = 'hidden';
      }

      body.style.position = 'relative';
      body.style.overflow = 'hidden';

      scrollLocked.current = true;
    } else {
      if (!body || !body.style || !scrollLocked.current) return;

      if (html) {
        html.style.position = '';
        html.style.overflow = '';
      }

      body.style.position = '';
      body.style.overflow = '';

      scrollLocked.current = false;
    }
  };

  const { scrollbarWidth } = getScrollbarWidth();
  const isScrollLocked = scrollLocked.current;

  useEffect(() => {
    return () => {
      if (isScrollLocked) {
        setIsScrollLocked(false);
      }
    };
  }, []);

  return { isScrollLocked, setIsScrollLocked, scrollbarWidth };
};
