import { INPUTS } from 'types/form.types';
import type { FieldConfig } from 'components/Form';

export const FIELDS_SOCIALS: FieldConfig[] = [
  {
    name: 'url_multi',
    inputType: INPUTS.repeaterURL,
    repeaterConfig: {
      inputType: INPUTS.url,
    },
    colWidth: { xs: 12 },
  },
];
