import { METADATA } from 'types';
import { INPUTS, DEFAULT_VALUE } from 'types/form.types';
import { AUTH_USER } from 'types/form.types';
import type { FormHeroConfig, FieldConfig, FormButton, FormConfig } from 'types/form.types';
import { BUTTONS, types } from 'components/Form/components/FormButtonBar/FormButtonBar.types';
import type { SelectOption } from 'components/FormUI/Select';
import {
  FIELDS_ADDRESS,
  FIELDS_DOCS_BIZ,
  FIELDS_DOCS_PERSONAL,
  FIELDS_PERSONAL_DATA,
  FIELDS_SOCIALS,
  FIELDS_CONTACT_PUBLIC,
  FIELDS_CONTACT_PRIVATE,
  FIELDS_CONFIRM_SIGNATOR,
} from 'config/form.sections';
import { TAGS_COUNTRIES } from 'constants/countries';
import { OPTIONS_LANGUAGES, TAGS_LANGUAGES } from 'constants/languages';
import { SPAIN_PROVINCES } from 'constants/spain';
import { DATATYPE, ENDPOINT } from './periodicos.config';
import { getFieldsetConfig } from 'config/data.utils/getFieldsetConfig';

let i = 0;

const SUBJECT = 'canale';
const SUBJECT_PLURAL = 'canales';

const buttons: FormButton[] = [
  {
    label: 'CANCEL',
    type: types.button,
    action: BUTTONS.CANCEL,
  },
  {
    label: 'SAVE',
    type: types.submit,
    action: BUTTONS.SUBMIT,
  },
];

const hero: FormHeroConfig = {
  heroTitleKey: ['name'],
  buttonRight: {
    isHidden: false, // make explicit 'false', for now.. as other logic handles is also..
    label: 'Volver',
    href: 'registros',
  },
};

// NOTE: FOLLOWING CONFIG WILL BE MERGED WITH form.config.ts/globalFieldProps
// TO POPULATE COMMON PROPS, OPTIONS etc
export const formConfig: FormConfig = {
  dataType: { key: DATATYPE, role: 'form' },
  api: { endpoint: ENDPOINT },
  hero,
  buttons,
  isNavPromptActive: false, // TODO: REMOVE !!!!
  // ----------------------------------------------------------------- //
  sections: [
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Datos del medio principal',
      fields: [
        {
          name: 'name',
          label: 'nombre de medio',
          inputType: INPUTS.text,
          required: true,
        },
        {
          name: 'image',
          placeholder: 'Escoge archivo...',
          hint: 'Imagen pricipal',
          inputType: INPUTS.image,
        },
      ],
    },
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Datos del titular o representante (PERIODICOS)',
      fields: [
        ...getFieldsetConfig(FIELDS_PERSONAL_DATA),
        ...[
          {
            name: 'position',
            label: 'cargo del titular',
            inputType: INPUTS.text,
            required: false,
          },
          ...getFieldsetConfig(FIELDS_DOCS_PERSONAL),
        ].map((fieldConfig) => ({ ...fieldConfig, colWidth: { xs: 12, md: 4 } })),
      ],
    },
    // ======================================================================== //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Datos fiscales del medio',
      fields: [...getFieldsetConfig(FIELDS_DOCS_BIZ)],
    },
    // ======================================================================== //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Dirección fiscal',
      fields: [...getFieldsetConfig(FIELDS_ADDRESS, { prefix: 'biz_' })],
    },
    // ======================================================================== //
    {
      sectionKey: `${ENDPOINT}_${i++}`,

      // NOTE: VERSION A: COPY_FROM
      sectionTitle: 'Dirección postal',
      sectionToggle: {
        name: 'copy_from_user_address',
        // label: `Marcar si la dirección coincide con la del titular : ${AUTH_USER.EMAIL}`,
        label: 'Marcar si la dirección postal NO coincide con la dirección fiscal.',
        inputType: INPUTS.checkbox,
        defaultValue: false,
        params: { endpoint: 'register', uuid: AUTH_USER.UUID },
      },
      fields: [...getFieldsetConfig(FIELDS_ADDRESS)],
    },
    // ======================================================================== //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Datos de contacto públicos',
      description: 'visibles a todo el mundo en la web pública',
      fields: [...getFieldsetConfig(FIELDS_CONTACT_PUBLIC)],
    },

    // ---------------------------------------------------------------------- //

    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Datos de contacto para usuarios registrados',
      description: 'visibles solo por titulares de otros medios o entidades',
      fields: [...getFieldsetConfig(FIELDS_CONTACT_PRIVATE)],
    },
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Datos del medio principal',
      fields: [
        {
          name: 'url',
          placeholder: 'Escoge archivo...',
          inputType: INPUTS.url,
          required: true,
        },
        // TODO:  -- MAKE SELECT DROPDOWN -
        // TODO: USE CURRENT "WEB"
        // TODO: IMPORTANT - FETCH SUB-CATS FROM DB !!!!!
        // {
        //   name: 'cats',
        //   label: 'Categorías',
        //   inputType: INPUTS.select,
        // },
        {
          name: 'sector',
          label: 'Tipo de medio o canal',
          inputType: INPUTS.select,
          options: [
            {
              value: 'canal-de-informacion',
              label: 'Canal de información',
            },
            {
              value: 'periodico-digital',
              label: 'Periódico digital',
            },
            {
              value: 'redes-sociales',
              label: 'Redes Sociales',
            },
          ] as SelectOption[],
          required: false,
          colWidth: {
            xs: 12,
            lg: 6,
          },
        },
        {
          name: 'language',
          label: 'Idioma',
          inputType: INPUTS.select,
          options: OPTIONS_LANGUAGES,
          required: true,
          colWidth: {
            xs: 12,
            lg: 6,
          },
        },
        {
          // TODO: make drodpown
          name: 'geo_range',
          label: 'Alcance: (Indique países con seguimiento)',
          inputType: INPUTS.selectCountry,
          required: true,
          colWidth: {
            xs: 12,
            lg: 6,
          },
        },
        {
          name: 'description',
          label: 'Descripción',
          hint: 'más información',
          inputType: INPUTS.textarea,
          mockValue: 'Lorem ipsum..',
          required: true,
          colWidth: {
            xs: 12,
          },
        },
      ],
    },
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Canales secundarios',
      description: 'Indique canales secundarios o redes sociales',
      fields: [...getFieldsetConfig(FIELDS_SOCIALS)],
    },
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'A devolver firmado:',
      fields: [...getFieldsetConfig(FIELDS_CONFIRM_SIGNATOR)],
    },
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      fields: [
        {
          name: 'cat_id',
          inputType: INPUTS.uuid,
          // defaultValue: DEFAULT_VALUE.cat_id,
          isHidden: true,
        },
      ],
    },
  ],
};
