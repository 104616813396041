import { Col, Row, Hidden } from 'react-grid-system';
import { format } from 'date-fns';
// ICONS
/* eslint-disable import/no-duplicates */
import { GrContact as IconContact1 } from 'react-icons/gr';
import { GrContactInfo as IconFichaV2 } from 'react-icons/gr';
import { MdOutlineEmail as IconContact3 } from 'react-icons/md';
import { MdOutlineEmail as IconEmail } from 'react-icons/md';
import { RiFileList2Line as IconFicha3, RiProfileLine as IconFicha2 } from 'react-icons/ri';
import { TbFileSearch as IconFicha } from 'react-icons/tb';
import type { DataListItemProps } from 'types/tableConfig.types';
// import { TfiEmail as IconContact3 } from 'react-icons/tfi';
import type { ScreenClass } from 'types';
import { useRouter } from 'routes/hooks';
import { useNavigateState } from 'hooks/useNavigateState';
import { DataColumnConfig, ROW_ACTION } from 'components/DataTable/DataTable.types';
import { useDetailConfig } from 'components/DetailSummary/useDetailConfig';
import { Checkbox } from 'components/FormUI/Checkbox';
import { usePagination } from 'components/Pagination';
import { Socials } from 'components/Socials';
import { getImageURL } from 'utils';
import { ArrayJSX } from 'utils/ArrayJSX';
import { getFakerImageURLv3 } from 'mocks/generators/data.images';
import { sizes } from 'styles/media-queries-V1';
import { getItemInternalURL } from './ListItemRow.utils';
import { styles } from './ListItemRow.css';

export const ListItemRow = ({ data, config, single = false, index, isHeader = false }: DataListItemProps) => {
  const { PATH_BASE, params, route } = useRouter();
  // const { navigate } = useNavigateState();
  const { navigate, navigateExternal, state, from, action } = useNavigateState();

  const { toggleID, selectedIDs } = usePagination();
  const imageSrc = getFakerImageURLv3();

  const listItemConfig = config.list?.listItem;
  const columns = config.columns;
  const isEven: boolean = index % 2 == 0;
  const isEntry = !!data.id;

  // const { columns, actions, baseClassName } = config;

  // NOTE: internal path used to navigate to List + Detail routes...
  const internalPathURL = data.path;

  const handleClickCard = async (event?: any) => {
    if (event.target.id === 'list-card') {
      event.preventDefault();
      if (data.url) {
        navigateExternal({ url: data.url });
      } else {
        await navigate(internalPathURL, { state: { action: 'VIEW' } });
      }
    }
    return;
  };

  const handleClickTitle = async (event?: any) => {
    if (event.target.id === 'list-card-title') {
      event.preventDefault();
      // await navigate(internalPathURL, { state: { action: 'VIEW' } });
    }
    return;
  };

  // CSS CLASSES
  const bgImage = imageSrc ? { backgroundImage: `url(${imageSrc})` } : null;
  const cssClasses = new ArrayJSX('card');
  cssClasses.push(`item-${isEven ? 'even' : 'odd'}`);
  isHeader ? cssClasses.push('header-item') : cssClasses.push('list-item');
  single && cssClasses.push('single');

  // const { sections, values } = useDetailConfig({ detailConfig: config, data }); // TODO: schema needed as prop ?? don't think so...
  // log('VALUES >>', 'lime', { sections, values });

  // ======================================================================== //

  const pathname = location.pathname;
  const isAdminPath = !!pathname.startsWith('/admin');
  const isCatPath = !!location.pathname.startsWith('/cat/');
  const isExternalURL = !!data?.url?.startsWith('http');

  const handleClick = () => {
    if (route?.path) {
      navigate(`/${route?.path}/view/${data.id}`, { state: { action: 'VIEW' } });
      return;
    }
    if (data.basePath) {
      navigate(`/${data.basePath}/view/${data.id}`, { state: { action: 'VIEW' } });
      return;
    }
    if (data.path) {
      navigate(data.path);
      return;
    }
    if (isExternalURL) {
      navigateExternal({ url: data.url });
      return;
    }
    if (isCatPath) {
      const state = {};
      !isAdminPath && Object.assign(state, { action: 'VIEW' });
      data?.path && navigate(data.path, { state });
      return;
    }
    navigate(data.url);
  };

  const setSelectedItems = (value: unknown) => {
    // setValue(field.name, value, optionsSetLoud);
    log('SELECTED:', 'magenta', value);
  };

  return (
    <div css={styles} onClick={handleClickCard} className={cssClasses.inline()}>
      <Row align="center" style={{ width: '100%', height: '100%', alignItems: 'stretch' }} className="row">
        {/* ---------------------------------------------------------------- */}

        {/* ---------------------------------------------------------------- */}
        {/* ----------------- IMAGE + DATA COLUMNS ----------------------------------------------------------------- */}
        {columns.map((column: DataColumnConfig, indexCol: number) => {
          // DETERMINE WIDTH: SET or RESPONSIVE /////////////////////////
          const widths = {};
          const hidden = {};
          if (column.widths) {
            let widthValue = Object.values(column.widths)[0];
            // SET ALL AVAIL BREAKPOINTS, EVEN IF UNSET IN CONFIG
            // RULE: SKIPPED KEYS TAKE THE VALUE OF LAST SET
            // ACTING AS A min-width MEDIA-QUERY
            for (const size of sizes) {
              widthValue = column.widths[size as ScreenClass] ?? widthValue;
              widthValue === 0
                ? Object.assign(hidden, { [size]: true })
                : Object.assign(widths, { [size]: widthValue });
            }
          } else if (column.width) {
            // OPTIONAL 'width' KeyboardEvent, IF NO RESPONSIVE CHANGE REQUIRED
            Object.assign(widths, { xs: column.width || 0 });
            column.width === 0 && Object.assign(hidden, { xs: true });
          } else {
            // LEAVE widths{} EMPTY = FLEXIBLE
          }

          /*
        } else if (column.width) {
          // OPTIONAL 'width' KeyboardEvent, IF NO RESPONSIVE CHANGE REQUIRED
          Object.assign(widths, { xs: column.width || 0 });
          column.width === 0 && Object.assign(hidden, { xs: true });
        } else {

          /*
          else {
            // OPTIONAL 'width' KeyboardEvent, IF NO RESPONSIVE CHANGE REQUIRED
            Object.assign(widths, { xs: column.width || 0 });
            column.width === 0 && Object.assign(hidden, { xs: true });
          }
          */

          if (isHeader) {
            return (
              <Hidden {...hidden} key={indexCol}>
                <Col {...widths} className={`th col col-${column.name || column.action}`}>
                  {column.label || column.name || column.action}
                </Col>
              </Hidden>
            );
          }

          if (column?.action === ROW_ACTION.SELECT) {
            return (
              <Hidden {...hidden} key={indexCol}>
                <Col {...widths} className={`td col col-action col-${column.name || column.action}`}>
                  <Checkbox
                    name="isNav"
                    label="Active"
                    defaultValue={!!selectedIDs?.includes(String(data.id))}
                    onChange={() => toggleID(String(data.id))}
                  />
                </Col>
              </Hidden>
            );
          }

          if (column?.action === ROW_ACTION.CONTACT) {
            return (
              <Hidden {...hidden} key={indexCol}>
                <Col {...widths} className={`td col col-action col-${column.name || column.action}`}>
                  <a href={`mailto:${data.email}`} target="_blank" className="value-email" rel="noreferrer">
                    <IconEmail
                      id={`view-${data.id}`}
                      className={`icon-action icon-action-${ROW_ACTION.CONTACT}`}
                    />
                  </a>
                </Col>
              </Hidden>
            );
          }

          if (column?.action === ROW_ACTION.VIEW) {
            return (
              <Hidden {...hidden} key={indexCol}>
                <Col {...widths} className={`td col col-action col-${column.name || column.action}`}>
                  <IconFichaV2
                    id={`view-${data.id}`}
                    className={`icon-action icon-action-${ROW_ACTION.VIEW}`}
                    onClick={handleClick}
                    // onClick={() => navigate(data.path, { state: { action: 'VIEW' } })}
                    // onClick={() =>
                    //   navigate('/admin/cat/periodicos/periodico-ejemplo-dos/edit/23', { state: { action: 'EDIT' } })
                    // }
                  />
                </Col>
              </Hidden>
            );
          }

          if (data.date) {
            data.date = format(new Date(data.date), 'yyyy-MM-dd');
          }

          if (column?.render) {
            return (
              <Hidden {...hidden} key={indexCol}>
                <Col {...widths} className={`td col col-${column.name}`}>
                  <span>{column.render({ data })}</span>
                </Col>
              </Hidden>
            );
          }

          return (
            <Hidden {...hidden} key={indexCol}>
              <Col {...widths} className={`td col col-${column.name}`}>
                <span>{data[column.name]}</span>
              </Col>
            </Hidden>
          );
        })}
        {/* <Row className="row">
              <Col xs={12} md={5} className="col col-right">

                  <div className="contact-row">
                    <Link target="_blank" to={data.url} rel="noopener noreferrer">
                      {urlPrettify(data.url)}
                    </Link>
                  </div>
                  {['email', 'tel'].map((key) => (
                    <div key={key} className="info-row">
                      {data[key]}
                    </div>
                  ))}

              </Col>
            </Row> */}
      </Row>
    </div>
  );
};
