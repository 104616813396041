import type { FilterDateSelected, FilterLocation } from '../EventFiltersContext';

interface ResetFilters {
  DATE_SELECTED: FilterDateSelected;
  LOCATIONS: FilterLocation;
}

export const RESET: ResetFilters = {
  DATE_SELECTED: {
    value: '',
    localized: '',
    datetime: { startDate: '', endDate: '' },
    isActive: false,
  },
  LOCATIONS: { value: '', label: '', isActive: false },
};

// TODO: NEEDED ??
// POSSIBLY REDUCES CLARITY FOR LOGIC THAT IS COMPLEX
export const FILTERS = {
  DATE_RANGE: 'dateRange',
  DATE_SELECTED: 'dateSelected',
  PROVINCE: 'province',
  SEARCH_QUERY: 'searchQuery',
} as const;
