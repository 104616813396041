import { AUTH_USER } from 'types/form.types';
import { USER_ROLE } from 'auth/types/user.types';
import { types, BUTTONS, INPUTS } from 'components/Form';
import type { FormConfig, FormButton } from 'components/Form';
import { FIELDS_REGISTER_CONFIRM } from 'config/form.sections/register.fieldset';
import { FIELDS_PERSONAL_DATA } from 'config/form.sections/personal.fieldset';
import { FIELDS_ADDRESS } from 'config/form.sections/address.fieldset';
import { FIELDS_DOCS_PERSONAL } from 'config/form.sections/documents.fieldset';
import { DATATYPE, ENDPOINT } from './register.config';
import { getFieldsetConfig } from 'config/data.utils/getFieldsetConfig';

let i = 0;

const buttons: FormButton[] = [
  {
    type: types.button,
    action: BUTTONS.LATER,
  },
  {
    type: types.button,
    action: BUTTONS.UPDATE,
    isHidden: true,
  },
  {
    type: types.submit,
    label: 't.buttons.save',
    action: BUTTONS.SUBMIT,
  },
];

export const formConfig: FormConfig = {
  dataType: { key: DATATYPE, role: 'form' },
  api: { endpoint: ENDPOINT, omitFields: ['is_validated'] },
  isNewEntry: false,
  isNavPromptActive: true,
  buttons,
  sections: [
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Datos personales',
      fields: [
        ...getFieldsetConfig(FIELDS_PERSONAL_DATA).map((field) => {
          if (field.name === 'email') field.readOnly = true;
          return field;
        }),
        // ...getFieldsetConfig(FIELDS_PERSONAL_DATA),
        ...getFieldsetConfig(FIELDS_DOCS_PERSONAL),
      ],
    },
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Dirección',
      description: 'Opcionalmente puede utilizar como dirección de registro la de su medio o entidad.',
      fields: [...getFieldsetConfig(FIELDS_ADDRESS)],
    },
    {
      // ---------------------------------------------------------------------- //
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: ' ',
      // TODO: (below erroneous) - FIX so that section
      // isHidden: !!(AUTH_USER.IS_REGISTERED || location.pathname.startsWith('/account')),
      fields: [...FIELDS_REGISTER_CONFIRM],
    },
    // ---------------------------------------------------------------------- //

    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'TEST - is_validated',
      isHidden: true,
      fields: [
        {
          name: 'id',
          label: 'ID',
          inputType: INPUTS.number, // NOTE: do NOT make INPUTS.uuid
          required: false,
          readOnly: true,
          isHidden: false,
          colWidth: { xs: 4 },
        },
        {
          name: 'uuid',
          label: 'REGISTERED_UUID',
          inputType: INPUTS.text, // NOTE: do NOT make INPUTS.uuid
          isHidden: false,
          readOnly: true,
          required: false,
          colWidth: { xs: 4 },
        },
        {
          name: 'is_validated',
          label: 'IS_VALIDATED',
          inputType: INPUTS.checkbox,
          isHidden: true,
          defaultValue: AUTH_USER.IS_VALIDATED,
          colWidth: { xs: 4 },
        },
      ],
    },
  ],
};
