import { Row, Col } from 'react-grid-system';
import { useEffect, useState } from 'react';
import { MdAddCircleOutline as IconNew } from 'react-icons/md';
import { ISchema, DataEntry } from 'types';
import { useRouter } from 'routes/hooks';
import { useQueryGet } from 'api';
import { useAuth } from 'auth/AuthContext';
import { useGlobal } from 'store/useGlobalContext';
import { ButtonLink } from 'components/Button';
import { Form } from 'components/Form';
import type { FormConfig } from 'types/form.types';
import { PageHero } from 'components/PageHero';
import { RegisterToolbar } from 'components/RegisterToolbar';
import { Spinner } from 'components/Spinner';
import { initDetailData } from 'config/data.utils/initDetailData';
import { formConfig as defailFormConfig } from 'config/datatype/entidades/entidades.form';
import { styles } from './AccountPage.css';
import { usePageContent, usePageContentActions } from 'pages/Layout/PageContentContext/PageContent.hooks';

export type AdminUserAccountPageProps = {
  endpoint?: string;
  formConfig?: FormConfig;
  data?: DataEntry;
  schema?: ISchema;
  isLocked?: boolean;
  isNavPromptActive?: boolean;
  heroTitleKey?: string | string[];
};

const AccountPage = ({ ...config }: AdminUserAccountPageProps) => {
  log('ACCOUNT', 'red', config);
  const { user } = useAuth();
  const { params, route, slug, basePath, PATH_BASE } = useRouter();
  const { pageHero, setPageHero } = useGlobal('pageHero');
  const { metaRoutes } = useGlobal('metaRoutes');
  const [data, setData] = useState(null);
  const [schema, setSchema] = useState<ISchema>();
  const [formConfig, setFormConfig] = useState<FormConfig>(config?.formConfig || defailFormConfig);
  const isNewEntry = !!params?.new;

  const { title, detail, subtitle, headerButton } = usePageContent();
  const { resetHeader, setPageTitle, setPageDetail, setPageSubtitle, setPageHeaderButton } =
    usePageContentActions();

  const {
    data: response,
    isFetching,
    isSuccess,
  } = useQueryGet({
    endpoint: 'registration',
    query: { json_meta: { $like: `%${user.uuid}%` } },
    filter: { $limit: 1 },
  });

  useEffect(() => {
    if (response && isSuccess) {
      try {
        // PARSED V1:
        // const dataParsed = JSO N.parse(response.data.json_meta);

        // PARSED V2:
        const dataParsed = initDetailData({ data: response });
        setData(dataParsed as any);
        setSchema(response.schema);
        setPageHero({ ...pageHero, title: `${dataParsed.name} ${dataParsed.name_last}`, values: dataParsed });

        const _route = metaRoutes.find((r) => r.pathname === location.pathname);
        log('>>>>> ROUTE:', 'red', _route);
        setPageTitle(_route.title);
      } catch (err) {
        console.error(err);
      }
    }
  }, [response]);

  if (isFetching) return <Spinner page />;

  const isReady = !isFetching && response && schema;

  //   Registre su medio o entidad principal:
  // - Registrar: Medio o canal (F1)
  // - Periódico digital (F1)
  // - Entidad o asociación. (F2)

  return (
    <section css={styles}>
      <PageHero />
      <Row>
        <Col xs={12} md={8} className="col col-buttons-create">
          {/* <RegisterToolbar /> */}
        </Col>
        <Col xs={12} md={4}>
          {isReady && <pre style={{ overflowX: 'hidden' }}>{JSON.stringify(data, null, 2)}</pre>}
        </Col>
        {/* <Col xs={12}>{isReady && <Form formConfig={formConfig} data={data} schema={schema} />}</Col> */}
      </Row>
    </section>
  );
};

export default AccountPage;
