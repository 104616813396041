import { ReactElement } from 'react';
import { css } from '@emotion/react';
import { ArrayJSX } from 'utils/ArrayJSX';
import { ButtonProps } from './Button.types';
import { getColorsByVariant, getStylesByIconScale } from './getButtonStyles';
import { styles } from './Button.css';

const Button = ({
  type = 'button',
  variant = 'solid',
  color = 'primaryDark',
  colorHover = color,
  isDisabled = false,
  label,
  icon,
  iconPos = 'left',
  iconScale = 1.5,
  size = 'md',
  isBusy,
  // onClick,
  padded: isPadded = true,
  className,
  children,
  ...props
}: ButtonProps): ReactElement => {
  // DIMENSIONS, PADDING ETC...
  const hasLabel = !!label;
  const hasIcon = !!icon;
  const isIconOnly: boolean = hasIcon && !hasLabel;

  // CSS CLASSES
  const cssClasses = new ArrayJSX('btn');
  cssClasses.push(`variant-${variant}`);
  cssClasses.push(`size-${size}`);
  className && cssClasses.push(className);

  if (isIconOnly) {
    cssClasses.push('icon-only');
  } else {
    iconPos && cssClasses.push(iconPos);
    isPadded && cssClasses.push('btn-padded');
  }

  const buttonContent = isIconOnly ? (
    icon
  ) : (
    <>
      {icon && (!iconPos || iconPos === 'left') && icon}
      {label && <span>{label}</span>}
      {icon && iconPos === 'right' && icon}
    </>
  );

  // GENERATE SMART SYLES BY BUTTON PROPS
  const stylesByProps = css`
    ${styles}
    ${getColorsByVariant({ color, colorHover, variant })}
    ${getStylesByIconScale({ iconScale })}
  `;

  return (
    <button
      type={type}
      aria-label={label}
      className={cssClasses.inline()}
      css={stylesByProps}
      disabled={isDisabled}
      {...props}
    >
      {children ?? buttonContent}
    </button>
  );
};

export { Button };
