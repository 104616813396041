import type { TableConfig } from 'types';
import { COL_TYPE, type DataColumnConfig } from 'components/DataTable/DataTable.types';
import { LinkToDetail } from 'components/DataTable/components/LinkToDetail';
import { EventCard } from 'components/EventCard';
import { DATATYPE, ENDPOINT } from './eventos.config';

// ----------------------------------------------------------------- //
// TABLE CONFIG:
export const tableConfig: TableConfig = {
  dataType: {
    key: DATATYPE,
    role: 'table',
  },
  api: {
    endpoint: ENDPOINT,
  },
  list: {
    colWidth: {
      xs: 12,
    },
    maxRows: 6,
    listItem: {
      component: EventCard,
      // shapeRatio: 0.1,
      minHeight: '192px',
    },
  },
  actions: {
    hasActionView: true,
    hasActionEdit: true,
    hasActionNew: true,
  },
  columns: [
    {
      label: 'NAME',
      name: 'name',
      columnType: COL_TYPE.DATA,
      isSortable: true,
      render: ({ data }) => <LinkToDetail targetID={data.id} label={data.name} />,
    },
    {
      label: 'DATE',
      name: 'date',
      columnType: COL_TYPE.DATA,
      // width: 2,
      widths: {
        xs: 1,
        lg: 2,
      },
      isSortable: true,
      render: ({ data }) => data.date,
    },
    {
      label: 'PROVINCE',
      name: 'province',
      columnType: COL_TYPE.DATA,
      // width: 2,
      widths: {
        xs: 1,
        lg: 2,
      },
      isSortable: true,
      render: ({ data }) => <LinkToDetail targetID={data.id} label={data.province} />,
    },
    {
      label: 'CITY',
      name: 'city',
      columnType: COL_TYPE.DATA,
      // width: 2,
      widths: {
        xs: 0,
        lg: 2,
      },
      isSortable: true,
      render: ({ data }) => <LinkToDetail targetID={data.id} label={data.city} />,
    },
    {
      label: 'ID',
      name: 'id',
      columnType: COL_TYPE.DATA,
      // width: 2,
      widths: {
        xs: 0,
        lg: 1,
      },
      isSortable: true,
      render: ({ data }) => data.id,
    },
  ],
};
