import { INPUTS } from 'types/form.types';
import type { ColumnSizes } from 'types/viewport.types';
import type { FieldConfig } from 'components/Form';
import type { SelectOption } from 'components/FormUI/Select';

export const defaultColumnSizes: ColumnSizes = {
  xs: 12,
  sm: 12,
  md: 6,
  lg: 6,
  xl: 6,
  xxl: 6,
  // xxxl: 6,
};

// ===== CONFIG + ATTR SCHEMAS ============================================== //

// ADDITIONAL DEFAULTS
export const defaultFieldProps = {
  // BASIC
  name: undefined,
  label: undefined,
  value: undefined,
  default: null,
  inputType: undefined,
  component: null,
  placeholder: null,
  pattern: null,
  autoComplete: 'off',
  disabled: false,
  readOnly: false,
  // UI
  isHidden: false,
  colWidth: {
    ...defaultColumnSizes,
  },
  // SAME AND/OR SIMLIAR TO REACT-HOOK-FORM
  formnovalidate: false,
  required: false,
  inputValidation: null,
  // ADDED BY JOI ??
  // ...
};

// ===== GLOBAL CORE CONFIGS ============================================== //

export const globalFieldProps: Record<string, Partial<FieldConfig>> = {
  id: {
    isHidden: true,
  },
  uuid: {
    inputType: INPUTS.uuid,
    readOnly: true,
    // isHidden: true,
  },
  name: {
    inputType: INPUTS.text,
    isHidden: false,
    required: true,
  },
  name_last: {
    label: 'apellidos',
    inputType: INPUTS.text,
    required: false,
  },
  image: {
    required: true,
  },
  email: {
    name: 'email',
    label: 'email',
    hint: 'correo electrónico',
    placeholder: 'correo@website.com',
    inputType: INPUTS.email,
  },
  tel: {
    label: 'teléfono de contacto',
    placeholder: '+34 123-456-789',
    inputType: INPUTS.text,
  },
  url: {
    inputType: INPUTS.url,
    placeholder: 'https://',
  },
  socials: {
    inputType: INPUTS.socials,
    label: 'Redes Sociales',
    hint: 'cambiar la red social especificada clicando el icono',
    colWidth: {
      xs: 12,
    },
  },
  image_id: {
    required: false,
    isHidden: true,
  },
  cat_id: {
    label: 'Categoría',
    isHidden: false,
    readOnly: false,
  },
  company_name: {
    inputType: INPUTS.text,
  },

  // ============================================================ //

  address: {
    label: 'dirección',
    inputType: INPUTS.text,
  },
  address_0: {
    label: 'dirección 1',
    // hint: 'calle + edificio',
    inputType: INPUTS.text,
  },
  address_1: {
    label: 'dirección 2',
    // hint: 'puerta',
    inputType: INPUTS.text,
  },
  postal_code: {
    label: 'código postal',
    inputType: INPUTS.text,
  },
  city: {
    label: 'población',
    inputType: INPUTS.text,
  },
  // country: {
  //   label: 'país',
  //   inputType: INPUTS.selectCountry,
  // },
  /*
  province: {
    label: 'província',
    hint: 'Provincia de España',
    // inputType: INPUTS.select,
    inputType: INPUTS.select,
    options: SPAIN_PROVINCES,
    order: 11,
  },
*/
  document_type: {
    label: 'tipo de documento',
    inputType: INPUTS.select,
    options: [
      {
        value: 'DNI',
        label: 'DNI',
      },
      {
        value: 'NIE',
        label: 'NIE',
      },
      {
        value: 'Pasaporte',
        label: 'Pasaporte',
      },
    ] as SelectOption[],
  },
  document_number: {
    label: 'número de documento',
    inputType: INPUTS.text,
  },

  // ============================================================ //

  json_meta: {
    label: 'JSON_META',
    isHidden: false,
    inputType: INPUTS.JSON_META,
    colWidth: {
      xs: 12,
    },
  },
  is_active: {
    inputType: INPUTS.checkbox,
    readOnly: true,
    isHidden: false,
    value: true,
  },
  user_id: {
    isHidden: true,
    readOnly: true,
  },
  created_at: {
    inputType: INPUTS.text,
    isHidden: true,
    readOnly: true,
  },
  updated_at: {
    inputType: INPUTS.text,
    isHidden: true,
    readOnly: true,
  },
};

// ===== GLOBAL CORE CONFIGS ============================================== //

export const placeholders: Record<string, Partial<FieldConfig>> = {
  id: {
    isHidden: true,
  },
  uuid: {
    inputType: INPUTS.uuid,
    readOnly: true,
    // isHidden: true,
  },
  name: {
    inputType: INPUTS.text,
    isHidden: false,
    required: true,
  },
};

// ===== GLOBAL CORE CONFIGS ============================================== //

// IGNORE WHEN SUBMITTING DATA
export const submitFieldsBlacklist = ['json_meta', 'created_at', 'updated_at'];
export const submitBlacklist = [
  ...submitFieldsBlacklist,
  'HEADER_IMAGE_PREVIEW',
  'image__PLACEHOLDER',
  'image__FILENAME',
  'TEST',
];

// IGNORE WHEN GENERATING SCHEMA BASED ON SCHEMA via API
export const schemaKeysBlacklist = ['image', 'logo'];

// ===== FIELD-SORT PRECEDENCE ============================================== //

export const SORT_BY_KEYS: string[] = [
  // 'order',
  // 'json_meta',
  // 'name',
  // 'name_last',
  // 'image',
  // 'email',
  // 'tel',
  // 'cat_id',
  // 'city',
  // 'country',
  // 'province',
];
