/* eslint-disable @typescript-eslint/no-shadow */
import { Row, Col } from 'react-grid-system';
import { useFormContext } from 'react-hook-form';
import type { UseFormSetValue, FieldValues, UseFieldArrayReturn } from 'react-hook-form';
import { useRef, type ChangeEvent, type KeyboardEventHandler } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';

// ICONS + STYLES
import { FiEdit3 as IconEdit } from 'react-icons/fi';
import { IoMdAdd as IconAddNew } from 'react-icons/io';
import { RiDeleteBin6Line as IconDelete } from 'react-icons/ri';
import type { IGeneric } from 'types';
import type { IConfigRepeater } from 'types/form.types';
import { Button } from 'components/Button';
import { INPUTS } from 'components/Form';
import { patterns, validationsBasic } from 'components/Form/config';
import { colors } from 'styles/colors.css';
import { IRepeaterItem } from './InputRepeater.types';
import { useFormItem, useFormParent, useConfigValidation } from './utils';

export const FieldItem = ({ index, item, fieldKey: FIELD_KEY, fieldArray, repeaterConfig }: IRepeaterItem) => {
  // SOURCE: https://codesandbox.io/s/github/react-dnd/react-dnd/tree/gh-pages/examples_js/04-sortable/simple?from-embed=&file=/src/Card.jsx
  const ref = useRef(null);
  const { uuid, dndID, name } = item;
  const isFocussed = !!((document as any)?.activeElement.id === uuid);

  const isEmpty = !(item.name?.length >= 3);

  // MY CARD ITEM LOGIC ===================================================== //

  const formMethods = useFormContext();
  const {
    register,
    formState,
    formState: { dirtyFields, errors },
    // NEW DEV:
    getValues,
  } = formMethods;

  const field = fieldArray.fields.find((field: IGeneric) => field.uuid === uuid);

  // log(`FIELD_EMPTY: ${FIELD_KEY} - NAME`, 'lime', field);

  // ITEM FORM LOGIC ================================================================================= //

  const { handleAppend, handleRemove } = useFormParent({ formMethods, fieldArray });
  const { onClickEdit, handleKeyDown, handleFocus, handleBlur, isValid, isReadOnly } = useFormItem({
    FIELD_KEY,
    index,
    uuid,
    isFocussed,
    isEmpty,
    formMethods,
    fieldArray,
  });

  const isLastEmpty = false;

  // const matched = FIELD_KEY.match(/(.+?(?=\[\d{1,2}\]))/);
  const matched = FIELD_KEY.match(/(.+?(\[\d{1,2}\]))/);

  const placeholder =
    repeaterConfig?.placeholder === ''
      ? ''
      : repeaterConfig?.placeholder || patterns[repeaterConfig?.inputType]?.placeholder || repeaterConfig?.inputType;

  const { inputType, inputValidation } = useConfigValidation({ repeaterConfig });

  return (
    <div ref={ref} className="card-item">
      <Row direction="row" align="stretch">
        <Col xs={11} className="col col-name">
          <fieldset className={'fieldset-name'}>
            <input
              {...register(`${FIELD_KEY}.name`, { ...inputValidation })}
              value={getValues(`${FIELD_KEY}.name`)}
              type={inputType}
              id={uuid}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown as unknown as KeyboardEventHandler<HTMLInputElement>}
              aria-invalid={!isValid}
              aria-labelledby={`field__${FIELD_KEY}`}
              data-type="name"
              placeholder={placeholder}
              readOnly={isReadOnly}
              className={'input-name'}
              required={false}
            />
            <Button
              variant="icon"
              icon={<IconDelete />}
              iconScale={1.25}
              onClick={() => handleRemove(index)}
              colorHover={colors.danger}
              className="btn-remove"
            />
          </fieldset>
        </Col>
        <Col xs={1} className="col col-add">
          <Button
            variant={'icon'}
            icon={<IconAddNew />}
            iconScale={1.4}
            onClick={handleAppend}
            color={colors.successDark}
            colorHover={colors.successXDark}
            className={'btn-add'}
            isDisabled={isLastEmpty}
          />
        </Col>
      </Row>
    </div>
  );
};
