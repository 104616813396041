import type { AuthenticationParams } from '@feathersjs/authentication';
import type { AuthContextValues } from './AuthContext.types';
import type { AuthUser } from './types/user.types';
import { DB_AUTH_SERVICE } from './client/client.config';

// NOTE: this `authentication` block should match API settings in: /config/default.json

export const API_AUHTENTICATION_CONFIG: { authentication: AuthenticationParams } = {
  authentication: {
    ...DB_AUTH_SERVICE,
    secret: 'VL59H5Mz4td/nR/oxTumJrwCKOgeltEZ',
    authStrategies: ['jwt', 'local'],
    jwtOptions: {
      // `JwtHeader`
      header: {
        alg: 'HS256',
        typ: 'access',
      },
      algorithm: 'HS256',
      expiresIn: '1d',
      // audience: 'https://yourdomain.com', // The resource server where the token is processed
      issuer: 'feathers', // The issuing server, application or resource
    },
    local: {
      usernameField: 'email',
      passwordField: 'password',
    },
    oauth: {
      github: {
        key: '<Client ID>',
        secret: '<Client secret>',
      },
    },
  },
};

export const initialState: AuthContextValues = {
  user: null as unknown as AuthUser,
  isAuthenticated: false,
  isAdminUser: false,
  handleSubmit: (_formData: unknown) => ({}),
  registerNewUser: (_formData: unknown) => ({}),
  login: (_formData: unknown) => ({}),
  logout: () => ({}),
  error: null,
  setError: () => ({}),
  isAuthorizing: false,
  setIsAuthorizing: () => ({}),
};
