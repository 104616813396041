import { Navigate, Route, Routes } from 'react-router-dom';
import type { ReactElement, ReactNode } from 'react';
import { useAuth } from 'auth/AuthContext';
import { config } from 'config';
import { isAdminPath, isUserPath } from 'routes';

export const AccessLayer = ({ children }: { children: ReactNode }): ReactElement => {
  const { user } = useAuth();
  const isRedirectToLogin = !user && (isUserPath() || isAdminPath());

  if (isRedirectToLogin) {
    const PATH_BASE = isAdminPath() ? config.PATH_BASE_ADMIN : config.PATH_BASE_WEB;
    return <Navigate to={`${PATH_BASE}/login`} />;
  }

  return <>{children}</>;
};
