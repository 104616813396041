import { Col, Row, Visible } from 'react-grid-system';
import { ReactElement } from 'react';
import { useLayout } from 'pages/Layout/context/LayoutContext';
import { Hamburger } from 'components/Hamburger/Hamburger';
import { NavVertical } from 'components/Nav';
import { UserApnaes } from 'components/UserApnaes';
import { UserCurrent } from 'components/UserCurrent';
import { BREAKPOINTS } from 'styles/breakpoints';
import { styles } from './Sidebar.css';

// NEW: CSS-Only SIDEBAR/DRAWER
// https://csslayout.io/sidebar/
// https://csslayout.io/drawer/

export const Sidebar = (): ReactElement => {
  const { winWidth, isSidebarOpen, setLayoutState } = useLayout();

  const closeSidebar = () => setLayoutState({ isSidebarOpen: false });

  const handleClickOutside = () => {
    closeSidebar();
    setLayoutState({ isScrollLocked: false });
  };

  if (isSidebarOpen && winWidth && winWidth > BREAKPOINTS.lg) closeSidebar();

  return (
    <div css={styles} className={isSidebarOpen ? 'sidebar-open' : ''}>
      <div className="sidebar-overlay" onClick={handleClickOutside} />
      <aside id="sidebar">
        <header>
          <Row align="center">
            <Visible xs>
              <Col xs={1} sm={0} className="icon icon-burger">
                <Hamburger />
              </Col>
            </Visible>
            <Col xs={10} sm={12} className="header-main">
              <h2>Your Listed Cities</h2>
            </Col>
          </Row>
        </header>
        <nav>
          <Row>
            <Col xs={12}>
              <NavVertical />
            </Col>
          </Row>
        </nav>
        <footer>
          {/* <UserCurrent /> */}
          <UserCurrent />
        </footer>
      </aside>
    </div>
  );
};
