import { INPUTS } from 'types/form.types';
import type { FieldConfig } from 'components/Form';

export const FIELDS_PERSONAL_DATA: FieldConfig[] = [
  {
    name: 'name_first',
    label: 'nombre',
    inputType: INPUTS.text,
    required: true,
  },
  {
    name: 'name_last',
    label: 'apellidos',
    inputType: INPUTS.text,
    required: true,
  },
  {
    name: 'email',
    label: 'correo electrónico',
    hint: 'email principal',
    inputType: INPUTS.email,
    required: true,
    readOnly: false,
  },
  {
    name: 'tel',
    label: 'teléfono de contacto',
    hint: 'móvil o fijo principal',
    inputType: INPUTS.tel,
    required: true,
  },
];
