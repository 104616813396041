import type { FieldConfig, IConfigRepeater, PatternValidator } from 'components/Form';
import { patterns, validationsBasic } from 'components/Form/config';

export const useConfigValidation = ({
  repeaterConfig /* , pattern */,
}: {
  repeaterConfig: IConfigRepeater /* pattern: PatternValidator; */;
}) => {
  const inputValidation = {};
  let inputType = 'text';

  if (repeaterConfig) {
    inputType = repeaterConfig?.inputType || 'text';
    const patternKeys = Object.keys(patterns);
    if (patternKeys.includes(inputType)) {
      const pattern = patterns[inputType];
      // const pattern = configSocials[3].validation;
      Object.assign(inputValidation, { pattern });
    }
  }

  // INCLUDE minLength VALIDATION - AT END
  Object.assign(inputValidation, { minLength: validationsBasic.minLength });

  return {
    inputType,
    inputValidation,
  };
};
