import type { DataFilter, DateRange } from 'types';

// TODO: MOVE TO FiltersContext ???
// export type FilterDateSelected = DataFilter<string | undefined> & { localized: string | undefined };
export type FilterDateSelected = DataFilter<string | undefined> & {
  datetime: DateRange<string>;
  localized: string | undefined;
};

export type FilterLocation = DataFilter<string | undefined> & { label: string | undefined };

// ======================================================================== //
// STATE DEFINITION (SPECIFIC):

export interface ContextActions {
  setFilters: (value: unknown) => void;
}

export interface EventFiltersState extends ContextActions {
  searchQuery: DataFilter<string | undefined>;
  dateRange: DateRange<string>;
  dateSelected: FilterDateSelected;
  province: FilterLocation;
}

// ======================================================================== //
// INIT CONTEXT: ContextState.ts

export const initialState: EventFiltersState = {
  setFilters: () => ({}),
  searchQuery: {
    value: undefined,
    isActive: false,
  },
  dateRange: { startDate: '', endDate: '' },
  dateSelected: {
    value: undefined,
    localized: undefined,
    datetime: { startDate: '', endDate: '' },
    isActive: false,
  },
  province: {
    value: undefined,
    label: undefined,
    isActive: false,
  },
};
