import { useContext } from 'react';
import { useStore, type StoreApi } from 'zustand';
import type { NavItemStore } from './NavItems.types';
import { NavItems } from './NavItemsContext';

export const useNavItemSubscriptions = () => {
  const store = useContext(NavItems.Context);
  if (!store) {
    throw new Error('useNavItemActions must be used within a NavItemProvider');
  }
  store.subscribe((state) => {
    // log('__PAGE_CHANGE - EVENT', 'cyan', state);
  });
  return;
};

export const useNavItemsActions = (): NavItemStore['actions'] => {
  const store = useContext(NavItems.Context);
  if (!store) {
    throw new Error('useNavItemActions must be used within a NavItemProvider');
  }
  return useStore<StoreApi<NavItemStore>, NavItemStore['actions']>(store, (state) => state.actions);
};

export const useNavItems = (): Omit<NavItemStore, 'actions'> => {
  const store = NavItems.useContext();
  if (!store) {
    throw new Error('useNavItem must be used within a NavItemProvider');
  }
  return useStore<StoreApi<NavItemStore>, Omit<NavItemStore, 'actions'>>(store, ({ actions, ...state }) => ({
    ...state,
  }));
};
