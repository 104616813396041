import { Row, Col } from 'react-grid-system';
import { useFormContext } from 'react-hook-form';
import type { FieldValues } from 'react-hook-form';
import { ReactElement, useState, useEffect } from 'react';
import { USER_ROLE } from 'auth/types/user.types';
import { useAuth } from 'auth/AuthContext';
import { optionsSetQuiet, useFormConfigContext } from 'components/Form';
import type { FieldConfig } from 'components/Form';
import { styles } from './FormCompletion.css';
import { useTranslation } from 'react-i18next';

export const FormCompletion = ({ field }: { field: FieldConfig }): ReactElement => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const IGNORE_FIELDS = [
    'FORM_COMPLETION_PERCENTAGE',
    'IS_FORM_SUBMIT_LOCKED',
    'accept_terms',
    'accept_privacy',
    'is_validated',
  ];
  const [percentageComplete, setPercentageComplete] = useState(0);
  const { fields, isLocked, setFormConfigState } = useFormConfigContext();
  const { register, watch, setValue, formState } = useFormContext();
  const values = watch();

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const getPercentageComplete = ({ values }: { values: FieldValues }): number => {
    const requiredFieldConfigs = fields.filter((fieldConfig: FieldConfig) => {
      return !IGNORE_FIELDS.includes(fieldConfig.name) && fieldConfig?.inputValidation?.required;
    });

    const requiredFieldsNames = requiredFieldConfigs.map((fieldConfig: FieldConfig) => fieldConfig.name);
    const numCompleted = Object.entries(values).filter(([key, value]) => {
      return (
        requiredFieldsNames.includes(key) &&
        ![null, undefined, ''].includes(value) &&
        !(Array.isArray(value) && value.length === 0)
      );
    });

    const numRequiredTotal = requiredFieldConfigs.length;
    const numRequiredCompleted = numCompleted.length;
    const PERCENTAGE_COMPLETE = Number(((numRequiredCompleted / numRequiredTotal) * 100).toFixed(0));

    return Number(PERCENTAGE_COMPLETE);
  };

  useEffect(() => {
    setPercentageComplete(getPercentageComplete({ values }));
  }, [values]);

  useEffect(() => {
    if (percentageComplete === 100) {
      setFormConfigState({ isLocked: false });
      setValue('IS_FORM_SUBMIT_LOCKED', false, optionsSetQuiet);
      fields.find(({ name }) => name === 'accept_terms').isDisabled = false;
      fields.find(({ name }) => name === 'accept_privacy').isDisabled = false;
    } else {
      setFormConfigState({ isLocked: true });
      setValue('IS_FORM_SUBMIT_LOCKED', true, optionsSetQuiet);
      fields.find(({ name }) => name === 'accept_terms').isDisabled = true;
      fields.find(({ name }) => name === 'accept_privacy').isDisabled = true;
      if (user?.user_role === USER_ROLE.PENDING) {
        setValue('accept_terms', false, optionsSetQuiet);
        setValue('accept_privacy', false, optionsSetQuiet);
      }
    }
  }, [percentageComplete]);

  return (
    <Row css={styles}>
      <Col xs={12} md={6} className="col col-dev">
        <p className={percentageComplete === 100 ? 'success' : 'warning'}>
          {t('t.forms.completion.progress')}: <strong>{`${percentageComplete}%`}</strong>
        </p>
      </Col>
      <Col xs={12} md={6} className="col col-dev">
        <input {...register(field.name)} type="text" value={percentageComplete} readOnly hidden />
      </Col>
    </Row>
  );
};
