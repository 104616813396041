import type { IconType } from 'react-icons';
import { cloneElement } from 'react';
import type { ReactElement, ReactHTMLElement } from 'react';
import { IoCheckbox as IconOnDefault, IoSquareOutline as IconOffDefault } from 'react-icons/io5';
import type { CheckboxFnArgs } from './Checkbox.types';

const defaultIcons = {
  on: <IconOnDefault className="icon-on" />,
  off: <IconOffDefault className="icon-off" />,
};

export const genCustomIcon = ({ iconCustom, state }: Required<CheckboxFnArgs>): IconType | ReactElement => {
  return typeof iconCustom === 'object'
    ? cloneElement(iconCustom, { className: `icon-${state}` }) // ReactElement
    : iconCustom; // IconType
};

export const getCheckboxStateIcon = ({ iconCustom, state }: CheckboxFnArgs): ReactHTMLElement<HTMLElement> => {
  const IconCheckboxState = (
    iconCustom ? genCustomIcon({ iconCustom, state }) : defaultIcons[state as keyof typeof defaultIcons]
  ) as ReactHTMLElement<HTMLElement>;

  return IconCheckboxState;
};
