/* eslint-disable quote-props */
/* eslint-disable prettier/prettier */
import type { AxiosInstance, AxiosRequestHeaders, AxiosRequestConfig, AxiosResponse } from 'axios';
import { default as Axios } from 'axios';
import {
  API_BASE_URL,
  // headersDefault,
  apiConfig,
} from './api.config';

type ApiRequestOptions = {
  headers?: AxiosRequestHeaders;
  body?: unknown;
  params?: unknown;
};

// const api: AxiosInstance = Axios.create({ ...apiConfig, headers: headersTest });

const initApi = () => {
  const axios: AxiosInstance = Axios.create();

  return {
    // NOTE: Axios.get<any, AxiosResponse<any, any>, any>(url: string, config?: AxiosRequestConfig<any> | undefined): Promise<AxiosResponse<any, any>>
    get: async (endpoint: string, { headers, params = {} }: ApiRequestOptions = {}) => {
      return axios.get(`${API_BASE_URL}/${endpoint}`, {
        headers: {
          // ...headersDefault,
          ...headers,
        },
        params,
      });
    },
    // NOTE: Axios.post<any, AxiosResponse<any, any>, unknown>(url: string, data?: unknown, config?: AxiosRequestConfig<unknown> | undefined): Promise<AxiosResponse<any, any>>
    post: async (endpoint: string, { body, headers }: ApiRequestOptions = {}) => {
      return await axios.post(`${API_BASE_URL}/${endpoint}`, body, {
        // body,
        // headers: { ...headersDefault, ...headers },
        headers: {
          // ...headersDefault,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
    },
    // NOTE: Axios.put<any, AxiosResponse<any, any>, any>(url: string, data?: any, config?: AxiosRequestConfig<any> | undefined): Promise<AxiosResponse<any, any>>
    put: async (endpoint: string, { id, body }: any = {}) => {
      return axios.put(`${API_BASE_URL}/${endpoint}/${id}`, body, {
        headers: {
          // ...headersDefault,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
    },
    // NOTE: Axios.patch<any, AxiosResponse<any, any>, {}>(url: string, data?: {} | undefined, config?: AxiosRequestConfig<{}> | undefined): Promise<AxiosResponse<any, any>>
    patch: async (endpoint: string, { id, body }: any = {}) => {
      return axios.patch(`${API_BASE_URL}/${endpoint}/${id}`, body, {
        headers: {
          // ...headersDefault,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
    },
  };
};

export default initApi();
