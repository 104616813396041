import { Container, Row, Col } from 'react-grid-system';
import { NavLink } from 'react-router-dom';
import { config } from 'config';
import { styles } from './Footer.css';

const Footer = () => (
  <footer className="footer" css={styles}>
    <Container fluid sm={true} md={true} lg={true} xl={true}>
      <Row align="stretch" justify="end">
        <Col xs={12} md={6} className="col-footer-left">
          &copy; Copyright {new Date().getFullYear()}
          <NavLink to="/" className="title-app">
            {config.bizName}
          </NavLink>
        </Col>
        <Col xs={12} md={6} className="col-footer-right">
          {/* SOME CONTENT */}
        </Col>
      </Row>
    </Container>
  </footer>
);
export { Footer };
