import { useContext } from 'react';
import { config } from 'config';
import { IPaginationContext, PaginationContext } from './PaginationContext';

export const usePagination = () => {
  const {
    numItems,
    numPages,
    page,
    setPage,
    setCursorStart,
    setCursorEnd,
    maxPerPage = config.TABLE_MAX_ROWS,
  } = useContext(PaginationContext) as IPaginationContext;

  const setCursorRange = () => {
    setCursorStart((page - 1) * maxPerPage);
    setCursorEnd((page - 1) * maxPerPage + maxPerPage);
  };

  const gotoFirstPage = () => {
    setPage(1);
  };
  const gotoLastPage = () => {
    setPage(numPages);
  };

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const nextPage = () => {
    if (page < numPages) {
      setPage(page + 1);
    }
  };

  return {
    ...useContext(PaginationContext),
    setCursorRange,
    gotoFirstPage,
    gotoLastPage,
    prevPage,
    nextPage,
    numItems,
    numPages,
    maxPerPage,
  };
};
