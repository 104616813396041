import { INPUTS, FORMAT } from 'types/form.types';

import { AUTH_USER } from 'types/form.types';
import type { DetailConfig, DetailSection } from 'components/DetailSummary';
import type { SelectOption } from 'components/FormUI/Select';
import { SPAIN_PROVINCES } from 'constants/spain';
import { DATATYPE, ENDPOINT } from './eventos.config';

let i = 0;

// PUBLIC WEB VIEW ----------------------------------------------------------------- //

// NOTE: FOLLOWING CONFIG WILL BE MERGED WITH form.config.ts/globalFieldProps
// TO POPULATE COMMON PROPS, OPTIONS etc
export const detailConfig: DetailConfig = {
  dataType: {
    key: DATATYPE,
    role: 'detail',
  },
  // ----------------------------------------------------------------- //
  api: { endpoint: ENDPOINT },
  header: {
    title: 't.titles.events',
    buttonLabel: 'Volver a Eventos',
  },
  hero: {
    hasImage: true,
    hasMap: {
      querySources: ['country', 'province', 'city'],
      // marker: {
      //   lat, lng
      // }
    },
  },
  sections: [
    // ---------------------------------------------------------------------- //
    // NOTE: ✅ DONE !!
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Fecha y Horario',
      fields: [
        {
          name: 'date',
          label: 'Fecha del evento',
        },
        {
          name: 'time_start',
          label: 'Hora de inicio',
        },
        {
          name: 'time_end',
          label: 'Hasta',
        },
      ],
    },
    {
      // REPEATER SECTION (with child fields array)
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Ubicación del Evento',
      fields: [
        {
          name: 'location',
          label: 'Ubicación',
        },
        {
          name: 'city',
          label: 't.labels.city',
        },
        {
          name: 'province',
          label: 't.labels.province',
          hint: 'Provincia de España',
          valueFormat: {
            type: FORMAT.BY_OPTION, // TODO: NO TS TYPE WARN ???????
            options: SPAIN_PROVINCES,
          },
        },
        {
          name: 'country',
          label: 't.labels.country',
        },
      ],
    },
    // ---------------------------------------------------------------------- //
    // NOTE: ✅ DONE !!
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Descripción',
      fields: [
        {
          name: 'event_type',
          label: 'tipo de evento',
        },
        {
          name: 'description',
          label: false,
          inputType: INPUTS.textarea,
        },
      ],
    },
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Más detalles',
      fields: [
        {
          name: 'url',
          label: 'URL de la página web (o similar):',
          inputType: INPUTS.url,
        },
        {
          name: 'activities',
          label: false,
          inputType: INPUTS.textarea,
        },
      ],
    },
    // ---------------------------------------------------------------------- //
    // NOTE: ✅ DONE !!
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Organizador',
      fetchedSource: {
        name: 'organizador',
        params: { endpoint: 'entidades', user_id: AUTH_USER.UUID },
      },
      fields: [
        {
          name: 'name',
          label: 'Entidad Organizadora',
        },
        {
          name: 'entity_type',
          label: 'Tipo de entidad',
          valueFormat: {
            type: FORMAT.BY_ID, // TODO: NO TS TYPE WARN ???????
            source: 'entity_type',
          },
        },
        {
          name: 'city',
          label: 'población',
        },
        {
          name: 'country',
          label: 'país',
        },
      ],
    },
    // ---------------------------------------------------------------------- //
    // NOTE: ✅ DONE !!
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Contactar al Organizador',
      fetchedSource: {
        name: 'contacto',
        params: { endpoint: 'entidades', user_id: AUTH_USER.UUID },
      },
      fields: [
        {
          name: 'email_public',
          label: 'email',
        },
        {
          name: 'tel_public',
          label: 'teléfono',
        },
      ],
    },
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Colaboradores',
      colWidth: {
        xs: 12,
      },
      fields: [
        {
          name: 'colaborators',
          label: false,
          valueFormat: {
            type: FORMAT.LIST_BY_ID,
            source: 'entidades',
          },
        },
      ],
    },
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Patrocinadores',
      colWidth: {
        xs: 12,
      },
      fields: [
        {
          name: 'sponsors',
          label: false,
          valueFormat: {
            type: FORMAT.TAGS, // TODO: NO TS TYPE WARN ???????
            source: 'sponsors',
          },
        },
      ],
    },
  ],
};
