import { Button } from 'components/Button';
import { Tooltip } from 'components/Tooltip';
import { ArrayJSX } from 'utils/ArrayJSX';
import { configSocials } from 'config/socials.config';
import { colors } from 'styles/colors.css';
import type { ColorName, HexColor } from 'styles';
import { styles } from './Socials.css';

type SocialsProps = {
  value: any;
  color?: ColorName | HexColor;
  mono?: boolean;
  scale?: number;
};

export const SocialLink = ({ value, color = colors.primary, mono: isMono, scale = 1 }: SocialsProps) => {
  if (!value) return;

  const handleClick = (url: string) => {
    const newWindow = window?.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
    return;
  };

  // CSS CLASSES
  const cssClasses = new ArrayJSX('socials');
  isMono && cssClasses.push('mono');

  const [socialType] = configSocials.filter((entry) => entry.name === value.platform);
  const iconColor = isMono ? color || '#ffffff' : socialType.color;

  return (
    <div css={styles} className={`social-item social-${socialType.name}`}>
      <Tooltip key={`social-${socialType.name}`} content={socialType.name} color={colors.primary} direction="top">
        <Button
          onClick={() => handleClick(value.url)}
          icon={<socialType.icon className={`social-${socialType.name}`} />}
          color={iconColor}
          colorHover={iconColor || 'primary'}
          variant="clear"
          label={value.name}
        />
      </Tooltip>
    </div>
  );
};
