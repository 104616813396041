import type { SortDirection } from 'api';

export enum Language {
  en = 'english',
  es = 'spanish',
  fr = 'french',
}

export interface AppConfiguration {
  appName: string;
  bizName: string;
  PATH_HOME_WEB: string;
  PATH_HOME_USER: string;
  PATH_HOME_ADMIN: string;
  PATH_BASE_WEB: string;
  PATH_BASE_USER: string;
  PATH_BASE_ADMIN: string;
  PATH_BASE: string;
  API_BASE_URL: string;
  API_BASE_IMAGES: string;
  LOGGED_OUT_PATH: '/' | '/login';
  TABLE_MAX_ROWS: number;
  SORT_PARAMS_DEFAULT: {
    column: string;
    dir: SortDirection;
  };
  tableRowHeight: string | number;
  tableRowPadding: string | number;
  languages: Record<keyof typeof Language, Language>;
  defaultLanguage: Language;
  NODE_ENV?: 'devlopment' | 'production';
}

export interface DotEnvVars {
  // API VARS
  API_PROTOCOL: string;
  API_HOST: string;
  API_PORT: string | number;
  //
  GOOGLE_MAPS_API_KEY: string;
  IS_ENV_PRODUCTION?: boolean;
  IS_SITE_LOCKED?: boolean;
  // WEB-SOCKET
  WDS_SOCKET_HOST?: string;
  WDS_SOCKET_PATH?: string;
  WDS_SOCKET_PORT?: string;
}
