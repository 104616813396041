import { useTranslation } from 'react-i18next';
import { useNavigateState } from 'hooks/useNavigateState';
import type { ReactElement } from 'react';
import { FaRegCheckCircle as IconSuccess } from 'react-icons/fa';
import type { LocationWithState } from 'types';
import { useRouter } from 'routes/hooks/useRouter/useRouter';
import { Alert } from 'components/Alert';
import { Button } from 'components/Button';
import { Card } from 'components/Card';
import { config } from 'config';
import { styles } from 'pages/LoginPage/LoginPage.css';
import { cleanRoutePath } from 'routes/utils/routes.utils';

const LoggedOut = (): ReactElement => {
  const { t } = useTranslation();
  const { PATH_BASE, location } = useRouter();
  const { navigate } = useNavigateState();
  const locationState = (location as unknown as LocationWithState)?.state;

  const handleClickButton = () => {
    config.LOGGED_OUT_PATH === '/'
      ? document.location.replace('/')
      : navigate(cleanRoutePath(`${PATH_BASE}/login`), { state: { ...locationState }, replace: true });
  };

  return (
    <section css={styles} className="logged-out">
      <Card>
        <Alert color={'success'}>
          <IconSuccess />
          <h4>{t('t.loggedOut.title')}</h4>
          <p>{t('t.loggedOut.subtitle')}</p>
        </Alert>
        <hr />
        <Button
          label={
            config.LOGGED_OUT_PATH === '/' ? t('t.loggedOut.button.ok') : t('t.loggedOut.button.gotoLogin')
          }
          color="defaultLight"
          onClick={handleClickButton}
        />
      </Card>
    </section>
  );
};

export { LoggedOut };
