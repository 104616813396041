import React from 'react';
import type { RouteObject } from 'react-router-dom';
import { type RouteConfig } from 'routes/routes.types';
import { type DataConfiguration, DataActions, type DynamicRouteOptions } from './getDynamicRoutes.types';
import * as canales from 'config/datatype/canales';
import * as entidades from 'config/datatype/entidades';
import * as periodicos from 'config/datatype/periodicos';

const configs: { [key: string]: DataConfiguration['dataConfig'] } = {
  canales,
  periodicos,
  entidades,
};

export const getDynamicRoutes = <T extends DataActions[]>(
  routes: RouteConfig[],
  options: DynamicRouteOptions<T>,
): RouteObject[] => {
  const createDynamicRoutes = (route: RouteConfig, parentPath: string): RouteObject => {
    const fullPath = `${parentPath}/${route.path}`.replace(/\/+/g, '/');
    const configKey = (route?.parent?.path ? route.parent.path : route?.path) as keyof typeof configs;
    const children: RouteObject[] = [];

    if (options.actions.includes(DataActions.LIST)) {
      children.push({
        index: true,
        element: React.createElement(options.components[DataActions.LIST], {
          dataConfig: configs[configKey],
        }),
      });
    }

    options.actions.forEach((action) => {
      if (action === DataActions.NEW) {
        children.push({
          path: `new`,
          element: React.createElement(options.components[action], {
            dataConfig: configs[configKey],
          }),
        });
      }
      if (action === DataActions.EDIT) {
        children.push({
          path: `edit/:id`,
          element: React.createElement(options.components[action], {
            dataConfig: configs[configKey],
          }),
        });
      }
      if (action === DataActions.VIEW) {
        children.push({
          path: `view/:id`,
          element: React.createElement(options.components[action], {
            dataConfig: configs[configKey],
          }),
        });
      }
    });

    if (route.children) {
      route.children.forEach((childRoute) => {
        children.push(createDynamicRoutes(childRoute, fullPath));
      });
    }

    return {
      path: route.path,
      children,
    };
  };

  // const basePathWithSlash = options.basePath.startsWith('/') ? options.basePath : `/${options.basePath}`;
  // const dynamicRoutes: RouteObject[] = routes.map((route) => createDynamicRoutes(route, basePathWithSlash));

  const dynamicRoutes: RouteObject[] = routes.map((route) => createDynamicRoutes(route, options.basePath));

  return dynamicRoutes;
};
