import { useReducer, useCallback } from 'react';
import { toUpperCaseFirst } from 'utils';
import { NODE_ENV, config } from 'config';
import type { ContextState } from './ContextReducer';
import ContextReducer, { Actions } from './ContextReducer';
import initialState, { CONTEXT_FIELDS, CONTEXT_ACTIONS } from './ContextState';
import { LayoutContext } from './LayoutContext';
import { notify } from '../components/Toast/Toast';
import type { ContextProviderProps } from './Context.types';
import { DevScreenSize } from '../components/DevScreenSize';
import { useLocation } from 'react-router-dom';
// import { useWindowSize } from 'hooks/useWindowSize';

const isDevelopment = Boolean(NODE_ENV === 'development' && !config.IS_ENV_PRODUCTION);

const ContextProvider = ({
  children,
  value: initialStateOverrides,
  contextName = 'context',
}: ContextProviderProps) => {
  const CONTEXT_NAME = toUpperCaseFirst(contextName);
  const [state, dispatch] = useReducer(ContextReducer, { ...initialState, ...initialStateOverrides });

  const setContextState = useCallback((value: { [key in CONTEXT_FIELDS]: unknown }) => {
    dispatch({ type: Actions.SET_FIELDS, payload: { value } });
  }, []);

  // EXTRA UTILS
  // const { winWidth, winHeight } = useWindowSize();

  // const location = useLocation();
  // log('__location', 'blue', location);

  const initialValue: ContextState = {
    ...state,
    [CONTEXT_FIELDS.isDevToolsVisible]: state.isDevToolsVisible && isDevelopment,
    [`set${CONTEXT_NAME}State`]: setContextState,
    // winWidth,
    // winHeight,
    notify,
  };

  return (
    <LayoutContext.Provider value={initialValue}>
      <>
        {children}
        <DevScreenSize />
      </>
    </LayoutContext.Provider>
  );
};

export default ContextProvider;
