import type { UseFormReturn } from 'react-hook-form';
import type { FieldConfig } from 'components/Form';
import { genMockValueByType } from 'mocks/generators';
import { optionsAutofill } from './config';

type UseFormMocking = {
  fields: FieldConfig[];
  formMethods: UseFormReturn;
};

export const useFormMocking = ({ fields, formMethods: { setValue } }: UseFormMocking) => {
  // FIELD VALUE MOCKS ====================================================== //

  const mockValues = () => {
    const fieldsToMock = fields.filter((field: FieldConfig) => {
      if (field.mockValue) {
        field.required = false;
      }
      return field.mockValue;
    });

    log('DEV: MOCKING - A', 'yellow', { fieldsToMock });

    fieldsToMock.map((field: FieldConfig, index: number) => {
      const fieldElement = document.querySelector(`[name="${field.name}"]`) as HTMLInputElement;
      if (field.mockValue) {
        if ((field.mockValue as string).startsWith('MOCK_')) {
          const mockStringValue = genMockValueByType(field.mockValue as string);
          fieldElement.value = mockStringValue;
          fieldElement.focus();
          setValue(field.name, mockStringValue, optionsAutofill);
        } else {
          fieldElement.value = field.mockValue;
          fieldElement.focus();
          setValue(field.name, field.mockValue, optionsAutofill);
        }
        fieldElement?.blur();
      }
    });
  };

  // FIELD VALUE MOCKS ====================================================== //

  const mockValuesAsAdmin = () => {
    log('DEV: MOCKING - B', 'orange');
    const fieldsToMock = fields.filter((field: FieldConfig) => {
      if (field.mockValueAsAdmin) {
        field.required = false;
      }
      return field.mockValueAsAdmin;
    });

    fieldsToMock.map((field: FieldConfig, index: number) => {
      const fieldElement = document.querySelector(`[name="${field.name}"]`) as HTMLInputElement;
      if (field.mockValueAsAdmin) {
        if ((field.mockValueAsAdmin as string).startsWith('MOCK_')) {
          const mockStringValue = genMockValueByType(field.mockValueAsAdmin as string);
          fieldElement.value = mockStringValue;
          fieldElement.focus();
          setValue(field.name, mockStringValue, optionsAutofill);
        } else {
          fieldElement.value = field.mockValueAsAdmin as string;
          fieldElement.focus();
          setValue(field.name, field.mockValueAsAdmin, optionsAutofill);
        }
        fieldElement?.blur();
      }
    });
  };

  return {
    mockValues,
    mockValuesAsAdmin,
  };
};
