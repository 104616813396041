import { detailConfig } from './posts.detail';
import { formConfig } from './posts.form';
import { tableConfig } from './posts.table';

export { formConfig } from './posts.form';
export { detailConfig } from './posts.detail';
export { tableConfig } from './posts.table';

// TODO: FUTURE, FOR: ADMIN DETAIL-VIEW, PUBLIC TABLE-VIEW...etc
// export { publicConfig } from './posts.detail';

export default { formConfig, detailConfig, tableConfig };
