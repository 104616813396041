import { IndexSignatureString, DataEntry, DataType } from 'types';
import { FieldConfig, INPUTS } from 'types/form.types';
import { getInputTypeDeep } from 'components/Form/utils/form.utils.config';
import { omitNull } from 'utils/utils.object';
import { errorMessages, patterns, inputValidationRHF } from '../config/form.validation';

// ========================================================================== //

// TODO: ADD CLAUSE FOR 'pattern' SETTINGS AND CUSTOM MESSAGES ETC...
// MAYBE CONFIG SETTING IS ALREADY A VALID RHF INPUT VALIDATION OBJECT

export const setInputValidation = (field: FieldConfig & IndexSignatureString) => {
  const { name } = field;
  const inputType = getInputTypeDeep(field);

  const validationProps = Object.entries(inputValidationRHF);
  const presetPatterns = Object.keys(patterns);
  const inputValidation = {} as any;

  for (const [attr, defaultSettings] of validationProps) {
    if (attr === 'pattern') {
      if (presetPatterns.includes(inputType as string)) {
        inputValidation[attr] = { ...patterns[inputType] };
      } else if (presetPatterns.includes(name)) {
        inputValidation[attr] = { ...patterns[name] };
      }
    }

    const configValue = field[attr];

    const hasConfig = !!((attr in field || inputType in field) && configValue !== null);
    const isConfigIgnored = !!(attr === 'required' && (configValue as DataType) === false);

    if (hasConfig && !isConfigIgnored) {
      const errorMessage = errorMessages[attr].replace('%1', configValue as any);
      const validation = {
        value: configValue,
        message: errorMessage,
        ...(configValue as DataEntry),
      };
      // ORIGINAL PROP CONFIGURED + MOVED TO RHF inputValidation OBJECT
      inputValidation[attr] = { ...validation };

      if (attr !== 'required') {
        delete field[attr];
      }
    }
  }

  field.inputValidation = omitNull(inputValidation);

  return field;
};

export const unsetRequired = (fields: FieldConfig[]): FieldConfig[] => {
  fields.filter((field: FieldConfig) => {
    delete field.inputValidation?.required;
  });

  return fields;
};
