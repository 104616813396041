import { useEffect, useState } from 'react';

export const useBodyScrollable = (): { isBodyScrollable: boolean } => {
  const [isBodyScrollable, setIsBodyScrollable] = useState<boolean>(document.body.scrollHeight > window.innerHeight);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setIsBodyScrollable(document.body.scrollHeight > window.innerHeight);
    });
    resizeObserver.observe(document.body);
    return () => {
      resizeObserver.unobserve(document.body);
    };
  }, []);

  return { isBodyScrollable };
};
