import cloneDeep from 'lodash/cloneDeep';
import { FieldConfig, INPUTS } from 'types/form.types';
import { defaultColumnSizes } from 'components/Form';
import { getResponsiveColumnSizes, setAsyncFields } from 'utils';
import { getFieldDefaultUI } from './form.utils.ui';
import { defaultFieldProps } from '../config';

// ====================================================================== //

// FIELDS ARRAY FROM CONFIG
export const getConfiguredFields = (fieldsConfig: FieldConfig[]) => {
  const fieldsConfiguredArray = fieldsConfig.map((field: FieldConfig) => {
    const fieldName = field.name;
    const colWidth = field?.colWidth
      ? getResponsiveColumnSizes({ defaultProps: defaultColumnSizes, customProps: field?.colWidth })
      : { ...defaultColumnSizes };

    return {
      ...defaultFieldProps, // empty props schema with default / unset values
      ...getFieldDefaultUI({ fieldName }),
      ...field,
      colWidth: cloneDeep({ ...colWidth }),
    };
  });

  setAsyncFields({ fieldsConfig });

  return fieldsConfiguredArray;
};

// FIELDS ARRAY FROM CONFIG
export const getInputTypeDeep = (fieldConfig: FieldConfig) => {
  const inputType =
    (fieldConfig.inputType === INPUTS.repeater && fieldConfig.repeaterConfig
      ? fieldConfig.repeaterConfig.inputType
      : fieldConfig.inputType) || INPUTS.text;

  return inputType;
};

// ======================================================================== //
// NOTE: UTILS FOR FORM CONFIGURATION..

export const applyReadOnly = (souceFields: FieldConfig[], targetNames: string[] = []): FieldConfig[] => {
  return cloneDeep(souceFields).map((field): FieldConfig => {
    if (targetNames.includes(field.name)) {
      field.readOnly = true;
    }
    return field;
  });
};
