import { useNavigateState } from 'hooks/useNavigateState';
import type { KeyboardEvent, ReactElement } from 'react';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import { ACTIONS } from 'types/actions.types';
import type { FormConfig, FieldConfig, FormButtonBarProps } from 'types/form.types';
import type { FormSectionConfig } from 'components/FormSection/FormSection.types';
import { useLayout } from 'pages/Layout/context/LayoutContext';
import { useRouter } from 'routes/hooks';
import { useGlobal } from 'store/useGlobalContext';
import { Spinner } from 'components/Spinner';
import type { FormButton, FormComponentProps } from './Form.types';
import { FormProvider, useForm, type UseFormReturn } from 'react-hook-form';
import { FormSection } from 'components/FormSection';
import { FormButtonBar } from './components/FormButtonBar';
import { DevFormState } from './components/_DevFormState';
import { useGetContext as useFormConfigContext } from './context/FormConfigContext';
// import { useFormHero } from './useFormHero';
import { useFormMethods } from './useFormMethods';
import { useFormSubmit } from './useFormSubmit';
import { useFormMocking } from './useFormMocking';
import { useFormUtilsUI } from './utils/form.utils.ui';
import { FormValuesJSON } from 'components/FormUI/FormValuesJSON';

// NOTE: GREAT REF: REACT-QUERY + REACT-HOOK-FORM:
// https://tkdodo.eu/blog/react-query-and-forms#invalidate-and-reset-after-mutation

// ref: https://blog.sRdd.com/style-forms-css/

// export const FormDynamic = ({ onSubmit, formMethods }: { onSubmit: any; formMethods: UseFormReturn }): ReactElement => {
export const FormDynamic = ({
  onSubmit, // optional override
  formMethods, // formConfig,
  // formSections,
  // fields,
  // defaultValues,
  // schema,
  // isLocked,
}: {
  onSubmit: any;
  formMethods: UseFormReturn;
  // formConfig: FormConfig;
  // formSections: FormSectionConfig[];
  // fields: FieldConfig[];
  // defaultValues: any;
  // schema: any;
  // isLocked: boolean;
}): ReactElement => {
  const { isDevToolsVisible } = useLayout();
  const { formConfig, formSections, fields, defaultValues, schema, isLocked } = useFormConfigContext();

  // if (!fields || !fields?.length) return <Spinner />;
  // if (!formSections || !formSections?.length) return <Spinner />;

  const navigate = useNavigate();
  // const { navigate } = useNavigateState();
  const { location, params, fromLocation, action } = useRouter();
  // const { isNavigationBlocked, setIsNavigationBlocked } = useGlobal('isNavigationBlocked');
  const FORM_MODE = params?.action || ACTIONS.EDIT;

  // ⭐️ CONFIGURE ABSTRACTED RHF FORM METHODS..
  const { resetFields, handleCancel, handleCopyFrom } = useFormMethods({ formMethods });

  // ⭐️ CONFIGURE ABSTRACTED SUBMIT HANDLERS..
  const { handleSubmit, handleSubmitForm, handleHasSubmittedNew } = useFormSubmit({
    onSubmit, // optional override
    formMethods,
    resetFields,
    // /
    formConfig,
    fields,
    schema,
  });

  // INIT RHF + METHODS from useForm()
  const {
    // handleSubmit: handleSubmitRHF,
    formState,
    reset,
    setValue,
    watch,
    getValues,
  } = formMethods;
  const { isDirty, isValid, isSubmitting, isSubmitSuccessful, submitCount, dirtyFields, errors } = formState;

  // ======================================================================== //

  // ============================================================================== //
  // TODO: MOVE MOCK METHODS OUT OF MAIN FORM COMPONENT
  const { mockValues, mockValuesAsAdmin } = useFormMocking({ fields, formMethods });
  // ============================================================================== //

  // GREAT "DIRTY FIELDS" REF:
  // https://codesandbox.io/s/react-hook-form-submit-only-dirty-fields-ol5d2?file=/src/index.tsx:105-901

  /*
  useEffect(() => {
    // log('FORM_DEFAULTS', 'yellow', getValues());
    if (formConfig?.isNavPromptActive !== false) {
      isDirty && !isNavigationBlocked && setIsNavigationBlocked(true);
    }
  }, [isDirty]);
  */

  // USE-EFFECT #1 - FORM SUBMISSION + UPDATE DEFAULT VALUES
  useEffect(() => handleHasSubmittedNew(isSubmitSuccessful), [handleHasSubmittedNew, isSubmitSuccessful]);
  // useFormHero({ formConfig, formMethods });

  // HANDLERS ======================================================== //

  // NOTE: UPDATE-ONLY SUBMIT HANDLER - WITHOUT VALIDATION..
  const handleUpdate = async (e: SubmitEvent): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();
    const values = getValues();
    const res = await handleSubmitForm(values);
    log('UPDATED !!!', 'lime', res);
  };

  // NOTE: UPDATE-ONLY SUBMIT HANDLER - WITHOUT VALIDATION..
  const handleLater = async (): Promise<void> => {
    await navigate('/logout');
  };

  const handleEdit = async (): Promise<void> => {
    if (params?.action === ACTIONS.VIEW) {
      const href = location.pathname.split('/view').shift();
      const id = defaultValues?.id || '';
      await navigate(`${href}/edit/${id}`);
    }
  };

  const handleView = async (): Promise<void> => {
    if (params?.action === ACTIONS.EDIT) {
      const href = location.pathname.split('/edit').shift();
      const id = defaultValues?.id || '';
      await navigate(`${href}/view/${id}`);
    }
  };

  const { getFormClasses } = useFormUtilsUI({ formMethods });
  const formClasses = getFormClasses(`mode-${FORM_MODE.toLowerCase()}`);

  // log('__SECTIONS (Form):', 'grey', { fields, formSections });

  return (
    <FormProvider {...formMethods}>
      <form
        id="form-main"
        // onSubmit={handleSubmit}
        // TODO: CONSIDER USING  formMethods.handleSubmit(handleSubmitForm, handleSubmitErrors), insteadd ??
        onSubmit={formMethods.handleSubmit(handleSubmit)}
        className={formClasses.inline()}
        /*
        onKeyDown={
          // DISABLE SUBMIT ON ENTER WITHOUT PREVENT_DEFAULT
          (event: KeyboardEvent<HTMLFormElement>) => {
            // NOTE: DO NO CANCEL EVENT FOR <textarea /> IN ORDER TO ALLOW LINE BREAKS
            if (event.key === 'Enter' && (event.target as any)[Symbol.toStringTag] !== 'HTMLTextAreaElement') {
              (event.target as HTMLFormElement).blur();
            }
          }
        }
        */
      >
        {/* <pre>BLOCKER: {String(blocker.state)}</pre> */}
        {/* <pre>isNavigationBlocked: {String(isNavigationBlocked)}</pre> */}
        {/* <pre>isDirty: {String(isDirty)}</pre> */}
        {/* TODO: REMPOVE - DEV ONLY !! ==================================== */}
        {/* <FormValuesJSON /> */}
        {/* ================================================================ */}

        <input id="no-autocomplete" role="presentation" autoComplete="off" style={{ display: 'none' }} />

        {/* == FORM SECTIONS ============================================== */}

        {/* <pre>{JSON.stringify(formSections, null, 2)}</pre> */}

        {formSections
          .filter(({ isHidden }) => !isHidden)
          .map((section) => (
            <FormSection
              key={section.sectionKey}
              sectionConfig={section}
              defaultValues={defaultValues}
              handleCopyFrom={handleCopyFrom}
              isLocked={isLocked}
            />
          ))}

        {/* == FORM BUTTONS ============================================== */}

        {/* <pre style={{ overflowX: 'hidden' }}>{JSON.stringify(getValues(), null, 2)}</pre> */}

        <FormButtonBar
          isLocked={isLocked}
          formMethods={formMethods}
          buttons={formConfig.buttons as FormButton[]}
          handleActions={{
            CANCEL: handleCancel,
            EDIT: handleEdit,
            VIEW: handleView,
            RESET: reset, // OR: resetFields
            UPDATE: handleUpdate, // UPDATE ONLY (skips validation)
            LATER: handleLater, // UPDATE ONLY (skips validation)
            SUBMIT: handleSubmit,
            MOCK: mockValues, // OR: resetFields
            MOCK_ADMIN: mockValuesAsAdmin, // OR: resetFields
          }}
        />
      </form>

      {/* == FORM DEV_TOOLS ============================================ */}

      {isDevToolsVisible && (
        <DevFormState
          formMethods={formMethods}
          fields={fields || []}
          isLocked={isLocked}
          isVisibleInfo={true}
          isVisibleMockButton={true}
        />
      )}
    </FormProvider>
  );
};
