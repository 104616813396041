import { Col } from 'react-grid-system';
import { format } from 'date-fns';

import { MdNavigateBefore as IconPrev, MdNavigateNext as IconNext } from 'react-icons/md';

import { DATE_FILTER } from 'i18n/datetime';
import { Button } from 'components/Button';
import { formatDate } from 'utils';

export const DualCalendarHeader = ({
  monthDate,
  customHeaderCount,
  decreaseMonth,
  increaseMonth,
  handleChangeMonth,
}: any) => {
  const DATE_TODAY = new Date();
  const minMonthDate = `${format(monthDate, DATE_FILTER.MONTH)}-01`;
  const isDisabled = minMonthDate <= `${format(DATE_TODAY, DATE_FILTER.MONTH)}-01`;
  return (
    <>
      {customHeaderCount === 0 ? (
        <>
          <Col xs={12} className="col-daterange col-daterange-btn-prev">
            <Button
              size="xs"
              icon={<IconPrev />}
              iconScale={1.8}
              aria-label="atrás"
              className={'daterange-btn daterange-prev'}
              color="primaryXLight"
              onClick={() => {
                decreaseMonth();
                handleChangeMonth(-1);
              }}
              isDisabled={isDisabled}
            />
          </Col>
          <Col xs={12} className="col-daterange col-daterange-title" style={{ paddingLeft: '1.2em' }}>
            {formatDate(new Date(monthDate), 'MMMM')}
          </Col>
        </>
      ) : (
        <>
          <Col xs={12} className="col-daterange col-daterange-btn-next">
            <Button
              size="xs"
              icon={<IconNext />}
              iconScale={1.8}
              aria-label="avanzar"
              className={'daterange-btn daterange-next'}
              color="primaryXLight"
              onClick={() => {
                increaseMonth();
                handleChangeMonth(1);
              }}
              // isDisabled={page === numPages || numItems === 0}
            />
          </Col>
          <Col xs={12} className="col-daterange col-daterange-title" style={{ paddingRight: '1.2em' }}>
            {formatDate(new Date(monthDate), 'MMMM')}
          </Col>
        </>
      )}
    </>
  );
};
