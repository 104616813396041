import { css } from '@emotion/react';
import Color from 'color';
import { layout } from 'styles/layout.css';
import { colors } from 'styles/colors.css';

export const colorVariant = {
  light: Color(colors.primaryXXLight).lighten(0.05).desaturate(0.2).hex(),
};

export const styles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: ${layout.padding} calc(${layout.padding}) calc(${layout.padding}); */
  padding: 0;
  text-align: center;
  min-height: 4em;
  height: 100%;
  border-width: ${layout.borderWidth};
  border-style: solid;

  border-radius: 10px !important;
  /* margin: 10px 10px 30px 10px; */
  overflow: hidden;
  /* border: calc(${layout.borderWidth} * 1.5) solid ${colors.primaryXXLight} !important; */
  border: calc(${layout.borderWidth} * 1.5) solid ${colorVariant.light} !important;
  /* box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.2); */

  .cal-top {
    width: 100%;
    height: 33%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${colorVariant.light};
    color: white;
    font-size: 1.4em;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
  }

  .cal-bottom {
    width: 100%;
    height: 67%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    color: ${colors.grey};
    color: ${colors.textLight};
    font-weight: 900;
    font-size: 3.3em;
    line-height: 1;
    padding-bottom: 5px;
  }

  svg {
    font-size: 5em !important;
    padding: calc(${layout.padding} * 1) calc(${layout.padding}) calc(${layout.padding} * 1) !important;
    opacity: 0.85;
  }
  h4 {
    font-size: 1.66em;
    font-weight: 700 !important;
    text-align: center;
    margin: calc(${layout.padding} / 4);
  }
  p {
    margin: calc(${layout.padding} / 4);
    font-size: 1em;
    font-weight: 600;
    line-height: 1.2;
  }

  background-color: ${colors.greyXXLight};
  border-color: ${colors.greyXXLight};

  &,
  * {
    color: ${colors.greyDark};
  }
`;
