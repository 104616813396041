import { Row, Col } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { ReactElement } from 'react';
import { BiUserCircle as IconUser, BiExit as IconLogout } from 'react-icons/bi';
import { useRouter } from 'routes/hooks';
import { styles } from './UserCurrent.css';

export const UserLogin = (): ReactElement => {
  const { t } = useTranslation();
  const { PATH_BASE } = useRouter();

  return (
    <div css={styles}>
      <Row align="center">
        <Col className="col-user col-login">
          <NavLink to={`${PATH_BASE}/registration`}>
            <span className="register">{t('t.headerUser.registerUserNew')}</span>
          </NavLink>
          <NavLink to="/login">
            <span className="start-session">
              {t('t.headerUser.log-in')}
              <IconUser className="btn-login" />
            </span>
          </NavLink>
        </Col>
      </Row>
    </div>
  );
};
