import React, { type MouseEventHandler, useCallback } from 'react';
import Uploady, {
  useUploady,
  useBatchAddListener,
  useItemFinalizeListener,
  useItemProgressListener,
  useAbortItem,
  FILE_STATES,
  Batch,
} from '@rpldy/uploady';
import { Button } from 'components/Button';
import IMG_FALLBACK from 'assets/img-placeholder-1000x600.png';
import UploadDropZone from '@rpldy/upload-drop-zone';
import { AiOutlineFileAdd as IconAddNew } from 'react-icons/ai';
import { getMockSenderEnhancer } from '@rpldy/mock-sender';
import UploadButton from '@rpldy/upload-button';
import { ArrayJSX } from 'utils/ArrayJSX';
import { styles as stylesDropzone } from './Uploady/Dropzone.css';
import { STATUS } from 'constants/status';
import { Spinner } from 'components/Spinner/Spinner';
import { MdErrorOutline } from 'react-icons/md';
import { t } from 'i18next';

const mockSenderEnhancer = getMockSenderEnhancer({ delay: 1500 });

// TODO: SEE THESE EXAMPLES..
// NOTE: ref - https://codesandbox.io/p/sandbox/react-uploady-dnd-with-asuploadbutton-jk9bg?file=%2Fsrc%2FApp.js%3A40%2C4-40%2C44
// NOTE: ref - https://codesandbox.io/p/sandbox/react-uploady-paste-to-upload-demo-usx9s?file=%2Fsrc%2Findex.js
// NOTE: ref - https://codesandbox.io/p/sandbox/react-uploady-add-and-remove-before-upload-vk1rs5?file=%2Fsrc%2FApp.js%3A2%2C1-2%2C40

// TODO: HERE !!!
// https://react-uploady.org/docs/api/types/#uploadyContextType
// PreviewOptions

export const ImagePreviewUploady = ({
  name,
  status,
  setStatus,
  items = [],
  setItems,
  file,
  setFile,
  image,
  setImage,
  localPreviewURL,
  setLocalPreviewURL,
  remotePreviewURL,
  height,
}: any) => {
  // ======================================================================== //

  useBatchAddListener((batch: any) => {
    log('LISTENER_BATCH', 'grey', { items, batch });
    setItems((items = []) => [...items].concat(batch.items));

    batch.items.forEach((item: any) => {
      log('LISTENER_BATCH', 'red', item);
      log('LISTENER_BATCH - FILE', 'lime', item.file);
      setFile(item.file);
      setLocalPreviewURL(URL.createObjectURL(item.file));
    });
  });

  const handleClickSelectFile: MouseEventHandler<HTMLButtonElement> = (evt) => {
    log('DROP_EVENT', 'magenta', evt);
    evt.preventDefault();
  };

  const onZoneClick = useCallback(
    (e: any) => {
      if (handleClickSelectFile) {
        handleClickSelectFile(e);
      }
    },
    [handleClickSelectFile],
  );

  // ------------------------------------------------------------------------ //

  const cssStatus = new ArrayJSX();
  status === STATUS.LOADING && cssStatus.push('loading');
  status === STATUS.ERROR && cssStatus.push('error');

  const isVisibleFileButton =
    (status === STATUS.ERROR || status === STATUS.IDLE) &&
    status !== STATUS.LOADING &&
    status !== STATUS.SUCCESS;

  return (
    <div css={stylesDropzone} className={cssStatus.inline()}>
      <UploadDropZone
        // ref={ref}
        className="dropzone-container"
        onDragOverClassName="drag-over"
        extraProps={{
          onClick: handleClickSelectFile,
        }}
      >
        <div className={`overlay-wrapper ${status}`}>
          {status === STATUS.LOADING && <Spinner light />}
          {status === STATUS.ERROR && <MdErrorOutline className="icon icon-error" />}

          <div className={cssStatus.inline('overlay')}>
            <UploadButton className="btn-upload-outter" onClick={handleClickSelectFile}>
              <Button
                color="white"
                // variant="clear"
                icon={<IconAddNew />}
                className="btn btn-add-file"
                type="button"
                label={t('t.forms.buttons.chooseImage') + '..'}
              />
            </UploadButton>
          </div>
        </div>
        <div
          className="img-preview"
          style={{
            backgroundImage: `url("${localPreviewURL ?? remotePreviewURL ?? IMG_FALLBACK}")`,
            boxShadow: 'inset 0 0 0 8px #fff',
          }}
        />
        {/* <UploadssList /> */}
      </UploadDropZone>
    </div>
  );
};
