import { css } from '@emotion/react';
import { colors, layout, forms } from 'styles';

const velocity = '0.2s';
const colorOn = colors.successDark;
const colorOff = colors.greyLight;
const switchRatio = 2.1;
const switchWidthValue = 5;
const switchHeightValue = switchWidthValue / switchRatio;
const circleWidthValue = switchWidthValue / 3.5;
const switchWidth = `${switchWidthValue}em`;
const switchHeight = `${switchHeightValue}em`;
const circleWidth = `${circleWidthValue}em`;

export const styles = css`
  height: ${forms.inputs.height};
  display: flex;
  align-items: center;
  padding: 0 calc(${layout.padding} * 0.5);

  .switch-wrapper {
    position: relative;
    width: ${switchWidth};
    display: inline-flex;
    vertical-align: middle;
    user-select: none;
    text-align: left;
  }

  input[type='checkbox'] {
    display: none;
  }

  .toggle-switch {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #ccc;
    border-radius: ${switchHeight};
    margin: 0;

    &.size-sm {
      transform: scale(0.9);
    }
    &.size-md {
      transform: scale(1.2);
    }
    &.size-lg {
      transform: scale(1.5);
    }

    .toggle-inner {
      display: block;
      width: 200%;
      margin-left: -100%;
      transition: margin ${velocity} ease-in 0s;
    }

    .toggle-inner::before,
    .toggle-inner::after {
      display: block;
      float: left;
      width: 50%;
      /*height: 34px;*/
      height: ${switchHeight};
      padding: 0;
      /* line-height: 34px; */
      /*line-height: 2.6;*/
      line-height: ${switchHeight};
      font-size: 14px;
      color: white;
      font-weight: bold;
      box-sizing: border-box;
    }

    .toggle-inner::before {
      /* content: 'Yes'; */
      text-transform: uppercase;
      padding-left: ${circleWidth};
      color: #ffffff;
      text-align: center;
    }
    .toggle-inner::after {
      /* content: 'No'; */
      text-transform: uppercase;
      padding-left: ${circleWidth};
      color: #ffffff;
      text-align: center;
    }

    /***** "SWITCH" *****/

    .toggle-circle {
      display: block;
      width: ${circleWidth};
      margin: 5px;
      background: #ffffff;
      position: absolute;
      top: 0;
      bottom: 0;
      right: calc(${switchWidth} - ${circleWidth} - 0.75em);
      border-radius: 50%;
      transition: all ${velocity} ease-in 0s;
    }
  }

  input[type='checkbox']:checked + .toggle-switch .toggle-inner {
    margin-left: 0;
  }
  input[type='checkbox']:checked + .toggle-switch .toggle-circle {
    right: 0px;
  }

  input[type='checkbox'] + .toggle-switch .toggle-inner::before,
  input[type='checkbox'] + .toggle-switch .toggle-inner::after {
    background-color: ${colorOff};
    transition: all ${velocity} ease-in 0s;
  }

  input[type='checkbox']:checked + .toggle-switch .toggle-inner::before,
  input[type='checkbox']:checked + .toggle-switch .toggle-inner::after {
    background-color: ${colorOn};
    transition: all ${velocity} ease-in 0s;
  }

  input[type='checkbox']:checked + .toggle-switch .toggle-inner::before {
    padding-left: 0;
    padding-right: calc(${switchWidth} - ${circleWidth} - 1.5em);
  }

  /*
  .toggle-switch-disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  .toggle-switch-disabled::before {
    background-color: #ccc;
    cursor: not-allowed;
  }
  */
`;
