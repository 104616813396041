import React, { createContext, useCallback, useContext, useReducer } from 'react';
import type { Context, ReactElement, ReactNode } from 'react';
import { eventFiltersReducer } from './InviteFiltersReducer';
import { initialState } from './InviteFiltersState';
import type { InviteFiltersState } from './InviteFiltersState';

// NOTE: see also - https://dev.to/mpriour/generating-strongly-typed-reducer-actions-for-react-j77
// NOTE: IN-DEPTH REF - https://www.typescript-training.com/course/intermediate-v1/09-mapped-types/
// NOTE: EXCELLENT REF - https://blog.logrocket.com/how-to-use-keyof-operator-typescript/

export const InviteFiltersContext: Context<InviteFiltersState> = createContext<InviteFiltersState>(initialState);

export const InviteFiltersProvider = ({
  initState: initialStateOverrides,
  children,
}: {
  initState?: InviteFiltersState;
  children: ReactNode;
}): ReactElement => {
  const [state, dispatch] = useReducer(eventFiltersReducer, { ...initialState, ...initialStateOverrides });

  const setFilters = useCallback((payload: { [key in keyof InviteFiltersState]: unknown }) => {
    dispatch({ type: 'SET_FIELDS', payload });
  }, []);

  const initialValue: InviteFiltersState = { ...state, setFilters };

  return <InviteFiltersContext.Provider value={{ ...initialValue }}>{children}</InviteFiltersContext.Provider>;
};

export const useInviteFilters = (): InviteFiltersState => useContext(InviteFiltersContext);
