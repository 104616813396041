import { NavLink } from 'react-router-dom';
import { ReactElement } from 'react';
import type { NavItemProps } from './Nav.types';

export const NavItem = ({ path, title, end, state }: NavItemProps): ReactElement => {
  return (
    <NavLink to={path} end={end} className={({ isActive }) => (isActive ? 'active' : 'inactive')} state={{ ...state }}>
      {title}
    </NavLink>
  );
};

// '{ index?: boolean | undefined; caseSensitive?: boolean | undefined; children?: RouteConfig[] | undefined; path: string; exact?: boolean | undefined; strict?: boolean | undefined; title?: string | undefined; ... 4 more ...; element?: ReactNode; }' is not assignable to type 'NavItemProps'.
//   Types of property 'title' are incompatible.
//     Type 'string | undefined' is not assignable to type 'string'.
//       Type 'undefined' is not assignable to type 'string'.
