import { Row, Col } from 'react-grid-system';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ISchema, DataEntry } from 'types';
import { ACTIONS } from 'types/actions.types';
import type { RouteConfig } from 'routes';
import { useRouter } from 'routes/hooks';
import { useQueryGet } from 'api/useQueryGet';
import { USER_ROLE } from 'auth/types/user.types';
import { useAuth } from 'auth/AuthContext';
import { useGlobal } from 'store/useGlobalContext';
import { useLayout } from 'pages/Layout/context/LayoutContext';
import { Button } from 'components/Button';
import type { DetailConfig } from 'components/DetailSummary';
import { Form, BUTTONS, type FormConfig } from 'components/Form';
import { Spinner } from 'components/Spinner';
import { getDataConiguration } from 'config/data.utils/getDataConiguration';
import { useNavigateState } from 'hooks/useNavigateState';
import { initDetailData } from 'config/data.utils/initDetailData';
import { formConfig as formConfigEdit, detailConfig as formConfigView } from 'config/datatype/account';
import { DevButtons } from './DevButtons';
import { getIsRegistrationComplete } from './account.utils';
import { useUserRegistration } from './useUserRegistration';
import { useUserValidation } from './useUserValidation';
import { styles } from './AccountDetailsPage.css';
import { usePageContentActions } from 'pages/Layout/PageContentContext/PageContent.hooks';

const AccountDetailsPage = () => {
  const { user } = useAuth();
  const { params, route, action } = useRouter();
  const location = useLocation();
  const { navigate } = useNavigateState();
  const { metaRoutes } = useGlobal('metaRoutes');
  const { isDevToolsVisible, isNavbarVisible, setLayoutState } = useLayout();
  const { setPageTitle, setPageDetail } = usePageContentActions();

  const setPageHeaderTitles = (route: RouteConfig) => {
    if (isView) {
      setPageTitle(route?.title || 'Mi Cuenta');
      setPageDetail(route?.detail || 'Datos personales');
    }
    if (isEdit) {
      setPageTitle(route?.title || 'Mi Cuenta');
      setPageDetail(route?.detail || 'Editar datos personales');
    }
  };

  const isNew = !!(params?.action && params.action === ACTIONS.NEW);
  const isEdit = !!(params?.action && params.action === ACTIONS.EDIT);
  const isView = !!(params?.action && params.action === ACTIONS.VIEW);
  const isUpdate = !!(params?.action && params.action === ACTIONS.UPDATE);

  const dataConfiguration = isEdit ? formConfigEdit : formConfigView;

  Object.assign(params, { table: 'registration' });

  const { dataRegistrationUpdate, isUpdating } = useUserRegistration();
  const [formData, setFormData] = useState<DataEntry | null>(null);

  // ======================================================================== //
  // NOTE: 1. - INITIAL FETCH OF USER REGISTRATION DATA, FROM CONNECTED TABLE..

  const query = {};
  if (user) {
    Object.assign(query, { uuid: user?.uuid });
  }

  const {
    // refetch: fetchUserRegistration,
    schema,
    isPending,
    isError,
  } = useQueryGet({
    endpoint: 'registration',
    query,
    onSuccess: ({ data }) => {
      const dataInitialized = initDetailData({ data, isExpandedSchema: true });
      setFormData(dataInitialized);
    },
  });

  // ======================================================================== //

  // NOTE: 3.A - PARSE USER REGISTRATION DATA...
  const isRegistrationFormComplete = getIsRegistrationComplete(formData);

  if (!dataConfiguration || isPending || !formData || isUpdating) {
    return <Spinner page dark />;
  }

  // ======================================================================== //

  if (isEdit && dataConfiguration?.buttons) {
    dataConfiguration.buttons = dataConfiguration.buttons.filter(
      (formButton) => formButton.action !== BUTTONS.LATER,
    );
  }

  const fromLocationView = metaRoutes.find((r: RouteConfig) => r.path === 'account') || {};

  return (
    <section css={styles}>
      <Row>
        <Col xs={12}>
          {/* <pre>REGISTRATION_FORM_COMPLETE: {String(isRegistrationFormComplete)}</pre>
          <pre>USER_VALIDATED: {String(!!(user?.user_role === USER_ROLE.VALIDATED))}</pre> */}
          <Form
            formConfig={dataConfiguration as FormConfig}
            data={{ ...formData }}
            isLocked={action === ACTIONS.VIEW}
            schema={schema}
            isNavPromptActive={action === ACTIONS.EDIT}
          />
        </Col>
        {/* <Col xs={12}>
          {isEdit ? (
            <Form
              formConfig={formConfigEdit}
              data={{ ...formData }}
              isLocked={isView}
              schema={schema}
              isNavPromptActive={isEdit}
            />
          ) : (
            <Form
              formConfig={formConfigView}
              data={{ ...formData }}
              isLocked={isView}
              schema={schema}
              isNavPromptActive={isEdit}
            />
          )}
        </Col> */}
      </Row>
      {formData && isView && (
        <Row direction="row" className="row-buttons-view">
          {/* <Col xs={6} className="col col-buttons-view">
            <Button
              type="button"
              label={'Editar Datos'}
              variant="outline"
              color="successXDark"
              onClick={() => {
                navigate(`/account/edit/${formData.id}`, {
                  state: {
                    action: ACTIONS.UPDATE,
                    from: { ...fromLocationView, path: '/account' },
                  },
                });
              }}
            />
          </Col>
          <Col xs={6} className="col col-buttons-view">
            <Button
              type="button"
              label={'Completar más tarde..'}
              variant="outline"
              color="grey"
              onClick={() => {
                navigate('/account');
              }}
            />
          </Col> */}
        </Row>
      )}
    </section>
  );
};

export default AccountDetailsPage;
