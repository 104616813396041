import { useBlocker } from 'react-router-dom';
import React from 'react';
import type { ReactElement } from 'react';
import { useGlobal } from 'store/useGlobalContext';
import { ModalNavigation } from 'components/Modal';

export const NavigationListener = (): ReactElement => {
  const { isNavigationBlocked, setIsNavigationBlocked } = useGlobal('isNavigationBlocked');

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isNavigationBlocked && currentLocation.pathname !== nextLocation.pathname,
  );

  if (blocker.state === 'blocked' ? true : false) {
    return (
      <ModalNavigation
        isPromptOpen={true}
        confirmNavigation={() => {
          blocker.proceed();
          setIsNavigationBlocked(false);
        }}
        cancelNavigation={() => blocker.reset()}
      />
    );
  }

  return <React.Fragment />;
};
