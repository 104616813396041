import { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { DataEntry } from 'types';
import type { TableConfig } from 'types';
import { usePagination } from 'components/Pagination';
import { tableConfig } from 'config/datatype/_default';
import { DataHead } from './components/DataHead';
import { DataRow } from './components/DataRow';
import { styles } from './DataTable.css';

// TODO: ⭐🚀 READ THIS !! - see: DataTable.EXAMPLE.types.ts
// PrismaAdmin - UI  Table - EXAMPLE PROPS
// ref: https://paljs.com/prisma-admin/#props-1

interface IDataTable {
  data: DataEntry[];
  config?: TableConfig;
  routePathBase?: string;
}

const DataTable = ({ data, config = tableConfig, routePathBase }: IDataTable) => {
  const { page, cursorStart, cursorEnd, setCursorRange } = usePagination();
  const [pageRows, setPageRows] = useState<DataEntry[]>(data);
  const [isBusy, setIsBusy] = useState(false);

  // ======================================================================== //
  // TODO: NEW..

  // log('__TABLE', 'magenta', { routePathBase }, config);

  // ======================================================================== //

  const doPageTransition = async () => {
    setIsBusy(true);
    setTimeout(() => setIsBusy(false), 200);
  };

  useEffect(() => {
    doPageTransition();
    setCursorRange();
  }, [page]);

  useEffect(() => {
    setPageRows(data?.slice(cursorStart, cursorEnd));
  }, [data, cursorStart, cursorEnd]);

  return (
    <div css={styles} className={`${isBusy && 'table-busy'}`}>
      <DataHead config={config} />
      {pageRows.length > 0 &&
        pageRows.map((rowData) => <DataRow key={rowData?.id || uuid()} rowData={rowData} config={config} />)}
    </div>
  );
};

export { DataTable };
