import { css } from '@emotion/react';
import { layout } from 'styles/layout.css';
import { colors } from 'styles/colors.css';
import { max, min } from '../../styles/media-queries-V1';

export const styles = css`
  width: 100%;
  height: 100%;
  padding: 0;
  min-height: 100vh;
  /* display: flex; */
  /* align-items: flex-start !important; */
  /* justify-content: flex-start !important; */
  /* align-items: center; */
  /* justify-content: center; */
  /* padding-top: 33vh; */
  padding-bottom: calc(100vh * 0.05);

  &:not(*-LoggedOut) .card {
    min-height: 720px;
  }

  form {
    section {
      margin: 0 !important;
      border-bottom: none !important;
      align-items: flex-start !important;
      justify-content: flex-start !important;
    }
  }

  button {
    width: 100%;
  }

  ${max.sm} {
    background-color: white;
  }

  .card {
    top: 20vh;
    left: 50vw;
    transform: translateX(-50%);
    position: absolute;
    margin: auto;
    padding-bottom: calc(${layout.padding} * 2);
    /* width: 33vw; */
    width: 100%;
    min-width: 40px;
    max-width: 100vw;
    border: none;
    box-shadow: none;
    min-height: 730px;
    ${min.sm} {
      min-width: 480px;
      max-width: 660px;
      border-radius: ${layout.borderRadius};
      border: ${layout.borderWidth} solid ${colors.greyXLight};
      box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.1);
    }

    .alert {
      min-height: 180px;
      margin-bottom: calc(${layout.padding} * 2);
      .spinner {
        height: 48px;
      }
      svg {
        font-size: 3em;
        padding: 0 0 0.25em 0;
      }
      p {
        min-height: 1em;
      }
      ul {
        transform: translateX(-0.66em);
        li {
          min-height: 1em;
          font-size: 1.1em;
          font-weight: 600;
          text-align: left;
        }
      }
    }
    .tabbed-container {
      min-height: 450px;
      .tab:hover {
        color: ${colors.grey};
        border-color: ${colors.greyLight};
      }
      .tab.selected {
        color: ${colors.greyDark};
        border-color: ${colors.grey};
        &:hover {
          color: ${colors.greyXDark};
          border-color: ${colors.greyDark};
        }
      }

      .tabbed-content {
        margin-top: ${layout.padding};
      }
      .form-status-message,
      .form-dev {
        /* display: none !important; */
      }
      hr {
        display: none !important;
      }
    }

    .tabbed-content {
      &.tab-login {
        label span {
          display: none;
        }
        button[type='submit'] {
          max-width: unset;
          width: 100%;
        }
      }
      label {
        color: ${colors.greyDark};
        span {
          color: ${colors.greyLight};
        }
      }
    }

    .row-buttons {
      padding-top: ${layout.padding};
    }
  }

  &.logged-out .card {
    min-height: unset;
  }
`;
