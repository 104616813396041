import { css } from '@emotion/react';
import { colors, layout, min } from 'styles';

export const styles = css`
  display: inline-flex;

  &:not(.modal) {
    .btn.btn-link {
      font-size: 1.05em;
      padding: 0 0.33em;
      margin: 0;
      text-decoration: underline;
      font-weight: 700 !important;
      background-color: transparent;
      border: none !important;
      -webkit-text-fill-color: ${colors.primaryDark};
      color: ${colors.primaryDark};
      /* opacity: 1; */
      &:hover {
        /* cursor: pointer; */
        -webkit-text-fill-color: ${colors.successXDark};
        color: ${colors.successXDark};
        background-color: transparent;
      }
    }

    &.is-disabled {
      button,
      button:hover {
        border: 1px solid red;
        pointer-events: none !important;
        -webkit-text-fill-color: ${colors.primaryDark};
        color: ${colors.primaryDark};
      }
    }
  }

  .modal {
    section {
      border: none !important;
      font-size: 1.1em;
      text-align: left;
      text-align: left;
      margin: 0 1em;
    }
  }

  ${min.xs} {
    .col-btn {
      order: 2;
    }
    .col-list {
      order: 1;
    }
  }
  ${min.lg} {
    .col-btn {
      order: 1;
    }
    .col-list {
      order: 2;
      /* padding-left: calc(${layout.padding} * 7) !important; */
    }
  }
`;
