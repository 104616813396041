import { ASC, DESC } from 'constants/sorting';
import { UUID_V4 } from 'utils/utils.string.pattern';

export const compareValues = (key: string, order = ASC) => {
  return function innerSort(a: any, b: any) {
    /* eslint no-prototype-builtins: "off" */
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }

    const _a = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
    const _b = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (_a > _b) {
      comparison = 1;
    } else if (_a < _b) {
      comparison = -1;
    }
    return order === DESC ? comparison * -1 : comparison;
  };
};

export const isPromise = (field: any) => {
  if (typeof field === 'object' && field[Symbol.toStringTag] === 'Promise') {
    return true;
  }
  return false;
};

export const getCelciusValue = ({ value, unit }: { value: number; unit: 'F' | 'K' | 'C' }) => {
  switch (unit) {
    case 'F':
      return ((value - 32) / 1.8).toFixed(1);
    case 'K':
      return (value - 273.15).toFixed(1);
    case 'C':
    default:
      return value.toFixed(1);
  }
};

export const isValueFileSymbol = (value: any) => {
  try {
    return !!(typeof value === 'object' && value[Symbol.toStringTag] === 'File');
  } catch {
    return false;
  }
};

export const getSymbolName = (value: any) => value[Symbol.toStringTag];

export const transformType = (data: any, dataType: string): any => {
  switch (dataType) {
    case 'boolean':
      if (typeof data === 'string') {
        return data === '1' || data === 'Y' || data === 'YES' || data === 'true' || data === 'on' || data === 'ON';
      } else {
        return data === 1;
      }
    case 'currency':
    case 'number':
      return data ? +data : undefined;
    case 'date':
    case 'Date':
      if (data && data instanceof Date) return data;
      if (data) return new Date(data);
      else return undefined;
    case 'string':
      if (!data) return undefined;
      return String(data);
    default:
      return data;
  }
};

export const isUUID = ({ str }: { str: string }) => {
  const isMatch = !!UUID_V4.test(str);
  return isMatch;
};
