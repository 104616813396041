import { Row, Col } from 'react-grid-system';
import { Fragment } from 'react';
import { useGlobal } from 'store/useGlobalContext';
import { useLayout } from 'pages/Layout/context/LayoutContext';
// import { RoutesLoaderData } from 'routes/routes.types';
// import { type RouteObject, useRouteLoaderData } from 'react-router-dom';
import { styles } from './FooterDev.css';

export const DevFooter = () => {
  const { ...state } = useGlobal();
  const { isDevToolsVisible } = useLayout();
  if (!isDevToolsVisible) return <Fragment />;

  const isAdminPath = !!location.pathname.startsWith('/admin');

  // const { transformedRoutes: metaRoutesTree } = useRouteLoaderData('root') as RoutesLoaderData;

  return (
    <Row css={styles} className="form-dev">
      {/* ============================================== */}
      {/* <Col xs={4}>
        <pre>
          <h3>categories:</h3>
          {JSON.stringify(state.categories, null, 2)}
        </pre>
      </Col> */}
      {/* ============================================== */}
      <Col xs={4}>
        <pre>
          <h3>metaCats:</h3>
          {JSON.stringify(state.metaCats, null, 2)}
        </pre>
      </Col>
      {/* ============================================== */}
      {/* <Col xs={3}>
        <pre>
          <h3>routes:</h3>
          {JSON.stringify(state.routes, null, 2)}
        </pre>
      </Col> */}
      {/* ============================================== */}
      <Col xs={4}>
        <pre>
          <h3>routesConfiguration:</h3>
          {JSON.stringify(state.routesConfiguration, null, 2)}
        </pre>
      </Col>
      {/* ============================================== */}
      {/* <Col xs={4}>
        <pre>
          <h3>metaRoutesTree: ({Number(state.metaRoutesTree?.[0]?.children?.length)})</h3>
          {JSON.stringify(state.metaRoutesTree, null, 2)}
        </pre>
      </Col> */}
      {/* ============================================== */}
      {/* <Col xs={4}>
        <pre>
          <h3>metaRoutesTree: ({Number(state.metaRoutesTree?.[0]?.children?.length)})</h3>
          {JSON.stringify(state.metaRoutesTree, null, 2)}
        </pre>
      </Col> */}
      {/* ============================================== */}
      <Col xs={4}>
        <pre>
          <h3>metaRoutes:</h3>
          {JSON.stringify(state.metaRoutes, null, 2)}
        </pre>
      </Col>
      {/* ============================================== */}
    </Row>
  );
};
