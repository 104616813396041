import { usePageContent } from 'pages/Layout/PageContentContext/PageContent.hooks';
import { useEffect, useRef, useState } from 'react';
import type { ReactElement } from 'react';
import { calcWidthByNumChars } from 'utils';

export const PageTitle = (): ReactElement => {
  const { title, detail, subtitle } = usePageContent();
  const isPageTitleWrapped = false; // TODO: REMOVE - CALCULATE INSIDE OF HEADER ??
  const [isWrapped, setIsWrapped] = useState(isPageTitleWrapped);
  const refTitle = useRef<any>();

  useEffect(() => {
    const columnElement = refTitle.current;
    if (title && columnElement?.clientWidth) {
      const numChars = Number(detail ? `${title} : ${detail}`.length : title.length);
      const fullTitleWidth = calcWidthByNumChars({ element: columnElement, numChars });
      if (!isPageTitleWrapped) {
        setIsWrapped(fullTitleWidth > columnElement.clientWidth && !!detail);
      }
    }
  }, [refTitle.current?.clientWidth, title, detail]);

  return (
    <>
      <h1 className="page-title" ref={refTitle}>
        {title}
        {detail && isWrapped && <br />}
        {detail && !isWrapped && <span className={'detail-separator'}> : </span>}
        {detail && <span className={`page-title-detail ${isWrapped && 'detail--is-wrapped'}`}>{detail}</span>}
      </h1>
      <h2 className="page-subtitle">{subtitle}</h2>
    </>
  );
};
