import type { FormConfig, TableConfig } from 'types';
import type { DetailConfig } from 'components/DetailSummary/DetailSummary.types';
import { ComponentType } from 'react';

export type DataConfiguration = {
  dataConfig: {
    tableConfig: TableConfig;
    formConfig: FormConfig;
    detailConfig: DetailConfig;
  };
};

export enum DataActions {
  LIST = 'list',
  VIEW = 'view',
  NEW = 'new',
  EDIT = 'edit',
  DELETE = 'delete',
}

export type DynamicRouteOptions<T extends DataActions[]> = {
  basePath: string;
  actions: T;
  components: Pick<
    {
      [DataActions.LIST]: ComponentType<any>;
      [DataActions.VIEW]: ComponentType<any>;
      [DataActions.NEW]: ComponentType<any>;
      [DataActions.EDIT]: ComponentType<any>;
      [DataActions.DELETE]: ComponentType<any>;
    },
    T[number]
  >;
};
