import { Row, Col, Hidden } from 'react-grid-system';
import { useNavigateState } from 'hooks/useNavigateState';
import { useState, useEffect } from 'react';
import { Menu, MenuItem, MenuButton, MenuDivider } from '@szhsin/react-menu';
import gravatar from 'gravatar';
import { BiUserCircle as IconUser, BiExit as IconLogout, BiHomeAlt as IconHome } from 'react-icons/bi';
import { IoMdArrowDropdown as IconCaretDown } from 'react-icons/io';
import { MdOutlineAdminPanelSettings as IconUserAdmin } from 'react-icons/md';
import { useRouter } from 'routes/hooks';
import { useAuth } from 'auth/AuthContext';
import type { StoredAuthenticatedUser } from 'auth';
import { NavItem } from 'components/Nav/NavItem';
import { UserLogin } from './UserLogin';
import { styles } from './UserCurrent.css';

const UserCurrent = () => {
  const { user } = useAuth();
  const { navigate } = useNavigateState();
  const { pathname, isAdminPath } = useRouter();
  const [avatar, setAvatar] = useState();
  const [userCurrent, setUserCurrent] = useState<any>();

  // log('USER:', 'grey', user);

  useEffect(() => {
    const updateUserCurrent = async () => {
      const avatarURL = await gravatar.url(user.email, { s: '200', r: 'pg', d: 'retro' });
      setAvatar(avatarURL);
      setUserCurrent(user);
    };

    if (user) {
      updateUserCurrent();
    }
  }, [user, pathname, localStorage.getItem('auth')]);

  if (!user) return <UserLogin />;

  const isAdminUser = !!(userCurrent?.user_role === 'ADMIN');
  const isRegisteredUser = !!(userCurrent?.user_role === 'REGISTERED');
  const IconUserLoggedIn = isAdminUser ? IconUserAdmin : IconUser;

  // log('IS_ADMIN', 'magenta', { isAdminUser, isRegisteredUser });

  // SHORTEN DISPLAY USERNAME, IF REQUIRED (TOO LONG)
  const displayName =
    userCurrent && userCurrent?.email.length <= 20
      ? userCurrent?.email
      : userCurrent?.email.match(/(.+?(?=@))/);

  return (
    <div css={styles}>
      <Row></Row>
    </div>
  );
};

export { UserCurrent };
