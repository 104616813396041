import type { TableConfig } from 'types';
import { ColumnThumb, LinkToDetail } from 'components/DataTable/components';
import { ListItemCard } from 'components/ListItemCard';
import { getRandomInt } from 'utils/utils.number';
import { COL_TYPE } from 'components/DataTable/DataTable.types';

export const tableConfig: TableConfig = {
  dataType: {
    key: '_default',
    role: 'table',
  },
  list: {
    colWidth: {
      xs: 12,
      md: 6,
    },
    listItem: {
      // TODO: MAKE Row OR Row | Card - DEPENDING ON IS_ADMIN
      component: ListItemCard,
      shapeRatio: 2.2,
    },
  },
  actions: {
    hasActionView: true,
    hasActionEdit: true,
    hasActionNew: true,
  },
  api: {
    endpoint: 'posts',
  },
  columns: [
    {
      label: 'IMAGE',
      name: 'image',
      columnType: COL_TYPE.DATA,
      // width: 2,
      widths: {
        xs: 0,
        lg: 1,
      },
      isSortable: false,
      render: ({ data }) => (data.image ? <ColumnThumb data={data} /> : null),
    },
    {
      label: 'NAME',
      name: 'name',
      columnType: COL_TYPE.DATA,
      isSortable: true,
      render: ({ data }) => <LinkToDetail targetID={data.id} label={data.name} />,
    },
    {
      label: 'DATE CREATED',
      name: 'created_at',
      columnType: COL_TYPE.DATA,
      // width: 2,
      widths: {
        xs: 0,
        lg: 2,
      },
      isSortable: true,
      render: ({ data }) => data.created_at,
    },
    {
      label: 'ACTIVE',
      name: 'is_active',
      columnType: COL_TYPE.DATA,
      // width: 2,
      widths: {
        xs: 1,
        lg: 1,
      },
      isSortable: true,
      render: ({ data }) => data.is_active,
    },
    {
      label: 'ID',
      name: 'id',
      columnType: COL_TYPE.DATA,
      // width: 2,
      widths: {
        xs: 0,
        lg: 1,
      },
      isSortable: true,
      render: ({ data }) => data.id,
    },
    {
      label: 'VISITS',
      name: 'visits',
      columnType: COL_TYPE.DATA,
      // width: 2,
      widths: {
        xs: 0,
        xl: 1,
      },
      isSortable: true,
      render: ({ data }) => getRandomInt(0, 200),
    },
  ],
};
