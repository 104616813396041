import { css } from '@emotion/react';
import { layout } from 'styles/layout.css';
import { colors } from 'styles/colors.css';

export const styles = css`
  padding: ${layout.padding};

  .dev-row {
    overflow: visible !important;
    & > div {
      overflow: visible !important;
    }
    & > div:nth-of-type(1) {
      width: 50% !important;
      max-width: 60% !important;
      text-align: left !important;
    }
    & > div:nth-of-type(2) {
      text-align: left !important;
    }
  }

  .col-route div div:nth-of-type(2) {
    text-align: left !important;
  }

  pre {
    overflow: visible;
    padding: 0 calc(${layout.padding} * 1.5);
    white-space: pre-wrap;
    font-size: 0.7em;
  }

  hr {
    opacity: 0.5;
  }

  strong {
    font-size: 0.8em;
    color: ${colors.greyLight};
  }
`;
