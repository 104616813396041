import { css } from '@emotion/react';
import { colors, cssFontMono } from 'styles';

export const styles = css`
  padding: 2em 0 2em 0;
  margin-top: 2.5em;

  .form-dev-buttons {
    & > div {
      display: flex;
      flex-direction: row;
    }
  }

  .dev-row {
    overflow: visible !important;
    & > div {
      overflow: visible !important;
    }
    & > div:nth-of-type(1) {
      width: 50% !important;
      max-width: 60% !important;
      text-align: left !important;
    }
    & > div:nth-of-type(2) {
      text-align: left !important;
    }
  }

  .col-route div div:nth-of-type(2) {
    text-align: left !important;
  }

  pre {
    overflow: visible !important;
  }

  hr {
    opacity: 0.5;
  }

  .json-dump {
    margin-bottom: 5em;
    textarea {
      padding: 1em 1.5em;
      min-height: 20em;
      ${cssFontMono};
      font-size: 0.85em;
      font-weight: 500;
      letter-spacing: 0.025em;
    }
  }

  .jsonview-code {
    display: block;
    padding: 0.5rem;
    /* background-color: rgb(40, 44, 52); */
    background-color: ${colors.greyXDark};
    color: rgb(136, 198, 190);
    overflow-x: scroll;
    border-radius: 5px;

    span {
      ${cssFontMono};
      font-size: 0.85em;
      font-weight: 400;
      letter-spacing: 0.05em;
    }
  }

  .jsonview-line {
    white-space: nowrap;
  }
  .jsonview-hi-line {
    white-space: nowrap;
    background-color: rgb(80, 80, 95);
  }

  .jsonview-str {
    color: rgb(121, 182, 242);
  }
  .jsonview-num {
    color: rgb(255, 255, 200);
  }
  .jsonview-bool {
    color: rgb(197, 165, 197);
  }
  .jsonview-null {
    color: rgb(197, 165, 197);
  }
  .jsonview-prop {
    color: rgb(250, 200, 99);
  }

  .jsonview-ln {
    display: inline-block;
    padding-right: 1rem;
    color: rgb(178, 178, 178);
    text-align: right;
    user-select: none;
  }
`;
