import { useNavigate } from 'react-router';
import type { UseFormReturn } from 'react-hook-form';
import { useNavigateState } from 'hooks/useNavigateState';
import type { DataEntry } from 'types';
import { useRouter } from 'routes/hooks';
import { api } from 'api';
import type { FormResetArgs } from 'components/Form';
import { optionsFormReset, optionsSetQuiet } from './config';

type UseFormMethods = {
  formMethods: UseFormReturn;
};

export const useFormMethods = ({ formMethods: { reset, setValue } }: UseFormMethods) => {
  const navigate = useNavigate();
  const { fromLocation, route } = useRouter();

  const resetFields = async ({ data = undefined, options = {} }: FormResetArgs) => {
    reset(data, { ...optionsFormReset, ...options });
  };

  const handleCancel = () => {
    navigate(fromLocation.pathname);
  };

  // FORM UI LOGIC ========================================================== //

  const handleCopyFrom = async ({ isChecked, copyFrom, targetFields }: any) => {
    if (isChecked) {
      const { table, ...params } = copyFrom.params;
      const response = await api.get(table, { params });
      if (response.status > 300) return;
      const sourceData: DataEntry = response.data.data[0];
      targetFields.flatMap((targetField: DataEntry) => {
        const key = targetField.name as string;
        const value = sourceData[key];
        setValue(key, value, optionsSetQuiet);
      });
    } else {
      targetFields.flatMap((targetField: DataEntry) => {
        const key = targetField.name as string;
        const value = targetField.value;
        setValue(key, value, optionsSetQuiet);
      });
    }
  };

  // ======================================================================== //

  return {
    // METHODS
    resetFields,
    handleCancel,
    handleCopyFrom,
  };
};
