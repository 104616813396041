import { faker, fakerES } from '@faker-js/faker';
import { genRandomWord } from './data.misc';

export const genAddressElement = (element: string) => {
  const mocks = {
    calle: fakerES.location.street(),
    address_street_type: faker.helpers.arrayElement(['Calle', 'Via', 'Passeo', 'Plaza', 'Ronda', 'C/']),
    address_street_name: fakerES.location.street(),
    address_street_number: fakerES.location.buildingNumber()?.replace(/, /g, ''),
    address_block: faker.helpers.arrayElement(['3C', '2D', '4A', '', '', '', '', '', '', '', '', '']),
    address_escalera: faker.helpers.arrayElement(['A', 'B', '', '', '', '', '', '', '', '', '', '']),
    address_piso: faker.helpers.arrayElement(['Bajos', 'Ento', '1º', '2º', '3º', '4º', '5º']),
    address_info: faker.helpers.arrayElement(['s/n', '1ª', '2ª', '3ª', '4ª', '5ª']),
  } as { [key: string]: string | number };

  const mockedValue = mocks[element];

  return mockedValue;
};

export const genAddressValue = (fieldName: string) => {
  fieldName.replace('biz_', '');

  switch (fieldName) {
    case 'address_street_type':
      return genAddressElement('address_street_type');
    case 'address_street_name':
      return genAddressElement('address_street_name');
    case 'address_street_number':
      return genAddressElement('address_street_number');
    case 'address_block':
      return genAddressElement('address_block');
    case 'address_escalera':
      return genAddressElement('address_escalera');
    case 'address_piso':
      return genAddressElement('address_piso');
    case 'address_door':
      return genAddressElement('address_door');
    case 'address_info':
      return faker.helpers
        .arrayElement([fakerES.lorem.sentences({ min: 1, max: 2 }), '', '', ''])
        .toLowerCase();
    default:
      return genRandomWord();
  }
};
