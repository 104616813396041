import { css } from '@emotion/react';
import { colors, layout, cssInputBox, cssInputText } from 'styles';

export const styles = css`
  .input-select {
    width: 100%;

    .select__control {
      ${cssInputBox}
      font-weight: 600;
      padding: 0 0.5em;
    }
    .select__value-container,
    .select__single-value,
    .select__input-container {
      color: ${colors.grey};
      caret-color: ${colors.grey};
    }

    .select__control--is-focused,
    .select__control--menu-is-open {
      border-color: ${colors.primaryLight};
    }

    .select__menu {
      transform: translateY(calc(-${layout.padding} * 0.5));
      ${cssInputText}
      -webkit-text-fill-color: ${colors.greyDark};
      color: ${colors.greyDark};
      font-weight: 500;
      text-indent: 0.5em;
    }

    /*
    .option-flag {
      margin-right: 0.66em;
      font-size: 1.5em;
      transform: translateY(0.18em);
      display: inline-flex;
    }
    */

    .select__single-value {
      display: flex;
      justify-content: space-between;
    }

    .option-custom {
      display: flex;
      line-height: 1.5;
      padding-top: 0.25em;
      span:nth-of-type(1) {
        order: 2;
        transform: translateY(0.33em);
      }
      span:nth-of-type(2) {
        order: 1;
        font-size: 1.5em;
        margin-right: 0.5em;
        /* transform: translateY(0.18em); */
      }
    }
  }
`;
