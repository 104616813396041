import { ToastContainer } from 'react-toastify';
import React, { useEffect } from 'react';
import type { ReactNode, ReactElement } from 'react';
import { useAuth } from 'auth/AuthContext';
import { useLayout } from 'pages/Layout/context/LayoutContext';
import { Footer } from 'components/Footer';
import { HeaderApnaes as Header } from 'components/Header';
import { NavHorizontal } from 'components/Nav';
import { Sidebar } from 'components/Sidebar';
import { Spinner } from 'components/Spinner';
import { DevFooter } from 'components/Footer/FooterDev';
import { NavItemsProvider } from 'components/Nav/NavItemsContext/NavItemsProvider';
import { ScrollControl } from './components/ScrollControl/ScrollControl';
import { defaultToastOptions } from './components/Toast/Toast.config';

export const Layout = ({ children }: { children: ReactNode }): ReactElement => {
  const { isAuthenticated } = useAuth();
  const { setLayoutState } = useLayout();

  useEffect(() => {
    setLayoutState({ isNavbarVisible: true });
  }, [location.pathname]);

  if (!isAuthenticated) {
    return <Spinner page color="warning" />;
  }

  return (
    <React.Fragment>
      <Header />
      <NavItemsProvider>
        <NavHorizontal />
        <Sidebar />
        <main>{children}</main>
        <Footer />
      </NavItemsProvider>
      <DevFooter />
      <ScrollControl />
      <ToastContainer {...defaultToastOptions} />
    </React.Fragment>
  );
};
