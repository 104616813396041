import { NavLink } from 'react-router-dom';
import { useGlobal } from 'store/useGlobalContext';
import { ROW_ACTION } from '../DataTable.types';
import type { RouteConfig } from 'routes/routes.types';

export const LinkToCategoryParent = ({
  catId,
  action = ROW_ACTION.VIEW,
}: {
  catId: number | string;
  action?: ROW_ACTION;
}) => {
  const { metaRoutes } = useGlobal('metaRoutes');
  const categoryRoute = metaRoutes?.find((route: RouteConfig) => route.uuid === catId);
  const parent = metaRoutes?.find(
    (route: RouteConfig) =>
      route.slug === categoryRoute?.parent?.slug || route.slug === categoryRoute?.parent,
  );

  return parent ? (
    <NavLink to={parent?.pathname} className="link-cat-parent">
      {<strong>{parent?.title}</strong>}
    </NavLink>
  ) : (
    <NavLink to={categoryRoute?.pathname} className="link-cat-parent">
      <strong>{categoryRoute?.title}</strong>
    </NavLink>
  );
};
