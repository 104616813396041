import { useState } from 'react';
import type { ChangeEvent, FocusEvent } from 'react';
import { useDebounce } from '@react-hook/debounce';
import type { UseFormReturn, UseFieldArrayReturn, FieldValues } from 'react-hook-form';

interface UseFormItem {
  FIELD_KEY: string;
  index: number;
  uuid: string;
  isFocussed: boolean;
  isEmpty: boolean;
  formMethods: UseFormReturn<FieldValues, any, undefined>;
  fieldArray: UseFieldArrayReturn<FieldValues, string, 'uuid'>;
}

export const useFormItem = ({ FIELD_KEY, index, uuid, isFocussed, isEmpty, formMethods, fieldArray }: UseFormItem) => {
  // FORM ACTIONS =========================================================== //
  const { getValues, setValue, formState } = formMethods;
  const [isValid, setIsValid] = useDebounce(isEmpty ? false : true, 100, false);
  const [isReadOnly, setIsReadOnly] = useState(!!(!isEmpty && !isFocussed));

  const onClickEdit = (evt: MouseEvent) => ((document as Document).getElementById(uuid) as HTMLElement).focus();

  const handleFocus = (evt: FocusEvent<HTMLInputElement>) => setIsReadOnly(false);

  const handleBlur = (evt: FocusEvent<HTMLInputElement>) => {
    if (evt.target.value.length >= 3) {
      setIsReadOnly(true);
    }
  };

  const handleKeyDown = (evt: ChangeEvent<HTMLInputElement>) => {
    const numChars = evt.target.value.length;
    numChars >= 3 ? setIsValid(true) : setIsValid(false);

    if ((evt as any).key === 'Enter') {
      evt.preventDefault();
      (document.getElementById(uuid) as HTMLInputElement).value = evt.target.value.trim();
      setTimeout(() => evt.target.blur(), 200);
    }
  };

  return {
    onClickEdit,
    handleKeyDown,
    handleFocus,
    handleBlur,
    isValid,
    isReadOnly,
  };
};
