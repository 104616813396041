import type { DataFilter, DateRange } from 'types';

// TODO: MOVE TO FiltersContext ???
// export type FilterDateSelected = DataFilter<string | undefined> & { localized: string | undefined };
export type FilterDateSelected = DataFilter<string | undefined> & {
  datetime: DateRange<string>;
  localized: string | undefined;
};

export type FilterLocation = DataFilter<string | undefined> & { label: string | undefined };

// ======================================================================== //
// STATE DEFINITION (SPECIFIC):

export interface ContextActions {
  setFilters: (value: unknown) => void;
}

export interface InviteFiltersState extends ContextActions {
  searchQuery: DataFilter<string | undefined>;
  province: FilterLocation;
  entityType: DataFilter<string | undefined>;
}

// ======================================================================== //
// INIT CONTEXT: ContextState.ts

export const initialState: InviteFiltersState = {
  setFilters: () => ({}),
  searchQuery: {
    value: undefined,
    isActive: false,
  },
  province: {
    value: undefined,
    label: undefined,
    isActive: false,
  },
  entityType: {
    value: undefined,
    isActive: false,
  },
};
