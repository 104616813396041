import { css } from '@emotion/react';
import { layout, colors, BREAKPOINTS, min, max } from 'styles';

export const styles = css`
  padding-top: 5.5em;
  h1 {
    /* margin-bottom: 0; */
  }

  h3 {
    margin-top: 0.5em;
    margin-bottom: 2em;
  }

  hr {
    margin: 1.7em 0 1.3em;
  }

  .tool-bar {
    margin-bottom: calc(${layout.padding} * 2);
    & > div {
      padding: 0em 0 1.5em 0;
      ${min.md} {
        padding: 0.2em 0;
      }
    }

    .col-search {
      order: 1;
    }

    .col-new {
      display: flex;
      justify-content: flex-end;
      order: 2;

      ${max.md} {
        order: -1;
        a {
          width: 100%;
        }
      }
    }
  }
`;

export const styles__V1 = css`
  & > div {
    padding: 0 calc(${layout.padding} * 1.75) !important;
  }

  h1 {
    /* margin-bottom: 0; */
  }

  h3 {
    margin-top: 0.5em;
    margin-bottom: 2em;
  }

  hr {
    margin: 1.7em 0 1.3em;
  }
`;
