import type { CSSProperties, ReactElement } from 'react';
import type { SizeUI, RegexMatchedString } from 'types';
import type { ColorName, HexColor } from 'styles';
import type { IconType } from 'react-icons';

export type ButtonType = 'button' | 'reset' | 'submit';
export enum ButtonTypeEnum {
  button = 'button',
  reset = 'reset',
  submit = 'submit',
}

export type ButtonVariant = 'outline' | 'clear' | 'icon' | 'solid';

export type IconPos = 'left' | 'right';

export interface ButtonProps {
  children?: any;
  className?: string;
  color?: ColorName | HexColor;
  colorHover?: string;
  icon?: string | ReactElement | IconType;
  iconPos?: IconPos;
  iconScale?: number;
  isBusy?: boolean;
  isDisabled?: boolean;
  key?: number;
  label?: string;
  id?: string;
  onClick?: ((evt?: any) => void) | undefined;
  // onClick: () => void;
  size?: SizeUI;
  padded?: boolean;
  type?: ButtonType;
  variant?: ButtonVariant | string | undefined;
  style?: CSSProperties;
}

export interface ButtonLinkProps extends ButtonProps {
  to: string;
  state?: {
    [key: string]: string | number | boolean;
  };
  label?: string;
}
