import { css } from '@emotion/react';
import { BREAKPOINTS, colors, layout, max, min } from 'styles';

export const styles = css`
  padding: 0 !important;

  /* TODO: DEV - REMOVE !! */
  /* #form-eventos {
    padding-top: 100px !important;
  } */

  ${min.sm} {
    padding: calc(${layout.padding} * 0) !important;
  }

  .col-form {
    section {
      padding-bottom: 0;
    }
  }

  .col-list {
    overflow-x: hidden;
    padding: 0 calc(${layout.padding} * 1.66) !important;

    .no-entries {
      padding: 0 2px;
      .card {
        border-radius: ${layout.borderRadius};
      }
    }
    .results-list {
      .grid-item-container {
        /* height: unset !important; */
        /* min-height: unset !important; */
        /* max-height: 200px !important; */
        ${min.md} {
          /* height: unset !important; */
          /* min-height: unset !important; */
          /* max-height: 200px !important; */
        }
        ${min.lg} {
          /* min-height: 300px !important; */
          padding-top: 30%;
        }
        ${min.xl} {
          /* min-height: 200px !important; */
          padding-top: 18%;
        }
        ${min.xxl} {
          /* min-height: 200px !important; */
          padding-top: 16%;
        }
      }
    }
  }

  h1 {
    margin-bottom: 0 !important;
    color: ${colors.primary};
    span {
      color: ${colors.greyLight};
      opacity: 1;
    }
  }
  h2 {
    margin-top: 0 !important;
    margin-bottom: 0.4em !important;
    color: ${colors.primaryXLight};
    span {
      color: ${colors.greyLight};
      opacity: 1;
    }
  }
  h1 + p {
    font-size: 1.25em;
    margin-top: 0 !important;
    margin-bottom: 2.5em;
  }

  .search-hr {
    ${max.lg} {
      margin-top: 3em !important;
      margin-bottom: 2.5em !important;
    }
    ${min.lg} {
      /* display: none !important; */
      opacity: 0;
    }
  }

  h2.search-summary {
    padding-bottom: 0.5em !important;
  }

  // FILTER SUMMARY ========================================================= //

  .filters-summary {
    flex-direction: column !important;
    background: ${colors.greyXLight};
    margin: 0 0 calc(${layout.padding} * 2) !important;
    padding: calc(${layout.padding} * 1) calc(${layout.padding} * 1.75) calc(${layout.padding} * 1.25);

    & > div:not(:first-of-type) {
      /* padding: calc(${layout.padding} * 1) 0; */
      height: 2.25em;
    }

    h2 {
      color: ${colors.grey};
      padding-bottom: 0em;
    }
    label.filter-label {
      color: ${colors.greyDark};
      margin: 0 !important;
    }
    .filter-value {
      color: ${colors.primary};
      font-weight: 600;
      span {
        color: ${colors.greyLight};
      }
      &.filter-date {
        text-transform: capitalize;
        span {
          color: ${colors.grey};
          margin: 0 0.25em;
        }
      }
      &.filter-search {
        span {
          color: ${colors.grey};
          margin: 0 0.15em;
        }
        span:first-of-type {
          margin-right: 0.15em;
        }
        span:last-of-type {
          margin-left: 0.15em;
        }
        &.empty span {
          color: ${colors.greyLight};
          margin: 0;
        }
      }
    }
  }
`;
