import type { RouteObject } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import { RouteConfig } from 'routes';
import { ROUTES_BASE, ROUTES_WEB, ROUTES_ADMIN } from '../routes';

type RouteObjectsAsConfigs = { [key: string]: RouteObject[] };

// ========================================================================== //
// CATEGORY ROUTE GENERATOR

export const genRouteObjectsFromConfig = (
  { routesConfiguration }: { routesConfiguration: RouteConfig[] },
  newRouteObjects: RouteObject[] = [],
): RouteObject[] => {
  if (!routesConfiguration) return [];

  for (const route of Object.values(routesConfiguration)) {
    if (route?.path?.startsWith('cat')) continue;

    const routeObject: RouteObject = {
      id: route?.uuid,
      path: route?.path,
    };

    newRouteObjects.push(routeObject);
  }

  return newRouteObjects;
};

// ========================================================================== //
// BASE ROUTE OBJECTS

export const getRouterRoutes = (): RouteObjectsAsConfigs => {
  const ROUTES: RouteObject[] = cloneDeep(ROUTES_BASE) as RouteObject[];

  return {
    ROUTES: cloneDeep(ROUTES),
    ROUTES_WEB: cloneDeep(ROUTES_WEB),
    ROUTES_ADMIN: cloneDeep(ROUTES_ADMIN),
  } as RouteObjectsAsConfigs;
};
