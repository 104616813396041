import type { FormConfig } from 'types/form.types';
import { INPUTS } from 'types/form.types';
import { AUTH_USER } from 'types/form.types';
import type { FormButton } from 'components/Form/components/FormButtonBar/FormButtonBar.types';
import type { FormHeroConfig } from 'types/form.types';
import { types, BUTTONS } from 'components/Form/components/FormButtonBar/FormButtonBar.types';
import type { SelectOption } from 'components/FormUI/Select';
import {
  FIELDS_ADDRESS,
  FIELDS_DOCS_BIZ,
  FIELDS_DOCS_PERSONAL,
  FIELDS_SOCIALS,
  FIELDS_CONTACT_PUBLIC,
  FIELDS_CONTACT_PRIVATE,
} from 'config/form.sections';
import { SPAIN_PROVINCES } from 'constants/spain';
import { DATATYPE, ENDPOINT } from './registros.config';
import { getFieldsetConfig } from 'config/data.utils/getFieldsetConfig';

let i = 0;

const hero: FormHeroConfig = {
  heroTitleKey: ['name'],
};

const buttons: FormButton[] = [
  {
    type: types.button,
    label: 'CANCEL',
    action: BUTTONS.CANCEL,
  },
  {
    type: types.submit,
    label: 'SAVE',
    action: BUTTONS.SUBMIT,
  },
];

// NOTE: FOLLOWING CONFIG WILL BE MERGED WITH form.config.ts/globalFieldProps
// TO POPULATE COMMON PROPS, OPTIONS etc
export const formConfig: FormConfig = {
  dataType: { key: DATATYPE, role: 'form' },
  api: { endpoint: ENDPOINT },
  isNavPromptActive: true,
  hero,
  buttons,
  // ----------------------------------------------------------------- //
  sections: [
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Datos de del medio o canal (REGISTROS)',
      fields: [
        {
          name: 'nombre',
          label: 'name del medio',
          inputType: INPUTS.text,
          required: true,
        },
      ],
    },
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Datos de del titular o representante',
      fields: [
        {
          name: 'owner_name',
          label: 'nombre del titular',
          inputType: INPUTS.text,
          required: true,
        },
        {
          name: 'position',
          label: 'cargo del titular',
          inputType: INPUTS.text,
          required: true,
        },
        {
          name: 'position',
          label: 'cargo del titular',
          inputType: INPUTS.text,
          required: true,
        },
        {
          name: 'document_type',
          label: 'tipo de documento',
          inputType: INPUTS.select,
          required: true,
          options: [
            {
              value: 'DNI',
              label: 'DNI',
            },
            {
              value: 'NIE',
              label: 'NIE',
            },
            {
              value: 'Pasaporte',
              label: 'Pasaporte',
            },
          ] as SelectOption[],
        },
        {
          name: 'document_number',
          label: 'número de documento',
          inputType: INPUTS.text,
          required: true,
        },

        // ======================================================================== //
        //
        /*
                {
          name: 'image',
          placeholder: 'Escoge archivo...',
          hint: 'choose image to upload',
          inputType: INPUTS.image,
        },
        {
          name: 'cat_id',
          label: 'Categoría',
          inputType: INPUTS.selectCategory,
          defaultValue: 'canales',
          // inputType: INPUTS.select,
          // options: [],
          required: true,
          colWidth: {
            xs: 6,
          },
        },
        */
        // ======================================================================== //
        /*
        {
          name: 'category',
          label: 'Categoría',
          inputType: INPUTS.selectCategory,
          // inputType: INPUTS.select,
          // options: [],
          colWidth: {
            xs: 6,
          },
        },
        */
      ],
    },
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Dirección / Ubicación',
      copyFrom: {
        name: 'copy_from_user_address',
        label: `Marcar si la dirección coincide con la del titular : ${AUTH_USER.EMAIL}`,
        inputType: INPUTS.checkbox,
        defaultValue: false,
        source: {
          endpoint: 'registration',
          uuid: AUTH_USER.UUID,
        },
        target: {
          // prefix: 'biz_',
          // fields: FIELDS_ADDRESS_BIZ
          fields: getFieldsetConfig(FIELDS_ADDRESS, { prefix: 'biz_' }),
        },
      },
      fields: [...getFieldsetConfig(FIELDS_ADDRESS)],
    },

    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Más detalles y Descripción sobre la actividad',
      description: 'Realice una descripción de la actividad que será visible para los usuarios',
      fields: [
        {
          name: 'url',
          label: 'URL de la página web (o similar) de la entidad o asociación:',
          inputType: INPUTS.url,
          required: false,
        },
        {
          name: 'socials',
          inputType: INPUTS.socials,
          colWidth: {
            xs: 12,
            md: 12,
          },
        },
        // {
        //   name: 'sector',
        //   label: 'Ámbito de actuación',
        //   inputType: INPUTS.select,
        //   options: [
        //     {
        //       value: 'local',
        //       label: 'Local',
        //     },
        //     {
        //       value: 'regional',
        //       label: 'Regional',
        //     },
        //     {
        //       value: 'nacional',
        //       label: 'Nacional',
        //     },
        //     {
        //       value: 'internacional',
        //       label: 'Internacional',
        //     },
        //   ] as SelectOption[],
        //   colWidth: {
        //     xs: 6,
        //   },
        // },
        {
          name: 'description',
          label: 'Descripción',
          hint: 'más información',
          inputType: INPUTS.textarea,
          required: false,
        },
        {
          name: 'activities',
          label: 'Tipo de actos o actividades que realiza',
          inputType: INPUTS.textarea,
          required: false,
        },
      ],
    },
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Datos de Contacto Públicos',
      description: 'visibles a todo el mundo en la web pública',
      fields: [...getFieldsetConfig(FIELDS_CONTACT_PUBLIC)],
    },
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Datos de Contacto para medios',
      description: 'visibles solo por titulares de otros medios o entidades',
      fields: [...getFieldsetConfig(FIELDS_CONTACT_PRIVATE)],
    },
  ],
  // ----------------------------------------------------------------- //
};
