import { getTimeStamp } from 'utils';
import type { StoredAuthentication, UserSession, UserSessionAccess, UserSessionUser } from '../types/user.types';
import { AUTH_USER_TOKEN } from '../client/client.config';
import ls from 'localstorage-slim';

// NOTE: ROBUST REPLACEMENT for LOCALSTORAGE
// https://www.npmjs.com/package/localstorage-slim

export const UserAuthSession = {
  get: function (): UserSession {
    try {
      const storedAuth = ls.get(AUTH_USER_TOKEN);
      if (storedAuth) {
        return JSON.parse(storedAuth as string);
      }
    } catch (error) {
      error instanceof Error ? console.error(error.message) : console.error(String(error));
      return null;
    }
  },

  set: function (userAuthSession: UserSession) {
    ls.set(AUTH_USER_TOKEN, JSON.stringify(userAuthSession));
  },

  delete: function () {
    const storedAuth = this.get();
    if (storedAuth) {
      ls.remove(AUTH_USER_TOKEN);
    }
  },

  get user(): UserSessionUser | undefined {
    return this.get()?.user;
  },

  get isExpired() {
    const storedAuth = UserAuthSession.get();
    if (storedAuth) {
      const timestampNow = getTimeStamp();
      const tokenExpires = storedAuth?.access?.expires;
      const isTokenExpired = !!(tokenExpires < timestampNow);

      return isTokenExpired;
    }

    // TODO: 'undefined' to trigger re-authentication
    return;
  },
};

// ======================================================================== //

export const storeAuthentication = ({ authentication, accessToken, user: userAuth }: StoredAuthentication) => {
  const { exp: expires, iat, jti } = authentication.payload;
  const access: UserSessionAccess = { accessToken, jti, expires, expiryHours: (expires - iat) / 60 / 60 };

  if (!userAuth) {
    UserAuthSession.delete();
    const newAuth: UserSession = { access } as UserSession;
    UserAuthSession.set(newAuth);
    return;
  }

  const user: UserSessionUser = {
    id: userAuth.id,
    email: userAuth.email,
    uuid: userAuth.uuid,
    user_role: userAuth.user_role,
  };

  const newAuth: UserSession = { access, user };
  UserAuthSession.set(newAuth);
};

export const updateStoredAuth = ({ user: userAuth }: { user: StoredAuthentication }) => {
  if (!userAuth) {
    // UserAuthSession.delete();
    return;
  }
  const auth = UserAuthSession.get();
  const access: UserSessionAccess = {
    ...auth.access,
    expires: getTimeStamp() / 60 / 60,
  };
  const user: UserSessionUser = {
    id: userAuth.id,
    email: userAuth.email,
    uuid: userAuth.uuid,
    user_role: userAuth.user_role,
  };

  const updatedAuth: UserSession = { access, user };
  UserAuthSession.set(updatedAuth);
};
