import { css } from '@emotion/react';
import { Tooltip } from 'components/Tooltip';
import { layout } from 'styles/layout.css';
import { colors } from 'styles/colors.css';

export const styles = css`
  white-space: nowrap;
  transform: translateX(calc(${layout.padding} / 2));

  a {
    display: block;
    padding: 0;
    color: ${colors.primary};
    font-weight: 500;
  }
  button {
    /* padding: 0.5em 1em 0.5em 1em; */
    padding: 1em;
    margin: 0 !important;
    transform: scale(0.8);

    svg {
      transform: scale(1.8);
    }
  }

  .mono {
  }
`;
