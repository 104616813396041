import { Link } from 'react-router-dom';
// ICONS:
import { MdOutlineEmail as IconEmail } from 'react-icons/md';
import { TbLink as IconURL, TbPhone as IconTelV2 } from 'react-icons/tb';
import { DATE_FORMAT } from 'i18n/datetime';
import type { SocialConfig } from 'components/Socials';
import { SocialLink, Socials } from 'components/Socials';

import { formatDate, isJSONString, isPOJO, PATTERNS, toUpperCaseFirst } from 'utils';
import { configSocials } from 'config/socials.config';
import type { FieldConfig } from './DetailSummary.types';

type DetailOptions = {
  isRepeater: boolean;
  isTextArea: boolean;
  isTagsField: boolean;
  isListByID: boolean;
};

type DetailRowProps = { field: FieldConfig };

interface ValueWrapperProps {
  field: FieldConfig;
  options: DetailOptions;
}
interface ValueContentProps extends ValueWrapperProps {
  repeaterValue?: any;
}

const socials = configSocials.map((s: SocialConfig) => (s as any).name || s.name);

// MAIN: VALUE ========================================================= //

export const ValueContent = ({ field, repeaterValue, options }: ValueContentProps) => {
  const value = repeaterValue || field?.value;

  switch (true) {
    case isPOJO(field?.value): {
      // const { value: _label, url } = field.value;
      const label = String(field?.value);
      const url = String((field as any)?.url);
      return <Link to={`/${url}`}>{label}</Link>;
    }

    case field?.name === 'url_multi' && isJSONString(value):
      return (
        <>
          {value.map((item: any) => (
            <a
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'orange' }}
              className="value-url"
              key={item.url}
            >
              <IconURL /> {item.name}
            </a>
          ))}
        </>
      );

    case field?.name === 'url_multi' && Array.isArray(field.value):
      return <SocialLink value={value} scale={1} />;

    case isPOJO(repeaterValue) && socials.includes(Object.keys(repeaterValue).at(0)): {
      const social = Object.entries(repeaterValue).at(0);
      const socialUrl = social?.[1] as string;

      return (
        <a
          href={socialUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'orange' }}
          className="value-url"
        >
          <IconURL /> {socialUrl}
        </a>
      );
    }

    case field?.name === 'socials' && isJSONString(value):
      return <Socials links={value} scale={1} />;

    case field?.name === 'date' || field?.name.startsWith('date'):
      return formatDate(new Date(value), DATE_FORMAT.LONG);

    case PATTERNS.URL.test(value):
      return (
        <a
          href={value}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'orange' }}
          className="value-url"
        >
          <IconURL /> {value}
        </a>
      );

    case PATTERNS.EMAIL.test(value):
      return (
        <a href={`mailto:${value}`} className="value-email">
          <IconEmail /> {value}
        </a>
      );

    case PATTERNS.TEL.test(value):
      return (
        <a href={`tel:${value}`} className="value-tel">
          <IconTelV2 /> {value}
        </a>
      );

    case typeof value === 'boolean':
      return String(value);

    case typeof value === 'string':
      return toUpperCaseFirst(value);

    default:
      return value;
  }
};
