import { css } from '@emotion/react';
import { colors, layout, cssInputBox, cssInputText, forms } from 'styles';
import Color from 'color';

const _colorTagBg = Color(colors.successDark).desaturate(0.55).lighten(0.95).hex();
const colorTagBg = Color(colors.primaryXLight).desaturate(0.35).lighten(0.85).hex();

export const styles = css`
  .ant-select.ant-select-multiple {
    width: 100%;
    padding: 0;
    &,
    &.ant-select-open .ant-select-selector {
      &:hover,
      &:focus,
      &:active {
        border-color: ${colors.primary}!important;
      }
    }

    .ant-select-arrow {
      width: calc(${forms.inputs.height} - ${layout.padding});
      height: ${forms.inputs.height};
      border-left: ${layout.borderWidth} solid ${colors.greyXLight};
      transform: translateY(-24px);
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 1.4em;
        font-weight: 700;
        color: ${colors.greyLight};
        transform: scaleY(0.9) translateX(25%);
      }
    }

    .ant-select-clear {
      width: calc(${forms.inputs.height} - ${layout.padding});
      height: ${forms.inputs.height};
      border-left: ${layout.borderWidth} solid ${colors.greyXLight};
      transform: translateY(-24px);
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 1.4em;
        font-weight: 700;
        color: ${colors.warning};
        transform: scaleY(0.9) translateX(25%);
      }
    }

    .ant-select-selector {
      ${cssInputBox}
      padding: 0;
      & > div & > div {
        padding: 0.3em 0.5em;
      }

      &:hover,
      &:focus,
      &:active {
        border-color: ${colors.primary}!important;
      }

      .ant-tag {
        padding: calc(${layout.padding} * 0.5) calc(${layout.padding} * 1) calc(${layout.padding} * 0.6)
          calc(${layout.padding} * 1);
        font-size: 1.2em;
        font-weight: 700;
        margin: 0.5em 0.6em 0.4em 0.4em;
        /* color: ${colors.successXDark};
        border: ${layout.borderWidth} solid ${colors.successDark};
        background: ${colorTagBg}; */
        color: ${colors.textDark};
        border: ${layout.borderWidth} solid rgba(60, 134, 157, 0.25);
        background: rgba(60, 134, 157, 0.1);
        line-height: calc(${forms.inputs.height} * 0.4);
        .anticon {
          margin-left: 0.8em;
          font-size: 1em;
          font-weight: 900;
          color: rgba(60, 134, 157, 0.6);
          transform: translateY(0.05em);
        }
        &:hover {
          color: ${colors.warningDark};
          border: ${layout.borderWidth} solid ${colors.warning};
          background: white;
          .anticon {
            color: ${colors.warningDark};
          }
        }
      }
    }
  }
`;
