import isEqual from 'lodash/isEqual';
import { METADATA } from 'types';
import type { CatMeta } from 'types/metadata.types';
import { useGetMetadata } from 'api/useGetMetaData_THIS';
import { useGlobal } from 'store/useGlobalContext';
import { getCategoriesFlat } from 'utils';
import { processCategoryMetadata } from 'routes/hooks/useInitCategories.utils';
import categories from 'mocks/data/CATS_FINAL.json';
import cloneDeep from 'lodash/cloneDeep';

const POSTS_ACCESS_DEFFAULT = { PUBLIC: true, GUEST: true, REGISTERED: true, VALIDATED: true, ADMIN: true };

interface UseGetRouteConfigurationResult {
  isFetchingCategories: boolean;
  isSuccessCategories: boolean;
  data: CatMeta[];
}

export const useInitCategories = (): UseGetRouteConfigurationResult => {
  const { setCategories } = useGlobal('categories');
  const { metaCats, setMetaCats } = useGlobal('metaCats');

  const getIsMetaCatsOutdated = () => !isEqual(metaCats, getCategoriesFlat({ categories }));
  const isMetaCatsOutdated = !!(metaCats.length === 0 || getIsMetaCatsOutdated());

  const { data, isFetching, isSuccess } = useGetMetadata({
    meta_key: METADATA.categories,
    enabled: isMetaCatsOutdated,
    onSuccess: (data: CatMeta[]) => {
      const categories = processCategoryMetadata({ categories: data, access: POSTS_ACCESS_DEFFAULT });
      setCategories(categories);
      setMetaCats(getCategoriesFlat({ categories: cloneDeep(categories) as CatMeta[] }));
      log('***** FETCHED -- CATS+CATS_META *****', 'lime');
    },
  });

  return {
    isFetchingCategories: isFetching,
    isSuccessCategories: isSuccess,
    data,
  };
};
