import { css } from '@emotion/react';
import { layout } from 'styles/layout.css';
import { colors } from 'styles/colors.css';

const arrowSize = '6px';
const tooltipMargin = layout.padding;

export const styles = css`
  /* Wrapping */
  display: inline-block;
  position: relative;

  /* Absolute positioning */
  .tooltip {
    position: absolute;
    z-index: 999;
    border-radius: ${layout.borderRadius};
    left: 50%;
    transform: translateX(-50%);
    padding: 0.5em 1em;
    color: white !important;
    -webkit-text-fill-color: white !important;
    /* background: ${colors.primaryDark}!important;
    border-color: ${colors.primaryDark}!important; */
    font-size: 0.8em;
    font-family: sans-serif;
    font-weight: 600;
    line-height: 1;
    white-space: nowrap;
  }

  /* CSS border triangles */
  .tooltip:before {
    content: ' ';
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: ${arrowSize};
    margin-left: calc(${arrowSize} * -1);
    /* border-color: ${colors.primaryDark}!important; */
    /* color: ${colors.primaryDark}!important; */
  }

  /* Absolute positioning */
  .tooltip.top {
    top: calc(${tooltipMargin} * -2);
  }
  /* CSS border triangles */
  .tooltip.top:before {
    top: 100%;
    border-top-color: ${colors.primaryDark};
  }

  /* Absolute positioning */
  .tooltip.right {
    left: calc(100% + ${tooltipMargin});
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }
  /* CSS border triangles */
  .tooltip.right:before {
    left: calc(${arrowSize} * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: ${colors.primaryDark};
  }

  /* Absolute positioning */
  .tooltip.bottom {
    bottom: calc(${tooltipMargin} * -1);
  }
  /* CSS border triangles */
  .tooltip.bottom:before {
    bottom: 100%;
    border-bottom-color: ${colors.primaryDark};
  }

  /* Absolute positioning */
  .tooltip.left {
    left: auto;
    right: calc(100% + ${tooltipMargin});
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }
  /* CSS border triangles */
  .tooltip.left:before {
    left: auto;
    right: calc(${arrowSize} * -2);
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }

  .tooltip {
    &,
    &.tooltip-default {
      background-color: ${colors.default};
      &.left:before {
        border-left-color: ${colors.default};
      }
      &.right:before {
        border-right-color: ${colors.default};
      }
      &.top:before {
        border-top-color: ${colors.default};
      }
      &.bottom:before {
        border-bottom-color: ${colors.default};
      }
    }

    &.tooltip-info {
      background-color: ${colors.info};
      &.left:before {
        border-left-color: ${colors.info};
      }
      &.right:before {
        border-right-color: ${colors.info};
      }
      &.top:before {
        border-top-color: ${colors.info};
      }
      &.bottom:before {
        border-bottom-color: ${colors.info};
      }
    }

    &.tooltip-success {
      background-color: ${colors.success};
      &.left:before {
        border-left-color: ${colors.success};
      }
      &.right:before {
        border-right-color: ${colors.success};
      }
      &.top:before {
        border-top-color: ${colors.success};
      }
      &.bottom:before {
        border-bottom-color: ${colors.success};
      }
    }

    &.tooltip-warning {
      background-color: ${colors.warning};
      &.left:before {
        border-left-color: ${colors.warning};
      }
      &.right:before {
        border-right-color: ${colors.warning};
      }
      &.top:before {
        border-top-color: ${colors.warning};
      }
      &.bottom:before {
        border-bottom-color: ${colors.warning};
      }
    }

    &.tooltip-error,
    &.tooltip-danger {
      background-color: ${colors.danger};
      &.left:before {
        border-left-color: ${colors.danger};
      }
      &.right:before {
        border-right-color: ${colors.danger};
      }
      &.top:before {
        border-top-color: ${colors.danger};
      }
      &.bottom:before {
        border-bottom-color: ${colors.danger};
      }
    }
  }
`;
