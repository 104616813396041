import type { ColorName } from 'styles';

export const getColorVariants = ({
  color,
  name,
  factor,
  factorX,
  factorXX,
}: {
  color: any;
  name: ColorName;
  factor: number;
  factorX: number;
  factorXX: number;
}) => {
  const variants = {} as { [key in keyof any]: string };

  const { valpha } = color;
  if (valpha < 1) {
    variants[name] = color.opaquer(0).string();
    if (color.luminosity() !== 1) {
      variants[`${name}XXLight`] = color.lighten(factorXX).desaturate(0.8).opaquer(0).string();
      variants[`${name}XLight`] = color.lighten(factorX).desaturate(0.4).opaquer(0).string();
      variants[`${name}Light`] = color.lighten(factor).desaturate(0).opaquer(0).string();
    }
    if (color.luminosity() !== 0) {
      variants[`${name}Dark`] = color.darken(factor).opaquer(0).string();
      variants[`${name}XDark`] = color.darken(factorX).opaquer(0).string();
      variants[`${name}XXDark`] = color.darken(factorXX).opaquer(0).string();
    }
  } else {
    variants[name] = color.hex();
    if (color.luminosity() !== 1) {
      variants[`${name}XXLight`] = color.lighten(factorXX).desaturate(0.3).hex();
      variants[`${name}XLight`] = color.lighten(factorX).desaturate(0.2).hex();
      variants[`${name}Light`] = color.lighten(factor).desaturate(0.15).hex();
    }
    if (color.luminosity() !== 0) {
      variants[`${name}Dark`] = color
        .darken(factor / 1.33)
        .desaturate(0.15)
        .hex();
      variants[`${name}XDark`] = color
        .darken(factorX / 1.66)
        .desaturate(0.3)
        .hex();
      variants[`${name}XXDark`] = color
        .darken(factorXX / 2)
        .desaturate(0.45)
        .hex();
    }
  }
  return variants;
};
