/* eslint-disable @typescript-eslint/no-shadow */
import { Row, Col } from 'react-grid-system';
import { useFormContext } from 'react-hook-form';
import { useRef, FormEventHandler, FormEvent, ChangeEvent, useEffect, useState } from 'react';
import Color from 'color';
import { debounce } from 'throttle-debounce';
import { IoMdAdd as IconAddNew } from 'react-icons/io';
import { RiDeleteBin6Line as IconDelete } from 'react-icons/ri';
import { Button } from 'components/Button';
import { Select } from 'components/FormUI/Select';
import type { SocialConfig } from 'components/Socials';
import { configSocials } from 'config/socials.config';
import { colors } from 'styles/colors.css';
import { useFormItem, useFormParent, useConfigValidation } from '../InputRepeater';
import type { IRepeaterItem } from '../InputRepeater';

// TODO:
// useConfigValidation

const DEFAULT_URL_PLACEHOLDER = 'https://abc.com';

const optionsUpdate = {
  shouldValidate: true,
  shouldDirty: true,
};

// EXAMPLE: http://twitter.com/abc123

export const InputURL = ({ index, item, fieldKey: FIELD_KEY, fieldArray, repeaterConfig }: IRepeaterItem) => {
  // SOURCE: https://codesandbox.io/s/github/react-dnd/react-dnd/tree/gh-pages/examples_js/04-sortable/simple?from-embed=&file=/src/Card.jsx
  const ref = useRef(null);
  const { uuid, dndID, name } = item;
  const isFocussed = !!((document as any)?.activeElement.id === uuid);

  const isEmpty = !(item.name?.length >= 3 || item.url?.length >= 3);

  // MY CARD ITEM LOGIC ===================================================== //

  const formMethods = useFormContext();
  const {
    register,
    formState,
    // formState: { dirtyFields, errors },
    // NEW DEV:
    getValues,
    setValue,
  } = formMethods;

  // TODO: IN ODER AUS ???
  // const field = fieldArray.fields.find((field: IGeneric) => field.uuid === uuid);

  // ITEM FORM LOGIC ================================================================================= //

  const { handleAppend, handleRemove } = useFormParent({ formMethods, fieldArray });
  const { onClickEdit, handleKeyDown, handleFocus, handleBlur, isValid, isReadOnly } = useFormItem({
    FIELD_KEY,
    index,
    uuid,
    isFocussed,
    isEmpty,
    formMethods,
    fieldArray,
  });

  const isLastEmpty = false;

  // const { inputType, inputValidation } = useConfigValidation({ repeaterConfig });

  // NEW INPUT HANDLER LOGIC ================================================================================= //

  const [platformConfig, setPlatformConfig] = useState<SocialConfig>();

  const handleChangePlatform = (selection: string) => {
    const newConfig = configSocials.find((social) => social.name === selection);
    setPlatformConfig(newConfig);
    setValue(`${FIELD_KEY}.platform`, selection, optionsUpdate);
  };

  const handleChangeName = debounce(500, (event: ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    setValue(`${FIELD_KEY}.name`, val, optionsUpdate);
  });

  const handleChangeURL = debounce(500, (event: ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    setValue(`${FIELD_KEY}.url`, val, optionsUpdate);
  });

  return (
    <div ref={ref} className="card-item">
      <Row direction="row" align="stretch">
        <Col xs={11} className="col col-name">
          <fieldset className={'fieldset-name'} style={{ zIndex: 100 - index }}>
            <Select
              {...register(`${FIELD_KEY}.platform`)}
              defaultMenuIsOpen={true}
              name={`${FIELD_KEY}.platform`}
              label={`${FIELD_KEY}.platform`}
              aria-label={`${FIELD_KEY}.platform`}
              value={getValues(`${FIELD_KEY}.platform`)}
              options={configSocials.map((social: SocialConfig) => {
                const IconSocial = social.icon;
                return {
                  // key: social.name,
                  // id: social.name,
                  value: social.name,
                  label: (
                    <div className="option-custom" aria-label={social.label}>
                      {/* <span id={social.name}> */}
                      <IconSocial className="option-icon" style={{ color: social.color }} />
                      <span>{social.label}</span>
                      {/* </span> */}
                    </div>
                  ),
                  // icon: social.icon,
                };
              })}
              className="link-platform"
              placeholder="Plataforma..."
              isDisabled={false}
              isMulti={false}
              isSearchable={true}
              isClearable={false}
              onChange={(value: string) => {
                handleChangePlatform(value);
              }}
              {...{ required: false }}
            />
            <input
              {...register(`${FIELD_KEY}.name`)}
              type="text"
              id={`${uuid}-name`}
              name={`${FIELD_KEY}.name`}
              aria-label={`${FIELD_KEY}.name`}
              // aria-invalid={!isValid}
              className="link-name"
              placeholder={'Nombre de canal'}
              onFocus={handleFocus}
              onBlur={handleBlur}
              // onKeyDown={handleKeyDown}
              onInput={handleChangeName}
              required={false}
            />
            <input
              {...register(`${FIELD_KEY}.url`, {
                pattern: {
                  value: platformConfig?.validation as RegExp,
                  message: 'Invalid URL format for selected platform',
                },
              })}
              type="url"
              id={`${uuid}-url`}
              name={`${FIELD_KEY}.url`}
              aria-label={`${FIELD_KEY}.url`}
              value={getValues(`${FIELD_KEY}.url`)}
              // aria-invalid={!isValid}
              className="link-url"
              placeholder={`${platformConfig?.baseUrl || DEFAULT_URL_PLACEHOLDER}/user123`}
              onFocus={handleFocus}
              onBlur={handleBlur}
              // onKeyDown={handleKeyDown}
              onInput={handleChangeURL}
              required={false}
            />
            <Button
              variant="icon"
              icon={<IconDelete />}
              iconScale={1.25}
              onClick={() => handleRemove(index)}
              colorHover={colors.danger}
              className="btn-remove"
            />
          </fieldset>
        </Col>
        <Col xs={1} className="col col-add">
          <Button
            variant={'icon'}
            icon={<IconAddNew />}
            iconScale={1.4}
            onClick={handleAppend}
            color={'#fff'}
            colorHover={colors.successXDark}
            className={'btn-add'}
            isDisabled={isLastEmpty}
          />
        </Col>
      </Row>
    </div>
  );
};
