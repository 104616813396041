import type { RouteObject } from 'react-router-dom';
import type { CatMeta } from 'types';
import type { RouteConfig } from 'routes';
import type { PageHeroProps } from 'components/PageHero';
import type { GlobalStoreValues } from './GlobalSchema.types';
import { config } from 'config';

const { TABLE_MAX_ROWS, SORT_PARAMS_DEFAULT } = config;

// TODO: force `string` type with empty-string ''
// OR make optional to allow `undefined`

export const RESET_STATE_PATHS = ['/logged-out', '/logout', '/login'];

export const initialState: GlobalStoreValues = {
  // APP
  // lang: (navigator.language as unknown as string) || 'en',
  lang: 'en',
  // PAGE-HERO
  pageHero: {} as PageHeroProps,
  // CATEGORIES
  categories: [], // fetched metadata
  metaCats: [] as CatMeta[], // generated
  // ROUTES
  routesConfiguration: [] as RouteConfig[], // fetched metadata
  routes: [] as RouteObject[], // merged local base routes with generated routes from categories
  metaRoutes: [] as RouteConfig[],
  metaRoutesTree: [] as RouteConfig[],
  // FORMS
  // metaForm: [],
  // TABLE DATA + PAGINATION
  sortParams: SORT_PARAMS_DEFAULT,
  filter: null,
  // TABLE DATA + PAGINATION
  isNavigationBlocked: false,
  // ======================================================================== //
  // TODO: REMOVE THESE ??  (in-use in app..)
  // pageSubtitleBreak: false,
  // isPageTitleWrapped: false,
};
