import { Row, Col, Hidden } from 'react-grid-system';
import { format } from 'date-fns';
import cloneDeep from 'lodash/cloneDeep';
import { FiEdit3 as IconEdit } from 'react-icons/fi';
import { RiZoomInLine as IconView } from 'react-icons/ri';
import type { ScreenClass } from 'types';
import { useRouter } from 'routes/hooks';
import { ButtonLink } from 'components/Button';
import { config } from 'config';
import { sizes } from 'styles/media-queries-V1';
import type { DataColumnConfig } from '../../DataTable.types';

// ================================================================================================ //

export const DataRow = ({ rowData, config: { columns, actions } }: any) => {
  const { basePath, params, isAdminPath } = useRouter();
  const data = cloneDeep(rowData);
  const table = params?.table;
  const basePathEdit = `/${basePath}`;

  return (
    <div className={'data-row'}>
      <Row nogutter justify="between" align="stretch" style={{ height: config.tableRowHeight }}>
        {/* ----------------- IMAGE + DATA COLUMNS ----------------------------------------------------------------- */}
        {columns.map((column: DataColumnConfig, index: number) => {
          // DETERMINE WIDTH: SET or RESPONSIVE /////////////////////////
          const widths = {};
          const hidden = {};
          const align = column?.align || 'left';

          if (column.widths) {
            let widthValue = Object.values(column.widths)[0];
            // SET ALL AVAIL BREAKPOINTS, EVEN IF UNSET IN CONFIG
            // RULE: SKIPPED KEYS TAKE THE VALUE OF LAST SET
            // ACTING AS A min-width MEDIA-QUERY
            for (const size of sizes) {
              widthValue = column.widths[size as ScreenClass] ?? widthValue;
              widthValue === 0
                ? Object.assign(hidden, { [size]: true })
                : Object.assign(widths, { [size]: widthValue });
            }
          } else if (column.width) {
            // OPTIONAL 'width' KeyboardEvent, IF NO RESPONSIVE CHANGE REQUIRED
            Object.assign(widths, { xs: column.width || 0 });
            column.width === 0 && Object.assign(hidden, { xs: true });
          } else {
            // LEAVE widths{} EMPTY = FLEXIBLE
          }
          /*

             // OPTIONAL 'width' KeyboardEvent, IF NO RESPONSIVE CHANGE REQUIRED
            Object.assign(widths, { xs: column.width || 0 });
            (column.width === false || !column.width) && Object.assign(hidden, { xs: true });

          } else if (column.width) {
            // OPTIONAL 'width' KeyboardEvent, IF NO RESPONSIVE CHANGE REQUIRED
            Object.assign(widths, { xs: column.width || 0 });
            column.width === 0 && Object.assign(hidden, { xs: true });
          } else {

          */

          /*
          else {
            // OPTIONAL 'width' KeyboardEvent, IF NO RESPONSIVE CHANGE REQUIRED
            Object.assign(widths, { xs: column.width || 0 });
            column.width === 0 && Object.assign(hidden, { xs: true });
          }
          */

          if (data.date) {
            data.date = format(new Date(data.date), 'yyyy-MM-dd');
          }

          if (column?.render) {
            // log('COL_RENDER_DATA', 'magenta', data);
            return (
              <Hidden {...hidden} key={index}>
                <Col {...widths} className={`td col col-${column.name} align-${align}`}>
                  {column.render({ data })}
                </Col>
              </Hidden>
            );
          }

          return (
            <Hidden {...hidden} key={index}>
              <Col {...widths} className={`td col col-${column.name}`}>
                {index}: {column.label || column.name || 'COLUMN'}
              </Col>
            </Hidden>
          );
        })}
        {/* ----------------- ACTION COLUMNS ----------------------------------------------------------------------- */}
        {/*
        // TODO: (DEV - REMOVED TEMPORARILY, IN FAVOUR OF `dataConfiguration`)
        {actions.hasActionView && (
          <Col xs={1} className="td col col-actions col-view">
            <ButtonLink
              to={`${basePath}/view/${data.id}`}
              state={{ from: basePath, action: 'VIEW' }}
              variant="clear"
              icon={<IconView />}
              colorHover="primary"
              iconScale={1.8}
            />
          </Col>
        )} */}
        {/* {isAdminPath && actions.hasActionEdit && (
          <Col xs={1} className="td col col-actions col-edit">
            <ButtonLink
              to={`${basePath}/edit/${data.id}`}
              state={{ from: basePath, action: 'EDIT' }}
              variant="clear"
              icon={<IconEdit />}
              colorHover="success"
              iconScale={1.8}
            />
          </Col>
        )} */}
      </Row>
    </div>
  );
};
