/* eslint-disable @typescript-eslint/no-shadow */
import { Row, Col } from 'react-grid-system';
import { useLayout } from 'pages/Layout/context/LayoutContext';
import { Button, type ButtonProps } from 'components/Button';
// import { optionsSetLoud, useFormConfigContext } from 'components/Form';
import { translate } from 'utils';
import { config } from 'config';
import { FormButtonBarProps, FormButton, types, BUTTONS, OnClickMethod } from './FormButtonBar.types';
import { useFormUtilsUI } from '../../utils/form.utils.ui';
import { styles } from './FormButtonBar.css';

export const FormButtonBar = ({ buttons, formMethods, handleActions, isLocked }: FormButtonBarProps) => {
  // const { isLocked: isSubmitLocked } = useFormConfigContext();
  const { isDevToolsVisible } = useLayout();
  const { getFormClasses, getButtonStyleByState } = useFormUtilsUI({ formMethods });
  const { formState } = formMethods;
  const { isDirty } = formState;

  const formClasses = getFormClasses();

  const handleButtonClick = ({ evt, button }: { evt?: MouseEvent; button: FormButton }) => {
    const { type, label, action } = button;

    if (action && handleActions && handleActions[action]) {
      const actionMethod = handleActions[action] as OnClickMethod;
      actionMethod(evt, formMethods);
    }
  };

  const buttonsVisible = buttons
    ?.filter(({ isHidden }) => !isHidden)
    ?.filter((button) =>
      config.IS_ENV_PRODUCTION
        ? !button.label.startsWith('DEV_') && !button.action?.startsWith('MOCK')
        : button,
    );

  const MOCK_BUTTONS = [BUTTONS.CANCEL, BUTTONS.LATER] as string[];
  const IS_ACTIVE_BUTTONS = [
    BUTTONS.CANCEL,
    BUTTONS.EDIT,
    BUTTONS.VIEW,
    BUTTONS.LATER,
    ...MOCK_BUTTONS,
  ] as string[];

  const isDisabled = !isDirty || isLocked;

  return (
    <Row className="row-buttons" css={styles}>
      <Col className="col-buttons" xs={12}>
        {buttonsVisible?.map((button: FormButton, index: number) => {
          const { type: buttonType, label, action, ...restProps } = button;

          // TODO: SHOW / HIDE, DEPENDING ON `EDIT` + `VIEW`
          if (!isDevToolsVisible && !!(action === BUTTONS.MOCK || action === BUTTONS.MOCK_ADMIN)) return null;

          const labelFallback =
            action && Object.values(BUTTONS).includes(String(action) as BUTTONS)
              ? `t.buttons.${action}`
              : label;

          return (
            <Button
              key={index}
              type={buttonType}
              label={translate(label || labelFallback)}
              isDisabled={
                IS_ACTIVE_BUTTONS.includes(action as string)
                  ? false
                  : action === BUTTONS.MOCK
                    ? false
                    : !isDirty || isLocked
                // : !isDirty || isLocked || isSubmitLocked
              }
              className={buttonType === types.submit ? formClasses.inline() : ''}
              icon={button?.icon}
              onClick={
                buttonType === types.submit
                  ? () => {
                      handleButtonClick({ button });
                    }
                  : (evt: any) => {
                      evt.preventDefault();
                      handleButtonClick({ evt, button });
                    }
              }
              {...getButtonStyleByState({ buttonType })}
              {...(restProps as any)}
            />
          );
        })}
      </Col>
    </Row>
  );
};
