/* eslint-disable prefer-const */
import { matchPath } from 'react-router-dom';
import { ACTIONS } from 'types/actions.types';
import type { CatMeta, LocationWithState } from 'types';
import type { RouteConfig, RouteGroup } from 'routes/routes.types';
import { getRouteDefaultPermissions } from 'routes/utils/routes.utils';
import type { GlobalStore } from 'store/GlobalContext.types';
import { isInteger } from 'utils/utils.number';
import { config } from 'config';
import type { ParamsCustom } from '../hooks/useRouter/useRouterLoader.types';
import { getRouteConfigCleaned } from '../hooks/useRouter/useRouter.utils';

const isAdminPath = !!location.pathname.startsWith('/admin');
const PATH_BASE = isAdminPath ? config.PATH_BASE_ADMIN : config.PATH_BASE_WEB;

// NEW: BETTER METHOD TO SET ROUTE_META ====================================== //
// CLEANED / MINIMAL RouteConfig (without Access rules, etc..)

// ======================================================================== //

export const getParamsMatched = ({
  pathname,
  paramsCustom,
  matchPathBase = '',
  param = 'cat',
}: {
  pathname: string;
  paramsCustom: ParamsCustom;
  matchPathBase?: string;
  param?: string;
}) => {
  const basePath = matchPathBase ? `${PATH_BASE}/${matchPathBase}` : PATH_BASE;

  if (matchPath({ path: `${basePath}/:parent/:${param}/new` }, pathname)) {
    const params = matchPath({ path: `${basePath}/:parent/:${param}/new` }, pathname)?.params;
    return { ...params, from: `${basePath}/${params?.parent}/${params?.cat}`, action: ACTIONS.NEW };
  }

  if (matchPath({ path: `${basePath}/:parent/:${param}/view/:id` }, pathname)) {
    const params = matchPath({ path: `${basePath}/:parent/:${param}/view/:id` }, pathname)?.params;
    return { ...params, from: `${basePath}/${params?.parent}/${params?.cat}`, action: ACTIONS.VIEW };
  }

  if (matchPath({ path: `${basePath}/:parent/:${param}/edit/:id` }, pathname)) {
    const params = matchPath({ path: `${basePath}/:parent/:${param}/edit/:id` }, pathname)?.params;
    return { ...params, from: `${basePath}/${params?.parent}/${params?.cat}`, action: ACTIONS.EDIT };
  }

  if (matchPath({ path: `${basePath}/:${param}/new` }, pathname)) {
    const params = matchPath({ path: `${basePath}/:${param}/new` }, pathname)?.params;
    return { ...params, from: `${basePath}/${params?.cat}`, action: ACTIONS.NEW };
  }

  if (matchPath({ path: `${basePath}/:${param}/view/:id` }, pathname)) {
    const params = matchPath({ path: `${basePath}/:${param}/view/:id` }, pathname)?.params;
    return { ...params, from: `${basePath}/${params?.cat}`, action: ACTIONS.VIEW };
  }

  if (matchPath({ path: `${basePath}/:${param}/edit/:id` }, pathname)) {
    const params = matchPath({ path: `${basePath}/:${param}/edit/:id` }, pathname)?.params;
    return { ...params, from: `${basePath}/${params?.cat}`, action: ACTIONS.EDIT };
  }

  if (matchPath({ path: `${basePath}/:parent/:${param}` }, pathname)) {
    const params = matchPath({ path: `${basePath}/:parent/:${param}` }, pathname)?.params;
    return { ...params, from: `${basePath}/${params?.parent}` };
  }

  if (matchPath({ path: `${basePath}/:${param}` }, pathname)) {
    return matchPath({ path: `${basePath}/:${param}` }, pathname)?.params;
  }

  return paramsCustom;
};

// ======================================================================== //
// ======================================================================== //

// MATCHES + PARAMS A -- CATEGORIZED ====================================== //

const paramsCustom: ParamsCustom = {
  table: undefined,
  cat: undefined,
  parent: undefined,
  sub: undefined,
  slug: undefined,
  id: undefined,
  new: false,
  action: undefined,
  from: undefined,
};

export const getRoutePathParams = ({
  pathname,
  slug,
  metaRoutes,
  metaCats,
  state,
  isUserPath,
}: {
  pathname: string;
  slug: string;
  metaRoutes: RouteConfig[];
  metaCats: CatMeta[];
  state: GlobalStore;
  isUserPath: boolean;
}) => {
  // INIT META ARRAY ======================================================== //

  let routeMeta: RouteConfig = {
    path: location.pathname,
    title: 'APNAES',
    slug: '',
    uuid: '',
    routeGroup: '' as RouteGroup,
    category: undefined,
    isDeletable: true,
    isActive: true,
    access: getRouteDefaultPermissions(),
  };

  if (location.pathname === '/') {
    Object.assign(routeMeta, {
      path: '/',
      title: 'Home',
      slug: '',
      uuid: '',
      routeGroup: '' as RouteGroup,
      category: undefined,
      isDeletable: true,
      isActive: true,
      access: getRouteDefaultPermissions(),
    });
  }

  const isRoot = !!matchPath({ path: PATH_BASE }, pathname);
  const pathMatch = !isRoot
    ? matchPath({ path: `${PATH_BASE}/:basePath/*` }, pathname)?.params
    : matchPath({ path: `${PATH_BASE}/:basePath` }, pathname)?.params;

  Object.assign(
    routeMeta,
    metaRoutes?.find(
      (route: RouteConfig) =>
        // ------------------------------------------------------------------ //
        // TODO: TEMP - COMMENTED OUT - ONLY `pathname` is NEEDED, I BELIEVE
        // route.slug === slug ||
        // route.path === slug ||
        // route.path === pathMatch?.basePath ||
        // route.path?.endsWith(`/${slug}`) ||
        // route.path?.startsWith(`/${slug}`),
        // ------------------------------------------------------------------ //
        route.pathname === pathname,
    ),
  );

  // MATCHES + PARAMS A -- CATEGORY (ADMIN) ==================================== //

  let catMeta: CatMeta | undefined = undefined;

  if (matchPath({ path: `${PATH_BASE}/cat/*` }, pathname)) {
    // Merge Category params, by matched slug..
    const matchedParams = getParamsMatched({ pathname, paramsCustom, matchPathBase: 'cat', param: 'cat' });
    Object.assign(paramsCustom, {
      ...matchedParams,
      slug: matchedParams?.cat,
      table: 'posts',
    });
  }
  // MATCHES + PARAMS B -- REGISTERED USERS (ADMIN) ==================================== //
  else if (matchPath({ path: `/account/registros/cat/*` }, pathname)) {
    // Merge "Feed" category params, by matched slug..
    const matchedParams = getParamsMatched({
      pathname,
      paramsCustom,
      matchPathBase: '/account/registros/cat',
      param: 'cat',
    });
    Object.assign(paramsCustom, {
      ...matchedParams,
      slug: matchedParams?.cat,
      table: 'posts',
    });
  }
  // MATCHES + PARAMS B -- REGISTERED USERS (ADMIN) ==================================== //
  else if (matchPath({ path: `${PATH_BASE}/feed/*` }, pathname)) {
    // Merge "Feed" category params, by matched slug..
    const matchedParams = getParamsMatched({ pathname, paramsCustom, matchPathBase: 'feed', param: 'cat' });
    Object.assign(paramsCustom, {
      ...matchedParams,
      slug: matchedParams?.cat,
      table: 'posts',
    });
  }
  // MATCHES + PARAMS B -- REGISTERED USERS (ADMIN) ==================================== //
  else if (matchPath({ path: `${PATH_BASE}/users/*` }, pathname)) {
    Object.assign(paramsCustom, { from: `${PATH_BASE}/users` });
    paramsCustom.table = 'register';
    paramsCustom.slug = 'users';
  }
  // MATCHES + PARAMS C -- UNCATEGORIZED ==================================== //
  else if (matchPath({ path: `${PATH_BASE}/:table/*` }, pathname)) {
    paramsCustom.table = matchPath({ path: `${PATH_BASE}/:table/*` }, pathname)?.params?.table;

    const params: ParamsCustom | undefined = matchPath(
      { path: `${PATH_BASE}/${paramsCustom.table}/:slug/:id` },
      pathname,
    )?.params || {
      ...matchPath({ path: `${PATH_BASE}/${paramsCustom.table}/:id` }, pathname)?.params,
      slug: paramsCustom.table,
    };

    // ID or SLUG
    if (params?.id && isInteger(params.id)) paramsCustom.id = params.id;
    if (params?.id && !isInteger(params.id)) paramsCustom.slug = params.id as string;

    if (!routeMeta) {
      routeMeta = (
        params?.sub
          ? metaRoutes?.find((route) =>
              route.path?.endsWith(`${paramsCustom.table}/${params?.sub}/${params.id}`),
            )
          : metaRoutes?.find((route) => route.path?.endsWith(`${paramsCustom.table}/${params?.id}`))
      ) as RouteConfig;
    }

    // FROM-LOCATION AND PATHNAME BASE ======================================== //
    Object.assign(paramsCustom, { ...getParamsMatched({ pathname, paramsCustom, param: 'table' }) });
    paramsCustom.slug = routeMeta.slug;
  }

  // ======================================================================== //
  // ======================================================================== //

  // prepare current route + category config..
  routeMeta = getRouteConfigCleaned(paramsCustom.slug, state.metaRoutes, ['access', 'isDeletable']);

  catMeta = metaCats?.find((cat: CatMeta) => cat.slug === paramsCustom.slug || cat.slug === slug);
  if (routeMeta && catMeta) {
    Object.assign(routeMeta, { category: catMeta });
  }

  if (isUserPath && pathname.includes('/cat/')) {
    Object.assign(paramsCustom, { table: 'posts', slug: catMeta?.slug });
  }

  return { paramsCustom, routeMeta, catMeta };
};
