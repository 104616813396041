/* eslint-disable new-cap */
import Color from 'color';
import { colors } from 'styles/colors.css';
import type { Theme } from './Select.types';

export const applyTheme = (theme: Theme) => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    primary: Color(colors.primaryXXLight).lighten(0.3).hex(),
    primary75: Color(colors.primaryXXLight).lighten(0.2).hex(),
    primary50: Color(colors.primaryXXLight).lighten(0.1).hex(),
    primary25: Color(colors.primaryXXLight).lighten(0.0).hex(),
  },
});

export const SELECT_DEFAULT_PROPS = {
  classNamePrefix: 'select',
  placeholder: 'Seleccionar...',
  autoComplete: 'off',
  autoFocus: false,
  autofill: false,
  isMulti: false,
  isSearchable: true,
  isClearable: true,
  isDisabled: false,
  theme: applyTheme,
  props: {},
};
