import { RouteGroup } from 'routes/routes.types';
import { RouteAccess } from 'routes/routes.types';
export interface CatMeta {
  uuid: string;
  title?: string;
  slug: string;
  path?: string;
  pathname?: string;
  routeGroup?: RouteGroup;
  parent?: CatMeta;
  access?: RouteAccess;
  hasChildren?: boolean;
  children?: CatMeta[];
}

export enum METADATA {
  routes_config_public = 'routes_config_public',
  routes_config_user = 'routes_config_user',
  routes_config_admin = 'routes_config_admin',
  categories = 'categories',
  tags_informativas = 'tags_informativas',
  tags_area_canal = 'tags_area_canal',
  tags_reivindicativas = 'tags_reivindicativas',
  event_type = 'event_type',
  entity_type = 'entity_type',
  accept_terms = 'accept_terms',
  accept_privacy = 'accept_privacy',
}
