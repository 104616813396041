import type { RouteObject } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuid } from 'uuid';
import type { CatMeta } from 'types';
import type { RouteConfig, ParentRouteProps } from 'routes/routes.types';
import { omitNull } from 'utils';
import { getCategoryOfRoute } from '../../utils/routes.utils.categories';

// const ROUTES_META_PATH_BLACKLIST: string[] = ['/', '*', 'logout'];
const ROUTES_META_PATH_BLACKLIST: string[] = ['', '*', 'logout'];

// NOTE: MAIN ROUTES CONFIGURE METHOD ===================================== //

export const getRoutesConfigured = ({
  routes,
  routesConfiguration,
  metaCats,
  parent = null,
}: {
  routes: RouteObject[] | RouteConfig[];
  routesConfiguration: RouteConfig[];
  metaCats: CatMeta[];
  parent?: ParentRouteProps | null;
}): RouteConfig[] => {
  const ROUTES_CONFIGURED: RouteConfig[] = [];

  cloneDeep(routes).map((route: any) => {
    // log('route:', 'grey', route);

    // log('__DEV000', 'grey', route.path);

    // NOTE: V1

    const _slug = route.path?.match(new RegExp('[^/]+(?=/$|$)'))?.[0] as string;

    // NOTE: V2
    if (route.path && !route.path.endsWith(':id')) {
      route.pathname = `/${route.path}`;
      log('__DEV000', 'grey', { pathname: route.path, PARENT: route.parent });
    }

    const pathSegments = route.path === '/' ? ['/'] : route.path?.split('/');
    const isCategoryPath = pathSegments?.length > 1 && pathSegments[0] === 'cat';

    const slug = pathSegments
      ? isCategoryPath
        ? pathSegments.at(-1)
        : pathSegments.join('-')
      : (route.path?.match(new RegExp('[^/]+(?=/$|$)'))?.[0] as string);

    const routeConfig: RouteConfig =
      routesConfiguration.find(
        (data: RouteConfig) => data?.slug === slug || data?.path === slug?.replace(/-/g, '/'),
      ) ?? ({} as RouteConfig);

    const routeCatMeta = metaCats.find((data: any) => data?.slug === slug) as CatMeta;

    // ======================================================================== //
    // NOTE: CORE PROPS..

    if (routeCatMeta) {
      Object.assign(routeConfig, { ...routeCatMeta });
    }

    if (route.path && !route.path.endsWith(':id')) {
      route.slug = slug;
    }

    if (routeConfig.title) {
      route.title = routeConfig.title;
    }

    // NOTE: missing UUID ?? Add one..
    if (!ROUTES_META_PATH_BLACKLIST.includes(route.path)) {
      route.uuid = routeConfig.uuid ?? uuid();
    }

    // ------------------------------------------------------------------------ //
    // NOTE: CATEGORY META (if category)

    // category routes
    if (route.path && route.path.startsWith('cat/')) {
      // TODO: REPLACE WITH - SET `route` WITH `routeConfig` VALUES
      const category = getCategoryOfRoute({ route, metaCats });
      route.category = category;
    }

    // ------------------------------------------------------------------------ //
    // NOTE: PARENT ROUTE META (if child)

    const parentProps: Partial<ParentRouteProps> | null = !parent
      ? {
          uuid: routeConfig.uuid,
          title: route.title || routeConfig.title,
          path: route.path || '',
          slug: route.slug || '',
        }
      : parent;

    if (parent && parent.category) {
      Object.assign(parentProps, { category: parent.category });
    }

    // ------------------------------------------------------------------------ //
    // NOTE: CHILD ROUTES

    // TODO: REMOVE ALL USE OF `hasChildren` PROP.. USE ONLY `!!children.length`
    const hasChildren = !!(route.children && route.children?.length);
    const isChildParam = route.children && route.children[0]?.path?.endsWith('/:id');

    const children =
      hasChildren && !isChildParam
        ? // call-self to iterate child routes..
          getRoutesConfigured({
            routes: route.children as RouteConfig[],
            routesConfiguration,
            metaCats,
            parent: parentProps.path !== '/' ? omitNull(parentProps) : null,
          })
        : null;

    // ======================================================================== //
    // NOTE: USER ACCESS + PERMISSIONS

    if (!routeConfig?.access) {
      // log('ERR_CONFIG!', 'red', { route, routeConfig }, routesConfiguration);
      // log('ERR_CONFIG!', 'orange', routesConfiguration);
      // log('---------------------------------------', 'grey');
      /* eslint-disable @typescript-eslint/no-shadow */
      const routeConfigByUUID = routesConfiguration.find((data: RouteConfig) => data?.uuid === route.uuid);
      const parent = routeConfigByUUID?.parent || null;
      const parentConfigByUUID = routesConfiguration.find((data: RouteConfig) => data?.uuid === parent?.uuid);
      const access = routeConfigByUUID?.access || parentConfigByUUID?.access || null;
      Object.assign(routeConfig, {
        access: {
          PUBLIC: true,
          GUEST: true,
          REGISTERED: true,
          VALIDATED: true,
          ADMIN: true,
          ...access,
        },
      });
      /* eslint-enable @typescript-eslint/no-shadow */
    }

    // ======================================================================== //
    // NOTE: UTILITY BOOLEANS...

    if (!('isDeletable' in route)) {
      const isDeletable: boolean =
        typeof routeConfig.isDeletable === 'boolean' && routeConfig.isDeletable === false ? false : true;
      (route as RouteConfig).isDeletable = isDeletable;
    }

    if (!('isActive' in route)) {
      const isActive: boolean =
        typeof routeConfig.isActive === 'boolean' && routeConfig.isActive === false ? false : true;
      (route as RouteConfig).isActive = isActive;
    }

    // ======================================================================== //
    // NOTE: CLEAN / REMOVE SPECIFIC PROPS...
    delete routeConfig.category;

    // ======================================================================== //
    // TODO: DEV: REMOVE PROPS THAT WILL BE DEPRECATED...
    // NOTE: missing UUID ?? Add one..

    // delete (route as RouteConfig).isNavHidden;
    // delete (routeConfig as RouteConfig).isNavHidden;

    // ======================================================================== //
    // NOTE: FINAL CONFIGURED ROUTE OBJECT - FULL

    const routeConfigured: RouteConfig = {
      ...route,
      ...omitNull(routeConfig),
      parent,
      children,
    } as RouteConfig;

    // NOTE: FINAL CONFIGURED ROUTE OBJECT - CLEAN PROPS...
    // TODO: MAKE OPTIONAL ?? TO ONLY HAVE 1 SINGLE ROUTE CONFIGURATOR
    // TODO: OR 2 SEPARATE METHODS, MAYBE BETTER..

    const routeConfigClean = omitNull(routeConfigured);
    delete (routeConfigClean as RouteObject).element;
    delete (routeConfigClean as RouteObject).index;

    if (routeConfigClean?.path && !ROUTES_META_PATH_BLACKLIST.includes(routeConfigClean?.path)) {
      ROUTES_CONFIGURED.push(routeConfigClean);
    }
  });

  // TODO: DEV..
  // log('ROUTES_CONFIGURED:', 'lime', ROUTES_CONFIGURED?.[0]?.children || ROUTES_CONFIGURED);

  return omitNull(ROUTES_CONFIGURED);
};
