import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';

// TODO: FIND A WAY TO DEFINE GLOBALLY
// PERHAPS REXPORT `date-fns/format` THAT INCLUDES { locale: ES }
// HERE's ONE IDEA: https://robertmarshall.dev/blog/dynamically-import-datefns-locale-mui-datepicker-localization/

registerLocale('es', es);

export { es as ES };
