import { useEffect, useMemo } from 'react';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import type { UseQueryResult } from '@tanstack/react-query';
import type { UseQueryGetProps } from './query';
import { getData, getSchema } from './utils';

/*
// NOTE: QUERY + FETCH PHASES + STATUSES
const _PHASE_1_INIT = { isPending: true, isFetching: false, isFetched: false, isSuccess: false };
const _PHASE_2_BUSY = { isPending: true, isFetching: true, isFetched: false, isSuccess: false };
const _PHASE_3_DONE = { isPending: false, isFetching: false, isFetched: true, isSuccess: true };
const _PHASE_4_BUG = { isPending: true, isFetching: false, isFetched: false, isSuccess: false };
*/

const useQueryGet = (args: UseQueryGetProps): UseQueryResult<any, unknown> & any => {
  const { endpoint, enabled = true, onSuccess, onError, schemaOnly = false, query, filter } = args;

  const isDetail = !!(
    (query && 'id' in query) ||
    (query && 'uuid' in query) ||
    (filter && filter?.$limit === 1)
  );
  const queryKey = isDetail
    ? ['getDetail', { endpoint, ...query }]
    : ['getList', endpoint, JSON.stringify(query)];

  const {
    data: response,
    isPending,
    isSuccess,
    isError,
    ...queryResult
  } = useQuery({
    queryKey,
    queryFn: () => (schemaOnly ? getSchema({ endpoint }) : getData({ endpoint, query, filter })),
    enabled,
    refetchOnWindowFocus: false,
    placeholderData: !isDetail ? keepPreviousData : undefined, // TODO: USE `keepPreviousData` ONLY FOR `PAGINATED` QUERIES ??
    gcTime: Infinity,
    // select: args?.select,
  });

  const data = useMemo(() => {
    return response?.data
      ? Array.isArray(response?.data) && !isDetail
        ? [...response.data]
        : response.data[0]
      : undefined;
  }, [response]);

  useEffect(() => {
    if (!isPending && isSuccess) {
      onSuccess?.({ data, schema: response.schema, total: response?.total });
    }
  }, [!isPending, isSuccess, data]);

  return response?.schema
    ? { data, schema: response.schema, isPending, isSuccess, isError, ...queryResult, total: response?.total }
    : { data, isPending, isSuccess, isError, ...queryResult, total: response?.total };
};

export { useQueryGet };
