import { css } from '@emotion/react';
import { layout } from 'styles/layout.css';
import { colors } from 'styles/colors.css';

export const styles = css`
  display: flex;
  /* width: min-content; */

  /* NEW DEV */
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-left: calc(${layout.padding} * 0.5);

  label {
    padding-left: calc(${layout.padding} * 1.25);
    color: ${colors.greyDark};
  }

  .toggle-checkbox {
    &.size-sm {
      /* transform: scale(1) translateY(15%); */
      transform: scale(1.8);
    }
    &.size-md {
      /* transform: scale(2.4) translateY(15%); */
      transform: scale(2.4);
    }
    &.size-lg {
      /* transform: scale(3) translateY(15%); */
      transform: scale(3);
    }

    svg {
      border-radius: ${layout.borderRadius};
      box-shadow: inset 0 0 0 2px ${colors.greyXXLight};
      background: ${colors.greyXLight};
      color: ${colors.grey};
    }

    svg.icon-on {
      display: none;
      color: ${colors.successDark};
    }
    svg.icon-off {
      display: flex;
    }

    &:hover {
      svg.icon-on {
        display: flex;
        background: white;
        color: ${colors.grey};
      }
      svg.icon-off {
        display: none;
      }
    }

    &.is-checked {
      svg.icon-on {
        display: flex;
        background: white;
        color: ${colors.successDark};
      }
      svg.icon-off {
        display: none;
      }
    }

    &.is-checked:hover {
      svg.icon-on {
        display: flex;
        background: white;
        color: ${colors.successDark};
      }
      svg.icon-off {
        display: none;
      }
    }
  }
`;
