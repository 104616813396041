import React, { createContext, useEffect } from 'react';
import { type StoreApi } from 'zustand';

export const createZustandContext = <TInitial, TStore extends StoreApi<any>>(
  getStore: (initial: TInitial) => TStore,
) => {
  const Context = createContext<TStore | null>(null);

  const Provider = (props: { children?: React.ReactNode; initialValue: TInitial }) => {
    const [store] = React.useState(() => getStore(props.initialValue));

    return <Context.Provider value={store}>{props.children}</Context.Provider>;
  };

  return {
    Context,
    Provider,
    useContext: () => React.useContext(Context),

    subscribe: (listener: (state: any) => void) => {
      const store = React.useContext(Context);
      if (!store) {
        throw new Error('Store cannot be null when subscribing to changes.');
      }
      useEffect(() => {
        const unsubscribe = store.subscribe(listener);
        return unsubscribe;
      }, [store]);

      return store;
    },
  };
};
