import { type ConstEnumOf } from 'types';
import type { NavItemConfig, RouteGroup } from './routes.types';
import type { RouteProps } from 'react-router-dom';

export const DEFUALT_ROUTE_ACCESS = {
  PUBLIC: true,
  GUEST: true,
  REGISTERED: true,
  VALIDATED: true,
  ADMIN: false,
};

export const ROUTE_GROUP: ConstEnumOf<RouteGroup> = {
  PUBLIC: 'public',
  USER: 'user',
  POSTS: 'posts',
  ADMIN: 'admin',
} as const;

export const DEFUALT_NAV_CONFIG: NavItemConfig = {
  group: 'left',
  isHidden: false,
  isHiddenChildren: false,
};

export const AUTH_ROUTE_PATHNAMES = ['/logout', '/logged-out', '/login'];

export const DEFAULT_ROUTES_CONFIG_KEYS: RouteGroup[] = ['public', 'user'];

export const routerFlagsV7 = {
  future: {
    v7_relativeSplatPath: true,
  },
};
