/* eslint-disable quote-props */
import { Col, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import React, { useState, useCallback, useEffect } from 'react';
import type { ReactNode, SetStateAction, Dispatch } from 'react';
import type { SizeUI } from 'types';
import { useLayout } from 'pages/Layout/context/LayoutContext';
import { Button } from 'components/Button';
import { Card } from 'components/Card';
import { ArrayJSX } from 'utils/ArrayJSX';
import { MODAL_ANIMATION_MS } from './Modal.config';
import { styles, aniModalClose } from './Modal.css';

// TODO: THIS HAS BEEN COPY + PASTED FROM ModalNavigation - MAKE GENERIC & ABSTRACTABLE !!!

export interface ModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onConfirm?: any;
  onCancel?: any;
  title?: string;
  size?: SizeUI;
  children?: ReactNode;
}

export const Modal = ({
  isOpen,
  setIsOpen,
  onConfirm,
  onCancel,
  title = 'This is a Modal',
  size = 'md',
  children,
}: ModalProps) => {
  const { t } = useTranslation();
  const { setLayoutState } = useLayout();
  const [isInitialized, setIsInitialized] = useState(false);

  const handleCancel = () => {
    initializeClose(onCancel);
    setIsOpen(false);
  };

  const handleConfirm = () => {
    initializeClose(onConfirm);
    setIsOpen(false);
  };

  const initializeClose = (action: any) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        setLayoutState({ isScrollLocked: false });
        action();
        resolve;
      }, MODAL_ANIMATION_MS * 0.9);
    });
  };

  const subtitle = '';
  const body = ['This is body line one.', 'This is body line two.'];
  const buttons = (
    <>
      {onConfirm && <Button label={t('t.buttons.OK')} variant="outline" color="success" onClick={handleConfirm} />}
      {onCancel && <Button label={t('t.buttons.CANCEL')} variant="outline" onClick={handleCancel} />}
    </>
  );

  // CSS CLASSES
  const cssClasses = new ArrayJSX('modal');
  cssClasses.push(`modal-${size}`);

  useEffect(() => {
    if (isOpen) {
      setIsInitialized(true);
    }
  }, [isOpen]);

  return (
    <div role="dialog" css={styles} className={isOpen ? 'is-open' : isInitialized ? 'is-closed' : ''}>
      <div className="modal-overlay" onClick={handleCancel} />
      <Card className={cssClasses.inline()} attr={{ role: 'dialog', 'aria-labelledby': 'dialog-title' }}>
        {(title || subtitle) && (
          <header>
            {title && <h1 id="dialog-title">{title}</h1>}
            {subtitle && <h2>{subtitle}</h2>}
          </header>
        )}
        <Row className="row-body">
          <Col xs={12}>{children}</Col>
        </Row>
        {(onConfirm || onCancel) && (
          <Row className="row-buttons">
            {onConfirm && (
              <Col>
                <Button label={t('t.buttons.OK')} variant="solid" color="success" onClick={handleConfirm} />
              </Col>
            )}
            {onCancel && (
              <Col>
                <Button label={t('t.buttons.CANCEL')} className="btn-cancel" variant="outline" onClick={handleCancel} />
              </Col>
            )}
          </Row>
        )}
      </Card>
    </div>
  );
};
