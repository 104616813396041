/* eslint-disable prefer-const */
import { useParams, useLocation, useMatch, matchPath, type Params, type PathMatch } from 'react-router-dom';
import { useMemo } from 'react';
import { ACTIONS } from 'types';
import type { CatMeta, LocationWithState } from 'types';
import type { RouteConfig } from 'routes';
// import { useAuth } from 'auth/AuthContext';
import { useGlobal } from 'store/useGlobalContext';
import type { GlobalStore } from 'store/GlobalContext.types';
import { config } from 'config';
import { getRoutePathParams } from '../../user/getRoutePathParams';
import type { ParamsCustom } from '../useRouter/useRouterLoader.types';
import { getFromLocation, getRouteAction, getUserRouteParams } from '../useRouter/useRouter.utils';
import { isUserPath, isAdminPath, getPathBase } from 'routes';
import { cleanRoutePath } from 'routes/utils/routes.utils';

// TODO: REFACTOR, CLEAN-UP + OPTIMISE !!!!!
// TODO: CLEAN + *GREATLY* SIMPLIFY THESE !!

export const useRouter = () => {
  const { ...state } = useGlobal();
  const paramsRouter = useParams();
  const location = useLocation();
  const pathname = location.pathname;

  // const PATH_BASE = cleanRoutePath(
  //   isUserPath() ? config.PATH_BASE_USER : isAdminPath() ? config.PATH_BASE_ADMIN : config.PATH_BASE_WEB || '/',
  // );
  const { PATH_BASE } = getPathBase();

  // const PATH_BASE = isAdminPath ? config.PATH_BASE_ADMIN : isUserPath ? '/registros' : config.PATH_BASE_WEB;
  const PATH_HOME = cleanRoutePath(
    `${PATH_BASE}/${isAdminPath() ? config.PATH_HOME_ADMIN : config.PATH_HOME_WEB}`,
  );

  const isRoot = !!matchPath({ path: PATH_BASE }, pathname);
  const match = !isRoot
    ? matchPath({ path: `${PATH_BASE}/:basePath/*` }, pathname)
    : matchPath({ path: `${PATH_BASE}/:basePath` }, pathname) || { basePath: null };

  // TODO: FIX !!!
  const matchParams = (match as PathMatch<'*' | 'cat' | 'parent' | 'basePath'>)?.params;
  type PathBaseMatch = Params<'*' | 'cat' | 'parent'> | Params<'basePath'> | { basePath: null };
  const basePath = isUserPath() ? 'registros' : ((match as any)?.cat ?? (match as any)?.basePath);

  // TODO: FIX!!!
  // my definition of "slug" - last segment of url path
  const slugMatch = pathname?.match(new RegExp('[^/]+(?=/$|$)'))?.[0] as string;

  const slug = (
    isUserPath()
      ? getUserRouteParams({ pathname })?.slug
      : ((Object.keys(ACTIONS).includes(slugMatch) ? basePath : slugMatch) as string)
  ) as string;

  // INIT META ARRAY ======================================================== //

  const metaCats: CatMeta[] = state.metaCats;
  const metaRoutes: RouteConfig[] = state.metaRoutes;

  // ======================================================================== //
  // (return): ============================================================== //
  // ======================================================================== //

  return useMemo(() => {
    const { routeMeta, catMeta, paramsCustom } = getRoutePathParams({
      pathname,
      slug,
      metaRoutes,
      metaCats,
      state: state as unknown as GlobalStore,
      isUserPath: isUserPath(),
    });

    // CATEGORY ============================================================= //

    if (catMeta?.parent && typeof catMeta.parent === 'string') {
      const parent = metaCats?.find((cat: CatMeta) => cat.slug === (catMeta?.parent as unknown as string));
      parent && Object.assign(catMeta, { parent });
    }

    // FROM LOCATION ======================================================== //

    const fromLocation = getFromLocation({
      location: location as unknown as LocationWithState,
      metaRoutes,
      paramsCustom,
    });

    // if ('from' in paramsCustom && (!paramsCustom?.from || paramsCustom.from === '/undefined')) {
    //   if (fromLocation) {
    //     Object.assign(paramsCustom, { from: fromLocation });
    //   } else {
    //     delete paramsCustom.from;
    //   }
    // }

    // CLEAN-UP: ID, SLUG, NEW ============================================== //

    for (const param of Object.keys(paramsCustom)) {
      const key = param as keyof ParamsCustom;
      if (!paramsCustom[key] && paramsCustom[key] !== false) delete paramsCustom[key];
    }
    // FIX (not sure why this is present):
    if ('*' in paramsRouter) delete (paramsRouter as { [key: string]: unknown })['*'];
    // NEW ENTRY ??
    if (paramsCustom?.new === false) delete paramsCustom.new;
    // if (pathname.endsWith('/new') || pathname.endsWith('/register') || pathname.endsWith('/registration')) {
    if (pathname.endsWith('/new') || pathname.endsWith('/register')) {
      paramsCustom.new = true;
      delete paramsCustom.id;
    }

    delete paramsCustom.from;

    // ACTION =============================================================== //

    const action = getRouteAction({ location: location as unknown as LocationWithState, paramsCustom });

    const values = {
      PATH_BASE,
      PATH_HOME,
      isAdminPath: isAdminPath(),
      isUserPath,
      basePath,
      pathname,
      params: {
        ...paramsCustom,
        ...paramsRouter,
      },
      slug: routeMeta?.slug || slug,
      action,
      location,
      fromLocation,
      navigateTo: (href: string) => {
        window.location.href = href;
      },
      route: routeMeta,
      category: catMeta as CatMeta,
    };

    return values;
  }, [location.pathname, metaCats, paramsRouter]);
};
