import { Row, Col } from 'react-grid-system';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactElement, useState } from 'react';
import { IoEyeOutline as IconEye, IoEyeOffOutline as IconEyeCrossed } from 'react-icons/io5';
import { Button } from 'components/Button';
import type { FieldConfig } from 'components/Form';
import { styles } from './InputPassword.css';

export const InputPassword = ({ field }: { field: FieldConfig }): ReactElement | null => {
  const { t } = useTranslation();
  const { register } = useFormContext();
  const [isVisible, setIsVisible] = useState(false);
  const handleToggle = () => {
    setIsVisible(!isVisible);
  };

  return (
    <Row css={styles}>
      <Col>
        <input
          {...register(field.name, { ...field.inputValidation })}
          type={isVisible ? 'text' : 'password'}
          placeholder={field?.placeholder}
          autoComplete={field.autoComplete}
        />
        <Button
          aria-label={t('t.forms.buttons.showPassword')}
          className="button-toggle"
          variant="icon"
          icon={isVisible ? <IconEye /> : <IconEyeCrossed />}
          iconScale={1.6}
          data-toggle={isVisible}
          onClick={handleToggle}
        />
      </Col>
    </Row>
  );
};
