import { css } from '@emotion/react';
import Color from 'color';

// VENDOR REQUIRED CSS ====================================================== //
// REF: https://codesandbox.io/s/react-menu-emotion-styled-2l35s?file=/src/App-v2.2.js:287-405
// TOASTER STYLES - https://github.com/fkhadra/react-toastify#readme
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import '@szhsin/react-menu/dist/core.css';
import 'react-toastify/dist/ReactToastify.css';
import { colors } from './colors.css';
import { layout } from './layout.css';

// COLOR VARIANTS =========================================================== //

const calSelectedDay = Color(colors.successDark).lighten(0).desaturate(0.1).hex();
const variantBase = Color(colors.primaryXLight);
const colorVariant = {
  bg: variantBase.lighten(0.8).hex(),
  inactive: variantBase.darken(0.2).desaturate(0).hex(),
  active: variantBase.darken(0.4).desaturate(0.3).hex(),
  sep: variantBase.darken(0.4).fade(0.75).rgb().toString(),
};

// @szhsin/react-menu ======================================================= //

export const cssMenu = css`
  .szh-menu {
    margin-top: calc(${layout.padding} * 0.66) !important;
    user-select: none;
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.33);
    padding: ${layout.padding};
    min-width: 12rem;

    &__item {
      border-radius: 6px;
      padding: calc(${layout.padding} * 0.75) calc(${layout.padding} * 1.5);
      margin: calc(${layout.padding} * 0.75) 0;
      font-weight: 700;
      color: ${colorVariant.inactive};
    }

    &__item--hover {
      color: ${variantBase.darken(0.1).desaturate(0.25).hex()};
      background-color: ${variantBase.lighten(0.66).desaturate(0.25).hex()};
    }

    &__item--submenu {
      background-color: ${colors.primaryXXLight};
      background-color: ${variantBase.lighten(0.66).desaturate(0.25).hex()};
    }

    &__item--open {
      color: #fff;
      background-color: ${colors.primaryXLight};
    }

    &__divider {
      margin: calc(${layout.padding} * 0.75) 0;
    }
  }

  .szh-menu__submenu > .szh-menu__item {
    position: relative;
    &::after {
      position: absolute;
      width: 7px;
      right: 0.625rem;
    }
  }

  .szh-menu-container {
    ul[role='menu'] {
      list-style: none;
      display: block;
      min-width: 250px;
      margin: 0;
      padding-top: calc(${layout.padding} * 0.25);
      padding-bottom: calc(${layout.padding} * 0.5);
      transform: translate(calc(${layout.padding} * -1.5), 0);

      .szh-menu {
        display: block !important;
        padding-top: 0.25em;
        padding-bottom: 0.25em;
      }

      li {
        a {
          padding: calc(${layout.padding} * 0.75) calc(${layout.padding} * 1.5) !important;
          width: 100%;
        }
      }

      li[role='menuitem'] {
        min-height: 2.5em;
        font-size: 1em;
        padding: 0 !important;
        span.menu-row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: calc(${layout.padding} * 0.75) calc(${layout.padding} * 1) !important;
          width: -webkit-fill-available;
          width: 100%;
          svg {
            transform: translate(0, 0) scale(1.75);
          }
          a,
          button {
            padding: calc(${layout.padding} * 0.75) calc(${layout.padding} * 1.5) !important;
            margin: calc(${layout.padding} * -0.75) calc(${layout.padding} * -1.5) !important;
            width: 100%;
          }
        }
      }
    }
  }
`;

// react-date-picker ======================================================== //

// COLOR VARIANTS

const calColors = {
  bg: variantBase.lighten(0.8).hex(),
  inactive: variantBase.darken(0.2).desaturate(0).hex(),
  active: variantBase.darken(0.4).desaturate(0.3).hex(),
  sep: variantBase.darken(0.4).fade(0.75).rgb().toString(),
  disabled: {
    bg: Color(colors.greyXLight).darken(0.4).fade(0.75).rgb().toString(),
    text: Color(colors.greyXLight).darken(0.4).fade(0.75).rgb().toString(),
  },
  highlighted: {
    bg: Color(colors.primary).darken(0.4).fade(0.75).rgb().toString(),
    text: Color(colors.primary).darken(0.4).fade(0.75).rgb().toString(),
  },
};

export const calendarDayColor = (colorBg?: string, colorBorder?: string, isImportant: boolean | string = false) => {
  isImportant = isImportant ? '!important' : '';
  colorBorder = colorBorder || colorBg;
  return css`
    background-color: ${colorBg}${isImportant};
    box-shadow:
      0 0 0 1px ${colorBorder || colorBg},
      0 0 0 1px ${colorBorder || colorBg} inset ${isImportant};
    transition:
      background-color 200ms ease,
      box-shadow 200ms ease ${isImportant};
  `;
};

export const cssDatePicker = css`
  .react-datepicker {
    user-select: none;
    .react-datepicker__month-container {
      /* float: none; */
    }
    button.react-datepicker__navigation {
      transform: translate(0%, 12%);
    }
    &__current-month {
      text-transform: capitalize;
    }
  }

  /* CALENDAR DAYS ---------------------------------- */

  /* day, disabled, selected, highlighted, today */

  .react-datepicker {
    &__day {
      border-radius: 0 !important;
      ${calendarDayColor(colors.greyXXLight, colors.greyXLight)}
      /* ${calendarDayColor(colors.white, colors.greyLight)} */
      color: ${colors.greyDark};
      /* color: ${colors.primaryXLight}; */
      font-weight: 600;
    }

    &__day--disabled:not(.react-datepicker__day--outside-month) {
      ${calendarDayColor(colors.white, colors.greyXLight)}
      color: ${colors.greyLight};
    }

    /* MARKED DAYS ---------------------------------- */

    &__day--today {
      ${calendarDayColor(colors.white, colors.primaryXXLight, true)}
      color: ${colors.primaryXLight}!important;
      pointer-events: none;
    }

    &__day--highlighted {
      ${calendarDayColor(colors.primaryXXLight)}
      color: white;
      &:hover {
        ${calendarDayColor(colors.primary)}
      }
    }

    &__day--selected {
      /* NEEDED?? - DEPENDS ON USE-CASE... USE OVERRIDES */
      ${calendarDayColor(colors.greyXLight)}
      ${calendarDayColor(colors.primaryXLight)}
      color: ${colors.white}!important;
      &:hover {
        ${calendarDayColor(colors.greyXLight)}
      }

      &.react-datepicker__day--highlighted:not(.react-datepicker__day--outside-month) {
        ${calendarDayColor(colors.successXDark, colors.successXDark, true)}
        ${calendarDayColor(calSelectedDay, calSelectedDay, true)}
        &:hover {
          ${calendarDayColor(colors.primary)}
        }
      }
    }

    /* DISABLED ---------------------------------- */

    &__day--disabled {
      pointer-events: none;
      ${calendarDayColor(colors.greyXXLight)}
      color: ${colors.textXXLight};
    }

    /* OUTSIDE MONTH ---------------------------------- */

    &__day--outside-month,
    &__day--outside-month[aria-disabled='true'] {
      &,
      &:hover {
        background-color: ${colors.white};
        box-shadow: 0 0 0 1px ${colors.white};
        color: ${colors.textXXLight};
        pointer-events: none;
      }
    }

    /* NAVIGATION ---------------------------------- */

    &__triangle {
      transform: translate(125px, 0px) !important;
    }
    &__navigation {
      display: flex;
      justify-content: center;
      width: 40px;
      padding-top: 12px !important;
      :hover {
        .react-datepicker__navigation-icon {
          :before {
            border-color: ${colors.primaryLight};
          }
        }
      }
    }
    &__navigation-icon {
      transform: scale(1.2);
      :before {
        border-color: ${colors.grey};
      }
    }
    &__navigation-icon--previous {
      /* transform: translateX(-5px) scale(1.2); */
    }
    &__navigation-icon--next {
      /* transform: translateX(5px) scale(1.2); */
    }
  }

  .react-datepicker__close-icon:after {
    background-color: ${colors.warningDark};
    height: 20px;
    width: 20px;
    padding: 0.1em 0.25em 0.2em 0.3em;
    font-size: 1em;
    font-weight: 700;
    line-height: 13px;
    transform: translateX(-33%);
    opacity: 0.5;
  }
`;

// react-toastify =========================================================== //

/*
TODO: for CSS STYLING !!
TOAST CONTAINER
id="toast-success"
Toastify__toast
Toastify__toast--success
TOAST_ALERT

TOAST BODY:
Toastify__toast-icon
Toastify__close-button
*/

export const cssToaster = css`
  .TOAST_ALERT,
  .Toastify__toast {
    width: 260px;
    margin-left: 40px;
    /* border: ${layout.borderWidth} solid ${colors.success}; */
    border-radius: ${layout.borderRadius};
    box-shadow: none;
    padding: calc(${layout.padding} * 0.66) calc(${layout.padding} * 1.33);

    &.Toastify__toast--success {
      background-color: ${colors.successXXLight};
      color: ${colors.successXXDark};
    }

    .Toastify__toast-body {
      text-align: center;
      font-size: 1.2em;
      font-weight: 700;
    }

    .Toastify__toast-icon {
      transform: translate(0em, 0) scale(1.33);
    }
  }
`;
