import { createContext, useMemo, useState } from 'react';
import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { config } from 'config';

type SetterFn = Dispatch<SetStateAction<number>>;

type PaginationProvider = {
  numItems: number;
  maxPerPage?: number;
  selectedIDs?: string[];
  pagination?: any;
  children: ReactNode;
};

export interface IPaginationContext {
  numItems: number;
  numPages: number;
  maxPerPage?: number;
  page: number;
  setPage: SetterFn;
  cursorStart: number;
  setCursorStart: SetterFn;
  cursorEnd: number;
  setCursorEnd: SetterFn;
  prevPage?: SetterFn;
  nextPage?: SetterFn;
  selectedIDs?: string[];
  setSelectedIDs?: any;
  toggleID?: any;
}

const initialState: IPaginationContext = {
  numItems: 0,
  numPages: 0,
  maxPerPage: config.TABLE_MAX_ROWS,
  page: 1,
  setPage: () => ({}),
  cursorStart: 0,
  setCursorStart: () => ({}),
  cursorEnd: config.TABLE_MAX_ROWS,
  setCursorEnd: () => ({}),
  prevPage: undefined,
  nextPage: undefined,
  selectedIDs: [],
  setSelectedIDs: () => ({}),
  toggleID: () => ({}),
};

export const PaginationContext = createContext(initialState);

// ===================================================== //

export const PaginationProvider = ({
  numItems,
  maxPerPage = config.TABLE_MAX_ROWS,
  selectedIDs: initSelectedIDs = [],
  children,
}: PaginationProvider) => {
  const [page, setPage] = useState<number>(initialState.page);
  const [cursorStart, setCursorStart] = useState(initialState.cursorStart);
  const [cursorEnd, setCursorEnd] = useState(initialState.cursorEnd);
  const numPages = Math.ceil(numItems / maxPerPage);

  const [selectedIDs, setSelectedIDs] = useState<string[]>(initSelectedIDs);

  const toggleID = (id: string) => {
    if (!selectedIDs.includes(id)) {
      setSelectedIDs([...selectedIDs, id]);
    } else {
      setSelectedIDs([...selectedIDs].filter((i) => i !== id));
    }
  };

  const contextValue: IPaginationContext = useMemo(
    () => ({
      numItems,
      numPages,
      maxPerPage,
      page,
      setPage,
      cursorStart,
      setCursorStart,
      cursorEnd,
      setCursorEnd,
      selectedIDs,
      setSelectedIDs,
      toggleID,
    }),
    [numItems, numPages, maxPerPage, page, cursorStart, cursorEnd, selectedIDs],
  );

  return <PaginationContext.Provider value={contextValue}>{children}</PaginationContext.Provider>;
};
