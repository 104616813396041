/* eslint-disable max-len */
import { RegisterOptions, ValidationRule, Message } from 'react-hook-form';
import { string } from 'yup';
import type { IndexSignatureString } from 'types';
import type { FormConfig, FieldConfig, FormButton, Pattern } from 'components/Form';

// ========================================================================== //

// RHF VALIDATION RULES
// Validation rules are all based on HTML standard and also allow custom validation.
// PROPS SET BY ref={register({...})}

/**
 * SUPPORTED BY REACT-HOOK-FORM
 * ============================
 * required
 * min
 * max
 * minLength
 * maxLength
 * pattern
 * validate
 */

interface ValidationRHF extends ValidationRule<any> {
  value: any;
  message: string;
}

export interface IPatternRHF {
  [key: string]: ValidationRHF;
}

// export interface IPatternRHF {
//   [key: string]: {
//     value?: Pattern;
//     message?: string;
//   };
// }

export interface IValidationPropsRHF {
  // HTML5 CORE:
  required?: false | Message | ValidationRule<boolean>;
  pattern?: null | ValidationRHF | ValidationRule<RegExp>;
  validate?: null | ValidationRHF;
  // NUMBERS:
  min?: null | ValidationRHF | ValidationRule<number | string>;
  max?: null | ValidationRHF | ValidationRule<number | string>;
  // STRINGS:
  minLength?: null | ValidationRHF | ValidationRule<number | string>;
  maxLength?: null | ValidationRHF | ValidationRule<number | string>;
}

// https://react-hook-form.com/ts#RegisterOptions
export const inputValidationRHF: IValidationPropsRHF = {
  // HTML5 CORE:
  required: false,
  pattern: null,
  validate: null,
  // NUMBERS:
  min: null,
  max: null,
  // STRINGS:
  minLength: null,
  maxLength: null,
};

// ========================================================================== //

// IGNORE ERRORS BELOW CHAR-COUNT:
export const hasErrorMinLength = 3;

export const errorMessages = {
  required: 'This field is required',
  pattern: 'Incorrect format of value entered',
  url: 'Invalid URL format',
  email: 'Invalid email format',
  tel: 'Invalid phone number. Must be international format.',
  min: 'Number must be at least %1',
  max: 'Number must not exceed %1',
  minLength: 'Value must be at least %1 characters long',
  maxLength: 'Value entered exceed maximum number of %1 characters',
  password: 'Password should contain at least one uppercase letter, lowercase letter, digit, and special symbol.',
  postal_code: 'Value must be valid postal code.',
} as { [key: string]: string };

export const validationsBasic = {
  required: errorMessages.required,
  minLength: {
    value: 3,
    message: errorMessages.minLength,
  },
  maxLength: {
    value: 25,
    message: errorMessages.maxLength,
  },
  min: {
    value: 1,
    message: errorMessages.min,
  },
  max: {
    value: 999,
    message: errorMessages.max,
  },
};

// TODO: FOR EACH TYPE, ADD "WARNING PATTERN"
// TO IGNORE WARNINGS SHOWN INITIALLY WHEN USER BEGINS TO TYPE VALUE

export const patterns: IPatternRHF = {
  url: {
    value: /[(http(s)?)://(www.)?\w-/=#%&.?]{2,}\.[a-z]{2,}([\w-/=#%&.?]*)/,
    message: errorMessages.url,
  },
  email: {
    value:
      /^([a-zA-Z0-9_\-.]+)*(\+[a-z0-9-]+)?@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/,
    message: errorMessages.email,
    placeholder: 'correo@website.com',
  },
  tel: {
    value:
      /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})$/,
    message: errorMessages.tel,
    placeholder: '+34 123-456-789',
  },
  password: {
    value: !/(?=.*d)(?=.*[a-z])(?=.*[A-Z])(?!.*s)(?=.*[!@#$*])/,
    message: errorMessages.password,
  },
  postal_code: {
    value: /^[0-9]{5}$/,
    message: errorMessages.postal_code,
  },
};

// ========================================================================== //

// COMMON, BASIC TYPINGS - CHECKED ON SUBMIT
// Mainly for converting integers/numbers back from string values.

export const typings: ITypings = {
  id: (value: string) => Number(value),
  is_active: (value: string) => Number(value),
};

export const typeFormValues = (data: any): void => {
  Object.entries(data).map(([key, val]) => {
    if (key in typings) {
      data[key] = typings[key](val);
    }
  });
};

interface ITypings {
  [key: string]: (value: any) => any;
}
