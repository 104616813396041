import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import type { ChangeEvent, KeyboardEvent } from 'react';
import { matchSorter } from 'match-sorter';
import { debounce } from 'throttle-debounce';
import { FaSearch as IconView } from 'react-icons/fa';
import { IoCloseSharp as IconDelete } from 'react-icons/io5';
import { Button } from 'components/Button';
import { usePagination } from 'components/Pagination';
import { ArrayJSX } from 'utils/ArrayJSX';
import { styles } from './DataSearch.css';

export const DataSearch = ({
  filterByKeys = ['name'],
  data,
  setSearchResults,
  searchResults,
  searchQuery: searchQueryExternal,
  setSearchQuery,
  placeholder,
  isDisabled = false,
}: any) => {
  const { register, getValues, setValue } = useForm();
  const { gotoFirstPage } = usePagination();
  const [isActive, setIsActive] = useState(false);
  const searchQueryValue = getValues('searchQuery');

  // TODO: see CHECKBOX for EXISTING EXAMPLE of CUSTOM ICONS
  const IconActive = IconDelete;
  const IconNotActive = IconDelete;

  const handleClickAction = (e: ChangeEvent<HTMLInputElement>) => {
    setValue('searchQuery', '');
    setSearchQuery('');
    setSearchResults(data);
    gotoFirstPage();
  };

  // www.npmjs.com/package/match-sorter
  // MATCH-SORTER (matchSorter) - THRESHOLD prop
  // threshold: number
  // Default: MATCHES

  // NOTE: BEST THRESHOLD MESSAGE
  // STARTS_WITH
  // WORD_STARTS_WITH // BEST ??
  // CONTAINS
  // MATCHES (default value)

  const MIN_CHARS = 2;

  const setQueryMatches = ({ searchQuery = '' }: { searchQuery: string }) => {
    const matches = matchSorter(data, searchQueryValue, {
      keys: [filterByKeys].flat(),
      // threshold: matchSorter.rankings.MATCHES, // (default)
      // threshold: matchSorter.rankings.CONTAINS,
      // threshold: matchSorter.rankings.STARTS_WITH,
      threshold: matchSorter.rankings.WORD_STARTS_WITH, // <== BEST!!
      baseSort: (a, b) => (a.index < b.index ? -1 : 1),
    });

    switch (true) {
      case searchQuery.length >= MIN_CHARS && matches.length >= 1:
        setSearchQuery(searchQuery);
        setSearchResults(matches);
        gotoFirstPage();
        break;
      case searchQuery.length >= MIN_CHARS && matches.length === 0:
        setSearchQuery(searchQuery);
        setSearchResults([]);
        gotoFirstPage();
        break;

      case searchQuery.length < MIN_CHARS:
      default:
        // DO NOTHING - USE DEFAULT DATASET
        setSearchQuery(searchQuery);
        setSearchResults(data);
        gotoFirstPage();
        break;
    }
  };

  const handleSearchEvent = (e: KeyboardEvent<HTMLInputElement>) => {
    const searchQuery = (e.target as HTMLInputElement).value;
    setQueryMatches({ searchQuery });
  };

  const handleChange = (e: KeyboardEvent<HTMLInputElement>) => {
    // TODO: DO WE WANT "FORM SUBMISSION" WITH HARD-SUBMIT PAGE RELOAD
    if (e.key === 'Enter') {
      e.preventDefault();
      (e.target as HTMLInputElement).value.trim();
      debounce(50, (e.target as HTMLInputElement).blur());
    }
    debounce(50, handleSearchEvent(e)); // NOTE: DEBOUNCE *HUGELY* IMPROVES PERFORMANCE !!!
  };

  // ======================================================================== //

  useEffect(() => {
    // if (!isDisabled) {
    const searchQuery = getValues('searchQuery');
    setQueryMatches({ searchQuery });
    // }
  }, [isDisabled, data]);

  useEffect(() => {
    gotoFirstPage();
  }, [searchResults]);

  useEffect(() => {
    setIsActive(!!getValues('searchQuery'));
  }, [getValues('searchQuery')]);

  // ========================================================================= //

  // CSS CLASSES
  const cssClasses = new ArrayJSX('col-image');
  if (getValues('searchQuery')) {
    getValues('searchQuery')?.length >= MIN_CHARS && cssClasses.push('has-query');
  } else if (searchQueryExternal) {
    searchQueryExternal?.length >= MIN_CHARS && cssClasses.push('has-query');
  }
  searchResults?.length === 0 && cssClasses.push('no-results');
  // isDisabled && cssClasses.push('is-disabled');
  // !isActive && cssClasses.push('is-disabled');

  return (
    <div css={styles} id="search" className={cssClasses.inline()}>
      <label>
        <IconView />
      </label>
      <input
        {...register('searchQuery')}
        type="text"
        placeholder={placeholder || 'Search...'}
        onKeyUp={handleChange}
        className={searchResults && searchResults.length === 0 ? 'no-results' : undefined}
        autoComplete="off"
        disabled={isDisabled}
        defaultValue={getValues('searchQuery')}
      />
      {/* TODO: use new custom CLEARABLE InputField ?? */}
      <Button
        className="button-clear"
        variant="icon"
        iconScale={1.6}
        icon={isActive ? <IconActive /> : <IconNotActive />}
        data-is-active={isActive}
        onClick={handleClickAction}
        // isDisabled={isDisabled}
      />
    </div>
  );
};
