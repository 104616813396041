import { css } from '@emotion/react';
import { config } from 'config';
import { layout } from 'styles/layout.css';
import { colors } from 'styles/colors.css';
import { min, max } from 'styles/media-queries-V1';

export const styles = css`
  h2 {
    background-color: ${colors.greyLight};
    color: white;
    padding: 0.33em 0.5em;
    margin: calc(${layout.padding}*1.5) -10px 0;
    font-size: 0.9em;
    text-align: center;
    border-radius: ${layout.borderRadius};
    span {
      padding-left: 0.5em;
      opacity: 0.5;
    }
  }

  .td {
    font-size: 0.9em !important;
    &,
    & > * {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .td.col-image {
    transition: opacity 0.5s ease !important;
  }

  &.table-busy {
    .card {
      opacity: 0.66;
    }
  }

  /* ROWS ******************************************************** */

  .data-row {
    & > div {
    }

    background-color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid ${colors.greyXLight};

    &:last-of-type > div {
      /*border-bottom: 0px;*/
    }

    & > div > div {
    }

    .hide-max-lg {
      ${max.lg} {
        display: none;
      }
    }
  }

  /* COLUMNS ******************************************************** */

  .col {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 calc(${layout.padding} * 2) !important;
    color: ${colors.greyDark};
    font-weight: 500;
    &.align-left {
      justify-content: flex-start;
    }
    &.align-center {
      justify-content: center;
    }
    &.align-right {
      justify-content: flex-end;
    }
  }

  .col-name {
    & > a {
      color: ${colors.greyDark};

      font-weight: 700;
    }
  }

  .col-image {
    min-width: 100px !important;
    img {
      height: ${config.tableRowHeight};
    }
    & > a > div {
      top: 0;
      bottom: 0;
      background-color: #e9e9e9;
    }
  }

  .col-id,
  .col-date {
    justify-content: center;
    padding: 0 !important;
  }

  .col-city,
  .col-province,
  .col-country {
    text-transform: capitalize;
    & > *::first-letter {
      /* text-transform: uppercase; */
    }
  }

  .col-inner {
    &.col-action {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .col-type {
  }

  .col-id {
  }

  .col-action {
  }

  .col-view {
  }

  .col-edit {
  }

  /* CONTENTS ******************************************************** */

  .link-cat {
    /* default link color */
    color: ${colors.primary};
  }

  .link-cat-parent {
    color: ${colors.primaryXLight};
    &:hover {
      color: ${colors.primary};
    }
  }
`;
