import type { AuthCredentials, AuthClientOptions, AuthServiceDB } from '../types/auth.types';

export const SESSION_LENGTH_HRS = 1;
export const AUTHENTICATOR_TOKEN = 'auth-jwt';
export const AUTHENTICATOR_CREDENTIALS: AuthCredentials = {
  email: 'auth@apnaes.org',
  password: 'Pass123',
};

export const AUTH_USER_TOKEN = 'auth';
export const DB_AUTH_SERVICE: AuthServiceDB = {
  entity: 'user', // the entity you are authenticating (ie. a users)
  service: 'users', // the service to look up the entity
};

export const DEFAULT_AUTH_OPTIONS_CORE: Omit<AuthClientOptions, 'header' | 'scheme'> = {
  storageKey: AUTHENTICATOR_TOKEN, // the key to store the accessToken in localstorage or AsyncStorage on React Native
  locationKey: 'access_token',
  locationErrorKey: 'error',
  jwtStrategy: 'jwt', // the name of the JWT authentication strategy
  path: '/authentication', // the server-side authentication service path
  storage: localStorage, // Passing a WebStorage-compatible object to enable automatic storage on the client.
  cookie: AUTHENTICATOR_TOKEN, // the name of the cookie to parse the JWT from when cookies are enabled server side
  ...DB_AUTH_SERVICE,
};
