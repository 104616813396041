/* eslint-disable @typescript-eslint/no-shadow */
import { Col, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { FaRegImage as IconImage } from 'react-icons/fa';
import { useGlobal } from 'store/useGlobalContext';
import { toUpperCaseFirst, isNonEmptyString, isValueFileSymbol, PATTERNS } from 'utils';
import { ArrayJSX } from 'utils/ArrayJSX';
import { API_BASE_IMAGES } from 'config';
import { PageHeroInfo } from './PageHeroInfo';
import { styles } from './PageHero.css';

export const PageHero = ({ data }: any) => {
  const { t } = useTranslation();
  const { pageHero } = useGlobal('pageHero');
  const [imagePreview, setImagePreview] = useState<Blob | MediaSource | string>('');

  const hasImage = !!(pageHero?.image || imagePreview);
  // const hasImage = true;

  // TODO: REMOVE THESE TERNARIES, AS PROBABLY NOT NECESSARY..
  // const title =
  //   typeof pageHero?.title === 'object'
  //     ? pageHero.title[0]?.name
  //     : typeof pageHero?.title === 'string'
  //       ? pageHero.title
  //       : '';
  const title = pageHero.title === false ? false : (pageHero.title || '').replace('undefined', '').trim();

  // ====================================================================== //

  useEffect(() => {
    const updateImagePreview = ({ image }: { image: any }) => {
      switch (true) {
        case isValueFileSymbol(image):
          setImagePreview(URL.createObjectURL(image));
          break;
        case PATTERNS.URL.test(image):
          setImagePreview(image);
          break;
        case isNonEmptyString(image):
          setImagePreview(`${API_BASE_IMAGES}/${image}`);
          break;
        default:
          setImagePreview('');
          break;
      }
    };
    pageHero?.image && !hasImage && updateImagePreview(pageHero);
  }, [pageHero?.image]);

  const triggerImageSelect = () => {
    const inputFile = document.querySelector('.fileupload-image input[type="file"]') as HTMLInputElement;
    inputFile.click();
  };

  // CSS CLASSES
  const cssClasses = new ArrayJSX('col-image');
  imagePreview && cssClasses.push('has-preview');

  const mainColumnSizes = hasImage
    ? { xs: 12, sm: 12, md: 6, lg: 6, xl: 7 }
    : { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 };

  return (
    <div css={styles}>
      <Row>
        <Col {...mainColumnSizes} className="col col-details">
          {title !== false && (
            <h2>{toUpperCaseFirst(title) || <span className="placeholder">{t('t.hero.newName')}</span>}</h2>
          )}
          <PageHeroInfo />
        </Col>
        {hasImage && (
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={5}
            onClick={triggerImageSelect}
            className={`col ${cssClasses.inline()}`}
            style={{
              background: imagePreview ? `url(${imagePreview}?r=${uuid()})` : undefined,
            }}
          >
            {!imagePreview && <IconImage />}
          </Col>
        )}
      </Row>
    </div>
  );
};
