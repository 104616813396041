import { config } from 'config';
import { useEffect, useState } from 'react';
import type { DataConfigRole, DataConfigurationType } from 'types';
// import { useRouter } from 'routes/hooks';
import { translate } from 'utils';

// ------------------------------------------------------------------------ //

// CONFIGS: main..
import * as _default from 'config/datatype/_default';
import * as canales from 'config/datatype/canales';
import * as periodicos from 'config/datatype/periodicos';
import * as entidades from 'config/datatype/entidades';
import * as posts from 'config/datatype/posts';
import * as eventos from 'config/datatype/eventos';
import * as registros from 'config/datatype/registros';
import * as users from 'config/datatype/users';

const dataConfigs = { _default, canales, periodicos, entidades, posts, eventos, registros, users };

// ------------------------------------------------------------------------ //

export const useDataConiguration = <T extends DataConfigurationType>({
  defaultConfig: initDefaultConfig,
  defaultDataTypeKey,
  defaultEndpoint = 'posts',
  role,
}: {
  defaultConfig: any;
  defaultDataTypeKey?: string;
  defaultEndpoint?: string;
  role: DataConfigRole;
}): { endpoint: string; dataConfiguration: T | undefined } => {
  // const defaultConfigRole = Object.keys(initDefaultConfig).at(0) || '';
  // const isModule = ['tableConfig', 'formConfig', 'detailConfig'].includes(defaultConfigRole);

  const isModule = ['tableConfig', 'formConfig', 'detailConfig'].includes(Object.keys(initDefaultConfig).at(0) || '');
  const defaultConfig = isModule ? Object.values(initDefaultConfig).at(0) : initDefaultConfig;

  // const { params, category, route } = useRouter();
  const [dataConfiguration, setDataConfiguration] = useState<T | undefined>();
  const [endpoint, setEndpoint] = useState<string>('');

  // log('__DEV:', 'blue', role, defaultConfigRole, initDefaultConfig);
  // log('__DEV:', 'blue', { isModule });
  // log('__DEV:', 'grey', defaultConfig);

  // log('DATA_CONFIG:', 'orange', dataConfiguration);
  // log('DATA_CONFIG: PARAMS', 'orange', params);
  // log('DATA_CONFIG: category', 'yellow', category);
  // log('DATA_CONFIG:', 'grey', { endpoint }, dataConfiguration);

  useEffect(() => {
    try {
      const dataTypeKey =
        defaultDataTypeKey || (defaultConfig as unknown as DataConfigurationType).dataType?.key || '_dafault';
      const configModuleKey = `${role || (dataConfiguration || defaultConfig)?.dataType?.role}Config`;
      const configs = dataConfigs[dataTypeKey as keyof typeof dataConfigs];

      // NOTE: TESTING VITE EXPLICIT IMPORTING..
      const configuration = configs[configModuleKey as keyof typeof configs] as T;
      translate(configuration);
      setDataConfiguration(configuration);
      setEndpoint(configuration.api.endpoint || defaultEndpoint);
      /*
      const filepath = `config/datatypes/${dataTypeKey}/index.ts`;
      import(filepath).then((configs: any) => {
        const configuration = configs[configModuleKey];
        translate(configuration);
        setDataConfiguration(configuration);
        setEndpoint(configuration.api.endpoint || defaultEndpoint);
      });
      */
    } catch (error: unknown) {
      import('config/datatype/_default/index.ts').then((configs: any) => {
        const configuration = configs[`${role}Config`];
        translate(configuration);
        setDataConfiguration(configuration);
        setEndpoint(configuration.api.endpoint || defaultEndpoint);
      });
    }
  }, [location.pathname]);

  // log(
  //   'DATA_CONFIG:',
  //   'hotpink',
  //   { key: dataConfiguration?.dataType?.key, role: dataConfiguration?.dataType?.role, endpoint },
  //   dataConfiguration,
  // );

  return { endpoint, dataConfiguration };
};
