import { SerializedStyles } from '@emotion/serialize';

export type { SerializedStyles };

export class ArrayJSX {
  value: (string | TemplateStringsArray | SerializedStyles)[];

  constructor(init = '') {
    this.value = [init];
  }

  push(addedClass: string | TemplateStringsArray | SerializedStyles): void {
    if (this.value.includes(addedClass)) return;
    this.value.push(addedClass);
  }

  add(addedClassArray: string[]): void {
    this.value = this.value.concat(addedClassArray);
  }

  remove(removedClass: string | TemplateStringsArray | SerializedStyles): void {
    this.value = this.value.filter((val) => val != removedClass);
  }

  toggle(toggleClass: string | TemplateStringsArray | SerializedStyles): void {
    this.value.includes(toggleClass) ? this.remove(toggleClass) : this.push(toggleClass);
  }

  toggleIf(toggleClass: string | TemplateStringsArray | SerializedStyles, ifClause: boolean): void {
    ifClause ? this.push(toggleClass) : this.remove(toggleClass);
  }

  inline(first: string = ''): string {
    first && this.value.unshift(first);
    return this.value.join(' ').trimStart();
  }
}
