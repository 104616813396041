export const getStoredGuest = () => {
  try {
    const storedAuth = localStorage?.getItem('guest');
    return JSON.parse(storedAuth as string);
  } catch (error: unknown) {
    return false;
  }
};

export const deleteStoredGuest = () => {
  const storedGuest = getStoredGuest();
  if (storedGuest) {
    localStorage?.removeItem('guest');
  }
};
