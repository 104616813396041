import type { RouteObject } from 'react-router-dom';
import { RouteConfig } from 'routes/routes.types';

const generatePathname = (basePath: string, currentPath: string): string => {
  // Ensure basePath and currentPath start with a slash
  basePath = basePath.startsWith('/') ? basePath : `/${basePath}`;
  currentPath = currentPath.startsWith('/') ? currentPath : `/${currentPath}`;

  const pathname = `${basePath}/${currentPath}`
    // Replace multiple consecutive slashes with a single slash
    .replace(/\/+/g, '/')
    // Remove trailing slash if present
    .replace(/\/$/, '');

  return pathname;
};
const mergeRouteConfig = (transformedRoute: any, routeConfig: RouteConfig): any => {
  const { path, ...restConfig } = routeConfig;
  return { ...transformedRoute, ...restConfig };
};

export const transformRoutes = (
  routes: RouteObject[],
  basePath: string = '',
  routesConfig: RouteConfig[] = [],
  isTopLevel: boolean = true,
): RouteConfig[] => {
  return routes.map((route) => {
    const { path, children, id, index, ..._rest } = route;
    const currentPath = index ? '' : path || '';
    const pathname = generatePathname(basePath, currentPath);

    let transformedRoute: RouteConfig = {};
    path && Object.assign(transformedRoute, { path });
    pathname && Object.assign(transformedRoute, { pathname });

    if (children) {
      transformedRoute.children = transformRoutes(children, pathname, routesConfig, false);
    }

    id && Object.assign(transformedRoute, { id });
    index && Object.assign(transformedRoute, { index });

    // Find matching route config
    const matchingConfig = routesConfig.find((config) => config.pathname === pathname);
    if (matchingConfig && !isTopLevel && !transformedRoute?.id) {
      transformedRoute = mergeRouteConfig(transformedRoute, matchingConfig);
    }

    // Only merge route config for children if the id matches the routeGroup
    if (id && !isTopLevel) {
      const matchingConfigs = routesConfig.filter((config) => config?.routeGroup === id);

      // log('MATCHES', 'orange', { transformedRoute }, matchingConfigs);
      if (matchingConfigs.length > 0) {
        transformedRoute.children = (transformedRoute.children || []).concat(
          matchingConfigs.map((config) => mergeRouteConfig({}, config)),
        );
      }
    }

    return transformedRoute as RouteConfig;
  });
};

export const transformRoutes__V1 = (
  routes: RouteObject[],
  basePath: string = '',
  routesConfig: RouteConfig[] = [],
  isTopLevel: boolean = true,
): RouteConfig[] => {
  return routes.map((route) => {
    const { path, children, id, index, ..._rest } = route;
    const currentPath = index ? '' : path || '';
    const pathname = generatePathname(basePath, currentPath);

    let transformedRoute: RouteConfig = {};
    path && Object.assign(transformedRoute, { path });
    pathname && Object.assign(transformedRoute, { pathname });

    if (children) {
      transformedRoute.children = transformRoutes(children, pathname, routesConfig, false);
    }

    id && Object.assign(transformedRoute, { id });
    index && Object.assign(transformedRoute, { index });

    // Find matching route config
    const matchingConfig = routesConfig.find((config) => config.path === pathname);
    if (matchingConfig && !isTopLevel && !transformedRoute?.id) {
      transformedRoute = mergeRouteConfig(transformedRoute, matchingConfig);
    }

    // Only merge route config for children if the id matches the routeGroup
    if (id && !isTopLevel) {
      const matchingConfigs = routesConfig.filter((config) => config?.routeGroup === id);

      // log('MATCHES', 'orange', { transformedRoute }, matchingConfigs);
      if (matchingConfigs.length > 0) {
        transformedRoute.children = (transformedRoute.children || []).concat(
          matchingConfigs.map((config) => mergeRouteConfig({}, config)),
        );
      }
    }

    return transformedRoute as RouteConfig;
  });
};
