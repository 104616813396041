import { Row, Col } from 'react-grid-system';
import type { UseFormReturn } from 'react-hook-form';
import { useFormState, useFormContext } from 'react-hook-form';
import type { ReactElement } from 'react';
import type { FieldConfig } from 'components/Form';
import { ErrorMessage } from 'components/Form/components/ErrorMessage/ErrorMessage-V1';
import { ModalTrigger } from 'components/ModalTrigger';
import { handleErrors } from './FormField.utils';
import { LabelContent } from './LabelContent';
import { styles } from './FormFieldLabel.css';
import { ArrayJSX } from 'utils/ArrayJSX';

// NOTE: GREAT REF: REACT-QUERY + REACT-HOOK-FORM:
// https://tkdodo.eu/blog/react-query-and-forms#invalidate-and-reset-after-mutation

// TODO: AMAZING BEST-PRACTICES REF FOR FORMS:
// ref: https://blog.logrocket.com/style-forms-css/

export interface FormFieldLabelProps {
  field: FieldConfig;
  isLabelInline?: boolean;
  isFormLocked?: boolean;
}

export const FormFieldLabel = ({
  field,
  isLabelInline = false,
  isFormLocked = false,
}: FormFieldLabelProps): ReactElement => {
  const formMethods = useFormContext();
  const { errorProps /* , hasWarning, hasError */ } = handleErrors({ field, methods: formMethods as UseFormReturn });
  const { isDirty } = useFormState({ name: field.name });

  const cssClasses = new ArrayJSX();
  isLabelInline && cssClasses.push('label-inline');
  field.required && cssClasses.push('required');
  field.isDisabled && cssClasses.push('is-disabled');

  // NOTE: INLINE LABEL - CHECKBOXES etc..

  if (isLabelInline && !field.hint) {
    return (
      <span css={styles} className={`row row-label ${cssClasses.inline()}`}>
        <label htmlFor={`field__${field.name}`}>
          <LabelContent field={field} isLabelInline={isLabelInline} isDisabled={!!field.isDisabled} />
        </label>
        {errorProps.isErrorVisible && <ErrorMessage {...errorProps} />}
      </span>
    );
  }

  // NOTE: DEFAULT LABEL..
  // TODO: SEPARATE ERROR vs HINT COLUMNS (??)

  return (
    <Row css={styles} nogutter className={`row-label ${cssClasses.inline()}`}>
      <Col xs={12} sm={6} md={12} lg={6} className="col col-label col-label-left">
        <label htmlFor={`field__${field.name}`}>
          <LabelContent field={field} isLabelInline={isLabelInline} isDisabled={!!field.isDisabled} />
        </label>
      </Col>
      <Col xs={12} sm={6} md={12} lg={6} className="col col-label col-label-right">
        {errorProps.isErrorVisible && <ErrorMessage {...errorProps} />}
        {!errorProps.isErrorVisible && field.hint && (
          <span className="label-hint" id={`label_hint__${field.name}`}>
            {field.hint}
          </span>
        )}
      </Col>
    </Row>
  );
};
