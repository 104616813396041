import { faker, fakerES, fakerEN } from '@faker-js/faker';
import { slugify } from 'utils/utils.string.format';
import { getRandomInt } from 'utils/utils.number';
import { PROVINCIAS } from './constants/fakerValues_ES';

export const genMockEmail = () => {
  const name_es = slugify(fakerES.person.fullName()).replace(/-/g, '.');
  const name_en = slugify(fakerEN.person.fullName()).replace(/-/g, '.');
  const province = slugify(PROVINCIAS[Math.floor(Math.random() * PROVINCIAS.length)]);
  const business = slugify(
    faker.helpers.arrayElement([
      fakerEN.company.name(),
      fakerES.company.name(),
      fakerEN.company.buzzNoun(),
      fakerES.company.buzzNoun(),
      fakerEN.company.buzzPhrase(),
      fakerES.company.buzzPhrase(),
    ]),
  );

  const emailName = faker.helpers.arrayElement([name_es, name_es, name_es, name_es, name_en]);
  const domainEnding = faker.helpers.arrayElement(['com', 'com', 'com', 'co', 'co', 'co.uk', 'es', 'biz']);
  const domain = faker.helpers.arrayElement([
    `${business}.${domainEnding}`,
    `${business}.${domainEnding}`,
    `${business}.${domainEnding}`,
    `${province}.${domainEnding}`,
    'gmail.com',
    'gmail.com',
    'gmail.com',
    'hotmail.com',
  ]);
  const mockEmail = `${emailName}@${domain}`;

  return faker.helpers
    .arrayElement([
      mockEmail,
      mockEmail,
      mockEmail,
      mockEmail,
      mockEmail,
      fakerES.internet.email(),
      fakerES.internet.email(),
      fakerES.internet.email(),
      faker.internet.email(),
      faker.internet.email(),
    ])
    .toLowerCase();
};

export const genMockTelNumber = (
  { isInternational }: { isInternational: boolean } = { isInternational: true },
) => {
  const mockTelNumber = fakerES.phone.number().replace(/\./g, ' ');
  // const mockTelNumber = fakerES.phone.number();
  if (isInternational) {
    const randomIntlPrefix = faker.helpers.arrayElement([
      `+${getRandomInt(1, 91)}`,
      '+34',
      '+34',
      '+34',
      '+34',
      '+34',
      '+1',
      '+1',
    ]);

    return `${randomIntlPrefix} ${mockTelNumber}`;
  }

  return mockTelNumber;
};
