import { Row } from 'react-grid-system';
import { ReactElement, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import type { DataEntry, TableConfig } from 'types';
import { ListItemCard as ListItemCardDefault } from 'components/ListItemCard';
import { usePagination } from 'components/Pagination';
import { getResponsiveGridItemProps } from 'utils';
import { tableConfig as tableConfigDefaultWeb } from 'config/datatype/_default'; // ADMIN CONFIG for WEB ??
import { styles } from './DataGrid.css';

// TODO: ⭐🚀 READ THIS !! - see: DataGrid.EXAMPLE.types.ts
// PrismaAdmin - UI  Table - EXAMPLE PROPS
// ref: https://paljs.com/prisma-admin/#props-1

type DataGridProps = {
  data: DataEntry[];
  tableConfig?: TableConfig;
  basePath?: string;
  itemComponent: any;
  shapeRatio?: number;
  hasHeader?: boolean;
};

export const DataGrid = ({
  data,
  tableConfig = tableConfigDefaultWeb,
  itemComponent,
  shapeRatio = 2.2,
  hasHeader = false,
}: DataGridProps): ReactElement => {
  // TODO: IMPLEMET THIS OVER PASSING DATA IN -OR- CAN REPLACE `data` IF NOT PASSED
  const { page, cursorStart, cursorEnd, setCursorRange } = usePagination();
  const [pageItems, setPageItems] = useState<unknown[]>(data);
  const [isBusy, setIsBusy] = useState(false);

  const doPageTransition = () => {
    setIsBusy(true);
    setTimeout(() => setIsBusy(false), 200);
  };

  useEffect(() => {
    doPageTransition();
    setCursorRange();
  }, [page]);

  useEffect(() => {
    setPageItems(data.slice(cursorStart, cursorEnd));
  }, [data, cursorStart, cursorEnd]);

  const ListItemCard = itemComponent ?? ListItemCardDefault;
  const listConfig = tableConfig.list || ({} as any);
  const listItemConfig = listConfig.listItem || ({} as any);
  const responsiveness = getResponsiveGridItemProps({ listConfig, listItemConfig, shapeRatio: 1 });

  return (
    <Row css={styles} className={`${isBusy ? 'table-busy' : ''}`}>
      {hasHeader && (
        <div className={'grid-item-container grid-item-header'} css={responsiveness}>
          <div className="grid-item-gutter ">
            {<ListItemCard data={pageItems[0]} config={tableConfig} index={0} isHeader={true} />}
          </div>
        </div>
      )}
      {pageItems.map((item: any, index: number) => {
        const isEven: boolean = index % 2 == 0;
        const cssEvenOdd = `item-${isEven ? 'even' : 'odd'}`;

        return (
          <div
            key={item.id || uuid()}
            className={`grid-item-container ${cssEvenOdd}`}
            css={responsiveness}
            style={{ minHeight: listItemConfig?.minHeight || 'unset' }}
          >
            <div className="grid-item-gutter">
              {item && (
                <ListItemCard
                  data={item}
                  config={tableConfig}
                  // isEven={isEven}
                  index={index}
                />
              )}
            </div>
          </div>
        );
      })}
    </Row>
  );
};
