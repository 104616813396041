import { INPUTS } from 'types/form.types';
import type { FormConfig, FormButton, FormHeroConfig } from 'components/Form';
import { types, BUTTONS } from 'components/Form/components/FormButtonBar/FormButtonBar.types';
import { SPAIN_PROVINCES } from 'constants/spain';
import { DATATYPE, ENDPOINT } from './users.config';

let i = 0;

const buttons: FormButton[] = [
  {
    type: types.button,
    label: 'CANCEL',
    action: BUTTONS.CANCEL,
  },
  {
    type: types.submit,
    label: 'SAVE',
    action: BUTTONS.SUBMIT,
  },
];

const hero: FormHeroConfig = {
  heroTitleKey: ['name', 'name_last'],
};

// NOTE: FOLLOWING CONFIG WILL BE MERGED WITH form.config.ts/globalFieldProps
// TO POPULATE COMMON PROPS, OPTIONS etc
export const formConfig: FormConfig = {
  dataType: { key: DATATYPE, role: 'form' },
  api: { endpoint: ENDPOINT },
  isNavPromptActive: true,
  hero,
  buttons,
  sections: [
    // ---------------------------------------------------------------------- //
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Datos del titular o representante',
      fields: [
        {
          name: 'name',
          label: 'nombre',
          inputType: INPUTS.text,
        },
        {
          name: 'name_last',
          required: true,
          inputType: INPUTS.text,
        },
        {
          name: 'email',
          required: true,
          inputType: INPUTS.email,
        },
        {
          name: 'tel',
          inputType: INPUTS.text,
        },
        {
          name: 'document_type',
          inputType: INPUTS.select,
        },
        {
          name: 'document_number',
          inputType: INPUTS.text,
        },
      ],
    },
    {
      sectionKey: `${ENDPOINT}_${i++}`,
      sectionTitle: 'Dirección Personal',
      fields: [
        {
          name: 'address_0',
          label: 'dirección 1',
          hint: 'calle + edificio',
          inputType: INPUTS.text,
        },
        {
          name: 'address_1',
          label: 'dirección 2',
          hint: 'puerta',
          inputType: INPUTS.text,
        },
        {
          name: 'postal_code',
          label: 'código postal',
          inputType: INPUTS.text,
        },
        {
          name: 'city',
          label: 'población',
          inputType: INPUTS.text,
        },
        {
          name: 'country',
          label: 'país',
          inputType: INPUTS.selectCountry,
        },
        {
          name: 'province',
          label: 'província',
          hint: 'Provincia de España',
          inputType: INPUTS.select,
          options: SPAIN_PROVINCES,
          required: false,
          isDisabled: true,
          isControlledBy: {
            criteria: { country: 'Spain' },
            target: { isDisabled: false },
          },
        },
      ],
    },
  ],
};
