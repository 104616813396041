/* eslint-disable new-cap */
import { css } from '@emotion/react';
import Color from 'color';
import { colorsMap } from 'components/Map';
import { colors, forms, layout, max, min } from 'styles';
import { calendarDayColor } from 'styles/vendor.ui.css';

// react-datepicker__navigation
// react-datepicker__navigation--previous
// react-datepicker__navigation--next

export const colorAccents = {
  light: Color(colors.primaryXLight).lighten(0.6).hex(),
  med: Color(colors.primaryXLight).lighten(0.4).hex(),
  dark: Color(colors.primaryXLight).darken(0.2).hex(),
};

export const styles = css`
  // ====================================================================== //

  padding: 0 !important;
  padding: calc(${layout.padding} * 2) 0 !important;

  .filter-card {
    /* padding: 0; */
    border: none !important;
    box-shadow: none !important;
  }

  .card-filters-group {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    background: white;
  }

  // DUAL CALENDAR...
  .col-date {
    display: flex;
    justify-content: center;
    background: white !important;
    box-shadow: inset 0 82px 0 0 #e6e6e6;

    ${min.xs} {
      /* padding: 0 calc(${layout.padding} * 1) !important; */
    }
    ${max.sm} {
      padding: 0 !important;
      /* background: white; */
      box-shadow: inset 0px 64px 0px 0 #e6e6e6;
    }
    ${min.md} {
      /* background: white; */
      box-shadow: inset 0px 82px 0px 0 #e6e6e6;
    }
    ${min.lg} {
      background: transparent !important;
      box-shadow: none;
      padding: 0 calc(${layout.padding} * 1) !important;
      justify-content: flex-start;
    }
    ${min.xl} {
      /* padding-right: calc(${layout.padding} * 0) !important; */
    }
  }

  .col-search {
    height: auto;
    align-items: stretch;
    justify-content: stretch;
    display: flex;
    height: unset;

    ${min.xs} {
      order: 2 !important;
    }

    ${min.lg} {
      order: 1;
    }
  }

  .col-filters {
    height: auto;
    align-items: stretch;
    justify-content: stretch;
    display: flex;
    height: unset;

    ${min.xs} {
      order: 0;
      padding: calc(${layout.padding} * 1) calc(${layout.padding} * 2) 0;
    }
    ${min.lg} {
      padding: 0;
      order: 2;
    }
  }

  fieldset.fieldset-filter {
    width: 100%;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    .card.filter-card {
      padding: 0;
    }
  }

  fieldset.fieldset-type {
    width: 100%;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    .card.filter-card {
      padding: 0;
    }
    /* input {
      width: 100%;
    } */
  }

  // WRAPPERS =============================================================== //

  .map-wrapper {
    height: 482px;
    overflow: hidden;
    padding: 0 100px 120px 100px !important;
  }

  // ======================================================================== //
  .summary {
    .date {
      text-transform: capitalize;
    }
  }

  #province {
    &.input-select {
      width: 100%;
      input {
        min-height: 40px;
      }
      .select__menu-list > div {
        /* Select Options */
        padding: 0.33em 0.66em;
        &:hover {
          background: ${colorAccents.light};
        }
        .select__option:hover {
          background: ${colors.successLight};
        }
      }
      .select__control {
        font-weight: 600;
        padding: 0 0.5em;
      }
    }
    .select__placeholder {
      color: ${colors.greyDark};
      opacity: 1;
    }
  }

  input.event-date-input {
    margin-bottom: calc(${layout.padding} * 1.5);
    display: block;
    text-transform: capitalize;
    &[value=''] {
      text-transform: uppercase;
    }
  }

  // ======================================================================== //

  .col-province {
    /* background: rgba(255, 255, 0, 0.5); */
  }
`;
