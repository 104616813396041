import { ContextValues } from './ContextState';

export interface ContextActions {
  setContextState: (value: unknown) => void;
  setLayoutState: (value: unknown) => void;
}

export interface ContextState extends ContextValues, ContextActions {
  notify: any;
}

export type ActionType = {
  type: string;
  payload: ActionPayload;
};

type ActionPayload = {
  value?: { [key: string]: unknown };
};

// ===================================================== //

export const Actions = {
  SET_FIELDS: 'SET_FIELDS',
};

const updateFieldsInContext = (state: ContextState, action: ActionType): ContextState => {
  const { value } = action.payload;

  return {
    ...state,
    ...value,
  };
};

const ContextReducer = (state: ContextState, action: ActionType): ContextState => {
  switch (action.type) {
    case Actions.SET_FIELDS:
      return updateFieldsInContext(state, action);

    /* istanbul ignore next */
    default:
      console.warn('reducer action not recognized');
      return state;
  }
};

export default ContextReducer;
