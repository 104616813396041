import { Col } from 'react-grid-system';
import { v4 as uuid } from 'uuid';
// ICONS:
import { IGeneric } from 'types';
import { DetailList } from 'components/DetailList';
import { Tag } from 'components/Tag';
import { ValueContent } from './DetailValueContent';
import type { FieldConfig } from './DetailSummary.types';

type DetailOptions = {
  isRepeater: boolean;
  isTextArea: boolean;
  isTagsField: boolean;
  isListByID: boolean;
};

type DetailRowProps = { field: FieldConfig };
interface ValueWrapperProps extends DetailRowProps {
  options: DetailOptions;
}

// ROW: REPEATER ============================================================ //

export const DetailValue = ({
  field,
  options,
  options: { isTextArea, isRepeater, isTagsField, isListByID },
}: ValueWrapperProps) => {
  if (isTagsField) {
    return field?.value.map((tag: { id: number; name: string }) => <Tag label={tag.name} />);
  }

  if (isListByID) {
    // TODO:'name' is default key for repeater value - may need to be changed
    return <DetailList endpoint={field.valueFormat?.source as string} ids={field.value} field={field} />;
  }

  if (isRepeater) {
    // TODO:'name' is default key for repeater value - may need to be changed
    return field?.value.map((repeaterItem: IGeneric) => {
      if (repeaterItem.name) {
        return (
          <Col key={uuid()} className="col col-value">
            {repeaterItem.platform ? (
              <ValueContent field={field} options={options} repeaterValue={repeaterItem} />
            ) : (
              <ValueContent field={field} options={options} repeaterValue={repeaterItem.name} />
            )}
          </Col>
        );
      }

      return (
        <Col key={uuid()} className="col col-value">
          {<ValueContent field={field} options={options} repeaterValue={repeaterItem} />}
        </Col>
      );
    });
  }
  if (isTextArea) {
    // TODO:'name' is default key for repeater value - may need to be changed
    const paragraphs = field?.value.split('<br/>');
    return paragraphs.map((paragraph: string) => <p key={uuid()}>{paragraph}</p>);
  }

  return <ValueContent field={field} options={options} />;
};
