import { css } from '@emotion/react';
import { layout } from 'styles/layout.css';
import { colors } from 'styles/colors.css';

export const styles = css`
  &.tag {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: ${layout.borderRadius};
    padding: calc(${layout.padding} / 2) ${layout.padding};
    margin: 0 ${layout.padding} 0 0;
    label {
      font-weight: 700;
      margin-right: 0;
    }
    button {
      padding: 1px;
      border-radius: 50%;
      margin-left: 1em;
      margin-right: calc(${layout.padding} / 2);
      &:hover {
        color: ${colors.danger}!important;
      }
    }
  }

  &,
  &.tag-default {
    background-color: ${colors.greyXLight};
    label,
    button {
      color: ${colors.greyDark};
    }
  }

  &.tag-primary {
    background-color: white;
    border: ${layout.borderWidth} solid ${colors.primaryLight};
    label,
    button {
      color: ${colors.primary};
    }
  }

  &.tag-info {
    background-color: ${colors.infoLight};
    label,
    button {
      color: ${colors.infoDark};
    }
  }

  &.tag-success {
    background-color: ${colors.successLight};
    label,
    button {
      color: ${colors.successDark};
    }
  }

  &.tag-warning {
    background-color: ${colors.warningLight};
    label,
    button {
      color: ${colors.warningDark};
    }
  }

  &.tag-error,
  &.tag-danger {
    background-color: ${colors.dangerLight};
    label,
    button {
      color: ${colors.dangerDark};
    }
  }
`;
