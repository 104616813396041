/* eslint-disable @typescript-eslint/no-shadow */
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useRouter } from 'routes/hooks';
import { useAuth } from 'auth/AuthContext';
import { useGlobal } from 'store/useGlobalContext';
import { formatDateLocal } from 'utils';
import { NODE_ENV } from 'config';

export const PageHeroInfo = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { pageHero } = useGlobal('pageHero');
  const { PATH_BASE, params, category } = useRouter();

  const { values } = pageHero || { title: '', values: [] };
  const isNewEntry = !!params.new;

  return (
    <ul>
      {category && (
        <>
          {/* {category.parent && (
            <li>
              <strong>{t('t.hero.categoryGroup')}:</strong>
              <Link to={`${PATH_BASE}/${category.parent.path}`}>{category.parent.name}</Link>
            </li>
          )} */}
          <li>
            <strong>{t('t.hero.category')}:</strong>
            <Link to={`${PATH_BASE}/${category.path}`}>{category.title}</Link>
          </li>
          {/* {NODE_ENV === 'development' && (
            <li>
              <strong>cat_id:</strong>
              {category.uuid}
            </li>
          )} */}
        </>
      )}
      {user && (
        <>
          <li>
            <strong>{t('t.hero.email')}:</strong>
            <span>{user.email}</span>
          </li>
          <li>
            <strong>{t('t.user.userId')}:</strong>
            <span>{user.uuid}</span>
          </li>
          <li>
            <strong>{t('t.user.userRole')}:</strong>
            <span>{user?.user_role}</span>
          </li>
        </>
      )}
      {!isNewEntry && values && (
        <>
          {values.created_at && (
            <li>
              <strong>Created:</strong>
              {formatDateLocal(values.created_at)}
            </li>
          )}
          {values?.updated_at && (
            <li>
              <strong>Modified:</strong>
              {formatDateLocal(values?.updated_at)}
            </li>
          )}
        </>
      )}
    </ul>
  );
};
