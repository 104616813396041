import { Row, Col } from 'react-grid-system';
import { useFormContext } from 'react-hook-form';
import { forwardRef, useState, type ForwardedRef } from 'react';
import type { FieldConfig } from 'components/Form';
import { styles } from './TextArea.css';

interface TextAreaRichProps {
  field: FieldConfig;
  disabled?: boolean;
}

export const TextAreaRich = forwardRef<unknown, TextAreaRichProps>(
  ({ field, disabled = false }: TextAreaRichProps, ref: ForwardedRef<HTMLTextAreaElement>) => {
    // TODO: REVIEW THIS HOOK..
    const { register } = useFormContext();

    return (
      <Row css={styles}>
        <Col>
          <textarea
            {...register(field.name, { ...field.inputValidation })}
            rows={field?.attributes?.rows || 3}
            ref={ref}
            disabled={disabled}
          >
            {/* ==== TEXTAREA ==== */}
          </textarea>
        </Col>
      </Row>
    );
  },
);
