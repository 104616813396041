import { faker, fakerES, fakerEN } from '@faker-js/faker';
import { slugify, toTitleCase } from 'utils/utils.string.format';
import { PROVINCIAS } from './constants/fakerValues_ES';

export const genBizName = () => {
  const province = slugify(PROVINCIAS[Math.floor(Math.random() * PROVINCIAS.length)]);
  const business = faker.helpers.arrayElement([
    fakerEN.company.name(),
    fakerES.company.name(),
    fakerES.company.name(),
    fakerES.company.name(),
    `${fakerES.company.name()} ${province}`,

    `${fakerES.company.name()} ${fakerES.person.lastName()}`,
    `${fakerES.company.name()} ${fakerES.person.firstName()}`,
    `${fakerES.company.name()} ${fakerES.person.firstName()}`,

    `${fakerEN.company.buzzNoun()}`,
    `${fakerES.company.buzzNoun()} ${province}`,
    `${fakerES.company.buzzNoun()} ${province}`,
    `${fakerES.company.buzzNoun()} ${province}`,
    `${fakerES.company.buzzNoun()} ${province}`,

    `${fakerEN.company.buzzNoun()} ${fakerES.person.firstName()}`,
    `${fakerES.company.buzzNoun()} ${fakerES.person.firstName()}`,
    `${fakerES.company.buzzNoun()} ${fakerES.person.firstName()}`,

    `${fakerEN.company.buzzNoun()} ${fakerES.person.lastName()}`,
    `${fakerEN.company.buzzNoun()} ${fakerES.person.lastName()}`,
    `${fakerES.company.buzzNoun()} ${fakerES.person.lastName()}`,
    `${fakerES.company.buzzNoun()} ${fakerES.person.lastName()}`,
    `${fakerES.company.buzzNoun()} ${fakerES.person.lastName()}`,

    `${fakerEN.company.buzzNoun()} ${fakerEN.company.buzzNoun()}`,
    `${fakerEN.company.buzzNoun()} ${fakerEN.company.buzzNoun()}`,

    fakerEN.company.buzzPhrase(),
    fakerES.company.buzzPhrase(),
    fakerES.company.buzzPhrase(),
    fakerES.company.buzzPhrase(),
    fakerES.company.buzzPhrase(),
  ]);

  return toTitleCase(business);
};
