import { Row, Col } from 'react-grid-system';
import type { UseFormReturn } from 'react-hook-form';
import { useFormState, useFormContext } from 'react-hook-form';
import type { ReactElement } from 'react';
import type { FieldConfig } from 'components/Form';
import { ErrorMessage } from 'components/Form/components/ErrorMessage/ErrorMessage-V1';
import { ModalTrigger } from 'components/ModalTrigger';
import { handleErrors } from './FormField.utils';
import { styles } from './FormFieldLabel.css';
import { ArrayJSX } from 'utils/ArrayJSX';

// NOTE: GREAT REF: REACT-QUERY + REACT-HOOK-FORM:
// https://tkdodo.eu/blog/react-query-and-forms#invalidate-and-reset-after-mutation

// TODO: AMAZING BEST-PRACTICES REF FOR FORMS:
// ref: https://blog.logrocket.com/style-forms-css/

export interface FormFieldLabelProps {
  field: FieldConfig;
  isLabelInline?: boolean;
  isFormLocked?: boolean;
}

export const LabelContent = ({
  field,
  isLabelInline,
  isDisabled = false,
}: {
  field: FieldConfig;
  isLabelInline: boolean;
  isDisabled: boolean;
}): ReactElement => {
  const label = field?.label;
  const link = field?.label_link;

  if (link) {
    const labelParts = label?.split('{' + link.key + '}');
    return (
      <>
        <span>{labelParts?.[0]}</span>
        <ModalTrigger label={link.label} target={link.target} variant={'clear'} isDisabled={!!field?.isDisabled} />
        <span>{labelParts?.[1]}</span>
      </>
    );
  }

  return <>{label}</>;
};
