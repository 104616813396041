import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { styles } from './Thumbnail.css';

export const placeHolder =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII=';

export const LazyLoadImage = ({ src, alt, ...props }: any) => {
  const [imgSrc, setImgSrc] = useState(placeHolder);
  const [imgRef, setImgRef] = useState();

  useEffect(() => {
    let observer: IntersectionObserver;
    let didCancel = false;

    if (imgRef && imgSrc === placeHolder) {
      if (IntersectionObserver) {
        observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (!didCancel && (entry.intersectionRatio > 0 || entry.isIntersecting)) {
                setImgSrc(src);
              }
            });
          },
          {
            threshold: 0.5,
          },
        );
        observer.observe(imgRef);
      } else {
        setImgSrc(src); // LEGACY BROWSERS
      }
    }
    return () => {
      didCancel = true;
      if (observer && observer.unobserve && imgRef) {
        observer.unobserve(imgRef);
      }
    };
  });

  return <img ref={setImgRef} src={imgSrc} alt={alt} {...props} />;
};

LazyLoadImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
};

export const ThumbLazy = (props: any) => (
  <div css={styles}>
    <LazyLoadImage {...props} />
  </div>
);

export const Thumbnail = ({ src, alt, cover }: any) => {
  return (
    <div css={styles} style={{ backgroundImage: `url(${src})` }} className={cover ? 'cover' : ''}>
      <img src={src} alt={alt} style={{ opacity: 0.1 }} />
    </div>
  );
};

export const ThumbnailV1 = (props: any) => (
  <div css={styles}>
    <img {...props} />
  </div>
);
