import { css } from '@emotion/react';
import { colors, layout, forms, cssFontMono, min, max } from 'styles';

export const styles = css`
  &.row-label.label-inline {
    display: flex;
    align-items: center;

    label {
      span {
        color: ${colors.primaryXLight};
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        margin: 0;
      }
    }
    .error-msg {
      height: calc(${layout.padding} * 3.2);
      display: flex;
      align-items: center;
      position: absolute;
      right: -0.5em;
      top: 94%;
    }
  }

  &.row-label:not(.label-inline) {
    display: flex;
    align-items: center;
    padding: calc(${layout.padding} * 0.5) 0;

    .col-label {
      display: inline-flex;
      align-items: center;
      flex-wrap: nowrap;
      white-space: nowrap;
      padding-bottom: calc(1em * 0.25) !important;
      ${min.sm} {
        padding-bottom: calc(1em * 0.25) !important;
      }
      ${min.md} {
        padding-bottom: calc(1em * 0.25) !important;
      }
      ${min.lg} {
        padding-bottom: 0 !important;
      }
    }
    .col-label-left {
      justify-content: flex-start;
      ${min.sm} {
        justify-content: flex-start;
      }
      ${min.md} {
        justify-content: flex-start;
      }
      ${min.lg} {
        justify-content: flex-start;
      }
    }
    .col-label-right {
      color: ${colors.grey};
      opacity: 1;
      font-weight: 600;
      font-size: 0.85em;
      justify-content: flex-start;
      ${min.sm} {
        justify-content: flex-end;
      }
      ${min.md} {
        justify-content: flex-start;
        &:empty {
          display: none;
        }
      }
      ${min.lg} {
        justify-content: flex-end;
        font-size: 0.95em;
      }
    }

    // ======================================================================== //
    // LABELS:

    label {
      margin: 0;
      span {
        position: absolute;
        right: 0;
        margin: 0 ${layout.padding};
        color: ${colors.grey};
        opacity: 1;
        font-weight: 600 !important;

        ${max.md} {
          display: block;
          position: relative;
          font-size: 0.95em;
          padding-top: 0.1em;
          margin: 0;
        }
      }
      .error-msg {
        position: absolute;
        right: 0;
        margin: 0 ${layout.padding};
        color: ${colors.grey};
        opacity: 1;
        font-weight: 600 !important;

        ${max.md} {
          display: block;
          position: relative;
          font-size: 0.95em;
          padding-top: 0.1em;
          margin: 0;
        }
      }
    }
  }

  /*** CLASSES ARE SET ON fieldset ***/

  &.is-disabled {
    /* opacity: 0.75; */
    opacity: 0.66;
    .row-label {
      button {
        /* pointer-events: all !important; */
        /* border: 1px solid blue !important; */
        /* &:hover {
          cursor: default;
          color: ${colors.primaryDark}!important;
          -webkit-text-fill-color: ${colors.primaryDark}!important;
        } */
      }
    }
  }

  &.required {
    label:after {
      content: '*';
      color: ${colors.grey}!important;
      font-size: 1.5em;
      font-weight: 400;
      position: absolute;
      transform: translateX(0.2em) translateY(-0.2em);
      opacity: 0.75;
    }
  }

  /* &.read-only {
    & > label {
      color: ${colors.grey};
      &:after {
        content: '';
      }
    }
  } */

  &.is-hidden {
    display: none !important;
  }

  .label-hint {
    /* border: 1px solid red; */
  }
`;
