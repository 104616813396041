import { css } from '@emotion/react';
import { layout } from 'styles/layout.css';
import { colors } from 'styles/colors.css';

export const styles = css`
  width: 100%;
  & > div {
    height: 100%;
  }

  button {
    width: 100%;
  }
`;
