/* eslint-disable quote-props */
import { Col, Row } from 'react-grid-system';
import { useState } from 'react';

import { useLayout } from 'pages/Layout/context/LayoutContext';
import { Button } from 'components/Button';
import { Card } from 'components/Card';

import { MODAL_ANIMATION_MS } from './Modal.config';
import { styles } from './Modal.css';
import { useGlobal } from 'store/useGlobalContext';

export const ModalNavigation = ({ isPromptOpen, cancelNavigation, confirmNavigation }: any) => {
  const { setLayoutState } = useLayout();
  const [isOpen, setIsOpen] = useState(isPromptOpen);

  const handleCancel = () => {
    initializeClose(cancelNavigation);
    setIsOpen(false);
  };

  const handleConfirm = () => {
    initializeClose(confirmNavigation);
    setIsOpen(false);
  };

  const initializeClose = (action: any) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        setLayoutState({ isScrollLocked: false });
        action();
        resolve;
      }, MODAL_ANIMATION_MS * 0.9);
    });
  };

  const title = 'Unsaved changes';
  const subtitle = '';
  const body = ['Confirm exit and lose all changes.'];
  // const body = ['Continue with exit ??', 'All new changes will be lost.'];
  const buttons = (
    <>
      <Button label="Confirm Exit" color="warning" onClick={handleConfirm} />
      <Button label="Cancel" variant="outline" onClick={handleCancel} />
    </>
  );

  return (
    <div css={styles} className={isOpen ? 'is-open' : 'is-closed'}>
      <div className="modal-overlay" onClick={handleCancel} />
      <Card className="modal" attr={{ role: 'dialog', 'aria-labelledby': 'dialog-title' }}>
        <div>
          {(title || subtitle) && (
            <header>
              {title && <h1 id="dialog-title">{title}</h1>}
              {subtitle && <h2>{subtitle}</h2>}
            </header>
          )}
          <hr />
          <Row className="row-body">
            {body && (
              <Col xs={12}>
                {typeof body === 'object' && body.map((text, i) => <p key={i}>{text}</p>)}
                {typeof body === 'string' && <p>{body}</p>}
              </Col>
            )}
          </Row>
          {buttons && (
            <Row className="row-buttons">
              <Col xs={12}>{buttons}</Col>
            </Row>
          )}
        </div>
      </Card>
    </div>
  );
};
